import {
  FormInput,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
} from "shards-react";
import { View, Text, StyleSheet } from "react-native-web";
//icons
import { FiUser } from "react-icons/fi";
import { TbLock } from "react-icons/tb";
import { HiOutlineMail } from "react-icons/hi";
import React, { useState, forwardRef } from "react";

const TextField = forwardRef((props, ref) => {
  const { icon, iconName, iconFix, iconColor, errorMessage, markMessage } =
    props;
  const { maxLength, placeholder, type, style, value, disable } = props;
  const { isInvalid } = props;
  const { onChangeText, onFocus, onEndEditing } = props;
  const [focus, setFocus] = useState(false);

  const changeFocus = (value) => {
    onFocus && onFocus();
    setFocus(value);
  };

  return (
    <React.Fragment>
      <InputGroup seamless>
        <FormInput
          style={{
            ...Styles.input,
            borderColor: focus ? "#DE7C78" : isInvalid ? "#E30505" : "",
            ...style,
            width: 30,
          }}
          innerRef={ref}
          value={value}
          type={type ? type : "text"}
          placeholder={placeholder ? placeholder : ""}
          maxLength={maxLength ? maxLength : null}
          onChange={(e) => {
            let text = e.target.value;
            onChangeText && onChangeText(text);
          }}
          onFocus={() => changeFocus(true)}
          onBlur={() => changeFocus(false)}
          onEndEditing={() => {
            onEndEditing && onEndEditing();
          }}
          disabled={disable}
        />
        {icon && !iconName ? (
          <InputGroupAddon type="append">
            <InputGroupText>{icon ? icon : null}</InputGroupText>
          </InputGroupAddon>
        ) : null}
        {iconName ? (
          <InputGroupAddon type="append">
            <InputGroupText>
              {icons({ iconName: iconName, iconColor: iconColor })}
            </InputGroupText>
          </InputGroupAddon>
        ) : null}
        {iconFix ? (
          <View
            style={{
              position: "absolute",
              zIndex: 10,
              top: "50%",
              transform: "translateY(-50%)",
              right: 10,
            }}
          >
            {iconFix}
          </View>
        ) : null}
      </InputGroup>
      {errorMessage && isInvalid ? (
        <View
          style={{
            marginTop: 5,
            position: "absolute",
            left: 5,
            bottom: 0,
            width: "100%",
            backgroundColor: "#dada",
          }}
        >
          <Text style={Styles.errorMessage}>{errorMessage}</Text>
        </View>
      ) : null}
      {markMessage && !isInvalid ? (
        <View style={{ marginTop: 5 }}>
          <Text style={Styles.markMessage}>{markMessage}</Text>
        </View>
      ) : null}
    </React.Fragment>
  );
});

const icons = (props) => {
  const { iconName, iconColor } = props;
  switch (iconName) {
    case "user":
      return (
        <FiUser
          style={{ fontSize: 20, color: iconColor ? iconColor : "#333333" }}
        />
      );
    case "password":
      return (
        <TbLock
          style={{ fontSize: 20, color: iconColor ? iconColor : "#333333" }}
        />
      );
    case "email":
      return (
        <HiOutlineMail
          style={{ fontSize: 20, color: iconColor ? iconColor : "#333333" }}
        />
      );
    default:
      return;
  }
};

const Styles = StyleSheet.create({
  input: {
    borderRadius: 5,
    borderColor: "",
    height: 50,
  },
  errorMessage: {
    fontSize: 12,
    color: "#E30505",
    position: "absolute",
  },
  markMessage: {
    fontSize: 12,
    color: "#696D79",
    position: "absolute",
  },
});

export default TextField;
