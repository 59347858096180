import React, { useState, useRef, useEffect } from "react";
import { Text, View } from "react-native-web";
import LinearGradient from "react-native-web-linear-gradient";
import DropdownShadow from "../../components/dropdown/DropdownShadow";
//Images
import badgesale1 from "../../asset/images/home/salesContainer/badgesale1.png";
import apiPath from "../../services/configs/apiPath";
import { get } from "../../functions/fetch/fetch";

const SalesContainer3 = (props) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const refBox = useRef();

  const [list, setList] = useState([]);
  const [select, setSelect] = useState({});
  const [rankingInfo, setRankingInfo] = useState({});

  useEffect(() => {
    downloadRanking();
    downloadRankingInfo();
  }, []);

  useEffect(() => {
    if (refBox.current) {
      setWidth(refBox.current.offsetWidth / 1.2);
      setHeight(refBox.current.offsetHeight);
    }
  }, [refBox.current]);

  const downloadRanking = async () => {
    let url = apiPath.member.getRankingWithPoint;
    let res = await get(url, {}, true);
    if (!res.status) {
      return;
    }
    if (res.response.data) {
      let init = [];
      res.response.data.forEach((item) => {
        console.log(item);
        init.push({
          value: item.code,
          label: item.name,
          point: item.point,
        });
      });
      setList(init);
    }
  };

  const downloadRankingInfo = async () => {
    let url = apiPath.member.getUserRankingInfo;
    const { status, response } = await get(url, {}, true);
    if (!status) {
      console.log(response.data.error);
    }
    if (response.data) {
      setRankingInfo(response.data);
      console.log("ranking info ....", response.data);
    }
  };

  return (
    <View
      ref={refBox}
      style={Styles.linearContainer1}
      onLayout={(e) => {
        if (refBox.current) {
          setWidth(refBox.current.offsetWidth / 1.2);
        }
      }}
    >
      <View style={{ width: "100%", zIndex: 30 }}>
        <DropdownShadow
          // placeholder={"คะแนนสำหรับ\nขึ้นตำแหน่ง ไตรมาส 01/2024"}
          placeholder={"คะแนนสำหรับขึ้นตำแหน่ง"}
          disable={true}
          list={[]}
          onChange={(values) => {
            const { value, label } = values;
            console.log(value, label);
          }}
        />
      </View>
      <View
        style={{
          width: "100%",
          position: "absolute",
          top: 70,
          zIndex: 10,
          paddingLeft: 15,
        }}
      >
        <Text
          style={{
            fontWeight: "bold",
            color: "#FFFFFF",
            alignSelf: "flex-start",
            marginTop: 8,
          }}
        >
          คะแนนปัจจุบัน :{" "}
          {rankingInfo.currentPoint
            ? Number(rankingInfo.currentPoint).toLocaleString()
            : "0"}
        </Text>
        <Text
          style={{
            fontWeight: "bold",
            color: "#FFFFFF",
            alignSelf: "flex-start",
            marginTop: 8,
          }}
        >
          ตำแหน่งปัจจุบัน : {rankingInfo.name ? rankingInfo.name : "N/A"}
        </Text>
      </View>
      <CircleFade width={width} />

      <View style={{ flex: 1 }} />
      <View style={{ width: "100%", zIndex: 99999999 }}>
        <DropdownShadow
          placeholder={"ตำแหน่งที่ต้องการพิชิตในไตรมาส"}
          list={list}
          onChange={(values) => {
            setSelect({ ...values });
          }}
        />
      </View>
      <Text
        style={{
          fontWeight: "bold",
          color: "#FFFFFF",
          alignSelf: "flex-start",
          marginTop: 8,
        }}
      >
        ขาดอีกเพียง
      </Text>
      <View
        style={{
          width: "100%",
          backgroundColor: "rgba(229, 93, 87, 1)",
          borderRadius: 8,
          justifyContent: "center",
          alignItems: "center",
          padding: 8,
          marginTop: 20,
        }}
      >
        <Text
          style={{
            color: "#FFFFFF",
            fontSize: "clamp(20px,3vh,24px)",
            fontWeight: "bold",
          }}
        >
          {select.point ? Number(select.point).toLocaleString() : 0}
        </Text>
      </View>
      <Text
        style={{
          fontWeight: "bold",
          color: "#FFFFFF",
          alignSelf: "flex-start",
          marginTop: 8,
        }}
      >
        คะแนน
      </Text>
    </View>
  );
};

const CircleFade = (props) => {
  const { width } = props;
  return (
    <View
      style={{
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 80,
      }}
    >
      <LinearGradient
        style={{
          width: width,
          height: width,
          borderRadius: "50%",
          position: "absolute",
          left: (width / 3.5) * -1,
        }}
        colors={[
          "rgba(236, 162, 163, 1)",
          "rgba(229, 129, 130, 0)",
          "rgba(229, 129, 130, 0)",
        ]}
        useAngle="true"
        angle={270}
      />
      <LinearGradient
        style={{
          width: width,
          height: width,
          borderRadius: "50%",
          position: "absolute",
          right: (width / 2.6) * -1,
          marginBottom: 50,
        }}
        colors={[
          "rgba(236, 162, 163, 1)",
          "rgba(229, 129, 130, 0)",
          "rgba(229, 129, 130, 0)",
        ]}
        useAngle="true"
        angle={110}
      />
      <img
        src={badgesale1}
        alt={"profile"}
        style={{ width: width / 1.4, maxWidth: 200, zIndex: 20 }}
      />
    </View>
  );
};

const Styles = {
  container: {
    margin: 0,
  },
  linearContainer1: {
    width: "100%",
    height: "100%",
    boxShadow: "16px 16px 24px 0px rgba(0, 0, 0, 0.04)",
    background: "linear-gradient(173.69deg, #EA869F -1.06%, #FF5252 100.91%)",
    borderRadius: 24,
    alignItems: "center",
    padding: 15,
    paddingBottom: 30,
  },
  circle: {
    width: "clamp(90px,3vh,120px)",
    height: "clamp(90px,3vh,120px)",
    borderRadius: "50%",
    position: "absolute",
    opacity: 0.8,
  },
};
export default SalesContainer3;
