import React, { useState, useEffect, useRef } from "react";
import { Grid } from "@mui/material";
import { View, Text } from "react-native-web";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import DatePickerRange from "../../components/calendar/DatePickerRange";
import moment from "moment-timezone";
import apiPath from "../../services/configs/apiPath";
import { get } from "../../functions/fetch/fetch";
import { useDialog } from "../../components/modal/Popup";
import LoadingTab from "../../components/modal/LoadingTab";
import EmptyList from "../../components/pages/EmptyList";
import { formatNumber } from "../../functions/format/numberFormat";
import { useDispatch } from "react-redux";

const ChartSalesHome = (props) => {
  const ref = useRef();
  const dialog = useDialog();
  const dispatch = useDispatch();
  //state
  const [containerOffset, setContainerOffset] = useState({
    width: 0,
    height: 0,
  });
  const [list, setList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [date, setDate] = useState({
    startDate: moment().subtract(1, "months"),
    endDate: moment(),
  });

  useEffect(() => {
    downloadSummaryList(date.startDate, date.endDate);
  }, [date]);

  useEffect(() => {
    if (ref.current) {
      setContainerOffset({
        width: ref.current.offsetWidth,
        height: ref.current.offsetHeight,
      });
    }
  }, [ref.current]);

  const downloadSummaryList = async (startDate, endDate) => {
    setLoading(false);
    let url = apiPath.sales.getSummaryHome;
    let _startDate = moment.utc(startDate).set({ h: 0, m: 0, s: 0 });
    let _endDate = moment.utc(endDate).set({ h: 0, m: 0, s: 0 });
    let params = {
      startDate: _startDate.toDate(),
      endDate: _endDate.toDate(),
    };
    let { status, response } = await get(url, params, true);
    if (!status) {
      dialog.alert({ message: response.data.error });
      return;
    }
    if (response.data) {
      //setList(response.data.length > 0 ? response.data : []);
      setList(response.data.data.length > 0 ? response.data.data : []);
      await dispatch({ type: "SET/HOMEPAGE/SUMMARY", payload: response.data });
      await dispatch({
        type: "SET/HOMEPAGE",
        payload: { startDate: _startDate, endDate: _endDate },
      });
      setLoading(true);
    }
  };

  const formatNumberWithComma = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <Grid item xs={12} md={12} lg={8}>
      <View
        ref={ref}
        style={Styles.container}
        onLayout={() => {
          if (ref.current) {
            setContainerOffset({
              width: ref.current.offsetWidth,
              height: ref.current.offsetHeight,
            });
          }
        }}
      >
        <Grid container>
          <Grid item xs={12} md={6} xl={4} style={{ padding: 10 }}>
            <DatePickerRange
              startDate={date.startDate}
              endDate={date.endDate}
              placeholder={"เลือกวันที่เริ่มต้น - วันที่สิ้นสุด"}
              isTop={false}
              styles={{ width: "100%" }}
              onChange={(values) => {
                const { startDate, endDate } = values;
                if (startDate && endDate) {
                  let _startDate = moment
                    .utc(startDate)
                    .set({ h: 0, m: 0, s: 0 });
                  let _endDate = moment.utc(endDate).set({ h: 0, m: 0, s: 0 });

                  setDate({
                    startDate: _startDate,
                    endDate: _endDate,
                  });
                  dispatch({
                    type: "SET/HOMEPAGE",
                    payload: { startDate: _startDate, endDate: _endDate },
                  });
                }
              }}
            />
          </Grid>
        </Grid>
        <View style={{ height: 30 }} />
        {list.length > 0 && isLoading ? (
          <LineChart
            width={containerOffset.width - 60}
            height={560}
            data={list}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <defs>
              <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
                <feGaussianBlur in="SourceAlpha" stdDeviation="5" />
                <feOffset dx="2" dy="2" result="offsetblur" />
                <feComponentTransfer>
                  <feFuncA type="linear" slope="0.5" />
                </feComponentTransfer>
                <feMerge>
                  <feMergeNode />
                  <feMergeNode in="SourceGraphic" />
                </feMerge>
              </filter>
            </defs>
            <CartesianGrid vertical={false} strokeDasharray="" />
            <XAxis dataKey="name" />
            <YAxis tickFormatter={formatNumberWithComma} />
            <Tooltip formatter={formatNumberWithComma} />
            <Legend />
            <Line
              type="monotone"
              dataKey="summaryRetail"
              stroke="rgba(40, 225, 170, 1)"
              activeDot={{ r: 8 }}
              strokeWidth={2}
              filter="url(#shadow)"
              name="ขายปลีก"
            />
            <Line
              type="monotone"
              dataKey="summaryOrder"
              stroke="rgba(170, 73, 66, 1)"
              strokeWidth={2}
              filter="url(#shadow)"
              name="คำสั่งซื้อ"
            />
          </LineChart>
        ) : isLoading && list.length === 0 ? (
          <EmptyList />
        ) : (
          <LoadingTab />
        )}
      </View>
    </Grid>
  );
};

const Styles = {
  container: {
    flex: 1,
    height: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: "2%",
  },
};

export default ChartSalesHome;
