import React from "react";
import { View, Text } from "react-native-web";
//images
import emptyProduct from "../../asset/images/product/inventory/emptyProduct.png";

const EmptyList = (props) => {
  const { title } = props;
  return (
    <View
      style={{
        flex: 1,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={emptyProduct} style={{ width: 120 }} alt={"emptyProduct"} />
      <View style={{ height: 30 }} />
      <Text
        style={{
          fontSize: "clamp(16px,2vh,20px)",
          fontWeight: "bold",
          color: "rgba(15, 16, 17, 1)",
        }}
      >
        {title ? title : "ยังไม่มีรายการ"}
      </Text>
    </View>
  );
};

export default EmptyList;
