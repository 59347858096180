import React from "react";
import { View, Text } from "react-native-web";
import { Grid } from "@mui/material";
import OptionButton from "../../../components/button/OptionButton";
import { CalendarRewardIcon } from "../../../asset/images/icons/iconsLib";
import productImg from "../../../asset/images/example/product.png";

const TopicContainer = () => {
  return (
    <Grid item xs={12} md={6} xl={4} sty>
      <View style={Styles.container}>
        <View style={Styles.content}>
          <View style={{ flex: 0.4 }}>
            <img style={Styles.img} alt={"image"} src={productImg} />
          </View>
          <View style={{ width: 10 }} />
          <View style={Styles.rightContent}>
            <Text style={Styles.textTitle}>
              เคล็ด (ไม่)ลับการขายให้ปัง ฉบับ Rosegold
            </Text>
            <OptionButton
              styles={Styles.optionIcon}
              title={"ตัวเลือก"}
              list={[
                {
                  label: "เปิดสิทธิใช้งาน",
                  value: "activeUser",
                },
                { label: "แก้ไขข้อมูล", value: "edit" },
              ]}
              onSelect={(value) => {}}
            />
            <View style={{ height: 10 }} />
            <StatusTag title={"แคมเปญ"} />
            <View style={Styles.calendarContainer}>
              <CalendarRewardIcon />
              <Text>12/11/2022</Text>
            </View>
            <View style={{ height: 10 }} />
            <View
              style={{
                width: "100%",
                height: 70,
                overflow: "hidden",
              }}
            ></View>
          </View>
        </View>
      </View>
    </Grid>
  );
};

const StatusTag = (props) => {
  const {
    title = "",
    color = "rgba(255, 228, 187, 1)",
    colorText = "rgba(241, 152, 19, 1)",
  } = props;
  return (
    <View
      style={{
        width: 100,
        height: 20,
        backgroundColor: color,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 8,
      }}
    >
      <Text
        style={{
          fontSize: 12,
          fontWeight: "bold",
          color: colorText,
        }}
      >
        {title}
      </Text>
    </View>
  );
};

const Styles = {
  container: {
    padding: 15,
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
    borderRadius: 8,
    maxHeight: 220,
    overflow: "hidden",
  },
  content: {
    flexDirection: "row",
  },
  img: {
    width: "100%",
    height: 190,
    objectFit: "cover",
    borderRadius: 8,
  },
  rightContent: {
    flex: 0.6,
    paddingLeft: 10,
    paddingRight: 5,
    paddingBottom: 10,
  },
  textTitle: {
    fontSize: 16,
    color: "rgba(15, 16, 17, 1)",
    fontWeight: "bold",
  },
  optionIcon: {
    fontSize: 15,
    color: "rgba(105, 109, 121, 1)",
    position: "absolute",
    top: 5,
    right: 0,
  },
  calendarContainer: {
    flexDirection: "row",
    marginTop: 10,
  },
  contentText: {
    fontSize: 10,
  },
};
export default TopicContainer;
