import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import { Grid } from "@mui/material";
import { Divider } from "rsuite";
import { useNavigate } from "react-router-dom";
//component
import { useDialog } from "../../components/modal/Popup";
import { RiDeleteBinLine } from "react-icons/ri";
import Button from "../../components/button/Button";
import CheckBoxLabel from "../../components/checkbox/CheckBoxLabel";
import Dropdown from "../../components/dropdown/Dropdown";
import TextField from "../../components/textField/TextField";
import { useSelector } from "react-redux";
import { formatNumberString } from "../../functions/format/numberFormat";
import {
  downloadAllDistrict,
  downloadAllProvince,
  downloadAllSubDistrict,
  downloadAllZipcode,
} from "../../services/api/masterdata/address";
import apiPath from "../../services/configs/apiPath";
import { post, put } from "../../functions/fetch/fetch";
import {
  validateEmail,
  validatePhone,
} from "../../functions/validate/validate";

const SettingAddProfile = (props) => {
  //property
  const navigate = useNavigate();
  const dialog = useDialog();
  const { editData } = props;
  const { onClose } = props;
  const masterData = useSelector((state) => {
    return state.setupData.masterData;
  });

  const addressList = [
    { value: "Y", label: "ที่อยู่ตามบัตรประชาชน (สำหรับหัก ณ ที่จ่าย)" },
    { value: "S", label: "ที่อยู่ปัจจุบัน" },
    { value: "P", label: "ที่อยู่สำหรับจัดส่งเอกสาร" },
    { value: "N", label: "ที่อยู่อื่นๆ" },
  ];

  //state
  const [provinceList, setProvinceList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [subDistrictList, setSubDistrictList] = useState([]);
  const [zipcodeList, setZipcodeList] = useState([]);

  const [data, setData] = useState({
    userId: "",
    personalType: 0,
    name: "",
    citizenId: "",
    passportNumber: "",
    email: "",
    phoneCode: "",
    phoneNumber: "",
    countryCode: "",
    address: "",
    provinceCode: "",
    districtCode: "",
    subdistrictCode: "",
    zipcode: "",
    bankCountryCode: "",
    bankId: "",
    bankName: "",
    accountName: "",
    accountNumber: "",
    bankSwiftCode: "",
    active: "Y",
  });

  useEffect(() => {
    if (editData) {
      console.log("edit data", editData);
      setData({
        ...editData,
        personalType: Number(editData.personalType),
      });
    }
  }, [editData]);

  useEffect(() => {
    const downloadAllData = async () => {
      let provinces = await downloadAllProvince();
      let initProvinces = [];
      provinces.forEach((item) => {
        initProvinces.push({ value: item.provinceCode, label: item.province });
      });
      setProvinceList(initProvinces);
    };
    downloadAllData();
  }, []);

  useEffect(() => {
    const getDistrictList = async () => {
      if (!data.provinceCode) {
        return;
      }
      let districtList = await downloadAllDistrict(data.provinceCode);
      let initDistrictList = [];
      districtList.forEach((item) => {
        initDistrictList.push({
          value: item.districtCode,
          label: item.district,
        });
      });
      console.log(initDistrictList);
      setDistrictList(initDistrictList);
    };
    if (!data) {
      return;
    }

    if (data.provinceCode) {
      console.log("getDistrictList");
      getDistrictList();
      setData({
        ...data,
        districtCode: null,
        district: null,
        subdistrictCode: null,
        subDistrict: null,
        zipcode: null,
      });
    }
  }, [data?.provinceCode]);

  useEffect(() => {
    const getSubDistrictList = async () => {
      let subDistrictList = await downloadAllSubDistrict(
        data.provinceCode,
        data.districtCode
      );
      let initSubDistrictList = [];
      subDistrictList.forEach((item) => {
        initSubDistrictList.push({
          value: item.subDistrictCode,
          label: item.subDistrict,
        });
      });
      setSubDistrictList(initSubDistrictList);
    };
    if (!data) {
      return;
    }
    if (data.provinceCode && data.districtCode) {
      getSubDistrictList();
      setData({
        ...data,
        subDistrictCode: null,
        subDistrict: null,
        zipcode: null,
      });
    }
  }, [data?.districtCode]);

  useEffect(() => {
    const getZipcodeList = async () => {
      let zipcodeList = await downloadAllZipcode(
        data.province,
        data.district,
        data.subdistrict
      );
      console.log("zipcodelist", zipcodeList);
      let initZipcodeList = [];
      zipcodeList.forEach((item) => {
        initZipcodeList.push({
          value: item.zipcode,
          label: item.zipcode,
        });
      });
      console.log(initZipcodeList);
      setZipcodeList(initZipcodeList);
    };
    if (!data) {
      return;
    }
    console.log("preapre downloadzipcode");
    if (data.province && data.district && data.subdistrict) {
      console.log("download zipcode");
      getZipcodeList();
      setData({
        ...data,
        zipcode: null,
      });
    }
  }, [data?.subdistrictCode]);

  const validation = () => {
    if (!data.countryCode) {
      return false;
    }
    if (data.countryCode === "THA") {
      if (
        !data.provinceCode ||
        !data.districtCode ||
        !data.subdistrictCode ||
        !data.zipcode
      ) {
        return false;
      }
    }
    if (!data.bankName || !data.accountName || !data.accountNumber) {
      return false;
    }
    if (
      !data.name ||
      !data.citizenId ||
      !data.email ||
      !data.phoneCode ||
      !data.phoneNumber ||
      !data.address
    ) {
      return false;
    }

    if (data.bankCountryCode !== "THA" && !data.bankSwiftCode) {
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (!validation()) {
      dialog.alert({ message: "กรุณากรอกข้อมูลให้ครบถ้วน" });
      return;
    }

    if (!validateEmail(data.email)) {
      dialog.alert({ message: "อีเมลไม่ถูกต้อง" });
      return;
    }

    if (String(data.phoneCode) === "66") {
      if (!validatePhone(data.phoneNumber)) {
        await dialog.alert({
          title: "แจ้งเตือน",
          message: "เบอร์โทรศัพท์ไม่ถูกต้อง",
        });
        return;
      }
    }

    let params = {
      ...data,
      personalType: String(data.personalType),
      phoneCode: String(data.phoneCode),
      userId: 0,
      provinceCode: String(data.provinceCode),
      districtCode: String(data.districtCode),
      subdistrictCode: String(data.subdistrictCode),
      zipcode: String(data.zipcode),
    };
    console.log("params", params);
    if (editData) {
      update(params);
    } else {
      add(params);
    }
  };

  const add = async (params) => {
    let url = apiPath.member.insertTxUserAddress;
    let { status, response } = await post(url, params, true);
    if (!status) {
      dialog.alert({ message: response.data.error });
      return;
    }
    if (response.data) {
      await dialog.alert({ message: "เพิ่มที่อยู่สำเร็จ" });
      onClose && onClose();
    }
  };

  const update = async (params) => {
    let url = apiPath.member.updateTxUserAddress;
    let { status, response } = await put(url, params, true);
    if (!status) {
      dialog.alert({ message: response.data.error });
      return;
    }
    if (response.data) {
      await dialog.alert({ message: "อัพเดทที่อยู่สำเร็จ" });
      onClose && onClose();
    }
  };

  return (
    <View>
      <Text style={Styles.title}>เพิ่มที่อยู่</Text>
      <Divider />
      <Text style={Styles.title2}>ข้อมูลการหักภาษี ณ ที่จ่าย</Text>
      <View style={Styles.checkBoxContainer}>
        <CheckBoxLabel
          value={data.personalType === 0}
          label={"บุคคลธรรมดา"}
          onCheck={(value) => {
            if (value) {
              setData({
                ...data,
                personalType: 0,
              });
            }
          }}
        />
        <View style={{ width: 10 }} />
        <CheckBoxLabel
          value={data.personalType === 1}
          label={"บริษัท"}
          onCheck={(value) => {
            if (value) {
              setData({
                ...data,
                personalType: 1,
              });
            }
          }}
        />
      </View>
      <Text style={Styles.subTitle}>ข้อมูลการโอนเงิน</Text>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Text style={Styles.titleText}>ประเทศของธนาคาร </Text>
          <View style={{ height: 8 }} />
          <Dropdown
            value={
              masterData &&
              masterData.countryList.length > 0 &&
              data.bankCountryCode
            }
            valueType={"string"}
            placeholder={"เลือกประเทศของธนาคาร"}
            list={masterData && masterData.countryList}
            onChange={(values) => {
              const { value, label } = values;
              setData({
                ...data,
                bankCountryCode: value,
              });
            }}
          />
        </Grid>

        {data.bankCountryCode === "THA" ? (
          <Grid item xs={12} md={6}>
            <Text style={Styles.titleText}>ธนาคาร </Text>
            <View style={{ height: 8 }} />

            <Dropdown
              value={masterData && masterData.bankList && data.bankId}
              valueType={"string"}
              placeholder={"เลือกธนาคาร"}
              list={masterData && masterData.bankList}
              onChange={(values) => {
                const { value, label } = values;
                setData({
                  ...data,
                  bankName: label,
                  bankId: value,
                });
              }}
            />
          </Grid>
        ) : (
          <Grid item xs={12} md={6}>
            <Text style={Styles.titleText}>ธนาคาร</Text>
            <View style={{ height: 8 }} />

            <TextField
              value={data.bankName}
              placeholder={"ชื่อธนาคาร"}
              onChangeText={(value) => {
                setData({
                  ...data,
                  bankName: value,
                });
              }}
            />
          </Grid>
        )}

        <Grid item xs={12} md={6}>
          <Text style={Styles.titleText}>เลขบัญชีรับเงิน </Text>
          <View style={{ height: 8 }} />
          <TextField
            value={data.accountNumber}
            placeholder={"เลขบัญชีรับเงิน"}
            onChangeText={(value) => {
              setData({
                ...data,
                accountNumber: formatNumberString(value),
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Text style={Styles.titleText}>ชื่อบัญชี </Text>
          <View style={{ height: 8 }} />
          <TextField
            value={data.accountName}
            placeholder={"ชื่อบัญชี"}
            onChangeText={(value) => {
              setData({
                ...data,
                accountName: value,
              });
            }}
          />
        </Grid>
        {data.bankCountryCode && data.bankCountryCode !== "THA" ? (
          <Grid item xs={12} md={6}>
            <Text style={Styles.titleText}>Swift Code</Text>
            <View style={{ height: 8 }} />
            <TextField
              value={data.bankSwiftCode}
              placeholder={"Swift Code"}
              onChangeText={(value) => {
                setData({
                  ...data,
                  bankSwiftCode: value,
                });
              }}
            />
          </Grid>
        ) : null}
      </Grid>

      <Text style={Styles.subTitle}>รายละเอียดข้อมูลบุคคลธรรมดา/บริษัท</Text>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Text style={Styles.titleText}>ชื่อ-นามสกุล/ชื่อบริษัท </Text>
          <View style={{ height: 8 }} />
          <TextField
            value={data.name}
            placeholder={"ชื่อ-นามสกุล/ชื่อบริษัท"}
            onChangeText={(value) => {
              setData({ ...data, name: value });
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Text style={Styles.titleText}>
            เลขบัตรประชาชน/เลขประจำตัวผู้เสียภาษี{" "}
          </Text>
          <View style={{ height: 8 }} />
          <TextField
            value={data.citizenId}
            placeholder={"เลขบัตรประชาชน/เลขประจำตัวผู้เสียภาษี"}
            onChangeText={(value) => {
              setData({
                ...data,
                citizenId: formatNumberString(value),
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Text style={Styles.titleText}>อีเมล </Text>
          <View style={{ height: 8 }} />
          <TextField
            value={data.email}
            placeholder={"อีเมล"}
            onChangeText={(value) => {
              setData({
                ...data,
                email: value,
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} style={{ zIndex: 20 }}>
          <Text style={Styles.titleText}>เบอร์โทรศัพท์ </Text>
          <View style={{ height: 8 }} />
          <View style={{ flexDirection: "row" }}>
            {" "}
            <View style={{ flex: 0.3 }}>
              <Dropdown
                value={data.phoneCode}
                valueType={"string"}
                placeholder={""}
                list={masterData && masterData.phoneCodeList}
                onChange={(values) => {
                  const { value } = values;
                  setData({
                    ...data,
                    phoneCode: value,
                  });
                }}
              />
            </View>
            <View style={{ width: 10 }} />
            <View style={{ flex: 0.7 }}>
              <TextField
                value={data.phoneNumber}
                placeholder={"ระบุเบอร์โทรศัพท์"}
                maxLength={String(data.phoneCode) === "66" ? 10 : null}
                onChangeText={(value) => {
                  setData({
                    ...data,
                    phoneNumber: formatNumberString(value),
                  });
                }}
              />
            </View>
          </View>
        </Grid>
        <Grid item xs={12} md={6} style={{ zIndex: 19 }}>
          <Text style={Styles.titleText}>ประเทศ (Country) </Text>
          <View style={{ height: 8 }} />

          <Dropdown
            value={data.countryCode}
            valueType={"string"}
            placeholder={"เลือกประเทศ (Country)"}
            list={masterData && masterData.countryList}
            onChange={(values) => {
              const { value } = values;
              setData({
                ...data,
                countryCode: value,
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Text style={Styles.titleText}>ที่อยู่ปัจจุบัน/ที่อยู่บริษัท </Text>
          <View style={{ height: 8 }} />
          <TextField
            value={data.address}
            placeholder={"ที่อยู่ปัจจุบัน/ที่อยู่บริษัท"}
            onChangeText={(value) => {
              setData({
                ...data,
                address: value,
              });
            }}
          />
        </Grid>
      </Grid>

      {data.countryCode === "THA" ? (
        <Grid container spacing={2} style={{ marginTop: 20 }}>
          <Grid item xs={12} md={6} style={{ zIndex: 18 }}>
            <Text style={Styles.titleText}>
              จังหวัด
              <Text style={{ color: "rgba(204, 0, 0, 1)" }}> *</Text>
            </Text>
            <View style={{ height: 8 }} />
            <Dropdown
              value={provinceList.length > 0 && data.provinceCode}
              valueType={"string"}
              placeholder={"เลือกจังหวัด"}
              list={provinceList}
              onChange={(values) => {
                const { value, label } = values;
                setData({
                  ...data,
                  provinceCode: value,
                  province: label,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} style={{ zIndex: 17 }}>
            <Text style={Styles.titleTextField}>
              เขต/อำเภอ
              <Text style={{ color: "rgba(204, 0, 0, 1)" }}> *</Text>
            </Text>
            <View style={{ height: 8 }} />
            <Dropdown
              disable={!data.provinceCode}
              value={districtList.length > 0 && data.districtCode}
              valueType={"string"}
              placeholder={"เลือกเขต/อำเภอ"}
              list={districtList}
              onChange={(values) => {
                const { value, label } = values;
                setData({
                  ...data,
                  districtCode: value,
                  district: label,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} style={{ zIndex: 16 }}>
            <Text style={Styles.titleTextField}>
              แขวง/ตำบล
              <Text style={{ color: "rgba(204, 0, 0, 1)" }}> *</Text>
            </Text>
            <View style={{ height: 8 }} />
            <Dropdown
              value={subDistrictList.length > 0 && data.subdistrictCode}
              valueType={"string"}
              disable={!data.districtCode}
              placeholder={"เลือกแขวง/ตำบล"}
              list={subDistrictList}
              onChange={(values) => {
                setData({
                  ...data,
                  subdistrictCode: values.value,
                  subdistrict: values.label,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} style={{ zIndex: 15 }}>
            <Text style={Styles.titleTextField}>
              รหัสไปรษณีย์
              <Text style={{ color: "rgba(204, 0, 0, 1)" }}> *</Text>
            </Text>
            <View style={{ height: 8 }} />
            <Dropdown
              value={zipcodeList.length > 0 && data.zipcode}
              valueType={"string"}
              disable={!data.districtCode || !data.subdistrictCode}
              placeholder={"เลือกรหัสไปรษณีย์"}
              list={zipcodeList}
              onChange={(values) => {
                const { value, label } = values;
                setData({ ...data, zipcode: value });
              }}
            />
          </Grid>
        </Grid>
      ) : null}
      <View style={{ marginTop: 20 }}>
        {/* <CheckBoxLabel
          value={data.active === "Y"}
          label={"ใช้เป็นที่อยู่เริ่มต้นสำหรับการทำเอกสารหัก ณ ที่จ่าย"}
          onCheck={(value) => {
            setData({
              ...data,
              active: value ? "Y" : "N",
            });
          }}
        /> */}
        <Dropdown
          value={data.active}
          valueType={"string"}
          list={addressList}
          onChange={(values) => {
            const { value } = values;
            setData({ ...data, active: value });
          }}
        />
      </View>
      <Grid container spacing={2} style={{ marginTop: 20 }}>
        <Grid xs={12} sm={12} md={2} />
        <Grid item xs={12} sm={12} md={4}>
          <Button
            buttonText={"ยกเลิก"}
            color={"primary"}
            style={{ width: "100%" }}
            isBorder={true}
            onClick={() => {
              onClose && onClose();
            }}
          />
          <View style={{ width: 20 }} />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Button
            buttonText={"ยืนยัน"}
            color={"primary"}
            style={{ width: "100%" }}
            onClick={() => {
              handleSubmit();
            }}
          />
        </Grid>
        <Grid xs={12} sm={12} md={2} />
      </Grid>
      <View style={{ height: 100 }} />
    </View>
  );
};

const Styles = {
  container: {
    flex: 1,
    height: "100vh",
    padding: "1.5%",
  },
  topContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  contentContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  textTopContainer1: {
    fontSize: "clamp(18px, 3vw, 22px)",
    fontWeight: "bold",
    color: "#AA4942",
  },
  textTopContainer2: {
    fontSize: "clamp(12px, 3vw, 16px)",
    color: "#696D79",
  },
  title: { fontSize: 20, fontWeight: "bold" },
  title2: { fontSize: 16, fontWeight: "bold" },
  checkBoxContainer: { marginTop: 20, flexDirection: "row" },
  subTitle: {
    fontSize: 16,
    fontWeight: "bold",
    marginTop: 20,
    marginBottom: 20,
  },
};
export default SettingAddProfile;

{
  /* <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text> */
}
