import apiPath from "../../configs/apiPath";
import { get } from "../../../functions/fetch/fetch";

export const downloadAllProducts = async () => {
  let url = apiPath.products.getAllProducts;
  let { status, response } = await get(url, {}, true);
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};

export const downloadAllProductsFromAgentCode = async (
  agentCode,
  countryCodeStore
) => {
  let url = apiPath.products.getAllProductsFromAgentCode;
  let { status, response } = await get(
    url,
    {
      agentCode: agentCode,
      countryCodeStore: countryCodeStore ? countryCodeStore : "",
    },
    true
  );
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};

export const downloadAllProductsListOrder = async (
  agentCodeSale,
  agentCodeBuyer,
  countryCodeStore
) => {
  let url = apiPath.products.getProductListOrder;
  let { status, response } = await get(
    url,
    {
      agentCodeSale: agentCodeSale ? agentCodeSale : "",
      agentCodeBuyer: agentCodeBuyer ? agentCodeBuyer : "",
      countryCodeStore: countryCodeStore ? countryCodeStore : "",
    },
    true
  );
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};

export const downloadAllProductsListRetail = async (
  agentCodeSale,
  agentCodeTransaction
) => {
  let url = apiPath.products.getProductListRetail;
  let { status, response } = await get(
    url,
    {
      agentCodeSale: agentCodeSale ? agentCodeSale : "",
      agentCodeTransaction: agentCodeTransaction ? agentCodeTransaction : "",
    },
    true
  );
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};

export const downloadProductInventory = async (countryCodeStore) => {
  let url = apiPath.products.getProductInventory;
  let { status, response } = await get(
    url,
    { countryCodeStore: countryCodeStore },
    true
  );
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};

export const downloadProductCategory = async () => {
  let url = apiPath.products.getAllProductCategory;
  let { status, response } = await get(url, {}, true);
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};

export const downloadProductType = async () => {
  let url = apiPath.products.getAllProductType;
  let { status, response } = await get(url, {}, true);
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};

export const downloadProductStatus = async () => {
  let url = apiPath.products.getAllProductStatus;
  let { status, response } = await get(url, {}, true);
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};
