import React, { useState, useRef, useEffect } from "react";
import { Text, View } from "react-native-web";
import LinearGradient from "react-native-web-linear-gradient";
import DropdownShadow from "../../components/dropdown/DropdownShadow";
//Images
import giftsale1 from "../../asset/images/home/salesContainer/giftsale1.png";
import apiPath from "../../services/configs/apiPath";
import { get } from "../../functions/fetch/fetch";
import { useDialog } from "../../components/modal/Popup";

const SalesContainer4 = (props) => {
  const dialog = useDialog();
  const [rewardInfo, setRewardInfo] = useState({
    point: 0,
    ticket_1: 0,
    ticket_2: 0,
  });
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const refBox = useRef();
  const [rankingInfo, setRankingInfo] = useState({});

  useEffect(() => {
    downloadPoint();
  }, []);

  useEffect(() => {
    if (refBox.current) {
      setWidth(refBox.current.offsetWidth / 1.2);
      setHeight(refBox.current.offsetHeight);
    }
  }, [refBox.current]);

  const downloadPoint = async () => {
    let url = apiPath.reward.remainingPointsInfo;
    let { status, response } = await get(url, {}, true);
    if (!status) {
      console.log(response.data.error);
    }
    if (status) {
      console.log("set date ", response.data);
      setRewardInfo(response.data);
    }
  };

  return (
    <View
      ref={refBox}
      style={Styles.linearContainer1}
      onLayout={(e) => {
        if (refBox.current) {
          setWidth(refBox.current.offsetWidth / 1.2);
        }
      }}
    >
      <View style={{ width: "100%", zIndex: 30 }}>
        <DropdownShadow
          disable={true}
          placeholder={"รางวัลที่แลกได้"}
          list={[]}
          onChange={(values) => {
            const { value, label } = values;
            console.log(value, label);
          }}
        />
      </View>

      <CircleFade width={width} height={height} />
      <View style={{ flex: 1 }} />
      <View
        style={{
          width: "100%",
          backgroundColor: "rgba(234, 133, 93, 1)",
          borderRadius: 8,
          justifyContent: "center",
          alignItems: "center",
          padding: 8,
          marginTop: 80,
        }}
      >
        <Text
          style={{
            color: "#FFFFFF",
            fontSize: "clamp(20px,3vh,24px)",
            fontWeight: "bold",
          }}
        >
          {rewardInfo
            ? Number(rewardInfo.point).toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })
            : ""}
        </Text>
      </View>
      <Text
        style={{
          fontWeight: "bold",
          color: "#FFFFFF",
          alignSelf: "flex-start",
          marginTop: 8,
        }}
      >
        {rewardInfo
          ? `สิทธิ์ที่เหลือ ${Number(rewardInfo.ticket_1).toLocaleString(
              undefined,
              {
                minimumFractionDigits: 0,
              }
            )} สิทธิ์`
          : ""}
      </Text>
    </View>
  );
};

const CircleFade = (props) => {
  const { width, height } = props;
  return (
    <View
      style={{
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 80,
      }}
    >
      <LinearGradient
        style={{
          width: width,
          height: width,
          borderRadius: "50%",
          position: "absolute",
          left: (width / 3.5) * -1,
        }}
        colors={[
          "rgba(236, 175, 162, 1)",
          "rgba(229, 129, 130, 0)",
          "rgba(229, 129, 130, 0)",
        ]}
        useAngle="true"
        angle={270}
      />
      <LinearGradient
        style={{
          width: width,
          height: width,
          borderRadius: "50%",
          position: "absolute",
          right: (width / 2.6) * -1,
          marginBottom: 50,
        }}
        colors={[
          "rgba(236, 175, 162, 1)",
          "rgba(229, 129, 130, 0)",
          "rgba(229, 129, 130, 0)",
        ]}
        useAngle="true"
        angle={110}
      />
      <img
        src={giftsale1}
        alt={"profile"}
        style={{ width: width / 1.4, maxWidth: 200, zIndex: 20 }}
      />
    </View>
  );
};

const Styles = {
  container: {
    margin: 0,
  },
  linearContainer1: {
    width: "100%",
    height: "100%",
    boxShadow: "16px 16px 24px 0px rgba(0, 0, 0, 0.04)",
    background: "linear-gradient(173.69deg, #FF7070 -1.06%, #FFA570 100.91%)",
    borderRadius: 24,
    alignItems: "center",
    justifyContent: "center",
    padding: 15,
    paddingBottom: 30,
    overflow: "hidden",
  },
  circle: {
    width: "clamp(90px,3vh,120px)",
    height: "clamp(90px,3vh,120px)",
    borderRadius: "50%",
    position: "absolute",
    background:
      "linear-gradient(105.71deg, #86EAA7 9.07%, rgba(134, 234, 167, 0) 51.52%)",
    opacity: 0.8,
  },
};
export default SalesContainer4;
