import React, { useState, useRef, useEffect } from "react";
import { Text, View } from "react-native-web";
import LinearGradient from "react-native-web-linear-gradient";
import DropdownShadow from "../../components/dropdown/DropdownShadow";
//Images
import noteSale1 from "../../asset/images/home/salesContainer/notesale1.png";
import { useDispatch, useSelector } from "react-redux";

const SalesContainer1 = (props) => {
  //property
  const dispatch = useDispatch();
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const refBox = useRef();
  const [typeSelect, setTypeSelect] = useState("retail");

  const summary = useSelector((state) => {
    if (!state.homePageData) {
      return null; // or some default value
    }
    return state.homePageData.summary;
  });

  useEffect(() => {
    if (refBox.current) {
      setWidth(refBox.current.offsetWidth / 1.2);
      setHeight(refBox.current.offsetHeight);
    }
  }, [refBox.current]);

  useEffect(() => {}, [summary]);

  return (
    <View
      ref={refBox}
      style={Styles.linearContainer1}
      onLayout={(e) => {
        if (refBox.current) {
          setWidth(refBox.current.offsetWidth / 1.2);
        }
      }}
    >
      <View style={{ width: "100%", zIndex: 30 }}>
        <DropdownShadow
          value={typeSelect}
          valueType={"string"}
          placeholder={"ยอดขายปลีก"}
          list={[
            { value: "retail", label: "ยอดขายปลีก" },
            { value: "order", label: "ยอดคำสั่งซื้อ" },
          ]}
          onChange={(values) => {
            const { value, label } = values;
            setTypeSelect(value);
            dispatch({ type: "SET/HOMEPAGE", payload: { selectType: value } });
          }}
        />
      </View>

      <CircleFade width={width} height={height} />

      <View style={{ flex: 1 }} />
      <View
        style={{
          width: "100%",
          backgroundColor: "rgba(97, 156, 168, 1)",
          borderRadius: 8,
          justifyContent: "center",
          alignItems: "center",
          padding: 8,
          marginTop: 80,
        }}
      >
        <Text
          style={{
            color: "#FFFFFF",
            fontSize: "clamp(20px,3vh,24px)",
            fontWeight: "bold",
          }}
        >
          {summary
            ? typeSelect === "retail"
              ? Number(summary.sumRetail).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })
              : Number(summary.sumOrder).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })
            : 0}
        </Text>
      </View>
      <Text
        style={{
          fontWeight: "bold",
          color: "#FFFFFF",
          alignSelf: "flex-start",
          marginTop: 8,
        }}
      >
        บาท
      </Text>
    </View>
  );
};

const CircleFade = (props) => {
  const { width, height } = props;
  return (
    <View
      style={{
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 80,
      }}
    >
      <LinearGradient
        style={{
          width: width,
          height: width,
          borderRadius: "50%",
          position: "absolute",
          left: (width / 3.5) * -1,
        }}
        colors={[
          "rgba(134, 234, 167, 1)",
          "rgba(134, 234, 167, 0)",
          "rgba(134, 234, 167, 0)",
        ]}
        useAngle="true"
        angle={270}
      />
      <LinearGradient
        style={{
          width: width,
          height: width,
          borderRadius: "50%",
          position: "absolute",
          right: (width / 2.6) * -1,
          marginBottom: 50,
        }}
        colors={[
          "rgba(134, 234, 167, 1)",
          "rgba(134, 234, 167, 0)",
          "rgba(134, 234, 167, 0)",
        ]}
        useAngle="true"
        angle={110}
      />
      <img
        src={noteSale1}
        alt={"profile"}
        style={{ width: width / 1.4, maxWidth: 200, zIndex: 20 }}
      />
    </View>
  );
};

const Styles = {
  container: {
    margin: 0,
  },
  linearContainer1: {
    width: "100%",
    height: "100%",
    boxShadow: "16px 16px 24px 0px rgba(0, 0, 0, 0.04)",
    background: "linear-gradient(174.58deg, #32E6D1 7.67%, #6197F4 154.88%)",
    borderRadius: 24,
    alignItems: "center",
    justifyContent: "center",
    padding: 15,
    paddingBottom: 30,
    overflow: "hidden",
  },
  circle: {
    width: "clamp(90px,3vh,120px)",
    height: "clamp(90px,3vh,120px)",
    borderRadius: "50%",
    position: "absolute",
    background:
      "linear-gradient(105.71deg, #86EAA7 9.07%, rgba(134, 234, 167, 0) 51.52%)",
    opacity: 0.8,
  },
};
export default SalesContainer1;
