import React, { useState, useEffect } from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import { Grid } from "@mui/material";
import { Divider } from "rsuite";
import { useNavigate } from "react-router-dom";
//component
import Button from "../../components/button/Button";
import Dropdown from "../../components/dropdown/Dropdown";
import SwitchMenu from "../../components/switch/SwitchMenu";
import SearchBox from "../../components/textField/SearchBox";
import TopicListContainer from "./component/TopicListContainer";
import CreateTopic from "./component/CreateTopic";

const ManagePage = () => {
  const navigate = useNavigate();

  const [createTopic, setCreateTopic] = useState(false);

  const addArticle = () => {
    setCreateTopic(true);
  };

  return (
    <React.Fragment>
      <View style={Styles.container}>
        <Grid container style={Styles.topContainer} spacing={2}>
          <Grid item xs={12} md={8} lg={9} xl={10}>
            <View style={{ flex: 1 }}>
              <Text style={Styles.textTopContainer1}>จัดการหน้าเว็บไซต์</Text>
              <Text style={Styles.textTopContainer2}>
                จัดการเว็บไซต์ / จัดการหน้าเว็บไซต์
              </Text>
            </View>
          </Grid>
          <Grid item xs={12} md={4} lg={3} xl={2}>
            <Button
              buttonText={"เพิ่มคลิปวิดีโอ"}
              color={"primary"}
              style={{ width: "100%" }}
              styleText={Styles.topButtonText}
              isBorder={false}
              onClick={() => {
                addArticle();
              }}
            />
          </Grid>
        </Grid>
        <View style={{ height: 30 }} />
        <View style={Styles.contentContainer}>
          <Grid container spacing={2} style={{ marginBottom: 15 }}>
            <Grid item xs={12} md={8} lg={6}>
              <SwitchMenu
                height={40}
                titles={["บทความ", "คลิปวิดิโอ"]}
                onSelect={(response) => {
                  const { title } = response;
                }}
                selectColor={{ text: "#AA4942", background: "#FFD7D7" }}
                unSelectColor={{ text: "#0F1011", background: "" }}
              />
            </Grid>
          </Grid>
          <Divider />

          {createTopic ? (
            <CreateTopic
              onClose={() => {
                setCreateTopic(false);
              }}
            />
          ) : (
            <TopicListContainer />
          )}
        </View>
      </View>
    </React.Fragment>
  );
};

const Styles = {
  container: {
    flex: 1,
    height: "100vh",
    padding: "1.5%",
  },
  topContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  contentContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  textTopContainer1: {
    fontSize: "clamp(18px, 3vw, 22px)",
    fontWeight: "bold",
    color: "#AA4942",
  },
  textTopContainer2: {
    fontSize: "clamp(12px, 3vw, 16px)",
    color: "#696D79",
  },
  topButtonText: {
    fontSize: "clamp(14px, 3vw, 18px)",
  },
  titleSearch: {
    fontSize: 20,
    color: "#0F1011",
  },
  subTitleSearch: {
    fontSize: 16,
    color: "#0F1011",
    marginTop: 8,
  },
};
export default ManagePage;
