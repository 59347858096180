import React, { useState, useEffect, useRef } from "react";
import { TouchableOpacity, Text, View } from "react-native-web";
import TimePickerContainer from "./TimePickerContainer";
//Icons
import calendarIcon from "../../asset/images/component/datepicker/calendar.png";
import moment from "moment-timezone";
import { BiTime } from "react-icons/bi";

const TimePicker = (props) => {
  const { placeholder, styles, time, disable } = props;
  const { onChange } = props;
  const [currentHour, setCurrentHour] = useState();
  const [currentMinute, setCurrentMinute] = useState();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  useEffect(() => {
    if (time) {
      const _time = time.split(":");
      if (_time.length === 2) {
        setCurrentHour(Number(_time[0]));
        setCurrentMinute(Number(_time[1]));
      }
    }
  }, [time]);

  const displayTime = () => {
    // Check if hour and minute are not null or undefined
    if (currentHour != null && currentMinute != null) {
      return `${currentHour < 10 ? `0${currentHour}` : currentHour}:${
        currentMinute < 10 ? `0${currentMinute}` : currentMinute
      }`;
    }
    return placeholder;
  };

  return (
    <React.Fragment>
      <View style={{ zIndex: 9998 }}>
        <TouchableOpacity
          activeOpacity={1}
          onPress={() => {
            if (disable) {
              return;
            }
            setIsPopoverOpen(true);
          }}
          style={{ ...Styles.button, ...styles }}
        >
          <Text
            style={{
              fontSize: 16,
              color:
                currentHour && currentMinute
                  ? "#0F1011"
                  : "rgba(138, 145, 161, 1)",
            }}
          >
            {displayTime()} {/* Use a function to get the display time */}
          </Text>
          <View style={{ flex: 1 }} />
          <BiTime style={{ fontSize: 20 }} />
        </TouchableOpacity>
        {isPopoverOpen ? (
          <View style={Styles.container}>
            <TimePickerContainer
              onSelect={(values) => {
                const { hour, minute } = values;
                setCurrentHour(hour);
                setCurrentMinute(minute);
                setIsPopoverOpen(false);
                onChange && onChange({ hour: hour, minute: minute });
              }}
              onClickOutside={() => {
                setIsPopoverOpen(false);
              }}
            />
          </View>
        ) : null}
      </View>
    </React.Fragment>
  );
};

const Styles = {
  container: {
    position: "absolute",
    right: 0,
    top: 60,
    width: "100%",
    maxWidth: 150,
    maxHeight: 320,
    borderRadius: 10,
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    zIndex: 99999,
  },
  button: {
    flexDirection: "row",
    width: "100%",
    height: 50,
    borderWidth: 1,
    borderColor: "#D5D7DD",
    borderRadius: 8,
    alignItems: "center",
    padding: 16,
  },
};

export default TimePicker;
