import React, { useEffect, useState } from "react";
import PopupBackdrop from "../../components/modal/PopupBackdrop";
import { View, Text, ScrollView } from "react-native-web";
import { Divider } from "rsuite";
import { Grid } from "@mui/material";
import SearchBox from "../../components/textField/SearchBox";
import Button from "../../components/button/Button";
//Images
import userMock from "../../asset/images/register/user.png";
import MoveTeamPathTable from "./component/MoveTeamPathTable";
import CloseButton from "../../components/button/CloseButton";
import apiPath from "../../services/configs/apiPath";
import { useDialog } from "../../components/modal/Popup";
import { get, post } from "../../functions/fetch/fetch";
import MoveTeamPathSearchTable from "./component/MoveTeamPathSearchTable";
import { useSelector } from "react-redux";
const MoveTeamPath = (props) => {
  //property
  const dialog = useDialog();
  const user = useSelector((state) => {
    return state.user;
  });
  const { agentProfile } = props;
  const { onClose } = props;
  //state
  const [search, setSearch] = useState("");
  const [findAgentList, setFindAgentList] = useState([]);
  const [transactionList, setTransactionList] = useState([]);
  const [moveTeamInfo, setMoveTeamInfo] = useState({
    agentCode: "",
    name: "",
    imageProfile: "",
  });

  useEffect(() => {
    if (agentProfile) {
      console.log("AgentProfile", agentProfile);
      downloadMoveTeamInfo(agentProfile.agentCode);
    }
  }, [agentProfile]);

  const downloadMoveTeamInfo = async (agentCode) => {
    if (!agentCode) {
      return;
    }
    let url = apiPath.member.getMoveTeamInfo;
    let { status, response } = await get(url, { agentCode: agentCode }, true);
    if (!status) {
      dialog.alert({ message: response.data.error });
      return;
    }
    if (response.data) {
      setMoveTeamInfo({ ...response.data });
      return;
    }
  };

  const searchAgent = async () => {
    if (!search || !agentProfile.agentCode) {
      return;
    }
    let url = apiPath.member.findAgent;
    let { status, response } = await get(
      url,
      { search: search, agentCode: agentProfile.agentCode },
      true
    );
    if (!status) {
      dialog.alert({ message: response.data.error });
      return;
    }

    if (response.data) {
      setFindAgentList(response.data);
    } else {
      setFindAgentList([]);
      dialog.alert({ message: "ไม่พบข้อมูล" });
    }
  };

  const handleSelect = async (values) => {
    let confirm = await dialog.confirm({
      title: `ยืนยันการย้ายทีม ${moveTeamInfo.name} ${moveTeamInfo.agentCode} \nไปแม่ทีม ${values.name} ${values.agentCode}`,
      message: "ยืนยันการย้ายทีม ใช่ หรือ ไม่ ?",
    });
    if (!confirm) {
      return;
    }

    let url = apiPath.member.moveTeam;
    let { status, response } = await post(
      url,
      { agentCode: agentProfile.agentCode, agentCodeMaster: values.agentCode },
      true
    );
    if (!status) {
      dialog.alert({
        message:
          response.data.error === "can't move team under path"
            ? "ไม่สามารถย้ายไปยังลูกทีมได้"
            : response.data.error,
      });

      return;
    }
    dialog.alert({ message: "สามารถย้ายทีมได้" });
  };

  return (
    <PopupBackdrop>
      <View style={Styles.container}>
        <View style={{ padding: 20, paddingBottom: 0 }}>
          <View style={Styles.headContainer}>
            <Text style={Styles.headText}>ขอย้ายทีมใหม่</Text>
            <CloseButton
              color={"rgba(15, 16, 17, 1)"}
              style={{ position: "absolute", top: 5, right: 5 }}
              onPress={() => {
                onClose && onClose();
              }}
            />
          </View>
          <Divider />
        </View>

        <ScrollView style={{ padding: 20, paddingTop: 0 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Text style={Styles.textTitle}>ข้อมูลตัวแทน</Text>​
              <View style={Styles.agentMasterContainer}>
                <img
                  src={
                    agentProfile && agentProfile.imageProfile
                      ? agentProfile.imageProfile
                      : userMock
                  }
                  style={Styles.imageProfile}
                  alt={"profile"}
                />
                <Text style={{ fontSize: 18, fontWeight: "bold" }}>
                  {agentProfile ? `ชื่อตัวแทน : ${agentProfile.name}` : ""}
                </Text>
                <Text style={{ fontSize: 17 }}>
                  {agentProfile ? `รหัสตัวแทน : ${agentProfile.agentCode}` : ""}
                </Text>
              </View>
            </Grid>
            <Grid item xs={12} md={6}>
              <Text style={Styles.textTitle}>แม่ทีมปัจจุบัน</Text>​
              <View style={Styles.agentMasterContainer}>
                <img
                  src={
                    moveTeamInfo.imageProfile
                      ? moveTeamInfo.imageProfile
                      : userMock
                  }
                  style={Styles.imageProfile}
                  alt={"profile"}
                />
                <Text style={{ fontSize: 18, fontWeight: "bold" }}>
                  {moveTeamInfo.name ? `ชื่อตัวแทน : ${moveTeamInfo.name}` : ""}
                </Text>
                <Text style={{ fontSize: 17 }}>
                  {moveTeamInfo.agentCode
                    ? `รหัสตัวแทน : ${moveTeamInfo.agentCode}`
                    : ""}
                </Text>
              </View>
            </Grid>
          </Grid>
          <View style={{ height: 20 }} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Text style={Styles.textTitle}>ขอเข้าสมัครทีมใหม่</Text>
              <View style={{ height: 10 }} />
              <View style={{ flexDirection: "row" }}>
                <SearchBox
                  value={search}
                  placeholder={"ค้นหา ชื่อ, รหัสตัวแทน, อีเมล"}
                  onChangeText={(text) => {
                    setSearch(text);
                  }}
                />
                <Button
                  buttonText={"ค้นหา"}
                  color={"primary"}
                  style={{ minWidth: 100, marginLeft: 20 }}
                  onClick={() => {
                    searchAgent();
                  }}
                />
              </View>
              <View style={{ flex: 1 }}>
                <MoveTeamPathSearchTable
                  tableData={findAgentList}
                  onSelect={(values) => {
                    handleSelect(values);
                  }}
                />
              </View>
            </Grid>
          </Grid>
          <View style={{ height: 20 }} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Text style={Styles.textTitle}>ประวัติการย้ายทีม</Text>​
            </Grid>
            <Grid item xs={12}>
              <View style={{ flex: 1 }}>
                <MoveTeamPathTable tableData={[]} />
              </View>
            </Grid>
          </Grid>
        </ScrollView>
      </View>
    </PopupBackdrop>
  );
};

const Styles = {
  container: {
    backgroundColor: "#FFFFFF",
    borderRadius: 20,
    width: "90vw",
    maxWidth: 1000,
    height: "90vh",
  },
  headContainer: {
    flexDirection: "row",
  },
  headText: {
    flex: 1,
    fontSize: 20,
    fontWeight: "bold",
  },
  textTitle: {
    fontSize: 18,
  },
  agentMasterContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 10,
  },
  imageProfile: { width: 100, borderRadius: "50%", margin: 20 },
};
export default MoveTeamPath;
