import React, { useEffect, useState } from "react";
import { View, Text, ScrollView, TouchableOpacity } from "react-native-web";
import { Divider } from "rsuite";
import { Grid } from "@mui/material";
import moment from "moment-timezone";
//component
import PopupBackdrop from "../../../components/modal/PopupBackdrop";
import CloseButton from "../../../components/button/CloseButton";
import Dropdown from "../../../components/dropdown/Dropdown";
import TextField from "../../../components/textField/TextField";
//image
import { useDialog } from "../../../components/modal/Popup";
import Button from "../../../components/button/Button";
import correctIcon from "../../../asset/images/icons/correct.png";

import {
  formatNumber,
  formatNumberString,
} from "../../../functions/format/numberFormat";
import { useSelector } from "react-redux";
import { register } from "../../../services/api/auth/user";

const EventRegisterPopup = (props) => {
  const dialog = useDialog();
  const { user } = useSelector((state) => {
    return state;
  });
  const { data = {} } = props;
  const { onClose } = props;
  const [eventJoinType, setEventJoinType] = useState([
    { label: "เข้าร่วมด้วยตัวเอง (คนเดียว)", value: "single" },
    { label: "เข้าร่วมด้วยรหัสคู่ (คนเดียว)", value: "double" },
    { label: "เข้าร่วมด้วยตัวเองกับรหัสคู่", value: "multiple" },
  ]);

  const [listFood, setListFood] = useState([]);
  const [registerData, setRegisterData] = useState({
    join_type: "",
    note: "",
    events_id: null,
    nick_name: "",
    full_name: "",
    people_amount: 0, // กรณีมีผู้ติดตามให้ระบุ 2
    tel_context: "",
    email: "",
    redeem_event_followers_request: [], // กรณีที่ไม่มีผู้ติดตามให้ส่ง array เปล่า
    required_foods: [],
  });

  useEffect(() => {
    if (data) {
      let init = [];
      data.list_food.forEach((item) => {
        init.push({
          label: item,
          value: item,
        });
      });
      setListFood(init);
    }
  }, [data]);

  useEffect(() => {
    if (user) {
      console.log("found user", user);
      setRegisterData({
        ...registerData,
        agent_code: user.agentCode,
        full_name: user.name,
        nick_name: user.nickName,
        email: user.email,
        tel_context: user.phoneNumber,
        ranking_code: user.currentRankingCode,
      });
    } else {
      console.log("not found user");
    }
  }, [user]);

  const validation = () => {
    const requiredFields = [
      "people_amount",
      "full_name",
      "nick_name",
      "email",
      "tel_context",
    ];
    let errors = requiredFields.filter((field) => {
      if (field.includes(".")) {
        const keys = field.split(".");
        return !registerData[keys[0]][keys[1]];
      }
      return !registerData[field];
    });
    if (requiredFields.length === 0) {
      errors.push("requiredFields");
    }
    let status = true;
    if (errors.length > 0) {
      status = false;
    }
    if (registerData.required_foods.length === 0) {
      status = false;
    }
    if (registerData.redeem_event_followers_request.length > 0) {
      registerData.redeem_event_followers_request.forEach((item) => {
        if (!item.full_name || item.required_foods.length === 0) {
          status = false;
        }
      });
    }
    return status;
  };

  const submit = async () => {
    if (!validation()) {
      dialog.alert({ message: "กรุณากรอกข้อมูลให้ครบถ้วน" });
      console.log(registerData);
      return;
    }
    registerData.events_id = data.event_id;
    //ชำระเงิน
    console.log("ชำระเงิน");
    let paymentRes = await dialog.payment({
      paymentMethodCode: "qrcode",
      data: {
        ...registerData,
        paymentFor: "redeemEvent",
        paymentMethodCode: "qrcode",
      },
    });
    if (!paymentRes.close) {
      return;
    }
    if (
      paymentRes.status !== null &&
      paymentRes.status !== undefined &&
      paymentRes.status === false
    ) {
      dialog.alert({
        message: paymentRes.timeout
          ? `หมดเวลาชำระเงิน`
          : "การชำระเงินไม่สำเร็จ",
      });
      return;
    }

    await dialog.alert({
      title: "ลงทะเบียนเข้าร่วมอีเวนท์สำเร็จ",
      message:
        "เมื่อได้รับการอนุมัติการเข้าร่วมอีเวนท์เรียบร้อยแล้ว คุณจะได้รับ QR Code สำหรับเข้าร่วมอีเวนท์ในอีเมลที่ได้ระบุไว้",
      topIcon: (
        <img
          src={correctIcon}
          style={{ width: 48, height: 48, marginBottom: 24 }}
          alt={"icon"}
        />
      ),
    });
    onClose && onClose();
  };

  return (
    <PopupBackdrop>
      <View style={Styles.box}>
        <View>
          <Text style={Styles.textTitle}>ลงทะเบียนอีเวนท์</Text>
          <CloseButton
            color={"rgba(15, 16, 17, 1)"}
            style={{ position: "absolute", top: 5, right: 5 }}
            onPress={() => {
              onClose && onClose();
            }}
          />
        </View>
        <Divider />
        <ScrollView style={{ padding: 5 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <img
                src={data.event_image ? data.event_image : ""}
                alt={"reward"}
                style={{ width: "100%", borderRadius: 20 }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              style={{
                paddingLeft: 30,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <View style={Styles.containerText}>
                <GetRewardDisplayRow
                  label={"ชื่ออีเวนท์"}
                  value={data.event_name ? data.event_name : ""}
                />
                <GetRewardDisplayRow
                  label={"วันที่จัดอีเวนท์"}
                  value={
                    data.event_start_time_date && data.event_end_time_date
                      ? `${moment(data.event_start_time_date).format(
                          "DD/MM/YYYY"
                        )} - ${moment(data.event_end_time_date).format(
                          "DD/MM/YYYY"
                        )}`
                      : ""
                  }
                />
                <GetRewardDisplayRow
                  label={"เวลา"}
                  value={
                    data.event_start_time_date && data.event_end_time_date
                      ? `${moment(data.event_start_time_date).format(
                          "HH:mm"
                        )} - ${moment(data.event_end_time_date).format(
                          "HH:mm"
                        )}`
                      : ""
                  }
                />
                <GetRewardDisplayRow
                  label={"จำนวนพอยท์/ค่าเข้างาน"}
                  value={
                    data.event_price
                      ? `${Number(data.event_price).toLocaleString()} ${
                          data.event_type_id === 1 ? "บาท" : "Points"
                        }`
                      : ""
                  }
                />
                <GetRewardDisplayRow
                  label={"เบอร์โทรศัพท์ติดต่อ"}
                  value={
                    data.event_telephone_contact
                      ? data.event_telephone_contact
                      : ""
                  }
                />
                <GetRewardDisplayRow
                  label={"สถานที่จัดอีเวนท์"}
                  value={data.event_location ? data.event_location : ""}
                />
                <GetRewardDisplayRow
                  label={"รายละเอียดอีเวนท์"}
                  value={data.event_description ? data.event_description : ""}
                />
                <GetRewardDisplayRow
                  label={"อาหารภายในอีเวนท์"}
                  value={data.event_list_food ? data.event_list_food : ""}
                />
                <GetRewardDisplayRow
                  label={"ระดับตัวแทนที่เข้าได้"}
                  value={
                    data.qualify_rank_list_modal
                      ? data.qualify_rank_list_modal
                          .map((item) => item.name)
                          .join(", ")
                      : ""
                  }
                />
              </View>
            </Grid>
          </Grid>
          <View style={{ height: 20 }} />
          <Text style={Styles.textTitle}>กรอกข้อมูลผู้เข้าร่วมอีเวนท์</Text>
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} style={{ zIndex: 20 }}>
              <Text style={Styles.titleText}>
                ผู้เข้าร่วมอีเวนท์{" "}
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />

              <Dropdown
                value={registerData.join_type ? registerData.join_type : ""}
                valueType={"string"}
                placeholder={"เลือกผู้เข้าร่วมอีเวนท์"}
                list={eventJoinType}
                onChange={(values) => {
                  const { value, label } = values;
                  console.log("value", value);
                  let number =
                    value === "multiple"
                      ? 0
                      : value === "single"
                      ? 1
                      : value === "double"
                      ? 2
                      : 0;
                  let newValue = {
                    join_type: value,
                    people_amount: number,
                  };
                  if (value !== "multiple") {
                    newValue.redeem_event_followers_request = Array(
                      number - 1
                    ).fill({
                      full_name: "",
                      note: "",
                      required_foods: [],
                    });
                  } else {
                    newValue.redeem_event_followers_request = [];
                  }
                  console.log("new Value", newValue);
                  setRegisterData({
                    ...registerData,
                    ...newValue,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ zIndex: 19 }}>
              <Text style={Styles.titleText}>
                จำนวนผู้เข้าร่วมอีเวนท์{" "}
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />
              <TextField
                disable={registerData.join_type !== "multiple"}
                value={
                  registerData.people_amount ? registerData.people_amount : ""
                }
                placeholder={"ระบุจำนวนผู้เข้าร่วมอีเวนท์"}
                errorMessage={""}
                onChangeText={(value) =>
                  setRegisterData({
                    ...registerData,
                    people_amount: formatNumber(value),
                    redeem_event_followers_request: Array(
                      formatNumber(value) - 1
                    ).fill({
                      full_name: "",
                      note: "",
                      required_foods: [],
                    }),
                  })
                }
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ zIndex: 18 }}>
              <Text style={Styles.titleText}>
                ระดับตัวแทน{" "}
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />
              <TextField
                value={
                  registerData.ranking_code ? registerData.ranking_code : ""
                }
                disable={true}
                iconName={""}
                isInvalid={false}
                placeholder={"ระดับตัวแทน"}
                errorMessage={""}
                onChangeText={(value) => {}}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ zIndex: 17 }}>
              <Text style={Styles.titleText}>
                รหัสตัวแทน{" "}
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />

              <TextField
                value={registerData.agent_code ? registerData.agent_code : ""}
                iconName={""}
                disable={true}
                isInvalid={false}
                placeholder={"ระบุรหัสตัวแทน"}
                errorMessage={""}
                onChangeText={(value) => {}}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ zIndex: 16 }}>
              <Text style={Styles.titleText}>
                ชื่อ - นามสกุล{" "}
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />

              <TextField
                value={registerData.full_name ? registerData.full_name : ""}
                disable={true}
                iconName={""}
                isInvalid={false}
                placeholder={"ระบุชื่อ - นามสกุล"}
                errorMessage={""}
                onChangeText={(value) => {
                  setRegisterData({
                    ...registerData,
                    full_name: value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ zIndex: 15 }}>
              <Text style={Styles.titleText}>
                ชื่อเล่น <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />

              <TextField
                value={registerData.nick_name ? registerData.nick_name : ""}
                placeholder={"ระบุชื่อเล่น"}
                errorMessage={""}
                onChangeText={(value) => {
                  setRegisterData({
                    ...registerData,
                    nick_name: value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ zIndex: 14 }}>
              <Text style={Styles.titleText}>
                อีเมล <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />

              <TextField
                value={registerData.email ? registerData.email : ""}
                iconName={""}
                isInvalid={false}
                placeholder={"ระบุอีเมล"}
                errorMessage={""}
                onChangeText={(value) => {
                  setRegisterData({
                    ...registerData,
                    email: value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ zIndex: 13 }}>
              <Text style={Styles.titleText}>
                เบอร์โทรศัพท์{" "}
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />

              <TextField
                value={registerData.tel_context ? registerData.tel_context : ""}
                iconName={""}
                isInvalid={false}
                placeholder={"ระบุเบอร์โทรศัพท์"}
                errorMessage={""}
                onChangeText={(value) => {
                  setRegisterData({
                    ...registerData,
                    tel_context: formatNumberString(value),
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ zIndex: 11 }}>
              <Text style={Styles.titleText}>
                อาหารในอีเวนท์{" "}
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />

              <Dropdown
                value={
                  registerData.required_foods
                    ? registerData.required_foods[0]
                    : ""
                }
                valueType={"string"}
                placeholder={"เลือกอาหารในอีเวนท์"}
                list={listFood}
                onChange={(values) => {
                  setRegisterData({
                    ...registerData,
                    required_foods: [values.value],
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} style={{ zIndex: 10 }}>
              <Text style={Styles.titleText}>หมายเหตุ </Text>
              <View style={{ height: 8 }} />
              <TextField
                value={registerData.note ? registerData.note : ""}
                iconName={""}
                isInvalid={false}
                placeholder={"ระบุหมายเหตุ"}
                errorMessage={""}
                onChangeText={(value) => {
                  setRegisterData({
                    ...registerData,
                    note: value,
                  });
                }}
              />
              <View style={{ height: 8 }} />
              <Text style={{ fontSize: 14, color: "rgba(105, 109, 121, 1)" }}>
                {" "}
                เช่น แพ้อาหาร รับประทานมังสวิรัส เป็นต้น
              </Text>
            </Grid>
            <FollwerEvent
              data={registerData.redeem_event_followers_request}
              foods={listFood}
              onChange={(values) => {
                setRegisterData({
                  ...registerData,
                  redeem_event_followers_request: values,
                });
              }}
            />
            <Grid
              item
              xs={12}
              style={{ marginTop: 10, marginBottom: 20, zIndex: 9 }}
            >
              <View>
                <Text style={{ fontSize: 18, fontWeight: "bold" }}>
                  การชำระค่าเข้าอีเวนท์
                </Text>
                <Text style={Styles.remarkText}>
                  1.กดปุ่ม “แสดง QR Code” เพื่อรับ QR Code
                </Text>
                <Text style={Styles.remarkText}>
                  2.ใช้แอป Mobile Banking เลือกเมนูสแกน
                </Text>
                <Text style={Styles.remarkText}>
                  3.สแกน QR Code ตรวจสอบยอด และกดชำระเงิน
                </Text>
                <Text style={Styles.remarkText}>
                  4.หากทำการชำระเงิน และขึ้นสถานะ ”สำเร็จ”
                  แล้วระบบจะพาคุณกลับหน้า ‘อีเวนท์’ อัตโนมัติ
                </Text>
              </View>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={0} md={1}></Grid>

              <Grid item xs={12} md={5}>
                <Button
                  buttonText={"ยกเลิก"}
                  color={"primary"}
                  isBorder={true}
                  style={{ width: "100%" }}
                  onClick={() => {
                    onClose && onClose();
                  }}
                />
              </Grid>
              <Grid item xs={0} md={0}></Grid>
              <Grid item xs={12} md={5}>
                <Button
                  buttonText={"แสดง QR Code"}
                  color={"primary"}
                  style={{ width: "100%" }}
                  onClick={() => {
                    submit();
                  }}
                />
              </Grid>
              <Grid item xs={0} md={1}></Grid>
            </Grid>
          </Grid>
        </ScrollView>
      </View>
    </PopupBackdrop>
  );
};

const GetRewardDisplayRow = (props) => {
  const { label, value } = props;
  return (
    <View style={{ flexDirection: "row", marginBottom: 10 }}>
      <Text style={{ flex: 0.4, color: "rgba(105, 109, 121, 1)" }}>
        {label}
      </Text>
      <Text
        style={{ flex: 0.6, color: "rgba(15, 16, 17, 1)", fontWeight: "bold" }}
      >
        {value}
      </Text>
    </View>
  );
};

const FollwerEvent = (props) => {
  const { data, foods } = props;
  const { onChange } = props;

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Text style={Styles.textTitle}>กรอกข้อมูลผู้เข้าร่วมอีเวนท์</Text>
        <Divider />
      </Grid>
      {data &&
        data.map((item, index) => {
          return (
            <React.Fragment>
              <Grid item xs={12} md={6}>
                <Text style={Styles.titleText}>
                  ชื่อ - นามสกุล{" "}
                  <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
                </Text>
                <View style={{ height: 8 }} />

                <TextField
                  value={item.full_name ? item.full_name : ""}
                  placeholder={"ระบุชื่อ - นามสกุล"}
                  errorMessage={""}
                  onChangeText={(value) => {
                    let init = JSON.parse(JSON.stringify(data)); // Create a deep copy of the data array
                    init[index].full_name = value;
                    onChange(init);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Text style={Styles.titleText}>
                  อาหารในอีเวนท์{" "}
                  <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
                </Text>
                <View style={{ height: 8 }} />

                <Dropdown
                  value={item.required_foods ? item.required_foods[0] : ""}
                  valueType={"string"}
                  placeholder={"เลือกอาหารในอีเวนท์"}
                  list={foods}
                  onChange={(values) => {
                    const { value } = values;
                    let init = [...data];
                    init[index].required_foods = [value];
                    onChange(init);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Text style={Styles.titleText}>หมายเหตุ </Text>
                <View style={{ height: 8 }} />

                <TextField
                  value={item.note ? item.note : ""}
                  placeholder={"ระบุหมายเหตุ"}
                  errorMessage={""}
                  onChangeText={(value) => {
                    let init = [...data];
                    init[index].note = value;
                    onChange(init);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} />
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </React.Fragment>
          );
        })}
    </React.Fragment>
  );
};
const Styles = {
  box: {
    width: "90vw",
    maxHeight: "90vh",
    maxWidth: 1164,
    padding: 20,
    backgroundColor: "#FFF",
    borderRadius: 16,
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.08,
    shadowRadius: 16,
  },
  textTitle: {
    fontSize: 18,
    fontWeight: "bold",
    color: "rgba(15, 16, 17, 1)",
  },
  containerText: {
    padding: 20,
    backgroundColor: "rgba(248, 248, 248, 1)",
    borderRadius: 16,
  },
  remarkText: { fontSize: 14, color: "rgba(105, 109, 121, 1)" },
};

export default EventRegisterPopup;
