import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity, ScrollView } from "react-native-web";
import { Grid } from "@mui/material";
import { Divider } from "rsuite";
import { useNavigate, useParams } from "react-router-dom";
import apiPath from "../../services/configs/apiPath";
import { get } from "../../functions/fetch/fetch";
import { useDialog } from "../../components/modal/Popup";

//component
//Images
import { IoIosArrowBack } from "react-icons/io";

const NewsContentPage = () => {
  const navigate = useNavigate();
  const dialog = useDialog();
  const { id } = useParams();
  //state
  const [content, setContent] = useState({});
  const [html, setHtml] = useState({});
  useEffect(() => {
    if (id) {
      donwloadContent();
    }
  }, [id]);

  const donwloadContent = async () => {
    let url = apiPath.news.getNewsDetail;

    let { status, response } = await get(url, { id: id }, true);
    if (!status) {
      dialog.alert({ message: response.data.error });
      return;
    }
    if (response.data) {
      setContent(response.data);
      setHtml(response.data.Content);
    }
  };

  return (
    <React.Fragment>
      <View style={Styles.container}>
        <Grid container style={Styles.topContainer} spacing={2}>
          <Grid item xs={12} md={8} lg={9} xl={10}>
            <View style={{ flex: 1 }}>
              <Text style={Styles.textTopContainer1}>หน้าหลัก</Text>
              <Text style={Styles.textTopContainer2}>
                หน้าหลัก/ ข้อมูลของคุณ / ข่าวสารจาก Rosegold
              </Text>
            </View>
          </Grid>
        </Grid>
        <View style={{ height: 30 }} />
        <View style={Styles.contentContainer}>
          <View style={Styles.topLeftContainer}>
            <TouchableOpacity
              onPress={() => {
                navigate(-1);
              }}
            >
              <IoIosArrowBack style={Styles.iconSelect} />
            </TouchableOpacity>
            <View style={{ width: 8 }} />
            <View>
              <Text style={Styles.textHeaderLeft}>
                {content ? content.Title : ""}
              </Text>
              <Text style={Styles.subTextHeaderLeft}>
                โดย Rosegold Thailand
              </Text>
            </View>
          </View>
          <Divider />
          <ScrollView>
            <View>
              <div
                style={{ flex: 1 }}
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </View>
          </ScrollView>
        </View>
      </View>
    </React.Fragment>
  );
};

const Styles = {
  container: {
    flex: 1,
    height: "100vh",
    padding: "1.5%",
  },
  topContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  contentContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  textTopContainer1: {
    fontSize: "clamp(18px, 3vw, 22px)",
    fontWeight: "bold",
    color: "#AA4942",
  },
  textTopContainer2: {
    fontSize: "clamp(12px, 3vw, 16px)",
    color: "#696D79",
  },
  topButtonText: {
    fontSize: "clamp(14px, 3vw, 18px)",
  },
  titleSearch: {
    fontSize: 20,
    color: "#0F1011",
  },
  subTitleSearch: {
    fontSize: 16,
    color: "#0F1011",
    marginTop: 8,
  },
  topLeftContainer: {
    flex: 1,
    flexDirection: "row",
    marginBottom: 20,
  },
  textHeaderLeft: {
    fontSize: 16,
    fontWeight: "bold",
    color: "rgba(15, 16, 17, 1)",
  },
  subTextHeaderLeft: {
    fontSize: 14,
    color: "rgba(138, 145, 161, 1)",
    marginTop: 5,
  },
  topRightContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  icon: {
    fontSize: 22,
    color: "rgba(138, 145, 161, 1)",
  },
  iconSelect: {
    fontSize: 22,
    color: "rgba(170, 73, 66, 1)",
  },
};
export default NewsContentPage;
