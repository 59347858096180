import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";

export const getConfig = () => {
  const firebaseConfigTemplate = {
    apiKey: null,
    authDomain: null,
    databaseURL: null,
    projectId: null,
    storageBucket: null,
    messagingSenderId: null,
    appId: null,
  };

  const firebaseConfig = {};

  Object.keys(firebaseConfigTemplate).forEach((item) => {
    firebaseConfig[item] = process.env[`REACT_APP_FIREBASE_${item}`];
  });

  return firebaseConfig;
};

const app = initializeApp(getConfig());

export const authentication = getAuth(app);

export const database = getDatabase(app);

/*export const database = () => {
  const app = initializeApp(getConfig());

  const db = getDatabase(app);
  set(ref(db, "users/"), {
    username: "Hello",
  });
  push(ref(db, "Test/"), {
    arf: 1,
    ye: 2,
  });
};*/
