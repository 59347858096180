import React, { useState, useEffect } from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import { Grid } from "@mui/material";
import { Divider } from "rsuite";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import * as XLSX from "xlsx";

//component
import Button from "../../components/button/Button";
import Dropdown from "../../components/dropdown/Dropdown";
import DatePicker from "../../components/calendar/DatePicker";
import ReportProductFlowTable from "./component/ReportProductFlowTable";
import SummaryBoxReport from "./component/SummaryBoxReport";
import { useDialog } from "../../components/modal/Popup";
import {
  getProductStockOrderCount,
  getProductStockRetailCount,
  getProductStockTransactionReport,
} from "../../services/api/report/stock";

const ReportProductFlow = () => {
  //property
  const navigate = useNavigate();
  const dialog = useDialog();
  //state
  const [isLoading, setIsLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [orderCount, setOrderCount] = useState({});
  const [retailCount, setRetailCount] = useState({});

  useEffect(() => {
    downloadAllStock();
  }, []);

  const downloadAllStock = async (props) => {
    dialog.loading();

    let res = await getProductStockTransactionReport();
    if (!res) {
      dialog.cancle();
      return;
    }

    let orderRes = await getProductStockOrderCount();
    if (orderRes) {
      setOrderCount(orderRes);
    }

    let retailRes = await getProductStockRetailCount();
    if (retailRes) {
      setRetailCount(retailRes);
    }
    console.log("orderRes", orderRes);
    console.log("retailRes", retailRes);

    setOrders(res);
    dialog.cancle();
    setIsLoading(false);
  };

  const downloadExcel = async () => {
    let newOrders = [];
    orders.forEach((item) => {
      newOrders.push({
        "วันที่/เวลา": moment(item.date).format("DD/MM/YYYY HH:mm"),
        เลขที่อ้างอิง: item.transactionCode,
        รายละเอียด: item.description,
        จำนวน: item.quantity,
        สถานะสินค้า: item.type === "ADD" ? "นำเข้า" : "ขายออก",
      });
    });

    const worksheet = XLSX.utils.json_to_sheet(newOrders);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(
      workbook,
      `รายงานการเคลื่อนไหวสินค้า_${moment().format("DD_MM_YYYY_HHmmss")}.xlsx`
    );
  };

  return (
    <React.Fragment>
      <View style={Styles.container}>
        <Grid container style={Styles.topContainer} spacing={2}>
          <Grid item xs={12} md={8} lg={9} xl={10}>
            <View style={{ flex: 1 }}>
              <Text style={Styles.textTopContainer1}>
                รายงานการเคลื่อนไหวสินค้า
              </Text>
              <Text style={Styles.textTopContainer2}>
                รายงาน / รายงานการเคลื่อนไหวสินค้า
              </Text>
            </View>
          </Grid>
          <Grid item xs={12} md={4} lg={3} xl={2}>
            <Button
              buttonText={"ดาวน์โหลดไฟล์ Excel"}
              color={"primary"}
              style={{ width: "100%" }}
              styleText={Styles.topButtonText}
              isBorder={true}
              onClick={() => {
                downloadExcel();
              }}
            />
          </Grid>
        </Grid>
        <View style={{ height: 30 }} />
        <View style={Styles.contentContainer}>
          <Grid container spacing={2} style={{ marginBottom: 15 }}>
            <Grid item xs={12} lg={4} style={{ zIndex: 7 }}>
              <View style={{ height: 8 }} />
              <DatePicker placeholder="วันที่" styles={{ width: "100%" }} />
            </Grid>

            <Grid item xs={12} lg={4} style={{ zIndex: 10 }}>
              <View style={{ height: 8 }} />
              <Dropdown
                placeholder={"ข้อมูลตัวแทน"}
                list={[]}
                onChange={(values) => {
                  const { value, label } = values;
                  console.log(value, label);
                }}
              />
            </Grid>

            <Grid item xs={12} lg={2}>
              <View style={{ height: 8 }} />
              <Button
                buttonText={"ค้นหา"}
                color={"primary"}
                style={{ width: "100%" }}
                styleText={Styles.topButtonText}
                onClick={() => {}}
              />
            </Grid>
            <Grid item xs={12} lg={4} style={{ zIndex: 9 }}>
              <View style={{ height: 8 }} />
              <Dropdown
                placeholder={"สินค้า"}
                list={[]}
                onChange={(values) => {
                  const { value, label } = values;
                  console.log(value, label);
                }}
              />
            </Grid>
          </Grid>
          <Divider />
          {false && (
            <Text style={Styles.titleSearch}>
              รายงานคำสั่งซื้อ วันที่ 10/9/2022 - 20/9/2022
            </Text>
          )}
          {/* <Text style={Styles.subTitleSearch}>ผลการค้นหา 50 รายการ</Text> */}
          <View style={{ flex: 1 }}>
            <ReportProductFlowTable tableData={orders} />
          </View>
          <View style={{ height: 30 }} />
          <Text style={Styles.titleSubMenu}>
            รายละเอียดการเคลื่อนไหวสินค้านำเข้า
          </Text>
          <Divider />
          <SummaryBoxReport
            subData={[
              {
                label: "คำสั่งซื้อ",
                value:
                  orderCount.orderCount !== undefined &&
                  orderCount.orderCount !== null
                    ? Number(orderCount.orderCount).toLocaleString()
                    : 0,
                labelValue: "รายการ",
              },
              {
                label: "จำนวนสินค้านำเข้า",
                value:
                  orderCount.productCount !== undefined &&
                  orderCount.productCount !== null
                    ? Number(orderCount.productCount).toLocaleString()
                    : 0,
                labelValue: "ชิ้น",
              },
            ]}
            sumData={[
              {
                label: "ยอดรวมทั้งหมด",
                value:
                  orderCount.productCount !== undefined &&
                  orderCount.productCount !== null
                    ? Number(orderCount.productCount).toLocaleString()
                    : 0,
                labelValue: "ชิ้น",
              },
            ]}
          />
          <View style={{ height: 30 }} />

          <Text style={Styles.titleSubMenu}>
            รายละเอียดการเคลื่อนไหวสินค้าขายออก
          </Text>
          <Divider />
          <SummaryBoxReport
            subData={[
              {
                label: "ขายปลีก",
                value:
                  retailCount.retailCount !== undefined &&
                  retailCount.retailCount !== null
                    ? Number(retailCount.retailCount).toLocaleString()
                    : 0,
                labelValue: "ชิ้น",
              },
              {
                label: "คำสั่งซื้อ",
                value:
                  retailCount.orderCount !== undefined &&
                  retailCount.orderCount !== null
                    ? Number(retailCount.orderCount).toLocaleString()
                    : 0,
                labelValue: "ชิ้น",
              },
            ]}
            sumData={[
              {
                label: "ยอดรวมทั้งหมด",
                value:
                  retailCount.totalCount !== undefined && retailCount !== null
                    ? Number(retailCount.totalCount).toLocaleString()
                    : 0,
                labelValue: "ชิ้น",
              },
            ]}
          />
        </View>
      </View>
    </React.Fragment>
  );
};

const Styles = {
  container: {
    flex: 1,
    height: "100vh",
    padding: "1.5%",
  },
  topContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  contentContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  textTopContainer1: {
    fontSize: "clamp(18px, 3vw, 22px)",
    fontWeight: "bold",
    color: "#AA4942",
  },
  textTopContainer2: {
    fontSize: "clamp(12px, 3vw, 16px)",
    color: "#696D79",
  },
  topButtonText: {
    fontSize: "clamp(14px, 3vw, 18px)",
  },
  titleSearch: {
    fontSize: 20,
    color: "#0F1011",
  },
  subTitleSearch: {
    fontSize: 16,
    color: "#0F1011",
    marginTop: 8,
  },
  titleSubMenu: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#0F1011",
  },
};
export default ReportProductFlow;
