import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import { Grid, Typography } from "@mui/material";
import { Divider } from "rsuite";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
//component
import Button from "../../components/button/Button";
import Dropdown from "../../components/dropdown/Dropdown";
import SearchBox from "../../components/textField/SearchBox";
import CourseSection from "./component/CourseSection";
import { get, post } from "../../functions/fetch/fetch";
import apiPath from "../../services/configs/apiPath";
import { useDialog } from "../../components/modal/Popup";
//Images
import { IoIosArrowBack } from "react-icons/io";
import ReactPlayer from "react-player";
import AddCourse from "./AddCourse";

const Course = () => {
  const navigate = useNavigate();
  const dialog = useDialog();
  const { user } = useSelector((state) => {
    return state;
  });
  //state
  const [list, setList] = useState([]);
  const [rankingList, setRankingList] = useState([]);
  const [content, setContent] = useState();
  const [isEdit, setIsEdit] = useState();
  const [search, setSearch] = useState({
    rankLevel: null,
    courseName: "",
  });

  useEffect(() => {
    downloadUniversityList();
    downloadAllRankingList();
  }, []);

  const downloadUniversityList = async () => {
    let url = apiPath.university.getListUniversity;
    let { status, response } = await get(url, {}, true);
    if (!status) {
      await dialog.alert({ message: "เกิดข้อผิดพลาด" });
      return;
    }
    if (response.data) {
      setList(response.data);
    }
  };

  const downloadContent = async (id, isEdit = false) => {
    let url = `${apiPath.university.getDetailUniversity}?id=${id}`;
    let { status, response } = await get(url, {}, true);
    console.log(response);

    if (!status) {
      dialog.alert({ message: "เกิดข้อผิดพลาด" });
      return false;
    }
    if (response.data) {
      if (isEdit) {
        setIsEdit(response.data);
      } else {
        setContent(response.data);
      }
    }
    return true;
  };

  const downloadAllRankingList = async () => {
    let url = apiPath.member.getAllRankingList;
    let { status, response } = await get(url, {}, true);
    if (!status) {
      dialog.alert({ message: "เกิดข้อผิดพลาด" });
    }
    if (response.data) {
      let val = response.data;
      let init = [];
      let qualify_rank = [];
      val.forEach((element) => {
        init.push({
          value: element.id,
          label: element.name,
        });
        qualify_rank.push(element.level);
      });
      setRankingList(init);
    }
  };

  const getTitle = () => {
    if (isEdit) {
      return "/ แก้ไขคอร์สเรียน";
    }
    if (content) {
      return `/ ${content.class_name}`;
    }
    return "";
  };

  const handleOption = async (values) => {
    const { option, data } = values;
    if (option === "delete") {
      let status = await dialog.confirm({
        title: `ยืนยันการลบ ${data.class_name}`,
        message: `คุณต้องการลบเนื้อหา ${data.class_name} ใช่ หรือ ไม่ ?`,
      });

      if (status) {
        let url = apiPath.university.deleteUniversity;
        console.log(data.id);
        let { status, response } = await post(url, { id: data.id }, true);
        console.log(response);
        if (!status) {
          dialog.alert({ message: "เกิดข้อผิดพลาด" });
          return false;
        }
        if (response.data) {
          await dialog.alert({ message: "ลบข้อมูลบสำเร็จ" });
          await downloadUniversityList();
        }
      }
      return;
    }

    if (option === "edit") {
      dialog.loading();
      let status = await downloadContent(data.id, true);
      if (status) {
        dialog.cancle();
      }
    }
  };

  return (
    <React.Fragment>
      {isEdit ? (
        <AddCourse
          isEdit={true}
          isEditData={isEdit}
          onEditBack={() => {
            setIsEdit(null);
          }}
          onEditSuccess={() => {
            setIsEdit(null);
            window.location.reload();
          }}
        />
      ) : (
        <View style={Styles.container}>
          <Grid container style={Styles.topContainer} spacing={2}>
            <Grid item xs={12} md={8} lg={9} xl={10}>
              <View style={{ flex: 1 }}>
                <Text style={Styles.textTopContainer1}>คอร์สเรียน</Text>
                <Text style={Styles.textTopContainer2}>
                  Rosegold university / คอร์สเรียน {getTitle()}
                </Text>
              </View>
            </Grid>
            {user && user.userRole !== "A" ? (
              <Grid item xs={12} md={4} lg={3} xl={2}>
                <Button
                  buttonText={"เพิ่มคอร์สเรียน"}
                  color={"primary"}
                  style={{ width: "100%" }}
                  styleText={Styles.topButtonText}
                  onClick={() => {
                    if (!user) {
                      return;
                    }
                    if (user.userRole !== "A") {
                      navigate("/addCourse");
                    }
                  }}
                />
              </Grid>
            ) : null}
          </Grid>
          <View style={{ height: 30 }} />
          <View style={Styles.contentContainer}>
            {content ? (
              <ContentView
                data={content}
                onBack={() => {
                  setContent(null);
                }}
              />
            ) : (
              <ListView
                rankingList={rankingList}
                list={list}
                search={search}
                onSelect={async (data) => {
                  dialog.loading();
                  let status = await downloadContent(data.id);
                  if (status) {
                    dialog.cancle();
                  }
                }}
                onOption={(values) => {
                  handleOption(values);
                }}
                onSearch={(values) => {
                  setSearch(values);
                }}
                onSearchEnter={() => {
                  console.log(search);
                }}
              />
            )}
          </View>
        </View>
      )}
    </React.Fragment>
  );
};

const ContentView = (props) => {
  const { data } = props;
  const { onBack } = props;
  const playerRef = React.useRef(null);
  const [totalTime, setTotalTime] = useState({
    isReady: false,
    duration: 0,
  });

  const [loading, setLoading] = useState(true);
  const [hasFetchedTime, setHasFetchedTime] = useState(false);

  const downloadCurrentTimeVdo = async () => {
    if (hasFetchedTime) {
      return;
    }
    setLoading(true); // set loading to true at the start of the function
    let url = apiPath.university.getStampVdo;
    let { status, response } = await post(url, { id: data.id }, true);
    if (status && response.data) {
      setTotalTime({
        isReady: true,
        duration: response.data,
      });
      if (playerRef.current) {
        playerRef.current.seekTo(response.data, "seconds"); // Uncomment this if needed
      }
    }
    setHasFetchedTime(true);
    setLoading(false); // set loading to false at the end of the function
  };
  const Styles = {
    container: {
      flex: 1,
    },
    headerContainer: {
      flexDirection: "row",
    },
    topLeftContainer: {
      flex: 1,
      flexDirection: "row",
    },
    textHeaderLeft: {
      fontSize: 16,
      fontWeight: "bold",
      color: "rgba(15, 16, 17, 1)",
    },
    subTextHeaderLeft: {
      fontSize: 14,
      color: "rgba(138, 145, 161, 1)",
      marginTop: 5,
    },
    topRightContainer: {
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    icon: {
      fontSize: 22,
      color: "rgba(138, 145, 161, 1)",
    },
    iconSelect: {
      fontSize: 22,
      color: "rgba(170, 73, 66, 1)",
    },
    textDescTitle: {
      fontSize: 15,
      fontWeight: "bold",
      color: "rgba(15, 16, 17, 1)",
    },
  };

  function handleProgress(state) {
    console.log("handleProgress");
    if (!loading) {
      // check if not loading before proceeding
      handleStampVdo(state.playedSeconds);
    }
  }

  const handleStampVdo = async (duration) => {
    let url = `${apiPath.university.stampVdo}`;
    let params = {
      id: data.id,
      duration_time: duration,
    };
    console.log(params);
    let { status, response } = await post(
      url,
      {
        id: data.id,
        duration_time: duration,
      },
      true
    );
    if (!status) {
      return;
    }
    if (response.data) {
      console.log(response);
    }
  };

  return (
    <View>
      <View style={Styles.topLeftContainer}>
        <TouchableOpacity
          onPress={() => {
            onBack && onBack();
          }}
        >
          <IoIosArrowBack style={Styles.iconSelect} />
        </TouchableOpacity>
        <View style={{ width: 8 }} />
        <View>
          <Text style={Styles.textHeaderLeft}>
            {data ? data.class_name : ""}
          </Text>
          <Text style={Styles.subTextHeaderLeft}>โดย Rosegold Thailand</Text>
        </View>
      </View>
      <Divider />

      <View
        style={{
          width: "100%",
          maxHeight: 600,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ReactPlayer
          ref={playerRef}
          url={data.link_video ? data.link_video : ""}
          controls={true}
          width={"100%"}
          height="100%"
          onReady={downloadCurrentTimeVdo} // set onReady to downloadCurrentTimeVdo
          onProgress={handleProgress}
          progressInterval={1000} // interval in ms
        />
      </View>
      <View style={{ height: 30 }} />
      <Text style={Styles.textDescTitle}>รายละเอียด</Text>
      <View style={{ height: 10 }} />
      <Typography>{data ? data.detail : ""}</Typography>
    </View>
  );
};

const ListView = (props) => {
  const { list, rankingList, search } = props;
  const { onSelect, onOption, onSearch, onSearchEnter } = props;

  return (
    <React.Fragment>
      <Grid container spacing={2} style={{ marginBottom: 15 }}>
        <Grid item xs={12} lg={4} style={{ zIndex: 10 }}>
          <View style={{ height: 8 }} />
          <Dropdown
            value={search.rankLevel}
            placeholder={"ระดับตัวแทน"}
            list={rankingList}
            onChange={(values) => {
              const { value, label } = values;
              onSearch &&
                onSearch({
                  ...search,
                  rankLevel: value,
                });
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4} style={{ zIndex: 10 }}>
          <View style={{ height: 8 }} />
          <SearchBox
            value={search.courseName}
            placeholder={"คอร์สเรียน"}
            showIcon={true}
            onChangeText={(value) => {
              onSearch &&
                onSearch({
                  ...search,
                  courseName: value,
                });
            }}
          />
        </Grid>

        <Grid item xs={12} lg={2}>
          <View style={{ height: 8 }} />
          <Button
            buttonText={"ค้นหา"}
            color={"primary"}
            style={{ width: "100%" }}
            styleText={Styles.topButtonText}
            onClick={() => {
              onSearchEnter && onSearchEnter();
            }}
          />
        </Grid>
      </Grid>
      <Divider />
      <CourseSection
        data={list}
        onSelect={(data) => {
          onSelect && onSelect(data);
        }}
        onOption={(values) => {
          onOption && onOption(values);
        }}
      />
    </React.Fragment>
  );
};

const Styles = {
  container: {
    flex: 1,
    height: "100vh",
    padding: "1.5%",
  },
  topContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  contentContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  textTopContainer1: {
    fontSize: "clamp(18px, 3vw, 22px)",
    fontWeight: "bold",
    color: "#AA4942",
  },
  textTopContainer2: {
    fontSize: "clamp(12px, 3vw, 16px)",
    color: "#696D79",
  },
  topButtonText: {
    fontSize: "clamp(14px, 3vw, 18px)",
  },
  titleSearch: {
    fontSize: 20,
    color: "#0F1011",
  },
  subTitleSearch: {
    fontSize: 16,
    color: "#0F1011",
    marginTop: 8,
  },
};
export default Course;
