import React, { useEffect, useState } from "react";
import { Text, View } from "react-native-web";
import { Grid } from "@mui/material";
import { Divider } from "rsuite";
//component
import Button from "../../components/button/Button";
import SearchBox from "../../components/textField/SearchBox";
import DatePicker from "../../components/calendar/DatePicker";
import Dropdown from "../../components/dropdown/Dropdown";
import SwitchMenu from "../../components/switch/SwitchMenu";
import SwitchMenuIcon from "../../components/switch/SwitchMenuIcon";

//images
import {
  CartIcon,
  BoxIcon,
  TruckIcon,
  CorrectLineCircleIcon,
  CashIcon,
  ReturnIcon,
} from "../../asset/images/icons/iconsLib";
import ShippingTable from "./component/ShippingTable";
import {
  getTrackingStatus,
  getTrackingStatusCount,
} from "../../services/api/transport/transport";
import { useDialog } from "../../components/modal/Popup";

const OrderMyList = (props) => {
  const dialog = useDialog();
  //state
  const [trackingStatus, setTrackingStatus] = useState("fulfillment");
  const [trackingCount, setTrackingCount] = useState();
  const [orderList, setOrderList] = useState([]);
  const [searchList, setSearchList] = useState([]);
  //search
  const [search, setSearch] = useState({
    wording: "",
  });

  useEffect(() => {
    downloadTrackingStatusCount();
    downloadTrackingStatusList(trackingStatus);
  }, []);

  useEffect(() => {
    downloadTrackingStatusList(trackingStatus);
  }, [trackingStatus]);

  useEffect(() => {
    if (search) {
      filterSearchData(orderList);
    }
  }, [search]);

  const downloadTrackingStatusList = async (status) => {
    if (!status) {
      return;
    }
    let res = await getTrackingStatus(status);
    console.log(res);
    if (!res) {
      setOrderList([]);
      return;
    }
    setOrderList(res);
  };

  const filterSearchData = async (data) => {
    let searchWord = search.wording
      ? data.filter((d) => {
          const searchTermLower = search.wording.toLowerCase();
          return (
            d.trackingCode.toLowerCase().includes(searchTermLower) ||
            d.retailCode.toLowerCase().includes(searchTermLower) ||
            d.sendingName.toLowerCase().includes(searchTermLower) ||
            d.customerName.toLowerCase().includes(searchTermLower) ||
            d.phoneNumber.toLowerCase().includes(searchTermLower)
          );
        })
      : [];
    console.log("filterSearchData", searchWord);
    setSearchList(searchWord);
  };

  const downloadTrackingStatusCount = async () => {
    let res = await getTrackingStatusCount();
    if (!res) {
      setTrackingCount(null);
      return;
    }
    setTrackingCount(res);
  };

  return (
    <React.Fragment>
      <View style={Styles.container}>
        <Grid container style={Styles.topContainer} spacing={2}>
          <Grid item xs={12} md={8} lg={9} xl={10}>
            <View style={{ flex: 1 }}>
              <Text style={Styles.textTopContainer1}>จัดส่งสินค้า</Text>
              <Text style={Styles.textTopContainer2}>จัดส่งสินค้า</Text>
            </View>
          </Grid>
        </Grid>
        <View style={{ height: 30 }} />

        <View style={Styles.contentContainer}>
          <Grid container spacing={2} style={{ marginBottom: 15 }}>
            <Grid item xs={12}>
              <SwitchMenuIcon
                height={40}
                minWidth={200}
                list={[
                  {
                    label: "พร้อมจัดส่ง",
                    value: "fulfillment",
                    badget: `(${
                      trackingCount ? trackingCount.fulfillment : ""
                    })`,
                    iconSelect: <CartIcon color={"#AA4942"} />,
                    iconDeselect: <CartIcon color={"#0F1011"} />,
                  },
                  {
                    label: "กำลังแพ็ค",
                    value: "booking",
                    badget: `(${trackingCount ? trackingCount.booking : ""})`,
                    iconSelect: <BoxIcon color={"#AA4942"} />,
                    iconDeselect: <BoxIcon color={"#0F1011"} />,
                  },
                  {
                    label: "กำลังจัดส่ง",
                    value: "shipping",
                    badget: `(${trackingCount ? trackingCount.shipping : ""})`,
                    iconSelect: <TruckIcon color={"#AA4942"} />,
                    iconDeselect: <TruckIcon color={"#0F1011"} />,
                  },
                  {
                    label: "จัดส่งสำเร็จ",
                    value: "complete",
                    badget: `(${trackingCount ? trackingCount.complete : ""})`,
                    iconSelect: <CorrectLineCircleIcon color={"#AA4942"} />,
                    iconDeselect: <CorrectLineCircleIcon color={"#0F1011"} />,
                  },
                  {
                    label: "การโอน COD",
                    value: "cod",
                    badget: `(${trackingCount ? trackingCount.transferred : ""})`,
                    iconSelect: <CashIcon color={"#AA4942"} />,
                    iconDeselect: <CashIcon color={"#0F1011"} />,
                  },
                  {
                    label: "ส่งของคืน",
                    value: "return",
                    badget: `(${trackingCount ? trackingCount.return : ""})`,
                    iconSelect: <ReturnIcon color={"#AA4942"} />,
                    iconDeselect: <ReturnIcon color={"#0F1011"} />,
                  },
                ]}
                onSelect={(response) => {
                  const { title } = response;
                  setTrackingStatus(title.value);
                }}
                selectColor={{ text: "#AA4942", background: "#FFD7D7" }}
                unSelectColor={{ text: "#0F1011", background: "" }}
              />
            </Grid>
          </Grid>
          <View style={{ height: 10 }} />
          <Grid container spacing={2}>
            <Grid item xs={12} md={8} lg={9}>
              <View style={{ flex: 1 }}>
                <SearchBox
                  placeholder={"ค้นหารายการ"}
                  value={search.wording}
                  onChangeText={(text) => {
                    setSearch({ ...search, wording: text });
                  }}
                />
              </View>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <Button
                buttonText={"ค้นหา"}
                color={"primary"}
                style={{ width: "100%" }}
                styleText={Styles.topButtonText}
                onClick={() => {}}
              />
            </Grid>
          </Grid>
          <Divider />
          <View style={{ flex: 1 }}>
            <ShippingTable
              tableData={
                search.wording
                  ? searchList
                  : orderList.length > 0
                  ? orderList
                  : []
              }
            />
          </View>
        </View>
      </View>
    </React.Fragment>
  );
};

const Styles = {
  container: {
    flex: 1,
    height: "100vh",
    padding: "1.5%",
  },
  topContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  contentContainer: {
    width: "100%",
    flex: 1,
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  textTopContainer1: {
    fontSize: "clamp(18px, 3vw, 22px)",
    fontWeight: "bold",
    color: "#AA4942",
  },
  textTopContainer2: {
    fontSize: "clamp(12px, 3vw, 16px)",
    color: "#696D79",
  },
  topButtonText: {
    fontSize: "clamp(14px, 3vw, 18px)",
  },
};

export default OrderMyList;
