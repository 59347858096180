import React, { useState, useContext, useCallback, useEffect } from "react";
//Component
import PopupMessage from "./PopupMessage";
import PopupConfirm from "./PopupConfirm";
import Loading from "./Loading";
//Another
import PopupPayment from "../../containers/order/component/PaymentPopup";
import PopupHead from "./HeadPopup";

const PopupContext = React.createContext();
export function useDialog() {
  return useContext(PopupContext);
}

export function PopupContextProvider({ children }) {
  const [portal, setPortal] = useState(null);
  const [parentPortal, setParentPortal] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingParent, setIsLoadingParent] = useState(false);
  const alert = useCallback(async (values) => {
    return new Promise((resolve) => {
      const resolveClosePopup = () => {
        if (!values.forceOpen) {
          resolve(true);
          setPortal(null);
        }
      };
      setPortal(
        <PopupMessage
          {...values}
          onConfirm={() => resolveClosePopup()}
          onReject={() => resolveClosePopup()}
        />
      );
    });
  }, []);

  const alertParent = useCallback(async (values) => {
    return new Promise((resolve) => {
      const resolveClosePopup = () => {
        resolve(true);
        setParentPortal(null);
      };
      setParentPortal(
        <PopupMessage
          {...values}
          onConfirm={() => resolveClosePopup()}
          onReject={() => resolveClosePopup()}
        />
      );
    });
  }, []);

  const confirm = useCallback(async (values) => {
    return new Promise((resolve) => {
      const resolveClosePopup = (status) => {
        resolve(status);
        setPortal(null);
      };
      setPortal(
        <PopupConfirm
          {...values}
          onConfirm={() => resolveClosePopup(true)}
          onCancle={() => resolveClosePopup(false)}
        />
      );
    });
  }, []);

  const loading = useCallback((values) => {
    return new Promise((resolve) => {
      if (!isLoading) {
        setIsLoading(true);
        setPortal(<Loading />);
      }
    });
  }, []);

  const loadingParent = useCallback((values) => {
    return new Promise((resolve) => {
      if (!isLoadingParent) {
        setIsLoadingParent(true);
        setParentPortal(<Loading />);
      }
    });
  }, []);

  const cancleParent = useCallback((values) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        setParentPortal(null);
        setIsLoadingParent(false);
      }, 200);
    });
  }, []);

  const cancle = useCallback((values) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        setPortal(null);
        setIsLoading(false);
      }, 200);
    });
  }, []);

  const payment = useCallback(async (values) => {
    return new Promise((resolve) => {
      const resolveClosePopup = (values) => {
        resolve(values);
      };
      setPortal(
        <PopupPayment
          {...values}
          onClose={() => {
            setPortal(null);
          }}
          onSuccess={() => {
            resolveClosePopup({ close: true, status: true, timeout: false });
          }}
          onFail={() => {
            resolveClosePopup({ close: true, status: false, timeout: false });
          }}
          onTimeout={() => {
            resolveClosePopup({ close: true, status: false, timeout: true });
          }}
        />
      );
    });
  }, []);

  const alertTag = useCallback((values) => {
    return new Promise((resolve) => {
      const resolveClosePopup = (values) => {
        resolve(true);
        setParentPortal(null);
      };
      if (!isLoadingParent) {
        setParentPortal(
          <PopupHead
            {...values}
            onPress={() => {
              resolveClosePopup();
            }}
          />
        );
      }
    });
  }, []);

  return (
    <PopupContext.Provider
      value={{
        alert,
        alertParent,
        confirm,
        loading,
        cancle,
        payment,
        loadingParent,
        cancleParent,
        alertTag,
      }}
    >
      {children}
      {portal}
      {parentPortal}
    </PopupContext.Provider>
  );
}
