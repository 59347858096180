import React, { useState } from "react";
import { View, Text } from "react-native-web";
import { Grid, Divider } from "@mui/material";
//component
import SearchBox from "../../../components/textField/SearchBox";
import DatePicker from "../../../components/calendar/DatePicker";
import Button from "../../../components/button/Button";
import AgentListTable from "./AgentListTable";
import { useDialog } from "../../../components/modal/Popup";
import apiPath from "../../../services/configs/apiPath";
import { post, put } from "../../../functions/fetch/fetch";
//Component
import MoveTeamPath from "../MoveTeamPath";

const AgentListTableDisplay = (props) => {
  //property
  const dialog = useDialog();
  const { data } = props;
  const { onRefresh } = props;

  const [moveTeam, setMoveTeam] = useState(false);

  const handleOption = async (values) => {
    if (!values) {
      return;
    }
    const { data, option } = values;

    if (option === "activeUser" || option === "inActiveUser") {
      handleActiveUser(data, option);
      return;
    }
    if (option === "edit") {
      handleEdit(data);
    }
    if (option === "delete") {
      handleDelete(data);
    }

    if (option === "moveTeam") {
      setMoveTeam({ visible: true, ...data });
    }
  };

  const handleActiveUser = async (data, option) => {
    if (!data) {
      return;
    }
    let pop = await dialog.confirm({
      title: `ยืนยันการ${
        option === "activeUser" ? "เปิดสิทธิการใช้งาน" : "ระงับสิทธิการใช้งาน"
      } ${data.name} ${data.agentCode}`,
      message: `${
        option === "activeUser" ? "เปิดสิทธิการใช้งาน" : "ระงับสิทธิการใช้งาน"
      }ผู้ใช้งาน ${data.name} ${data.agentCode} `,
    });
    if (!pop) {
      return;
    }
    let url = apiPath.member.updateAgentActiveStatus;
    let { status } = await put(
      url,
      { id: data.id, active: option === "activeUser" ? "Y" : "N" },
      true
    );
    if (!status) {
      dialog.alert({ message: "ไม่สามารถเปลี่ยนสถานะได้" });
      return;
    }
    await dialog.alert({ message: "เปลี่ยนสถานะสำเร็จ" });
    onRefresh && onRefresh();
  };

  const handleEdit = async (data) => {
    if (!data) {
      return;
    }
  };

  const handleDelete = async (data) => {
    if (!data) {
      return;
    }
    let pop = await dialog.confirm({
      title: `ยืนยันการลบ ${data.name} ${data.agentCode}`,
      message: `ผู้ใช้งาน ${data.name} ${data.agentCode} จะไม่สามารถใช้งานได้อีก และไม่สามารถเปิดใช้งานบัญชีนี้ได้อีกต่อไป`,
    });
    if (!pop) {
      return;
    }
    let url = apiPath.member.updateAgentActiveStatus;
    let { status } = await put(url, { id: data.id, active: "D" }, true);
    if (!status) {
      dialog.alert({ message: "ไม่สามารถลบผู้ใช้งานได้" });
      return;
    }
    dialog.alert({ message: "ลบผู้ใช้งานสำเร็จ" });
    onRefresh && onRefresh();
  };

  return (
    <View style={{ flex: 1 }}>
      {moveTeam.visible && (
        <MoveTeamPath
          agentProfile={moveTeam}
          onClose={() => {
            setMoveTeam({ ...moveTeam, visible: false });
          }}
        />
      )}
      <View style={{ height: 30 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          <SearchBox
            placeholder={"ค้นหารหัสตัวแทน ชื่อ นามสกุล เลขบัตรประชาชน"}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <DatePicker
            placeholder="วันที่ลงทะเบียน"
            styles={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} md={2} lg={2}>
          <Button
            buttonText={"ค้นหา"}
            color={"primary"}
            style={{ width: "100%" }}
            styleText={Styles.topButtonText}
            onClick={() => {}}
          />
        </Grid>
        <Grid item xs={12} style={{ marginTop: 10 }}>
          <Divider />
        </Grid>
      </Grid>
      <View style={{ flex: 1 }}>
        <AgentListTable
          tableData={data}
          onSelect={(values) => {
            handleOption(values);
          }}
        />
      </View>
    </View>
  );
};

const Styles = {
  topButtonText: {
    fontSize: "clamp(14px, 3vw, 18px)",
  },
};

export default AgentListTableDisplay;
