import React, { useEffect, useState } from "react";
import { View, Text } from "react-native-web";
import { Grid } from "@mui/material";
//images
import profileImg from "../../asset/images/home/agentInfo/mockProfileAgent.png";
import apiPath from "../../services/configs/apiPath";
import { get } from "../../functions/fetch/fetch";
import moment from "moment-timezone";
import noImage from "../../asset/images/register/user.png";
import EmptyList from "../../components/pages/EmptyList";
import "moment/locale/th";

const formatThaiDate = (date) => {
  return moment(date).locale("th");
};
const AgentInfoHome = (props) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    downloadTeamLoginLog();
  }, []);

  const downloadTeamLoginLog = async () => {
    let url = apiPath.member.getTeamLoginLog;
    let { status, response } = await get(url, {}, true);
    if (!status) {
      return;
    }
    if (response.data) {
      setData(response.data);
    }
  };
  return (
    <Grid item xs={12} md={6}>
      <View style={Styles.container}>
        <View style={{ flex: 1 }}>
          <View style={{ padding: 20 }}>
            <Text style={Styles.textTitle}>ข้อมูลลูกทีมทั้งสายงาน</Text>
            <View style={{ height: 20 }} />
            <View style={{ flexDirection: "row" }}>
              <Text style={Styles.textHeader}>ระดับ</Text>
              <Text style={Styles.textHeader}>จำนวน</Text>
            </View>
          </View>
          <View style={{ flex: 1 }}>
            {data.length > 0 ? (
              data.map((item, index) => {
                return <ContentRow data={item} bgColor={index % 2 === 0} />;
              })
            ) : (
              <EmptyList />
            )}
          </View>
        </View>
        <View style={{ height: 10 }} />
      </View>
    </Grid>
  );
};

const ContentRow = (props) => {
  const { bgColor, data } = props;
  return (
    <View
      style={{
        flexDirection: "row",
        backgroundColor: bgColor ? "rgba(204, 0, 0, 0.1)" : "",
        padding: 20,
        alignItems: "center",
      }}
    >
      <View style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>
        <img
          src={data.imageProfile ? data.imageProfile : noImage}
          alt={"icon"}
          style={{ width: 45, borderRadius: "50%" }}
        />
        <View style={{ width: 25 }} />
        <View>
          <Text
            style={{
              fontSize: "clamp(12px, 2vw, 16px)",
              fontWeight: "bold",
              color: "rgba(105, 109, 121, 1)",
            }}
          >
            {data
              ? `${data.agentName} ${
                  data.agentNickName ? `(${data.agentNickName})` : ""
                }`
              : ""}
          </Text>
          <Text
            style={{
              fontSize: "clamp(12px, 2vw, 16px)",
              fontWeight: "bold",
              color: "rgba(105, 109, 121, 1)",
            }}
          >
            {data ? data.agentCode : ""}
          </Text>
        </View>
      </View>
      <View style={{ flex: 1 }}>
        <Text
          style={{
            fontSize: "clamp(12px, 2vw, 16px)",
            color: "rgba(105, 109, 121, 1)",
          }}
        >
          {data ? formatThaiDate(data.createDate).fromNow() : ""}
        </Text>
      </View>
    </View>
  );
};

const Styles = {
  container: {
    width: "100%",
    height: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    overflow: "hidden",
  },
  textTitle: {
    fontSize: "clamp(18px, 2vw, 22px)",
    fontWeight: "bold",
    color: "rgba(15, 16, 17, 1)",
  },
  textHeader: {
    flex: 1,
    fontSize: "clamp(12px, 2vw, 16px)",
    fontWeight: "bold",
    color: "rgba(15, 16, 17, 1)",
  },
};

export default AgentInfoHome;
