import React, { useEffect, useState } from "react";
import { Text, View } from "react-native-web";
import { Grid } from "@mui/material";
import { Divider } from "rsuite";
//component
import Button from "../../components/button/Button";
import TextField from "../../components/textField/TextField";
import TextArea from "../../components/textField/TextArea";
import Dropdown from "../../components/dropdown/Dropdown";
import TextFieldList from "../../components/textField/TextFieldList";
import UploadImages from "../../components/uploadbox/UploadImages";
import CheckBoxList from "../../components/checkbox/CheckBoxList";
import ProductDiscountBox from "./component/ProductDiscountBox";
import SearchProductLink from "./component/SearchProductLink";
//images
//functions
import {
  formatDecimal,
  formatNumber,
} from "../../functions/format/numberFormat";
import { get, post, upload } from "../../functions/fetch/fetch";
import { useDialog } from "../../components/modal/Popup";
//services
import apiPath from "../../services/configs/apiPath";
import {
  downloadProductCategory,
  downloadProductType,
  downloadProductStatus,
  downloadAllProducts,
} from "../../services/api/products/products";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ProductAddNew = (props) => {
  //property
  const dialog = useDialog();
  const navigate = useNavigate();
  const { isEdit, editData } = props;
  const { onClose } = props;
  const { setupData } = useSelector((state) => {
    return state;
  });
  //state
  const [productCategoryList, setProductCategoryList] = useState([]);
  const [productTypeList, setProductTypeList] = useState([]);
  const [productStatusList, setProductStatusList] = useState([]);
  const [productList, setProductList] = useState([]);

  const [productData, setProductData] = useState({
    code: null,
    name: null,
    price: 0,
    point: 0,
    detail: null,
    prominentPoint: null,
    images: [],
    productCategoryId: null,
    productTypeId: null,
    productStatusId: null,
    remark: null,
    createBy: "",
    productRelation: [],
    discountList: [],
    countryCode: null,
    currencyCode: null,
  });
  const data = {
    files: [],
    maxUploadImages: 5,
  };

  useEffect(() => {
    const downloadAllData = async () => {
      let categoryList = await downloadProductCategory();
      let initCategoryList = [];
      categoryList.forEach((item) => {
        initCategoryList.push({ value: item.id, label: item.name });
      });
      setProductCategoryList(initCategoryList);
      console.log("pass catergory");
      //
      let typeList = await downloadProductType();
      let initTypeList = [];
      typeList.forEach((item) => {
        initTypeList.push({ value: item.id, label: item.name });
      });
      setProductTypeList(initTypeList);
      console.log("pass type");
      //
      let statusList = await downloadProductStatus();
      let initStatusList = [];
      statusList.forEach((item) => {
        initStatusList.push({ value: item.id, label: item.name });
      });
      setProductStatusList(initStatusList);
      console.log("pass status");
      let productsList = await downloadAllProducts();
      let initProducts = [];
      productsList.forEach((item) => {
        initProducts.push(item);
      });
      setProductList(initProducts);
      console.log("pass list");
    };
    downloadAllData();
  }, []);

  useEffect(() => {
    if (
      productTypeList.length > 0 &&
      productCategoryList.length > 0 &&
      productStatusList.length > 0
    ) {
      dialog.cancle();
      if (isEdit) {
        setProductData(editData);
      }
    } else {
      dialog.loading();
    }
  }, [
    editData,
    productTypeList,
    productCategoryList,
    productStatusList,
    productList,
  ]);

  useEffect(() => {
    if (productData.countryCode) {
      //download currency code
      downloadCurrencyCode(productData.countryCode);
    }
  }, [productData.countryCode]);

  const downloadCurrencyCode = async (countryCode) => {
    console.log("download Curreny Code", countryCode);
    let { response } = await get(
      apiPath.masterData.getCurrencyCode,
      { countryCode: countryCode },
      false
    );
    console.log(response);
    if (response.data) {
      console.log("set ", response.data);
      setProductData({
        ...productData,
        currencyCode: response.data,
      });
    }
  };

  const validation = async () => {
    let checkBasePriceShopping = productData.discountList.filter((d) => {
      return d.quantity === 1 && d.typeOfDiscount === "shopping";
    }).length;
    if (checkBasePriceShopping === 0) {
      await dialog.alert({
        title: "แจ้งเตือน",
        message: `กรุณากำหนดราคาสินค้า 1 ชิ้น ประเภทขายปลีก`,
      });
      return false;
    } else if (checkBasePriceShopping > 1) {
      await dialog.alert({
        title: "แจ้งเตือน",
        message:
          "กรุณากำหนดราคาสินค้า 1 ชิ้น ประเภทขายปลีกได้เพียง 1 รายการเท่านั้น",
      });
      return false;
    }
    let checkBasePriceOrder = productData.discountList.filter((d) => {
      return d.quantity === 1 && d.typeOfDiscount === "order";
    }).length;
    if (checkBasePriceOrder === 0) {
      await dialog.alert({
        title: "แจ้งเตือน",
        message: `กรุณากำหนดราคาสินค้า 1 ชิ้น ประเภทคำสั่งซื้อ`,
      });
      return false;
    } else if (checkBasePriceOrder > 1) {
      await dialog.alert({
        title: "แจ้งเตือน",
        message:
          "กรุณากำหนดราคาสินค้า 1 ชิ้น ประเภทคำสั่งซื้อได้เพียง 1 รายการเท่านั้น",
      });
      return false;
    }

    const {
      code,
      name,
      detail,
      prominentPoint,
      productCategoryId,
      productTypeId,
      productStatusId,
      images,
      countryCode,
      currencyCode,
    } = productData;
    if (
      !code ||
      !name ||
      !detail ||
      !prominentPoint ||
      !productCategoryId ||
      !productTypeId ||
      !productStatusId ||
      !countryCode ||
      !currencyCode ||
      images.length === 0
    ) {
      await dialog.alert({
        title: "แจ้งเตือน",
        message: "กรุณากรอกข้อมูลให้ครบถ้วน",
      });
      return false;
    }
    //check price
    if (productData.discountList.length === 0) {
      await dialog.alert({
        title: "แจ้งเตือน",
        message: "กรุณาเพิ่มระดับราคาสินค้า",
      });
      return false;
    }
    return true;
  };

  const addProduct = async () => {
    if (!(await validation())) {
      return;
    }
    //start call api
    dialog.loading();
    const uploadImages = await upload(
      productData.images,
      `${process.env["REACT_APP_ENV"]}/Images/Product`
    );
    const { data } = uploadImages.response;
    if (!data) {
      await dialog.alert({
        title: "แจ้งเตือน",
        message: uploadImages.response.data.error,
      });
      dialog.cancle();
      return;
    }
    let url = apiPath.products.addProduct;
    let { status, response } = await post(
      url,
      {
        ...productData,
        price: Number(productData.price),
        point: Number(productData.point),
        images: data.data ? data.data : [],
      },
      true
    );
    if (!status) {
      await dialog.alert({ title: "แจ้งเตือน", message: response.data.error });
      dialog.cancle();
      return;
    }
    //dialog.cancle();
    await dialog.alert({ title: "แจ้งเตือน", message: "เพิ่มสินค้าเรียบร้อย" });
    navigate("/inventory");
  };

  const updateProduct = async () => {
    if (!(await validation())) {
      return;
    }
    dialog.loading();
    let newImages = await reCheckImages(productData.images);
    let url = apiPath.products.updateProduct;
    let { status, response } = await post(
      url,
      {
        ...productData,
        price: Number(productData.price),
        point: Number(productData.point),
        images: newImages,
      },
      true
    );
    if (!status) {
      await dialog.alert({ title: "แจ้งเตือน", message: response.data.error });
      dialog.cancle();
      return;
    }
    let alertStatus = await dialog.alert({
      title: "แจ้งเตือน",
      message: "บันทึกสินค้าเรียบร้อย",
    });
    if (!alertStatus) {
      return;
    }
    dialog.cancle();

    onClose && onClose();
  };

  const reCheckImages = async (images) => {
    let list = [...images];
    let imageForUpload = [];
    list.forEach((image, index) => {
      if (image instanceof File) {
        imageForUpload.push({ image: image, index: index });
      }
    }, []);

    const promises = imageForUpload.map((item) => {
      return uploadImage(item.image, item.index);
    });

    const updatedImages = await Promise.all(promises);

    updatedImages.forEach((item) => {
      list[item.index] = item.image;
    });
    return list;
  };

  const uploadImage = (image, index) => {
    return new Promise(async (resolve) => {
      let { response, status } = await upload(
        [image],
        `${process.env["REACT_APP_ENV"]}/Images/Product`
      );
      if (status) {
        resolve({ image: response.data.data[0], index: index });
      } else {
        resolve({ image: "", index: index });
      }
    });
  };

  const close = () => {
    if (isEdit) {
      onClose && onClose();
    } else {
      navigate("/inventory");
    }
  };
  return (
    <View style={Styles.container}>
      <Grid container style={Styles.topContainer} spacing={2}>
        <Grid item xs={12} md={8} lg={9} xl={10}>
          <View style={{ flex: 1 }}>
            <Text style={Styles.textTopContainer1}>
              {isEdit ? "แก้ไขสินค้า" : "เพิ่มสินค้า"}
            </Text>
            <Text style={Styles.textTopContainer2}>
              สินค้า / {isEdit ? "แก้ไขสินค้า" : "เพิ่มสินค้า"}
            </Text>
          </View>
        </Grid>
      </Grid>
      <View style={{ height: 30 }} />
      <View style={Styles.contentContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} lg={6} xl={4}>
            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontSize: "clamp(16px,2vh,20px)",
                  fontWeight: "bold",
                  color: "rgba(15, 16, 17, 1)",
                }}
              >
                รายละเอียดสินค้า
              </Text>
            </View>
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Text style={Styles.titleTextField}>ประเทศของคลังสินค้า</Text>
            <View style={{ height: 8 }} />
            <Dropdown
              disable={isEdit}
              value={productData.countryCode}
              valueType={"string"}
              placeholder={"เลือกประเภทสินค้า"}
              list={setupData && setupData.masterData.countryList}
              onChange={(values) => {
                const { value, label } = values;
                setProductData({
                  ...productData,
                  countryCode: value,
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Text style={Styles.titleTextField}>สกุลเงิน</Text>
            <View style={{ height: 8 }} />
            <TextField
              value={productData.currencyCode ? productData.currencyCode : null}
              placeholder="สกุลเงิน"
              disable={true}
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ zIndex: 20 }}>
            <Text style={Styles.titleTextField}>ประเภทสินค้า</Text>
            <View style={{ height: 8 }} />
            <Dropdown
              value={productData.productTypeId}
              valueType={"number"}
              placeholder={"เลือกประเภทสินค้า"}
              list={productTypeList}
              onChange={(values) => {
                const { value, label } = values;
                setProductData({
                  ...productData,
                  productTypeId: value,
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Text style={Styles.titleTextField}>ชื่อสินค้า</Text>
            <View style={{ height: 8 }} />
            <TextField
              value={productData.name ? productData.name : ""}
              iconName={""}
              isInvalid={false}
              placeholder={"ระบุชื่อสินค้า"}
              errorMessage={""}
              onChangeText={(value) => {
                setProductData({
                  ...productData,
                  name: value,
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Text style={Styles.titleTextField}>ราคาสินค้า</Text>
            <View style={{ height: 8 }} />
            <TextField
              value={productData.price ? productData.price : ""}
              iconName={""}
              isInvalid={false}
              placeholder={"ระบุราคาสินค้า"}
              errorMessage={""}
              onChangeText={(value) => {
                // Accept only numbers and a single decimal point
                const onlyNumsAndDot = value.replace(/[^0-9.]/g, "");
                // Remove all but first dot
                const singleDot = onlyNumsAndDot.replace(/(\..*)\./g, "$1");
                // Don't allow more than two digits after the decimal point
                const maxTwoDecimals = singleDot.replace(/(\.\d{2}).*/g, "$1");
                setProductData({
                  ...productData,
                  price: maxTwoDecimals,
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Text style={Styles.titleTextField}>รหัสสินค้า</Text>
            <View style={{ height: 8 }} />
            <TextField
              value={productData.code ? productData.code : ""}
              iconName={""}
              isInvalid={false}
              placeholder={"ระบุรหัสสินค้า"}
              errorMessage={""}
              onChangeText={(value) => {
                setProductData({
                  ...productData,
                  code: value,
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Text style={Styles.titleTextField}>แต้มสะสม</Text>
            <View style={{ height: 8 }} />
            <TextField
              value={
                productData.point || Number(productData.point) === 0
                  ? productData.point
                  : ""
              }
              iconName={""}
              isInvalid={false}
              placeholder={"ระบุแต้มสะสม"}
              errorMessage={""}
              onChangeText={(value) => {
                // Accept only numbers and a single decimal poaint
                const onlyNumsAndDot = value.replace(/[^0-9.]/g, "");
                // Remove all but first dot
                const singleDot = onlyNumsAndDot.replace(/(\..*)\./g, "$1");
                // Don't allow more than two digits after the decimal point
                const maxTwoDecimals = singleDot.replace(/(\.\d{2}).*/g, "$1");
                setProductData({
                  ...productData,
                  point: maxTwoDecimals,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Text style={Styles.titleTextField}>หมวดหมู่สินค้า</Text>
            <View style={{ height: 8 }} />
            <Dropdown
              value={productData.productCategoryId}
              valueType={"number"}
              placeholder={"เลือกหมวดหมู่สินค้า"}
              list={productCategoryList}
              onChange={(values) => {
                const { value, label } = values;
                setProductData({
                  ...productData,
                  productCategoryId: value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Text style={Styles.titleTextField}>ข้อมูลสินค้า</Text>
            <View style={{ height: 8 }} />
            <TextField
              value={productData.detail ? productData.detail : ""}
              iconName={""}
              isInvalid={false}
              placeholder={"ระบุข้อมูลสินค้า"}
              errorMessage={""}
              onChangeText={(value) => {
                setProductData({
                  ...productData,
                  detail: value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Text style={Styles.titleTextField}>จุดเด่นสินค้า</Text>
            <View style={{ height: 8 }} />
            <TextFieldList
              value={productData.prominentPoint}
              placeholder={"ระบุจุดเด่นสินค้า"}
              onChange={(values) => {
                setProductData({
                  ...productData,
                  prominentPoint: values,
                });
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <View>
              <Text style={{ fontSize: "clamp(12px,2vh,16px)" }}>
                เพิ่มรูปภาพสินค้า
              </Text>
              <Text
                style={{
                  fontSize: "clamp(10px,2vh,14px)",
                  color: "rgba(105, 109, 121, 1)",
                  marginTop: 8,
                }}
              >
                สามารถเพิ่มรูปได้สูงสุด 5 รูป โดยรูปแรกคือรูปหลัก และรองรับไฟล์
                JPG, PNG, JPEGS เท่านั้น
              </Text>
              <View style={{ height: 8 }} />
              <UploadImages
                images={productData ? productData.images : []}
                onChangeFile={(file) => {
                  setProductData({
                    ...productData,
                    images: file,
                  });
                }}
                maxUpload={data.maxUploadImages ? data.maxUploadImages : 5}
              />
              {data.files ? (
                <Text
                  style={{
                    fontSize: "clamp(10px,2vh,14px)",
                    color: "rgba(105, 109, 121, 1)",
                  }}
                >
                  จำนวนรูปภาพ{" "}
                  {`${productData.images.length}/${data.maxUploadImages}`} รูป
                </Text>
              ) : null}
            </View>
          </Grid>

          <Grid item xs={12}>
            <Text style={Styles.titleTextField}>สถานะสินค้า</Text>
            <View style={{ height: 8 }} />
            <CheckBoxList
              value={productData ? productData.productStatusId : null}
              list={productStatusList}
              minWidth={150}
              onCheck={(values) => {
                const { value, label } = values;
                setProductData({
                  ...productData,
                  productStatusId: value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Text style={Styles.titleTextField}>หมายเหตุ</Text>
            <View style={{ height: 8 }} />
            <TextArea
              value={productData.remark}
              onChangeText={(value) => {
                setProductData({
                  ...productData,
                  remark: value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} style={{ zIndex: 20 }}>
            <Text style={Styles.titleTextField}>ร่วมกับสินค้าอื่นๆ</Text>
            <View style={{ height: 8 }} />
            <SearchProductLink
              currentItem={productData}
              value={productData.productRelation}
              data={productList}
              onChangeData={(values) => {
                let filterOnlyId = values.map((d) => d.id);
                setProductData({
                  ...productData,
                  productRelation: filterOnlyId,
                });
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <View>
              <Text style={Styles.titleTextField}>
                เพิ่มราคาสินค้าตามจำนวนสินค้า
              </Text>
              <Text style={Styles.subTitle}>
                ราคาสินค้าจะถูกคิดตามระดับจำนวนสินค้า
              </Text>
            </View>
          </Grid>
        </Grid>
        <View style={{ height: 30 }} />
        <ProductDiscountBox
          value={productData.discountList}
          onChangeData={(values) => {
            setProductData({
              ...productData,
              discountList: values,
            });
          }}
        />
        <Grid
          container
          spacing={2}
          style={{ justifyContent: "center", marginTop: 40 }}
        >
          <Grid item xs={12} md={4} lg={3}>
            <View style={{ width: "100%" }}>
              <Button
                buttonText={"ยกเลิก"}
                color={"primary"}
                style={Styles.acceptButton}
                isBorder={true}
                onClick={() => {
                  close();
                }}
              />
            </View>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <View style={{ width: "100%" }}>
              <Button
                buttonText={isEdit ? "บันทึก" : "เพิ่มสินค้า"}
                color={"primary"}
                style={Styles.acceptButton}
                onClick={() => {
                  if (isEdit) {
                    updateProduct();
                  } else {
                    addProduct();
                  }
                }}
              />
            </View>
          </Grid>
        </Grid>
      </View>
    </View>
  );
};

const Styles = {
  container: {
    flex: 1,
    height: "100vh",
    padding: "1.5%",
  },
  topContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  contentContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  textTopContainer1: {
    fontSize: "clamp(18px, 3vw, 22px)",
    fontWeight: "bold",
    color: "#AA4942",
  },
  textTopContainer2: {
    fontSize: "clamp(12px, 3vw, 16px)",
    color: "#696D79",
  },
  topButtonText: {
    fontSize: "clamp(14px, 3vw, 18px)",
  },
  titleTextField: {
    fontSize: 14,
    color: "#0F1011",
  },
  subTitle: {
    fontSize: 13,
    color: "#696D79",
  },
};

export default ProductAddNew;
