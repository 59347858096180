export function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  let byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  let arrayBuffer = new ArrayBuffer(byteString.length);
  let _ia = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    _ia[i] = byteString.charCodeAt(i);
  }

  let dataView = new DataView(arrayBuffer);
  let blob = new Blob([dataView], { type: mimeString });
  return blob;
}

export function base64ToBlob(base64String) {
  const binaryString = atob(base64String);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return new Blob([bytes], { type: "image/jpeg" });
}

export function base64ToFile(base64String, fileName) {
  const mimeType = base64String.match(
    /^data:([a-zA-Z0-9]+\/[a-zA-Z0-9]+).*,.*/
  )[1];
  const base64Data = base64String.replace(
    /^data:([a-zA-Z0-9]+\/[a-zA-Z0-9]+).*,/,
    ""
  );
  const binaryData = atob(base64Data);
  const length = binaryData.length;
  const bytes = new Uint8Array(length);

  for (let i = 0; i < length; i++) {
    bytes[i] = binaryData.charCodeAt(i);
  }

  const blob = new Blob([bytes], { type: mimeType });
  return new File([blob], fileName, { type: mimeType });
}
