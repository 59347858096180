import apiPath from "../../configs/apiPath";
import { get, post } from "../../../functions/fetch/fetch";

export const downloadAllCountry = async () => {
  let url = apiPath.masterData.getAllCountry;
  let { status, response } = await get(url, {});
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};

export const downloadAllProvince = async () => {
  let url = apiPath.masterData.getAllProvince;
  let { status, response } = await get(url, {});
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};

export const downloadAllDistrict = async (provinceCode) => {
  let url = apiPath.masterData.getAllDistrict;
  let { status, response } = await get(url, { provinceCode: provinceCode });
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};

export const downloadAllSubDistrict = async (provinceCode, districtCode) => {
  let url = apiPath.masterData.getAllSubDistrict;
  let { status, response } = await get(url, {
    provinceCode: provinceCode,
    districtCode: districtCode,
  });
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};

export const downloadAllZipcode = async (province, district, subDistrict) => {
  let url = apiPath.masterData.getAllZipcode;
  let { status, response } = await get(url, {
    province: province,
    district: district,
    subdistrict: subDistrict,
  });
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};

export const downloadAllRanking = async () => {
  let url = apiPath.masterData.getRanking;
  let { status, response } = await get(url, {}, false);
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};

export const downloadAllBanks = async () => {
  let url = apiPath.masterData.getBanks;
  let { status, response } = await get(url, {}, false);
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};
