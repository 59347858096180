import React, { useState, useEffect, useRef } from "react";
import { View, Text, TouchableOpacity, ScrollView } from "react-native-web";
import { connect } from "react-redux";
import moment from "moment";
//component
import BuildDayBoxTitle from "./component/BuildTitleBox";
import BuildDayBox from "./component/BuildDayBox";
import BuildYear from "./component/BuildYear";
//Icons
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosArrowDown,
  IoIosArrowUp,
} from "react-icons/io";
//Service
import language from "../../services/language/language";
import { month } from "../../functions/format/dateFormat";

const systemLanguage = "TH";

const Calendar = (props) => {
  //property
  const { date, isBetween, startDate, endDate } = props;
  const { onSelect, onClickOutside } = props;

  //state
  const [currentDate, setCurrentDate] = useState(moment());
  const [dateType, setDateType] = useState(0);

  const componentRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [componentRef]);

  useEffect(() => {
    if (date) {
      setCurrentDate(moment(Number(date)));
    }
  }, [date]);

  const previousDate = () => {
    setCurrentDate(moment(currentDate).subtract(1, "months"));
  };

  const nextDate = () => {
    setCurrentDate(moment(currentDate).add(1, "months"));
  };

  return (
    <View
      style={{ ...Styles.container, padding: dateType === 0 ? 20 : 0 }}
      ref={componentRef}
    >
      <View style={Styles.selectBox}>
        <TouchableOpacity
          style={{
            flexDirection: "row",
            paddingTop: dateType === 1 ? 20 : 0,
            paddingLeft: dateType === 1 ? 20 : 0,
            justifyContent: "center",
            alignItems: "center",
          }}
          activeOpacity={0.7}
          onPress={() => {
            setDateType((prev) => {
              return prev === 0 ? 1 : 0;
            });
          }}
        >
          <Text style={{ fontWeight: "bold" }}>
            {currentDate
              ? language.getWord(
                  month.filter((d) => {
                    return Number(d.value) === Number(currentDate.format("MM"));
                  })[0].label,
                  systemLanguage
                ) +
                " " +
                currentDate.format("YYYY")
              : ""}
          </Text>
          <View style={{ width: 8 }} />
          {dateType === 0 ? (
            <IoIosArrowDown style={Styles.icon} />
          ) : (
            <IoIosArrowUp style={Styles.icon} />
          )}
        </TouchableOpacity>

        <View style={{ flex: 1 }} />

        {dateType === 0 ? (
          <React.Fragment>
            <TouchableOpacity onPress={previousDate}>
              <IoIosArrowBack style={Styles.icon} />
            </TouchableOpacity>
            <View style={{ width: 20 }} />
            <TouchableOpacity onPress={nextDate}>
              <IoIosArrowForward style={Styles.icon} />
            </TouchableOpacity>
          </React.Fragment>
        ) : null}
      </View>
      {dateType === 0 ? (
        <BuildMonth
          isBetween={isBetween}
          startDate={startDate}
          endDate={endDate}
        />
      ) : (
        <ScrollView>
          <BuildYear
            date={currentDate}
            onSelect={(year) => {
              setCurrentDate(currentDate.set("year", year));
              setDateType(0);
            }}
          />
        </ScrollView>
      )}
    </View>
  );
  //For build Month
  function BuildMonth() {
    return (
      <React.Fragment>
        <View style={{ marginBottom: 10 }} />
        <BuildDayBoxTitle />
        <View style={{ height: 12 }} />
        <View
          style={{ width: "100%", height: 1, backgroundColor: "#EDEEF0" }}
        />
        <View style={{ height: 12 }} />
        <BuildDayBox
          currentDate={currentDate}
          callback={(response) => {
            setCurrentDate(currentDate.set("date", response));
            onSelect && onSelect(currentDate);
          }}
        />
      </React.Fragment>
    );
  }
};

const Styles = {
  icon: {
    fontSize: 20,
    color: "#DE7C78",
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  selectBox: {
    width: "100%",
    flexDirection: "row",
  },
};

function mapStateToProps(state) {
  return {
    ...state,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
