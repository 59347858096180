import React, { useState } from "react";
import { View, Text } from "react-native-web";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
//components
import PageButtonNavigate from "../../../components/button/PageButtonNavigate";
import Button from "../../../components/button/Button";
//images
import noImage from "../../../asset/images/register/user.png";
//functions
import { useSelector } from "react-redux";

const MoveTeamPathSearchTable = (props) => {
  const { tableHeader = [], tableData = [] } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const userInfo = useSelector((state) => {
    return state.user;
  });

  const pagesCount = Math.ceil(tableData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const tableDataSliced = tableData.slice(startIndex, endIndex);

  const { onSelect } = props;

  return (
    <React.Fragment>
      {tableDataSliced.length > 0 ? (
        <View style={{ width: "100%", height: "100%" }}>
          <TableContainer sx={styles.tableContainer}>
            <Table sx={styles.table}>
              <TableHead sx={{ position: "sticky", top: 0, zIndex: 9999 }}>
                <TableRow style={{ width: "100%", backgroundColor: "#FFF" }}>
                  <TableCell sx={{ ...styles.headerCell }}>
                    รหัสตัวแทน
                  </TableCell>
                  <TableCell sx={{ ...styles.headerCell }}>ชื่อ</TableCell>
                  <TableCell sx={{ ...styles.headerCell }}>อีเมล</TableCell>
                  <TableCell sx={{ ...styles.headerCell }}>ตัวเลือก</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableDataSliced.map((row) => (
                  <TableRow key={row.id} sx={styles.tableRow}>
                    <TableCell sx={styles.tableCell}>
                      {" "}
                      <Text>{row.agentCode ? row.agentCode : ""}</Text>
                    </TableCell>
                    <TableCell sx={styles.tableCell}>
                      <Text>{row.name ? row.name : "-"}</Text>
                    </TableCell>
                    <TableCell sx={styles.tableCell}>
                      <Text>{row.email ? row.email : "-"}</Text>
                    </TableCell>
                    <TableCell sx={styles.tableCell}>
                      <Button
                        buttonText={"เลือก"}
                        color="primary"
                        onClick={() => {
                          onSelect && onSelect(row);
                        }}
                      ></Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <PageButtonNavigate
            currentPage={currentPage}
            pagesCount={pagesCount}
            startIndex={startIndex}
            endIndex={endIndex}
            dataCount={tableData.length}
            onSelectPage={(page) => {
              setCurrentPage(page);
            }}
          />
        </View>
      ) : (
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            margin: 20,
          }}
        >
          <Text>ยังไม่มีรายการ</Text>
        </View>
      )}
    </React.Fragment>
  );
};

const styles = {
  tableContainer: {
    overflowY: "scroll",
    scrollbarWidth: "thin",
    scrollbarColor: "#c7c7c7 #f5f5f5",
    flex: 1,
  },
  table: {
    border: "none",
    minWidth: 650,
  },
  tableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: "rgba(255, 255, 255, 1)",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "rgba(255, 241, 241, 1)",
    },
  },
  tableCell: {
    borderBottom: "none",
    whiteSpace: "nowrap",
    wordBreak: "break-word",
  },
  headerCell: {
    color: "rgba(165, 88, 90, 1)",
    whiteSpace: "nowrap",
  },
};

export default MoveTeamPathSearchTable;
