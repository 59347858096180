import React, { useState } from "react";
import PopupPaymentThaiQr from "./PopupPaymentThaiQr";
import PopupPaymentCard from "./PopupPaymentCard";
import { useDialog } from "../../../components/modal/Popup";

const PaymentPopup = (props) => {
  //property
  const { paymentMethodCode, data } = props;
  const { onClose, onSuccess, onFail, onTimeout } = props;
  //state
  const [isDone, setIsDone] = useState(false);

  return (
    <React.Fragment>
      {paymentMethodCode === "qrcode" ? (
        <PopupPaymentThaiQr
          data={data}
          onSuccess={() => {
            if (!isDone) {
              setIsDone(true);
              onSuccess && onSuccess();
            }
          }}
          onFail={() => {
            if (!isDone) {
              setIsDone(true);
              onFail && onFail();
            }
          }}
          onClose={() => {
            onClose && onClose();
          }}
          onTimeout={() => {
            onTimeout && onTimeout();
          }}
        />
      ) : paymentMethodCode === "card" ? (
        <PopupPaymentCard
          data={data}
          onClose={() => {
            onClose && onClose();
          }}
        />
      ) : null}
    </React.Fragment>
  );
};

export default PaymentPopup;
