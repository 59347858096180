import Cookies from "js-cookie";

export function isAdmin(userInfo) {
  if (!userInfo) {
    return false;
  }
  if (!userInfo.userRole) {
    return false;
  }
  return userInfo.userRole === "S" || userInfo.userRole === "SS";
}

export function isLogin() {
  const cookieTokenName = "rosegoldtoken";
  return (
    Cookies.get(cookieTokenName) !== undefined &&
    Cookies.get(cookieTokenName) !== null &&
    Cookies.get(cookieTokenName) !== ""
  );
}

export function validatePhone(phoneNumber) {
  if (!phoneNumber) {
    return false;
  }
  let phoneNumberCut = phoneNumber.substring(0, 2);
  if (
    phoneNumberCut === "06" ||
    phoneNumberCut === "08" ||
    phoneNumberCut === "09"
  ) {
  } else {
    return false;
  }
  if (phoneNumber.length !== 10) {
    return false;
  }

  return true;
}

export function validatePhoneNumber(phoneNumber) {
  // Remove any non-digit characters
  let cleanedNumber = phoneNumber.replace(/\D/g, "");

  // Validate the phone number
  let regex = /^(\+66|0)8[0-9]{8}$/; // Regex for Thai phone numbers
  return regex.test(cleanedNumber);
}

export function validateEmail(email) {
  if (!email) {
    return false;
  }
  if (
    !/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
      email
    )
  ) {
    return false;
  }
  return true;
}

export function validateLine(line) {
  if (!/^[a-zA-Z\@\-\.]$/.test(line)) {
    return false;
  }

  return true;
}

export function checkFileType(file, callback) {
  let fileReader = new FileReader();
  fileReader.onloadend = function (e) {
    let arr = new Uint8Array(e.target.result).subarray(0, 4);
    let header = "";
    for (let i = 0; i < arr.length; i++) {
      header += arr[i].toString(16);
    }
    // Check the file signature against known types
    let type = "unknown";
    switch (header) {
      case "89504e47":
        type = "image/png";
        break;
      case "47494638":
        type = "image/gif";
        break;
      case "ffd8ffe0":
      case "ffd8ffe1":
      case "ffd8ffe2":
        type = "image/jpeg";
        break;
      case "25504446":
        type = "application/pdf";
        break;
      case "efbbbf22":
      case "22616765":
      case "5469746c":
        type = "text/csv";
        break;
      default:
        break;
    }
    callback && callback(type);
  };
  fileReader.readAsArrayBuffer(file);
}
export function canParseJSON(variable) {
  try {
    JSON.parse(variable);
    return true;
  } catch (error) {
    return false;
  }
}

export function validatePasswordV2(password) {
  // ตรวจสอบความยาวของรหัสผ่าน
  if (password.length < 8) {
    return false;
  }

  // ตรวจสอบว่ารหัสผ่านประกอบไปด้วย A-Z, a-z, 0-9 และตัวอักษรพิเศษ
  const pattern =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&+-])[A-Za-z\d@$!%*?&+-]+$/;
  return pattern.test(password);
}

export function validatePassword(password) {
  let message =
    " - รหัสผ่านให้มีความยาวอย่างน้อย 8 อักษร และไม่เกิน 30 อักษร \n - รหัสผ่านต้องประกอบไปด้วย อักษรตัวพิมพ์ใหญ่ (Uppercase letters) เช่น A, B, C เป็นต้น \n - รหัสผ่านต้องประกอบไปด้วย อักษรตัวพิมพ์เล็ก (Lowercase letters) เช่น a, b, c เป็นต้น \n - รหัสผ่านต้องประกอบไปด้วย ตัวเลข (Numbers) คือ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9 \n - รหัสผ่านต้องประกอบไปด้วย สัญลักษณ์ (Symbols) เช่น ! @ # $ % ^ & * เป็นต้น";

  if (password.length < 8 || password.length > 30) {
    return {
      status: false,
      message: "กำหนดรหัสผ่านให้มีความยาวอย่างน้อย 8 อักษร และไม่เกิน 30 อักษร",
    };
  }

  let checkEngLarge = /[A-Z]/;
  if (!checkEngLarge.test(password)) {
    return { status: false, message: message };
  }
  let checkEngSmall = /[a-z]/;
  if (!checkEngSmall.test(password)) {
    return { status: false, message: message };
  }
  let checkNumber = /[0-9]/;
  if (!checkNumber.test(password)) {
    return { status: false, message: message };
  }
  let checkChar = /([!@#$%^&*-])/;
  if (!checkChar.test(password)) {
    return { status: false, message: message };
  }
  return { status: true, message: "" };
}

export function validateIdCardNumber(id) {
  let newId = String(id).replace(/[^[0-9]+/g, "");
  if (newId.length !== 13) return false;
  let sum = 0;
  let i = 0;
  for (i; i < 12; i++) sum += parseFloat(id.charAt(i)) * (13 - i);
  if ((11 - (sum % 11)) % 10 != parseFloat(newId.charAt(12))) return false;
  return true;
}

export const allFieldsSet = (data) => {
  for (const key in data) {
    if (data[key] === null || data[key] === "" || data[key] === undefined) {
      return false;
    }
  }
  return true;
};

export function arraysHaveSameData(array1, array2) {
  // Check if the arrays have the same length
  if (array1.length !== array2.length) {
    return false;
  }

  // Sort the arrays to ensure consistent order for comparison
  const sortedArray1 = array1.sort();
  const sortedArray2 = array2.sort();

  // Convert the sorted arrays to JSON strings
  const jsonString1 = JSON.stringify(sortedArray1);
  const jsonString2 = JSON.stringify(sortedArray2);

  // Compare the JSON strings
  return jsonString1 === jsonString2;
}
