import React, { useEffect } from "react";
import { View, Text } from "react-native-web";
import { Grid } from "@mui/material";

const SummaryBox = (props) => {
  const { displayDataSummary } = props;

  useEffect(() => {
    console.log("displayDataSummary--", displayDataSummary);
  }, [displayDataSummary]);

  const StylesSummaryBox = {
    container: {
      width: "100%",
      backgroundColor: "rgba(248, 248, 248, 1)",
      borderRadius: 16,
      padding: 20,
      justifyContent: "center",
      alignItems: "center",
    },
    row: {
      width: "100%",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    titleText: {
      color: "rgba(105, 109, 121, 1)",
      flex: 1,
    },
    valueText: {
      color: "rgba(15, 16, 17, 1)",
      fontSize: 18,
      fontWeight: "bold",
    },
    unitText: {
      marginLeft: 8,
      color: "rgba(105, 109, 121, 1)",
    },
    line: {
      width: "100%",
      height: 1,
      backgroundColor: "rgba(237, 238, 240, 1)",
      marginTop: 20,
      marginBottom: 20,
    },
    sumText: {
      fontSize: 18,
      fontWeight: "bold",
      color: "rgba(15, 16, 17, 1)",
      flex: 1,
    },
    sumValueText: {
      fontSize: 20,
      fontWeight: "bold",
      color: "rgba(170, 73, 66, 1)",
    },
  };

  const SummaryRow = (props) => {
    const { title, value, unit } = props;
    return (
      <View style={StylesSummaryBox.row}>
        <Text style={StylesSummaryBox.titleText}>{title ? title : ""}</Text>
        <Text style={StylesSummaryBox.valueText}>{value ? value : ""}</Text>
        <Text style={StylesSummaryBox.unitText}>{unit ? unit : ""}</Text>
      </View>
    );
  };

  return (
    <Grid item xs={12}>
      <View style={StylesSummaryBox.container}>
        <SummaryRow
          key={"sum_count"}
          title={"จำนวน"}
          value={
            displayDataSummary.quantity ? displayDataSummary.quantity : "0"
          }
          unit={"ชิ้น"}
        />
        <SummaryRow
          key={"sum_total"}
          title={"ยอดรวม"}
          value={
            displayDataSummary.totalBasePrice
              ? Number(displayDataSummary.totalBasePrice).toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 2,
                  }
                )
              : "0.00"
          }
          unit={"บาท"}
        />
        <SummaryRow
          key={"sum_discount"}
          title={"ส่วนลด"}
          value={
            displayDataSummary.totalDiscount
              ? Number(displayDataSummary.totalDiscount).toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 2,
                  }
                )
              : "0.00"
          }
          unit={"บาท"}
        />
        <SummaryRow
          key={"sum_sending"}
          title={"ค่าจัดส่ง"}
          value={
            displayDataSummary.totalDelivery
              ? Number(displayDataSummary.totalDelivery).toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 2,
                  }
                )
              : "0.00"
          }
          unit={"บาท"}
        />
        {/* {displayDataSummary.totalExtraCashDelivery ? (
          <SummaryRow
            key={"extra_sending"}
            title={"ค่าบริการเก็บเงินปลายทาง"}
            value={Number(
              displayDataSummary.totalExtraCashDelivery
            ).toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })}
            unit={"บาท"}
          />
        ) : null} */}
        {
          displayDataSummary.addOn ?
          displayDataSummary.addOn.map((item, index) => {
            return(
              <SummaryRow
              key={item.label}
              title={item.label}
              value={
                item.value
                  ? Number(item.value).toLocaleString(
                      undefined,
                      {
                        minimumFractionDigits: 2,
                      }
                    )
                  : "0.00"
              }
              unit={"บาท"}
            />
            )
          })
          : null
        }
        <View style={StylesSummaryBox.line} />
        <View style={StylesSummaryBox.row}>
          <Text style={StylesSummaryBox.sumText}>ยอดรวมทั้งหมด</Text>
          <Text style={StylesSummaryBox.sumValueText}>
            {displayDataSummary.total
              ? Number(displayDataSummary.total).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })
              : "0.00"}
          </Text>
          <Text style={StylesSummaryBox.unitText}>บาท</Text>
        </View>
      </View>
    </Grid>
  );
};

export default SummaryBox;
