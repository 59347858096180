import React, { useEffect, useMemo, useState } from "react";
import { View } from "react-native-web";
import { useNavigate } from "react-router-dom";
import apiPath from "../../services/configs/apiPath";
import {get} from "../../functions/fetch/fetch";
import Cookies from "js-cookie";
//component
import HomeHeaderContainer from "./HomeHeaderContainer";
import HomeTopContainer from "./HomeTopContainer";
import HomeCenterContainer from "./HomeCenterContainer";
import HomeBottomContainer from "./HomeBottomContainer";

//functions
import { getUserInfo } from "../../services/api/member/member";
import HomeBanner from "./HomeBanner";
import { useDialog } from "../../components/modal/Popup";
import { useDispatch } from "react-redux";

const Home = () => {
  //property
  const dialog = useDialog();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //state
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    downloadAll();
  }, []);

  const downloadAll = async () => {
    let url = apiPath.member.getUserInfo;
    let { status, response } = await get(url, {}, true);
    if (!status) {
      await dialog.alert({message: response.data.error});
      logout();
      return false;
    }
    if(response.data){
      setUserInfo(response.data)
    }else{
      await dialog.alert({message: "ไม่พบข้อมูลผู้ใช้"});
      logout();
      return false;
    }
  };

  const logout = () => {
    Cookies.remove("token");
    Cookies.remove("rosegoldtoken");
    dispatch({ type: "SetUser", payload: null });
    navigate("/login");
  };

  return (
    <View style={Styles.container}>
      <HomeHeaderContainer userInfo={userInfo} />
      <View style={Styles.spaceHeightComponent} />
      <HomeTopContainer userInfo={userInfo} />
      <View style={Styles.spaceHeightComponent} />
      <HomeCenterContainer />
      <View style={Styles.spaceHeightComponent} />
      <HomeBottomContainer />
      <View style={Styles.spaceHeightComponent} />
    </View>
  );
};

const Styles = {
  container: {
    flex: 1,
    height: "100vh",
    padding: "1.5%",
  },
  spaceHeightComponent: {
    height: 35,
  },
};
export default Home;
