import React, { useEffect, useMemo, useRef, useState } from "react";
import { Modal, View, Text, StyleSheet, ScrollView } from "react-native-web";
import { Grid, Typography } from "@mui/material";
import moment from "moment-timezone";
//components
import CloseButton from "../../../components/button/CloseButton";
import Button from "../../../components/button/Button";
//functions
import { calculatorFontSize } from "../../../functions/screen/screenFunction";
import { useDialog } from "../../../components/modal/Popup";
import { getUserInfo } from "../../../services/api/member/member";
//images
import { CorrectCircleIcon } from "../../../asset/images/icons/iconsLib";
import ProductShoppingTable from "../../shopping/component/ProductShoppingTable";
import SummaryBox from "../../order/component/SummaryBox";

const PopupShoppingDetail = (props) => {
  //property
  const dialog = useDialog();
  //props
  const { visible, data } = props;
  const { onClose } = props;
  //state
  const [userInfo, setUserInfo] = useState();


  useEffect(() => {
    if (!userInfo) {
      downloadUserProfile();
    }
  }, []);

  const downloadUserProfile = async () => {
    let uInfo = await getUserInfo();
    if (uInfo) {
      setUserInfo(uInfo);
    }
  };

  const handleStatusText = (value) => {
    switch (value) {
      case "Y":
        return "สำเร็จ";
      case "N":
        return "ไม่สำเร็จ";
      case "C":
        return "รอเก็บเงินปลายทาง";
      default:
        return "รอดำเนินการ";
    }
  };

  return (
    <Modal transparent={true} visible={visible ? visible : false}>
      <View style={Styles.modalContainer}>
        <View style={Styles.box}>
          <View style={Styles.headerBox}>
            <View style={{ flex: 1 }}>
              <Text style={Styles.headerTitle}>รายละเอียดรายการขายปลีก</Text>
              <Text style={Styles.headerSubTitle}>
                {data ? data.retailCode : ""}
              </Text>
            </View>
            <CloseButton
              onPress={() => {
                onClose && onClose();
              }}
            />
          </View>
          <View style={{ height: 20 }} />
          <View
            style={{ width: "100%", backgroundColor: "#EDEEF0", height: 1 }}
          />
          <View style={{ height: 20 }} />
          <ScrollView
            style={{ width: "100%" }}
            showsVerticalScrollIndicator={false}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Text style={Styles.titleTextBold}>รายละเอียดคำสั่งซื้อ</Text>
                <View style={Styles.detailBox}>
                  <View style={Styles.boxRow}>
                    <Text style={Styles.textTitle}>วันที่และเวลา</Text>
                    <Text style={Styles.textValue}>
                      {data ? moment(data.date).format("DD/MM/YYYY HH:mm") : ""}
                    </Text>
                  </View>
                  <View style={Styles.boxRow}>
                    <Text style={Styles.textTitle}>เลขที่อ้างอิง</Text>
                    <Text style={Styles.textValue}>
                      {data ? data.retailCode : ""}
                    </Text>
                  </View>
                  <View style={Styles.boxRow}>
                    <Text style={Styles.textTitle}>สถานะการขาย</Text>
                    <Text style={Styles.textValue}>
                      {handleStatusText(data ? data.status : "")}
                    </Text>
                  </View>
                  {/* <View style={Styles.boxRow}>
                    <Text style={Styles.textTitle}>สถานะการจ่ายเงิน</Text>
                    <Text style={Styles.textValue}>
                      {data ? data.description : ""}
                    </Text>
                  </View> */}
                  {false && (
                    <View style={Styles.boxRow}>
                      <Text style={Styles.textTitle}>วิธีการส่ง</Text>
                      <Text style={Styles.textValue}>Kerry Express</Text>
                    </View>
                  )}
                </View>
              </Grid>

              <Grid item xs={12} md={6}>
                <Text style={Styles.titleTextBold}>ข้อมูลผู้ยื่นสั่งซื้อ</Text>
                <View style={Styles.detailBox}>
                  <View style={Styles.boxRow}>
                    <Text style={Styles.textTitle}>ชื่อ</Text>
                    <Text style={Styles.textValue}>
                      {data ? data.customerName : ""}
                    </Text>
                  </View>
                  <View style={Styles.boxRow}>
                    <Text style={Styles.textTitle}>ที่อยู่</Text>
                    <Text style={Styles.textValue}>
                      {data ? data.allAddress : ""}
                    </Text>
                  </View>
                  <View style={Styles.boxRow}>
                    <Text style={Styles.textTitle}>เบอร์โทรศัพท์</Text>
                    <Text style={Styles.textValue}>
                      {data ? data.customerPhoneNumber : ""}
                    </Text>
                  </View>
                  <View style={Styles.boxRow}>
                    <Text style={Styles.textTitle}>Facebook</Text>
                    <Text style={Styles.textValue}>
                      {data ? data.facebook : ""}
                    </Text>
                  </View>
                </View>
              </Grid>

              <Grid item xs={12} md={6}>
                <Text style={Styles.titleTextBold}>รายละเอียดสินค้า</Text>{" "}
              </Grid>
              <View
                style={{
                  width: "100%",
                  backgroundColor: "#EDEEF0",
                  height: 1,
                  marginTop: 20,
                }}
              />
              <Grid item xs={12}>
                <ProductShoppingTable
                  isView={true}
                  tableData={data ? data.productList : []}
                />
              </Grid>
              <Grid item xs={12}>
                <Text style={Styles.titleTextBold}>รายละเอียดการขายสินค้า</Text>{" "}
                <View
                  style={{
                    width: "100%",
                    backgroundColor: "#EDEEF0",
                    height: 1,
                    marginTop: 20,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <SummaryBox displayDataSummary={data} />
              </Grid>
              {false && (
                <React.Fragment>
                  <Grid item xs={12} md={6}>
                    <View
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                      }}
                    >
                      <Button
                        buttonText={"คัดลอกลิงก์"}
                        color={"primary"}
                        style={{ width: "100%", maxWidth: 400 }}
                        isBorder={true}
                        onClick={() => {}}
                      />
                    </View>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button
                      buttonText={"แก้ไขรายการ"}
                      color={"primary"}
                      style={{ width: "100%", maxWidth: 400 }}
                      isBorder={false}
                      onClick={() => {}}
                    />
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
          </ScrollView>
        </View>
      </View>
    </Modal>
  );
};

const FeatureText = (props) => {
  const { text } = props;
  return (
    <View
      style={{
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
        marginBottom: 8,
      }}
    >
      <CorrectCircleIcon width={20} height={20} color="rgba(170, 73, 66, 1)" />
      <Typography style={{ marginLeft: 10, color: "rgba(105, 109, 121, 1)" }}>
        {text ? text : ""}
      </Typography>
    </View>
  );
};

const Styles = StyleSheet.create({
  modalContainer: {
    position: "fixed",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 9999,
    alignItems: "center",
    justifyContent: "center",
  },
  box: {
    width: "90%",
    maxHeight: "90%",
    maxWidth: 1280,
    padding: 20,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFF",
    borderRadius: 16,
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.08,
    shadowRadius: 16,
  },
  headerBox: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  headerTitle: {
    fontSize: calculatorFontSize(16, 20),
    fontWeight: "bold",
    color: "rgba(15, 16, 17, 1)",
    flex: 1,
  },
  headerSubTitle: {
    marginTop: 8,
    color: "#0F1011",
  },
  titleTextBold: {
    fontWeight: "bold",
  },
  detailBox: {
    backgroundColor: "#F8F8F8",
    borderRadius: 16,
    padding: 20,
    marginTop: 16,
  },
  boxRow: {
    flex: 1,
    flexDirection: "row",
    marginTop: 8,
  },
  textTitle: {
    color: "#696D79",
    flex: 1,
  },
  textValue: {
    color: "#0F1011",
    fontWeight: "bold",
    maxWidth: "50%",
    textAlign: "right",
  },
});

export default PopupShoppingDetail;
