import React, { useState } from "react";
import { TouchableOpacity, Text } from "react-native-web";

const Button_ = (props) => {
  const { buttonText, color, style, isBorder, styleText, icon } = props;
  const { onClick } = props;
  const [isPressed, setIsPressed] = useState(false);

  const handlePressIn = () => {
    setIsPressed(true);
  };

  const handlePressOut = () => {
    setIsPressed(false);
  };

  return (
    <TouchableOpacity
      activeOpacity={0.9}
      style={{
        height: 50,
        backgroundColor: isBorder ? "rgba(0,0,0,0)" : colorOfButton(color),
        borderWidth: isBorder ? 1 : 0,
        borderColor: isBorder ? colorOfButton(color) : "",
        borderRadius: 4,
        outline: 0,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        ...style,
      }}
      onPress={() => {
        onClick && onClick();
      }}
      onPressIn={handlePressIn}
      onPressOut={handlePressOut}
    >
      {icon ? icon : null}
      <Text
        style={{
          color: isBorder ? colorOfButton(color) : colorOfText(color),
          fontWeight: "bold",
          ...styleText,
        }}
      >
        {buttonText ? buttonText : ""}
      </Text>
    </TouchableOpacity>
  );
};

const colorOfButton = (color) => {
  switch (color) {
    case "primary":
      return "#DE7C78";
    case "light":
      return "#EDEEF0";
    case "disable":
      return "#EDEEF0";
    default:
      return;
  }
};

const colorOfText = (color) => {
  switch (color) {
    case "primary":
      return "#FFFFFF";
    case "light":
      return "#8A91A1";
    case "disable":
      return "#8A91A1";
    default:
      return;
  }
};

export default Button_;
