import React, { useEffect, useState } from "react";
import { View, Text, ScrollView, TouchableOpacity } from "react-native-web";
import { Divider } from "rsuite";
import { Grid } from "@mui/material";
//component
import PopupBackdrop from "../../../components/modal/PopupBackdrop";
import CloseButton from "../../../components/button/CloseButton";
//image
import productImg from "../../../asset/images/example/product.png";
import correctImg from "../../../asset/images/icons/correct.png";
import Button from "../../../components/button/Button";
import apiPath from "../../../services/configs/apiPath";
import { post } from "../../../functions/fetch/fetch";
import { useDialog } from "../../../components/modal/Popup";
import { IoMdAdd } from "react-icons/io";
import { IoMdRemove } from "react-icons/io";

const PopupGetReward = (props) => {
  const dialog = useDialog();
  const { data } = props;
  const { onClose } = props;
  const [amount, setAmount] = useState(1);

  const handleAdd = () => {
    setAmount((prev) => {
      return prev + 1;
    });
  };

  const handleRecude = () => {
    if (amount - 1 >= 1) {
      setAmount((prev) => {
        return prev - 1;
      });
    }
  };

  const redeem = async () => {
    dialog.loading();
    let url = apiPath.reward.redeemReward;
    let params = { reward_id: data.id, amount: amount };

    let { status, response } = await post(url, params, true);
    if (!status) {
      dialog.alert({ message: response.data.error });
      return;
    }
    onClose && onClose();

    await dialog.alert({
      title: "แลกของรางวัลสำเร็จ",
      message:
        "ของรางวัลที่คุณแลกไว้จะถูกจัดส่งตามที่อยู่ที่ลงทะเบียนไว้กับทาง Rosgold Thailand ",
      topIcon: (
        <img
          src={correctImg}
          style={{ width: 48, height: 48, marginBottom: 24 }}
          alt={"notesIcondsdsdsd"}
        />
      ),
    });
  };
  return (
    <PopupBackdrop>
      <View style={Styles.box}>
        <View>
          <Text style={Styles.textTitle}>แลกของรางวัล</Text>
          <CloseButton
            color={"rgba(15, 16, 17, 1)"}
            style={{ position: "absolute", top: 5, right: 5 }}
            onPress={() => {
              onClose && onClose();
            }}
          />
        </View>
        <Divider />
        <ScrollView
          style={{ padding: 10 }}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <img
                src={data ? data.image : ""}
                alt={"reward"}
                style={{ width: "100%", borderRadius: 20 }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              style={{
                paddingLeft: 30,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <View>
                <GetRewardDisplayRow
                  label={"ชื่อของรางวัล"}
                  value={data ? data.name : ""}
                />
                <GetRewardDisplayRow
                  label={"ประเภทของรางวัล"}
                  value={data ? data.type : ""}
                />
                <GetRewardDisplayRow
                  label={"ระยะเวลาในการแลกรางวัล"}
                  value={
                    data
                      ? `${data.display_condition_open_date} - ${data.display_condition_close_date}`
                      : ""
                  }
                />
                <GetRewardDisplayRow
                  label={"จำนวนพอยท์ที่แลก"}
                  value={data ? Number(data.ticket_use).toLocaleString() : "0"}
                />
                <GetRewardDisplayRow
                  label={"ประเภทของผู้รับรางวัล"}
                  value={
                    data && Array.isArray(data.qualify_rank_name)
                      ? data.qualify_rank_name.join(", ")
                      : "Data not available"
                  }
                />
                {data.isViewOnly ? null : (
                  <React.Fragment>
                    <Divider />
                    <Text
                      style={{
                        fontWeight: "bold",
                        color: "rgba(15, 16, 17, 1)",
                      }}
                    >
                      ระบุจำนวนของรางวัลที่ต้องการแลก
                    </Text>
                    <View
                      style={{
                        flexDirection: "row",
                        marginTop: 10,
                        alignItems: "center",
                        marginBottom: 20,
                      }}
                    >
                      <TouchableOpacity
                        style={{
                          width: 32,
                          height: 32,
                          backgroundColor: "rgba(237, 238, 240, 1)",
                          borderRadius: 4,
                          marginRight: 20,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onPress={() => {
                          handleRecude();
                        }}
                      >
                        <IoMdRemove
                          style={{
                            fontSize: 24,
                            color: "rgba(105, 109, 121, 1)",
                          }}
                        />
                      </TouchableOpacity>
                      <Text
                        style={{
                          fontWeight: "bold",
                          color: "rgba(15, 16, 17, 1)",
                          textAlign: "center",
                        }}
                      >
                        {amount.toLocaleString()}
                      </Text>
                      <TouchableOpacity
                        style={{
                          width: 32,
                          height: 32,
                          backgroundColor: "rgba(255, 241, 241, 1)",
                          borderRadius: 4,
                          marginLeft: 20,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onPress={() => {
                          handleAdd();
                        }}
                      >
                        {" "}
                        <IoMdAdd
                          style={{
                            fontSize: 24,
                            color: "rgba(222, 124, 120, 1)",
                          }}
                        />
                      </TouchableOpacity>
                    </View>
                  </React.Fragment>
                )}
              </View>
            </Grid>
          </Grid>
          {data.isViewOnly ? (
            <React.Fragment />
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} />
              <Grid item xs={12} md={4}>
                <Button
                  buttonText={"แลกของรางวัล"}
                  color={"primary"}
                  style={{ width: "100%" }}
                  onClick={() => {
                    redeem();
                  }}
                />
              </Grid>
            </Grid>
          )}
        </ScrollView>
      </View>
    </PopupBackdrop>
  );
};

const GetRewardDisplayRow = (props) => {
  const { label, value } = props;
  return (
    <View style={{ flexDirection: "row", marginBottom: 10 }}>
      <Text style={{ flex: 0.4, color: "rgba(105, 109, 121, 1)" }}>
        {label}
      </Text>
      <Text
        style={{ flex: 0.6, color: "rgba(15, 16, 17, 1)", fontWeight: "bold" }}
      >
        {value}
      </Text>
    </View>
  );
};
const Styles = {
  box: {
    width: "90vw",
    maxHeight: "90vh",
    padding: 20,
    maxWidth: 1000,
    backgroundColor: "#FFF",
    borderRadius: 16,
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.08,
    shadowRadius: 16,
  },
  textTitle: {
    fontSize: 18,
    fontWeight: "bold",
    color: "rgba(15, 16, 17, 1)",
  },
};

export default PopupGetReward;
