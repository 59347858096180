import React, { useEffect, useState } from "react";
import { View, Text, ScrollView, TouchableOpacity } from "react-native-web";
import { Divider } from "rsuite";
import { Grid } from "@mui/material";
//component
import PopupBackdrop from "../../../components/modal/PopupBackdrop";
import CloseButton from "../../../components/button/CloseButton";
//image
import { useDialog } from "../../../components/modal/Popup";
import ReportCommissionLogTable from "./ReportComissionLogTable";
import apiPath from "../../../services/configs/apiPath";
import { post } from "../../../functions/fetch/fetch";
import ReportCommissionRetailProductCount from "./ReportComissionRetailProductCount";
import Loading from "../../../components/modal/Loading";
import LoadingTab from "../../../components/modal/LoadingTab";

const ReportCommissionLogPopup = (props) => {
  const dialog = useDialog();

  const { showLog } = props;
  const { onClose } = props;

  const [log, setLog] = useState([]);
  const [retailProduct, setRetailProduct] = useState([]);
  const [retailProductIsLoading, setRetailProductIsLoading] = useState(false);

  useEffect(() => {
    const downloadAll = async () => {
      await downloadCommissionLog();
      await downloadCommissionRetailProductCount();
    };
    if (showLog) {
      downloadAll();
    }
  }, [showLog]);

  const downloadCommissionLog = async () => {
    let url = apiPath.report.getComissionLog;
    const { status, response } = await post(
      url,
      { period: showLog.period, agentCode: showLog.agentCode },
      true
    );
    if (!status) {
      dialog.alert({ message: response.data.error });
      return;
    }
    console.log(response.data);
    if (response.data) {
      setLog(response.data);
    }
  };

  const downloadCommissionRetailProductCount = async () => {
    let url = apiPath.report.getComissionRetailProductCount;
    const { status, response } = await post(
      url,
      { period: showLog.period, agentCode: showLog.agentCode },
      true
    );
    if (!status) {
      dialog.alert({ message: response.data.error });
      return;
    }
    console.log("retail product count", response.data);
    if (response.data) {
      setRetailProduct(response.data);
      setRetailProductIsLoading(true);
    }
  };

  return (
    <PopupBackdrop>
      <View style={Styles.box}>
        <View>
          <Text style={Styles.textTitle}>ประวัติการค่าคอมมิชชั่น</Text>
          <CloseButton
            color={"rgba(15, 16, 17, 1)"}
            style={{ position: "absolute", top: 5, right: 5 }}
            onPress={() => {
              onClose && onClose();
            }}
          />
          <Divider />
        </View>
        <ScrollView>
          <View style={{ flex: 1 }}>
            <ReportCommissionLogTable tableData={log} />
          </View>
          <Text style={Styles.textTitle}>รายการขายสินค้า</Text>
          <Divider />
          <View style={{ flex: 1 }}>
            {!retailProductIsLoading ? (
              <LoadingTab />
            ) : (
              <ReportCommissionRetailProductCount tableData={retailProduct} />
            )}
          </View>
        </ScrollView>
      </View>
    </PopupBackdrop>
  );
};

const Styles = {
  box: {
    width: "90vw",
    maxHeight: "90vh",
    padding: 20,
    backgroundColor: "#FFF",
    borderRadius: 16,
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.08,
    shadowRadius: 16,
  },
  textTitle: {
    fontSize: 18,
    fontWeight: "bold",
    color: "rgba(15, 16, 17, 1)",
  },
};

export default ReportCommissionLogPopup;
