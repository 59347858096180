import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity, ScrollView } from "react-native-web";
import LinearGradient from "react-native-web-linear-gradient";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Animated } from "react-animated-css";
//functions
import { useWindowsSize } from "../../functions/screen/screenFunction";
import { isLogin } from "../../functions/validate/validate";
//Icons
import logo from "../../asset/images/logo/onelife-984x180.png";

import {
  HomeIcon,
  ProductIcon,
  ShoppingIcon,
  OrderIcon,
  LogisticIcon,
  MemberIcon,
  RewardIcon,
  CreditIcon,
  ReportIcon,
  SettingIcon,
  PolicyIcon,
  UniversityIcon,
  EventIcon,
  LogoutIcon,
} from "./homeIcons";
import Cookies from "js-cookie";
import { GearIcon } from "../../asset/images/icons/iconsLib";

const defaultColor = "#8A91A1";
const primaryColor = "#AA4942";
const disableColor = "#EBEBEB";

const MenuLeftPanel = (props) => {
  //property
  const size = useWindowsSize();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = props;
  const [ignorePage, setIgnorePage] = useState(["creditinfo", "creditHistory"]);

  //reducer
  const { userUseRole, showMenu } = useSelector((state) => {
    return state;
  });
  //state
  const [pages, setPages] = useState([]);
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (userUseRole) {
      setPages(Object.values(userUseRole));
      validPage(userUseRole);
    }
  }, [userUseRole, Cookies.get("rosegoldtoken")]);

  useEffect(() => {
    if (size.width >= 900) {
      dispatch({ type: "SHOWMENU", payload: false });
    }
  }, [size]);

  const logout = () => {
    Cookies.remove("token");
    Cookies.remove("rosegoldtoken");
    dispatch({ type: "SetUser", payload: null });
    navigate("/login");
  };

  const validPage = (useRole) => {
    if (useRole === undefined || useRole === null || useRole === "") {
      setShow(false);
      return;
    }
    if (!isLogin()) {
      setShow(false);
      return;
    }

    if (isLogin() && (pathname === "/" || pathname === "/login")) {
      setShow(true);
      dispatch({ type: "SetPage", payload: "home" });
      dispatch({ type: "SetSubPage", action: "" });
      return;
    }

    let _page = "";
    let _subPage = "";
    let userAllPage = [];
    Object.values(useRole).forEach((page) => {
      if (page.active === "Y") {
        userAllPage.push(page.value);
        if (`/${page.value}` === pathname) {
          _page = page.value;
        }
      }
      if (page.subMenu) {
        if (page.subMenu.length > 0) {
          page.subMenu.forEach((sub) => {
            if (sub.active === "Y") {
              userAllPage.push(sub.value);
              if (`/${sub.value}` === pathname) {
                _page = page.value;
                _subPage = sub.value;
              }
            }
          });
        }
      }
    });
    if (
      _page !== undefined &&
      _page !== null &&
      _subPage !== undefined &&
      _subPage !== null
    ) {
      dispatch({ type: "SetPage", payload: _page });
      dispatch({ type: "SetSubPage", payload: _subPage });
    }

    let checkInRole = userAllPage.find((d) => {
      return `/${d}` === `${pathname}`;
    });
    if (!checkInRole) {
      //can't Enter Page no access permission
      setShow(false);
      return;
    }
    setShow(true);
  };

  const componentRef = React.useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        dispatch({ type: "SHOWMENU", payload: false });
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [componentRef]);

  const packageJson = require('../../../package.json');


  return (
    <React.Fragment>
      {(size.width >= 900 && pages && show) || (showMenu && isLogin()) ? (
        <View
          ref={componentRef}
          style={{
            ...Styles.container,
            position: showMenu && isLogin() ? "fixed" : "relative",
            zIndex: 10,
            marginTop: showMenu && isLogin() ? "2.5%" : "1.5%",
          }}
          key={"MenuLeft"}
        >
          {showMenu && isLogin() ? null : (
            <React.Fragment>
              <img
                src={logo}
                style={{ width: 120, left: "1%", alignSelf: "center" }}
                alt={"logo"}
              />
              {/* <Text style={{fontSize:10,position:"absolute",right:10,top:20,color:"#333333"}}>{packageJson && packageJson.version}</Text> */}
              <View style={Styles.spaceHeight} />
            </React.Fragment>
          )}

          <View
            style={{
              ...Styles.menuContainer,
              backgroundColor: showMenu
                ? "rgba(255, 255, 255, 1)"
                : "rgba(255, 255, 255, 0.7)",
              height: showMenu && isLogin() ? "78vh" : "",
            }}
          >
            <View style={Styles.spaceHeight} />

            <ScrollView showsVerticalScrollIndicator={false}>
              <View>
                {pages && pages.length > 0
                  ? pages.map((page, index) => {
                      if (page.active === "N" || page.value === "credit") {
                        return null;
                      }
                      return (
                        <React.Fragment>
                          <Menu
                            key={"Menu_" + page.value}
                            value={page.value}
                            label={page.label}
                            active={page.active}
                            onSelect={(value) => {
                              if (page.subMenu) {
                                if (page.subMenu.length > 0) {
                                  dispatch({ type: "SetPage", payload: value });
                                  dispatch({
                                    type: "SetSubPage",
                                    payload: page.subMenu[0].value,
                                  });
                                  navigate(page.subMenu[0].value);
                                } else {
                                  if (page !== value) {
                                    navigate(value);
                                  }
                                  console.log("set page", value);
                                  dispatch({ type: "SetPage", payload: value });
                                }
                              }
                            }}
                            subMenu={page.subMenu}
                            onSelectSubMenu={(value) => {
                              dispatch({ type: "SetSubPage", payload: value });
                              navigate(value);
                            }}
                          />
                          <View
                            key={"line_" + page}
                            style={Styles.spaceHeight}
                          />
                        </React.Fragment>
                      );
                    })
                  : null}
              </View>
            </ScrollView>
          </View>

          <View style={Styles.spaceHeight} />
          <View style={Styles.menuContainer}>
            <MenuLogout onLogout={logout} />
          </View>
        </View>
      ) : null}
    </React.Fragment>
  );
};

const Menu = (props) => {
  const { label, value, subMenu, active } = props;
  const { onSelect, onSelectSubMenu } = props;
  const [hover, setHover] = useState(false);
  const { page, subPage } = useSelector((state) => {
    return state;
  });

  return (
    <View>
      <TouchableOpacity
        style={Styles.menuButton}
        onPress={() => {
          if (active !== "Y") {
            return;
          }
          onSelect && onSelect(value);
        }}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      >
        {page === value ? (
          <LinearGradient
            colors={["#CC7B7B", "#AA4942"]}
            useAngle="true"
            angle={180}
            style={{ ...Styles.menuLinear }}
          />
        ) : null}
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <RenderIcon
            label={label}
            isHover={hover || page === value}
            active={active}
          />
          <View style={{ height: 5 }} />
          <Text
            style={{
              fontSize: "clamp(10px, 3vw, 14px)",
              color:
                active !== "Y"
                  ? disableColor
                  : hover || page === value
                  ? primaryColor
                  : defaultColor,
            }}
          >
            {label ? label : ""}
          </Text>
        </View>
      </TouchableOpacity>
      {subMenu && subMenu.length > 0 && page === value ? (
        <SubMenu
          key={"subMenu_" + subMenu.value}
          isSelect={page === value}
          data={subMenu}
          subPage={subPage}
          onSelect={(value) => {
            onSelectSubMenu && onSelectSubMenu(value);
          }}
        />
      ) : null}
    </View>
  );
};

const SubMenu = (props) => {
  const { data, subPage } = props;
  const { onSelect } = props;

  const [subMenu, setSubMenu] = useState([]);

  useEffect(() => {
    if (data) {
      let init = [];
      data.forEach((item) => {
        if (item.active === "Y" && item.value !== "addProductCsv") {
          init.push(item);
        }
      });
      setSubMenu(init);
    }
  }, []);

  const StylesSubMenu = {
    circleSelect: {
      width: 10,
      height: 10,
      borderRadius: "50%",
      backgroundColor: "rgba(170, 73, 66, 1)",
    },
    circleSelectLast: {
      width: 10,
      height: 10,
      borderRadius: "50%",
      backgroundColor: "rgba(170, 73, 66, 1)",
    },
    circleDeSelect: {
      width: 10,
      height: 10,
      borderRadius: "50%",
      borderWidth: 2,
      borderColor: "rgba(138, 145, 161, 1)",
    },
    circleDeSelectLast: {
      width: 10,
      height: 10,
      borderRadius: "50%",
      borderWidth: 2,
      borderColor: "rgba(138, 145, 161, 1)",
    },
    textSelect: {
      flex: 1,
      color: "rgba(170, 73, 66, 1)",
      marginLeft: 8,
      marginTop: -5,
    },
    textSelectLast: {
      flex: 1,
      color: "rgba(170, 73, 66, 1)",
      marginLeft: 8,
      marginTop: -5,
    },
    textDeSelect: {
      flex: 1,
      color: "rgba(138, 145, 161, 1)",
      marginLeft: 8,
      marginTop: -5,
    },
    textDeSelectLast: {
      flex: 1,
      color: "rgba(138, 145, 161, 1)",
      marginLeft: 8,
      marginTop: -5,
    },
    lineSelect: {
      width: 2,
      height: 35,
      backgroundColor: "rgba(170, 73, 66, 1)",
    },
    lineDeSelect: {
      width: 2,
      height: 35,
      backgroundColor: "rgba(138, 145, 161, 1)",
    },
  };
  return (
    <View
      style={{
        backgroundColor: "rgba(251, 251, 251, 1)",
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 16,
        paddingRight: 16,
        justifyContent: "center",
      }}
    >
      <Animated animationIn="fadeIn" animationInDuration={400} isVisible={true}>
        {subMenu &&
          subMenu.map((item, index) => {
            if (item.value === "addProductCsv") {
              return null;
            }
            const isLastItem = index + 1 === subMenu.length;
            return (
              <View
                key={"subMenu_" + item.label}
                style={{ flexDirection: "row" }}
              >
                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <View
                    style={
                      item.value === subPage
                        ? isLastItem
                          ? StylesSubMenu.circleSelectLast
                          : StylesSubMenu.circleSelect
                        : isLastItem
                        ? StylesSubMenu.circleDeSelectLast
                        : StylesSubMenu.circleDeSelect
                    }
                  />
                  {!isLastItem ? (
                    <View
                      style={
                        isLastItem || item.value !== subPage
                          ? StylesSubMenu.lineDeSelect
                          : StylesSubMenu.lineSelect
                      }
                    />
                  ) : (
                    <View style={{ width: 2, height: 25 }} />
                  )}
                </View>
                <TouchableOpacity
                  style={{ flex: 1 }}
                  onPress={() => {
                    if (item.active !== "Y") {
                      return;
                    }
                    onSelect && onSelect(item.value);
                  }}
                >
                  <Text
                    style={
                      item.value === subPage
                        ? isLastItem
                          ? StylesSubMenu.textSelectLast
                          : StylesSubMenu.textSelect
                        : isLastItem
                        ? StylesSubMenu.textDeSelectLast
                        : StylesSubMenu.textDeSelect
                    }
                  >
                    {item.label}
                  </Text>
                </TouchableOpacity>
              </View>
            );
          })}
      </Animated>
    </View>
  );
};

const MenuLogout = (props) => {
  const [hover, setHover] = useState(false);
  const { onLogout } = props;

  return (
    <TouchableOpacity
      style={Styles.logoutContainer}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onPress={() => {
        onLogout && onLogout();
      }}
    >
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <LogoutIcon
          width={20}
          height={20}
          color={hover ? primaryColor : defaultColor}
        />
        <View style={{ height: 5 }} />
        <Text
          style={{ fontSize: 12, color: hover ? primaryColor : defaultColor }}
        >
          ออกจากระบบ
        </Text>
      </View>
    </TouchableOpacity>
  );
};

const RenderIcon = (props) => {
  const { label, isHover, active } = props;
  switch (label) {
    case "หน้าหลัก":
      return (
        <HomeIcon
          width={20}
          height={20}
          color={
            active !== "Y"
              ? disableColor
              : isHover
              ? primaryColor
              : defaultColor
          }
        />
      );
    case "สินค้า":
      return (
        <ProductIcon
          width={20}
          height={20}
          color={
            active !== "Y"
              ? disableColor
              : isHover
              ? primaryColor
              : defaultColor
          }
        />
      );
    case "ขายปลีกและลูกค้า":
      return (
        <ShoppingIcon
          width={20}
          height={20}
          color={
            active !== "Y"
              ? disableColor
              : isHover
              ? primaryColor
              : defaultColor
          }
        />
      );
    case "คำสั่งซื้อ":
      return (
        <OrderIcon
          width={20}
          height={20}
          color={
            active !== "Y"
              ? disableColor
              : isHover
              ? primaryColor
              : defaultColor
          }
        />
      );
    case "จัดส่งสินค้า":
      return (
        <LogisticIcon
          width={20}
          height={20}
          color={
            active !== "Y"
              ? disableColor
              : isHover
              ? primaryColor
              : defaultColor
          }
        />
      );
    case "สมาชิก":
      return (
        <MemberIcon
          width={20}
          height={20}
          color={
            active !== "Y"
              ? disableColor
              : isHover
              ? primaryColor
              : defaultColor
          }
        />
      );
    case "ของรางวัล":
      return (
        <RewardIcon
          width={20}
          height={20}
          color={
            active !== "Y"
              ? disableColor
              : isHover
              ? primaryColor
              : defaultColor
          }
        />
      );
    case "เครดิตร้านค้า":
      return (
        <CreditIcon
          width={20}
          height={20}
          color={
            active !== "Y"
              ? disableColor
              : isHover
              ? primaryColor
              : defaultColor
          }
        />
      );
    case "รายงาน":
      return (
        <ReportIcon
          width={20}
          height={20}
          color={
            active !== "Y"
              ? disableColor
              : isHover
              ? primaryColor
              : defaultColor
          }
        />
      );
    case "จัดการเว็บไซต์":
      return (
        <SettingIcon
          width={20}
          height={20}
          color={
            active !== "Y"
              ? disableColor
              : isHover
              ? primaryColor
              : defaultColor
          }
        />
      );
    case "เงื่อนไขและข้อบังคับ":
      return (
        <PolicyIcon
          width={20}
          height={20}
          color={
            active !== "Y"
              ? disableColor
              : isHover
              ? primaryColor
              : defaultColor
          }
        />
      );
    case "Rosegold University":
      return (
        <UniversityIcon
          width={20}
          height={20}
          color={
            active !== "Y"
              ? disableColor
              : isHover
              ? primaryColor
              : defaultColor
          }
        />
      );
    case "อีเว้นท์":
      return (
        <EventIcon
          width={20}
          height={20}
          color={
            active !== "Y"
              ? disableColor
              : isHover
              ? primaryColor
              : defaultColor
          }
        />
      );
    case "ตั้งค่า":
      return (
        <GearIcon
          width={20}
          height={20}
          color={
            active !== "Y"
              ? disableColor
              : isHover
              ? primaryColor
              : defaultColor
          }
        />
      );
    default:
      return;
  }
};

const Styles = {
  container: {
    flex: 1,
    width: 160,
    maxWidth: 160,
    height: "100%",
    margin: "1.5%",
  },
  menuContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
  },
  spaceHeight: {
    height: 20,
  },
  menuButton: {
    width: "100%",
    height: 45,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  menuLinear: {
    width: 6,
    height: 45,
    position: "absolute",
    top: 0,
    left: 0,
  },
  logoutContainer: {
    width: "100%",
    flexDirection: "row",
    paddingTop: 20,
    paddingBottom: 20,
  },
};

export default MenuLeftPanel;
