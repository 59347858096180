import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import { Grid, Typography } from "@mui/material";
import { Divider } from "rsuite";
import { useNavigate } from "react-router-dom";
import apiPath from "../../services/configs/apiPath";
import { get } from "../../functions/fetch/fetch";
//component

const Policy = () => {
  const navigate = useNavigate();

  const [policyHtml, setPolicyHtml] = useState("");

  useEffect(() => {
    downloadPolicy();
  }, []);

  const downloadPolicy = async () => {
    let url = apiPath.masterData.getPolicy;
    let { status, response } = await get(url, {}, false);
    if (response.data) {
      setPolicyHtml(response.data);
    }
  };

  return (
    <React.Fragment>
      <View style={Styles.container}>
        <Grid container style={Styles.topContainer} spacing={2}>
          <Grid item xs={12} md={8} lg={9} xl={10}>
            <View style={{ flex: 1 }}>
              <Text style={Styles.textTopContainer1}>เงื่อนไขและข้อบังคับ</Text>
              <Text style={Styles.textTopContainer2}>เงื่อนไขและข้อบังคับ</Text>
            </View>
          </Grid>
        </Grid>
        <View style={{ height: 30 }} />
        <View style={Styles.contentContainer}>
          <div
            dangerouslySetInnerHTML={{
              __html: `${policyHtml}`,
            }}
          />
        </View>
        <View style={{ height: 100 }} />
      </View>
    </React.Fragment>
  );
};

const Styles = {
  container: {
    flex: 1,
    height: "100vh",
    padding: "1.5%",
  },
  topContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  contentContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 30,
    margin: 0,
  },
  textTopContainer1: {
    fontSize: "clamp(18px, 3vw, 22px)",
    fontWeight: "bold",
    color: "#AA4942",
  },
  textTopContainer2: {
    fontSize: "clamp(12px, 3vw, 16px)",
    color: "#696D79",
  },
  textContainer1: {
    justifyContent: "center",
    alignItems: "center",
  },
  textTitle: {
    fontSize: 20,
    fontWeight: "bold",
  },
  subText1: {
    fontSize: 16,
    color: "#0F1011",
    textAlign: "center",
  },
  subText2: {
    fontSize: 14,
    color: "#0F1011",
  },
  subBoldText: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#0F1011",
  },
};
export default Policy;
