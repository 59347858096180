import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import { Grid } from "@mui/material";
import { Divider } from "rsuite";
import { useNavigate } from "react-router-dom";
//component
import Button from "../../components/button/Button";
import Dropdown from "../../components/dropdown/Dropdown";
import UploadImage from "../../components/uploadbox/UploadImage";
import TextField from "../../components/textField/TextField";
import TimePicker from "../../components/time/TimePicker";
import DatePicker from "../../components/calendar/DatePicker";
import CheckBoxList from "../../components/checkbox/CheckBoxList";
import CheckBoxListV2 from "../../components/checkbox/CheckBoxListV2";
import { get, post, upload } from "../../functions/fetch/fetch";
import apiPath from "../../services/configs/apiPath";
import { useDialog } from "../../components/modal/Popup";
import moment from "moment-timezone";
import {
  formatNumber,
  formatNumberString,
} from "../../functions/format/numberFormat";
import VideoUploadPreview from "../../components/uploadbox/VideoUploadPreview";
import {
  base64ToFile,
  dataURItoBlob,
} from "../../functions/format/convertFile";
import { useSelector } from "react-redux";
import { find } from "rsuite/esm/utils/ReactChildren";
const AddCourse = (props) => {
  //property
  const navigate = useNavigate();
  const dialog = useDialog();
  const { isEdit, isEditData } = props;
  const { onEditBack, onEditSuccess } = props;
  const { user } = useSelector((state) => {
    return state;
  });
  //state
  const [rankingList, setRankingList] = useState([]);
  const [isQualifyRank, setIsQualifyRank] = useState(false);

  const [data, setData] = useState({
    banner_image: null,
    link_video: null,
    class_name: null,
    class_open_time: null,
    class_close_time: null,
    point: 0,
    tel_context: null,
    other_social: null,
    location_class: null,
    link_map: null,
    detail: null,
    qualify_rank: [], // มาจาก table `rank_list`
    limit_register: 0, // กรณีที่เข้าเรียนได้แบบไม่จำกัดให้ระบุ 0
    imageFile: null,
    vdoFile: null,
  });

  useEffect(() => {
    downloadAllRankingList();
  }, []);

  useEffect(() => {
    if (rankingList.length > 0) {
      if (!isQualifyRank && isEdit) {
        let init = [];
        let qualify = isEditData.qualify_rank.split(",");
        rankingList.forEach((item) => {
          let filter = qualify.filter((d) => {
            return Number(d) === Number(item.value); // แก้ไขที่นี้: จาก d.value === d เป็น d.value === item.value
          });
          init.push({ ...item, active: filter.length > 0 ? "Y" : "N" });
        });

        setRankingList(init);

        setIsQualifyRank(true);
      }
    }
  }, [rankingList]);

  const validation = (values) => {
    let errors = [];
    if (!data.class_name) {
      errors.push("ชื่อคอร์สเรียน");
    }
    if (!data.class_open_time) {
      errors.push("วันที่เปิดคอร์สเรียน");
    }
    if (!data.class_close_time) {
      errors.push("วันที่สิ้นสุดคอร์สเรียน");
    }
    if (data.point === null) {
      errors.push("จำนวนพอยต์");
    }
    if (!data.tel_context) {
      errors.push("เบอร์โทรศัพท์ติดต่อ");
    }
    if (!data.other_social) {
      errors.push("ช่องทางติดต่ออื่นๆ");
    }
    if (!data.location_class) {
      errors.push("สถานที่เรียน");
    }

    if (!data.detail) {
      errors.push("รายละเอียด");
    }

    if (!data.vdoFile && !isEdit) {
      errors.push("วิดิโอ");
    }
    if (!data.imageFile && !isEdit) {
      errors.push("รูปภาพ");
    }
    if (!data.limit_register === null) {
      errors.push("จำนวนผู้ลงทะเบียน");
    }
    console.log(errors);
    return errors.length > 0 ? false : true;
  };

  const submit = async () => {
    dialog.loading();
    if (!validation()) {
      dialog.alert({ message: "กรุณากรอกข้อมูลให้ครบถ้วน" });
      return;
    }
    //check ranking
    let q = [];
    rankingList.forEach((item) => {
      if (item.active === "Y") {
        q.push(item.value);
      }
    });
    if (q.length === 0) {
      dialog.alert({ message: "กรุณาระบุระดับตัวแทนที่เข้าได้" });
      return;
    }

    //Upload
    const uploadImages = await upload(
      [data.imageFile],
      `${process.env["REACT_APP_ENV"]}/Images/Course/${user.agentCode}`
    );
    const resImg = uploadImages.response;
    if (!resImg.data) {
      await dialog.alert({
        title: "แจ้งเตือน",
        message: uploadImages.response.data.error,
      });
      return;
    }

    data.banner_image = resImg.data.data[0];
    data.qualify_rank = q;
    const response = await fetch(data.vdoFile);
    const blob = await response.blob();
    const uploadVdo = await upload(
      [blob],
      `${process.env["REACT_APP_ENV"]}/VDO/Course/${user.agentCode}`
    );
    const resVdo = uploadVdo.response;
    if (!resVdo.data) {
      await dialog.alert({
        title: "แจ้งเตือน",
        message: uploadVdo.response.data.error,
      });
      return;
    }
    data.link_video = resVdo.data.data[0];
    data.class_open_time = moment(data.class_open_time).toDate();
    data.class_close_time = moment(data.class_close_time).toDate();

    delete data.imageFile;
    delete data.vdoFile;

    let url = apiPath.university.createUniversity;
    let resCreate = await post(url, data, true);
    if (!resCreate.status) {
      await dialog.alert({ title: "แจ้งเตือน", message: response.data.error });
      dialog.cancle();
      return;
    }
    let alert = dialog.alert({ message: "สร้างคอร์สเรียนสำเร็จ" });
    if (!alert) {
      return;
    }
    navigate("/course");
  };

  const update = async () => {
    console.log("update", data);
    dialog.loading();
    if (!validation()) {
      dialog.alert({ message: "กรุณากรอกข้อมูลให้ครบถ้วน" });
      return;
    }
    //check ranking
    let q = [];
    rankingList.forEach((item) => {
      if (item.active === "Y") {
        q.push(item.value);
      }
    });
    if (q.length === 0) {
      dialog.alert({ message: "กรุณาระบุระดับตัวแทนที่เข้าได้" });
      return;
    }
    data.qualify_rank = q;

    //Upload
    if (data.imageFile) {
      const uploadImages = await upload(
        [data.imageFile],
        `${process.env["REACT_APP_ENV"]}/Images/Course/${user.agentCode}`
      );
      const resImg = uploadImages.response;
      if (!resImg.data) {
        await dialog.alert({
          title: "แจ้งเตือน",
          message: uploadImages.response.data.error,
        });
        return;
      }
      data.banner_image = resImg.data.data[0];
    }

    if (data.vdoFile) {
      const response = await fetch(data.vdoFile);
      const blob = await response.blob();
      const uploadVdo = await upload(
        [blob],
        `${process.env["REACT_APP_ENV"]}/VDO/Course/${user.agentCode}`
      );
      const resVdo = uploadVdo.response;
      if (!resVdo.data) {
        await dialog.alert({
          title: "แจ้งเตือน",
          message: uploadVdo.response.data.error,
        });
        return;
      }
      data.link_video = resVdo.data.data[0];
    }
    data.class_open_time = moment(data.class_open_time).toDate();
    data.class_close_time = moment(data.class_close_time).toDate();

    delete data.imageFile;
    delete data.vdoFile;

    let url = apiPath.university.updateUniversity;
    let resCreate = await post(url, data, true);
    if (!resCreate.status) {
      await dialog.alert({
        title: "แจ้งเตือน",
        message: "เกิดข้อผิดพลาด" + resCreate.response.data.error,
      });
      dialog.cancle();
      return;
    }
    let alert = await dialog.alert({ message: "บันทึกสำเร็จ" });
    if (!alert) {
      return;
    }
    onEditSuccess && onEditSuccess();
  };

  const downloadAllRankingList = async () => {
    let url = apiPath.member.getAllRankingList;
    let { status, response } = await get(url, {}, true);
    if (!status) {
      console.log(response);
      dialog.alert({ message: "เกิดข้อผิดพลาด" });
    }
    if (response.data) {
      let val = response.data;
      let init = [];
      let qualify_rank = [];
      val.forEach((element) => {
        init.push({
          value: element.id,
          label: element.name,
          enable: true,
          active: "Y",
        });
        qualify_rank.push(element.level);
      });
      setRankingList(init);
      setData({ ...data, qualify_rank: qualify_rank });
    }

    if (isEditData) {
      setData({
        ...data,
        ...isEditData,
        class_open_time: moment(isEditData.class_open_date),
        class_close_time: moment(isEditData.class_close_date),
      });
    }
  };

  return (
    <React.Fragment>
      <View style={Styles.container}>
        <Grid container style={Styles.topContainer} spacing={2}>
          <Grid item xs={12} md={8} lg={9} xl={10}>
            <View style={{ flex: 1 }}>
              <Text style={Styles.textTopContainer1}>คอร์สเรียน</Text>
              <Text style={Styles.textTopContainer2}>
                Rosegold university /{" "}
                {isEdit ? "แก้ไขคอร์สเรียน" : "เพิ่มคอร์สเรียน"}
              </Text>
            </View>
          </Grid>
        </Grid>
        <View style={{ height: 30 }} />
        <View style={Styles.contentContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8} lg={6} xl={4}>
              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    fontSize: "clamp(16px,2vh,20px)",
                    fontWeight: "bold",
                    color: "rgba(15, 16, 17, 1)",
                  }}
                >
                  {isEdit ? "แก้ไขคอร์สเรียน" : " สร้างคอร์สเรียน"}
                </Text>
              </View>
            </Grid>
          </Grid>
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <View
                style={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text style={{ fontSize: 16 }}>เพิ่มรูปคอร์สเรียน</Text>
                <View style={{ marginTop: 16 }} />
                <UploadImage
                  value={data.banner_image ? data.banner_image : ""}
                  placeholder={"เพิ่มรูปภาพ"}
                  style={{ width: 200, height: 200 }}
                  onChangeImage={(image) => {
                    setData({
                      ...data,
                      imageFile: base64ToFile(image),
                      banner_image: image,
                    });
                  }}
                />
                <View style={{ marginTop: 16 }} />
                <Text style={Styles.textImageMark}>
                  รองรับไฟล์ JPG, PNG, JPEGS เท่านั้น
                </Text>
              </View>
            </Grid>
          </Grid>
          <View style={{ height: 30 }} />

          <Grid container spacing={2}>
            <Grid item xs={12} md={6} style={{ zIndex: 20 }}>
              <Text style={Styles.titleText}>
                ชื่อคอร์สเรียน{" "}
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TextField
                  value={data ? data.class_name : ""}
                  iconName={""}
                  isInvalid={false}
                  placeholder={"ระบุชื่อคอร์สเรียน"}
                  errorMessage={""}
                  onChangeText={(value) => {
                    setData({ ...data, class_name: value });
                  }}
                />
              </View>
            </Grid>
            <Grid item xs={12} md={6} style={{ zIndex: 19 }}>
              <Text style={Styles.titleText}>
                วันที่เปิดคอร์สเรียน{" "}
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />
              <DatePicker
                showTime={true}
                date={data ? data.class_open_time : null}
                placeholder="วันที่เปิดคอร์สเรียน"
                styles={{ width: "100%", zIndex: 9999 }}
                onChange={(value) => {
                  setData({
                    ...data,
                    class_open_time: value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} md={6} style={{ zIndex: 19 }}>
              <Text style={Styles.titleText}>
                วันที่สิ้นสุดคอร์สเรียน{" "}
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />
              <DatePicker
                showTime={true}
                date={data ? data.class_close_time : null}
                placeholder="วันที่สิ้นสุดคอร์สเรียน"
                styles={{ width: "100%", zIndex: 9999 }}
                onChange={(value) => {
                  setData({
                    ...data,
                    class_close_time: value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} md={6} style={{ zIndex: 16 }}>
              <Text style={Styles.titleText}>
                จำนวนพอยต์/ค่าเข้าคอร์สเรียน{" "}
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />

              <TextField
                value={data ? data.point : 0}
                iconName={""}
                isInvalid={false}
                placeholder={"ระบุจำนวนพอยต์/ค่าเข้าคอร์สเรียน"}
                errorMessage={""}
                onChangeText={(value) => {
                  let num = formatNumber(value);
                  setData({
                    ...data,
                    point: num,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ zIndex: 15 }}>
              <Text style={Styles.titleText}>
                เบอร์โทรศัพท์ติดต่อ{" "}
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />

              <TextField
                value={data ? data.tel_context : ""}
                iconName={""}
                isInvalid={false}
                placeholder={"ระบุเบอร์โทรศัพท์ติดต่อ"}
                errorMessage={""}
                onChangeText={(value) => {
                  let num = formatNumberString(value);
                  setData({ ...data, tel_context: num });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ zIndex: 14 }}>
              <Text style={Styles.titleText}>
                ช่องทางติดต่ออื่นๆ{" "}
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />

              <TextField
                value={data ? data.other_social : ""}
                iconName={""}
                isInvalid={false}
                placeholder={"ระบุช่องทางติดต่ออื่นๆ"}
                errorMessage={""}
                onChangeText={(value) => {
                  setData({
                    ...data,
                    other_social: value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ zIndex: 13 }}>
              <Text style={Styles.titleText}>
                สถานที่เรียน{" "}
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />
              <TextField
                value={data ? data.location_class : ""}
                iconName={""}
                isInvalid={false}
                placeholder={"ระบุสถานที่เรียน"}
                errorMessage={""}
                onChangeText={(value) => {
                  setData({
                    ...data,
                    location_class: value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ zIndex: 12 }}>
              <Text style={Styles.titleText}>
                ลิงก์สถานที่เรียน (ถ้ามี){" "}
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />
              <TextField
                value={data ? data.link_map : ""}
                iconName={""}
                isInvalid={false}
                placeholder={"ระบุลิงก์สถานที่เรียน (ถ้ามี)"}
                errorMessage={""}
                onChangeText={(value) => {
                  setData({
                    ...data,
                    link_map: value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} style={{ zIndex: 11 }}>
              <Text style={Styles.titleText}>
                รายละเอียดคอร์สเรียน{" "}
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />

              <TextField
                value={data ? data.detail : ""}
                iconName={""}
                isInvalid={false}
                placeholder={"ระบุรายละเอียดคอร์สเรียน" + data.link_video}
                errorMessage={""}
                onChangeText={(value) => {
                  setData({ ...data, detail: value });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <VideoUploadPreview
                value={data ? data.link_video : ""}
                onChange={(src) => {
                  setData({
                    ...data,
                    vdoFile: src,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Text
                style={{
                  fontSize: "clamp(16px,2vh,20px)",
                  fontWeight: "bold",
                  color: "rgba(15, 16, 17, 1)",
                }}
              >
                เงื่อนไขการเข้าร่วม{" "}
              </Text>
            </Grid>
          </Grid>
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} style={{ zIndex: 9 }}>
              <Text style={Styles.titleText}>
                จำนวนผู้ลงทะเบียน{" "}
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />

              <TextField
                value={data ? data.limit_register : 0}
                iconName={""}
                isInvalid={false}
                placeholder={"ระบุจำนวนผู้ลงทะเบียน"}
                errorMessage={""}
                onChangeText={(value) => {
                  let num = formatNumber(value);
                  setData({ ...data, limit_register: num });
                }}
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: 10, zIndex: 9 }}>
              <View
                style={{
                  borderWidth: 1,
                  borderColor: "rgba(213, 215, 221, 1)",
                  borderRadius: 8,
                  padding: 10,
                }}
              >
                <Text>ระดับตัวแทนที่เข้าได้</Text>
                <CheckBoxListV2
                  list={rankingList}
                  showLine={false}
                  onCheck={(values) => {
                    console.log(values);
                    let findIndex = rankingList.findIndex((item) => {
                      return item.value === values.value;
                    });
                    let init = rankingList;
                    init[findIndex] = { ...values };
                    //findIndex
                    setRankingList([...init]);
                  }}
                />
              </View>
            </Grid>
          </Grid>
          <View style={{ height: 40 }} />
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              buttonText={"ยกเลิก"}
              color={"primary"}
              style={{ flex: 1, maxWidth: 400 }}
              isBorder={true}
              onClick={() => {
                if (isEdit) {
                  onEditBack && onEditBack();
                } else {
                  navigate("/course");
                }
              }}
            />
            <View style={{ width: 20 }} />
            <Button
              buttonText={isEdit ? "บันทึก" : "สร้างคอร์สเรียน"}
              color={"primary"}
              style={{ flex: 1, maxWidth: 400 }}
              isBorder={false}
              onClick={() => {
                if (isEdit) {
                  update();
                } else {
                  submit();
                }
              }}
            />
          </View>
          <View style={{ height: 40 }} />
        </View>
        <View style={{ height: 100 }} />
      </View>
    </React.Fragment>
  );
};

const Styles = {
  container: {
    flex: 1,
    height: "100vh",
    padding: "1.5%",
  },
  topContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  contentContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  textTopContainer1: {
    fontSize: "clamp(18px, 3vw, 22px)",
    fontWeight: "bold",
    color: "#AA4942",
  },
  textTopContainer2: {
    fontSize: "clamp(12px, 3vw, 16px)",
    color: "#696D79",
  },
  topButtonText: {
    fontSize: "clamp(14px, 3vw, 18px)",
  },
  titleSearch: {
    fontSize: 20,
    color: "#0F1011",
  },
  subTitleSearch: {
    fontSize: 16,
    color: "#0F1011",
    marginTop: 8,
  },
};
export default AddCourse;
