import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@mui/material";
import { StylesContext } from "@material-ui/styles";
//components
import DropdownMenuPopover from "../../../components/dropdown/DropdownPopover";
import PageButton from "../../../components/button/PageButton";
//images
import logo from "../../../asset/images/example/product.png";
//functions
import { formatNumber } from "../../../functions/format/numberFormat";
import PageButtonNavigate from "../../../components/button/PageButtonNavigate";

const CustomerInfoTable = (props) => {
  const { tableHeader = [], tableData = ["", ""] } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const pagesCount = Math.ceil(tableData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const tableDataSliced = tableData.slice(startIndex, endIndex);

  const { onSelect } = props;

  return (
    <View style={{ width: "100%", height: "100%" }}>
      <TableContainer sx={styles.tableContainer}>
        <Table sx={styles.table}>
          <TableHead sx={{ position: "sticky", top: 0, zIndex: 9999 }}>
            <TableRow style={{ width: "100%", backgroundColor: "#FFF" }}>
              <TableCell sx={{ ...styles.headerCell }}>
                ชื่อ - นามสกุล
              </TableCell>
              <TableCell sx={{ ...styles.headerCell }}>อีเมล</TableCell>
              <TableCell sx={{ ...styles.headerCell }}>เบอร์โทรศัพท์</TableCell>
              <TableCell sx={{ ...styles.headerCell }}>ที่อยู่</TableCell>
              <TableCell sx={{ ...styles.headerCell }}>ประเทศ</TableCell>
              <TableCell sx={{ ...styles.headerCell }}>
                สถานะการดำเนินการ
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableDataSliced.map((row) => {
              console.log(row);
              return (
                <TableRow key={row.id} sx={styles.tableRow}>
                  <TableCell sx={styles.tableCell}>
                    <Text>{row.name ? row.name : ""}</Text>
                  </TableCell>
                  <TableCell sx={styles.tableCell}>
                    <Text>{row.email ? row.email : ""}</Text>
                  </TableCell>
                  <TableCell sx={styles.tableCell}>
                    <Text>{row.phoneNumber ? row.phoneNumber : ""}</Text>
                  </TableCell>
                  <TableCell sx={styles.tableCell}>
                    <Text>{row.address ? row.address : ""}</Text>
                  </TableCell>
                  <TableCell sx={styles.tableCell}>
                    <Text>ไทย</Text>
                  </TableCell>
                  <TableCell sx={styles.tableCell}>
                    <DropdownMenuPopover title={"กำลังดำเนินการ"} list={[]} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <PageButtonNavigate
        currentPage={currentPage}
        pagesCount={pagesCount}
        startIndex={startIndex}
        endIndex={endIndex}
        dataCount={tableData.length}
        onSelectPage={(page) => {
          setCurrentPage(page);
        }}
      />
    </View>
  );
};

const styles = {
  tableContainer: {
    overflowY: "scroll",
    scrollbarWidth: "thin",
    scrollbarColor: "#c7c7c7 #f5f5f5",
    flex: 1,
  },
  table: {
    border: "none",
    minWidth: 650,
  },
  tableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: "rgba(255, 255, 255, 1)",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "rgba(255, 241, 241, 1)",
    },
  },
  tableCell: {
    borderBottom: "none",
    whiteSpace: "nowrap",
    wordBreak: "break-word",
  },
  headerCell: {
    color: "rgba(165, 88, 90, 1)",
  },
};

export default CustomerInfoTable;
