import React, { useState, useRef, useEffect } from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
//Images
import { PlusCircleIcon } from "../../asset/images/icons/iconsLib";
import { FiPlus } from "react-icons/fi";

const UploadImage = (props) => {
  const { style, value, placeholder = "เพิ่มรูปโปรไฟล์" } = props;
  const { onChangeImage } = props;

  const [imageUrl, setImageUrl] = useState(null);

  const fileInputRef = useRef(null);

  useEffect(() => {
    if (value) {
      setImageUrl(value);
    }
  }, [value]);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setImageUrl(reader.result);
      onChangeImage && onChangeImage(reader.result);
    };
    reader.readAsDataURL(file);
    // do something with the selected file here
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setImageUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <TouchableOpacity
      activeOpacity={0.9}
      style={{ ...Styles.container, ...style }}
      onPress={() => {
        handleButtonClick();
      }}
    >
      <input
        ref={fileInputRef}
        type="file"
        style={{ display: "none" }}
        onChange={handleFileChange}
        accept="image/png,image/jpeg"
      />
      <div
        style={{ ...Styles.box }}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <View style={{ ...Styles.content }}>
          {imageUrl ? (
            <img
              src={
                imageUrl instanceof File
                  ? URL.createObjectURL(imageUrl)
                  : imageUrl
              }
              alt={"profile"}
              style={{
                width: "100%",
                objectFit: "cover",
                overflow: "hidden",
                borderWidth: 1,
              }}
            />
          ) : (
            <View style={{ flexDirection: "row" }}>
              <FiPlus style={Styles.icon} />
              <View style={{ width: 10 }} />
              <Text style={Styles.text2}>{placeholder}</Text>
            </View>
          )}
        </View>
      </div>
    </TouchableOpacity>
  );
};

const Styles = {
  container: {
    width: "100%",
    borderStyle: "dashed",
    borderWidth: 1,
    borderColor: "rgba(213, 215, 221, 1)",
    padding: 4,
    borderRadius: 4,
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  box: {
    width: "100%",
    padding: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  boxUpload: {},
  icon: { color: "rgba(222, 124, 120, 1)", fontSize: 25 },
  text1: {
    fontWeight: "bold",
    color: "rgba(10, 10, 10, 1)",
  },
  text2: {
    fontWeight: "bold",
    color: "rgba(222, 124, 120, 1)",
    fontSize: 18,
  },
  textPlaceHolder: {
    color: "rgba(185, 189, 199, 1)",
  },
};

export default UploadImage;
