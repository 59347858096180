import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import { Grid } from "@mui/material";
import { Divider } from "rsuite";
import { useNavigate } from "react-router-dom";
//component
import { useDialog } from "../../components/modal/Popup";
import { RiDeleteBinLine } from "react-icons/ri";
import Button from "../../components/button/Button";
//PAGES
import SettingAddProfile from "./SettingAddProfile";
import apiPath from "../../services/configs/apiPath";
import { get, post, put } from "../../functions/fetch/fetch";
import Dropdown from "../../components/dropdown/Dropdown";

const SettingProfile = () => {
  //property
  const navigate = useNavigate();
  const dialog = useDialog();
  //state
  const [list, setList] = useState([]);
  const [editData, setEditData] = useState(null);
  const [add, setAdd] = useState(false);
  useEffect(() => {
    downloadAddress();
  }, []);

  const downloadAddress = async () => {
    let url = apiPath.member.getTxUserAddress;
    let { status, response } = await get(url, {}, true);
    if (!status) {
      dialog.alert({ message: response.data.error });
      return;
    }
    if (response.data) {
      setList(response.data);
    }
  };

  const handleDelete = async (values) => {
    let confirm = await dialog.confirm({
      title: "ยืนยันการลบที่อยู่ ใช่ หรือ ไม่ ?",
      message: "ยืนยันการลบ",
    });
    if (!confirm) {
      return;
    }

    let url = apiPath.member.updateTxUserAddressActive;
    let { status, response } = await put(url, { ...values, active: "D" }, true);
    if (!status) {
      dialog.alert({ message: response.data.error });
      return;
    }
    downloadAddress();
    return;
  };

  return (
    <React.Fragment>
      <View style={Styles.container}>
        <Grid container style={Styles.topContainer} spacing={2}>
          <Grid item xs={12} md={8} lg={9} xl={10}>
            <View style={{ flex: 1 }}>
              <Text style={Styles.textTopContainer1}>แก้ไขข้อมูลส่วนตัว</Text>
              <Text style={Styles.textTopContainer2}>
                ตั้งค่า / ข้อมูลส่วนตัว
              </Text>
            </View>
          </Grid>
        </Grid>
        <View style={{ height: 30 }} />
        <View style={Styles.contentContainer}>
          {add || editData ? (
            <SettingAddProfile
              editData={editData}
              onClose={() => {
                setAdd(false);
                setEditData(null);
                downloadAddress();
              }}
            />
          ) : (
            <MainSettingProfile
              data={list}
              onAdd={() => {
                setAdd(true);
              }}
              onEdit={(values) => {
                setEditData(values);
              }}
              onDelete={(values) => {
                handleDelete(values);
              }}
              onRefresh={() => {
                downloadAddress();
              }}
            />
          )}
        </View>
      </View>
    </React.Fragment>
  );
};

const MainSettingProfile = (props) => {
  const { data, onAdd } = props;
  const { onEdit, onDelete, onRefresh } = props;

  const handleAdd = async () => {
    onAdd && onAdd();
  };

  const handleEdit = async (values) => {
    onEdit && onEdit(values);
  };

  const handleDelete = async (values) => {
    onDelete && onDelete(values);
  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} md={10}>
          <Text style={Styles.title1}>ข้อมูลส่วนตัว</Text>
        </Grid>
        <Grid item xs={12} md={2}>
          <Button
            buttonText={"เพิ่มที่อยู่ใหม่"}
            color={"primary"}
            style={{ width: "100%" }}
            isBorder={true}
            onClick={() => {
              handleAdd();
            }}
          />
        </Grid>
      </Grid>
      <View style={{ height: 20 }} />
      <View style={Styles.addressBoxContent}>
        <Text style={Styles.title2}>ที่อยู่ทั้งหมด</Text>
        <Divider />
        <View>
          {data.length === 0 ? (
            <Text style={{ alignSelf: "center" }}>ยังไม่มีรายการ</Text>
          ) : null}
          {data.map((item, index) => {
            return (
              <AddressBox
                key={"AddressBox_" + Number(index).toLocaleString()}
                index={data.length * 10 - index}
                data={item}
                onEdit={() => {
                  handleEdit(item);
                }}
                onDelete={() => {
                  handleDelete(item);
                }}
                onRefresh={() => {
                  onRefresh && onRefresh();
                }}
              />
            );
          })}
        </View>
      </View>
    </React.Fragment>
  );
};

const AddressBox = (props) => {
  const { data, index } = props;
  const { onEdit, onDelete, onRefresh } = props;
  const dialog = useDialog();

  const addressList = [
    { value: "Y", label: "ที่อยู่ตามบัตรประชาชน (สำหรับหัก ณ ที่จ่าย)" },
    { value: "S", label: "ที่อยู่ปัจจุบัน" },
    { value: "P", label: "ที่อยู่สำหรับจัดส่งเอกสาร" },
    { value: "N", label: "ที่อยู่อื่นๆ" },
  ];

  const handleChange = async (active) => {
    if (!active) {
      return;
    }
    let confirm = await dialog.confirm({
      title: "ยืนยันการเปลี่ยนแปลงประเภทที่อยู่ ใช่ หรือ ไม่ ?",
      message: "ยืนยันการเปลี่ยนแปลงประเภทที่อยู่",
    });
    if (!confirm) {
      return;
    }

    let url = apiPath.member.updateTxUserAddressActive;
    let { status, response } = await put(
      url,
      { ...data, active: active },
      true
    );
    if (!status) {
      dialog.alert({ message: response.data.error });
      return;
    }
    onRefresh && onRefresh();
  };

  return (
    <View style={{ ...Styles.addressBoxContainer, zIndex: index }}>
      <View style={{ flex: 1 }}>
        <View style={{ ...Styles.addressBoxHeader, zIndex: index }}>
          <View style={{ flex: 1, maxWidth: 300, marginBottom: 15 }}>
            <Dropdown
              value={data.active}
              valueType={"string"}
              list={addressList}
              onChange={(values) => {
                const { value } = values;
                handleChange(value);
              }}
            />
          </View>
        </View>
        <Text>{`${data.address} ${data.subdistrict} ${data.district} ${data.province} ${data.zipcode}`}</Text>
      </View>
      <View style={{ marginLeft: 10, flexDirection: "row" }}>
        <TouchableOpacity
          onPress={() => {
            onEdit && onEdit();
          }}
        >
          <Text style={Styles.iconEdit}>แก้ไข</Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            onDelete && onDelete();
          }}
        >
          <RiDeleteBinLine style={Styles.iconDelete} />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const Styles = {
  container: {
    flex: 1,
    height: "100vh",
    padding: "1.5%",
  },
  topContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  contentContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  textTopContainer1: {
    fontSize: "clamp(18px, 3vw, 22px)",
    fontWeight: "bold",
    color: "#AA4942",
  },
  textTopContainer2: {
    fontSize: "clamp(12px, 3vw, 16px)",
    color: "#696D79",
  },
  topButtonText: {
    fontSize: "clamp(14px, 3vw, 18px)",
  },
  title1: {
    fontSize: 20,
    fontWeight: "bold",
    color: "rgba(15, 16, 17, 1)",
  },
  title2: {
    flex: 1,
    fontSize: 18,
    fontWeight: "bold",
    color: "rgba(15, 16, 17, 1)",
  },
  title3: {
    fontSize: 16,
    fontWeight: "bold",
    color: "rgba(15, 16, 17, 1)",
  },
  markTitle: {
    fontSize: 14,
    color: "rgba(105, 109, 121, 1)",
    marginTop: 4,
    marginLeft: 10,
  },
  addressBoxContainer: {
    borderWidth: 1,
    borderColor: "rgba(213, 215, 221, 0.5)",
    borderRadius: 4,
    padding: 12,
    flexDirection: "row",
    marginBottom: 20,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  addressBoxHeader: { flex: 1, flexDirection: "row", alignItems: "center" },
  addressBoxContent: {
    borderWidth: 1,
    borderColor: "rgba(213, 215, 221, 0.5)",
    borderRadius: 4,
    padding: 20,
  },
  iconEdit: { fontWeight: "bold", textDecorationLine: "underline" },
  iconDelete: {
    fontSize: 18,
    color: "rgba(227, 5, 5, 1)",
    marginLeft: 10,
  },
};
export default SettingProfile;
