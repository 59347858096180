import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Text, View } from "react-native-web";
import LinearGradient from "react-native-web-linear-gradient";
import { get } from "../../functions/fetch/fetch";
//Images
import profileMock from "../../asset/images/home/profileMock.png";
import crowIcon from "../../asset/images/home/crown.png";
import noImage from "../../asset/images/register/user.png";

//Component
import SalesContainer1 from "./SalesContainer1";
import SalesContainer2 from "./SalesContainer2";
import SalesContainer3 from "./SalesContainer3";
import SalesContainer4 from "./SalesContainer4";
import SalesContainer5 from "./SalesContainer5";
import apiPath from "../../services/configs/apiPath";

const HomeTopContainer = (props) => {
  const { userInfo } = props;
  const [totalAgent, setTotalAgent] = useState(0);

  useEffect(() => {
    getTotalAgent();
  }, []);

  const getTotalAgent = async () => {
    let url = apiPath.member.getTotalAgentInTeamPath;
    let res = await get(url, {}, true);
    console.log(res.response);
    if (res.response.data) {
      setTotalAgent(res.response.data);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} sm={6} md={4} lg={2}>
        <ProfileOverview userInfo={userInfo} />
        <View style={Styles.gird1BoxAgentCount}>
          <Text style={Styles.grid1_2Text1}>BFF ทั้งหมดของคุณ</Text>
          <Text style={Styles.grid1_2Text2}>
            {Number(totalAgent).toLocaleString()}
          </Text>
          <Text style={Styles.grid1_2Text1}>คน</Text>
        </View>
      </Grid>
      <Grid item xs={6} sm={6} md={4} lg={2}>
        <SalesContainer1 />
      </Grid>
      <Grid item xs={6} sm={6} md={4} lg={2} style={{ zIndex: 10 }}>
        <SalesContainer2 />
      </Grid>
      <Grid item xs={6} sm={6} md={4} lg={2} style={{ zIndex: 9 }}>
        <SalesContainer3 />
      </Grid>
      <Grid item xs={6} sm={6} md={4} lg={2}>
        <SalesContainer4 />
      </Grid>
      <Grid item xs={6} sm={6} md={4} lg={2}>
        <SalesContainer5 />
      </Grid>
    </Grid>
  );
};

const ProfileOverview = (props) => {
  const { userInfo } = props;

  const [rankingInfo, setRankingInfo] = useState({});

  useEffect(() => {
    downloadRankingInfo();
  }, []);

  const downloadRankingInfo = async () => {
    let url = apiPath.member.getUserTopRanking;
    let { status, response } = await get(url, {}, true);
    if (!status) {
      return;
    }
    if (response.data) {
      setRankingInfo(response.data);
    }
  };

  return (
    <LinearGradient
      style={Styles.linearContainer1}
      colors={["rgba(253,247,247,0.3)", "rgba(255,155,5,0.3)"]}
      useAngle="true"
      angle={180}
    >
      <img src={crowIcon} alt={"profile"} style={Styles.crownImg} />
      <img
        src={userInfo.imageProfile ? userInfo.imageProfile : noImage}
        alt={"profile"}
        style={Styles.gird1ProfileImg}
      ></img>
      <View style={{ height: 0 }} />
      <Text style={Styles.grid1TextTitle}>
        {rankingInfo ? rankingInfo.name : ""}
      </Text>
      <Text style={Styles.grid1TextName}>{userInfo ? userInfo.name : ""}</Text>
      <View style={Styles.gridBoxAgentCode}>
        {userInfo ? (
          <Text style={Styles.grid1TextAgentCode}>
            รหัส : {userInfo.agentCode}
          </Text>
        ) : null}
      </View>
    </LinearGradient>
  );
};
const Styles = {
  container: {
    margin: 0,
    marginTop: 20,
  },
  linearContainer1: {
    width: "100%",
    boxShadow: "16px 16px 24px 0px rgba(0, 0, 0, 0.04)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    marginTop: 20,
    paddingBottom: 30,
  },
  crownImg: {
    width: 30,
    height: 30,
    top: -40,
    position: "absolute",
  },
  gird1ProfileImg: {
    width: 80,
    height: 80,
    top: -15,
    position: "absolute",
    backgroundColor: "#9C9C9C",
    borderRadius: "50%",
    border: "5px solid #FFBF66",
  },
  grid1TextTitle: {
    fontSize: "clamp(12px,3vw,16px)",
    fontWeight: "bold",
    color: "#AA4942",
    marginTop: 65,
  },
  grid1TextName: {
    fontSize: "clamp(14px,3vw,18px)",
    fontWeight: "bold",
    color: "#0F1011",
    marginTop: 12,
  },
  gridBoxAgentCode: {
    width: "100%",
    backgroundColor: "#AA4942",
    padding: 8,
    borderRadius: 8,
    marginTop: 12,
    justifyContent: "center",
    alignItems: "center",
  },
  grid1TextAgentCode: {
    fontSize: "clamp(12px,3vw,16px)",
    fontWeight: "bold",
    color: "#FFFFFF",
  },
  gird1BoxAgentCount: {
    marginTop: 20,
    padding: 30,
    backgroundColor: "rgba(253,247,247,0.7)",
    boxShadow: "16px 16px 24px 0px rgba(0, 0, 0, 0.04)",
    borderRadius: 24,
    justifyContent: "center",
    alignItems: "center",
  },
  grid1_2Text1: {
    fontSize: "clamp(12px,3vw,16px)",
    color: "#0F1011",
  },
  grid1_2Text2: {
    fontSize: "clamp(28px,3vw,32px)",
    fontWeight: "bold",
    color: "#0F1011",
    margin: 8,
  },
};
export default HomeTopContainer;
