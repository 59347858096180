import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import { Grid } from "@mui/material";
import { Divider } from "rsuite";
import { useNavigate } from "react-router-dom";
//component
import Button from "../../components/button/Button";
import Dropdown from "../../components/dropdown/Dropdown";
import SearchBox from "../../components/textField/SearchBox";
import EventContainer from "./component/EventContainer";
import EventViewAdmin from "./component/EventViewAdmin";
import apiPath from "../../services/configs/apiPath";
import { get } from "../../functions/fetch/fetch";
import { useDialog } from "../../components/modal/Popup";
import EventRegisterPopup from "./component/EventRegisterPopup";
import EmptyList from "../../components/pages/EmptyList";
import EventBox from "./component/EventBox";
import { useSelector } from "react-redux";
//images
import { FiEdit } from "react-icons/fi";
import AddEvent from "./AddEvent";
import { set } from "firebase/database";
import LoadingTab from "../../components/modal/LoadingTab";

const EventList = () => {
  const navigate = useNavigate();
  const dialog = useDialog();
  const { user } = useSelector((state) => {
    return state;
  });

  const [eventList, setEventList] = useState([]);
  const [eventLoadSuccess, setEventLoadSuccess] = useState();
  const [showEventDetailAdmin, setShowEventDetailAdmin] = useState();
  const [showRegitser, setShowRegister] = useState(false);
  const [editData, setEditData] = useState(null);

  useEffect(() => {
    const downloadAll = async () => {
      await downloadEventList();
    };
    downloadAll();
  }, []);

  const downloadEventList = async () => {
    let url = apiPath.event.getAllEvent;
    const { status, response } = await get(url, {}, true);
    if (!status) {
      dialog.alert({ message: response.data.error });
      return;
    }
    console.log("event download", response);
    if (response.data) {
      setEventList(response.data);
    } else {
      setEventList([]);
    }
    setEventLoadSuccess(true);
  };

  return (
    <React.Fragment>
      {editData ? (
        <AddEvent
          editData={editData}
          onClose={() => {
            setEditData(null);
            setShowEventDetailAdmin(null);
            downloadEventList();
          }}
        />
      ) : (
        <React.Fragment>
          {showRegitser ? (
            <EventRegisterPopup
              data={showRegitser}
              onClose={() => {
                setShowRegister(null);
                downloadEventList();
              }}
            />
          ) : null}
          <View style={Styles.container}>
            <Grid container style={Styles.topContainer} spacing={2}>
              <Grid item xs={12} md={8} lg={9} xl={10}>
                <View style={{ flex: 1 }}>
                  <Text style={Styles.textTopContainer1}>อีเวนท์</Text>
                  <Text style={Styles.textTopContainer2}>อีเวนท์บริษัท</Text>
                </View>
              </Grid>
              {(user && user.userRole === "S") || user.userRole === "SS" ? (
                <Grid item xs={12} md={4} lg={3} xl={2}>
                  <Button
                    buttonText={"เพิ่มอีเวนท์"}
                    color={"primary"}
                    style={{ width: "100%" }}
                    styleText={Styles.topButtonText}
                    isBorder={false}
                    onClick={() => {
                      navigate("/addEvent");
                    }}
                  />
                </Grid>
              ) : null}
            </Grid>
            <View style={{ height: 30 }} />
            {showEventDetailAdmin ? (
              <EventViewAdmin
                data={showEventDetailAdmin}
                onClose={() => {
                  setShowEventDetailAdmin(null);
                  downloadEventList();
                }}
                onEdit={(data) => {
                  setEditData(data);
                }}
              />
            ) : (
              <EventListContainer
                eventLoadSuccess={eventLoadSuccess}
                data={eventList}
                onView={(data) => {
                  console.log("ONVIEW");
                  console.log(user);
                  if (user.userRole === "S" || user.userRole === "SS") {
                    setShowEventDetailAdmin(data);
                  } else {
                    setShowRegister(data);
                  }
                }}
                onEdit={(data) => {
                  setEditData(data);
                }}
              />
            )}
          </View>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const EventListContainer = (props) => {
  const { data = [], onView, eventLoadSuccess = false, onEdit } = props;
  return (
    <View style={Styles.contentContainer}>
      <Grid container spacing={2} style={{ marginBottom: 15 }}>
        <Grid item xs={12} lg={4} style={{ zIndex: 10 }}>
          <View style={{ height: 8 }} />
          <SearchBox placeholder="ค้นหา" />
        </Grid>

        <Grid item xs={12} lg={2}>
          <View style={{ height: 8 }} />
          <Button
            buttonText={"ค้นหา"}
            color={"primary"}
            style={{ width: "100%" }}
            onClick={() => {}}
          />
        </Grid>
      </Grid>
      <Divider />
      {eventLoadSuccess ? (
        data.length === 0 ? (
          <EmptyList />
        ) : (
          <Grid container spacing={2}>
            {data.map((item) => {
              return (
                <EventBox
                  data={item}
                  onView={(data) => {
                    onView && onView(data);
                  }}
                  onEdit={(data) => {
                    onEdit && onEdit(data);
                  }}
                />
              );
            })}
          </Grid>
        )
      ) : (
        <LoadingTab />
      )}
    </View>
  );
};

const Styles = {
  container: {
    flex: 1,
    height: "100vh",
    padding: "1.5%",
  },
  topContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  contentContainer: {
    width: "100%",
    minHeight: "100vh",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  textTopContainer1: {
    fontSize: "clamp(18px, 3vw, 22px)",
    fontWeight: "bold",
    color: "#AA4942",
  },
  textTopContainer2: {
    fontSize: "clamp(12px, 3vw, 16px)",
    color: "#696D79",
  },
  topButtonText: {
    fontSize: "clamp(14px, 3vw, 18px)",
  },
  titleSearch: {
    fontSize: 20,
    color: "#0F1011",
  },
  subTitleSearch: {
    fontSize: 16,
    color: "#0F1011",
    marginTop: 8,
  },
};
export default EventList;
