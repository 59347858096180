import React, { useEffect, useState } from "react";
import { View, Text, ScrollView } from "react-native-web";
import { Divider } from "rsuite";
import { Grid } from "@mui/material";
//components
import PopupBackdrop from "../../../components/modal/PopupBackdrop";
import TextField from "../../../components/textField/TextField";
import BoxUploadFile from "../../../components/uploadbox/BoxUploadFile";
import Button from "../../../components/button/Button";
import CourseListTableUpload from "./CourseListTableUpload";
import { base64ToFile } from "../../../functions/format/convertFile";
import { get, post, upload } from "../../../functions/fetch/fetch";
import { useSelector } from "react-redux";
import { useDialog } from "../../../components/modal/Popup";
import apiPath from "../../../services/configs/apiPath";
const PopupAddFileCourse = (props) => {
  const dialog = useDialog();
  const { user } = useSelector((state) => {
    return state;
  });

  const { onClose } = props;
  const { docId = 1, editData } = props;

  //state
  const [folderName, setFolderName] = useState("");
  const [uploadFiles, setUploadFiles] = useState([]);
  const [editDone, setEditDone] = useState(false);

  useEffect(() => {
    if (editData) {
      console.log(editData);
      if (editData.length > 0 && !editDone) {
        setFolderName(editData[0].folder_name);
        let init = [];
        editData.forEach((element, index) => {
          init.push({
            id: element.id,
            fileName: element.detail,
            file: null,
            url: element.file_download,
            priority: element.priority,
            index: index,
            status: element.status,
            isOldFile: true,
          });
        });
        setUploadFiles(init);
        setEditDone(true);
      }
    }
  }, [editData]);

  const uploadFileToStorage = async (file) => {
    if (!file) {
      return;
    }
    const { status, response } = await upload(
      [file],
      `${process.env["REACT_APP_ENV"]}/File/University/${user.agentCode}`
    );
    if (!status) {
      dialog.alert({ message: "เกิดข้อผิดพลาดในการอัปโหลด" });
      return;
    }
    if (response.data) {
      let init = [...uploadFiles];
      init.push({
        fileName: file.name,
        file: file,
        url: response.data.data[0],
        priority: init.length + 1,
        isOldFile: false,
        status: false,
      });
      setUploadFiles(init);
    }
  };

  const update = async () => {
    const folderId = editData[0].university_docs_folder_id;
    if (!folderId) {
      dialog.alert({ message: "ไม่พบ folder_id กรุณา refresh browser" });
      return;
    }

    uploadFileToFolder(folderId);
  };

  const createFolder = async () => {
    if (!folderName) {
      return;
    }
    if (uploadFiles.length === 0) {
      return;
    }

    let url1 = apiPath.university.createFolder;
    let res1 = await post(
      url1,
      {
        university_docs_type_id: docId,
        folder_name: folderName,
      },
      true
    );
    if (!res1.status) {
      dialog.alert({ message: "เกิดข้อผิดพลาดในการสร้าง Folder" });
      return;
    }
    let folderId;
    if (res1.response) {
      folderId = res1.response.data.id;
    }
    if (!folderId) {
      dialog.alert({ message: "เกิดข้อผิดพลาดในการสร้าง Folder" });
      return;
    }

    uploadFileToFolder(folderId);
  };

  const uploadFileToFolder = async (folderId) => {
    if (!folderId) {
      return;
    }
    dialog.loading();
    try {
      await Promise.all(
        uploadFiles.map(async (item, index) => {
          let url = item.id
            ? apiPath.university.updateFileIntoFolder
            : apiPath.university.addFileToFolder;
          let params = {
            university_docs_type_id: docId,
            university_docs_folder_id: folderId,
            detail: item.fileName,
            file_download: item.url,
            image: "",
            priority: index + 1,
            status: item.status,
          };
          if (item.id) {
            params.id = item.id;
          }
          let { status, response } = await post(url, params, true);
          if (!status) {
            dialog.cancle();
            return;
          }
        })
      );
      let alert = await dialog.alert({ message: "อัปโหลดสำเร็จ" });
      if (!alert) {
        return;
      }
      onClose && onClose();
    } catch (error) {
      dialog.alert({ message: error });
      // จัดการกับ error ที่นี่
    }
  };

  return (
    <PopupBackdrop>
      <View style={Styles.box}>
        <Text style={Styles.textTitle}>
          {editData ? "แก้ไขโฟลเดอร์" : "สร้างโฟลเดอร์"}
        </Text>
        <Divider />
        <ScrollView>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Text style={Styles.titleText}>
                ชื่อโฟลเดอร์{" "}
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />
              <TextField
                value={folderName}
                placeholder={"ชื่อโฟลเดอร์"}
                onChangeText={(value) => {
                  setFolderName(value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <BoxUploadFile
                onSelect={(file) => {
                  uploadFileToStorage(file);
                }}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <CourseListTableUpload
                data={uploadFiles}
                onDelete={(values) => {
                  console.log(values);
                  let findIndex = uploadFiles.findIndex((d) => {
                    return d.index === values.index;
                  });
                  let init = [...uploadFiles];
                  if (init[findIndex].file) {
                    init.splice(findIndex, 1);
                  } else {
                    init[findIndex].status = true;
                  }
                  setUploadFiles(init);
                }}
              />
            </Grid>
          </Grid>
        </ScrollView>
        <View style={{ height: 30 }} />
        <Grid container spacing={1}>
          <Grid item xs={0} md={1}></Grid>

          <Grid item xs={12} md={5}>
            <Button
              buttonText={"ยกเลิก"}
              color={"primary"}
              isBorder={true}
              style={{ width: "100%" }}
              onClick={() => {
                onClose && onClose();
              }}
            />
          </Grid>
          <Grid item xs={0} md={0}></Grid>
          <Grid item xs={12} md={5}>
            <Button
              buttonText={editData ? "บันทึก" : "สร้างโฟลเดอร์"}
              color={"primary"}
              style={{ width: "100%" }}
              onClick={() => {
                if (editData) {
                  update();
                } else {
                  createFolder();
                }
              }}
            />
          </Grid>
          <Grid item xs={0} md={1}></Grid>
        </Grid>
      </View>
    </PopupBackdrop>
  );
};

const Styles = {
  box: {
    width: "90vw",
    padding: 20,
    maxWidth: 1000,
    height: "90vh",
    backgroundColor: "#FFF",
    borderRadius: 16,
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.08,
    shadowRadius: 16,
  },
  textTitle: {
    fontSize: 18,
    fontWeight: "bold",
    color: "rgba(15, 16, 17, 1)",
  },
};

export default PopupAddFileCourse;
