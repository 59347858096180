import React, { useEffect, useState } from "react";
import { View, Text, Touchable, TouchableOpacity } from "react-native-web";
import { Divider } from "rsuite";
import { Grid } from "@mui/material";
//component
import UploadImage from "../../../components/uploadbox/UploadImage";
import TextField from "../../../components/textField/TextField";
import Button from "../../../components/button/Button";
import Dropdown from "../../../components/dropdown/Dropdown";
import TextArea from "../../../components/textField/TextArea";
//images
import { CalendarRewardIcon } from "../../../asset/images/icons/iconsLib";
import productImg from "../../../asset/images/example/product.png";
import { SlOptionsVertical } from "react-icons/sl";
import TextEditor from "../../../components/textField/TextEditor";
import VideoUploadPreview from "../../../components/uploadbox/VideoUploadPreview";
import { useSelector } from "react-redux";
import { setDate } from "rsuite/esm/utils/dateUtils";
import { base64ToFile } from "../../../functions/format/convertFile";
import { useDialog } from "../../../components/modal/Popup";
import { get, post, upload } from "../../../functions/fetch/fetch";
import apiPath from "../../../services/configs/apiPath";

const CreateArticle = (props) => {
  //property
  const dialog = useDialog();
  const { user } = useSelector((state) => {
    return state;
  });
  const { editData } = props;
  const { onClose } = props;
  const { countryList } = useSelector((state) => {
    return state.setupData.masterData;
  });

  const [statusList, setStatusList] = useState([
    { value: true, label: "เผยแพร่" },
    { value: false, label: "ไม่เผยแพร่" },
  ]);
  const [data, setData] = useState({
    country_id: null,
    title: "",
    description: "",
    image: "",
    imageFile: null,
    content: "",
    slug: "", // ลิงก์สำหรับวิ่งไปหน้านั้นนน
    status_publish: true, // false คือ ยังไม่เผยแพร่, true คือ เผยแพร่
  });

  useEffect(() => {
    if (editData) {
      donwloadContent();
    }
  }, [editData]);

  const donwloadContent = async () => {
    console.log("donwloadContent");
    let url = apiPath.news.getNewsDetail;

    let { status, response } = await get(url, { id: editData.ID }, true);
    if (!status) {
      dialog.alert({ message: response.data.error });
      return;
    }
    if (response.data) {
      let val = response.data;
      let newData = {
        country_id: val.CountryID,
        title: val.Title,
        description: val.Description,
        image: val.Image,
        imageFile: null,
        content: val.Content,
        slug: val.Slug, // ลิงก์สำหรับวิ่งไปหน้านั้นนน
        status_publish: val.StatusPublish, // false คือ ยังไม่เผยแพร่, true คือ เผยแพร่
      };
      console.log("New data", newData);
      setData(newData);
    }
  };

  const validation = () => {
    let valid = true;
    if (!data.country_id) {
      valid = false;
    }
    if (!data.title) {
      valid = false;
    }
    if (!data.description) {
      valid = false;
    }
    if (!data.image) {
      valid = false;
    }
    if (!data.content) {
      valid = false;
    }

    return valid;
  };

  const submit = async () => {
    if (!validation()) {
      dialog.alert({ message: "กรุณากรอกข้อมูลให้ครบถ้วน" });
      return;
    }

    //Upload
    if (data.imageFile) {
      const agentCode = user.agentCode ? user.agentCode : "not_found";
      const uploadImages = await upload(
        [data.imageFile],
        `${process.env["REACT_APP_ENV"]}/Images/Article/${agentCode}`
      );
      const resImg = uploadImages.response;
      if (!resImg.data) {
        await dialog.alert({
          title: "แจ้งเตือน",
          message: uploadImages.response.data.error,
        });
        return;
      }
      data.image = resImg.data.data[0];
    }

    let url = apiPath.news.createNews;
    let resCreate = await post(url, data, true);
    if (!resCreate.status) {
      await dialog.alert({ title: "แจ้งเตือน", message: resCreate.data.error });
      dialog.cancle();
      return;
    }
    let alert = dialog.alert({ message: "สร้างบทความสำเร็จ" });
    if (!alert) {
      return;
    }
    onClose && onClose();
  };

  const update = async () => {
    if (!validation()) {
      dialog.alert({ message: "กรุณากรอกข้อมูลให้ครบถ้วน" });
      return;
    }

    //Upload
    if (data.imageFile) {
      const agentCode = user.agentCode ? user.agentCode : "not_found";
      const uploadImages = await upload(
        [data.imageFile],
        `${process.env["REACT_APP_ENV"]}/Images/Article/${agentCode}`
      );
      const resImg = uploadImages.response;
      if (!resImg.data) {
        await dialog.alert({
          title: "แจ้งเตือน",
          message: uploadImages.response.data.error,
        });
        return;
      }
      data.image = resImg.data.data[0];
    }

    let url = apiPath.news.updateNews;
    let params = {
      id: editData.ID,
      ...data,
    };
    let resCreate = await post(url, params, true);
    if (!resCreate.status) {
      await dialog.alert({ title: "แจ้งเตือน", message: resCreate.data.error });
      dialog.cancle();
      return;
    }
    let alert = dialog.alert({ message: "แก้ไขบทความสำเร็จ" });
    if (!alert) {
      return;
    }
    onClose && onClose();
  };

  return (
    <View>
      <Grid container spacing={2}>
        <Grid item xs={12} md={2}>
          <Text style={Styles.titleText}>
            แสดงผล
            <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
          </Text>
          <View style={{ height: 8 }} />
          <Dropdown
            value={data.status_publish}
            valueType={"string"}
            placeholder={"เลือกแสดงผล"}
            list={statusList}
            onChange={(values) => {
              const { value, label } = values;
              setData({
                ...data,
                status_publish: value,
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={8}></Grid>
        {/* <Grid item xs={12} md={2}>
          <Button
            buttonText={"ดูตัวอย่าง"}
            color={"disable"}
            style={{ width: "100%" }}
            onClick={() => {}}
          />
        </Grid> */}
      </Grid>
      <View style={{ height: 20 }} />
      <Text style={Styles.titleTextHead}>{`${
        editData ? "แก้ไขบทความ" : "สร้างบทความ"
      }`}</Text>
      <View style={{ height: 20 }} />

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Text style={Styles.titleText}>
            ชื่อบทความ
            <Text style={{ color: "rgba(204, 0, 0, 1)" }}> *</Text>
          </Text>
          <View style={{ height: 8 }} />

          <TextField
            value={data.title}
            iconName={""}
            isInvalid={false}
            placeholder={"ระบุชื่อบทความ"}
            errorMessage={""}
            onChangeText={(value) => {
              setData({
                ...data,
                title: value,
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Text style={Styles.titleText}>
            ประเทศที่แสดงบทความ
            <Text style={{ color: "rgba(204, 0, 0, 1)" }}> *</Text>
          </Text>
          <View style={{ height: 8 }} />

          <Dropdown
            value={data.country_id}
            valueType={"string"}
            findWithId={true}
            placeholder={"เลือกประเทศที่แสดงบทความ"}
            list={countryList ? countryList : []}
            onChange={(values) => {
              const { id } = values;
              console.log(id);
              setData({
                ...data,
                country_id: id,
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Text style={Styles.titleText}>
            รายละเอียดบทความ
            <Text style={{ color: "rgba(204, 0, 0, 1)" }}> *</Text>
          </Text>
          <View style={{ height: 8 }} />
          <TextArea
            value={data.description}
            onChangeText={(value) => {
              setData({
                ...data,
                description: value,
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Text style={Styles.titleText}>
            เนื้อหา
            <Text style={{ color: "rgba(204, 0, 0, 1)" }}> *</Text>
          </Text>
          <View style={{ height: 8 }} />
          {data.content ? (
            <TextEditor
              html={data.content}
              onChange={(value) => {
                setData({
                  ...data,
                  content: value,
                });
              }}
            />
          ) : null}
        </Grid>

        <Grid item xs={12}>
          <View
            style={{
              width: "100%",
            }}
          >
            <Text style={{ fontSize: 16 }}>เพิ่มรูปบทความ</Text>
            <View style={{ marginTop: 8 }} />
            <Text style={Styles.textImageMark}>
              รองรับไฟล์ JPG, PNG, JPEGS เท่านั้น
            </Text>
            <View style={{ marginTop: 16 }} />
            <UploadImage
              value={data.image}
              placeholder={"เพิ่มรูปภาพ"}
              style={{ width: 200, height: 200 }}
              onChangeImage={(base64) => {
                setData({
                  ...data,
                  imageFile: base64ToFile(base64),
                  image: base64,
                });
              }}
            />
          </View>
        </Grid>
      </Grid>
      <View style={{ height: 60 }} />
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          buttonText={"ยกเลิก"}
          color={"primary"}
          style={{ flex: 1, maxWidth: 400 }}
          isBorder={true}
          onClick={() => {
            onClose && onClose();
          }}
        />
        <View style={{ width: 20 }} />
        <Button
          buttonText={editData ? "แก้ไขบทความ" : "สร้างบทความ"}
          color={"primary"}
          style={{ flex: 1, maxWidth: 400 }}
          isBorder={false}
          onClick={() => {
            if (editData) {
              update();
            } else {
              submit();
            }
          }}
        />
      </View>
      <View style={{ height: 40 }} />
    </View>
  );
};

const Styles = {
  titleTextHead: {
    fontSize: "clamp(16px,2vh,20px)",
    fontWeight: "bold",
    color: "rgba(15, 16, 17, 1)",
  },
  textImageMark: {
    fontSize: 14,
    color: "rgba(105, 109, 121, 1)",
  },
};

export default CreateArticle;
