import apiPath from "../../configs/apiPath";
import { get, post } from "../../../functions/fetch/fetch";

export const getTrackingStatus = async (transportStatus) => {
  if (!transportStatus) {
    return;
  }
  let url = apiPath.transport.getTrackingStatus;
  let { status, response } = await get(url, { status: transportStatus }, true);
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};

export const getTrackingStatusCount = async () => {
  let url = apiPath.transport.getTrackingStatusCount;
  let { status, response } = await get(url, {}, true);
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};
