import React from "react";
import {View,Text} from 'react-native-web';
//service
import language from "../../../services/language/language";
const systemLanguage = "TH";

const BuildDayBoxTitle = (props) => {
    const styles = {
      text:{
        fontSize: 14, fontWeight: "bold", textAlign: "center",color:"#8A91A1"
      }
    }
    return (
      <View
        style={{
          width: "100%",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Text style={styles.text}>
          {language.getWord("อา.", systemLanguage)}
        </Text>
        <Text style={styles.text}>
          {language.getWord("จ.", systemLanguage)}
        </Text>
        <Text style={styles.text}>
          {language.getWord("อ.", systemLanguage)}
        </Text>
        <Text style={styles.text}>
          {language.getWord("พ.", systemLanguage)}
        </Text>
        <Text style={styles.text}>
          {language.getWord("พฤ.", systemLanguage)}
        </Text>
        <Text style={styles.text}>
          {language.getWord("ศ.", systemLanguage)}
        </Text>
        <Text style={styles.text}>
          {language.getWord("ส.", systemLanguage)}
        </Text>
      </View>
    );
  };

  export default BuildDayBoxTitle;