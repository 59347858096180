import apiPath from "../../configs/apiPath";
import { get, post } from "../../../functions/fetch/fetch";

export const downloadAllUserRole = async () => {
  let url = apiPath.auth.userRole;
  let { status, response } = await get(url, {}, true);
  if (!status) {
    console.log("error api", response.data.error);
  }
  return !status ? [] : response.data;
};

export const register = async () => {
  let url = apiPath.auth.register;
};

export const login = async () => {
  let url = apiPath.auth.login;
};

export const getUserUseRole = async () => {
  let url = apiPath.auth.getUserUseRole;
  let { status, response } = await get(url, {}, true);
  if (!status) {
    console.log("error api", response.data.error);
  }
  return !status ? [] : response.data;
};
