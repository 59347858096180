import React from 'react';
import { Grid } from '@mui/material';
//component
import ChartSalesHome from './ChartSalesHome';
import NewsHome from './NewsHome';

const HomeCenterContainer = (props)=>{
    
    return (
        <Grid container spacing={2}>
            <ChartSalesHome/>
            <NewsHome/>
        </Grid>
    )
}

const Styles = {
    container:{
        width:"100%",
        margin:0
      }
}

export default HomeCenterContainer;