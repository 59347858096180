import React, { useEffect } from "react";
import { View, ScrollView, Text, TouchableOpacity } from "react-native-web";
import {
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Divider,
} from "@mui/material";
import { GroupIcon } from "../../../asset/images/icons/iconsLib";
import Button from "../../../components/button/Button";
import moment from "moment-timezone";
//Images
import closeIcon from "../../../asset/images/icons/closeIcon.png";

const ContentListTableUpload = (props) => {
  const { data = [{}, {}] } = props;
  const { onDelete } = props;

  useEffect(() => {
    console.log(data);
  }, [data]);
  return (
    <View style={{ width: "100%", height: "100%" }}>
      <TableContainer sx={styles.tableContainer}>
        <Table sx={styles.table}>
          <TableBody>
            {data &&
              data.map((row) => {
                if (!row.status) {
                  return (
                    <TableRow key={row.id} sx={styles.tableRow}>
                      <TableCell sx={styles.tableCell}>
                        <GroupIcon color={"rgba(170, 73, 66, 1)"} />
                      </TableCell>
                      <TableCell sx={styles.tableCell}>
                        <Text>{row.fileName ? row.fileName : ""}</Text>
                      </TableCell>
                      <TableCell
                        sx={{
                          ...styles.tableCell,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                        }}
                      >
                        <View
                          style={{
                            backgroundColor: "rgba(180, 241, 179, 1)",
                            borderRadius: 8,
                            padding: 2,
                            width: 130,
                          }}
                        >
                          <Text
                            style={{
                              fontWeight: "bold",
                              flex: 1,
                              textAlign: "center",
                              color: "rgba(28, 155, 26, 1)",
                            }}
                          >
                            {row.url ? "อัพโหลดสำเร็จ" : "กำลังอัปโหลด"}
                          </Text>
                        </View>
                      </TableCell>
                      <TableCell sx={{ ...styles.tableCell, width: 20 }}>
                        <TouchableOpacity
                          onPress={() => {
                            onDelete && onDelete(row);
                          }}
                        >
                          <img
                            src={closeIcon}
                            alt={"closeIcon"}
                            style={{ width: 20, height: 20 }}
                          />
                        </TouchableOpacity>
                      </TableCell>
                    </TableRow>
                  );
                }
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </View>
  );
};

const styles = {
  tableContainer: {
    overflowY: "scroll",
    scrollbarWidth: "thin",
    scrollbarColor: "#c7c7c7 #f5f5f5",
    flex: 1,
  },
  table: {
    border: "none",
    minWidth: 650,
  },
  tableCell: {
    borderBottom: "none",
    whiteSpace: "nowrap",
    wordBreak: "break-word",
  },
  headerCell: {
    color: "rgba(165, 88, 90, 1)",
    whiteSpace: "nowrap",
  },
};

export default ContentListTableUpload;
