import React, { useState, useRef, useEffect } from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import { motion } from "framer-motion";
//Function
import { useWindowsSize } from "../../functions/screen/screenFunction";

const SwitchMenu = (props) => {
  const { titles, icons, height, selectColor, unSelectColor } = props;
  const { onSelect } = props;

  const [selectIndex, setSelectIndex] = useState(0);
  const [width, setWidth] = useState(0);
  const [offsetX, setOffsetX] = useState(0);
  const ref = useRef();
  const size = useWindowsSize();

  const [textColor, setTextColor] = useState("#FFD7D7");
  const [backgroundColor, setBackgroundColor] = useState("#dada");

  useEffect(() => {
    setWidth(ref.current.offsetWidth / titles.length);
    setOffsetX((ref.current.offsetWidth / titles.length) * selectIndex);
  }, [size]);

  useEffect(() => {
    setTextColor(selectColor ? selectColor.text : "");
    setBackgroundColor(selectColor ? selectColor.background : "");
  }, [selectColor]);

  return (
    <View style={{ width: "100%", justifyContent: "center" }}>
      {false && (
        <motion.div
          animate={{ x: offsetX + 10 }}
          transition={{ duration: 0 }}
          style={{
            flex: 1,
            height: height ? height : "100%",
            backgroundColor: backgroundColor,
            position: "absolute",
            borderRadius: 8,
            zIndex: 10,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <View
            style={{
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#ddaa",
            }}
          >
            <Text
              style={{ fontSize: 16, fontWeight: "bold", color: textColor }}
            >
              {titles[selectIndex]}
            </Text>
          </View>
        </motion.div>
      )}
      <View
        ref={ref}
        style={{
          flexDirection: "row",
          width: "100%",
          height: height ? height : 50,
          justifyContent: "space-around",
          alignItems: "space-around",
        }}
      >
        {titles.map((item, index) => {
          return (
            <BoxSelect
              key={"BoxSelect_" + index.toLocaleString()}
              index={index}
              title={item}
              isSelect={index === selectIndex}
              isLastIndex={index === titles.length - 1}
              onSelect={(responseIndex) => {
                setSelectIndex(responseIndex.index);
                setOffsetX(responseIndex.offset.offsetLeft);
                onSelect &&
                  onSelect({
                    title: titles[responseIndex.index],
                    index: responseIndex.index,
                  });
              }}
              selectColor={selectColor}
              unSelectColor={unSelectColor}
            />
          );
        })}
      </View>
    </View>
  );
};

const BoxSelect = (props) => {
  const [hover, setHover] = useState(false);
  const { index, title, selectColor, unSelectColor } = props;
  const { isSelect, onSelect } = props;
  const refBox = useRef();

  return (
    <View
      style={{
        flex: 1,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: isSelect
          ? selectColor.background
          : unSelectColor.background,
        borderRadius: 8,
      }}
      ref={refBox}
    >
      <TouchableOpacity
        style={{
          flex: 1,
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        onPress={() => {
          onSelect({ index: index, offset: refBox.current });
        }}
      >
        <Text
          style={{
            fontSize: 16,
            fontWeight: "bold",
            color: isSelect ? selectColor.text : unSelectColor.text,
            zIndex: 11,
          }}
        >
          {title}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

export default SwitchMenu;
