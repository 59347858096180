import React,{useState,forwardRef} from 'react';
import { FormTextarea } from "shards-react";

const TextArea = forwardRef((props, ref) => {

    const {placeholder,value} = props;
    const {rows,style} = props;
    const { onChangeText ,onFocus} = props;
    const [focus, setFocus] = useState(false);
  
    const changeFocus = (value) => {
      onFocus && onFocus();
      setFocus(value);
    };

  return (
    <FormTextarea
      placeholder={placeholder ? placeholder : ""}
      rows={rows ? rows : 4}
      style={{
        ...Styles.input,
        borderColor: focus ? "#DE7C78" : "",
        ...style,
      }}
      innerRef={ref}
      value={value}
      onChange={(e) => {
        let text = e.target.value;
        onChangeText && onChangeText(text);
      }}
      onFocus={() => changeFocus(true)}
      onBlur={() => changeFocus(false)}
    />
  );
});

const Styles = {
    input: {
      borderRadius: 5,
      borderColor: "",
      height: 50,
    },
    errorMessage: {
      fontSize: 12,
      color: "#E30505",
      position: "absolute",
    },
    markMessage: {
      fontSize: 12,
      color: "#696D79",
      position: "absolute",
    }
}

export default TextArea;