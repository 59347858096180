import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import { Grid } from "@mui/material";
import { Divider } from "rsuite";
import { useNavigate } from "react-router-dom";
//component
import Button from "../../components/button/Button";
import Dropdown from "../../components/dropdown/Dropdown";
import GridContainerReward from "./componnent/GridContainerReward";
import apiPath from "../../services/configs/apiPath";
import { get, post } from "../../functions/fetch/fetch";
import { useDialog } from "../../components/modal/Popup";
import PopupGetReward from "./componnent/PopupGetReward";
import AddReward from "./AddReward";
import { useSelector } from "react-redux";

const ExchangeReward = () => {
  const navigate = useNavigate();
  const dialog = useDialog();

  const { user } = useSelector((state) => {
    return state;
  });

  //state
  const [rankingList, setRankingList] = useState([]);
  const [rewardList, setRewardList] = useState([]);
  const [viewData, setViewData] = useState(null);
  const [editData, setEditData] = useState(null);

  useEffect(() => {
    downloadAllRankingList();
  }, []);

  useEffect(() => {
    if (rankingList.length > 0) {
      downloadRewardList();
    }
  }, [rankingList]);

  const downloadRewardList = async () => {
    try {
      let url = apiPath.reward.getListReward;
      let { status, response } = await get(url, {}, true);

      if (!status || !response.data) {
        return;
      }

      // Convert rankingList to a map for O(1) lookup time
      const rankingMap = new Map(
        rankingList.map((item) => [Number(item.value), item.label])
      );

      // Using map instead of forEach to avoid manual array population
      const init = response.data.map((item) => {
        const qualify = item.qualify_rank.split(",");
        const initQualify = qualify
          .map((q) => rankingMap.get(Number(q)))
          .filter(Boolean);

        return {
          ...item,
          qualify_rank_name: initQualify,
        };
      });

      console.log("init", init);
      setRewardList(init);
    } catch (error) {
      console.error("Error in downloadRewardList:", error);
    }
  };

  const downloadAllRankingList = async () => {
    let url = apiPath.member.getAllRankingList;
    let { status, response } = await get(url, {}, true);
    if (!status) {
      dialog.alert({ message: "เกิดข้อผิดพลาด" });
    }
    if (response.data) {
      let val = response.data;
      let init = [];
      let qualify_rank = [];
      val.forEach((element) => {
        init.push({
          value: element.id,
          label: element.name,
        });
        qualify_rank.push(element.level);
      });
      setRankingList(init);
    }
  };

  const handleOption = async (values) => {
    const { option, data } = values;
    if (option === "edit") {
      handleEdit(data);
    } else if (option === "delete") {
      let confirm = await dialog.confirm({
        title: `ยืนยันการลบ ${data.name}`,
        message: `ยืนยันการลบ ${data.name} ใช่ หรือ ไม่ ?`,
      });
      if (!confirm) {
        return;
      }
      handleDelete(data);
    }
  };

  const handleEdit = (data) => {
    if (!user) {
      return;
    }
    if (user.userRole !== "S" && user.userRole !== "SS") {
      dialog.alert({ message: "reject by role" });
      return;
    }
    console.log("set edit date");
    setEditData(data);
  };

  const handleDelete = async (data) => {
    let params = {
      ...data,
      condition_reward_amount: data.condition_amount,
      qualify_rank: data.qualify_rank.split(",").map(Number),
      open_date: data.condition_open_date,
      close_date: data.condition_close_date,
      disable: true,
    };
    let url = apiPath.reward.updateReward;
    let { status, response } = await post(url, params, true);
    if (!status) {
      dialog.alert({ message: response.data.error });
      return;
    }
    if (response.data) {
      dialog.alert({ message: "ลบข้อมูลสำเร็จ" });
      await downloadRewardList();
      return;
    }
  };
  return (
    <React.Fragment>
      {viewData ? (
        <PopupGetReward
          data={viewData}
          onClose={() => {
            setViewData(null);
          }}
        />
      ) : null}
      {editData ? (
        <AddReward
          editData={editData}
          onClose={() => {
            setEditData(null);
          }}
        />
      ) : (
        <View style={Styles.container}>
          <Grid container style={Styles.topContainer} spacing={2}>
            <Grid item xs={12} md={8} lg={9} xl={10}>
              <View style={{ flex: 1 }}>
                <Text style={Styles.textTopContainer1}>แลกรางวัล</Text>
                <Text style={Styles.textTopContainer2}>
                  ของรางวัล / แลกรางวัล
                </Text>
              </View>
            </Grid>
          </Grid>
          <View style={{ height: 30 }} />
          <View style={Styles.contentContainer}>
            <Grid container spacing={2} style={{ marginBottom: 15 }}>
              <Grid item xs={12} lg={4} style={{ zIndex: 10 }}>
                <View style={{ height: 8 }} />
                <Dropdown
                  placeholder={"ประเภทของรางวัล"}
                  list={[]}
                  onChange={(values) => {
                    const { value, label } = values;
                    console.log(value, label);
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={4} style={{ zIndex: 10 }}>
                <View style={{ height: 8 }} />
                <Dropdown
                  placeholder={"ของรางวัล"}
                  list={[]}
                  onChange={(values) => {
                    const { value, label } = values;
                    console.log(value, label);
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <View style={{ height: 8 }} />
                <Button
                  buttonText={"ค้นหา"}
                  color={"primary"}
                  style={{ width: "100%" }}
                  styleText={Styles.topButtonText}
                  onClick={() => {}}
                />
              </Grid>
            </Grid>
            <Divider />

            <GridContainerReward
              data={rewardList}
              disableOption={
                user
                  ? user.userRole === "SS" || user.userRole === "S"
                    ? false
                    : true
                  : true
              }
              onSelect={(value) => {
                setViewData(value);
              }}
              onOption={(values) => {
                handleOption(values);
              }}
            />
          </View>
          <View style={{ height: 100 }} />
        </View>
      )}
    </React.Fragment>
  );
};

const Styles = {
  container: {
    flex: 1,
    height: "100vh",
    padding: "1.5%",
  },
  topContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  contentContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  textTopContainer1: {
    fontSize: "clamp(18px, 3vw, 22px)",
    fontWeight: "bold",
    color: "#AA4942",
  },
  textTopContainer2: {
    fontSize: "clamp(12px, 3vw, 16px)",
    color: "#696D79",
  },
  topButtonText: {
    fontSize: "clamp(14px, 3vw, 18px)",
  },
  titleSearch: {
    fontSize: 20,
    color: "#0F1011",
  },
  subTitleSearch: {
    fontSize: 16,
    color: "#0F1011",
    marginTop: 8,
  },
};
export default ExchangeReward;
