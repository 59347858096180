import apiPath from "../../configs/apiPath";
import { get, post } from "../../../functions/fetch/fetch";

export const downloadRetailMyList = async () => {
  let url = apiPath.retail.getRetailMyList;
  let { status, response } = await get(url, {}, true);
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};

export const downloadRetailMyAgent = async (limit = 0,offset = 0) => {
  let url = apiPath.retail.getRetailMyAgentV2;
  let { status, response } = await get(url, {limit:limit,offset:offset}, true);
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};
