import React, { useState, useEffect, useRef } from "react";
import { Text, View, TouchableOpacity } from "react-native-web";
import { Grid } from "@mui/material";
import { Divider } from "rsuite";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//component
import Button from "../../components/button/Button";
import Dropdown from "../../components/dropdown/Dropdown";
import DatePicker from "../../components/calendar/DatePicker";
import BoxImage from "../../components/uploadbox/BoxUploadImage";
import TextArea from "../../components/textField/TextArea";
import PopupAddCustomer from "./component/PopupAddCustomer";
import TimePicker from "../../components/time/TimePicker";
import ProductShoppingTable from "./component/ProductShoppingTable";
import EmptyProduct from "./component/EmptyProduct";
import SummaryBox from "./component/SummaryBox";
//images
import { PlusCircleIcon } from "../../asset/images/icons/iconsLib";
//functions
import {
  downloadRetailCustomerList,
  downloadRetailSaleList,
} from "../../services/api/sales/sales";
import { downloadAllProductsListRetail } from "../../services/api/products/products";
import { getUserInfo } from "../../services/api/member/member";
import moment from "moment-timezone";
import { useDialog } from "../../components/modal/Popup";
import apiPath from "../../services/configs/apiPath";
import { get, post } from "../../functions/fetch/fetch";
import TextField from "../../components/textField/TextField";
import { dataURItoBlob } from "../../functions/format/convertFile";
import { upload } from "../../functions/fetch/fetch";
import {
  downloadCurrentTransportCode,
  downloadPaymentMethodList,
  downloadTransportMethodList,
} from "../../services/api/sales/method";
import PopupURLCustomerPay from "./component/PopupURLCustomerPay";
import {
  downloadAllDistrict,
  downloadAllProvince,
  downloadAllSubDistrict,
} from "../../services/api/masterdata/address";
import { set } from "firebase/database";
import BoxUploadFile from "../../components/uploadbox/BoxUploadFile";
import { formatNumber } from "../../functions/format/numberFormat";
import { engAndNumber } from "../../functions/format/format";

const ShoppingAdd = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dialog = useDialog();
  const { user } = useSelector((state) => {
    return state;
  });
  const isRequireAttachmentTransportCode = ["9","10","11"]
  //state
  let [shoppingData, setShoppingData] = useState({
    date: moment(),
    time: moment().format("HH:mm"),
    saleId: null,
    saleCode: null,
    saleCodeStore: null,
    customerId: null,
    description: null,
    productList: null,
    transportId: null,
    transportCode: null,
    paymentMethodCode: null,
    countryCodeStore: null,
    forSelf: false,
    attachment:null,
    trackingCodes:null,
  });
  const [customerAds, setCustomerAds] = useState({
    provinceCode: null,
    districtCode: null,
    subDistrictCode: null,
    zipcode: null,
  });
  const [lockSubmit,setLockSubmit] = useState(false);
  const [customerInfo, setCustomerInfo] = useState();
  //popup add customer
  const [popupAddCustomer, setPopupAddCustomer] = useState(false);
  const [saleCodeAddCustomer, setSaleCodeAddCustomer] = useState(null);
  //state select
  const [retailProfileList, setRetailProfileList] = useState([]);
  const [retailSaleList, setRetailSaleList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [customerSalesList, setCustomerSalesList] = useState([]);
  const [userInfo, setUserInfo] = useState();
  const [transportList, setTransportList] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [alertDone, setAlertDone] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [popupUrl, setPopupUrl] = useState({
    visible: false,
    url: "",
  });
  const [agentAddress, setAgentAddress] = useState({});

  //products list
  const [productList, setProductList] = useState([]);
  const [productSelectList, setProductSelectList] = useState([]);
  const [productCalculate, setProductCalculate] = useState([]);
  const [displayDataSummary, setDisplayDataSummary] = useState({
    quantity: 0,
    total: 0,
    totalDiscount: 0,
    totalDelivery: 0,
    totalBasePrice: 0,
    addOn:[]
  });

  useEffect(() => {
    const downloadAllData = async () => {
      dialog.loading();
      await downloadRetailSale();
      let uInfo = await getUserInfo();
      setUserInfo(uInfo);
      if (uInfo.userRole === "A") {
        setShoppingData({
          ...shoppingData,
          saleId: uInfo.id,
          saleCode: uInfo.agentCode,
        });
      }
      await downloadTransportMethod();
      await downloadSetupTransport();
      await downloadPaymentMethod();
      await getAgentAddress();
      dialog.cancle();
    };
    downloadAllData();
  }, []);

  useEffect(() => {
    if (customerInfo == null) {
      return;
    }

    const downloadAllData = async () => {
      var information = {
        provinceCode: "",
        districtCode: "",
        subDistrictCode: "",
      };

      let [provinces, districtList, subDistrictList] = await Promise.all([
        downloadAllProvince(),
        downloadAllDistrict(customerInfo?.provinceCode),
        downloadAllSubDistrict(
          customerInfo?.provinceCode,
          customerInfo?.districtCode
        ),
      ]);

      for (let index = 0; index < provinces.length; index++) {
        const province = provinces[index];
        if (province.provinceCode == customerInfo?.provinceCode) {
          information.provinceCode = province.province;
          break;
        }
      }

      for (let index = 0; index < districtList.length; index++) {
        const district = districtList[index];
        if (district.districtCode == customerInfo?.districtCode) {
          information.districtCode = district.district;
          break;
        }
      }

      for (let index = 0; index < subDistrictList.length; index++) {
        const subDistrict = subDistrictList[index];
        if (subDistrict.subDistrictCode == customerInfo?.subDistrictCode) {
          information.subDistrictCode = subDistrict.subDistrict;
          break;
        }
      }

      setCustomerAds({
        provinceCode: information.provinceCode,
        districtCode: information.districtCode,
        subDistrictCode: information.subDistrictCode,
      });
    };
    downloadAllData();
  }, [customerInfo]);

  useEffect(() => {
    // ตรวจสอบว่ามีรายการสินค้าที่ถูกเลือกหรือไม่
    if (productSelectList.length > 0) {
      // เรียกการคำนวณเมื่อรายการสินค้าถูกเลือก, รหัสการขนส่ง, หรือ shoppingData เปลี่ยนแปลง
      calculateOrder(productSelectList);
    } else {
      // ถ้าไม่มีรายการสินค้าที่ถูกเลือก ตั้งค่าผลลัพธ์การคำนวณเป็นค่าเริ่มต้น
      setProductCalculate([]);
      setDisplayDataSummary({
        quantity: 0,
        total: 0,
        totalDiscount: 0,
        totalDelivery: 0,
        totalBasePrice: 0,
        addOn:[]
      });
    }
  }, [productSelectList, shoppingData]);

  useEffect(() => {
    // ดึงข้อมูลลูกค้าและรายการสินค้าเมื่อมีรหัสการขายหรือรหัสการขนส่งเปลี่ยนแปลง
    if (shoppingData.saleIdStore) {
      downloadRetailCustomer(shoppingData.saleCodeStore);
    }
    if (shoppingData.saleCodeStore) {
      downloadProductList();
    }
  }, [shoppingData.saleIdStore, shoppingData.saleCodeStore]);

  useEffect(() => {
    // ตั้งค่าวิธีการชำระเงินเป็น null หากการซื้อเป็นการซื้อสำหรับตนเองและวิธีการชำระเงินเป็น 'cashdelivery'
    if (
      shoppingData.forSelf &&
      (shoppingData.paymentMethodCode === "cashdelivery" ||
        shoppingData.paymentMethodCode === "customerpayment")
    ) {
      setShoppingData({
        ...shoppingData,
        paymentMethodCode: null,
      });
    }
    // ดาวน์โหลดวิธีการชำระเงินไม่ว่าเงื่อนไขไหนก็ตาม
    downloadPaymentMethod();
  }, [shoppingData?.forSelf, shoppingData?.paymentMethodCode,shoppingData?.transportCode]);

  const downloadRetailSale = async () => {
    let res = await downloadRetailSaleList();
    let init = [];
    res.forEach((item) => {
      init.push({
        value: item.id,
        label: item.name,
      });
    });
    setRetailSaleList(init);
    setRetailProfileList(res);
  };

  const downloadRetailCustomer = async (agentCode) => {
    if (!agentCode) {
      return;
    }
    let res = await downloadRetailCustomerList(agentCode);

    let init = [];
    res.forEach((item) => {
      init.push({
        value: item.id,
        label: item.name,
      });
    });
    setCustomerList(res);
    setCustomerSalesList(init);
    setShoppingData({
      ...shoppingData,
      customerId: null,
    });
  };

  const downloadProductList = async () => {
    console.log("downloadProductList");
    if (!shoppingData.saleCodeStore && !userInfo.agentCode) {
      return;
    }
    let productsList = await downloadAllProductsListRetail(
      shoppingData.saleCodeStore,
      userInfo.agentCode
    );
    let initProducts = [];
    productsList.forEach((item) => {
      initProducts.push({
        ...item,
        value: item.id,
        label: `${item.name} (${item.code}) (${item.quantity})`,
      });
    });
    setProductList(initProducts);
  };

  const downloadPaymentMethod = async () => {
    let res = await downloadPaymentMethodList({transportCode:shoppingData.transportCode});
    if (res) {
      let init = [];
      res.forEach((item) => {
        if (shoppingData.forSelf && item.code === "cashdelivery") {
          return;
        }
        if (userInfo) {
          if (
            (userInfo.userRole === "A" &&
              userInfo.active === "W" &&
              item.code === "customerpayment") ||
            (item.code === "customerpayment" && shoppingData.forSelf)
          ) {
            return;
          }
        }
        init.push({ value: item.code, label: item.name });
      });
      setPaymentMethod(init);
      //recheck if PaymentMethodCode not in list
      if (shoppingData?.paymentMethodCode && !init.find((d) => d.value === shoppingData.paymentMethodCode)) {
        setShoppingData({
          ...shoppingData,
          paymentMethodCode: null,
        });
      }
    }
  };

  const downloadTransportMethod = async () => {
    let res = await downloadTransportMethodList();
    if (res) {
      let init = [];
      res.forEach((item) => {
        init.push({ value: item.code, label: item.name });
      });
      setTransportList(init);
    }
  };

  const downloadSetupTransport = async () => {
    let res = await downloadCurrentTransportCode();
    if (!res) {
      return;
    }
    await setShoppingData({
      ...shoppingData,
      transportCode: res.code,
    });
  };

  const getAgentAddress = async () => {
    let url = apiPath.member.getAgentAddressSending;
    const { status, response } = await get(url, {}, true);
    if (!status) {
      return;
    }
    if (response.data) {
      setAgentAddress(response.data);
    }
  };

  const calculateOrder = async (values) => {
    setIsLoading(true);
    let url = apiPath.retail.calculateOrder;
    let reNewOrder = [];
    values.forEach((item) => {
      delete item.discountList;
      reNewOrder.push({ ...item });
    });
    let params =  {
      orders: JSON.stringify(reNewOrder),
      ...shoppingData,
    }
    delete params.attachment;
    delete params.trackingCodes;
    let { status, response } = await post(
      url,
      params,
      true
    );
    if (!status) {
      setIsLoading(false);
      dialog.cancle();
      return;
    }
    if (response.data) {
      const cal = response.data;

      setProductCalculate(cal.data);

      setDisplayDataSummary({
        ...displayDataSummary,
        quantity: cal.totalQuantity,
        totalDiscount: cal.totalDiscount,
        total: cal.totalPrice,
        totalBasePrice: cal.totalBasePrice,
        totalDelivery: cal.totalTransportCost,
        totalExtraCashDelivery: cal.totalExtraCashDelivery,
        addOn:cal.addOn ? cal.addOn : []
      });
    }
    dialog.cancle();
  };

  const reset = () => {
    setShoppingData({
      date: moment(),
      time: moment().format("HH:mm"),
      saleId: null,
      saleCode: null,
      customerId: null,
      description: null,
      productList: null,
      transportId: null,
      transportCode: null,
      paymentMethodCode: null,
    });

    var information = {
      provinceCode: "",
      districtCode: "",
      subDistrictCode: "",
    };

    setCustomerAds(information);
    setCustomerInfo(null);
    setProductSelectList([]);
  };

  const validation = async () => {
    let errors = [];

    if (
      !shoppingData.saleId ||
      !shoppingData.saleCode ||
      !shoppingData.saleCodeStore
    ) {
      console.log(shoppingData);
      errors.push("รหัสผู้ขาย");
    }

    if (!shoppingData.date) {
      errors.push("วันที่");
    }
    if (!shoppingData.time) {
      errors.push("เวลา");
    }
    if (!shoppingData.customerId && !shoppingData.forSelf) {
      errors.push("ลูกค้า");
    }
    if (
      shoppingData.countryCodeStore === "THA" &&
      !shoppingData.paymentMethodCode
    ) {
      errors.push("วิธีการชำระเงิน");
    }
    if (shoppingData.countryCodeStore !== "THA" && !shoppingData.paymentImage) {
      errors.push("หลักฐานการชำระเงิน");
    }

    if (productCalculate.length <= 0) {
      errors.push("กรุณาเลือกสินค้า");
    }
    if (!shoppingData.transportCode) {
      errors.push("กรุณาเลือกขนส่ง");
    }
    if(isRequireAttachmentTransportCode.includes(shoppingData.transportCode) && !shoppingData.attachment){
      errors.push("กรุณาแนบเอกสารใบแปะหน้า");
    }

    if(isRequireAttachmentTransportCode.includes(shoppingData.transportCode) && !shoppingData.trackingCodes){
      errors.push("กรุณาระบุ Tracking Code");
    }

    //validate transport code && payment method
    if (shoppingData?.paymentMethodCode && !paymentMethod.find((d) => d.value === shoppingData.paymentMethodCode)) {
      errors.push("วิธีการชำระเงินไม่ถูกต้อง");
    }

    let message = "";
    if (errors.length > 0) {
      message = "กรุณากรอกข้อมูลให้ครบถ้วน";
    }

    if (message) {
      await dialog.alert({
        title: "แจ้งเตือน",
        message: message,
      });
      return false;
    }

    return true;
  };

  const submit = async () => {
    if (!(await validation())) {
      return;
    }
    dialog.loading();

    let newTime = moment().format("HH:mm");
    let params = {
      date: moment().toDate(),
      time: newTime,
      hour: Number(newTime.split(":")[0]),
      minute: Number(newTime.split(":")[1]),
      saleId: shoppingData.saleId,
      saleCode: shoppingData.saleCode,
      saleCodeStore: shoppingData.saleCodeStore,
      saleCodeTransaction: userInfo.agentCode,
      customerId: shoppingData.customerId,
      customerName: shoppingData.customerName,
      customerEmail: shoppingData.customerEmail,
      productList: productCalculate,
      deliveryId: 0,
      transportId: shoppingData.transportId,
      transportCode: shoppingData.transportCode,
      transportStatus: "",
      transportCost: displayDataSummary.totalDelivery,
      totalQuantity: displayDataSummary.quantity,
      totalBasePrice: displayDataSummary.totalBasePrice,
      totalPrice: displayDataSummary.total,
      totalDiscount: displayDataSummary.totalDiscount,
      description: shoppingData.description ? shoppingData.description : "",
      orders: JSON.stringify(productSelectList),
      forSelf: shoppingData.forSelf,
    };

    //กรณี require attachment file
    if(isRequireAttachmentTransportCode.includes(shoppingData.transportCode)){
      if(!shoppingData.attachment){
        dialog.alert({message:"ไม่พบเอกสารใบแปะหน้า"});
        return;
      }
      const uploadAttachment = await upload(
        [shoppingData.attachment],
        `${process.env["REACT_APP_ENV"]}/Images/Retail/Attachment/${userInfo.agentCode}`
      );
      const { data } = uploadAttachment.response;
      if (!data) {
        await dialog.alert({
          title: "แจ้งเตือน",
          message: uploadAttachment.response.data.error,
        });
        return;
      }
      params.attachment = data.data ? data.data[0] : "";
      params.trackingCodes = shoppingData.trackingCodes;
    }

    //Payment
    if (shoppingData.paymentImage) {
      //Upload image process
      const uploadImages = await upload(
        [dataURItoBlob(shoppingData.paymentImage)],
        `${process.env["REACT_APP_ENV"]}/Images/Retail/Payment/${userInfo.agentCode}`
      );
      const { data } = uploadImages.response;
      if (!data) {
        await dialog.alert({
          title: "แจ้งเตือน",
          message: uploadImages.response.data.error,
        });
        return;
      }
      params.paymentImage = data.data ? data.data[0] : "";
      //end upload image process
    }

    if (shoppingData.paymentMethodCode === "cashdelivery") {
      //เก็บเงินปลายทาง
      handleCashOnDelivery(params);
      return;
    }

    if (shoppingData.paymentMethodCode === "customerpayment") {
      handleCustomerPayment(params);
      return;
    }

    if(shoppingData.paymentMethodCode === "adminpayment"){
      handleAdminPayment(params);
      return;
    }

    //ชำระเงิน
    let paymentRes = await dialog.payment({
      paymentMethodCode: shoppingData.paymentMethodCode,
      data: {
        ...params,
        transactionType: "RETAIL",
        paymentMethodCode: shoppingData.paymentMethodCode,
      },
    });
    if (!paymentRes.close) {
      return;
    }
    if (
      paymentRes.status !== null &&
      paymentRes.status !== undefined &&
      paymentRes.status === false
    ) {
      dialog.alert({ message: "การชำระเงินไม่สำเร็จ" });
      return;
    }

    // ส่วนนี้สำหรับ mock test
    // let url = apiPath.retail.addRetail;
    // console.log("Params", params);
    // let { status, response } = await post(url, params, true);
    // if (!status) {
    //   console.log(response.data);
    //   await dialog.alert({ title: "แจ้งเตือน", message: response.data.error });
    //   dialog.cancle();
    //   return;
    // }
    handleSuccess();
  };

  const handleSuccess = async () => {
    if (!alertDone) {
      setAlertDone(true);
      let message = "เพิ่มรายการขายปลีกสำเร็จ";
      let alert = await dialog.alert({
        title: "แจ้งเตือน",
        message: message,
      });
      if (!alert) {
        return;
      }

      if (user) {
        if (user.userRole === "S" || user.userRole === "SS") {
          navigate("/shoppingMyAgent");
          return;
        }
      }
      navigateBack();
    }
  };

  const handleCashOnDelivery = async (params) => {
    
    if (!params) {
      dialog.cancle();
      return;
    }
    if(lockSubmit){
      return;
    }
    setLockSubmit(true);
    params.paymentMethodCode = "cashdelivery";
    params.transactionType = "RETAIL";
    dialog.loading();
    let url = apiPath.retail.addRetailCashDelivery;
    let { status, response } = await post(url, params, true);
    if (!status) {
      await dialog.alert({ title: "แจ้งเตือน", message: response.data.error });
      setLockSubmit(false);
      return;
    }
    let alert = await dialog.alert({ message: "เพิ่มรายการขายปลีกสำเร็จ" });
    if (!alert) {
      return;
    }
    navigateBack();
  };

  const handleCustomerPayment = async (params) => {
    if (!params) {
      dialog.cancle();
      return;
    }
    params.paymentMethodCode = "customerpayment";
    let url = apiPath.retail.createRetailCustomerPayment;
    let { status, response } = await post(url, params, true);
    if (!status) {
      console.log(response.data);
      await dialog.alert({ title: "แจ้งเตือน", message: response.data.error });
      dialog.cancle();
      return;
    }
    if (response.data) {
      setPopupUrl({
        visible: true,
        url: response.data,
      });
    }
  };

  const handleAdminPayment = async (params) => {
    if (!params) {
      dialog.cancle();
      return;
    }
    params.paymentMethodCode = "adminpayment";

    let url = apiPath.payment.adminExceptPayment;
    let {status,response} = await post(url,params,true);
    if(!status){
      await dialog.alert({title:"แจ้งเตือน",message:response.data.error});
      dialog.cancle();
      return;
    }
    if(response.data){
      await dialog.alert({title:"แจ้งเตือน",message:"เพิ่มรายการขายปลีกสำเร็จ"});
    }
    return;
  }

  const navigateBack = () => {
    let page =
      userInfo.userRole !== "A" ? "/shoppingMyAgent" : "/shoppingMyList";
    navigate(page);
  };


  return (
    <React.Fragment>
      {popupUrl.visible ? (
        <PopupURLCustomerPay
          url={popupUrl.url}
          onClose={() => {
            setPopupUrl({ ...popupUrl, visible: false });
            dialog.cancle();
          }}
        />
      ) : null}
      <PopupAddCustomer
        visible={popupAddCustomer}
        saleCode={saleCodeAddCustomer}
        onClose={() => {
          downloadRetailCustomer(shoppingData.saleCodeStore);
          setPopupAddCustomer(false);
        }}
      />
      <View style={Styles.container}>
        <Grid container style={Styles.topContainer} spacing={2}>
          <Grid item xs={12} md={8} lg={9} xl={10}>
            <View style={{ flex: 1 }}>
              <Text style={Styles.textTopContainer1}>เพิ่มรายการขายปลีก</Text>
              <Text style={Styles.textTopContainer2}>
                ขายปลีกและลูกค้า / เพิ่มรายการขายปลีก
              </Text>
            </View>
          </Grid>
        </Grid>
        <View style={{ height: 30 }} />
        <View style={Styles.contentContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={9} lg={10}>
              <Text
                style={{
                  fontSize: "clamp(16px,2vh,20px)",
                  fontWeight: "bold",
                  color: "rgba(15, 16, 17, 1)",
                }}
              >
                รายละเอียดคำสั่งซื้อ
              </Text>
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <Button
                buttonText={"ล้างข้อมูล"}
                color={"primary"}
                isBorder={true}
                style={{
                  width: "100%",
                  height: 40,
                  justifyContent: "center",
                  alignItems: "center",
                }}
                styleText={{ fontSize: 14 }}
                onClick={() => {
                  reset();
                }}
              />
            </Grid>
          </Grid>
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} style={{ zIndex: 11 }}>
              <Text style={Styles.titleText}>วันที่การขาย</Text>
              <View style={{ height: 8 }} />
              <DatePicker
                date={shoppingData.date}
                styles={{ width: "100%" }}
                placeholder="เลือกวันที่การขาย"
                disable={true}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ zIndex: 10 }}>
              <Text style={Styles.titleTextField}>เวลา</Text>
              <View style={{ height: 8 }} />
              <TimePicker
                time={shoppingData.time}
                styles={{ width: "100%" }}
                placeholder="เลือกเวลา"
                disable={true}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ zIndex: 9 }}>
              <Text style={Styles.titleText}>
                ผู้ขาย <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />
              <Dropdown
                placeholder={"เลือกจากคลังผู้ขาย"}
                list={retailSaleList}
                disable={!shoppingData.transportCode}
                onChange={(values) => {
                  const { value, label } = values;
                  let findAgentCode = retailProfileList.find((d) => {
                    return d.id === value;
                  });
                  if (!findAgentCode) {
                    return;
                  }
                  let init = {
                    ...shoppingData,
                    saleIdStore: findAgentCode.id,
                    saleCodeStore: findAgentCode.agentCode,
                    countryCodeStore: userInfo.countryCodeStore,
                    forSelf: false,
                  };
                  if (userInfo.userRole !== "A") {
                    //กรณี Admin เป็นคนทำรายการ
                    init.saleId = findAgentCode.id;
                    init.saleCode = findAgentCode.agentCode;
                    init.countryCodeStore = findAgentCode.countryCodeStore;
                  } else {
                    //Agent
                    init.saleId = userInfo.id;
                    init.saleCode = userInfo.agentCode;
                  }
                  setShoppingData(init);

                  var information = {
                    provinceCode: "",
                    districtCode: "",
                    subDistrictCode: "",
                  };

                  setCustomerAds(information);
                  setCustomerInfo(null);
                }}
              />
              <View style={{ height: 8 }} />
              <Text style={Styles.textRemark}>สามารถพิมพ์เพื่อค้นหาได้</Text>
            </Grid>
            <Grid item xs={12} md={6} style={{ zIndex: 8 }}>
              <Text style={Styles.titleText}>
                ลูกค้า <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Dropdown
                  placeholder={"เลือกลูกค้า"}
                  value={shoppingData.customerId}
                  valueType={"number"}
                  disable={customerSalesList.length === 0}
                  list={customerSalesList}
                  onChange={(values) => {
                    const { value, label } = values;
                    let findCustomer = customerList.find((d) => {
                      return d.id === value;
                    });
                    if (!findCustomer) {
                      console.log("not found customer");
                      return;
                    }
                    setShoppingData({
                      ...shoppingData,
                      customerId: value,
                      customerName: findCustomer.name,
                      customerEmail: findCustomer.email,
                      forSelf: label === "ซื้อให้ตัวเอง" && value === 0,
                    });

                    setCustomerInfo(findCustomer);
                  }}
                />
                <TouchableOpacity
                  style={{ marginLeft: 10 }}
                  onPress={() => {
                    if (!shoppingData.saleCode) {
                      dialog.alert({ message: "กรุณาเลือกผู้ขาย" });
                      return;
                    }
                    setPopupAddCustomer(true);
                    setSaleCodeAddCustomer(shoppingData.saleCode);
                  }}
                >
                  <PlusCircleIcon
                    width={24}
                    height={24}
                    color={"rgba(15, 16, 17, 1)"}
                  />
                </TouchableOpacity>
              </View>
              <View style={{ height: 8 }} />
              <Text style={Styles.textRemark}>สามารถพิมพ์เพื่อค้นหาได้</Text>
            </Grid>
            <Grid item xs={12} md={6}></Grid>

            {customerInfo?.name === "ซื้อให้ตัวเอง" ? (
              <Grid item xs={12} md={6}>
                <Text style={Styles.textRemark}></Text>
                <Text style={Styles.textRemark}>
                  ชื่อ: {customerInfo?.name ?? "-"}
                </Text>{" "}
                {agentAddress?.phoneNumber && (
                  <Text style={Styles.textRemark}>
                    เบอร์โทรศัพท์: {`${agentAddress?.phoneNumber} ` ?? "-"}
                  </Text>
                )}
                <Text style={Styles.textRemark}>
                  ที่อยู่: {agentAddress?.address ?? "-"}
                </Text>{" "}
                <Text style={Styles.textRemark}>
                  จังหวัด: {agentAddress?.province ?? "-"}
                </Text>{" "}
                <Text style={Styles.textRemark}>
                  เขต/อำเภอ: {agentAddress?.district ?? "-"}
                </Text>{" "}
                <Text style={Styles.textRemark}>
                  แขวง/ตำบล: {agentAddress?.subDistrict ?? "-"}
                </Text>{" "}
                <Text style={Styles.textRemark}>
                  รหัสไปรษณีย์: {agentAddress?.zipCode ?? "-"}
                </Text>
              </Grid>
            ) : (
              <Grid item xs={12} md={6}>
                <Text style={Styles.textRemark}></Text>
                <Text style={Styles.textRemark}>
                  ชื่อ: {customerInfo?.name ?? "-"}
                </Text>{" "}
                {customerInfo?.phoneNumber && (
                  <Text style={Styles.textRemark}>
                    เบอร์โทรศัพท์: {`${customerInfo?.phoneNumber} ` ?? "-"}
                  </Text>
                )}
                <Text style={Styles.textRemark}>
                  ที่อยู่: {customerInfo?.address ?? "-"}
                </Text>{" "}
                <Text style={Styles.textRemark}>
                  จังหวัด: {customerAds?.provinceCode ?? "-"}
                </Text>{" "}
                <Text style={Styles.textRemark}>
                  เขต/อำเภอ: {customerAds?.districtCode ?? "-"}
                </Text>{" "}
                <Text style={Styles.textRemark}>
                  แขวง/ตำบล: {customerAds?.subDistrictCode ?? "-"}
                </Text>{" "}
                <Text style={Styles.textRemark}>
                  รหัสไปรษณีย์: {customerInfo?.zipcode ?? "-"}
                </Text>
              </Grid>
            )}
            <Grid item xs={12} md={12} lg={12} style={{ zIndex: 7 }}>
              <Text
                style={{
                  fontSize: "clamp(16px,2vh,20px)",
                  fontWeight: "bold",
                  color: "rgba(15, 16, 17, 1)",
                }}
              >
                รายละเอียดสินค้า
              </Text>
              <Divider />
              <Grid item xs={12} md={6} style={{ zIndex: 6 }}>
                <View style={{ height: 8 }} />
                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 8,
                  }}
                >
                  <Dropdown
                    placeholder={"เพิ่ม/ค้นหาสินค้า"}
                    list={productList}
                    onChange={(values) => {
                      const { value, label } = values;
                      let product = productList.find((d) => {
                        return d.id === value;
                      });
                      if (!product) {
                        return;
                      }
                      let init = [...productSelectList];
                      let checkDuplicate =
                        productSelectList.filter((d) => {
                          return d.id === value;
                        }).length > 0;
                      if (!checkDuplicate) {
                        init.push({
                          ...product,
                          quantity: 1,
                        });
                        setProductSelectList(init);
                      }
                    }}
                  />
                  {false && (
                    <TouchableOpacity
                      style={{ marginLeft: 10 }}
                      onPress={() => {
                        dispatch({ type: "SetSubPage", payload: "addProduct" });
                        navigate("/addProduct");
                      }}
                    >
                      <PlusCircleIcon
                        width={24}
                        height={24}
                        color={"rgba(15, 16, 17, 1)"}
                      />
                    </TouchableOpacity>
                  )}
                </View>
              </Grid>
            </Grid>

            <Grid item xs={12} style={{ zIndex: 5 }}>
              {productCalculate.length > 0 ? (
                <ProductShoppingTable
                  tableData={productCalculate}
                  onChangeProduct={(value) => {
                    let init = productSelectList;
                    let findIndex = productSelectList.findIndex(
                      (d) => d.id === value.productId
                    );
                    if (findIndex < 0) {
                      return;
                    }
                    init[
                      productSelectList.findIndex(
                        (d) => d.id === value.productId
                      )
                    ].quantity = value.quantity;
                    setProductSelectList([...init]);
                  }}
                  onDelete={(values) => {
                    let findIndex = productSelectList.findIndex(
                      (d) => d.id === values.productId
                    );
                    let init = productSelectList;
                    init.splice(findIndex, 1);
                    setProductSelectList([...init]);
                  }}
                />
              ) : (
                <EmptyProduct />
              )}
            </Grid>
            <View style={{ height: 20 }} />
            <Grid item xs={12} md={12} lg={12}>
              <Text
                style={{
                  fontSize: "clamp(16px,2vh,20px)",
                  fontWeight: "bold",
                  color: "rgba(15, 16, 17, 1)",
                }}
              >
                รายละเอียดเพิ่มเติม
              </Text>
              <Divider />
            </Grid>

            <Grid item xs={12} md={6} style={{ zIndex: 4 }}>
              <Text style={Styles.titleText}>
                วิธีการส่ง{" "}
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Dropdown
                  placeholder={"เลือกวิธีการส่ง"}
                  value={shoppingData.transportCode}
                  valueType={"number"}
                  list={transportList}
                  disable={false}
                  onChange={(values) => {
                    const { value, label } = values;
                    console.log(value);
                    setShoppingData({
                      ...shoppingData,
                      transportCode: value,
                    });
                  }}
                />
              </View>
            </Grid>

            <Grid item xs={12} md={6} style={{ zIndex: 3 }}>
              <Text style={Styles.titleText}>ค่าจัดส่ง</Text>
              <View style={{ height: 8 }} />
              <TextField
                value={
                  displayDataSummary.totalDelivery
                    ? Number(displayDataSummary.totalDelivery).toLocaleString({
                        undefined,
                        minimumFractionDigits: 2,
                      })
                    : "0.00"
                }
                disable={true}
              />
            </Grid>

            {shoppingData.transportCode && isRequireAttachmentTransportCode.includes(shoppingData.transportCode) && 
              <React.Fragment>
                  <Grid item xs={12} md={6}>
                    <Text style={Styles.titleTextField}>เอกสารใบแปะหน้า <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text></Text>
                    <View style={{ height: 8 }} />
                    <BoxUploadFile
                        style={{ maxWidth: "100%", maxHeight: 500 }}
                        accept={"image/*,application/pdf"}
                        onSelect={(value) => {
                          console.log("file upload",value)
                          setShoppingData({
                            ...shoppingData,
                            attachment: value,
                          });
                        }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Text style={Styles.titleText}>
                      Tracking Code <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
                    </Text>        
                    <View style={{ height: 8 }} />        
                    <TextField
                        value={shoppingData.trackingCodes ? shoppingData.trackingCodes : ""}
                        placeholder={"ระบุ Tracking Code"}
                        onChangeText={(values) => {
                          setShoppingData({
                            ...shoppingData,
                            trackingCodes: engAndNumber(values),
                          });
                        }}
                      />
                  </Grid>
              </React.Fragment>
            }

            <Grid item xs={12} md={6}>
              <View style={{ height: 40 }} />
              <Text
                style={{
                  fontSize: "clamp(16px,2vh,20px)",
                  fontWeight: "bold",
                  color: "rgba(15, 16, 17, 1)",
                }}
              >
                รายละเอียดการขายสินค้า
              </Text>
              <Divider />
            </Grid>

            <SummaryBox displayDataSummary={displayDataSummary} />

            <Grid item xs={12}>
              <View style={{ height: 20 }} />
              <Text style={Styles.titleTextField}>วิธีการชำระเงิน</Text>
              <View style={{ height: 8 }} />
              <Grid item xs={12} md={4}>
                <Dropdown
                  value={shoppingData.paymentMethodCode}
                  valueType={"string"}
                  placeholder={"เลือกวิธีการชำระเงิน"}
                  list={paymentMethod}
                  onChange={(values) => {
                    const { value, label } = values;
                    setShoppingData({
                      ...shoppingData,
                      paymentMethodCode: value,
                    });
                  }}
                />
              </Grid>
            </Grid>

{false &&            <Grid item xs={12}>
              <View style={{ height: 20 }} />
              <Text style={Styles.titleTextField}>แนบหลักฐานการชำระเงิน</Text>
              <View style={{ height: 16 }} />
              <Grid xs={12} md={4}>
                <BoxImage
                  style={{ maxWidth: 500, maxHeight: 500 }}
                  accept={"image/*"}
                  onSelect={(value) => {
                    setShoppingData({
                      ...shoppingData,
                      paymentImage: value,
                    });
                  }}
                />
              </Grid>
            </Grid>}

            <Grid item xs={12}>
              <Text style={Styles.titleTextField}>รายละเอียดอื่นๆ</Text>
              <View style={{ height: 8 }} />
              <TextArea
                onChangeText={(values) => {
                  setShoppingData({
                    ...shoppingData,
                    description: values,
                  });
                }}
              />
            </Grid>

            <Grid
              container
              spacing={2}
              style={{ justifyContent: "center", marginTop: 40 }}
            >
              <Grid item xs={12} md={4} lg={3}>
                <View style={{ width: "100%" }}>
                  <Button
                    buttonText={"ยกเลิก"}
                    color={"primary"}
                    style={Styles.acceptButton}
                    isBorder={true}
                    onClick={() => {
                      navigateBack();
                    }}
                  />
                </View>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <View style={{ width: "100%" }}>
                  <Button
                    buttonText={"เพิ่มรายการขายปลีก"}
                    color={"primary"}
                    style={Styles.acceptButton}
                    onClick={() => {
                      submit();
                    }}
                  />
                </View>
              </Grid>
            </Grid>
          </Grid>
        </View>
        <View style={{ height: 100 }} />
      </View>
    </React.Fragment>
  );
};

const Styles = {
  container: {
    flex: 1,
    height: "100vh",
    padding: "1.5%",
  },
  topContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  contentContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  textTopContainer1: {
    fontSize: "clamp(18px, 3vw, 22px)",
    fontWeight: "bold",
    color: "#AA4942",
  },
  textTopContainer2: {
    fontSize: "clamp(12px, 3vw, 16px)",
    color: "#696D79",
  },
  topButtonText: {
    fontSize: "clamp(14px, 3vw, 18px)",
  },
  textRemark: {
    fontSize: 14,
    color: "rgba(105, 109, 121, 1)",
  },
};

export default ShoppingAdd;
