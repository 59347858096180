import React from "react";
import { View, Text } from "react-native-web";
//images
import noImage from "../../../asset/images/register/user.png";

const SingleMemberNode = (props) => {
  const { ranking, name, teamCount, imageProfile, userRole } = props;
  return (
    <View style={Styles.container}>
      <img
        src={imageProfile ? imageProfile : noImage}
        alt={"profile"}
        style={Styles.imageProfile}
      />
      <View style={Styles.innerContainer}>
        <View style={Styles.badgeContainer}>
          <Text style={Styles.badgeText}>{teamCount ? teamCount : 0}</Text>
        </View>

        <View style={Styles.contentContainer}>
          <View style={{ height: 20 }} />
          <Text style={Styles.contentPositionText}>
            {ranking ? ranking : userRole === "A" ? "ตัวแทน" : ""}
          </Text>
          <Text style={Styles.contentNameText}>{name ? name : ""}</Text>
        </View>
      </View>
    </View>
  );
};

const Styles = {
  container: {
    display: "inline-block",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
    borderRadius: 8,
    zIndex: 99,
    minWidth: 300,
  },
  imageProfile: {
    width: 56,
    height: 56,
    transform: "translate(-50%, -50%)",
    position: "absolute",
    backgroundColor: "#9C9C9C",
    borderRadius: "50%",
  },
  innerContainer: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
    borderRadius: 8,
  },
  badgeContainer: {
    width: 40,
    height: 30,
    backgroundColor: "#FFD7D7",
    right: "0%",
    transform: "translate(0%, 0%)",
    position: "absolute",
    justifyContent: "center",
    borderBottomLeftRadius: 8,
    borderTopRigthRadius: 8,
  },
  badgeText: {
    fontWeight: "bold",
    color: "#AA4942",
  },
  contentContainer: {
    padding: 20,
  },
  contentPositionText: {
    fontSize: 20,
    fontWeight: "bold",
    color: "#0F1011",
  },
  contentNameText: {
    fontSize: 16,
    color: "#696D79",
  },
};
export default SingleMemberNode;
