import React, { useState, useRef } from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import { useDialog } from "../modal/Popup";
//Images
import { PlusCircleIcon } from "../../asset/images/icons/iconsLib";

const BoxUploadFile = (props) => {
  const { style, accept } = props;
  const { onSelect } = props;
  const dialog = useDialog();

  const [filePreview, setFilePreview] = useState(null);
  const [fileType, setFileType] = useState(null);

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const accept = "image/*,application/pdf";
    
    // Split the accepted types into an array
    const acceptedTypes = accept.split(',');
  
    // Function to check if the file type is accepted
    const isFileTypeAccepted = (fileType, acceptedTypes) => {
      return acceptedTypes.some(acceptedType => {
        if (acceptedType === 'image/*') {
          // Check for any image type
          return fileType.match('image.*');
        } else {
          // Check for specific type
          return fileType === acceptedType;
        }
      });
    };
    if (file && isFileTypeAccepted(file.type, acceptedTypes)) {
      handleFileSelection(file);
    } else {
      dialog.alert({message:"ไฟล์ไม่รองรับ กรุณาลองใหม่อีกครั้ง"})
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
  
    const file = event.dataTransfer.files[0];
    const accept = "image/*,application/pdf";
  
    // Function to check if the file type is accepted
    const isFileTypeAccepted = (fileType, accept) => {
      const acceptedTypes = accept.split(',');
  
      return acceptedTypes.some(acceptedType => {
        if (acceptedType === 'image/*') {
          return fileType.match('image.*');
        } else {
          return fileType === acceptedType;
        }
      });
    };
  
    if (file && isFileTypeAccepted(file.type, accept)) {
      handleFileSelection(file);
    } else {
      dialog.alert({message:"ไฟล์ไม่รองรับ กรุณาลองใหม่อีกครั้ง"})
    }
  };
  

  const handleFileSelection = (file) => {
    if (file) {
      setFilePreview(URL.createObjectURL(file));
      setFileType(file.type);
      onSelect && onSelect(file);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const renderFilePreview = () => {
    if (fileType && fileType.startsWith("image")) {
      return (
        <img
          src={filePreview}
          alt="Uploaded"
          style={{ flex: 1, objectFit: "cover", maxHeight: 300 }}
        />
      );
    } else if (fileType === "application/pdf") {
      return (
        <object
          data={filePreview}
          type="application/pdf"
          width={"100%"}
          height={300}
        ></object>
      );
    } else {
      return null;
    }
  };

  return (
    <TouchableOpacity
      activeOpacity={0.9}
      style={{ ...Styles.container, ...style }}
      onPress={() => {
        handleButtonClick();
      }}
    >
      <input
        ref={fileInputRef}
        type="file"
        style={{ display: "none" }}
        onChange={handleFileChange}
        accept={accept ? accept : "image/*,application/pdf"}
      />
      <div
        style={{ ...Styles.box }}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <View style={Styles.content}>
          {filePreview ? (
            renderFilePreview()
          ) : (
            <React.Fragment>
              <PlusCircleIcon
                width={24}
                height={24}
                color={"rgba(138, 145, 161, 1)"}
              />
              <View style={{ height: 10 }} />
              <Text style={Styles.text1}>
                วางไฟล์ตรงนี้ หรือ{" "}
                <Text style={Styles.text2}>เลือกจากคอมพิวเตอร์</Text>
              </Text>
              <View style={{ height: 8 }} />
              <Text style={Styles.textPlaceHolder}>
                รองรับไฟล์ JPG, PNG, JPEG, PDF
              </Text>
            </React.Fragment>
          )}
        </View>
      </div>
    </TouchableOpacity>
  );
};

const Styles = {
  container: {
    width: "100%",
    borderStyle: "dashed",
    borderWidth: 1,
    borderColor: "rgba(213, 215, 221, 1)",
    padding: 4,
    borderRadius: 4,
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  box: {
    width: "100%",
    padding: 20,
    backgroundColor: "rgba(248, 248, 248, 1)",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  content: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  text1: {
    fontWeight: "bold",
    color: "rgba(10, 10, 10, 1)",
  },
  text2: {
    fontWeight: "bold",
    color: "rgba(222, 124, 120, 1)",
  },
  textPlaceHolder: {
    color: "rgba(185, 189, 199, 1)",
  },
};

export default BoxUploadFile;
