import React, { useEffect, useState } from "react";
import { Text, View } from "react-native-web";
import { Grid } from "@mui/material";
import { Divider } from "rsuite";
//component
import Button from "../../components/button/Button";
import SearchBox from "../../components/textField/SearchBox";
import DatePicker from "../../components/calendar/DatePicker";
import Dropdown from "../../components/dropdown/Dropdown";
import PopupShoppingDetail from "./component/PopupShoppingDetail";
//images
import ShoppingMyListTable from "./component/ShoppingMyListTable";
import { useNavigate } from "react-router-dom";
import { downloadRetailMyList } from "../../services/api/sales/retail";
import { useDialog } from "../../components/modal/Popup";
import { getPaymentStatus } from "../../services/api/payment/payment";
import DatePickerRange from "../../components/calendar/DatePickerRange";
import moment from "moment-timezone";
import apiPath from "../../services/configs/apiPath";
import { get } from "../../functions/fetch/fetch";

const ShoppingMyList = (props) => {
  const [shoppingMyList, setShoppingMyList] = useState([]);
  const [shoppingSearchList, setShoppingSearchList] = useState([]);
  const navigate = useNavigate();
  const dialog = useDialog();
  //view order
  const [popupDetail, setPopupDetail] = useState(false);
  const [viewOrder, setViewOrder] = useState();

  const [search, setSearch] = useState({
    startDate: null,
    endDate: null,
    wording: "",
    status: "",
  });
  const [optionShow, setOptionShow] = useState([
    { value: "", label: "ทั้งหมด" },
    { value: "Y", label: "สำเร็จ" },
    { value: "N", label: "ไม่สำเร็จ" },
  ]);

  useEffect(() => {
    downloadAll();
    onCheckPaymentHandle();
  }, []);

  useEffect(() => {
    if (search) {
      filterSearchData(shoppingMyList);
    }
  }, [search]);

  const downloadAll = async () => {
    dialog.loading();
    await downloadShoppingList();

    dialog.cancle();
  };

  const downloadShoppingList = async () => {
    let res = await downloadRetailMyList();
    setShoppingMyList(res);
  };

  const filterSearchData = async (data) => {
    let searchWord = search.wording
      ? data.filter((d) => {
          const searchTermLower = search.wording.toLowerCase();
          return (
            d.retailCode.toLowerCase().includes(searchTermLower) ||
            d.customerName.toLowerCase().includes(searchTermLower)
          );
        })
      : [];

    let newList = search.status
      ? !search.wording
        ? data.filter((item) => item.status === search.status)
        : searchWord.filter((item) => item.status === search.status)
      : searchWord;

    setShoppingSearchList(newList);
  };

  const searchFilter = async () => {
    dialog.loading();
    let url = apiPath.retail.getRetailMyList;
    let { status, response } = await get(
      url,
      {
        startDate: moment.utc(search.startDate).set({ h: 0, m: 0, s: 0 }),
        endDate: moment.utc(search.endDate).set({ h: 0, m: 0, s: 0 }),
      },
      true
    );
    if (!status) {
      dialog.alert({ message: response.data.error });
    }
    if (response.data) {
      await setShoppingMyList(response.data);
      filterSearchData(response.data);
      dialog.cancle();
    }
  };

  const onCheckPaymentHandle = async () => {
    let params = new URLSearchParams(window.location.search);
    let checkPayment = params.get("payment");
    let refNo = params.get("refNo");

    if (checkPayment && refNo) {
      let res = await getPaymentStatus(refNo);
      console.log(res);
      if (res.checkCount > 0) {
        return;
      }
      if (!res.paymentRef) {
        dialog.alertParent({ message: "การชำระเงินไม่สำเร็จ" });
        return;
      }
      if (res.status === "Y") {
        dialog.alertParent({ message: "เพิ่มรายการขายปลีกสำเร็จ" });
      } else {
        dialog.alertParent({ message: "รายการไม่สำเร็จ" });
      }
    }
  };

  return (
    <React.Fragment>
      <PopupShoppingDetail
        visible={popupDetail}
        data={viewOrder}
        onEnter={() => {}}
        onClose={() => {
          setPopupDetail(false);
        }}
      />
      <View style={Styles.container}>
        <Grid container style={Styles.topContainer} spacing={2}>
          <Grid item xs={12} md={8} lg={9} xl={10}>
            <View style={{ flex: 1 }}>
              <Text style={Styles.textTopContainer1}>รายการขายปลีก</Text>
              <Text style={Styles.textTopContainer2}>
                ขายปลีกและลูกค้า / รายการขายปลีก
              </Text>
            </View>
          </Grid>
          <Grid item xs={12} md={4} lg={3} xl={2}>
            <Button
              buttonText={"เพิ่มรายการขายปลีก"}
              color={"primary"}
              style={{ width: "100%" }}
              styleText={Styles.topButtonText}
              onClick={() => {
                navigate("/shoppingAdd");
              }}
            />
          </Grid>
        </Grid>
        <View style={{ height: 30 }} />

        <View style={Styles.contentContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} style={{ zIndex: 999999 }}>
              <DatePickerRange
                startDate={search.startDate}
                endDate={search.endDate}
                placeholder={"เลือกวันที่เริ่มต้น - วันที่สิ้นสุด"}
                isTop={false}
                styles={{ width: "100%" }}
                onChange={(values) => {
                  const { startDate, endDate } = values;
                  if (startDate && endDate) {
                    setSearch({
                      ...search,
                      startDate: startDate.utc(),
                      endDate: endDate.utc(),
                    });
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <SearchBox
                value={search.wording}
                placeholder={"ค้นหารายการ"}
                onChangeText={(text) => {
                  setSearch({ ...search, wording: text });
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                buttonText={"ค้นหา"}
                color={"primary"}
                style={{ width: "100%" }}
                styleText={Styles.topButtonText}
                onClick={() => {
                  searchFilter();
                }}
              />
            </Grid>
          </Grid>
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Text style={Styles.titleText}>แสดงผล</Text>
              <View style={{ height: 8 }} />
              <Dropdown
                placeholder={""}
                list={optionShow}
                onChange={(values) => {
                  const { value, label } = values;
                  setSearch({
                    ...search,
                    status: value,
                  });
                }}
              />
            </Grid>
          </Grid>
          <View style={{ flex: 1 }}>
            <ShoppingMyListTable
              tableData={
                search.wording || search.status
                  ? shoppingSearchList
                  : shoppingMyList.length > 0
                  ? shoppingMyList
                  : []
              }
              onSelect={(values) => {
                setViewOrder(values);
                setPopupDetail(true);
              }}
            />
          </View>
        </View>
      </View>
    </React.Fragment>
  );
};

const Styles = {
  container: {
    flex: 1,
    height: "100vh",
    padding: "1.5%",
  },
  topContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  contentContainer: {
    width: "100%",
    flex: 1,
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  textTopContainer1: {
    fontSize: "clamp(18px, 3vw, 22px)",
    fontWeight: "bold",
    color: "#AA4942",
  },
  textTopContainer2: {
    fontSize: "clamp(12px, 3vw, 16px)",
    color: "#696D79",
  },
  topButtonText: {
    fontSize: "clamp(14px, 3vw, 18px)",
  },
};

export default ShoppingMyList;
