import axios from "axios";
import apiPath from "../../services/configs/apiPath";
import { isLogin } from "../validate/validate";
import Cookies from "js-cookie";

const getHeaders = (auth) => {
  const headers = {
    "Content-Type": "application/json",
  };

  if (auth) {
    const rosegoldToken = JSON.parse(Cookies.get("rosegoldtoken"));
    if (rosegoldToken && rosegoldToken.access_token) {
      headers.Authorization = `Bearer ${rosegoldToken.access_token}`;
    }
  }
  return headers;
};

export const post = async (url, params = {}, auth = false) => {
  return new Promise(async (resolve) => {
    if (!url || (auth && !isLogin())) {
      return null;
    }
    try {
      const response = await axios.post(url, params, {
        headers: getHeaders(auth),
      });
      resolve({
        status: true,
        response: response,
      });
    } catch (error) {
      resolve({
        status: false,
        response: error.response,
      });
    }
  });
};

export const get = (url, params = {}, auth = false) => {
  return new Promise(async (resolve) => {
    if (!url || (auth && !isLogin())) {
      resolve(null);
    }
    try {
      const response = await axios.get(url, {
        headers: getHeaders(auth),
        params: params,
      });
      resolve({
        status: true,
        response: response,
      });
    } catch (error) {
      resolve({
        status: false,
        response: error.response,
      });
    }
  });
};

export const put = async (url, params = {}, auth = false) => {
  return new Promise(async (resolve) => {
    if (!url || (auth && !isLogin())) {
      return null;
    }
    try {
      const response = await axios.put(url, params, {
        headers: getHeaders(auth),
      });
      resolve({
        status: true,
        response: response,
      });
    } catch (error) {
      resolve({
        status: false,
        response: error.response,
      });
    }
  });
};

export const remove = async (url, params = {}, auth = false) => {
  return new Promise(async (resolve) => {
    if (!url || (auth && !isLogin())) {
      return null;
    }
    try {
      const response = await axios.delete(url, {
        data: params,
        headers: getHeaders(auth),
      });
      resolve({
        status: true,
        response: response,
      });
    } catch (error) {
      resolve({
        status: false,
        response: error.response,
      });
    }
  });
};

export const upload = (files, folderName, fileName = "") => {
  return new Promise(async (resolve) => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("file", file);
    });
    if (!folderName) {
      resolve({
        status: false,
        response: null,
      });
    }
    formData.append("folderName", folderName);
    formData.append("fileName", fileName);

    console.log(apiPath.uploads.uploads, files, folderName, formData);
    try {
      const response = await axios.post(apiPath.uploads.uploads, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      resolve({
        status: true,
        response: response,
      });
    } catch (error) {
      resolve({
        status: false,
        response: error.response,
      });
    }
  });
};
