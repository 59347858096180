import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import { Grid } from "@mui/material";
import { Divider } from "rsuite";
import { useNavigate } from "react-router-dom";
//component
import Button from "../../components/button/Button";
import Dropdown from "../../components/dropdown/Dropdown";
import UploadImage from "../../components/uploadbox/UploadImage";
import TextField from "../../components/textField/TextField";
import DatePickerRange from "../../components/calendar/DatePickerRange";
import apiPath from "../../services/configs/apiPath";
import { get, post, upload } from "../../functions/fetch/fetch";
import { useDialog } from "../../components/modal/Popup";
import CheckBoxListV2 from "../../components/checkbox/CheckBoxListV2";
import { formatNumber } from "../../functions/format/numberFormat";
import moment from "moment-timezone";
import useSelection from "antd/es/table/hooks/useSelection";
import { base64ToFile } from "../../functions/format/convertFile";
import { updateProfile } from "firebase/auth";

const AddReward = (props) => {
  //property
  const navigate = useNavigate();
  const dialog = useDialog();
  const { editData, onClose } = props;
  const { user } = useSelection((state) => {
    return state;
  });
  //state
  const [data, setData] = useState({
    reward_type_id: null,
    condition_reward_id: 0, // ใช้เฉพาะตอนเป็นเงื่อนไข pro เด้ง 2  อ้างอิงกับ pro 1 , กรณีที่เป็น cashback, pro 1 ให้ตั้ง 0
    condition_reward_amount: 0, // ใช้เฉพาะตอนเป็นเงื่อนไข pro เด้ง 2 อ้างอิงจำนวนที่ต้องแลก, กรณีที่เป็น cashback, pro 1 ให้ตั้ง 0
    ticket_use: 0,
    qualify_rank: [], // แรงค์ที่ใช้งานได้
    open_date: moment(),
    close_date: moment(),
    name: "",
    image: "",
    imageFile: null,
    cashback_value: 0, // กรณีที่เป็น reward type id 1 ที่เป็น cashback เท่านั้น แทนจำนวนบาท ถ้าเป็น pro 1, pro 2 ให้ระบถ 0
  });
  const [rewardType, setRewardType] = useState([]);
  const [rankingList, setRankingList] = useState([]);
  const [rewardPro1List, setRewardPro1List] = useState([]);

  //edit
  const [isQualifyRank, setIsQualifyRank] = useState(false);

  useEffect(() => {
    const downloadAll = async () => {
      await downloadRewardType();
      await downloadAllRankingList();
      await downloadRewardPro1All();
      if (editData) {
        console.log("Edit data", editData);
        setData({
          ...data,
          ...editData,
          open_date: moment(editData.condition_open_date),
          close_date: moment(editData.condition_close_date),
        });
      }
    };
    downloadAll();
  }, []);

  useEffect(() => {
    if (rankingList.length > 0) {
      console.log("ranking list");
      if (!isQualifyRank && editData) {
        let init = [];
        let qualify = editData.qualify_rank.split(",");
        rankingList.forEach((item) => {
          let filter = qualify.filter((d) => {
            return Number(d) === Number(item.value); // แก้ไขที่นี้: จาก d.value === d เป็น d.value === item.value
          });
          init.push({ ...item, active: filter.length > 0 ? "Y" : "N" });
        });

        setRankingList(init);

        setIsQualifyRank(true);
      }
    }
  }, [rankingList]);

  const validation = () => {
    let errors = [];
    if (!data.reward_type_id) {
      errors.push("reward_type_id");
    }
    if (!data.name) {
      errors.push("name");
    }
    if (!data.open_date) {
      errors.push("open_date");
    }
    if (!data.close_date) {
      errors.push("close_date");
    }
    if (!data.imageFile && !editData) {
      errors.push("imageFile");
    }
    if (errors.length > 0) {
      console.log(errors);
      return false;
    }
    return true;
  };

  const submit = async () => {
    if (!validation()) {
      dialog.alert({ message: "กรุณากรอกข้อมูลให้ครบถ้วน" });
      return;
    }
    //check ranking
    let q = [];
    rankingList.forEach((item) => {
      if (item.active === "Y") {
        q.push(item.value);
      }
    });
    if (q.length === 0) {
      dialog.alert({ message: "กรุณาระบุระดับตัวแทนที่เข้าได้" });
      return;
    }
    data.qualify_rank = q;
    //Upload
    if (data.imageFile) {
      let userUploadName = user ? user.agentCode : "NOTFOUNDUSER";
      console.log(userUploadName);
      const uploadImages = await upload(
        [base64ToFile(data.imageFile)],
        `${process.env["REACT_APP_ENV"]}/Images/Reward/${userUploadName}`
      );
      const resImg = uploadImages.response;
      if (!resImg.data) {
        await dialog.alert({
          title: "แจ้งเตือน",
          message: uploadImages.response.data.error,
        });
        return;
      }
      console.log(resImg);
      data.image = resImg.data.data[0];
    }

    data.class_open_time = moment(data.class_open_time).toDate();
    data.class_close_time = moment(data.class_close_time).toDate();
    delete data.imageFile;
    let url = apiPath.reward.createReward;
    let resCreate = await post(url, data, true);
    if (!resCreate.status) {
      await dialog.alert({
        title: "แจ้งเตือน",
        message: resCreate.response.data.error,
      });
      dialog.cancle();
      return;
    }
    let alert = dialog.alert({ message: "สร้างรางวัลสำเร็จ" });
    if (!alert) {
      return;
    }
    navigate("/exchangeReward");
  };

  const update = async () => {
    if (!validation()) {
      dialog.alert({ message: "กรุณากรอกข้อมูลให้ครบถ้วน" });
      return;
    }
    //check ranking
    let q = [];
    rankingList.forEach((item) => {
      if (item.active === "Y") {
        q.push(item.value);
      }
    });
    if (q.length === 0) {
      dialog.alert({ message: "กรุณาระบุระดับตัวแทนที่เข้าได้" });
      return;
    }
    data.qualify_rank = q;
    //Upload
    if (data.imageFile) {
      let userUploadName = user ? user.agentCode : "NOTFOUNDUSER";
      const uploadImages = await upload(
        [base64ToFile(data.imageFile)],
        `${process.env["REACT_APP_ENV"]}/Images/Reward/${userUploadName}`
      );
      const resImg = uploadImages.response;
      if (!resImg.data) {
        await dialog.alert({
          title: "แจ้งเตือน",
          message: uploadImages.response.data.error,
        });
        return;
      }
      console.log(resImg);
      data.image = resImg.data.data[0];
    }

    data.class_open_time = moment(data.class_open_time).toDate();
    data.class_close_time = moment(data.class_close_time).toDate();
    data.disable = false;
    delete data.imageFile;
    let url = apiPath.reward.updateReward;
    let resCreate = await post(url, data, true);
    if (!resCreate.status) {
      await dialog.alert({
        title: "แจ้งเตือน",
        message: resCreate.response.data.error,
      });
      dialog.cancle();
      return;
    }
    let alert = dialog.alert({ message: "บันทึกสำเร็จ" });
    if (!alert) {
      return;
    }
    navigate("/exchangeReward");
  };

  const downloadRewardType = async () => {
    let url = apiPath.reward.getRewardTypeAll;
    let { status, response } = await get(url, {}, true);
    if (!status) {
      dialog.alert({ message: "เกิดข้อผิดพลาด" });
      return;
    }
    if (response.data) {
      let init = [];
      response.data.forEach((item) => {
        init.push({
          label: item.type,
          value: item.id,
        });
      });
      setRewardType(init);
    }
  };
  const downloadRewardPro1All = async () => {
    let url = apiPath.reward.getRewardPro1All;
    let { status, response } = await get(url, {}, true);
    if (!status) {
      dialog.alert({ message: "เกิดข้อผิดพลาด" });
      return;
    }
    if (response.data) {
      let init = [];
      response.data.forEach((item) => {
        init.push({ label: item.name, value: item.id });
      });
      setRewardPro1List(init);
    }
  };

  const downloadAllRankingList = async () => {
    let url = apiPath.member.getAllRankingList;
    let { status, response } = await get(url, {}, true);
    if (!status) {
      dialog.alert({ message: "เกิดข้อผิดพลาด" });
    }
    if (response.data) {
      let val = response.data;
      let init = [];
      let qualify_rank = [];
      val.forEach((element) => {
        init.push({
          value: element.id,
          label: element.name,
          enable: true,
          active: "Y",
        });
        qualify_rank.push(element.level);
      });
      setRankingList(init);

      setData({ ...data, qualify_rank: qualify_rank });
    }
  };

  return (
    <React.Fragment>
      <View style={Styles.container}>
        <Grid container style={Styles.topContainer} spacing={2}>
          <Grid item xs={12} md={8} lg={9} xl={10}>
            <View style={{ flex: 1 }}>
              <Text style={Styles.textTopContainer1}>สร้างของรางวัล</Text>
              <Text style={Styles.textTopContainer2}>
                ของรางวัล / สร้างของรางวัล
              </Text>
            </View>
          </Grid>
        </Grid>
        <View style={{ height: 30 }} />
        <View style={Styles.contentContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8} lg={6} xl={4}>
              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    fontSize: "clamp(16px,2vh,20px)",
                    fontWeight: "bold",
                    color: "rgba(15, 16, 17, 1)",
                  }}
                >
                  รายละเอียดของรางวัล
                </Text>
              </View>
            </Grid>
          </Grid>
          <Divider />

          <View
            style={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <UploadImage
              value={data.image ? data.image : null}
              placeholder={"เพิ่มรูปภาพ"}
              style={{ width: 200, height: 200 }}
              onChangeImage={(image) => {
                setData({
                  ...data,
                  imageFile: image,
                  image: image,
                });
              }}
            />
            <View style={{ marginTop: 16 }} />
            <Text style={Styles.textImageMark}>
              รองรับไฟล์ JPG, PNG, JPEGS เท่านั้น
            </Text>
          </View>
          <View style={{ height: 30 }} />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} style={{ zIndex: 20 }}>
              <Text style={Styles.titleText}>
                ประเภทของรางวัล{" "}
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Dropdown
                  value={data.reward_type_id ? data.reward_type_id : 0}
                  valueType={"string"}
                  placeholder={"เลือกประเภทของรางวัล"}
                  list={rewardType}
                  onChange={(values) => {
                    const { value, label } = values;
                    if (value === 1) {
                      setData({
                        ...data,
                        reward_type_id: value,

                        condition_reward_amount: 0,
                        condition_reward_id: 0,
                      });
                    } else {
                      setData({
                        ...data,
                        reward_type_id: value,
                        cashback_value: 0,
                      });
                    }
                  }}
                />
              </View>
            </Grid>
            <Grid item xs={12} md={6} style={{ zIndex: 19 }}>
              <Text style={Styles.titleText}>
                ชื่อของรางวัล{" "}
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />
              <TextField
                value={data.name ? data.name : ""}
                iconName={""}
                isInvalid={false}
                placeholder={"ระบุชื่อของรางวัล"}
                errorMessage={""}
                onChangeText={(value) => {
                  setData({
                    ...data,
                    name: value,
                  });
                }}
              />
            </Grid>
            {data.reward_type_id !== 1 ? (
              <Grid item xs={12} md={6} style={{ zIndex: 18 }}>
                <Text style={Styles.titleText}>
                  เงื่อนไขพิเศษ{" "}
                  <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
                </Text>
                <View style={{ height: 8 }} />
                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Dropdown
                    value={
                      data.condition_reward_id ? data.condition_reward_id : 0
                    }
                    valueType={"number"}
                    placeholder={"เลือกเงื่อนไขพิเศษ"}
                    list={rewardPro1List}
                    onChange={(values) => {
                      const { value, label } = values;
                      setData({
                        ...data,
                        condition_reward_id: value,
                      });
                    }}
                  />
                </View>
                <View style={{ height: 8 }} />
                <Text
                  style={{
                    fontSize: 12,
                    color: "rgba(105, 109, 121, 1)",
                  }}
                >
                  เงื่อนไขสำหรับใช้ในการแลกแคมเปญ
                </Text>
              </Grid>
            ) : null}
            {data.reward_type_id === 1 ? (
              <Grid item xs={12} md={6} style={{ zIndex: 17 }}>
                <Text style={Styles.titleText}>
                  จำนวน Cash back{" "}
                  <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
                </Text>
                <View style={{ height: 8 }} />
                <TextField
                  value={data.cashback_value ? data.cashback_value : 0}
                  iconName={""}
                  isInvalid={false}
                  placeholder={"ระบุจำนวนทริป"}
                  errorMessage={""}
                  onChangeText={(value) => {
                    setData({
                      ...data,
                      cashback_value: formatNumber(value),
                    });
                  }}
                />
              </Grid>
            ) : null}
            {data.reward_type_id !== 1 ? (
              <Grid item xs={12} md={6} style={{ zIndex: 17 }}>
                <Text style={Styles.titleText}>
                  จำนวนทริป{" "}
                  <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
                </Text>
                <View style={{ height: 8 }} />
                <TextField
                  value={
                    data.condition_reward_amount
                      ? data.condition_reward_amount
                      : 0
                  }
                  iconName={""}
                  isInvalid={false}
                  placeholder={"ระบุจำนวนทริป"}
                  errorMessage={""}
                  onChangeText={(value) => {
                    setData({
                      ...data,
                      condition_reward_amount: formatNumber(value),
                    });
                  }}
                />
              </Grid>
            ) : null}
            <Grid item xs={12} md={6} style={{ zIndex: 16 }}>
              <Text style={Styles.titleText}>
                วันที่เริ่มต้น - วันที่สิ้นสุด
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />
              <DatePickerRange
                startDate={data ? moment(data.open_date) : null}
                endDate={data ? moment(data.close_date) : null}
                placeholder={"เลือกวันที่เริ่มต้น - วันที่สิ้นสุด"}
                isTop={false}
                styles={{ width: "100%" }}
                onChange={(values) => {
                  const { startDate, endDate } = values;
                  if (startDate && endDate) {
                    setData({
                      ...data,
                      open_date: startDate.utc(),
                      close_date: endDate.utc(),
                    });
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ zIndex: 15 }}>
              <Text style={Styles.titleText}>
                จำนวนแต้มที่ใช้แลก{" "}
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />
              <TextField
                value={data.ticket_use ? data.ticket_use : 0}
                iconName={""}
                isInvalid={false}
                placeholder={"ระบุจำนวนแต้มที่ใช้แลก"}
                errorMessage={""}
                onChangeText={(value) => {
                  setData({
                    ...data,
                    ticket_use: formatNumber(value),
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: 10, zIndex: 9 }}>
              <View
                style={{
                  borderWidth: 1,
                  borderColor: "rgba(213, 215, 221, 1)",
                  borderRadius: 8,
                  padding: 10,
                }}
              >
                <Text>ประเภทของผู้รับรางวัล</Text>
                <CheckBoxListV2
                  list={rankingList}
                  showLine={false}
                  onCheck={(values) => {
                    let findIndex = rankingList.findIndex((item) => {
                      return item.value === values.value;
                    });
                    let init = rankingList;
                    init[findIndex] = { ...values };
                    //findIndex
                    setRankingList([...init]);
                  }}
                />
              </View>
            </Grid>
          </Grid>
          <View style={{ height: 40 }} />
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              buttonText={"ยกเลิก"}
              color={"primary"}
              style={{ flex: 1, maxWidth: 400 }}
              isBorder={true}
              onClick={() => {
                if (editData) {
                  onClose && onClose();
                } else {
                  navigate("/exchangeReward");
                }
              }}
            />
            <View style={{ width: 20 }} />
            <Button
              buttonText={editData ? "บันทึก" : "สร้างของรางวัล"}
              color={"primary"}
              style={{ flex: 1, maxWidth: 400 }}
              isBorder={false}
              onClick={() => {
                if (editData) {
                  update();
                } else {
                  submit();
                }
              }}
            />
          </View>
          <View style={{ height: 40 }} />
        </View>
        <View style={{ height: 100 }} />
      </View>
    </React.Fragment>
  );
};

const Styles = {
  container: {
    flex: 1,
    height: "100vh",
    padding: "1.5%",
    zIndex: 0,
    overflow: "visible", // Set overflow to "visible"
  },
  topContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
    zIndex: 10,
  },
  contentContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  textTopContainer1: {
    fontSize: "clamp(18px, 3vw, 22px)",
    fontWeight: "bold",
    color: "#AA4942",
  },
  textTopContainer2: {
    fontSize: "clamp(12px, 3vw, 16px)",
    color: "#696D79",
  },
  topButtonText: {
    fontSize: "clamp(14px, 3vw, 18px)",
  },
  titleSearch: {
    fontSize: 20,
    color: "#0F1011",
  },
  subTitleSearch: {
    fontSize: 16,
    color: "#0F1011",
    marginTop: 8,
  },
};
export default AddReward;
