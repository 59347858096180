import React, { useState } from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
//components
import DropdownMenuPopover from "../../../components/dropdown/DropdownPopover";
import PageButtonNavigate from "../../../components/button/PageButtonNavigate";
//images
import emptyProduct from "../../../asset/images/product/inventory/emptyProduct.png";
import moment from "moment-timezone";
//functions

const AdminListTable = (props) => {
  const { onEdit, onChangeActive } = props;

  const { tableHeader = [], tableData = [] } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const pagesCount = Math.ceil(tableData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const tableDataSliced = tableData.slice(startIndex, endIndex);

  const actionSelect = (value) => {
    if (value.action === "edit") {
      onEdit && onEdit({ ...value });
    }
    if (value.action === "activeUser") {
      onChangeActive && onChangeActive({ ...value, active: "Y" });
    }
    if (value.action === "inActiveUser") {
      onChangeActive && onChangeActive({ ...value, active: "N" });
    }
  };

  return tableData.length > 0 ? (
    <View style={{ width: "100%", height: "100%" }}>
      <TableContainer sx={styles.tableContainer}>
        <Table sx={styles.table}>
          <TableHead sx={{ position: "sticky", top: 0, zIndex: 9999 }}>
            <TableRow style={{ width: "100%", backgroundColor: "#FFF" }}>
              <TableCell sx={{ ...styles.headerCell }}></TableCell>
              <TableCell sx={{ ...styles.headerCell }}>ชื่อ- นามสกุล</TableCell>
              <TableCell sx={{ ...styles.headerCell }}>ชื่อเล่น</TableCell>
              <TableCell sx={{ ...styles.headerCell }}>อีเมล</TableCell>
              <TableCell sx={{ ...styles.headerCell }}>วันที่สร้าง</TableCell>
              <TableCell sx={{ ...styles.headerCell }}>สถานะ</TableCell>
              <TableCell sx={{ ...styles.headerCell }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableDataSliced.map((row) => (
              <TableRow key={row.id} sx={styles.tableRow}>
                <TableCell
                  sx={{
                    ...styles.tableCell,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={row.imageProfile ? row.imageProfile : ""}
                    style={{
                      width: 50,
                      height: 50,
                      borderRadius: 8,
                      objectFit: "cover",
                      alignSelf: "center",
                    }}
                  />
                </TableCell>
                <TableCell sx={styles.tableCell}>
                  <Text>{row.name ? row.name : ""}</Text>
                </TableCell>
                <TableCell sx={styles.tableCell}>
                  {row.nickName ? row.nickName : ""}
                </TableCell>
                <TableCell sx={styles.tableCell}>
                  {row.email ? row.email : ""}
                </TableCell>{" "}
                <TableCell sx={styles.tableCell}>
                  {row.createDate
                    ? moment(row.createDate).format("DD/MM/YYYY")
                    : ""}
                </TableCell>
                <TableCell sx={styles.tableCell}>
                  {row.active === "Y"
                    ? "ปกติ"
                    : row.active === "N"
                    ? "ระงับการใช้งาน"
                    : "-"}
                </TableCell>
                <TableCell sx={styles.tableCell}>
                  <DropdownMenuPopover
                    title={"ตัวเลือก"}
                    list={[
                      {
                        label:
                          row.active === "Y"
                            ? "ระงับการใช้งาน"
                            : "เปิดสิทธิใช้งาน",
                        value:
                          row.active === "Y" ? "inActiveUser" : "activeUser",
                      },
                      { label: "แก้ไขข้อมูล", value: "edit" },
                    ]}
                    onSelect={(value) => {
                      actionSelect({ ...row, action: value });
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <PageButtonNavigate
        currentPage={currentPage}
        pagesCount={pagesCount}
        startIndex={startIndex}
        endIndex={endIndex}
        dataCount={tableData.length}
        onSelectPage={(page) => {
          setCurrentPage(page);
        }}
      />
    </View>
  ) : (
    <EmptyList />
  );
};

const EmptyList = () => {
  return (
    <View
      style={{
        flex: 1,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={emptyProduct} style={{ width: 120 }} alt={"emptyProduct"} />
      <View style={{ height: 30 }} />
      <Text
        style={{
          fontSize: "clamp(16px,2vh,20px)",
          fontWeight: "bold",
          color: "rgba(15, 16, 17, 1)",
        }}
      >
        ยังไม่มีรายการผู้ดูแล
      </Text>
    </View>
  );
};

const styles = {
  tableContainer: {
    overflowY: "scroll",
    scrollbarWidth: "thin",
    scrollbarColor: "#c7c7c7 #f5f5f5",
    flex: 1,
  },
  table: {
    border: "none",
    minWidth: 650,
  },
  tableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: "rgba(255, 255, 255, 1)",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "rgba(255, 241, 241, 1)",
    },
  },
  tableCell: {
    borderBottom: "none",
    whiteSpace: "nowrap",
    wordBreak: "break-word",
  },
  headerCell: {
    color: "rgba(165, 88, 90, 1)",
    whiteSpace: "nowrap",
  },
};

export default AdminListTable;
