import React, { useEffect, useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import { View, TouchableOpacity, Text } from "react-native-web";
//images
import { RiArrowDownSLine } from "react-icons/ri";
import { SlOptionsVertical } from "react-icons/sl";

const DropdownPopover = (props) => {
  const { title, list, showIconOnly = false, customIcon, buttonStyle } = props;
  const { onSelect } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectItem, setSelectItem] = useState("");

  useEffect(() => {
    if (list && !selectItem) {
      setSelectItem(list[0] ? list[0].label : "");
    }
  }, [list]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (value) => {
    setAnchorEl(null);
    onSelect && onSelect(value);
  };

  return (
    <>
      <View style={Styles.button}>
        {buttonStyle ? (
          <View style={{ flexDirection: "row" }}
          onPress={handleClick}
          variant="contained"
          >
        
            <Button
              buttonText={title ? title : selectItem}
              color={"primary"}
              style={{ width: "100%" }}
              isBorder={true}
              onClick={handleClick}
            />
          </View>
        ) : (
          <TouchableOpacity
            style={{ flexDirection: "row" }}
            onPress={handleClick}
            variant="contained"
          >
            {customIcon ? (
              customIcon
            ) : showIconOnly ? (
              <SlOptionsVertical
                style={{
                  fontSize: 15,
                  color: "rgba(105, 109, 121, 1)",
                }}
              />
            ) : (
              <React.Fragment>
                <Text>{title ? title : selectItem}</Text>
                <RiArrowDownSLine style={Styles.icon} />
              </React.Fragment>
            )}
          </TouchableOpacity>
        )}
      </View>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: "100px",
          },
        }}
      >
        {list.map((item) => {
          return (
            <MenuItem
              key={"menu_" + item.label}
              sx={{
                "&:hover": {
                  background: "rgba(255, 215, 215, 1)", // สีที่คุณต้องการให้แสดงเมื่อ hover
                },
              }}
              onClick={() => {
                handleClose && handleClose(item.value);
              }}
            >
              {item.label}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

const Styles = {
  button: {
    flexDirection: "row",
  },
  icon: {
    fontSize: 20,
    color: "#000",
  },
};

export default DropdownPopover;
