import React from "react";
import { View, Text } from "react-native-web";

const StockDisplayContainer = (props) => {
  const { items = [], isLoading = false } = props;

  return (
    <React.Fragment>
      {!isLoading ? (
        <View style={Styles.container}>
          {items.map((item) => {
            return <RowItemsStock data={item} />;
          })}
        </View>
      ) : null}
    </React.Fragment>
  );
};

const RowItemsStock = (props) => {
  const { data } = props;
  return (
    <View style={Styles.containerRow}>
      <Text style={Styles.rowText}>{data ? data.label : ""}</Text>
      <Text style={Styles.valueRowText}>{data ? data.value : ""}</Text>
      <Text style={Styles.subValueRowText}>ชิ้น</Text>
    </View>
  );
};

const Styles = {
  container: {
    width: "100%",
    padding: 15,
    backgroundColor: "#F8F8F8",
    borderRadius: 16,
  },
  containerRow: {
    width: "100%",
    flexDirection: "row",
    marginBottom: 15,
    justifyContent: "center",
    alignItems: "center",
  },
  rowText: {
    flex: 1,
    fontSize: 16,
    color: "#696D79",
  },
  valueRowText: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#0F1011",
  },
  subValueRowText: {
    fontSize: 14,
    color: "#696D79",
    marginLeft: 8,
  },
};
export default StockDisplayContainer;
