import React from "react";
import { View, ScrollView, Text, TouchableOpacity } from "react-native-web";
import {
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Divider,
} from "@mui/material";
import { GroupIcon } from "../../../asset/images/icons/iconsLib";
import Button from "../../../components/button/Button";
import moment from "moment-timezone";
import axios from "axios";
import { dropdownMenuPropTypes } from "rsuite/esm/Picker/DropdownMenu";
import { saveAs } from "file-saver";
import apiPath from "../../../services/configs/apiPath";

const ContentListTable = (props) => {
  const { data = [{}, {}] } = props;

  const downloadFile = (filePath, fileName = "file.jpg") => {
    let url = `${apiPath.university.downloadFile}?url=${filePath}`;
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok " + response.statusText);
        }

        const contentDisposition = response.headers.get("Content-Disposition");
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(
            /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          );
          if (fileNameMatch && fileNameMatch[1]) {
            fileName = fileNameMatch[1].replace(/['"]/g, "");
          }
        }

        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName; // The file name is now automatically extracted from the headers
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch((error) => console.error("Error:", error));
  };

  return (
    <View style={{ width: "100%", height: "100%" }}>
      <TableContainer sx={styles.tableContainer}>
        <Table sx={styles.table}>
          <TableBody>
            {data &&
              data.map((row) => (
                <TableRow key={row.id} sx={styles.tableRow}>
                  <TableCell sx={styles.tableCell}>
                    <GroupIcon color={"rgba(170, 73, 66, 1)"} />
                  </TableCell>
                  <TableCell sx={styles.tableCell}>
                    <Text>{moment(row.create_date).format("DD/MM/YYYY")}</Text>
                  </TableCell>
                  <TableCell sx={styles.tableCell}>
                    <Text>{row.detail}</Text>
                  </TableCell>
                  <TableCell
                    sx={{
                      ...styles.tableCell,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                    }}
                  >
                    <TouchableOpacity
                      style={{
                        backgroundColor: "rgba(180, 241, 179, 1)",
                        borderRadius: 8,
                        padding: 2,
                        width: 130,
                      }}
                      onPress={() => {
                        if (row.file_download) {
                          downloadFile(row.file_download, row.detail);
                        }
                      }}
                    >
                      <Text
                        style={{
                          fontWeight: "bold",
                          flex: 1,
                          textAlign: "center",
                          color: "rgba(28, 155, 26, 1)",
                        }}
                      >
                        ดาวน์โหลด
                      </Text>
                    </TouchableOpacity>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </View>
  );
};

const styles = {
  tableContainer: {
    overflowY: "scroll",
    scrollbarWidth: "thin",
    scrollbarColor: "#c7c7c7 #f5f5f5",
    flex: 1,
  },
  table: {
    border: "none",
    minWidth: 650,
  },
  tableCell: {
    borderBottom: "none",
    whiteSpace: "nowrap",
    wordBreak: "break-word",
  },
  headerCell: {
    color: "rgba(165, 88, 90, 1)",
    whiteSpace: "nowrap",
  },
};

export default ContentListTable;
