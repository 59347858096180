import React from 'react';
import { Grid } from '@mui/material';
//component
import LevelAgentHome from './LevelAgentHome';
import AgentInfoHome from './AgentInfoHome';

const HomeBottomContainer = (props)=>{
    
    return (
        <Grid container spacing={2}>
            <LevelAgentHome/>
            <AgentInfoHome/>
        </Grid>
    )
}

export default HomeBottomContainer;