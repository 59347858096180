import { WaveLoading } from "react-loadingg";
import { View } from "react-native-web";

const LoadingTab = (props) => {
  return (
    <View
      style={{
        flex: 1,
      }}
    >
      <WaveLoading color="#DE7C78" />
    </View>
  );
};

export default LoadingTab;
