import React, { useState } from "react";
import { Text, View } from "react-native-web";
import { Grid } from "@mui/material";
import { Divider } from "rsuite";
//component
import Button from "../../components/button/Button";
import ButtonV2 from "../../components/button/ButtonV2";
import DatePicker from "../../components/calendar/DatePicker";
import Dropdown from "../../components/dropdown/Dropdown";
import WalletTable from "./component/WalletTable";
//images
import Cookies from "js-cookie";

const Wallet = (props) => {
  const [orderList, setOrderList] = useState([""]);
  return (
    <React.Fragment>
      <View style={Styles.container}>
        <Grid container style={Styles.topContainer} spacing={2}>
          <Grid item xs={12} md={8} lg={9} xl={10}>
            <View style={{ flex: 1 }}>
              <Text style={Styles.textTopContainer1}>กระเป๋าเงิน</Text>
              <Text style={Styles.textTopContainer2}>
                เครดิตร้านค้า / กระเป๋าเงิน
              </Text>
            </View>
          </Grid>
        </Grid>
        <View style={{ height: 30 }} />

        <View style={Styles.contentContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={5}>
              <View style={{ height: 8 }} />
              <Dropdown
                placeholder={"ข้อมูลตัวแทน"}
                list={[]}
                onChange={(values) => {
                  const { value, label } = values;
                  console.log(value, label);
                }}
              />
            </Grid>
            <Grid item xs={12} lg={5}>
              <View style={{ height: 8 }} />
              <DatePicker
                placeholder="วันที่เริ่มต้น - วันที่สิ้นสุด"
                styles={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} lg={2} xl={2}>
              <View style={{ height: 8 }} />
              <Button
                buttonText={"ค้นหา"}
                color={"primary"}
                style={{ width: "100%" }}
                styleText={Styles.topButtonText}
                onClick={() => {}}
              />
            </Grid>
          </Grid>
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <View>
                <Text style={Styles.textTitleCredit}>
                  ยอดเงินคงเหลือในกระเป๋า
                </Text>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                >
                  <Text style={Styles.textValueCredit}>21,100.75 </Text>
                  <Text style={Styles.textMarkCredit}> บาท</Text>
                </View>
                <View style={{ height: 10 }} />
                <ButtonV2
                  buttonText={"- ถอนเงิน"}
                  color={"primary"}
                  style={{ height: 40, maxWidth: 200 }}
                  styleText={{ fontSize: 16 }}
                  isBorder={true}
                  onClick={() => {}}
                />
              </View>
            </Grid>
          </Grid>
          <View style={{ height: 30 }} />
          <View style={{ flex: 1 }}>
            <WalletTable
              tableData={[
                {
                  createDate: "20/9/2022 14:00 น.",
                  transactionId: "ประเภทธุรกรรม",
                  amount: "19,238.75",
                  status: "Y",
                },
                {
                  createDate: "20/9/2022 14:00 น.",
                  transactionId: "ประเภทธุรกรรม",
                  amount: "19,238.75",
                  status: "Y",
                },
                {
                  createDate: "20/9/2022 14:00 น.",
                  transactionId: "ประเภทธุรกรรม",
                  amount: "19,238.75",
                  status: "Y",
                },
                {
                  createDate: "20/9/2022 14:00 น.",
                  transactionId: "ประเภทธุรกรรม",
                  amount: "19,238.75",
                  status: "Y",
                },
                {
                  createDate: "20/9/2022 14:00 น.",
                  transactionId: "ประเภทธุรกรรม",
                  amount: "19,238.75",
                  status: "Y",
                },
                {
                  createDate: "20/9/2022 14:00 น.",
                  transactionId: "ประเภทธุรกรรม",
                  amount: "19,238.75",
                  status: "Y",
                },
              ]}
            />
          </View>
        </View>
      </View>
    </React.Fragment>
  );
};

const Styles = {
  container: {
    flex: 1,
    height: "100vh",
    padding: "1.5%",
  },
  topContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  contentContainer: {
    width: "100%",
    flex: 1,
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  textTopContainer1: {
    fontSize: "clamp(18px, 3vw, 22px)",
    fontWeight: "bold",
    color: "#AA4942",
  },
  textTopContainer2: {
    fontSize: "clamp(12px, 3vw, 16px)",
    color: "#696D79",
  },
  topButtonText: {
    fontSize: "clamp(14px, 3vw, 18px)",
  },
  creditBox: {
    flexDirection: "row",
    alignItems: "flex-end",
  },
  textTitleCredit: {
    fontSize: 18,
    color: "#0F1011",
  },
  textValueCredit: {
    fontSize: 36,
    fontWeight: "bold",
    color: "#0F1011",
  },
  textMarkCredit: {
    fontSize: 18,
    color: "#0F1011",
    lineHeight: 42,
  },
};

export default Wallet;
