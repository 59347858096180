export function formatNumber(value) {
  return Number(String(value).replace(/[^0-9]+/g, ""));
}

export function formatNumberString(value) {
  return String(value).replace(/[^0-9]+/g, "");
}

export function formatDecimal(value) {
  var num = parseFloat(value);
  return isNaN(num) ? "" : num.toFixed(2);
}

export function formatCreditCardNumber(input) {
  var cardNum = input.replace(/\s+/g, ""); // remove all spaces
  if (cardNum.length > 0) {
    cardNum = cardNum.match(new RegExp(".{1,4}", "g")).join(" ");
  }
  return cardNum;
}

export function getCardBrand(number) {
  var cardNumber = number.replace(/\s+/g, ""); // remove all spaces
  const firstFourDigits = cardNumber.substring(0, 4);
  const firstTwoDigits = cardNumber.substring(0, 2);
  const firstDigit = cardNumber.substring(0, 1);

  if (firstDigit === "4") {
    return "Visa";
  } else if (firstTwoDigits.match(/^(5[1-5])/)) {
    return "MasterCard";
  } else if (firstTwoDigits === "34" || firstTwoDigits === "37") {
    return "American Express";
  } else if (firstFourDigits === "6011" || firstTwoDigits.match(/^(64|65)/)) {
    return "Discover";
  } else if (firstFourDigits.match(/^(352[89]|35[3-8])/)) {
    return "JCB";
  } else if (firstTwoDigits.match(/^(62)/)) {
    return "UnionPay";
  } else {
    // Add more cases as needed...
    return "Unknown";
  }
}
