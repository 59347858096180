import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import moment from "moment-timezone";
//components
import DropdownMenuPopover from "../../../components/dropdown/DropdownPopover";
//images
import logo from "../../../asset/images/example/product.png";
import emptyProduct from "../../../asset/images/product/inventory/emptyProduct.png";
//functions
import { formatNumber } from "../../../functions/format/numberFormat";
import PageButtonNavigate from "../../../components/button/PageButtonNavigate";
import { approveOrder } from "../../../services/api/sales/sales";

const ReportProductFlowTable = (props) => {
  const { tableHeader = [], tableData = [] } = props;
  const { onView } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const pagesCount = Math.ceil(tableData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const tableDataSliced = tableData.slice(startIndex, endIndex);

  const { onSelect } = props;

  const select = async (orderId, values) => {};

  return tableData.length > 0 ? (
    <View style={{ width: "100%", height: "100%" }}>
      <TableContainer sx={styles.tableContainer}>
        <Table sx={styles.table}>
          <TableHead sx={{ position: "sticky", top: 0, zIndex: 9999 }}>
            <TableRow style={{ width: "100%", backgroundColor: "#FFF" }}>
              <TableCell sx={{ ...styles.headerCell }}>วันที่/เวลา</TableCell>
              <TableCell sx={{ ...styles.headerCell }}>เลขที่อ้างอิง</TableCell>
              <TableCell sx={{ ...styles.headerCell }}>รายละเอียด</TableCell>
              <TableCell sx={{ ...styles.headerCell }}>จำนวน</TableCell>
              <TableCell sx={{ ...styles.headerCell }}>สถานะสินค้า</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableDataSliced.map((row) => {
              return (
                <TableRow key={row.id} sx={styles.tableRow}>
                  <TableCell sx={styles.tableCell}>
                    <Text>
                      {row.date
                        ? moment(row.date).format("YYYY/MM/DD HH:mm")
                        : ""}
                    </Text>
                  </TableCell>
                  <TableCell sx={styles.tableCell}>
                    <TouchableOpacity
                      onPress={() => {
                        onView && onView(row);
                      }}
                    >
                      <Text>
                        {row.transactionCode ? row.transactionCode : ""}
                      </Text>
                    </TouchableOpacity>
                  </TableCell>
                  <TableCell sx={styles.tableCell}>
                    <Text>{row.description ? row.description : ""}</Text>
                  </TableCell>
                  <TableCell sx={styles.tableCell}>
                    <Text>
                      {row.quantity
                        ? Number(row.quantity).toLocaleString()
                        : ""}
                    </Text>
                  </TableCell>
                  <TableCell sx={styles.tableCell}>
                    <StatusTag
                      text={
                        row.type
                          ? row.type === "ADD"
                            ? "นำเข้า"
                            : "ขายออก"
                          : ""
                      }
                      customColor={
                        row.type
                          ? row.type === "ADD"
                            ? "#B4F1B3"
                            : "#FFF8BB"
                          : ""
                      }
                      customColorText={
                        row.type
                          ? row.type === "ADD"
                            ? "#1C9B1A"
                            : "#B29602"
                          : ""
                      }
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <PageButtonNavigate
        currentPage={currentPage}
        pagesCount={pagesCount}
        startIndex={startIndex}
        endIndex={endIndex}
        dataCount={tableData.length}
        onSelectPage={(page) => {
          setCurrentPage(page);
        }}
      />
    </View>
  ) : (
    <EmptyList />
  );
};

const StatusTag = (props) => {
  const { text, customColor, customColorText } = props;
  return (
    <View
      style={{
        width: 100,
        height: 20,
        backgroundColor: customColor,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 8,
      }}
    >
      <Text
        style={{
          fontSize: 12,
          fontWeight: "bold",
          color: customColorText,
        }}
      >
        {text ? text : ""}
      </Text>
    </View>
  );
};

const EmptyList = () => {
  return (
    <View
      style={{
        flex: 1,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={emptyProduct} style={{ width: 120 }} alt={"emptyProduct"} />
      <View style={{ height: 30 }} />
      <Text
        style={{
          fontSize: "clamp(16px,2vh,20px)",
          fontWeight: "bold",
          color: "rgba(15, 16, 17, 1)",
        }}
      >
        ยังไม่มีรายการ
      </Text>
    </View>
  );
};

const styles = {
  tableContainer: {
    overflowY: "scroll",
    scrollbarWidth: "thin",
    scrollbarColor: "#c7c7c7 #f5f5f5",
    flex: 1,
  },
  table: {
    border: "none",
    minWidth: 650,
  },
  tableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: "rgba(255, 255, 255, 1)",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "rgba(255, 241, 241, 1)",
    },
  },
  tableCell: {
    borderBottom: "none",
    whiteSpace: "nowrap",
    wordBreak: "break-word",
  },
  headerCell: {
    color: "rgba(165, 88, 90, 1)",
    whiteSpace: "nowrap",
  },
};

export default ReportProductFlowTable;
