import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import { Grid } from "@mui/material";
import { Divider } from "rsuite";
import { useNavigate } from "react-router-dom";
//component
import Button from "../../components/button/Button";
import Dropdown from "../../components/dropdown/Dropdown";
import {
  downloadCurrentTransportCode,
  downloadTransportMethodList,
} from "../../services/api/sales/method";
import { post } from "../../functions/fetch/fetch";
import apiPath from "../../services/configs/apiPath";
import { useDialog } from "../../components/modal/Popup";

const SettingCourier = () => {
  const navigate = useNavigate();
  const dialog = useDialog();

  const [currentTransport, setCurrentTransport] = useState();
  const [transportList, setTransportList] = useState([]);

  useEffect(() => {
    downloadTransportMethod();
  }, []);

  const downloadSetupTransport = async () => {
    let res = await downloadCurrentTransportCode();
    if (!res) {
      return;
    }
    setCurrentTransport(res.code);
  };

  const downloadTransportMethod = async () => {
    let res = await downloadTransportMethodList();
    if (res) {
      let init = [];
      res.forEach((item) => {
        init.push({ value: item.code, label: item.name });
      });
      setTransportList(init);
    }
    downloadSetupTransport();
  };

  const update = async () => {
    let url = apiPath.transport.updateCurrentTransportCode;
    let { status, response } = await post(
      url,
      {
        code: currentTransport,
      },
      true
    );

    console.log(currentTransport);
    if (!status) {
      dialog.alert({ message: "ไม่สามารถบันทึกได้" });
      return;
    }
    dialog.alert({ message: "บันทึกสำเร็จ" });
  };

  return (
    <React.Fragment>
      <View style={Styles.container}>
        <Grid container style={Styles.topContainer} spacing={2}>
          <Grid item xs={12} md={8} lg={9} xl={10}>
            <View style={{ flex: 1 }}>
              <Text style={Styles.textTopContainer1}>ตั้งค่าการจัดส่ง</Text>
              <Text style={Styles.textTopContainer2}>
                ตั้งค่า / ตั้งค่าการจัดส่ง
              </Text>
            </View>
          </Grid>
        </Grid>
        <View style={{ height: 30 }} />

        <View style={Styles.contentContainer}>
          <Text style={{ fontSize: 18, fontWeight: "bold" }}>
            ตั้งค่าการจัดส่ง
          </Text>
          <Divider />

          <Grid container spacing={2}>
            <Grid item xs={12} md={4} style={{ zIndex: 4 }}>
              <Text style={Styles.titleText}>
                วิธีการส่ง{" "}
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Dropdown
                  placeholder={"เลือกวิธีการส่ง"}
                  value={currentTransport}
                  valueType={"string"}
                  list={transportList}
                  onChange={(values) => {
                    const { value, label } = values;
                    setCurrentTransport(value);
                  }}
                />
              </View>
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <View
                style={{
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  buttonText={"บันทึก"}
                  color={"primary"}
                  style={{ width: "100%", marginTop: 24 }}
                  isBorder={false}
                  onClick={() => {
                    update();
                  }}
                />
              </View>
            </Grid>
          </Grid>
        </View>
      </View>
    </React.Fragment>
  );
};

const Styles = {
  container: {
    flex: 1,
    height: "100vh",
    padding: "1.5%",
  },
  topContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  contentContainer: {
    width: "100%",
    minHeight: "100vh",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  textTopContainer1: {
    fontSize: "clamp(18px, 3vw, 22px)",
    fontWeight: "bold",
    color: "#AA4942",
  },
  textTopContainer2: {
    fontSize: "clamp(12px, 3vw, 16px)",
    color: "#696D79",
  },
  topButtonText: {
    fontSize: "clamp(14px, 3vw, 18px)",
  },
  titleSearch: {
    fontSize: 20,
    color: "#0F1011",
  },
  subTitleSearch: {
    fontSize: 16,
    color: "#0F1011",
    marginTop: 8,
  },
};
export default SettingCourier;
