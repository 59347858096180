import React, { useEffect, useState } from "react";
import { Text, View } from "react-native-web";
import { Grid } from "@mui/material";
import { Divider } from "rsuite";
//component
import Button from "../../components/button/Button";
import SearchBox from "../../components/textField/SearchBox";
import DatePicker from "../../components/calendar/DatePicker";
import { useDialog } from "../../components/modal/Popup";
import AdminListTable from "./component/AdminListTable";
import ManageAdmin from "./ManageAdmin";
//images
//function
import { downloadAllAdmin } from "../../services/api/member/member";
import { changeAdminActive } from "../../services/api/member/member";

const AdminList = (props) => {
  //property
  const dialog = useDialog();
  //state
  const [adminList, setAdminList] = useState([]);
  const [editValue, setEditValue] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    downloadAllData();
  }, []);

  const downloadAllData = async () => {
    dialog.loading();
    let admins = await downloadAllAdmin();
    let init = [];
    admins.forEach((item) => {
      init.push(item);
    });
    setAdminList(init);
    dialog.cancle();
  };

  const changeActive = async (value) => {
    let confirm = await dialog.confirm({
      title: "ยืนยันการเปลี่ยนสถานะ",
      message: `${
        value.active === "Y" ? "เปิดสิทธิการใข้งาน" : "ระงับสิทธิการใช้งาน"
      } ${value.name}`,
    });
    if (!confirm) {
      return;
    }
    const response = await changeAdminActive(value.id, value.active);
    downloadAllData();
  };

  return (
    <React.Fragment>
      {isEdit ? (
        <ManageAdmin
          isEdit={isEdit}
          editValue={editValue}
          onCancle={() => {
            downloadAllData();
            setIsEdit(false);
          }}
        />
      ) : (
        <View style={Styles.container}>
          <Grid container style={Styles.topContainer} spacing={2}>
            <Grid item xs={12} md={8} lg={9} xl={10}>
              <View style={{ flex: 1 }}>
                <Text style={Styles.textTopContainer1}>รายการผู้ดูแล</Text>
                <Text style={Styles.textTopContainer2}>สมาชิก / ผู้ดูแล</Text>
              </View>
            </Grid>
          </Grid>
          <View style={{ height: 30 }} />
          <View style={Styles.contentContainer}>
            <View style={{ height: 30 }} />

            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={4}>
                <SearchBox
                  placeholder={"ค้นหารหัสตัวแทน ชื่อ นามสกุล เลขบัตรประชาชน"}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <DatePicker
                  placeholder="วันที่ลงทะเบียน"
                  styles={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <Button
                  buttonText={"ค้นหา"}
                  color={"primary"}
                  style={{ width: "100%" }}
                  styleText={Styles.topButtonText}
                  onClick={() => {}}
                />
              </Grid>
            </Grid>

            <Divider />

            <View style={{ flex: 1 }}>
              <AdminListTable
                tableData={adminList}
                onChangeActive={(value) => {
                  changeActive(value);
                }}
                onEdit={(values) => {
                  setIsEdit(true);
                  setTimeout(() => {
                    setEditValue(values);
                  }, 100);
                }}
              />
            </View>
          </View>
        </View>
      )}
    </React.Fragment>
  );
};

const Styles = {
  container: {
    flex: 1,
    height: "100vh",
    padding: "1.5%",
  },
  topContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  contentContainer: {
    width: "100%",
    flex: 1,
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  textTopContainer1: {
    fontSize: "clamp(18px, 3vw, 22px)",
    fontWeight: "bold",
    color: "#AA4942",
  },
  textTopContainer2: {
    fontSize: "clamp(12px, 3vw, 16px)",
    color: "#696D79",
  },
  topButtonText: {
    fontSize: "clamp(14px, 3vw, 18px)",
  },
};

export default AdminList;
