import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { Text, View } from "react-native-web";
import LinearGradient from "react-native-web-linear-gradient";
import DropdownShadow from "../../components/dropdown/DropdownShadow";
import moment from "moment-timezone";
//Images
import trolley from "../../asset/images/home/salesContainer/trolleysale1.png";
import apiPath from "../../services/configs/apiPath";
import { get } from "../../functions/fetch/fetch";

const SalesContainer2 = (props) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const refBox = useRef();
  const homePageData = useSelector((state) => {
    if (!state.homePageData) {
      return null;
    }
    return state.homePageData;
  });

  const [productList, setProductList] = useState([]);
  const [selectProduct, setSelectProduct] = useState({ value: 0, label: "" });

  useEffect(() => {
    downloadProductSummary();
  }, [
    homePageData?.startDate,
    homePageData?.endDate,
    homePageData?.selectType,
  ]);

  useEffect(() => {
    if (refBox.current) {
      setWidth(refBox.current.offsetWidth / 1.2);
      setHeight(refBox.current.offsetHeight);
    }
  }, [refBox.current]);

  const downloadProductSummary = async () => {
    let url = apiPath.sales.getSummaryProductHome;
    let res = await get(
      url,
      {
        startDate: moment(homePageData.startDate).toDate(),
        endDate: moment(homePageData.endDate).toDate(),
      },
      true
    );
    if (!res.status) {
      return;
    }
    if (res.response.data) {
      let init = [];
      res.response.data.forEach((item) => {
        init.push({
          value: item.productName,
          label: item.productName,
          quantity: item.quantity,
          source: item.source,
        });
      });
      console.log("INIT PRODUCT", init);
      setProductList(init);
      if (init.length > 0) {
        setSelectProduct(init[0]);
      }
    }
  };

  return (
    <View
      ref={refBox}
      style={Styles.linearContainer1}
      onLayout={(e) => {
        if (refBox.current) {
          setWidth(refBox.current.offsetWidth / 1.2);
        }
      }}
    >
      <View style={{ width: "100%", zIndex: 30 }}>
        <DropdownShadow
          placeholder={"สินค้า"}
          list={[]}
          disable={true}
          onChange={(values) => {
            const { value, label } = values;
            console.log(value, label);
          }}
        />
      </View>
      <CircleFade width={width} />

      <View style={{ flex: 1 }} />
      <View style={{ width: "100%", zIndex: 99999999 }}>
        <DropdownShadow
          placeholder={"สินค้า"}
          value={selectProduct ? selectProduct.value : ""}
          valueType="string"
          list={
            productList.length > 0
              ? productList.filter((d) => {
                  return (
                    d.source ===
                    (homePageData
                      ? homePageData.selectType
                        ? homePageData.selectType
                        : "retail"
                      : "retail")
                  );
                })
              : []
          }
          onChange={(values) => {
            setSelectProduct(values);
          }}
        />
      </View>
      <View
        style={{
          width: "100%",
          backgroundColor: "rgba(168, 120, 196, 1)",
          borderRadius: 8,
          justifyContent: "center",
          alignItems: "center",
          padding: 8,
          marginTop: 20,
        }}
      >
        <Text
          style={{
            color: "#FFFFFF",
            fontSize: "clamp(20px,3vh,24px)",
            fontWeight: "bold",
          }}
        >
          {selectProduct.quantity
            ? Number(selectProduct.quantity).toLocaleString()
            : 0}
        </Text>
      </View>
      <Text
        style={{
          fontWeight: "bold",
          color: "#FFFFFF",
          alignSelf: "flex-start",
          marginTop: 8,
        }}
      >
        ชิ้น
      </Text>
    </View>
  );
};

const CircleFade = (props) => {
  const { width } = props;
  return (
    <View
      style={{
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 80,
      }}
    >
      <LinearGradient
        style={{
          width: width,
          height: width,
          borderRadius: "50%",
          position: "absolute",
          left: (width / 3.5) * -1,
        }}
        colors={[
          "rgba(183, 168, 214, 1)",
          "rgba(166, 145, 204, 0)",
          "rgba(166, 145, 204, 0)",
        ]}
        useAngle="true"
        angle={270}
      />
      <LinearGradient
        style={{
          width: width,
          height: width,
          borderRadius: "50%",
          position: "absolute",
          right: (width / 2.6) * -1,
          marginBottom: 50,
        }}
        colors={[
          "rgba(183, 168, 214, 1)",
          "rgba(166, 145, 204, 0)",
          "rgba(166, 145, 204, 0)",
        ]}
        useAngle="true"
        angle={110}
      />
      <img
        src={trolley}
        alt={"profile"}
        style={{ width: width / 1.4, maxWidth: 200, zIndex: 20 }}
      />
    </View>
  );
};

const Styles = {
  container: {
    margin: 0,
  },
  linearContainer1: {
    width: "100%",
    height: "100%",
    boxShadow: "16px 16px 24px 0px rgba(0, 0, 0, 0.04)",
    background: "linear-gradient(173.69deg, #70A1FF -1.06%, #C070FF 100.91%)",
    borderRadius: 24,
    alignItems: "center",
    padding: 15,
    paddingBottom: 30,
  },
  circle: {
    width: "clamp(90px,3vh,120px)",
    height: "clamp(90px,3vh,120px)",
    borderRadius: "50%",
    position: "absolute",
    opacity: 0.8,
  },
};
export default SalesContainer2;
