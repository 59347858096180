import React from "react";
import { View, TouchableOpacity ,Text} from "react-native-web";
import { useWindowsSize } from "../../functions/screen/screenFunction";
import logo from "../../asset/images/logo/onelife-984x180.png";
import { FiMenu } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";

const HeaderMobile = (props) => {
  const size = useWindowsSize();
  const { showMenu } = useSelector((state) => {
    return state;
  });
  const dispatch = useDispatch();
  const packageJson = require("../../../package.json");
  return (
    <React.Fragment>
      {size.width < 900 ? (
        <React.Fragment>
          <View style={Styles.container}>
            <TouchableOpacity
              style={{ position: "absolute", top: 15, left: 15 }}
              onPress={() => {
                dispatch({ type: "SHOWMENU", payload: !showMenu });
              }}
            >
              <FiMenu
                style={{
                  fontSize: 30,
                  color: "rgba(170, 73, 66, 1)",
                }}
              />
            </TouchableOpacity>
            <img
              src={logo}
              style={{ width: 140, left: "1%", alignSelf: "center" }}
              alt={"logo"}
            />
            {/* <Text style={{fontSize:10,position:"absolute",right:10,bottom:10,color:"#333333"}}>{packageJson && packageJson.version}</Text> */}
          </View>
          <View style={{ height: 60 }} />
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

const Styles = {
  container: {
    width: "96vw",
    height: 60,
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 1)",
    borderRadius: 10,
    margin: "2%",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    zIndex: 9998,
  },
};
export default HeaderMobile;
