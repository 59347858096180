import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
} from "react-native-web";
import Button from "../button/Button";
//icons
import { IoMdClose } from "react-icons/io";
//functions
import { checkFileType } from "../../functions/validate/validate";

const UploadBox = (props) => {
  const { style, accept } = props;
  const { onChangeImage } = props;
  const inputRef = React.useRef();
  //state
  const [file, setFile] = useState([]);
  const [num, setNum] = useState(0);

  useEffect(() => {
    onChangeImage && onChangeImage(file);
  }, [file]);

  const selectFile = () => {
    if (inputRef) {
      inputRef.current.click();
    }
  };

  const deleteFile = (index) => {
    if (index >= 0) {
      let init = file;
      init.splice(index, 1);
      setFile(init);
      setNum(num + 1);
    }
  };

  return (
    <View style={{ ...Styles.container, ...style }}>
      <ScrollView horizontal showsHorizontalScrollIndicator={false}>
        <View style={Styles.boxTag}>
          {file.map((file, index) => (
            <TagNameUpload
              key={"TagNameUpload_" + index.toLocaleString()}
              index={index}
              file={file}
              onDelete={(index) => {
                deleteFile(index);
              }}
            />
          ))}
        </View>
      </ScrollView>
      <View style={{ width: 10 }} />
      <Button
        buttonText={"อัปโหลด"}
        color={"primary"}
        style={{ width: 120, height: 34, padding: 0 }}
        styleText={{ fontSize: 14 }}
        onClick={selectFile}
      />
      <input
        type="file"
        accept={accept ? accept : null}
        style={{ display: "none" }}
        ref={inputRef}
        onClick={(event) => {
          event.target.value = null;
        }}
        onChange={(event, data) => {
          if (event.target.files[0] !== undefined) {
            let image = event.target.files[0];
            let init = [...file];
            init.push(image);
            setFile(init);
            /*checkFileType(
              image,
              (type) => {
                if (type === "image/png" || type === "image/jpeg") {
                  let init = [...file];
                  init.push(image);
                  setFile(init);
                } else {
                  alert("กรุณาอัพโหลดเฉพาะไฟล์รูปภาพ PNG หรือ JPEG เท่านั้น");
                }
              },
              (value) => {}
            );*/
          }
        }}
      />
    </View>
  );
};

const TagNameUpload = (props) => {
  const { file, index } = props;
  const { onDelete } = props;

  const deleteFile = () => {
    onDelete && onDelete(index);
  };

  return (
    <View style={Styles.tagContainer}>
      <Text style={Styles.tagText}>{file ? file.name : ""}</Text>
      <TouchableOpacity onPress={deleteFile}>
        <IoMdClose style={Styles.tagCloseIcon} />
      </TouchableOpacity>
    </View>
  );
};

const Styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    width: "100%",
    height: 50,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderColor: "#D5D7DD",
    borderRadius: 8,
    padding: 8,
  },
  boxTag: {
    flex: 1,
    flexDirection: "row",
  },
  tagContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 8,
    paddingRight: 8,
    backgroundColor: "#B9BDC7",
    borderRadius: 2,
    marginRight: 8,
  },
  tagText: {
    flex: 1,
    fontSize: 14,
    color: "#FFFFFF",
  },
  tagCloseIcon: {
    fontSize: 14,
    color: "#FFFFFF",
    marginLeft: 8,
  },
});

export default UploadBox;
