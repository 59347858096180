import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import moment from "moment-timezone";
//component
import ContentListTable from "./ContentListTable";
import ContentListGrid from "./ContentListGrid";
import { Divider } from "rsuite";
import { Grid } from "@mui/material";
//icons
import { BsGrid, BsListUl } from "react-icons/bs";
import { IoIosArrowBack } from "react-icons/io";
import EmptyList from "../../../components/pages/EmptyList";

const ContentList = (props) => {
  const { data, folderData, contentLoad } = props;
  const { onBack } = props;
  const [viewType, setViewType] = useState(0);

  useEffect(() => {
    console.log(data);
  }, [data]);
  return (
    <React.Fragment>
      {contentLoad ? (
        <View style={Styles.container}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={10}>
              <View style={Styles.topLeftContainer}>
                <TouchableOpacity
                  onPress={() => {
                    onBack && onBack();
                  }}
                >
                  <IoIosArrowBack style={Styles.iconSelect} />
                </TouchableOpacity>
                <View style={{ width: 8 }} />
                <View>
                  <Text style={Styles.textHeaderLeft}>
                    {folderData ? folderData.folder_name : ""}
                  </Text>
                  <Text style={Styles.subTextHeaderLeft}>
                    {folderData
                      ? `แก้ไขล่าสุด ${moment(folderData.update_date).format(
                          "DD/MM/YYYY HH:mm"
                        )} โดย ${folderData.name_update_by}`
                      : ""}
                  </Text>
                </View>
              </View>
            </Grid>
            <Grid item xs={12} md={2}>
              <View style={Styles.topRightContainer}>
                <Text>มุมมอง:</Text>
                <View style={{ width: 10 }} />
                <TouchableOpacity
                  onPress={() => {
                    setViewType(0);
                  }}
                >
                  <BsListUl
                    style={viewType === 0 ? Styles.iconSelect : Styles.icon}
                  />
                </TouchableOpacity>
                <View style={Styles.cutLine} />
                <TouchableOpacity
                  onPress={() => {
                    setViewType(1);
                  }}
                >
                  <BsGrid
                    style={viewType === 1 ? Styles.iconSelect : Styles.icon}
                  />
                </TouchableOpacity>
              </View>
            </Grid>
          </Grid>
          <Divider />
          {data.length === 0 && contentLoad ? <EmptyList /> : null}
          {viewType === 0 ? (
            <ContentListTable data={data} />
          ) : (
            <ContentListGrid data={data} />
          )}
        </View>
      ) : null}
    </React.Fragment>
  );
};

const Styles = {
  container: {
    flex: 1,
  },
  headerContainer: {
    flexDirection: "row",
  },
  topLeftContainer: {
    flex: 1,
    flexDirection: "row",
  },
  textHeaderLeft: {
    fontSize: 16,
    fontWeight: "bold",
    color: "rgba(15, 16, 17, 1)",
  },
  subTextHeaderLeft: {
    fontSize: 14,
    color: "rgba(138, 145, 161, 1)",
    marginTop: 5,
  },
  topRightContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  icon: {
    fontSize: 22,
    color: "rgba(138, 145, 161, 1)",
  },
  iconSelect: {
    fontSize: 22,
    color: "rgba(170, 73, 66, 1)",
  },
  cutLine: {
    width: 1,
    height: 30,
    backgroundColor: "rgba(138, 145, 161, 1)",
    marginLeft: 10,
    marginRight: 10,
  },
};

export default ContentList;
