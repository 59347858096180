import React, { useState, useEffect, useMemo, useRef } from "react";
import { Text, View, TouchableOpacity } from "react-native-web";
import { Grid } from "@mui/material";
import { Divider } from "rsuite";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
//component
import Button from "../../components/button/Button";
import TextField from "../../components/textField/TextField";
import Dropdown from "../../components/dropdown/Dropdown";
import DatePicker from "../../components/calendar/DatePicker";
import BoxImage from "../../components/uploadbox/BoxUploadImage";
import TextArea from "../../components/textField/TextArea";
import TimePicker from "../../components/time/TimePicker";
import ProductShoppingTable from "../shopping/component/ProductShoppingTable";
//images
import emptyBox from "../../asset/images/shopping/emptyBox.png";
import { PlusCircleIcon } from "../../asset/images/icons/iconsLib";
//functions
import {
  downloadSalesStoreList,
  downloadBuyerStoreList,
} from "../../services/api/sales/sales";
import { getUserInfo } from "../../services/api/member/member";
import { downloadAllProductsListOrder } from "../../services/api/products/products";
import {
  downloadTransportMethodList,
  downloadPaymentMethodList,
} from "../../services/api/sales/method";
import moment from "moment-timezone";
import { useDialog } from "../../components/modal/Popup";
import apiPath from "../../services/configs/apiPath";
import { upload } from "../../functions/fetch/fetch";
import { post } from "../../functions/fetch/fetch";
import { dataURItoBlob } from "../../functions/format/convertFile";

const AddOrder = (props) => {
  //property
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dialog = useDialog();
  //props
  const { isFirstOrder } = props;
  const { onClose } = props;
  const { setupData } = useSelector((state) => {
    return state;
  });
  //state
  const [userInfo, setUserInfo] = useState({});
  let [orderData, setOrderData] = useState({
    date: moment(),
    time: moment().format("HH:mm"),
    saleId: null,
    buyerId: null,
    transportId: null,
    transportCode: null,
    paymentMethodCode: null,
    productList: [],
    countryCodeStore: null,
  });
  const [salesStoreList, setSalesStoreList] = useState([]);
  const [buyerStoreList, setBuyerStoreList] = useState([]);
  const [transportList, setTransportList] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [saleStoreSelect, setSaleStoreSelect] = useState({});
  const [buyerStoreSelect, setBuyerStoreSelect] = useState({});

  const [productList, setProductList] = useState([]);
  const [productSelectList, setProductSelectList] = useState([]);
  const [productCalculate, setProductCalculate] = useState([]);
  const [displayDataSummary, setDisplayDataSummary] = useState({
    quantity: 0,
    total: 0,
    totalDiscount: 0,
    totalDelivery: 0,
    totalBasePrice: 0,
  });

  useEffect(() => {
    const downloadAllData = async () => {
      dialog.loading();
      //get salesList
      let salesList = await downloadSalesStoreList({ wording: "" });
      let initSales = [];
      salesList.forEach((item) => {
        initSales.push({
          ...item,
          value: item.id,
          label: `${item.name}`,
        });
      });
      setSalesStoreList(initSales);
      //get buyerList
      let buyerList = await downloadBuyerStoreList();
      let initBuyer = [];
      buyerList.forEach((item) => {
        initBuyer.push({
          ...item,
          value: item.id,
          label: `${item.name}`,
        });
      });
      setBuyerStoreList(initBuyer);

      let uInfo = await getUserInfo();
      await setUserInfo(uInfo);

      if (
        uInfo.userRole === "A" &&
        initSales.length > 0 &&
        initBuyer.length > 0
      ) {
        await setOrderData({
          ...orderData,
          saleId: initSales[0].value,
          buyerId: initBuyer[0].value,
          countryCodeStore: buyerList ? buyerList[0].countryCodeStore : null,
        });
        setSaleStoreSelect({ ...initSales[0] });
        setBuyerStoreSelect({ ...initBuyer[0] });
      } else if (uInfo.userRole === "S" || uInfo.userRole === "SS") {
        await downloadProductListStore();
      }

      await downloadPaymentMethod();
      await downloadTransportMethod();
      dialog.cancle();
    };
    downloadAllData();
  }, []);

  useEffect(() => {
    if (productSelectList.length > 0) {
      calculateOrder(productSelectList);
    } else {
      setProductCalculate([]);
      setDisplayDataSummary({
        quantity: 0,
        total: 0,
        totalDiscount: 0,
        totalBasePrice: 0,
      });
    }
  }, [productSelectList,orderData]);

  const getSaleList = async (wording) => {
    let salesList = await downloadSalesStoreList({ wording: wording });
    let initSales = [];
    salesList.forEach((item) => {
      initSales.push({
        ...item,
        value: item.id,
        label: `${item.name}`,
      });
    });
    setSalesStoreList(initSales);
  };

  const getBuyerList = async (wording) => {
    let buyerList = await downloadBuyerStoreList({ wording: wording });
    let initBuyer = [];
    buyerList.forEach((item) => {
      initBuyer.push({
        ...item,
        value: item.id,
        label: `${item.name}`,
      });
    });
    setBuyerStoreList(initBuyer);
  };

  const calculateOrder = async (values) => {
    let url = apiPath.order.calculateOrder;
    let { status, response } = await post(
      url,
      { orders: JSON.stringify(values),...orderData },
      true
    );
    if (!status) {
      dialog.cancle();
      return;
    }
    if (response.data) {
      const cal = response.data;
      setProductCalculate(cal.data);
      setDisplayDataSummary({
        ...displayDataSummary,
        quantity: cal.totalQuantity,
        totalDiscount: cal.totalDiscount,
        total: cal.totalPrice,
        totalBasePrice: cal.totalBasePrice,
      });
    }
  };

  useEffect(() => {
    if (orderData.saleId && orderData.buyerId && saleStoreSelect.agentCode) {
      downloadProductListStore(
        saleStoreSelect.agentCode,
        buyerStoreSelect.agentCode,
        orderData.countryCodeStore
      );
    }
  }, [orderData.saleId, orderData.countryCodeStore, orderData.buyerId]);

  const downloadProductListStore = async (
    agentCodeSale,
    agentCodeBuyer,
    countryCodeStore
  ) => {
    if (!agentCodeSale || !agentCodeBuyer) {
      return;
    }
    dialog.loading();
    let productsList = await downloadAllProductsListOrder(
      agentCodeSale,
      agentCodeBuyer,
      countryCodeStore
    );
    let initProducts = [];
    productsList.forEach((item) => {
      initProducts.push({
        ...item,
        value: item.id,
        label: `${item.name} (${item.code}) (${item.quantity})`,
      });
    });
    setProductList(initProducts);
    dialog.cancle();
  };

  const downloadPaymentMethod = async () => {
    let res = await downloadPaymentMethodList();
    if (res) {
      let init = [];
      res.forEach((item) => {
        console.log(item.code);
        if (
          !item.code.includes("customerpayment") &&
          !item.code.includes("cashdelivery")
        ) {
          init.push({ value: item.code, label: item.name });
        }
      });
      setPaymentMethod(init);
    }
  };

  const downloadTransportMethod = async () => {
    let res = await downloadTransportMethodList();
    if (res) {
      let init = [];
      res.forEach((item) => {
        init.push({ value: item.code, label: item.name });
      });
      setTransportList(init);
    }
  };

  const clearData = () => {
    setOrderData({
      date: moment(),
      time: moment().format("HH:mm"),
      saleId: userInfo.userRole === "A" ? orderData.saleId : null,
      buyerId: userInfo.userRole === "A" ? orderData.buyerId : null,
      transportId: null,
    });
    setProductSelectList([]);
  };

  const validation = async () => {
    let errors = [];
    if (!orderData.date) {
      errors.push("วันที่");
    }
    if (!orderData.time) {
      errors.push("เวลา");
    }
    if (!orderData.saleId) {
      errors.push("คลังผู้ขาย");
    }
    if (!orderData.buyerId) {
      errors.push("ผู้ซื้อ");
    }
    if (productSelectList.length === 0) {
      errors.push("เลือกสินค้า");
    }

    let findAgentCodeSale = saleStoreSelect;

    let findAgentCodeBuyer = buyerStoreSelect;

    let isAdmin = isAdminAddStock();
    if (
      orderData.countryCodeStore === "THA" &&
      !orderData.paymentMethodCode &&
      !isAdmin
    ) {
      errors.push("วิธีการชำระเงิน");
    }
    if (
      orderData.countryCodeStore !== "THA" &&
      !orderData.paymentImage &&
      !isAdmin
    ) {
      errors.push("หลักฐานการชำระเงิน");
    }
    if (productCalculate.length <= 0) {
      errors.push("กรุณาเลือกสินค้า");
    }

    console.log("Error validation", errors);
    let message = "";
    if (errors.length > 0) {
      message = "กรุณากรอกข้อมูลให้ครบถ้วน";
    }

    if (
      orderData.saleId === orderData.buyerId &&
      findAgentCodeSale.agentCode !== "ONELIFE" &&
      findAgentCodeBuyer.agentCode !== "ONELIFE"
    ) {
      message = "ไม่สามารถเลือกผู้ขาย กับผู้ซื้อคนเดียวกันได้";
    }

    if (
      !orderData.countryCodeStore &&
      findAgentCodeSale.agentCode === "ONELIFE" &&
      findAgentCodeBuyer.agentCode === "ONELIFE"
    ) {
      message = "กรุณาระบุ ประเทศของคลังสินค้า";
    }

    if (
      !orderData.countryCodeStore &&
      findAgentCodeSale.agentCode === "ONELIFE" &&
      findAgentCodeBuyer.agentCode !== "ONELIFE"
    ) {
      message = "ไม่พบประเทศของคลังสินค้า กรุณาติดต่อ Admin";
    }

    if (message) {
      await dialog.alert({
        title: "แจ้งเตือน",
        message: message,
      });
      return false;
    }

    return true;
  };

  const submit = async () => {
    if (!(await validation())) {
      return;
    }
    dialog.loading();

    let newTime = moment().format("HH:mm");

    let params = {
      date: moment().toDate(),
      time: newTime,
      hour: Number(newTime.split(":")[0]),
      minute: Number(newTime.split(":")[1]),
      saleId: Number(orderData.saleId),
      saleCode: saleStoreSelect.agentCode,
      buyerId: Number(orderData.buyerId),
      buyerCode: buyerStoreSelect.agentCode,
      countryCodeStore: orderData.countryCodeStore,
      productList: productCalculate,
      deliveryId: 0,
      transportId: orderData.transportId,
      trasportCode: orderData.transportCode,
      transportStatus: "",
      transportCost: displayDataSummary.totalDelivery,
      totalQuantity: displayDataSummary.quantity,
      totalBasePrice: displayDataSummary.totalBasePrice,
      totalPrice: displayDataSummary.total,
      totalDiscount: displayDataSummary.totalDiscount,
      paymentMethodCode: orderData.paymentMethodCode,
      description: orderData.description ? orderData.description : "",
      orders: JSON.stringify(productSelectList),
      customerName: buyerStoreSelect.name,
      customerEmail: buyerStoreSelect.email,
    };

    //Check available <== กำลังคิดว่าส่วนนี้เกินความจำเป็น เพราะ Service หลักมีเช็คอยู่แล้ว
    // let urlCheck = apiPath.order.checkAvailableProductOrder;
    // let res = await post(urlCheck, params, true);
    // if (!res.status) {
    //   console.log(res.response.data);
    //   await dialog.alert({
    //     title: "แจ้งเตือน",
    //     message: res.response.data.error,
    //   });
    //   dialog.cancle();
    //   return;
    // }

    if (orderData.paymentImage) {
      //Upload image process
      const uploadImages = await upload(
        [dataURItoBlob(orderData.paymentImage)],
        `${process.env["REACT_APP_ENV"]}/Images/Order/Payment/${
          salesStoreList.find((d) => {
            return d.id === orderData.saleId;
          }).agentCode
        }`
      );
      const { data } = uploadImages.response;
      if (!data) {
        await dialog.alert({
          title: "แจ้งเตือน",
          message: uploadImages.response.data.error,
        });
        return;
      }
      params.paymentImage = data.data ? data.data[0] : "";
      //end upload image process
    }
    //Only admin
    if (
      (userInfo.userRole === "S" || userInfo.userRole === "SS") &&
      isAdminAddStock()
    ) {
      handleAdminSuccess(params);
      return;
    }

    if (orderData.countryCodeStore === "THA") {
      //ชำระเงิน
      let paymentRes = await dialog.payment({
        paymentMethodCode: orderData.paymentMethodCode,
        data: {
          ...params,
          transactionType: "ORDER",
          paymentMethodCode: orderData.paymentMethodCode,
        },
      });
      if (!paymentRes.close) {
        return;
      }
      if (
        paymentRes.status !== null &&
        paymentRes.status !== undefined &&
        paymentRes.status === false
      ) {
        dialog.alert({ message: "การชำระเงินไม่สำเร็จ" });
        return;
      }
    } else {
      //การสั่งซื้อ Order ต่างประเทศ
      let url = apiPath.order.addOrderOutSideTHA;
      console.log("Params", params);
      let { status, response } = await post(url, params, true);
      if (!status) {
        await dialog.alert({
          title: "แจ้งเตือน",
          message: response.data.error,
        });
        dialog.cancle();
        return;
      }
    }

    // ส่วนนี้สำหรับ mock test
    // let url = apiPath.order.addOrder;
    // console.log("Params", params);
    // let { status, response } = await post(url, params, true);
    // if (!status) {
    //   console.log(response.data);
    //   await dialog.alert({ title: "แจ้งเตือน", message: response.data.error });
    //   dialog.cancle();
    //   return;
    // }

    handleSuccess();
  };

  const handleSuccess = async () => {
    let message = "เพิ่มคำสั่งซื้อสำเร็จ";
    let alert = await dialog.alert({
      title: "แจ้งเตือน",
      message: message,
    });
    if (!alert) {
      return;
    }
    navigate("/orderMyList");
  };

  const handleAdminSuccess = async (params) => {
    let url = apiPath.order.addOrder;

    let { status, response } = await post(url, params, true);
    if (!status) {
      console.log(response.data);
      await dialog.alert({ title: "แจ้งเตือน", message: response.data.error });
      dialog.cancle();
      return;
    }

    let message = isFirstOrder
      ? "เพิ่มคำสั่งซื้อสำเร็จ กรุณารอยืนยันคำสั่งต่อไป"
      : "เพิ่มคำสั่งซื้อสำเร็จ";
    await dialog.alert({
      title: "แจ้งเตือน",
      message: message,
    });
    dialog.cancle();

    navigate("/orderMyList");
  };

  const isAdminAddStock = () => {
    let findAgentCodeSale = saleStoreSelect;

    let findAgentCodeBuyer = buyerStoreSelect;
    let isAdmin =
      findAgentCodeSale.agentCode === "ONELIFE" &&
      findAgentCodeBuyer.agentCode === "ONELIFE";
    return isAdmin;
  };
  const logout = () => {
    Cookies.remove("token");
    Cookies.remove("rosegoldtoken");
    dispatch({ type: "SetUser", payload: null });
    navigate("/login");
  };

  const close = () => {
    if (!isFirstOrder) {
      navigate("/orderMyList");
      return;
    }
    onClose && onClose();
  };

  return (
    <React.Fragment>
      <View style={Styles.container}>
        <Grid container style={Styles.topContainer} spacing={2}>
          <Grid item xs={12} md={8} lg={9} xl={10}>
            <View style={{ flex: 1 }}>
              <Text style={Styles.textTopContainer1}>เพิ่มคำสั่งซื้อ</Text>
              <Text style={Styles.textTopContainer2}>
                คำสั่งซื้อ / เพิ่มคำสั่งซื้อ
              </Text>
            </View>
          </Grid>
        </Grid>
        <View style={{ height: 30 }} />
        <View style={Styles.contentContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={9} lg={10}>
              <Text
                style={{
                  fontSize: "clamp(16px,2vh,20px)",
                  fontWeight: "bold",
                  color: "rgba(15, 16, 17, 1)",
                }}
              >
                รายละเอียดคำสั่งซื้อ
              </Text>
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <Button
                buttonText={"ล้างข้อมูล"}
                color={"primary"}
                isBorder={true}
                style={{
                  width: "100%",
                  height: 40,
                  justifyContent: "center",
                  alignItems: "center",
                }}
                styleText={{ fontSize: 14 }}
                onClick={() => {
                  clearData();
                }}
              />
            </Grid>
          </Grid>
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} style={{ zIndex: 10 }}>
              <Text style={Styles.titleText}>วันที่การขาย</Text>
              <View style={{ height: 8 }} />
              <DatePicker
                date={orderData.date}
                disable={true}
                styles={{ width: "100%" }}
                placeholder="เลือกวันที่การขาย"
                onChange={(date) => {
                  setOrderData({ ...orderData, date: Number(date) });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ zIndex: 9 }}>
              <Text style={Styles.titleTextField}>เวลา</Text>
              <View style={{ height: 8 }} />
              <TimePicker
                time={orderData.time}
                disable={true}
                styles={{ width: "100%" }}
                placeholder="เลือกเวลา"
                onChange={(values) => {
                  const { hour, minute } = values;
                  console.log(hour, minute);
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ zIndex: 99999 }}>
              <Text style={Styles.titleText}>
                จากคลังผู้ขาย{" "}
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />
              <Dropdown
                value={orderData.saleId}
                valueType={"number"}
                disable={
                  userInfo.userRole === "A" || salesStoreList.length === 0
                }
                placeholder={"เลือกจากคลังผู้ขาย"}
                list={salesStoreList}
                onInputChange={(value) => {
                  getSaleList(value);
                }}
                onChange={(values) => {
                  const { value, label } = values;
                  let findSaleInfo = salesStoreList.find((d) => {
                    return d.id === value;
                  });
                  setSaleStoreSelect({ ...values });
                  setOrderData({
                    ...orderData,
                    saleId: value,
                    countryCodeStore: findSaleInfo
                      ? findSaleInfo.countryCodeStore
                      : "",
                  });
                }}
              />

              {userInfo.userRole === "S" || userInfo.userRole === "SS" ? (
                <React.Fragment>
                  <View style={{ height: 8 }} />
                  <Text style={Styles.textRemark}>
                    สามารถพิมพ์เพื่อค้นหาได้
                  </Text>
                </React.Fragment>
              ) : null}
            </Grid>
            <Grid item xs={12} md={6} style={{ zIndex: 9999 }}>
              <Text style={Styles.titleText}>
                ผู้ซื้อ <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Dropdown
                  value={orderData.buyerId}
                  valueType={"number"}
                  disable={
                    userInfo.userRole === "A" || buyerStoreList.length === 0
                  }
                  placeholder={"เลือกผู้ซื้อ"}
                  list={buyerStoreList}
                  onInputChange={(value) => {
                    getBuyerList(value);
                  }}
                  onChange={(values) => {
                    const { value, label } = values;
                    let findBuyerInfo = buyerStoreList.find((d) => {
                      return d.id === value;
                    });
                    if (!findBuyerInfo) {
                      return;
                    }
                    setBuyerStoreSelect({ ...values });
                    setOrderData({
                      ...orderData,
                      buyerId: value,
                      countryCodeStore: findBuyerInfo.countryCodeStore,
                    });
                  }}
                />
              </View>
              {userInfo.userRole === "S" || userInfo.userRole === "SS" ? (
                <React.Fragment>
                  <View style={{ height: 8 }} />
                  <Text style={Styles.textRemark}>
                    สามารถพิมพ์เพื่อค้นหาได้
                  </Text>
                </React.Fragment>
              ) : null}
            </Grid>

            {orderData.saleId &&
            orderData.buyerId &&
            saleStoreSelect.agentCode === "ONELIFE" &&
            buyerStoreSelect.agentCode === "ONELIFE" ? (
              <Grid item xs={12} md={6} style={{ zIndex: 9999 }}>
                <Text style={Styles.titleText}>
                  ประเทศของคลังสินค้า{" "}
                  <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
                </Text>
                <View style={{ height: 8 }} />
                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Dropdown
                    value={orderData.countryCodeStore}
                    valueType={"string"}
                    placeholder={"เลือกประเทศของคลังสินค้า"}
                    disable={productCalculate.length > 0}
                    list={setupData && setupData.masterData.countryList}
                    onChange={(values) => {
                      const { value, label } = values;
                      setOrderData({
                        ...orderData,
                        countryCodeStore: value,
                      });
                    }}
                  />
                </View>
              </Grid>
            ) : null}

            <Grid item xs={12} md={12} lg={12}>
              <Text
                style={{
                  fontSize: "clamp(16px,2vh,20px)",
                  fontWeight: "bold",
                  color: "rgba(15, 16, 17, 1)",
                }}
              >
                รายละเอียดสินค้า
              </Text>
              <Divider />
              <Grid item xs={12} md={6} style={{ zIndex: 8 }}>
                <View style={{ height: 8 }} />
                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 8,
                  }}
                >
                  <Dropdown
                    placeholder={`เพิ่ม/ค้นหาสินค้า ${
                      !orderData.saleId ? "(กรุณาเลือก จากคลังผู้ขาย)" : ""
                    }`}
                    list={productList}
                    disable={!orderData.saleId ? true : false}
                    onChange={(values) => {
                      const { value, label } = values;
                      let product = productList.find((d) => {
                        return d.id === value;
                      });
                      if (!product) {
                        return;
                      }
                      let init = [...productSelectList];
                      let checkDuplicate =
                        productSelectList.filter((d) => {
                          return d.id === value;
                        }).length > 0;
                      if (!checkDuplicate) {
                        init.push({
                          ...product,
                          quantity: 1,
                        });
                        setProductSelectList(init);
                      }
                    }}
                  />
                  {false && (
                    <TouchableOpacity
                      style={{ marginLeft: 10 }}
                      onPress={() => {
                        dispatch({ type: "SetSubPage", payload: "addProduct" });
                        navigate("/addProduct");
                      }}
                    >
                      <PlusCircleIcon
                        width={24}
                        height={24}
                        color={"rgba(15, 16, 17, 1)"}
                      />
                    </TouchableOpacity>
                  )}
                </View>
              </Grid>
            </Grid>

            <Grid item xs={12} style={{ zIndex: 7 }}>
              {productCalculate.length > 0 ? (
                <ProductShoppingTable
                  tableData={productCalculate}
                  onChangeProduct={(value) => {
                    let init = productSelectList;
                    let findIndex = productSelectList.findIndex(
                      (d) => d.id === value.productId
                    );
                    if (findIndex < 0) {
                      return;
                    }
                    init[
                      productSelectList.findIndex(
                        (d) => d.id === value.productId
                      )
                    ].quantity = value.quantity;
                    setProductSelectList([...init]);
                  }}
                  onDelete={(values) => {
                    let findIndex = productSelectList.findIndex(
                      (d) => d.id === values.productId
                    );
                    let init = productSelectList;
                    init.splice(findIndex, 1);
                    setProductSelectList([...init]);
                  }}
                />
              ) : (
                <EmptyProduct />
              )}
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <View style={{ height: 40 }} />
              <Text
                style={{
                  fontSize: "clamp(16px,2vh,20px)",
                  fontWeight: "bold",
                  color: "rgba(15, 16, 17, 1)",
                }}
              >
                รายละเอียดการขายสินค้า
              </Text>
              <Divider />
            </Grid>

            <SummaryBox displayDataSummary={displayDataSummary} />

            {orderData.countryCodeStore === "THA" &&
            !(
              saleStoreSelect.agentCode === "ONELIFE" &&
              buyerStoreSelect.agentCode === "ONELIFE"
            ) ? (
              <Grid item xs={12}>
                <View style={{ height: 20 }} />
                <Text style={Styles.titleTextField}>
                  วิธีการชำระเงิน {orderData.saleCode} {orderData.buyerCode}
                </Text>
                <View style={{ height: 8 }} />
                <Grid item xs={12} md={4}>
                  <Dropdown
                    placeholder={"เลือกวิธีการชำระเงิน"}
                    list={paymentMethod}
                    onChange={(values) => {
                      const { value, label } = values;
                      setOrderData({
                        ...orderData,
                        paymentMethodCode: value,
                      });
                    }}
                  />
                </Grid>
              </Grid>
            ) : null}

            {orderData.countryCodeStore &&
            orderData.countryCodeStore !== "THA" ? (
              <Grid item xs={12}>
                <View style={{ height: 20 }} />
                <Text style={Styles.titleTextField}>
                  รายละเอียดการชำระเงิน {orderData.countryCodeStore}
                </Text>
                <View style={{ height: 8 }} />
                <Grid item xs={12} md={4}></Grid>
              </Grid>
            ) : null}

            <Grid item xs={12}>
              <View style={{ height: 20 }} />
              <Text style={Styles.titleTextField}>แนบหลักฐานการชำระเงิน</Text>
              <View style={{ height: 16 }} />
              <Grid xs={12} md={4}>
                <BoxImage
                  style={{ maxWidth: 500, maxHeight: 500 }}
                  accept={"image/*"}
                  onSelect={(value) => {
                    setOrderData({
                      ...orderData,
                      paymentImage: value,
                    });
                  }}
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Text style={Styles.titleTextField}>รายละเอียดอื่นๆ</Text>
              <View style={{ height: 8 }} />
              <TextArea
                onChangeText={(value) => {
                  setOrderData({
                    ...orderData,
                    description: value,
                  });
                }}
              />
            </Grid>

            <Grid
              container
              spacing={2}
              style={{ justifyContent: "center", marginTop: 40 }}
            >
              <Grid item xs={12} md={4} lg={3}>
                <View style={{ width: "100%" }}>
                  <Button
                    buttonText={"ยกเลิก"}
                    color={"primary"}
                    style={Styles.acceptButton}
                    isBorder={true}
                    onClick={() => {
                      close();
                    }}
                  />
                </View>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <View style={{ width: "100%" }}>
                  <Button
                    buttonText={"เพิ่มคำสั่งซื้อ"}
                    color={"primary"}
                    style={Styles.acceptButton}
                    onClick={() => {
                      submit();
                    }}
                  />
                </View>
              </Grid>
            </Grid>
          </Grid>
        </View>
      </View>
    </React.Fragment>
  );
};

const SummaryBox = (props) => {
  const { displayDataSummary } = props;

  useEffect(() => {}, [displayDataSummary]);

  const StylesSummaryBox = {
    container: {
      width: "100%",
      backgroundColor: "rgba(248, 248, 248, 1)",
      borderRadius: 16,
      padding: 20,
      justifyContent: "center",
      alignItems: "center",
    },
    row: {
      width: "100%",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    titleText: {
      color: "rgba(105, 109, 121, 1)",
      flex: 1,
    },
    valueText: {
      color: "rgba(15, 16, 17, 1)",
      fontSize: 18,
      fontWeight: "bold",
    },
    unitText: {
      marginLeft: 8,
      color: "rgba(105, 109, 121, 1)",
    },
    line: {
      width: "100%",
      height: 1,
      backgroundColor: "rgba(237, 238, 240, 1)",
      marginTop: 20,
      marginBottom: 20,
    },
    sumText: {
      fontSize: 18,
      fontWeight: "bold",
      color: "rgba(15, 16, 17, 1)",
      flex: 1,
    },
    sumValueText: {
      fontSize: 20,
      fontWeight: "bold",
      color: "rgba(170, 73, 66, 1)",
    },
  };

  const SummaryRow = (props) => {
    const { title, value, unit } = props;
    return (
      <View style={StylesSummaryBox.row}>
        <Text style={StylesSummaryBox.titleText}>{title ? title : ""}</Text>
        <Text style={StylesSummaryBox.valueText}>{value ? value : ""}</Text>
        <Text style={StylesSummaryBox.unitText}>{unit ? unit : ""}</Text>
      </View>
    );
  };

  return (
    <Grid item xs={12}>
      <View style={StylesSummaryBox.container}>
        <SummaryRow
          key={"sum_count"}
          title={"จำนวน"}
          value={
            displayDataSummary.quantity ? displayDataSummary.quantity : "0"
          }
          unit={"ชิ้น"}
        />
        <SummaryRow
          key={"sum_total"}
          title={"ยอดรวม"}
          value={
            displayDataSummary.totalBasePrice
              ? Number(displayDataSummary.totalBasePrice).toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 2,
                  }
                )
              : "0.00"
          }
          unit={"บาท"}
        />
        <SummaryRow
          key={"sum_discount"}
          title={"ส่วนลด"}
          value={
            displayDataSummary.totalDiscount
              ? Number(displayDataSummary.totalDiscount).toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 2,
                  }
                )
              : "0.00"
          }
          unit={"บาท"}
        />
        {false ? (
          <SummaryRow
            key={"sum_sending"}
            title={"ค่าจัดส่ง"}
            value={
              displayDataSummary.totalDelivery
                ? Number(displayDataSummary.totalDelivery).toLocaleString(
                    undefined,
                    {
                      minimumFractionDigits: 2,
                    }
                  )
                : "0.00"
            }
            unit={"บาท"}
          />
        ) : null}
        <View style={StylesSummaryBox.line} />
        <View style={StylesSummaryBox.row}>
          <Text style={StylesSummaryBox.sumText}>ยอดรวมทั้งหมด</Text>
          <Text style={StylesSummaryBox.sumValueText}>
            {displayDataSummary.total
              ? Number(displayDataSummary.total).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })
              : "0.00"}
          </Text>
          <Text style={StylesSummaryBox.unitText}>บาท</Text>
        </View>
      </View>
    </Grid>
  );
};

const EmptyProduct = () => {
  return (
    <View
      style={{
        flex: 1,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={emptyBox}
        style={{ width: 120, zIndex: 0 }}
        alt={"emptyProduct"}
      />
      <View style={{ height: 30 }} />
      <Text
        style={{
          fontSize: "clamp(16px,2vh,20px)",
          fontWeight: "bold",
          color: "rgba(15, 16, 17, 1)",
        }}
      >
        ยังไม่มีสินค้า
      </Text>
      <Text
        style={{
          fontSize: "clamp(12px,2vh,16px)",
          color: "rgba(105, 109, 121, 1)",
          marginTop: 8,
        }}
      >
        กดที่ช่อง ‘เพิ่ม/ค้นหาสินค้า’ เพื่อเพิ่มสินค้าในคำสั่งซื้อ
      </Text>
    </View>
  );
};

const Styles = {
  container: {
    flex: 1,
    height: "100vh",
    padding: "1.5%",
  },
  topContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  contentContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  textTopContainer1: {
    fontSize: "clamp(18px, 3vw, 22px)",
    fontWeight: "bold",
    color: "#AA4942",
  },
  textTopContainer2: {
    fontSize: "clamp(12px, 3vw, 16px)",
    color: "#696D79",
  },
  topButtonText: {
    fontSize: "clamp(14px, 3vw, 18px)",
  },
  textRemark: {
    fontSize: 14,
    color: "rgba(105, 109, 121, 1)",
  },
};

export default AddOrder;
