import React, { useState } from 'react';
import apiPath from '../../services/configs/apiPath';
import { post } from '../../functions/fetch/fetch';
import {useDialog} from "../../components/modal/Popup"
import {View,Text} from "react-native-web";
import Button from '../../components/button/Button';
import moment from 'moment-timezone';
import { Divider } from 'rsuite';
import * as XLSX from "xlsx";


function PatchTeamPath() {
  const dialog = useDialog();
  const [transportFile,setTransportFile] = useState(null);
  const [teamPathFile,setTeamPathFile] = useState(null);

  const downloadUserPatchList = async () => {

    dialog.loading();
    let url = apiPath.member.getUserPatch;

    try {
      let { status, response } = await post(url, {  }, true);
      if (!status) {
        throw new Error("Network response was not ok");
      }

      // Assuming data is a CSV string
      const blob = new Blob([response.data], { type: "text/csv" });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute(
        "download",
        `users_${moment().format("DD_MM_YYYY_HHmmss")}.csv`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
      dialog.cancle();
    } catch (error) {
      dialog.alert({ message: error.message });
    }
  };

  const handleFileChangeTransport = (event) => {
    setTransportFile(event.target.files[0])
  };
  const handleUploadTransport = async () => {
    if (!transportFile) {
        alert('กรุณาอัพโหลดไฟล์ CSV');
        return;
    }
    let res = await dialog.confirm({message:"ยืนยันการอัพเดทราคาค่าส่ง"});
    if(!res){return}
    dialog.loading();
    const reader = new FileReader();
    reader.onload = async (event) => {
      const transportFile = event.target.result
      const json = csvToJson(transportFile);
      let url = apiPath.transport.patchTransportCost;
      const {status,response} = await post(url,JSON.parse(json),true);
      if(!status){
        dialog.alert({message:response.data.error})
        return;
      }

      if(response.data){
        dialog.alert({message:"อัพเดทราคาค่าส่งเรียบร้อยแล้ว"})
        return;
      }

    };

    reader.readAsText(transportFile);
  };

  const handleFileChangeTeamPath = (event) => {
    setTeamPathFile(event.target.files[0]);
  };
  const handleUploadTeamPath = async () => {
    if (!teamPathFile) {
        alert('กรุณาอัพโหลดไฟล์ CSV');
        return;
    }

    let res = await dialog.confirm({message:"ยืนยันการอัพเดท team path"});
    if(!res){return}

    dialog.loading();
    const reader = new FileReader();
    reader.onload = async (event) => {
      const teamFile = event.target.result
      const json = csvToJson(teamFile);
      let url = apiPath.member.patchTeamPath;
      const {status,response} = await post(url,JSON.parse(json),true);
      if(!status){
        dialog.alert({message:response.data.error})
        return;
      }

      if(response.data){
        dialog.alert({message:"อัพเดท team path เรียบร้อยแล้ว"})
        return;
      }

    };

    reader.readAsText(teamPathFile);
  };


  const openServer = async () => {
    let res = await dialog.confirm({message:"ยืนยันการเปิดการคีย์ขายปลีก"});
    if(!res){return}

    let url = apiPath.system.paymentCanDo;
    dialog.loading();
    const {status,response} = await post(url,{},true);
    if (!status){
      dialog.alert({message:response.data.error});
      return;
    }
    if(response.data){
      dialog.alert({message:"เปิดการคีย์ขายปลีกเรียบร้อยแล้ว"});
    }
  }

  const closeServer = async () => {
    let res = await dialog.confirm({message:"ยืนยันการปิดการคีย์ขายปลีก"});
    if(!res){return}
    let url = apiPath.system.paymentOpen;
    dialog.loading();
    const {status,response} = await post(url,{},true);
    if (!status){
      dialog.alert({message:response.data.error});
      return;
    }
    if(response.data){
      dialog.alert({message:"ปิดการคีย์ขายปลีกเรียบร้อยแล้ว"});
    }
  }

  const disableUserNotActive = async () => { 

    let res = await dialog.confirm({message:"ยืนยันการปิดการใช้งานตัวแทนที่ไม่ Active"});
    if(!res){return}

    dialog.loading();

    let url = apiPath.member.disableUserNotActive;
    const {status,response} = await post(url,{},true);
    if (!status){
      dialog.alert({message:response.data.error});
      return;
    }
    if(response.data){
      dialog.alert({message:"ปิดการใช้งานตัวแทนที่ไม่ Active เรียบร้อยแล้ว"});
    }
  }


  const downloadCommission = async () => {

    dialog.loading();
    let url = apiPath.report.getCommissionDoubleCheck;
    try {
      let { status, response } = await post(url, { period: "202401" }, true);
      if (!status) {
        throw new Error("Network response was not ok");
      }
      // Assuming data is a CSV string
      const blob = new Blob([response.data], { type: "text/csv" });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute(
        "download",
        `commission_report_${moment().format("DD_MM_YYYY_HHmmss")}.csv`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
      dialog.cancle();
    } catch (error) {
      dialog.alert({ message: error.message });
    }
  };


  const downloadRetailReport = async () => {
    const startDate = moment.tz("2024-01-01 00:00:00", "YYYY-MM-DD HH:mm:ss", "Asia/Bangkok");
    const endDate = moment.tz("2024-01-31 23:59:59", "YYYY-MM-DD HH:mm:ss", "Asia/Bangkok");
    dialog.loading();
    let url = apiPath.report.getRetailReportByProduct;
    let { status, response } = await post(
      url,
      {
        startDate: moment
          .utc(startDate)
          .set({ h: 0, m: 0, s: 0 })
          .format("YYYY-MM-DD HH:mm:ss"),
        endDate: moment
          .utc(endDate)
          .set({ h: 23, m: 59, s: 59 })
          .format("YYYY-MM-DD HH:mm:ss"),
      },
      true
    );
    if (!status) {
      dialog.alert({ message: response.data.error });
      return;
    }
    if (response.data) {
      // ตรวจสอบว่า response.data เป็นอาร์เรย์
      if (!Array.isArray(response.data)) {
        dialog.alert({ message: "ข้อมูลที่ได้รับไม่ถูกต้อง" });
        return;
      }
      const orderedKeys = [
        "date",
        "retailCode",
        "paymentRefNo",
        "gbpRefNo",
        "saleName",
        "saleCode",
        "customerName",
        "totalPrice",
        "totalPriceWithOutTransport",
      ];

      if (response.data && Array.isArray(response.data)) {
        const sortedData = response.data.map((item) => {
          const sortedItem = {};

          // เพิ่มคีย์ตามลำดับที่กำหนด
          orderedKeys.forEach((key) => {
            sortedItem[key] = item[key];
          });

          // เพิ่มคีย์ที่เหลือตามลำดับตัวอักษร
          Object.keys(item)
            .filter((key) => !orderedKeys.includes(key))
            .sort()
            .forEach((key) => {
              sortedItem[key] = item[key];
            });

          return sortedItem;
        });

        // ตอนนี้ sortedData มีข้อมูลที่ถูกเรียงลำดับแล้ว
        const worksheet = XLSX.utils.json_to_sheet(sortedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(
          workbook,
          `รายงานขายปลีก_${moment().format("DD_MM_YYYY_HHmmss")}.xlsx`
        );
        dialog.cancle();
      } else {
        dialog.alert({ message: "ข้อมูลไม่ถูกต้อง" });
      }
    }
  };

 


  return (
    <View style={{justifyContent: 'center',padding:20}}>

    
      <View style={Styles.box}>
      <Text style={Styles.textTitle}>1. เปิด/ปิด ระบบการคีย์ขายปลีก</Text>
      <Text style={Styles.textSub}>(กดปุ่มเพื่อ เปิด/ปิด ระบบ)</Text>
        <Button 
        buttonText={"ปิดการคีย์ขายปลีก - Close Server"} 
        color={"primary"}
        isBorder={true}
        onClick={() => {
          closeServer();
        }}/>
        <View style={{height:10}}/>
                <Button 
        buttonText={"เปิดการคีย์ขายปลีก - Open Server"} 
        color={"primary"}
        onClick={() => {
          openServer();        }}/>
      </View>

      <View style={Styles.box}>
        <Text style={Styles.textTitle}>2. ปรับราคาค่าส่ง กลับเป็นเรทเดิม</Text>
        <Text style={Styles.textSub}>(อัพโหลดไฟล์ CSV เพื่ออัพเดทราคาค่าส่ง)</Text>

        <input type="file" accept=".csv" onChange={handleFileChangeTransport} />
        <View style={{height:10}}/>
        <Button 
        buttonText={"ยืนยัน"} 
        color={"primary"}
        onClick={() => {
          handleUploadTransport();
        }}/>      </View>

      <View style={Styles.box}>
        <Text style={Styles.textTitle}>3. เปลี่ยนแปลงสายงาน รหัสตัวแทนที่ไม่แอคทีฟ</Text>
        <Text style={Styles.textSub}>{`(อัพโหลดไฟล์ CSV ที่มีการเปลี่ยนแปลงค่า team_path,team_level,agent_code_ref,agent_code_master ==> ในส่วน id,agent_code ห้ามเปลี่ยนแปลง)`}</Text>

        <input type="file" accept=".csv" onChange={handleFileChangeTeamPath} />
        <View style={{height:10}}/>
        <Text>ดาวน์โหลดไฟล์จากด้านล่างก่อนเพื่อนำไปปรับปรุงข้อมูลจากนั้นจึงเลือกไฟล์ และกด ยืนยัน</Text>
        <View style={{height:10}}/>
       <Button 
        buttonText={"ยืนยัน"} 
        color={"primary"}
        onClick={() => {
          handleUploadTeamPath();
        }}/>
      
        <View style={{height:20}}/>
        <Divider/>
        <Button 
        buttonText={"ดาวน์โหลดไฟล์ User ทั้งหมด"} 
        color={"primary"}
        isBorder={true}
        onClick={() => {
          downloadUserPatchList()
        }}/>
        <View style={{height:10}}/>


      </View>

      <View style={Styles.box}>
        <Text style={Styles.textTitle}>3.1 ยกเลิกรหัสตัวแทนที่ไม่แอคทีฟ (ระงับการเข้าระบบตัวแทนที่ไม่ Active สถานะ active = 'M'</Text>
        <Text style={Styles.textSub}>{`(กดปุ่มเพื่อระงับการใช้งานตัวแทนที่ไม่ Active) เปลี่ยนสถานะจาก M เป็น D <== ระงับถาวร`}</Text>

        <Button 
        buttonText={"กดที่นี่เพื่อ ระงับตัวแทนที่ไม่ Active"} 
        color={"primary"}
        onClick={() => {
          disableUserNotActive();
        }}/>
      </View>

      <View style={Styles.box}>
        <Text style={Styles.textTitle}>4. ปรับเรทราคาสินค้า (เข้าไปปรับที่หน้าสินค้า)</Text>
      </View>

      <View style={Styles.box}>
        <Text style={Styles.textTitle}>5. ดึงรายการขายปลีกเดือนมกราคม</Text>
        <Button 
        buttonText={"ดาวน์โหลด"} 
        color={"primary"}
        onClick={() => {
          downloadRetailReport();
        }}/>
      </View>
    
      <View style={Styles.box}>
        <Text style={Styles.textTitle}>6. ดึงคอมมิชชั่นมกราคมมาตรวจสอบ</Text>
        <Button 
        buttonText={"ดาวน์โหลด"} 
        color={"primary"}
        onClick={() => {
          downloadCommission();
        }}/>
      </View>

    </View>
  );
}

const Styles = {
  box:{padding:20,
    borderWidth:1,
    borderColor:"#333333",
    borderRadius:20,
    marginBottom:20
  },
  textTitle:{
    fontSize: 20,
    fontWeight: "bold",
    color: "#333333",
    marginBottom:4
  },
  textSub:{
    marginBottom:10,
    color:"#666666"
  }
}
export default PatchTeamPath;


function csvToJson(csv) {
  var lines = csv.split("\n");

  // Extract headers
  var headers = lines[0].split(",");

  var result = [];
  for (var i = 1; i < lines.length; i++) {
      // Skip empty rows
      if (!lines[i].trim()) continue;

      var obj = {};
      var currentline = lines[i].split(",");

      for (var j = 0; j < headers.length; j++) {
          // Handle missing values in a row
          obj[headers[j]] = currentline[j] ? currentline[j] : null;
      }

      result.push(obj);
  }

  // Return JSON
  return JSON.stringify(result); 
}
