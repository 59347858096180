import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import { Grid } from "@mui/material";
import { Divider } from "rsuite";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
//component
import Button from "../../components/button/Button";
import Dropdown from "../../components/dropdown/Dropdown";
import UploadImage from "../../components/uploadbox/UploadImage";
import TextField from "../../components/textField/TextField";
import TimePicker from "../../components/time/TimePicker";
import DatePicker from "../../components/calendar/DatePicker";
import CheckBoxList from "../../components/checkbox/CheckBoxList";
import CheckBoxListV2 from "../../components/checkbox/CheckBoxListV2";
import TextFieldList from "../../components/textField/TextFieldList";
import { useDialog } from "../../components/modal/Popup";
import apiPath from "../../services/configs/apiPath";
import { get, post, upload } from "../../functions/fetch/fetch";
import { base64ToFile } from "../../functions/format/convertFile";
import {
  formatNumber,
  formatNumberString,
} from "../../functions/format/numberFormat";
import { setDate } from "rsuite/esm/utils/dateUtils";
import { useSelector } from "react-redux";

const AddEvent = (props) => {
  const navigate = useNavigate();
  const dialog = useDialog();
  const { editData } = props;
  const { onClose } = props;
  const { user } = useSelector((state) => {
    return state;
  });
  //state
  const [rankingList, setRankingList] = useState([]);
  const [rankingListTop, setRankingListTop] = useState([]);

  const [eventType, setEventType] = useState([]);
  const [isSetEditData, setIsSetEditData] = useState(false);
  const [data, setData] = useState({
    event_id: null, // ถ้าไม่มีให้ส่ง null สำหรับสร้างอีเว้นท์ใหม่ / ถ้าหากต้องการอัพเดตให้ส่ง id ของ event มา
    event_image: "",
    imageFile: null,
    register_limit: 0,
    qualify_rank: [], // ไม่มีให้ส่ง [] แสดงมาสามารถเข้างานได้ทุกคน
    qualify_top_rank: [], // ไม่มีให้ส่ง [] แสดงมาสามารถเข้างานได้ทุกคน
    event_start_time_date: null, // งานเริ่มกี่โมง
    event_end_time_date: null, // งานสิ้นสุดกี่โมง
    event_list_food: [""],
    event_description: "",
    event_link_location: "",
    event_location: "",
    event_other_contact: "",
    event_telephone_contact: "",
    event_price: 0,
    event_type_id: 0,
    event_start_register_date: "", // วันเริ่มซื้อ event
    event_close_register_date: "", // วันปิดการซื้อ event
    event_date: moment(), // ส่งเวลา Now มา
    event_name: "",
    disable_status: false, // กรณีสร้างอีเว้นท์ใหม่ส่ง false / กรณีที่ต้องการปิด event นี้ให้ส่ง true
  });

  useEffect(() => {
    const downloadAll = async () => {
      await downloadAllRankingList();
      await downloadEventType();
    };
    downloadAll();
  }, []);

  useEffect(() => {
    if (
      editData &&
      rankingList.length > 0 &&
      rankingListTop.length > 0 &&
      eventType.length > 0 &&
      !isSetEditData
    ) {
      setData({
        event_id: editData.event_id, // ถ้าไม่มีให้ส่ง null สำหรับสร้างอีเว้นท์ใหม่ / ถ้าหากต้องการอัพเดตให้ส่ง id ของ event มา
        event_image: editData.event_image,
        imageFile: null,
        register_limit: editData.register_limit,
        qualify_rank: editData.qualify_rank, // ไม่มีให้ส่ง [] แสดงมาสามารถเข้างานได้ทุกคน
        qualify_top_rank: editData.qualify_top_rank, // ไม่มีให้ส่ง [] แสดงมาสามารถเข้างานได้ทุกคน
        event_start_time_date: editData.event_start_time_date, // งานเริ่มกี่โมง
        event_end_time_date: editData.event_end_time_date, // งานสิ้นสุดกี่โมง
        event_list_food: editData.event_list_food,
        event_description: editData.event_description,
        event_link_location: editData.event_link_location,
        event_location: editData.event_location,
        event_other_contact: editData.event_other_contact,
        event_telephone_contact: editData.event_telephone_contact,
        event_price: editData.event_price,
        event_type_id: editData.event_type_id,
        event_start_register_date: editData.event_start_register_date, // วันเริ่มซื้อ event
        event_close_register_date: editData.event_close_register_date, // วันปิดการซื้อ event
        event_date: moment(), // ส่งเวลา Now มา
        event_name: editData.event_name,
      });
      let initRankingList = [];
      rankingList.forEach((item) => {
        let find = editData.qualify_rank.find((d) => {
          return d.rank_id === item.value;
        });
        initRankingList.push({
          ...item,
          optionText: find ? Number(find.limit_register) : null,
          active: find ? "Y" : "N",
        });
      });
      setRankingList(initRankingList);
      let initRankingListTop = [];
      rankingListTop.forEach((item) => {
        let find = editData.qualify_top_rank.find((d) => {
          return d.rank_id === item.value;
        });
        initRankingListTop.push({
          ...item,
          optionText: find ? Number(find.limit_register) : null,
          active: find ? "Y" : "N",
        });
      });
      setRankingListTop(initRankingListTop);
      setIsSetEditData(true);
    }
  }, [editData, rankingList, rankingListTop, eventType]);

  const downloadAllRankingList = async () => {
    let url = apiPath.member.getAllRankingList;
    let { status, response } = await get(url, {}, true);
    if (!status) {
      dialog.alert({ message: "เกิดข้อผิดพลาด" });
    }
    if (response.data) {
      let val = response.data;
      let init = [];
      let qualify_rank = [];
      val.forEach((element) => {
        init.push({
          value: element.id,
          label: element.name,
          enable: true,
          active: "Y",
        });
        qualify_rank.push(element.level);
      });
      setRankingList([...init]);
      setRankingListTop([...init]);

      setData({ ...data, qualify_rank: qualify_rank });
    }
  };

  const downloadEventType = async () => {
    let url = apiPath.event.getEventTypeList;
    const { status, response } = await get(url, {}, true);
    if (!status) {
      return;
    }
    if (response.data) {
      let init = [];
      response.data.forEach((item) => {
        init.push({ label: item.event_type, value: item.id });
      });
      setEventType(init);
    }
  };

  const validation = () => {
    const requiredFields = [
      "event_name",
      "event_start_time_date",
      "event_end_time_date",
      "event_type_id",
      "event_price",
      "event_telephone_contact",
      "event_location",
      "event_link_location",
      "event_description",
      "event_start_register_date",
      "event_close_register_date",
      "register_limit",
    ];

    if (!data.imageFile && !editData) {
      requiredFields.push("imageFile");
    }

    let errors = requiredFields.filter((field) => {
      if (field.includes(".")) {
        const keys = field.split(".");
        return !data[keys[0]][keys[1]];
      }
      return !data[field];
    });

    // แสดงข้อผิดพลาดหากมี
    if (errors.length > 0) {
      console.log(errors);
      console.log(data);
      return false;
    }
    return true;
  };
  const submit = async () => {
    console.log("submit");
    if (!validation()) {
      dialog.alert({
        message: "กรุณากรอกข้อมูลให้ครบถ้วน",
      });
      return;
    }

    //check ranking
    let qr = [];
    let errrosRank = false;
    rankingList.forEach((item) => {
      console.log(item);
      if (item.active === "Y") {
        if (!item.optionText) {
          errrosRank = true;
        }
        qr.push({
          rank_id: item.value,
          limit_register: item.optionText ? item.optionText : 0,
        });
      }
    });
    if (qr.length === 0 || errrosRank) {
      dialog.alert({
        message: "กรุณาระบุระดับตัวแทนที่เข้าได้ หรือจำนวนที่เข้าได้",
      });
      return;
    }
    data.qualify_rank = qr;

    let qtr = [];
    rankingListTop.forEach((item) => {
      if (item.active === "Y") {
        qtr.push({
          rank_id: item.value,
          limit_register: item.optionText ? item.optionText : 0,
        });
      }
    });
    data.qualify_top_rank = qtr;

    //Upload
    if (data.imageFile) {
      let userUploadName = user ? user.agentCode : "NOTFOUNDUSER";
      const uploadImages = await upload(
        [data.imageFile],
        `${process.env["REACT_APP_ENV"]}/Images/Event/${userUploadName}`
      );
      const resImg = uploadImages.response;
      if (!resImg.data) {
        await dialog.alert({
          title: "แจ้งเตือน",
          message: uploadImages.response.data.error,
        });
        return;
      }
      data.event_image = resImg.data.data[0];
      delete data.imageFile;
    }

    data.event_start_time_date = moment(data.event_start_time_date).toDate();
    data.event_end_time_date = moment(data.event_end_time_date).toDate();
    data.event_start_register_date = moment(
      data.event_start_register_date
    ).toDate();
    data.event_close_register_date = moment(
      data.event_close_register_date
    ).toDate();
    data.disable_status = false;

    let url = apiPath.event.createOrUpdateEvent;
    let resCreate = await post(url, data, true);
    if (!resCreate.status) {
      await dialog.alert({
        title: "แจ้งเตือน",
        message: resCreate.response.data.error,
      });
      console.log(data);
      return;
    }
    let alert = dialog.alert({ message: "สร้างอีเวนท์สำเร็จ" });
    if (!alert) {
      return;
    }
    navigate("/eventList");
  };

  const update = async () => {
    if (!validation()) {
      dialog.alert({
        message: "กรุณากรอกข้อมูลให้ครบถ้วน",
      });
      return;
    }

    //check ranking
    let qr = [];
    let errrosRank = false;
    rankingList.forEach((item) => {
      console.log(item);
      if (item.active === "Y") {
        if (!item.optionText) {
          errrosRank = true;
        }
        qr.push({
          rank_id: item.value,
          limit_register: item.optionText ? item.optionText : 0,
        });
      }
    });
    if (qr.length === 0 || errrosRank) {
      dialog.alert({
        message: "กรุณาระบุระดับตัวแทนที่เข้าได้ หรือจำนวนที่เข้าได้",
      });
      return;
    }
    data.qualify_rank = qr;

    let qtr = [];
    rankingListTop.forEach((item) => {
      if (item.active === "Y") {
        qtr.push({
          rank_id: item.value,
          limit_register: item.optionText ? item.optionText : 0,
        });
      }
    });
    data.qualify_top_rank = qtr;

    //Upload
    if (data.imageFile) {
      let userUploadName = user ? user.agentCode : "NOTFOUNDUSER";
      const uploadImages = await upload(
        [data.imageFile],
        `${process.env["REACT_APP_ENV"]}/Images/Event/${userUploadName}`
      );
      const resImg = uploadImages.response;
      if (!resImg.data) {
        await dialog.alert({
          title: "แจ้งเตือน",
          message: uploadImages.response.data.error,
        });
        return;
      }
      data.event_image = resImg.data.data[0];
      delete data.imageFile;
    }

    data.event_start_time_date = moment(data.event_start_time_date).toDate();
    data.event_end_time_date = moment(data.event_end_time_date).toDate();
    data.event_start_register_date = moment(
      data.event_start_register_date
    ).toDate();
    data.event_close_register_date = moment(
      data.event_close_register_date
    ).toDate();
    data.disable_status = false;

    let url = apiPath.event.createOrUpdateEvent;
    let resCreate = await post(url, data, true);
    if (!resCreate.status) {
      await dialog.alert({
        title: "แจ้งเตือน",
        message: resCreate.response.data.error,
      });
      console.log(data);
      return;
    }
    let alert = dialog.alert({ message: "บันทึกการแก้ไขสำเร็จ" });
    if (!alert) {
      return;
    }
    onClose && onClose();
  };

  return (
    <React.Fragment>
      <View style={Styles.container}>
        <Grid container style={Styles.topContainer} spacing={2}>
          <Grid item xs={12} md={8} lg={9} xl={10}>
            <View style={{ flex: 1 }}>
              <Text style={Styles.textTopContainer1}>อีเวนท์</Text>
              <Text style={Styles.textTopContainer2}>
                อีเวนท์ / {`${editData ? "แก้ไขอีเวนท์" : "เพิ่มอีเวนท์"}`}
              </Text>
            </View>
          </Grid>
        </Grid>
        <View style={{ height: 30 }} />
        <View style={Styles.contentContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8} lg={6} xl={4}>
              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    fontSize: "clamp(16px,2vh,20px)",
                    fontWeight: "bold",
                    color: "rgba(15, 16, 17, 1)",
                  }}
                >
                  {`${editData ? "แก้ไขอีเวนท์" : "สร้างอีเวนท์"}`}
                </Text>
              </View>
            </Grid>
          </Grid>
          <Divider />
          <View
            style={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text style={{ fontSize: 16 }}>เพิ่มรูปอีเวนท์</Text>
            <View style={{ marginTop: 16 }} />
            <UploadImage
              value={data.event_image ? data.event_image : ""}
              placeholder={"เพิ่มรูปภาพ"}
              style={{ width: 200, height: 200 }}
              onChangeImage={(image) => {
                setData({
                  ...data,
                  imageFile: base64ToFile(image),
                  event_image: image,
                });
              }}
            />
            <View style={{ marginTop: 16 }} />
            <Text style={Styles.textImageMark}>
              รองรับไฟล์ JPG, PNG, JPEGS เท่านั้น
            </Text>
          </View>
          <View style={{ height: 30 }} />

          <Grid container spacing={2}>
            <Grid item xs={12} md={6} style={{ zIndex: 20 }}>
              <Text style={Styles.titleText}>
                ชื่ออีเวนท์{" "}
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TextField
                  value={data.event_name ? data.event_name : ""}
                  isInvalid={false}
                  errorMessage={""}
                  placeholder={"ระบุชื่ออีเวนท์"}
                  onChangeText={(value) => {
                    setData({
                      ...data,
                      event_name: value,
                    });
                  }}
                />
              </View>
            </Grid>
            <Grid item xs={12} md={6} style={{ zIndex: 19 }}>
              <Text style={Styles.titleText}>
                วันที่เริ่มต้นจัดอีเวนท์{" "}
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />
              <DatePicker
                date={
                  data.event_start_time_date
                    ? moment(data.event_start_time_date)
                    : null
                }
                showTime={true}
                placeholder="วันที่เริ่มต้นจัดอีเวนท์"
                styles={{ width: "100%", zIndex: 9999 }}
                onChange={(date) => {
                  setData({
                    ...data,
                    event_start_time_date: date,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ zIndex: 18 }}>
              <Text style={Styles.titleText}>
                วันที่สิ้นสุดอีเวนท์{" "}
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />
              <DatePicker
                date={
                  data.event_end_time_date
                    ? moment(data.event_end_time_date)
                    : null
                }
                showTime={true}
                placeholder="วันที่สิ้นสุดอีเวนท์"
                styles={{ width: "100%", zIndex: 9999 }}
                onChange={(date) => {
                  setData({
                    ...data,
                    event_end_time_date: date,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} md={6} style={{ zIndex: 16 }}>
              <Text style={Styles.titleText}>
                ประเภทอีเวนท์{" "}
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />

              <Dropdown
                value={data.event_type_id ? data.event_type_id : null}
                valueType={"number"}
                placeholder={"เลือกประเภทอีเวนท์"}
                list={eventType}
                onChange={(values) => {
                  const { value, label } = values;
                  setData({
                    ...data,
                    event_type_id: value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ zIndex: 15 }}>
              <Text style={Styles.titleText}>
                จำนวนพอยต์/ค่าเข้าอีเวนท์{" "}
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />

              <TextField
                value={data.event_price ? data.event_price : ""}
                placeholder={"ระบุจำนวนพอยต์/ค่าเข้าอีเวนท์"}
                onChangeText={(value) => {
                  setData({ ...data, event_price: formatNumber(value) });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ zIndex: 14 }}>
              <Text style={Styles.titleText}>
                เบอร์โทรศัพท์ติดต่อ{" "}
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />

              <TextField
                value={
                  data.event_telephone_contact
                    ? data.event_telephone_contact
                    : ""
                }
                placeholder={"ระบุเบอร์โทรศัพท์ติดต่อ"}
                errorMessage={""}
                onChangeText={(value) => {
                  setData({
                    ...data,
                    event_telephone_contact: formatNumberString(value),
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ zIndex: 13 }}>
              <Text style={Styles.titleText}>
                ช่องทางติดต่ออื่นๆ{" "}
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />

              <TextField
                value={data.event_other_contact ? data.event_other_contact : ""}
                iconName={""}
                isInvalid={false}
                placeholder={"ระบุ ช่องทางติดต่ออื่นๆ"}
                errorMessage={""}
                onChangeText={(value) => {
                  setData({
                    ...data,
                    event_other_contact: value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ zIndex: 12 }}>
              <Text style={Styles.titleText}>
                สถานที่จัดอีเวนท์{" "}
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />
              <TextField
                value={data.event_location ? data.event_location : ""}
                iconName={""}
                isInvalid={false}
                placeholder={"ระบุสถานที่จัดอีเวนท์"}
                errorMessage={""}
                onChangeText={(value) => {
                  setData({ ...data, event_location: value });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ zIndex: 11 }}>
              <Text style={Styles.titleText}>
                ลิงก์สถานที่จัดอีเวนท์{" "}
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />
              <TextField
                value={data.event_link_location ? data.event_link_location : ""}
                iconName={""}
                isInvalid={false}
                placeholder={"ระบุลิงก์สถานที่จัดอีเวนท์"}
                errorMessage={""}
                onChangeText={(value) => {
                  setData({ ...data, event_link_location: value });
                }}
              />
            </Grid>
            <Grid item xs={12} style={{ zIndex: 10 }}>
              <Text style={Styles.titleText}>
                รายละเอียดอีเวนท์{" "}
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />

              <TextField
                value={data.event_description ? data.event_description : ""}
                iconName={""}
                isInvalid={false}
                placeholder={"ระบุรายละเอียดอีเวนท์"}
                errorMessage={""}
                onChangeText={(value) => {
                  setData({ ...data, event_description: value });
                }}
              />
            </Grid>
            <Grid item xs={12} style={{ zIndex: 9 }}>
              <Text style={Styles.titleTextField}>อาหารในอีเวนท์</Text>
              <View style={{ height: 8 }} />
              <TextFieldList
                value={data.event_list_food ? data.event_list_food : [""]}
                placeholder={"อาหารในอีเวนท์"}
                onChange={(values) => {
                  setData({ ...data, event_list_food: values });
                }}
              />
            </Grid>
            <Grid item xs={12} style={{ zIndex: 8 }}>
              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    fontSize: "clamp(16px,2vh,20px)",
                    fontWeight: "bold",
                    color: "rgba(15, 16, 17, 1)",
                  }}
                >
                  เงื่อนไขการเข้าร่วมอีเวนท์{" "}
                </Text>
              </View>
            </Grid>
          </Grid>
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} style={{ zIndex: 7 }}>
              <Text style={Styles.titleText}>
                วันที่เริ่มต้นการลงทะเบียน{" "}
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />
              <DatePicker
                date={moment(data.event_start_register_date)}
                showTime={true}
                placeholder="วันที่เริ่มต้นการลงทะเบียน"
                styles={{ width: "100%", zIndex: 9999 }}
                onChange={(date) => {
                  setData({ ...data, event_start_register_date: date });
                }}
              />
            </Grid>

            <Grid item xs={12} md={6} style={{ zIndex: 7 }}>
              <Text style={Styles.titleText}>
                วันที่สิ้นสุดการลงทะเบียน{" "}
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />
              <DatePicker
                date={moment(data.event_close_register_date)}
                showTime={true}
                placeholder="วันที่สิ้นสุดการลงทะเบียน"
                styles={{ width: "100%", zIndex: 9999 }}
                onChange={(date) => {
                  setData({ ...data, event_close_register_date: date });
                }}
              />
            </Grid>

            <Grid item xs={12} md={6} style={{ zIndex: 6 }}>
              <Text style={Styles.titleText}>
                จำนวนผู้ลงทะเบียน{" "}
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />

              <TextField
                value={data.register_limit ? data.register_limit : ""}
                iconName={""}
                isInvalid={false}
                placeholder={"ระบุจำนวนผู้ลงทะเบียน"}
                errorMessage={""}
                onChangeText={(value) => {
                  setData({ ...data, register_limit: formatNumber(value) });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ zIndex: 6 }} />

            <Grid item xs={12} md={6} style={{ marginTop: 10, zIndex: 5 }}>
              <View
                style={{
                  borderWidth: 1,
                  borderColor: "rgba(213, 215, 221, 1)",
                  borderRadius: 8,
                  padding: 10,
                }}
              >
                <Text style={{ fontWeight: "bold" }}>
                  ระดับตัวแทนที่เข้าได้
                </Text>{" "}
                <CheckBoxListV2
                  list={rankingList}
                  showTextFieldOption={true}
                  showLine={false}
                  onCheck={(values) => {
                    let findIndex = rankingList.findIndex((item) => {
                      return item.value === values.value;
                    });
                    let init = rankingList;
                    init[findIndex] = { ...values };
                    //findIndex
                    setRankingList([...init]);
                  }}
                  onChangeOption={(values) => {
                    if (values.active === "N") {
                      return;
                    }
                    let findIndex = rankingList.findIndex((item) => {
                      return item.value === values.value;
                    });
                    let init = rankingList;
                    init[findIndex] = {
                      ...values,
                      optionText: formatNumber(values.optionText),
                    };

                    setRankingList([...init]);
                  }}
                />
              </View>
            </Grid>
            <Grid item xs={12} md={6} style={{ marginTop: 10, zIndex: 4 }}>
              <View
                style={{
                  borderWidth: 1,
                  borderColor: "rgba(213, 215, 221, 1)",
                  borderRadius: 8,
                  padding: 10,
                }}
              >
                <Text style={{ fontWeight: "bold" }}>
                  ขั้นต่ำของแรงค์ที่สามารถเข้าอีเวนท์
                </Text>
                <CheckBoxListV2
                  list={rankingListTop}
                  showTextFieldOption={true}
                  showLine={false}
                  onCheck={(values) => {
                    let findIndex = rankingListTop.findIndex((item) => {
                      return item.value === values.value;
                    });
                    let init = rankingListTop;
                    init[findIndex] = { ...values };
                    //findIndex
                    setRankingListTop([...init]);
                  }}
                  onChangeOption={(values) => {
                    if (values.active === "N") {
                      return;
                    }
                    let findIndex = rankingListTop.findIndex((item) => {
                      return item.value === values.value;
                    });
                    let init = rankingListTop;
                    init[findIndex] = {
                      ...values,
                      optionText: formatNumber(values.optionText),
                    };

                    setRankingListTop([...init]);
                  }}
                />
              </View>
            </Grid>
          </Grid>
          <View style={{ height: 40 }} />
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              buttonText={"ยกเลิก"}
              color={"primary"}
              style={{ flex: 1, maxWidth: 400 }}
              isBorder={true}
              onClick={() => {
                if (editData) {
                  onClose && onClose();
                } else {
                  navigate("/eventList");
                }
              }}
            />
            <View style={{ width: 20 }} />
            <Button
              buttonText={`${editData ? "บันทึก" : "สร้างอีเวนท์"}`}
              color={"primary"}
              style={{ flex: 1, maxWidth: 400 }}
              isBorder={false}
              onClick={() => {
                if (editData) {
                  update();
                } else {
                  submit();
                }
              }}
            />
          </View>
          <View style={{ height: 40 }} />
        </View>
        <View style={{ height: 100 }} />
      </View>
    </React.Fragment>
  );
};

const Styles = {
  container: {
    flex: 1,
    height: "100vh",
    padding: "1.5%",
  },
  topContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  contentContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  textTopContainer1: {
    fontSize: "clamp(18px, 3vw, 22px)",
    fontWeight: "bold",
    color: "#AA4942",
  },
  textTopContainer2: {
    fontSize: "clamp(12px, 3vw, 16px)",
    color: "#696D79",
  },
  topButtonText: {
    fontSize: "clamp(14px, 3vw, 18px)",
  },
  titleSearch: {
    fontSize: 20,
    color: "#0F1011",
  },
  subTitleSearch: {
    fontSize: 16,
    color: "#0F1011",
    marginTop: 8,
  },
};
export default AddEvent;
