import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import { Grid } from "@mui/material";
import { Divider } from "rsuite";
import { useNavigate } from "react-router-dom";
//component
import Button from "../../components/button/Button";
import Dropdown from "../../components/dropdown/Dropdown";
import CreateArticle from "./component/CreateArticle";
import ArticleContainer from "./component/ArticleContainer";
import apiPath from "../../services/configs/apiPath";
import { get, post } from "../../functions/fetch/fetch";
import { useDialog } from "../../components/modal/Popup";

const SettingArticle = () => {
  const navigate = useNavigate();
  const dialog = useDialog();
  const [showCreate, setShowCreate] = useState(false);
  const [editData, setEditData] = useState(null);
  const [list, setList] = useState([]);

  useEffect(() => {
    downloadNews();
  }, []);

  const downloadNews = async () => {
    let url = apiPath.news.getNewsByAdmin;
    let { status, response } = await get(url, {}, true);
    if (!status) {
      dialog.alert({ message: response.data.error });
      return;
    }
    if (response.data) {
      setList(response.data);
    }
  };

  const handleOption = (values) => {
    console.log(values);

    const { option, data } = values;
    if (option === "delete") {
      handleDelete(data);
    } else if (option === "edit") {
      handleEdit(data);
    }
  };

  const handleDelete = async (data) => {
    let confirm = await dialog.confirm({
      title: `ยืนยันการลบบทความ ${data.Title}`,
      message: `ยืนยันการลบบทความ ${data.Title} ใช่ หรือ ไม่ ?`,
    });
    if (!confirm) {
      return;
    }

    let url = apiPath.news.deleteNews;
    let { status, response } = await post(url, { id: data.ID }, true);
    if (!status) {
      dialog.alert({ message: response.data.error });
      return;
    }
    if (response.data) {
      dialog.alert({ message: "ลบบทความสำเร็จ" });
      downloadNews();
      return;
    }
  };

  const handleEdit = async (data) => {
    setEditData(data);
  };

  return (
    <React.Fragment>
      <View style={Styles.container}>
        <Grid container style={Styles.topContainer} spacing={2}>
          <Grid item xs={12} md={8} lg={9} xl={10}>
            <View style={{ flex: 1 }}>
              <Text style={Styles.textTopContainer1}>จัดการบทความ</Text>
              <Text style={Styles.textTopContainer2}>
                {`จัดการเว็บไซต์ / จัดการบทความ${
                  showCreate ? " / สร้างบทความ" : ""
                }`}
              </Text>
            </View>
          </Grid>
          <Grid item xs={12} md={4} lg={3} xl={2}>
            <Button
              buttonText={"เพิ่มบทความ"}
              color={"primary"}
              style={{ width: "100%" }}
              styleText={Styles.topButtonText}
              isBorder={false}
              onClick={() => {
                setShowCreate(true);
              }}
            />
          </Grid>
        </Grid>
        <View style={{ height: 30 }} />
        <View style={Styles.contentContainer}>
          {showCreate || editData ? (
            <CreateArticle
              editData={editData}
              onClose={() => {
                setShowCreate(false);
                setEditData(null);
                downloadNews();
              }}
            />
          ) : (
            <ArticleContainer
              data={list}
              onOption={(values) => {
                handleOption(values);
              }}
            />
          )}
        </View>
      </View>
    </React.Fragment>
  );
};

const Styles = {
  container: {
    flex: 1,
    height: "100vh",
    padding: "1.5%",
  },
  topContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  contentContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  textTopContainer1: {
    fontSize: "clamp(18px, 3vw, 22px)",
    fontWeight: "bold",
    color: "#AA4942",
  },
  textTopContainer2: {
    fontSize: "clamp(12px, 3vw, 16px)",
    color: "#696D79",
  },
  topButtonText: {
    fontSize: "clamp(14px, 3vw, 18px)",
  },
};
export default SettingArticle;
