import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import moment from "moment-timezone";
//components
import DropdownMenuPopover from "../../../components/dropdown/DropdownPopover";
//images
import logo from "../../../asset/images/example/product.png";
import emptyProduct from "../../../asset/images/product/inventory/emptyProduct.png";
//functions
import { formatNumber } from "../../../functions/format/numberFormat";
import PageButtonNavigate from "../../../components/button/PageButtonNavigate";
import { approveOrder } from "../../../services/api/sales/sales";
import ReportCommission from "../ReportComission";

const ReportCommissionRetailProductCount = (props) => {
  const { tableHeader = [], tableData = [] } = props;
  const { onView } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const pagesCount = Math.ceil(tableData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const tableDataSliced = tableData.slice(startIndex, endIndex);

  return tableData.length > 0 ? (
    <React.Fragment>
      <TableContainer sx={styles.tableContainer}>
        <Table sx={styles.table}>
          <TableHead sx={{ position: "sticky", top: 0, zIndex: 9999 }}>
            <TableRow style={{ width: "100%", backgroundColor: "#FFF" }}>
              <TableCell sx={{ ...styles.headerCell }}>ชื่อสินค้า</TableCell>
              <TableCell sx={{ ...styles.headerCell }}>
                จำนวนสินค้า(ส่วนตัว)
              </TableCell>
              <TableCell sx={{ ...styles.headerCell }}>
                จำนวนสินค้า(ทีม)
              </TableCell>
              <TableCell sx={{ ...styles.headerCell }}>
                จำนวนสินค้าทั้งหมด
              </TableCell>
              <TableCell sx={{ ...styles.headerCell }}>
                ยอดขายปลีก(ส่วนตัว)
              </TableCell>
              <TableCell sx={{ ...styles.headerCell }}>
                ยอดขายปลีก(ทีม)
              </TableCell>
              <TableCell sx={{ ...styles.headerCell }}>
                ยอดขายปลีกทั้งหมด
              </TableCell>
              <TableCell sx={{ ...styles.headerCell }}>ต้นทุนทั้งหมด</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableDataSliced.map((row) => {
              return (
                <TableRow key={row.id} sx={styles.tableRow}>
                  <TableCell sx={styles.tableCell}>
                    <Text>{row.productName ? row.productName : ""}</Text>
                  </TableCell>
                  <TableCell sx={styles.tableCell}>
                    <Text>
                      {row.quantity
                        ? Number(row.quantity).toLocaleString()
                        : "0"}
                    </Text>
                  </TableCell>
                  <TableCell sx={styles.tableCell}>
                    <Text>
                      {row.quantityAll && row.quantity
                        ? Number(
                            Number(row.quantityAll) - Number(row.quantity)
                          ).toLocaleString()
                        : "0"}
                    </Text>
                  </TableCell>
                  <TableCell sx={styles.tableCell}>
                    <Text>
                      {row.quantity
                        ? Number(row.quantityAll).toLocaleString()
                        : "0"}
                    </Text>
                  </TableCell>
                  <TableCell sx={styles.tableCell}>
                    <Text>
                      {row.totalPrice
                        ? Number(row.totalPrice).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })
                        : "0"}
                    </Text>
                  </TableCell>
                  <TableCell sx={styles.tableCell}>
                    <Text>
                      {row.totalPriceAll && row.totalPrice
                        ? Number(
                            Number(row.totalPriceAll) - Number(row.totalPrice)
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })
                        : "0"}
                    </Text>
                  </TableCell>
                  <TableCell sx={styles.tableCell}>
                    <Text>
                      {row.totalPriceAll
                        ? Number(row.totalPriceAll).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })
                        : "0"}
                    </Text>
                  </TableCell>
                  <TableCell sx={styles.tableCell}>
                    <Text>
                      {row.orderCost
                        ? Number(row.orderCost).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })
                        : "0"}
                    </Text>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <PageButtonNavigate
        currentPage={currentPage}
        pagesCount={pagesCount}
        startIndex={startIndex}
        endIndex={endIndex}
        dataCount={tableData.length}
        onSelectPage={(page) => {
          setCurrentPage(page);
        }}
      />
    </React.Fragment>
  ) : (
    <EmptyList />
  );
};

const EmptyList = () => {
  return (
    <View
      style={{
        flex: 1,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={emptyProduct} style={{ width: 120 }} alt={"emptyProduct"} />
      <View style={{ height: 30 }} />
      <Text
        style={{
          fontSize: "clamp(16px,2vh,20px)",
          fontWeight: "bold",
          color: "rgba(15, 16, 17, 1)",
        }}
      >
        ยังไม่มีรายการ
      </Text>
    </View>
  );
};

const styles = {
  tableContainer: {
    flexGrow: 1,
    overflowY: "scroll",
    scrollbarWidth: "thin",
    scrollbarColor: "#c7c7c7 #f5f5f5",
  },
  table: {
    border: "none",
    minWidth: 650,
  },
  tableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: "rgba(255, 255, 255, 1)",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "rgba(255, 241, 241, 1)",
    },
  },
  tableCell: {
    borderBottom: "none",
    whiteSpace: "nowrap",
    wordBreak: "break-word",
  },
  headerCell: {
    color: "rgba(165, 88, 90, 1)",
    whiteSpace: "nowrap",
  },
};

export default ReportCommissionRetailProductCount;
