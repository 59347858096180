import React, { useState } from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Paper,
} from "@mui/material";
//components
import DropdownMenuPopover from "../../../components/dropdown/DropdownPopover";
import PageButtonNavigate from "../../../components/button/PageButtonNavigate";
import { EditButton } from "../../../components/button/IconButton";
//functions
import { formatNumber } from "../../../functions/format/numberFormat";

const ProductTable = (props) => {
  const { tableHeader = [], tableData = [], isLoading = false } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const pagesCount = Math.ceil(tableData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const tableDataSliced = tableData.slice(startIndex, endIndex);

  const { onSelect } = props;

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <View style={{ width: "100%", height: "100%" }}>
      <TableContainer sx={styles.tableContainer}>
        <Table sx={styles.table}>
          <TableHead sx={{ position: "sticky", top: 0, zIndex: 9999 }}>
            <TableRow style={{ width: "100%", backgroundColor: "#FFF" }}>
              <TableCell
                sx={{ ...styles.headerCell, width: "7.17%" }}
              ></TableCell>
              <TableCell sx={{ ...styles.headerCell, width: "15.71%" }}>
                ชื่อสินค้า
              </TableCell>
              <TableCell sx={{ ...styles.headerCell, width: "10%" }}>
                รหัสสินค้า
              </TableCell>
              <TableCell sx={{ ...styles.headerCell, width: "10%" }}>
                หมวดหมู่
              </TableCell>
              <TableCell sx={{ ...styles.headerCell, width: "15.71%" }}>
                ราคา (บาท)
              </TableCell>
              <TableCell sx={{ ...styles.headerCell, width: "11.42%" }}>
                แต้มสะสม
              </TableCell>
              <TableCell sx={{ ...styles.headerCell, width: "8.57%" }}>
                ประเภท
              </TableCell>
              <TableCell sx={{ ...styles.headerCell, width: "8.57%" }}>
                จำนวน
              </TableCell>
              <TableCell sx={{ ...styles.headerCell, width: "12.85%" }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableDataSliced.map((row) => (
              <TableRow
                key={row.id}
                sx={styles.tableRow}
                onClick={() => {
                  // onSelect && onSelect(row);
                }}
              >
                <TableCell
                  sx={{
                    ...styles.tableCell,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={row.images ? row.images[0] : ""}
                    style={{
                      width: 50,
                      height: 50,
                      borderRadius: 8,
                      objectFit: "cover",
                      alignSelf: "center",
                    }}
                  />
                </TableCell>
                <TableCell sx={styles.tableCell}>
                  <Text>{row.name ? row.name : ""}</Text>
                </TableCell>
                <TableCell sx={styles.tableCell}>
                  <Text>{row.code ? row.code : ""}</Text>
                </TableCell>
                <TableCell sx={styles.tableCell}>
                  <Text>
                    {row.productCategoryName ? row.productCategoryName : ""}
                  </Text>
                </TableCell>
                <TableCell sx={styles.tableCell}>
                  <Text>
                    {row.price ? Number(row.price).toLocaleString() : "0"}
                  </Text>
                </TableCell>
                <TableCell sx={styles.tableCell}>
                  <Text>
                    {row.point ? Number(row.point).toLocaleString() : "0"}
                  </Text>{" "}
                </TableCell>
                <TableCell sx={styles.tableCell}>
                  <Text>{row.productTypeName ? row.productTypeName : ""}</Text>{" "}
                </TableCell>
                <TableCell sx={styles.tableCell}>
                  <Text>
                    {row.remainingQuantity
                      ? formatNumber(row.remainingQuantity).toLocaleString()
                      : "0"}
                  </Text>{" "}
                </TableCell>
                <TableCell sx={styles.tableCell}>
                  {false && (
                    <DropdownMenuPopover title={"กำลังดำเนินการ"} list={[]} />
                  )}
                  <EditButton
                    fontSize={16}
                    color={"#0F1011"}
                    onPress={() => {
                      onSelect && onSelect(row);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <PageButtonNavigate
        currentPage={currentPage}
        pagesCount={pagesCount}
        startIndex={startIndex}
        endIndex={endIndex}
        dataCount={tableData.length}
        onSelectPage={(page) => {
          setCurrentPage(page);
        }}
      />
    </View>
  );
};

const styles = {
  tableContainer: {
    overflowY: "scroll",
    scrollbarWidth: "thin",
    scrollbarColor: "#c7c7c7 #f5f5f5",
    flex: 1,
  },
  table: {
    border: "none",
    minWidth: 650,
  },
  tableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: "rgba(255, 255, 255, 1)",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "rgba(255, 241, 241, 1)",
    },
  },
  tableCell: {
    borderBottom: "none",
    whiteSpace: "nowrap",
    wordBreak: "break-word",
  },
  headerCell: {
    color: "rgba(165, 88, 90, 1)",
  },
};

export default ProductTable;
