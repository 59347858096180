import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import { Grid } from "@mui/material";
import { Divider } from "rsuite";
import { useNavigate } from "react-router-dom";
//component
import Button from "../../components/button/Button";
import Dropdown from "../../components/dropdown/Dropdown";
import GridContainerReward from "./componnent/GridContainerReward";
import apiPath from "../../services/configs/apiPath";
import { get, post } from "../../functions/fetch/fetch";
import { useDialog } from "../../components/modal/Popup";
import moment from "moment-timezone";
import Reward from "./Reward";
import PopupGetReward from "./componnent/PopupGetReward";

const MyReward = () => {
  const dialog = useDialog();
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [myCashback, setMyCashback] = useState(0);
  const [viewContent, setViewContent] = useState(null);

  useEffect(() => {
    const downloadAll = async () => {
      await downloadMyReward();
      await downloadMyCashback();
    };
    downloadAll();
  }, []);

  const downloadMyReward = async () => {
    let url = apiPath.reward.getMyReward;
    let { status, response } = await get(url, {}, true);
    if (!status) {
      dialog.alert({ message: response.data.error });
      return;
    }
    if (response.data) {
      let init = [];
      response.data.forEach((i) => {
        console.log(i);
        init.push({
          ...i,
          name: i.reward_name,
          image: i.reward_image,
          type: i.reward_type_name,
          display_condition_open_date: moment(i.condition_open).format(
            "DD/MM/YYYY"
          ),
          display_condition_close_date: moment(i.condition_close).format(
            "DD/MM/YYYY"
          ),
          qualify_rank_name: i.ranks.map((item) => item.name),
        });
      });
      console.log(init);
      setList(init);
    }
  };

  const downloadMyCashback = async () => {
    let url = apiPath.reward.getCashbackAmount;
    const { status, response } = await get(url, {}, true);
    if (!status) {
      return;
    }
    if (response.data) {
      setMyCashback(response.data);
    }
  };

  return (
    <React.Fragment>
      {viewContent ? (
        <PopupGetReward
          data={viewContent}
          onClose={() => {
            setViewContent(null);
            downloadMyReward();
          }}
        />
      ) : null}
      <View style={Styles.container}>
        <Grid container style={Styles.topContainer} spacing={2}>
          <Grid item xs={12} md={8} lg={9} xl={10}>
            <View style={{ flex: 1 }}>
              <Text style={Styles.textTopContainer1}>รางวัลของฉัน</Text>
              <Text style={Styles.textTopContainer2}>
                ของรางวัล / รางวัลของฉัน
              </Text>
            </View>
          </Grid>
        </Grid>
        <View style={{ height: 30 }} />
        <View style={Styles.contentContainer}>
          <Grid container spacing={2} style={{ marginBottom: 15 }}>
            <Grid item xs={12} lg={4} style={{ zIndex: 10 }}>
              <View style={{ height: 8 }} />
              <Dropdown
                placeholder={"ของรางวัล"}
                list={[]}
                onChange={(values) => {
                  const { value, label } = values;
                  console.log(value, label);
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4} style={{ zIndex: 10 }}>
              <View style={{ height: 8 }} />
              <Dropdown
                placeholder={"ข้อมูลตัวแทน"}
                list={[]}
                onChange={(values) => {
                  const { value, label } = values;
                  console.log(value, label);
                }}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <View style={{ height: 8 }} />
              <Button
                buttonText={"ค้นหา"}
                color={"primary"}
                style={{ width: "100%" }}
                styleText={Styles.topButtonText}
                onClick={() => {}}
              />
            </Grid>
          </Grid>
          <Divider />

          <Text style={Styles.textTitleCredit}>จำนวนแคชแบ็คที่ได้รับสะสม</Text>
          <View
            style={{
              flexDirection: "row",
              alignItems: "flex-end",
            }}
          >
            <Text style={Styles.textValueCredit}>
              {Number(myCashback).toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })}{" "}
            </Text>
            <Text style={Styles.textMarkCredit}> บาท</Text>
          </View>
          <View style={{ height: 20 }} />
          <Text style={Styles.textTotalReward}>
            {" "}
            ของรางวัลที่ได้ทั้งหมด {list.length} รางวัล
          </Text>
          <View style={{ height: 20 }} />

          <GridContainerReward
            data={list}
            disableOption={true}
            showButton={false}
            onSelect={(value) => {
              setViewContent({
                ...value,
                id: value.reward_id,
                isViewOnly: true,
              });
            }}
          />
        </View>
        <View style={{ height: 100 }} />
      </View>
    </React.Fragment>
  );
};

const Styles = {
  container: {
    flex: 1,
    height: "100vh",
    padding: "1.5%",
  },
  topContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  contentContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  textTopContainer1: {
    fontSize: "clamp(18px, 3vw, 22px)",
    fontWeight: "bold",
    color: "#AA4942",
  },
  textTopContainer2: {
    fontSize: "clamp(12px, 3vw, 16px)",
    color: "#696D79",
  },
  topButtonText: {
    fontSize: "clamp(14px, 3vw, 18px)",
  },
  titleSearch: {
    fontSize: 20,
    color: "#0F1011",
  },
  subTitleSearch: {
    fontSize: 16,
    color: "#0F1011",
    marginTop: 8,
  },
  textTitleCredit: {
    fontSize: 18,
    color: "#0F1011",
  },
  textValueCredit: {
    fontSize: 36,
    fontWeight: "bold",
    color: "#0F1011",
  },
  textMarkCredit: {
    fontSize: 18,
    color: "#0F1011",
    lineHeight: 42,
  },
  textTotalReward: {
    fontSize: 16,
    color: "#0F1011",
    lineHeight: 42,
  },
};
export default MyReward;
