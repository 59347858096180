import React, { useEffect, useState } from "react";
import { Text, View } from "react-native-web";
import { Grid } from "@mui/material";
import { Divider } from "rsuite";
//component
import Button from "../../components/button/Button";
import SearchBox from "../../components/textField/SearchBox";
import DatePicker from "../../components/calendar/DatePicker";
import Dropdown from "../../components/dropdown/Dropdown";
import TableView from "../../components/table/TableView";
import SwitchMenu from "../../components/switch/SwitchMenu";
//images
import emptyProduct from "../../asset/images/product/inventory/emptyProduct.png";
//function
import { downloadAllCustomerSales } from "../../services/api/sales/sales";
import AgentFreeCourseTable from "./component/AgentFreeCourseTable";

const AgentFreeCourseList = (props) => {
  const [customerSalesList, setCustomerSalesList] = useState([]);
  const [page, setPage] = useState(1);
  const maxCountPerPage = 10;

  useEffect(() => {
    const downloadAllData = async () => {
      let countrys = await downloadAllCustomerSales();
      let initCustomerSales = [];
      console.log(countrys);
      countrys.forEach((item) => {
        initCustomerSales.push(item);
      });
      setCustomerSalesList(initCustomerSales);
    };
    downloadAllData();
  }, []);

  return (
    <View style={Styles.container}>
      <Grid container style={Styles.topContainer} spacing={2}>
        <Grid item xs={12} md={8} lg={9} xl={10}>
          <View style={{ flex: 1 }}>
            <Text style={Styles.textTopContainer1}>
              รายการสมาชิกที่เรียนฟรี
            </Text>
            <Text style={Styles.textTopContainer2}>
              สมาชิก / รายการสมาชิกที่เรียนฟรี
            </Text>
          </View>
        </Grid>
      </Grid>
      <View style={{ height: 30 }} />
      <View style={Styles.contentContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <SearchBox placeholder={"ค้นหาตัวแทน"} />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <DatePicker
              placeholder="วันที่ลงทะเบียน"
              styles={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <Button
              buttonText={"ค้นหา"}
              color={"primary"}
              style={{ width: "100%" }}
              styleText={Styles.topButtonText}
              onClick={() => {}}
            />
          </Grid>
        </Grid>

        <Divider />

        <View style={{ flex: 1 }}>
          <AgentFreeCourseTable tableData={[]} />
        </View>
      </View>
    </View>
  );
};

const EmptyList = () => {
  return (
    <View
      style={{
        flex: 1,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={emptyProduct} style={{ width: 120 }} alt={"emptyProduct"} />
      <View style={{ height: 30 }} />
      <Text
        style={{
          fontSize: "clamp(16px,2vh,20px)",
          fontWeight: "bold",
          color: "rgba(15, 16, 17, 1)",
        }}
      >
        ยังไม่มีรายการขายปลีก
      </Text>
      <Text
        style={{
          fontSize: "clamp(12px,2vh,16px)",
          color: "rgba(105, 109, 121, 1)",
          marginTop: 8,
        }}
      >
        กดปุ่ม ‘เพิ่มรายการขายปลีก’ เพื่อเพิ่มรายการสินค้า
      </Text>
    </View>
  );
};

const Styles = {
  container: {
    flex: 1,
    height: "100vh",
    padding: "1.5%",
  },
  topContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  contentContainer: {
    width: "100%",
    flex: 1,
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  textTopContainer1: {
    fontSize: "clamp(18px, 3vw, 22px)",
    fontWeight: "bold",
    color: "#AA4942",
  },
  textTopContainer2: {
    fontSize: "clamp(12px, 3vw, 16px)",
    color: "#696D79",
  },
  topButtonText: {
    fontSize: "clamp(14px, 3vw, 18px)",
  },
};

export default AgentFreeCourseList;
