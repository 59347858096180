import moment from "moment";

///****อย่าลืมแก้ Reducer ให้ใช้แบบ combien แทนเพื่อแยกหัวข้อระบบต่างๆ*/
let initial = {
  user: null,
  page: "home",
  subPage: "",
  setupData: {
    masterData: {
      phoneCodeList: {},
      countryList: {},
      rankingList: [],
      bankList: {},
    },
  },
  showMenu: false,
  userUseRole: {},
  course: {
    contentId: null,
  },
  homePageData: {
    startDate: moment().subtract(1, "months"),
    endDate: moment(),
    selectSource: "retail",
    summary: {
      data: [],
      sumRetail: 0,
      sumOrder: 0,
    },
  },
};

const State = (state = Object.assign({}, initial), action) => {
  const nextState = Object.assign({}, state);
  switch (action.type) {
    case "SetUser":
      nextState.user = { ...action.payload };
      return nextState;
    case "SetPage":
      nextState.page = action.payload;
      return nextState;
    case "SetSubPage":
      nextState.subPage = action.payload;
      return nextState;
    case "SetRegisterAgent":
      nextState.registerAgent = { ...action.payload };
      return nextState;
    case "SetUserUseRole":
      nextState.userUseRole = { ...action.payload };
      return nextState;
    case "SETUPDATA":
      nextState.setupData = {};
      return nextState;
    case "SETUPDATA/MASTERDATA":
      nextState.setupData.masterData = {};
      return nextState;
    case "SETUPDATA/MASTERDATA/PHONE_CODE_LIST":
      nextState.setupData.masterData.phoneCodeList = action.payload;
      return nextState;
    case "SETUPDATA/MASTERDATA/COUNTRY_LIST":
      nextState.setupData.masterData.countryList = action.payload;
      return nextState;
    case "SETUPDATA/MASTERDATA/RANKING_LIST":
      nextState.setupData.masterData.rankingList = action.payload;
      return nextState;
    case "SETUPDATA/MASTERDATA/BANK_LIST":
      nextState.setupData.masterData.bankList = action.payload;
      return nextState;
    case "SHOWMENU":
      nextState.showMenu = action.payload;
      return nextState;
    case "SET/HOMEPAGE":
      nextState.homePageData = { ...nextState.homePageData, ...action.payload };
      return nextState;
    case "SET/HOMEPAGE/SUMMARY":
      if (!nextState.homePageData) {
        nextState.homePageData = {};
      }
      nextState.homePageData.summary = { ...action.payload };
      return nextState;
    default:
      return state;
  }
};

export default State;
