import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import { Grid } from "@mui/material";
import Button from "../../../components/button/Button";
//Images
import iphoneImg from "../../../asset/images/example/iphone.png";
import productImg from "../../../asset/images/example/product.png";
import { SlOptionsVertical } from "react-icons/sl";
import {
  AgentIcon,
  CalendarRewardIcon,
  EventIcon,
} from "../../../asset/images/icons/iconsLib";
import DropdownPopover from "../../../components/dropdown/DropdownPopover";

const GridContainerReward = (props) => {
  const {
    data = [],
    onSelect,
    disableOption = false,
    showButton = false,
    onOption,
  } = props;
  return (
    <Grid container spacing={2}>
      {data.map((item) => {
        return (
          <RewardContainer
            data={item}
            disableOption={disableOption}
            showButton={showButton}
            onSelect={(value) => {
              onSelect && onSelect(value);
            }}
            onOption={(values) => {
              onOption && onOption(values);
            }}
          />
        );
      })}
    </Grid>
  );
};

const RewardContainer = (props) => {
  const { data = {}, disableOption, showButton, onOption } = props;
  const { onSelect } = props;

  const StylesReward = {
    container: {
      padding: 20,
      boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
      borderRadius: 8,
    },
    optionButton: {
      borderRadius: "50%",
      width: 25,
      height: 25,
      backgroundColor: "rgba(15, 16, 17, 0.5)",
      padding: 4,
      justifyContent: "center",
      alignItems: "center",
    },
    img: {
      width: "100%",
      height: 200,
      objectFit: "cover",
    },
    textTitle: {
      fontSize: 20,
      fontWeight: "bold",
      color: "rgba(15, 16, 17, 1)",
      marginTop: 10,
    },
    text: {
      flex: 1,
      color: "rgba(15, 16, 17, 1)",
      marginLeft: 10,
    },
    textContainer: {
      flex: 1,
      flexDirection: "row",
      marginTop: 10,
      justifyContent: "center",
      alignItems: "center",
    },
  };

  return (
    <Grid item xs={12} md={4} xl={3}>
      <View style={{ ...StylesReward.container, zIndex: 0 }}>
        {!disableOption ? (
          <View
            style={{ position: "absolute", top: 15, right: 15, zIndex: 10 }}
          >
            <DropdownPopover
              list={[
                { label: "แก้ไข", value: "edit" },
                { label: "ลบ", value: "delete" },
              ]}
              customIcon={
                <View style={{ ...StylesReward.optionButton, zIndex: 10 }}>
                  <SlOptionsVertical
                    style={{ fontSize: 15, color: "#FFFFFF" }}
                  />
                </View>
              }
              showIconOnly={true}
              onSelect={(values) => {
                onOption && onOption({ option: values, data: data });
              }}
            />
          </View>
        ) : null}
        <TouchableOpacity
          onPress={() => {
            onSelect && onSelect(data);
          }}
        >
          <img
            style={StylesReward.img}
            alt={"contentImage"}
            src={data ? data.image : ""}
          />
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            onSelect && onSelect(data);
          }}
        >
          <Text style={StylesReward.textTitle}>{data ? data.name : ""}</Text>
        </TouchableOpacity>

        <View style={{ height: 10 }} />
        <StatusTag title={data ? data.type : ""} />
        <View style={{ height: 10 }} />
        <View style={StylesReward.textContainer}>
          <CalendarRewardIcon />
          <Text style={StylesReward.text}>
            {data
              ? `${data.display_condition_open_date} - ${data.display_condition_close_date}`
              : ""}
          </Text>
        </View>
        <View style={StylesReward.textContainer}>
          <EventIcon />
          <Text style={StylesReward.text}>
            {data ? Number(data.ticket_use).toLocaleString() : 0} Ticket
          </Text>
        </View>
        <View style={StylesReward.textContainer}>
          <AgentIcon />
          <View style={{ flex: 1, marginLeft: 10 }}>
            <Text style={{}}>
              {data && Array.isArray(data.qualify_rank_name)
                ? data.qualify_rank_name.join(", ")
                : "Data not available"}
            </Text>
          </View>
        </View>
        {showButton ? (
          <Button
            buttonText={data.disable ? "หมดเวลา" : "แลกรางวัล"}
            color={data.disable ? "disable" : "primary"}
            onClick={() => {
              onSelect && onSelect(data);
            }}
            style={{ zIndex: 20, marginTop: 20 }}
          />
        ) : null}
      </View>
    </Grid>
  );
};

const StatusTag = (props) => {
  const {
    title = "",
    color = "rgba(255, 228, 187, 1)",
    colorText = "rgba(241, 152, 19, 1)",
  } = props;
  return (
    <View
      style={{
        width: 100,
        height: 20,
        backgroundColor: color,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 8,
      }}
    >
      <Text
        style={{
          fontSize: 12,
          fontWeight: "bold",
          color: colorText,
        }}
      >
        {title}
      </Text>
    </View>
  );
};

const Styles = {};
export default GridContainerReward;
