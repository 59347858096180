import React, { useState } from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import moment from "moment-timezone";
//components
import DropdownMenuPopover from "../../../components/dropdown/DropdownPopover";
import PageButtonNavigate from "../../../components/button/PageButtonNavigate";
import EmptyList from "../../../components/pages/EmptyList";
//images
import noImage from "../../../asset/images/register/user.png";
//functions
import { isAdmin } from "../../../functions/validate/validate";
import { useSelector } from "react-redux";

const AgentListTable = (props) => {
  const { tableHeader = [], tableData = [] } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const userInfo = useSelector((state) => {
    return state.user;
  });

  const pagesCount = Math.ceil(tableData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const tableDataSliced = tableData.slice(startIndex, endIndex);

  const { onSelect } = props;

  const renderMenu = (row) => {
    let init = [];
    if (!row) {
      return init;
    }
    if (row.active === "Y" || row.active === "W") {
      init.push({ label: "ระงับการใช้งาน", value: "inActiveUser" });
    } else if (row.active === "N") {
      init.push({ label: "เปิดสิทธิใช้งาน", value: "activeUser" });
    }
    if (row.active !== "D") {
      init.push({ label: "แก้ไขข้อมูล", value: "edit" });
      init.push({ label: "ลบผู้ใช้งาน", value: "delete" });
      init.push({ label: "ย้ายทีม", value: "moveTeam" });
    }
    return init;
  };

  const renderActive = (row) => {
    switch (row.active) {
      case "Y":
        return "ปกติ";
      case "N":
        return "ระงับการใช้งาน";
      case "W":
        return "ยังไม่เปิดรายการขาย";
      case "D":
        return "ลบผู้ใช้งาน";
      default:
        return "-";
    }
  };

  return (
    <React.Fragment>
      {tableDataSliced.length > 0 ? (
        <View style={{ width: "100%", height: "100%" }}>
          <TableContainer sx={styles.tableContainer}>
            <Table sx={styles.table}>
              <TableHead sx={{ position: "sticky", top: 0, zIndex: 9999 }}>
                <TableRow style={{ width: "100%", backgroundColor: "#FFF" }}>
                  <TableCell sx={{ ...styles.headerCell }}></TableCell>
                  <TableCell sx={{ ...styles.headerCell }}>
                    รหัสตัวแทน
                  </TableCell>
                  <TableCell sx={{ ...styles.headerCell }}>
                    ชื่อ - นามสกุล
                  </TableCell>
                  <TableCell sx={{ ...styles.headerCell }}>
                    พิกัดนัดรับสินค้า
                  </TableCell>
                  <TableCell sx={{ ...styles.headerCell }}>
                    ระดับตัวแทน
                  </TableCell>
                  <TableCell sx={{ ...styles.headerCell }}>
                    ระดับตัวแทนตามไตรมาส
                  </TableCell>
                  <TableCell sx={{ ...styles.headerCell }}>
                    ยอดสะสมสั่งซื้อ
                  </TableCell>
                  <TableCell sx={{ ...styles.headerCell }}>คะแนนสะสม</TableCell>
                  <TableCell sx={{ ...styles.headerCell }}>
                    คะแนนกิจกรรม
                  </TableCell>
                  <TableCell sx={{ ...styles.headerCell }}>รูปแบบ</TableCell>
                  <TableCell sx={{ ...styles.headerCell }}>
                    วันที่/เวลา ลงทะเบียน
                  </TableCell>
                  <TableCell sx={{ ...styles.headerCell }}>สถานะ</TableCell>
                  {userInfo && isAdmin(userInfo) ? (
                    <TableCell sx={{ ...styles.headerCell }}>
                      ตัวเลือก
                    </TableCell>
                  ) : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableDataSliced.map((row) => (
                  <TableRow key={row.id} sx={styles.tableRow}>
                    <TableCell
                      sx={{
                        ...styles.tableCell,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={row.imageProfile ? row.imageProfile : noImage}
                        style={{
                          width: 50,
                          height: 50,
                          borderRadius: 8,
                          objectFit: "cover",
                          alignSelf: "center",
                        }}
                        alt={"imageProfile"}
                      />
                    </TableCell>
                    <TableCell sx={styles.tableCell}>
                      <TouchableOpacity
                        onPress={() => {
                          onSelect && onSelect(row);
                        }}
                      >
                        <Text>{row.agentCode ? row.agentCode : ""}</Text>
                      </TouchableOpacity>
                    </TableCell>
                    <TableCell sx={styles.tableCell}>
                      {" "}
                      <Text>{row.name ? row.name : ""}</Text>
                    </TableCell>
                    <TableCell sx={styles.tableCell}>
                      <Text>
                        {row.locationProduct ? row.locationProduct : "-"}
                      </Text>
                    </TableCell>{" "}
                    <TableCell sx={styles.tableCell}>
                      <Text>ยังไม่จัดอันดับ</Text>
                    </TableCell>{" "}
                    <TableCell sx={styles.tableCell}>
                      <Text>ยังไม่จัดอันดับ</Text>
                    </TableCell>{" "}
                    <TableCell sx={styles.tableCell}>
                      <Text>{Number(row.totalOrder).toLocaleString()}</Text>
                    </TableCell>{" "}
                    <TableCell sx={styles.tableCell}>
                      <Text>{Number(row.totalPoint).toLocaleString()}</Text>
                    </TableCell>{" "}
                    <TableCell sx={styles.tableCell}>
                      <Text>0</Text>
                    </TableCell>{" "}
                    <TableCell sx={styles.tableCell}>
                      <Text>สต็อกสินค้า</Text>
                    </TableCell>{" "}
                    <TableCell sx={styles.tableCell}>
                      <Text>
                        {moment(row.createDate).format("DD/MM/YYYY HH:mm")}
                      </Text>
                    </TableCell>{" "}
                    <TableCell sx={styles.tableCell}>
                      <Text>{renderActive(row)}</Text>
                    </TableCell>
                    {userInfo && isAdmin(userInfo) ? (
                      <TableCell sx={styles.tableCell}>
                        {row.active !== "D" ? (
                          <DropdownMenuPopover
                            title={"ตัวเลือก"}
                            list={renderMenu(row)}
                            onSelect={(value) => {
                              onSelect &&
                                onSelect({ data: row, option: value });
                            }}
                          />
                        ) : null}
                      </TableCell>
                    ) : null}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <PageButtonNavigate
            currentPage={currentPage}
            pagesCount={pagesCount}
            startIndex={startIndex}
            endIndex={endIndex}
            dataCount={tableData.length}
            onSelectPage={(page) => {
              setCurrentPage(page);
            }}
          />
        </View>
      ) : (
        <EmptyList title={"ยังไม่มีรายการตัวแทน"} />
      )}
    </React.Fragment>
  );
};

const styles = {
  tableContainer: {
    overflowY: "scroll",
    scrollbarWidth: "thin",
    scrollbarColor: "#c7c7c7 #f5f5f5",
    flex: 1,
  },
  table: {
    border: "none",
    minWidth: 650,
  },
  tableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: "rgba(255, 255, 255, 1)",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "rgba(255, 241, 241, 1)",
    },
  },
  tableCell: {
    borderBottom: "none",
    whiteSpace: "nowrap",
    wordBreak: "break-word",
  },
  headerCell: {
    color: "rgba(165, 88, 90, 1)",
    whiteSpace: "nowrap",
  },
};

export default AgentListTable;
