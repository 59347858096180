import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import { Grid } from "@mui/material";
import { Divider } from "rsuite";
import { useNavigate } from "react-router-dom";
//component
import Button from "../../components/button/Button";
import Dropdown from "../../components/dropdown/Dropdown";
import SwitchMenuIcon from "../../components/switch/SwitchMenuIcon";
import SwitchMenu from "../../components/switch/SwitchMenu";
import SearchBox from "../../components/textField/SearchBox";
import CourseInfoBox from "./component/CourseInfoBox";
import ContentMain from "./component/ContentMain";
import ReportOrderTable from "../report/component/ReportOrderTable";
import ContentListTable from "./component/ContentListTable";
import ContentListGrid from "./component/ContentListGrid";
import ContentList from "./component/ContentList";
import PopupAddFileCourse from "./component/PopupAddFileCourse";
import apiPath from "../../services/configs/apiPath";
import { get } from "../../functions/fetch/fetch";
import { useSelector } from "react-redux";

const CourseList = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => {
    return state;
  });
  //state
  const [showContent, setShowContent] = useState(false);
  const [selectHeader, setSelectHeader] = useState(1);
  const [addFolder, setAddFolder] = useState(false);
  const [folders, setFolders] = useState([]);
  const [folderData, setFolderData] = useState({});
  const [contentFile, setContentFile] = useState([]);
  const [editContentFile, setEditContentFile] = useState(null);
  const [contentLoad, setContentLoad] = useState(false);
  const [header, setHeader] = useState([]);
  useEffect(() => {
    downloadAllDoc();
  }, []);

  useEffect(() => {
    downloadFolderAll();
  }, [selectHeader]);

  const downloadAllDoc = async () => {
    let url = apiPath.university.getListDocType;
    let { status, response } = await get(url, {}, true);
    if (!status) {
      return;
    }
    if (response.data) {
      let init = [];
      response.data.forEach((item) => {
        init.push({ label: item.docs_name, value: item.id });
      });
      setHeader(init);
    }
  };

  const downloadFolderAll = async () => {
    let url = apiPath.university.getFolderAll;
    let { status, response } = await get(url, {}, true);
    if (!status) {
      return;
    }
    if (response.data) {
      setFolders(response.data);
    }
  };

  const downloadContentFile = async (data, forEdit = false) => {
    let url = `${apiPath.university.getFileInFolder}?folder_id=${data.id}`;
    let { status, response } = await get(url, {}, true);
    if (!status) {
      return;
    }
    if (response.data) {
      setContentFile(response.data);
      setFolderData(data);
      setContentLoad(true);
      if (forEdit) {
        setEditContentFile(response.data);
      }
    }
  };

  return (
    <React.Fragment>
      {addFolder || editContentFile ? (
        <PopupAddFileCourse
          docId={selectHeader}
          editData={editContentFile}
          onClose={() => {
            downloadFolderAll();
            setAddFolder(false);
            setEditContentFile(null);
          }}
        />
      ) : null}
      <View style={Styles.container}>
        <Grid container style={Styles.topContainer} spacing={2}>
          <Grid item xs={12} md={8} lg={9} xl={10}>
            <View style={{ flex: 1 }}>
              <Text style={Styles.textTopContainer1}>คลังข้อมูล</Text>
              <Text style={Styles.textTopContainer2}>
                Rosegold university / คลังข้อมูล
              </Text>
            </View>
          </Grid>
        </Grid>
        <View style={{ height: 30 }} />
        <View style={Styles.contentContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8} lg={6}>
              <SwitchMenu
                height={40}
                titles={["ตัวแทน", "บริษัท", "ผลิตภัณฑ์"]}
                onSelect={(response) => {
                  const { title } = response;
                  if (title === "ตัวแทน") {
                    setSelectHeader(1);
                  } else if (title === "บริษัท") {
                    setSelectHeader(2);
                  } else if (title === "ผลิตภัณฑ์") {
                    setSelectHeader(3);
                  }
                  setContentFile([]);
                  setShowContent(false);
                }}
                selectColor={{ text: "#AA4942", background: "#FFD7D7" }}
                unSelectColor={{ text: "#0F1011", background: "" }}
              />
            </Grid>
          </Grid>
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} lg={3}>
              <SearchBox />
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                buttonText={"ค้นหา"}
                color={"primary"}
                style={{ width: "100%" }}
                styleText={Styles.topButtonText}
                onClick={() => {}}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                buttonText={"เพิ่มไฟล์"}
                color={"primary"}
                style={{ width: "100%" }}
                styleText={Styles.topButtonText}
                isBorder={true}
                onClick={() => {
                  setAddFolder(true);
                }}
              />
            </Grid>
          </Grid>
          <View style={{ height: 40 }} />
          <View style={{ flex: 1 }}>
            {showContent ? (
              <ContentList
                folderData={folderData}
                data={contentFile}
                contentLoad={contentLoad}
                onBack={() => {
                  setShowContent(false);
                  setContentFile([]);
                  setFolderData({});
                  setContentLoad(false);
                }}
              />
            ) : (
              <ContentMain
                docId={selectHeader}
                data={folders}
                onSelect={(data) => {
                  setShowContent(true);
                  downloadContentFile(data);
                }}
                onRefresh={() => {
                  console.log("onRefresh");
                  downloadFolderAll();
                }}
                onEdit={(data) => {
                  downloadContentFile(data, true);
                }}
              />
            )}
          </View>
        </View>
      </View>
    </React.Fragment>
  );
};

const Styles = {
  container: {
    flex: 1,
    minHeight: "100vh",
    height: "100%",
    padding: "1.5%",
  },
  topContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  textTopContainer1: {
    fontSize: "clamp(18px, 3vw, 22px)",
    fontWeight: "bold",
    color: "#AA4942",
  },
  textTopContainer2: {
    fontSize: "clamp(12px, 3vw, 16px)",
    color: "#696D79",
  },
  topButtonText: {
    fontSize: "clamp(14px, 3vw, 18px)",
  },
  titleSearch: {
    fontSize: 20,
    color: "#0F1011",
  },
  subTitleSearch: {
    fontSize: 16,
    color: "#0F1011",
    marginTop: 8,
  },
};
export default CourseList;
