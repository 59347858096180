import React, { useCallback } from "react";
import PopupBackdrop from "../../../components/modal/PopupBackdrop";
import { View, Text, TouchableOpacity } from "react-native-web";
import { Divider } from "rsuite";
import { Grid } from "@mui/material";
import TextField from "../../../components/textField/TextField";
import Button from "../../../components/button/Button";
import { useNavigate } from "react-router-dom";
import CloseButton from "../../../components/button/CloseButton";

const PopupURLCustomerPay = (props) => {
  const navigate = useNavigate();
  const { onClose } = props;
  const { url = "" } = props;
  const hostname = window.location.hostname;

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(`${hostname}/salespayment/${url}`);
  }, []);

  const handleNavigate = useCallback(() => {
    if (url) {
      navigate("/shoppingMyList");
      const newWindow = window.open(
        `/salespayment/${url}`,
        "_blank",
        "noopener,noreferrer"
      );
      if (newWindow) newWindow.opener = null;
    }
  }, []);

  return (
    <PopupBackdrop>
      <View style={Styles.box}>
        <View style={{ position: "absolute", top: 15, right: 15 }}>
          <CloseButton
            color={"#999999"}
            onPress={() => {
              onClose && onClose();
            }}
          />
        </View>
        <Text
          style={{
            fontSize: 20,
            fontWeight: "bold",
            color: "rgba(170, 73, 66, 1)",
            marginBottom: 20,
          }}
        >
          URL สำหรับส่งให้ลูกค้า
        </Text>
        <TextField value={`${hostname}/salespayment/${url}`} />
        <View style={{ width: "100%", flexDirection: "row", marginTop: 20 }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={6}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                color={"primary"}
                buttonText="คัดลอก URL"
                isBorder={true}
                style={{ width: "100%" }}
                onClick={() => {
                  handleCopy();
                }}
              />
            </Grid>
            <Grid
              item
              xs={6}
              alignItems={"center"}
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              <Button
                color={"primary"}
                buttonText="ไปที่หน้าชำระเงิน"
                style={{ width: "100%" }}
                onClick={() => {
                  handleNavigate();
                }}
              />
            </Grid>
          </Grid>
        </View>
      </View>
    </PopupBackdrop>
  );
};

const Styles = {
  box: {
    width: "90vw",
    padding: 20,
    maxWidth: 600,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFF",
    borderRadius: 16,
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.08,
    shadowRadius: 16,
  },
};

export default PopupURLCustomerPay;
