import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import { Grid } from "@mui/material";
import { Divider } from "rsuite";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import * as XLSX from "xlsx";
import moment from "moment-timezone";
//component
import Button from "../../components/button/Button";
import Dropdown from "../../components/dropdown/Dropdown";
import ReportStockTable from "./component/ReportStockTable";
import StockDisplayContainer from "./component/StockDisplayContainer";
import {
  getAllStockListByQuantity,
  getStockListByAgentCode,
} from "../../services/api/report/stock";
import { useDialog } from "../../components/modal/Popup";
import { getUserInfo } from "../../services/api/member/member";

const ReportStock = () => {
  //property
  const navigate = useNavigate();
  const dialog = useDialog();

  //state
  const {
    setupData: { masterData },
  } = useSelector((state) => {
    return state;
  });
  const [isLoading, setIsLoading] = useState(true);
  const [userInfo, setUserInfo] = useState();
  const [selectCountryCodeStore, setSelectCountryCodeStore] = useState();
  const [allStock, setAllStock] = useState([]);
  const [stockByAgent, setStockByAgent] = useState([]);

  const [limitPage, setLimitPage] = useState({
    startIndex: 1,
    limit: 10,
  });

  useEffect(() => {
    downloadUserInfo();
  }, []);

  useEffect(() => {
    if (selectCountryCodeStore) {
      downloadAll();
    }
  }, [selectCountryCodeStore, limitPage]);

  const downloadAll = async () => {
    dialog.loading();
    await downloadAllStock();
    await downloadStockListAgent();
    dialog.cancle();
    setIsLoading(false);
  };

  const downloadUserInfo = async () => {
    let uInfo = await getUserInfo();
    setSelectCountryCodeStore(
      uInfo.userRole === "A" ? uInfo.countryCodeStore : "THA"
    );
    setUserInfo(uInfo);
  };

  const downloadAllStock = async (props) => {
    let res = await getAllStockListByQuantity(selectCountryCodeStore);
    if (!res) {
      return;
    }
    if (res.length <= 0) {
      return;
    }
    let init = [];
    res.forEach((element) => {
      init.push({
        label: `${element.name} ${element.code}`,
        id: element.id,
        value: element.quantity,
      });
    });
    setAllStock(init);
  };

  const downloadStockListAgent = async (props) => {
    let res = await getStockListByAgentCode(
      selectCountryCodeStore,
      limitPage.startIndex,
      limitPage.limit
    );
    if (!res) {
      return;
    }
    setStockByAgent(res);
  };

  const downloadExcel = async () => {
    let newOrders = [];
    stockByAgent.forEach((item) => {
      newOrders.push({
        รหัสตัวแทน: item.agentCode,
        ชื่อ: item.name,
        "สินค้า (จำนวน)": item.productList,
      });
    });

    const worksheet = XLSX.utils.json_to_sheet(newOrders);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(
      workbook,
      `รายงานขายปลีก_${moment().format("DD_MM_YYYY_HHmmss")}.xlsx`
    );
  };

  return (
    <React.Fragment>
      <View style={Styles.container}>
        <Grid container style={Styles.topContainer} spacing={2}>
          <Grid item xs={12} md={8} lg={9} xl={10}>
            <View style={{ flex: 1 }}>
              <Text style={Styles.textTopContainer1}>รายงานสต๊อก</Text>
              <Text style={Styles.textTopContainer2}>รายงาน / รายงานสต๊อก</Text>
            </View>
          </Grid>
          <Grid item xs={12} md={4} lg={3} xl={2}>
            <Button
              buttonText={"ดาวน์โหลดไฟล์ Excel"}
              color={"primary"}
              style={{ width: "100%" }}
              styleText={Styles.topButtonText}
              isBorder={true}
              onClick={() => {
                downloadExcel();
              }}
            />
          </Grid>
        </Grid>
        <View style={{ height: 30 }} />
        <View style={Styles.contentContainer}>
          {userInfo && userInfo.userRole !== "A" ? (
            <React.Fragment>
              <Grid container spacing={2} style={{ marginBottom: 15 }}>
                <Grid item xs={12} lg={4} style={{ zIndex: 10 }}>
                  <View style={{ height: 8 }} />
                  <Dropdown
                    placeholder={"ประเทศคลังสินค้า"}
                    value={selectCountryCodeStore}
                    valueType={"string"}
                    list={masterData ? masterData.countryList : []}
                    onChange={(values) => {
                      const { value, label } = values;
                      setSelectCountryCodeStore(value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={4} style={{ zIndex: 9 }}>
                  <View style={{ height: 8 }} />
                  <Dropdown
                    placeholder={"รหัสตัวแทน"}
                    list={[]}
                    onChange={(values) => {
                      const { value, label } = values;
                      console.log(value, label);
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <View style={{ height: 8 }} />
                  <Button
                    buttonText={"ค้นหา"}
                    color={"primary"}
                    style={{ width: "100%" }}
                    styleText={Styles.topButtonText}
                    onClick={() => {}}
                  />
                </Grid>
              </Grid>
              <Divider />
            </React.Fragment>
          ) : null}
          <View style={{ flex: 1 }}>
            <ReportStockTable
              tableData={stockByAgent}
              isLoading={isLoading}
              onSelectPage={(values) => {
                setLimitPage({
                  startIndex: values.startIndex,
                  limit: values.limit,
                });
              }}
            />
          </View>
          <View style={{ height: 20 }} />
          <Text style={Styles.titleStock}>สินค้าในคลัง</Text>
          <Divider />
          <StockDisplayContainer items={allStock} isLoading={isLoading} />
        </View>
      </View>
    </React.Fragment>
  );
};

const Styles = {
  container: {
    flex: 1,
    height: "100vh",
    padding: "1.5%",
  },
  topContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  contentContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  textTopContainer1: {
    fontSize: "clamp(18px, 3vw, 22px)",
    fontWeight: "bold",
    color: "#AA4942",
  },
  textTopContainer2: {
    fontSize: "clamp(12px, 3vw, 16px)",
    color: "#696D79",
  },
  topButtonText: {
    fontSize: "clamp(14px, 3vw, 18px)",
  },
  titleStock: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#0F1011",
  },
};
export default ReportStock;
