import React from "react";
import { View, Text, Touchable, TouchableOpacity } from "react-native-web";
import { Divider } from "rsuite";
import { Grid } from "@mui/material";
//images
import { CalendarRewardIcon } from "../../../asset/images/icons/iconsLib";
import productImg from "../../../asset/images/example/product.png";
import { SlOptionsVertical } from "react-icons/sl";
import DropdownPopover from "../../../components/dropdown/DropdownPopover";
import OptionButton from "../../../components/button/OptionButton";

const BannerContainer = (props) => {
  return (
    <View>
      <Text style={Styles.titleTextHead}>ตั้งค่าเว็บไซต์</Text>
      <Divider />
      <Grid container spacing={2}>
        <BannerBox />
        <BannerBox />
        <BannerBox />
        <BannerBox />
      </Grid>
    </View>
  );
};

const BannerBox = () => {
  const StylesCourse = {
    container: {
      padding: 15,
      boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
      borderRadius: 8,
      maxHeight: 220,
      overflow: "hidden",
    },
    content: {
      flexDirection: "row",
    },
    img: {
      width: "100%",
      height: 190,
      objectFit: "cover",
      borderRadius: 8,
    },
    rightContent: {
      flex: 0.6,
      paddingLeft: 10,
      paddingRight: 5,
      paddingBottom: 10,
    },
    textTitle: {
      fontSize: 16,
      color: "rgba(15, 16, 17, 1)",
      fontWeight: "bold",
      marginRight: 5,
    },
    optionIcon: {
      fontSize: 15,
      color: "rgba(105, 109, 121, 1)",
      position: "absolute",
      top: 5,
      right: -5,
    },
    calendarContainer: {
      flexDirection: "row",
      marginTop: 10,
    },
    contentText: {
      fontSize: 10,
    },
  };

  return (
    <Grid item xs={12} md={6} xl={4}>
      <View style={StylesCourse.container}>
        <View style={StylesCourse.content}>
          <View style={{ flex: 0.6 }}>
            <img style={StylesCourse.img} alt={"image"} src={productImg} />
          </View>
          <View style={{ width: 10 }} />
          <View style={StylesCourse.rightContent}>
            <Text style={StylesCourse.textTitle}>
              เคล็ด (ไม่)ลับการขายให้ปัง ฉบับ Rosegold
            </Text>
            <View style={StylesCourse.optionIcon}>
              <OptionButton
                styles={Styles.optionIcon}
                title={"ตัวเลือก"}
                list={[
                  {
                    label: "เปิดสิทธิใช้งาน",
                    value: "activeUser",
                  },
                  { label: "แก้ไขข้อมูล", value: "edit" },
                ]}
                onSelect={(value) => {}}
              />
            </View>

            <View style={StylesCourse.calendarContainer}>
              <CalendarRewardIcon />
              <Text>12/11/2022</Text>
            </View>
            <View style={{ height: 10 }} />
            <View
              style={{
                width: "100%",
                height: 70,
                overflow: "hidden",
              }}
            ></View>
          </View>
        </View>
      </View>
    </Grid>
  );
};

const Styles = {
  titleTextHead: {
    fontSize: "clamp(16px,2vh,20px)",
    fontWeight: "bold",
    color: "rgba(15, 16, 17, 1)",
  },
};

export default BannerContainer;
