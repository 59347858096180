import React from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import { Grid, Typography } from "@mui/material";
import { Divider } from "rsuite";
import parse from "react-html-parser";

//images
import { SlOptionsVertical } from "react-icons/sl";
import { CalendarRewardIcon } from "../../../asset/images/icons/iconsLib";
import productImg from "../../../asset/images/example/product.png";
import TopicContainer from "./TopicContainer";
import SearchBox from "../../../components/textField/SearchBox";
import Button from "../../../components/button/Button";
const TopicListContainer = (props) => {
  return (
    <View style={Styles.container}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          <SearchBox placeholder={"ชื่อคลิปวิดีโอ"} />
        </Grid>
        <Grid item xs={12} md={2} lg={2}>
          <Button
            buttonText={"ค้นหา"}
            color={"primary"}
            style={{ width: "100%" }}
            styleText={Styles.topButtonText}
            onClick={() => {}}
          />
        </Grid>
      </Grid>
      <View style={{ height: 40 }} />
      <Grid container spacing={2}>
        <TopicContainer />
      </Grid>
    </View>
  );
};

const Styles = {
  container: {
    marginTop: 0,
  },
  title: {},
};

export default TopicListContainer;
