const apiURL = process.env["REACT_APP_API_URL"];

const apiPath = {
  auth: {
    ping: `${apiURL}/auth/ping`,
    getInfo: `${apiURL}/auth/get_info`,
    login: `${apiURL}/auth/login`,
    renewToken: `${apiURL}/auth/renew_token`,
    userRole: `${apiURL}/auth/user_role_list`,
    register: `${apiURL}/auth/register`,
    registerAdmin: `${apiURL}/auth/register_admin`,
    getUserUseRole: `${apiURL}/auth/get_user_use_role`,
    getProfileRegister: `${apiURL}/auth/get_profile_register`,
    checkUserMirgation: `${apiURL}/auth/check_user_mirgation`,
    createOtpForRegister: `${apiURL}/auth/create_otp_for_register`,
    verifyOtp: `${apiURL}/auth/verify_otp`,
  },
  products: {
    getAllProductCategory: `${apiURL}/product/get_product_category_list`,
    getAllProductType: `${apiURL}/product/get_product_type_list`,
    getAllProductStatus: `${apiURL}/product/get_product_status_list`,
    addProduct: `${apiURL}/product/add_product`,
    updateProduct: `${apiURL}/product/update_product`,
    getAllProducts: `${apiURL}/product/get_product_list`,
    getAllProductsFromAgentCode: `${apiURL}/product/get_product_list_from_agent_code`,
    getProductListOrder: `${apiURL}/product/get_product_list_order`,
    getProductListRetail: `${apiURL}/product/get_product_list_retail`,
    changeProductActive: `${apiURL}/product/change_product_active`,
    getProductInventory: `${apiURL}/product/get_product_inventory`,
  },
  uploads: {
    upload: `${apiURL}/uploads/upload`,
    uploads: `${apiURL}/uploads/uploads`,
  },
  masterData: {
    getAllProvince: `${apiURL}/masterdata/province_list`,
    getAllDistrict: `${apiURL}/masterdata/district_list`,
    getAllSubDistrict: `${apiURL}/masterdata/subdistrict_list`,
    getAllZipcode: `${apiURL}/masterdata/zipcode_list`,
    getAllCountry: `${apiURL}/masterdata/country_list`,
    getAllPhoneCode: `${apiURL}/masterdata/phone_code_list`,
    getCurrencyCode: `${apiURL}/masterdata/currency_code`,
    getPolicy: `${apiURL}/masterdata/get_policy`,
    getPolicyWithCode: `${apiURL}/masterdata/get_policy_with_code`,
    getRanking: `${apiURL}/masterdata/get_ranking_list`,
    getBanks: `${apiURL}/masterdata/get_banks_list`,
  },
  sales: {
    addCustomerSales: `${apiURL}/sales/add_customer_sale`,
    getAllCustomerSales: `${apiURL}/sales/get_customer_sales_list`,
    getPaymentMethod: `${apiURL}/sales/get_payment_method`,
    getTransportMethod: `${apiURL}/sales/get_transport_method`,
    getSummaryHome: `${apiURL}/sales/get_summary_home`,
    getSummaryProductHome: `${apiURL}/sales/get_summary_product_home`,
  },
  retail: {
    ping: `${apiURL}/retail/ping:4000`,
    calculateOrder: `${apiURL}/retail/calculate_order`,
    getRetailSalesList: `${apiURL}/retail/get_retail_sale_list`,
    getRetailCustomerList: `${apiURL}/retail/get_retail_customer_list`,
    checkAvailableProductRetail: `${apiURL}/retail/check_available_product_retail`,
    addRetail: `${apiURL}/retail/add_retail`,
    addRetailCashDelivery: `${apiURL}/retail/add_retail_cash_delivery`,
    addRetailCashDeliveryCustomer: `${apiURL}/retail/add_retail_cash_delivery_customer`,
    getRetailMyList: `${apiURL}/retail/get_retail_my_list`,
    getRetailMyAgent: `${apiURL}/retail/get_retail_my_agent`,
    getRetailMyAgentV2: `${apiURL}/retail/get_retail_my_agent_v2`,
    getTopMember: `${apiURL}/retail/get_member_teampath`,
    createRetailCustomerPayment: `${apiURL}/retail/create_retail_customer_payment`,
    getCustomerPaymentData: `${apiURL}/retail/get_customer_payment_data`,
  },
  order: {
    calculateOrder: `${apiURL}/order/calculate_order`,
    getSalesStoreList: `${apiURL}/order/get_sales_store_list`,
    getBuyerStoreList: `${apiURL}/order/get_buyer_store_list`,
    checkAvailableProductOrder: `${apiURL}/order/check_available_product_order`,
    addOrder: `${apiURL}/order/add_order`,
    addOrderOutSideTHA: `${apiURL}/order/add_order_outside_tha`,
    getOrderMyList: `${apiURL}/order/get_order_my_list`,
    getOrderMyAgentList: `${apiURL}/order/get_order_my_agent_list`,
    getOrderCodeMyList: `${apiURL}/order/get_order_code_my_list`,
    getOrderCodeMyAgentList: `${apiURL}/order/get_order_code_my_list`,
  },
  member: {
    getAllAdmin: `${apiURL}/member/admin_list`,
    getUserInfo: `${apiURL}/member/user_info`,
    getUserActiveInfo: `${apiURL}/member/user_active_info`,
    getAgentList: `${apiURL}/member/agent_list`,
    changeAdminActive: `${apiURL}/member/change_admin_active`,
    updateAdmin: `${apiURL}/member/update_admin`,
    getTeamList: `${apiURL}/member/team_list`,
    getTotalAgentInTeamPath: `${apiURL}/member/get_total_agent_team_path`,
    getAllRankingList: `${apiURL}/member/get_all_ranking_list`,
    getTeamLoginLog: `${apiURL}/member/get_team_login_log`,
    getTeamRankingReport: `${apiURL}/member/get_team_ranking_report`,
    getRankingWithPoint: `${apiURL}/member/get_ranking_with_point`,
    updateAgentActiveStatus: `${apiURL}/member/update_agent_active_status`,
    getTxUserAddress: `${apiURL}/member/get_tx_user_address`,
    insertTxUserAddress: `${apiURL}/member/insert_tx_user_address`,
    updateTxUserAddress: `${apiURL}/member/update_tx_user_address`,
    updateTxUserAddressActive: `${apiURL}/member/update_tx_user_address_active`,
    getMoveTeamInfo: `${apiURL}/member/get_move_team_info`,
    findAgent: `${apiURL}/member/find_agent`,
    moveTeam: `${apiURL}/member/move_team`,
    getUserTopRanking: `${apiURL}/member/user_top_ranking`,
    getUserRankingInfo: `${apiURL}/member/user_ranking_info`,
    createResetPassword: `${apiURL}/member/create_reset_password`,
    checkTokenResetPassword: `${apiURL}/member/check_token_reset_password`,
    changePassword: `${apiURL}/member/change_password`,
    checkUsernameExist: `${apiURL}/member/check_username_exist`,
    getAgentAddressSending: `${apiURL}/member/get_agent_address_sending`,
    patchTeamPath: `${apiURL}/member/patch_team_path`,
    getUserPatch: `${apiURL}/member/get_user_patch`,
    disableUserNotActive: `${apiURL}/member/disable_user_not_active`,
  },
  report: {
    getAllStockListByQuantity: `${apiURL}/report/get_all_stock_list_by_quantity`,
    getStockListByAgentCode: `${apiURL}/report/get_stock_list_by_agent_code`,
    getAllOrderReport: `${apiURL}/report/get_all_order_report`,
    getAllRetailReport: `${apiURL}/report/get_all_retail_report`,
    getProductStockTransactionReport: `${apiURL}/report/get_product_stock_transaction_report`,
    getProductStockOrderCount: `${apiURL}/report/get_product_stock_order_count`,
    getProductStockRetailCount: `${apiURL}/report/get_product_stock_retail_count`,
    getCommissionList: `${apiURL}/report/get_commission`,
    getComissionLog: `${apiURL}/report/get_commission_log`,
    getComissionRetailProductCount: `${apiURL}/report/get_commission_retail_product_count`,
    getCommissionDoubleCheck: `${apiURL}/report/commission/double_check_commission`,
    getRetailReportByProduct: `${apiURL}/report/commission/retail_report_by_product`,
  },
  payment: {
    createThaiQr: `${apiURL}/payment/payment_thaiqr`,
    createThaiQrCustomer: `${apiURL}/payment/payment_thaiqr_customer`,
    createPaymentCard: `${apiURL}/payment/payment_card`,
    createPaymentCardCustomer: `${apiURL}/payment/payment_card_customer`,
    paymentCheckStatus: `${apiURL}/payment/payment_check_status`,
    adminExceptPayment:`${apiURL}/payment/admin_except_payment`,
  },
  transport: {
    getTrackingStatus: `${apiURL}/transport/tracking_status`,
    getTrackingStatusCount: `${apiURL}/transport/tracking_status_count`,
    getCurrentTransportCode: `${apiURL}/transport/get_current_transport_code`,
    updateCurrentTransportCode: `${apiURL}/transport/update_current_transport_code`,
    patchTransportCost: `${apiURL}/transport/patch_transport_cost`,
  },
  university: {
    createUniversity: `${apiURL}/university/add_rosegold_university`,
    getListUniversity: `${apiURL}/university/get_list_rosegold_university`,
    getDetailUniversity: `${apiURL}/university/get_detail_rosegold_university`,
    deleteUniversity: `${apiURL}/university/del_rosegold_university`,
    updateUniversity: `${apiURL}/university/update_rosegold_university`,
    createFolder: `${apiURL}/university/create_folder`,
    updateFolder: `${apiURL}/university/update_folder`,
    addFileToFolder: `${apiURL}/university/add_file_into_folder`,
    updateFileIntoFolder: `${apiURL}/university/update_file_into_folder`,
    getListDocType: `${apiURL}/university/get_list_docs_type`,
    getFolderAll: `${apiURL}/university/get_folder`,
    getFileInFolder: `${apiURL}/university/get_file_in_folder`,
    downloadFile: `${apiURL}/university/download_file`,
    stampVdo: `${apiURL}/university/stemp_time_watch_video_class_rosegold_university_by_id`,
    getStampVdo: `${apiURL}/university/get_time_stemp_video_class_rosegold_university_by_id`,
  },
  reward: {
    getRewardTypeAll: `${apiURL}/reward/get_reward_type_all`,
    getListReward: `${apiURL}/reward/get_list_reward`,
    createReward: `${apiURL}/reward/add_reward`,
    updateReward: `${apiURL}/reward/update_reward`,
    redeemReward: `${apiURL}/reward/redeem_rewards`,
    getMyReward: `${apiURL}/reward/get_my_reward`,
    getRewardReport: `${apiURL}/reward/report_reward_in_progess`,
    confirmReward: `${apiURL}/reward/admin_confirm_redeem_reward`,
    getRewardPro1All: `${apiURL}/reward/get_reward_pro1_all`,
    getCashbackAmount: `${apiURL}/reward/get_cashback_amount`,
    remainingPointsInfo: `${apiURL}/reward/remaining_points_info`,
  },
  event: {
    createOrUpdateEvent: `${apiURL}/events/create_event_and_update`,
    getAllEvent: `${apiURL}/events/get_event_all`,
    redeemEvent: `${apiURL}/events/redeem_event`,
    getRedeemListByEventId: `${apiURL}/events/get_event_redeem_list`,
    eventCheckIn: `${apiURL}/events/redeem_event_check_in_by_redeem_event_id`,
    getEventTypeList: `${apiURL}/events/get_event_type`,
    getEventPaymentStatus: `${apiURL}/events/get_event_payment_status`,
    updateDisableStatus: `${apiURL}/events/update_disable_status`,
  },
  news: {
    getAllByMember: `${apiURL}/news/get_news`,
    createNews: `${apiURL}/news/create_news`,
    updateNews: `${apiURL}/news/update_news`,
    deleteNews: `${apiURL}/news/delete_news`,
    getNewsDetail: `${apiURL}/news/get_news_detail`,
    getNewsByAdmin: `${apiURL}/news/get_news_by_admin`,
  },
  system:{
    paymentCanDo:`${apiURL}/system/payment-can-do`,
    paymentOpen:`${apiURL}/system/payment-open`,
  }
};

export default apiPath;
