import React, { useEffect, useMemo, useState } from "react";
import { Text, View } from "react-native-web";
import { Grid } from "@mui/material";
import { Divider } from "rsuite";
import { useDispatch, useSelector } from "react-redux";
//component
import Button from "../../components/button/Button";
import SearchBox from "../../components/textField/SearchBox";
import PopupProductDetail from "./component/PopupProductDetail";
import ProductTable from "./component/ProductTable";
import ProductAddNew from "./productAddNew";
//images
import emptyProduct from "../../asset/images/product/inventory/emptyProduct.png";

//services
import {
  downloadAllProducts,
  downloadAllProductsFromAgentCode,
  downloadProductInventory,
} from "../../services/api/products/products";
import { useNavigate } from "react-router-dom";
import { getUserInfo } from "../../services/api/member/member";
import { useDialog } from "../../components/modal/Popup";
import Dropdown from "../../components/dropdown/Dropdown";

const ProductInventory = (props) => {
  //property
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dialog = useDialog();
  const {
    setupData: { masterData },
  } = useSelector((state) => {
    return state;
  });
  //state
  const [productPopup, setProductPopup] = useState(false);
  const [productPopupData, setProductPopupData] = useState();
  const [productListMaster, setProductListMaster] = useState([]);
  const [productList, setProductList] = useState([]);
  const [userInfo, setUserInfo] = useState();
  const [selectCountryCodeStore, setCountryCodeStore] = useState();
  //edit
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState({});
  const [isLoadSuccess, setIsLoadSuccess] = useState(false);
  const [searchProduct, setSearchProduct] = useState();

  useEffect(() => {
    downloadAllData();
  }, []);

  useEffect(() => {}, [masterData]);

  useEffect(() => {
    let search = productListMaster.filter((d) => {
      const searchTermLower = searchProduct.toLowerCase();
      const nameLower = d.name.toLowerCase();
      const codeLower = d.code.toLowerCase();
      return (
        nameLower.includes(searchTermLower) ||
        codeLower.includes(searchTermLower)
      );
    });

    if (searchProduct) {
      setProductList(search);
    } else {
      setProductList(productListMaster);
    }
  }, [searchProduct]);

  useEffect(() => {
    if (selectCountryCodeStore) {
      downloadProducts();
    }
  }, [selectCountryCodeStore]);

  const downloadAllData = async () => {
    //dialog.loading();
    let uInfo = await getUserInfo();

    if (uInfo) {
      setUserInfo(uInfo);
      let countryCode =
        uInfo.userRole === "S" || uInfo.userRole === "SS"
          ? "THA"
          : uInfo.countryCodeStore;

      if (!selectCountryCodeStore) {
        setCountryCodeStore(countryCode);
      }

      let productsList = await downloadProductInventory(countryCode);
      let initProducts = [];
      productsList.forEach((item) => {
        initProducts.push(item);
      });

      setProductList(initProducts);
      setProductListMaster(initProducts);
    }
    setIsLoadSuccess(true);
    dialog.cancle();
  };

  const downloadProducts = async () => {
    // dialog.loading();
    if (!selectCountryCodeStore) {
      return;
    }
    let productsList = await downloadProductInventory(selectCountryCodeStore);
    let initProducts = [];
    productsList.forEach((item) => {
      initProducts.push(item);
    });
    setProductList(initProducts);
    setProductListMaster(initProducts);
    dialog.cancle();
  };

  return (
    <React.Fragment>
      <PopupProductDetail
        visible={productPopup && productPopupData}
        subTitle={"รายละเอียดสินค้า"}
        data={productPopupData}
        onEnter={() => {}}
        onClose={() => {
          setProductPopup(false);
          setProductPopupData(null);
          downloadAllData();
        }}
        onEdit={(values) => {
          setProductPopup(false);
          setProductPopupData(null);
          setIsEdit(true);
          setEditData(values);
        }}
      />

      {isEdit ? (
        <React.Fragment>
          <ProductAddNew
            isEdit={isEdit}
            editData={editData}
            onClose={() => {
              downloadAllData();
              setIsEdit(false);
              setEditData({});
            }}
          />
        </React.Fragment>
      ) : (
        <View style={Styles.container}>
          <Grid container style={Styles.topContainer} spacing={2}>
            <Grid item xs={12} md={8} lg={9} xl={10}>
              <View style={{ flex: 1 }}>
                <Text style={Styles.textTopContainer1}>รายการสินค้าในคลัง</Text>
                <Text style={Styles.textTopContainer2}>
                  สินค้า / รายการสินค้าในคลัง
                </Text>
              </View>
            </Grid>
            <Grid item xs={12} md={4} lg={3} xl={2}>
              <Button
                buttonText={"เพิ่มสินค้า"}
                color={"primary"}
                style={{ width: "100%" }}
                styleText={Styles.topButtonText}
                onClick={() => {
                  if (userInfo.userRole === "A") {
                    dispatch({ type: "SetPage", payload: "order" });
                    dispatch({ type: "SetSubPage", payload: "addOrder" });
                    navigate("/addOrder");
                  } else {
                    dispatch({ type: "SetSubPage", payload: "addProduct" });
                    navigate("/addProduct");
                  }
                }}
              />
            </Grid>
          </Grid>
          <View style={{ height: 30 }} />

          <View style={Styles.contentContainer}>
            <Grid container spacing={2}>
              {userInfo &&
              (userInfo.userRole === "S" || userInfo.userRole === "SS") ? (
                <Grid item xs={12} md={12} lg={3} xl={4}>
                  <Dropdown
                    value={selectCountryCodeStore}
                    valueType={"string"}
                    list={masterData ? masterData.countryList : []}
                    placeholder={"ประเทศของคลังสินค้า"}
                    onChange={(data) => {
                      const { value, label } = data;
                      setCountryCodeStore(value);
                    }}
                  />
                </Grid>
              ) : null}
              <Grid item xs={12} md={8} lg={6} xl={4}>
                <View style={{ flex: 1 }}>
                  <SearchBox
                    value={searchProduct}
                    placeholder={"ค้นหารายการ"}
                    onChangeText={(values) => {
                      console.log(values);
                      setSearchProduct(values);
                    }}
                  />
                </View>
              </Grid>
              <Grid item xs={12} md={4} lg={3} xl={2}>
                <Button
                  buttonText={"ค้นหา"}
                  color={"primary"}
                  style={{ width: "100%" }}
                  styleText={Styles.topButtonText}
                  onClick={() => {}}
                />
              </Grid>
            </Grid>
            <Divider />
            {productList.length > 0 ? (
              <ProductContainer
                data={productList}
                onSelect={(data) => {
                  setProductPopup(true);
                  setProductPopupData(data);
                }}
              />
            ) : isLoadSuccess ? (
              <EmptyProduct />
            ) : null}
          </View>
        </View>
      )}
    </React.Fragment>
  );
};

const ProductContainer = (props) => {
  const { data } = props;
  const { onSelect } = props;
  return (
    <View style={{ flex: 1 }}>
      <ProductTable
        tableData={data}
        onSelect={(data) => {
          onSelect && onSelect(data);
        }}
      />
    </View>
  );
};

const EmptyProduct = () => {
  return (
    <View
      style={{
        flex: 1,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={emptyProduct} style={{ width: 120 }} alt={"emptyProduct"} />
      <View style={{ height: 30 }} />
      <Text
        style={{
          fontSize: "clamp(16px,2vh,20px)",
          fontWeight: "bold",
          color: "rgba(15, 16, 17, 1)",
        }}
      >
        ยังไม่มีสินค้าในคลัง
      </Text>
      <Text
        style={{
          fontSize: "clamp(12px,2vh,16px)",
          color: "rgba(105, 109, 121, 1)",
          marginTop: 8,
        }}
      >
        กดปุ่ม ‘เพิ่มสินค้า’ เพื่อเพิ่มสินค้าลงในคลัง
      </Text>
    </View>
  );
};

const Styles = {
  container: {
    flex: 1,
    height: "100vh",
    padding: "1.5%",
  },
  topContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  contentContainer: {
    width: "100%",
    flex: 1,
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  textTopContainer1: {
    fontSize: "clamp(18px, 3vw, 22px)",
    fontWeight: "bold",
    color: "#AA4942",
  },
  textTopContainer2: {
    fontSize: "clamp(12px, 3vw, 16px)",
    color: "#696D79",
  },
  topButtonText: {
    fontSize: "clamp(14px, 3vw, 18px)",
  },
};

export default ProductInventory;
