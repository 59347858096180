import React, { useEffect, useState } from "react";
import { Text, View } from "react-native-web";
import { Grid } from "@mui/material";
import { Divider } from "rsuite";
//component
import Button from "../../components/button/Button";
import SearchBox from "../../components/textField/SearchBox";
import DatePicker from "../../components/calendar/DatePicker";
import Dropdown from "../../components/dropdown/Dropdown";
import CreditHistoryTable from "./component/CreditHistoryTable";
//images
import emptyProduct from "../../asset/images/product/inventory/emptyProduct.png";
//function
import { downloadAllCustomerSales } from "../../services/api/sales/sales";

const CreditHistory = (props) => {
  return (
    <View style={Styles.container}>
      <Grid container style={Styles.topContainer} spacing={2}>
        <Grid item xs={12} md={8} lg={9} xl={10}>
          <View style={{ flex: 1 }}>
            <Text style={Styles.textTopContainer1}>ประวัติการใช้งานเครดิต</Text>
            <Text style={Styles.textTopContainer2}>
              เครดิตร้านค้า / ประวัติการใช้งานเครดิต
            </Text>
          </View>
        </Grid>
      </Grid>
      <View style={{ height: 30 }} />
      <View style={Styles.contentContainer}>
        <View style={{ height: 30 }} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <Dropdown
              placeholder={"ข้อมูลตัวแทน"}
              list={[]}
              onChange={(values) => {
                const { value, label } = values;
                console.log(value, label);
              }}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <DatePicker
              placeholder="วันที่เริ่มต้น - วันที่สิ้นสุด"
              styles={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <Button
              buttonText={"ค้นหา"}
              color={"primary"}
              style={{ width: "100%" }}
              styleText={Styles.topButtonText}
              onClick={() => {}}
            />
          </Grid>
        </Grid>
        <View style={{ height: 30 }} />
        <View style={{ flex: 1 }}>
          <CreditHistoryTable
            tableData={[
              {
                createDate: "20/9/2022 14:00 น.",
                desctiontion:
                  "หักค่าบริการ SMS การจัดส่งเลขที่ DO2022/03/203015",
                deposit: "19,238.75",
                withdraw: "5,138.00 ",
                amount: "14,100.75",
              },
              {
                createDate: "20/9/2022 14:00 น.",
                desctiontion:
                  "หักค่าบริการ SMS การจัดส่งเลขที่ DO2022/03/203015",
                deposit: "19,238.75",
                withdraw: "5,138.00 ",
                amount: "14,100.75",
              },
              {
                createDate: "20/9/2022 14:00 น.",
                desctiontion:
                  "หักค่าบริการ SMS การจัดส่งเลขที่ DO2022/03/203015",
                deposit: "19,238.75",
                withdraw: "5,138.00 ",
                amount: "14,100.75",
              },
              {
                createDate: "20/9/2022 14:00 น.",
                desctiontion:
                  "หักค่าบริการ SMS การจัดส่งเลขที่ DO2022/03/203015",
                deposit: "19,238.75",
                withdraw: "5,138.00 ",
                amount: "14,100.75",
              },
              {
                createDate: "20/9/2022 14:00 น.",
                desctiontion:
                  "หักค่าบริการ SMS การจัดส่งเลขที่ DO2022/03/203015",
                deposit: "19,238.75",
                withdraw: "5,138.00 ",
                amount: "14,100.75",
              },
              {
                createDate: "20/9/2022 14:00 น.",
                desctiontion:
                  "หักค่าบริการ SMS การจัดส่งเลขที่ DO2022/03/203015",
                deposit: "19,238.75",
                withdraw: "5,138.00 ",
                amount: "14,100.75",
              },
            ]}
          />
        </View>
        <Divider />
      </View>
    </View>
  );
};

const Styles = {
  container: {
    flex: 1,
    height: "100vh",
    padding: "1.5%",
  },
  topContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  contentContainer: {
    width: "100%",
    flex: 1,
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  textTopContainer1: {
    fontSize: "clamp(18px, 3vw, 22px)",
    fontWeight: "bold",
    color: "#AA4942",
  },
  textTopContainer2: {
    fontSize: "clamp(12px, 3vw, 16px)",
    color: "#696D79",
  },
  topButtonText: {
    fontSize: "clamp(14px, 3vw, 18px)",
  },
};

export default CreditHistory;
