import React, { useEffect, useState } from "react";
import { View, TouchableOpacity, Text } from "react-native-web";
import { FiPlus } from "react-icons/fi";
import Button from "@mui/material/Button";

const VideoUploadPreview = (props) => {
  const { placeholder = "เพิ่มวิดิโอ", value } = props;
  const { onChange } = props;
  const [videoSrc, setVideoSrc] = useState(null);

  const handleVideoChange = (e) => {
    const file = e.target.files[0];

    if (file && file.type.startsWith("video/")) {
      const src = URL.createObjectURL(file);
      setVideoSrc(src);
      onChange && onChange(src);
    } else {
      setVideoSrc(null);
      alert("Please select a valid video file.");
    }
  };

  useEffect(() => {
    if (value) {
      setVideoSrc(value);
    }
  }, [value]);

  return (
    <TouchableOpacity
      activeOpacity={0.9}
      style={{ ...Styles.container }}
      onPress={() => document.getElementById("video-upload").click()}
    >
      <input
        type="file"
        accept="video/*"
        onChange={handleVideoChange}
        style={{ display: "none" }}
        id="video-upload"
      />
      <div style={{ ...Styles.box }}>
        <View style={{ ...Styles.content }}>
          {videoSrc ? (
            <video
              controls
              style={{
                width: "100%",
                height: "auto", // ปรับความสูงเป็น 'auto' เพื่อให้ควบคุมวิดิโอปรากฏ
                objectFit: "cover",
                overflow: "hidden",
                borderWidth: 1,
              }}
              src={videoSrc}
            ></video>
          ) : (
            <View style={{ flexDirection: "row" }}>
              <FiPlus style={Styles.icon} />
              <View style={{ width: 10 }} />
              <Text style={Styles.text2}>{placeholder}</Text>
            </View>
          )}
        </View>
      </div>
    </TouchableOpacity>
  );
};
const Styles = {
  container: {
    borderStyle: "dashed",
    borderWidth: 1,
    borderColor: "rgba(213, 215, 221, 1)",
    padding: 10,
    borderRadius: 4,
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    width: "100%", // เพิ่มความกว้าง 100%
    height: "auto", // เพิ่มความสูง 'auto'
  },
  box: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%", // เพิ่มความกว้าง 100%
    height: "auto", // เพิ่มความสูง 'auto'
  },
  content: {
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    width: "100%", // เพิ่มความกว้าง 100%
    height: "auto", // เพิ่มความสูง 'auto'
  },
  icon: { color: "rgba(222, 124, 120, 1)", fontSize: 25 },
  text2: {
    fontWeight: "bold",
    color: "rgba(222, 124, 120, 1)",
    fontSize: 18,
  },
};
export default VideoUploadPreview;
