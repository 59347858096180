import React, { useState, useRef, useEffect } from "react";
import { View, Text, TouchableOpacity, ScrollView } from "react-native-web";
import { Scrollbar } from "react-scrollbars-custom";
//Function
import { useWindowsSize } from "../../functions/screen/screenFunction";

const SwitchMenuIcon = (props) => {
  const { list, icons, minWidth, height, selectColor, unSelectColor } = props;
  const { onSelect } = props;

  const [selectIndex, setSelectIndex] = useState(0);
  const ref = useRef();

  const scrollViewRef = useRef(null);

  const scrollToItem = (index) => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo({
        x: index * (minWidth + 10), // Replace ITEM_WIDTH with your item width
        y: 0,
        animated: true,
      });
    }
  };
  return (
    <View style={{ width: "100%", justifyContent: "center" }}>
      <View
        ref={ref}
        style={{
          flexDirection: "row",
          width: "100%",
          height: height ? height : 50,
          justifyContent: "space-around",
          alignItems: "space-around",
        }}
      >
        <ScrollView
          ref={scrollViewRef}
          style={{ width: "100%", height: "100%" }}
          horizontal
          showsHorizontalScrollIndicator={false}
        >
          <View style={{ flexDirection: "row" }}>
            {list.map((item, index) => {
              return (
                <BoxSelect
                  key={"BoxSelect_" + index.toLocaleString()}
                  index={index}
                  data={item}
                  minWidth={minWidth}
                  isSelect={index === selectIndex}
                  isLastIndex={index === list.length - 1}
                  onSelect={(responseIndex) => {
                    setSelectIndex(responseIndex.index);
                    scrollToItem(responseIndex.index);

                    onSelect &&
                      onSelect({
                        title: list[responseIndex.index],
                        index: responseIndex.index,
                      });
                  }}
                  selectColor={selectColor}
                  unSelectColor={unSelectColor}
                />
              );
            })}
          </View>
        </ScrollView>
      </View>
    </View>
  );
};

const BoxSelect = (props) => {
  const [hover, setHover] = useState(false);
  const { index, data, selectColor, unSelectColor, minWidth } = props;
  const { isSelect, onSelect } = props;
  const refBox = useRef();

  return (
    <View
      style={{
        flex: 1,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: isSelect
          ? selectColor.background
          : unSelectColor.background,
        borderRadius: 8,
        minWidth: minWidth ? minWidth : null,
      }}
      ref={refBox}
    >
      <TouchableOpacity
        style={{
          flex: 1,
          flexDirection: "row",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          minWidth: minWidth ? minWidth : null,
        }}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        onPress={() => {
          onSelect({ index: index, offset: refBox.current });
        }}
      >
        {isSelect
          ? data.iconSelect
            ? data.iconSelect
            : null
          : data.iconDeselect
          ? data.iconDeselect
          : null}
        <Text
          style={{
            fontSize: 16,
            fontWeight: isSelect ? "bold" : "normal",
            color: isSelect ? selectColor.text : unSelectColor.text,
            zIndex: 11,
            marginLeft: 8,
          }}
        >
          {data.label ? data.label : ""} {data.badget ? data.badget : ""}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

export default SwitchMenuIcon;
