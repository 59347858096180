import apiPath from "../../configs/apiPath";
import { get, post } from "../../../functions/fetch/fetch";

export const getAllOrderReport = async () => {
  console.log("");
  let url = apiPath.report.getAllOrderReport;
  let { status, response } = await get(url, {}, true);
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};

export const getAllRetailReport = async () => {
  console.log("");
  let url = apiPath.report.getAllRetailReport;
  let { status, response } = await get(url, {}, true);
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};
