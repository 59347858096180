import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Text, View } from "react-native-web";
//component
import TextField from "../../../components/textField/TextField";
import ButtonV2 from "../../../components/button/ButtonV2";
import Dropdown from "../../../components/dropdown/Dropdown";
//functions
import {
  formatNumber,
  formatNumberString,
} from "../../../functions/format/numberFormat";
import { useDialog } from "../../../components/modal/Popup";
//Images
import { CgTrash } from "react-icons/cg";

const ProductDiscountBox = (props) => {
  const dialog = useDialog();
  const { value } = props;
  const { onChangeData } = props;
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(items)) {
      // value.sort((a, b) => a.quantity - b.quantity);
      // const groupedData = value.reduce((acc, item) => {
      //   acc[item.typeOfDiscount] = acc[item.typeOfDiscount] || [];
      //   acc[item.typeOfDiscount].push(item);
      //   return acc;
      // }, {});
      // let newList = [...groupedData.shopping, ...groupedData.order];

      setItems(value);
    }
  }, [value]);

  // Grouping by 'typeOfDiscount'

  useEffect(() => {
    let init = [];
    items.forEach((element) => {
      init.push({
        quantity: Number(element.quantity),
        pricePerPiece: Number(element.pricePerPiece),
        typeOfDiscount: element.typeOfDiscount,
      });
    });
    onChangeData && onChangeData(init);
  }, [items]);

  const addItems = () => {
    let init = items;
    if (items.length > 0) {
      let lastItem = items[items.length - 1];
      if (
        !lastItem.quantity ||
        !lastItem.pricePerPiece ||
        !lastItem.typeOfDiscount
      ) {
        return;
      }
    }
    init.push({
      quantity: "",
      pricePerPiece: "",
      typeOfDiscount: "",
    });
    setItems([...init]);
  };

  function addNewData(values, quantity, typeOfDiscount, pricePerPiece) {
    if (!typeOfDiscount && !pricePerPiece) {
      return true;
    }
    let init = values;
    //check quantity 2 type
    let duplicateValue = init.filter((d) => {
      return d.quantity === quantity && quantity;
    }).length;

    if (duplicateValue > 2) {
      showAlert(
        `จำนวนสินค้า "${quantity}" มีได้สูงสุด 2 ค่า และประเภทของการสั่งซื้อต้องไม่เหมือนกัน`
      );
      return false;
    }
    //check same type
    if (duplicateValue >= 1 && typeOfDiscount) {
      let checkSameType = init.filter((d) => {
        return d.typeOfDiscount === typeOfDiscount && d.quantity === quantity;
      }).length;
      if (checkSameType > 1) {
        showAlert(
          `จำนวนสินค้า "${quantity}" ประเภทของการสั่งซื้อต้องไม่เหมือนกัน`
        );
        return false;
      }
    }
    //check quantity > in list
    return true;
  }

  const showAlert = async (message) => {
    await dialog.alert({
      title: "แจ้งเตือน",
      message: message,
    });
  };

  return (
    <View style={{ flex: 1 }}>
      <Grid container spacing={2}>
        {items.map((element, index) => {
          return (
            <RowOfDiscount
              key={"rowOfDiscount_" + index.toString()}
              data={{ ...element, index: index, zIndex: items.length - index }}
              onChangeData={(value) => {
                let init = [...items];
                init[value.index] = { ...value };
                let check = addNewData(
                  init,
                  value.quantity,
                  value.typeOfDiscount,
                  value.pricePerPiece
                );
                if (check) {
                  setItems([...init]);
                }
              }}
              onDelete={(value) => {
                let init = items;
                init.splice(value.index, 1);
                setItems([...init]);
              }}
            />
          );
        })}
      </Grid>
      <View style={{ height: 20 }} />
      <ButtonV2
        buttonText={"เพิ่มระดับราคา"}
        color={"primary"}
        style={{ height: 32, maxWidth: 120 }}
        styleText={{ fontSize: 14 }}
        onClick={() => {
          addItems();
        }}
      />
    </View>
  );
};

const RowOfDiscount = (props) => {
  const { data } = props;
  const { onChangeData, onDelete } = props;
  const [price, setPrice] = useState(data ? data.pricePerPiece : 0);

  const typeOfDiscount = () => {
    return [
      { value: "shopping", label: "ขายปลีก" },
      { value: "order", label: "คำสั่งซื้อ" },
    ];
  };
  return (
    <React.Fragment>
      <Grid item xs={12} md={4}>
        <Text style={Styles.titleTextField}>จำนวนสินค้า (ชิ้น)</Text>
        <View style={{ height: 8 }} />
        <TextField
          value={data ? data.quantity : ""}
          onChangeText={(value) => {
            let num = formatNumber(value);
            onChangeData &&
              onChangeData({
                ...data,
                quantity: num,
              });
          }}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Text style={Styles.titleTextField}>ราคาสินค้า (ต่อชิ้น)</Text>
        <View style={{ height: 8 }} />
        <TextField
          value={price ? price : ""}
          onChangeText={(value) => {
            // Accept only numbers and a single decimal point
            const onlyNumsAndDot = value.replace(/[^0-9.]/g, "");
            // Remove all but first dot
            const singleDot = onlyNumsAndDot.replace(/(\..*)\./g, "$1");
            // Don't allow more than two digits after the decimal point
            const maxTwoDecimals = singleDot.replace(/(\.\d{2}).*/g, "$1");
            setPrice(maxTwoDecimals);
            onChangeData &&
              onChangeData({
                ...data,
                pricePerPiece: Number(maxTwoDecimals),
              });
          }}
        />
      </Grid>
      <Grid item xs={12} md={4} style={{ zIndex: data.zIndex }}>
        <Text style={Styles.titleTextField}>ประเภทของการสั่งซื้อ</Text>
        <View style={{ height: 8 }} />
        <Dropdown
          value={data.typeOfDiscount ? data.typeOfDiscount : ""}
          valueType={"string"}
          placeholder={"ประเภทคำสั่งซื้อ"}
          list={typeOfDiscount()}
          onChange={(values) => {
            const { value, label } = values;
            onChangeData &&
              onChangeData({
                ...data,
                typeOfDiscount: value,
              });
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <ButtonV2
          icon={
            <CgTrash
              style={{ fontSize: 15, color: "#E30505", marginRight: 4 }}
            />
          }
          buttonText={"ลบระดับราคา"}
          color={"primary"}
          style={{ height: 32, maxWidth: 120, borderColor: "#E30505" }}
          styleText={{ fontSize: 14, color: "#E30505" }}
          isBorder={true}
          onClick={() => {
            onDelete && onDelete(data);
          }}
        />
      </Grid>
    </React.Fragment>
  );
};

const Styles = {
  titleTextField: {
    fontSize: 14,
    color: "#0F1011",
  },
};

export default ProductDiscountBox;
