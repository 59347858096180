import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import { Grid, Typography } from "@mui/material";
import { Divider } from "rsuite";
import parse from "react-html-parser";
import moment from "moment-timezone";
//images
import { SlOptionsVertical } from "react-icons/sl";
import { CalendarRewardIcon } from "../../../asset/images/icons/iconsLib";
import productImg from "../../../asset/images/example/product.png";
import DropdownPopover from "../../../components/dropdown/DropdownPopover";
import StatusTag from "../../../components/tag/StatusTag";
import { useSelector } from "react-redux";

const CourseSection = (props) => {
  const { data = [] } = props;
  const [course, setCourse] = useState([]);
  const { onSelect, onOption } = props;

  useEffect(() => {
    if (data) {
      let groupedRanks = data.reduce((acc, item) => {
        item.ranks.forEach((rank) => {
          if (!acc[rank.name]) {
            acc[rank.name] = [];
          }
          acc[rank.name].push(item);
        });
        return acc;
      }, {});

      for (let rankName in groupedRanks) {
        groupedRanks[rankName].sort((a, b) => a.id - b.id);
      }

      setCourse(groupedRanks);
    }
  }, [data]);

  return (
    <View>
      {Object.entries(course).map(([key, courseGroup]) => {
        return (
          <React.Fragment key={key}>
            <Text style={Styles.title}>
              {`คอร์สสำหรับ ระดับ ${key ? key : "ตัวแทน"}`}
            </Text>
            <Divider />
            <Grid container spacing={2}>
              {courseGroup.map((item, index) => (
                <CourseContainer
                  key={index}
                  data={item}
                  rank={key}
                  onSelect={(data) => {
                    onSelect && onSelect(data);
                  }}
                  onOption={(value) => {
                    onOption && onOption({ data: item, option: value });
                  }}
                />
              ))}
            </Grid>
          </React.Fragment>
        );
      })}
    </View>
  );
};

const CourseContainer = (props) => {
  const { data, rank } = props;
  const { onSelect, onOption } = props;
  const { user } = useSelector((state) => {
    return state;
  });

  const StylesCourse = {
    container: {
      padding: 15,
      boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
      borderRadius: 8,
      maxHeight: 220,
      overflow: "hidden",
    },
    content: {
      flexDirection: "row",
    },
    img: {
      width: "100%",
      height: 190,
      objectFit: "cover",
      borderRadius: 8,
    },
    rightContent: {
      flex: 0.6,
      paddingLeft: 10,
      paddingRight: 5,
      paddingBottom: 10,
    },
    textTitle: {
      fontSize: 16,
      color: "rgba(15, 16, 17, 1)",
      fontWeight: "bold",
    },
    optionIcon: {
      fontSize: 15,
      color: "rgba(105, 109, 121, 1)",
      position: "absolute",
      top: 5,
      right: 0,
    },
    calendarContainer: {
      flexDirection: "row",
      marginTop: 10,
    },
    contentText: {
      fontSize: 10,
    },
  };

  return (
    <Grid item xs={12} md={6} xl={4}>
      <View style={StylesCourse.container}>
        <View style={StylesCourse.content}>
          <View style={{ flex: 0.4 }}>
            <TouchableOpacity
              activeOpacity={0.9}
              onPress={() => {
                onSelect && onSelect(data);
              }}
            >
              <img
                style={StylesCourse.img}
                alt={"image"}
                src={data ? data.banner_image : ""}
              />
            </TouchableOpacity>
          </View>
          <View style={{ width: 10 }} />
          <View style={StylesCourse.rightContent}>
            <TouchableOpacity
              activeOpacity={0.9}
              onPress={() => {
                onSelect && onSelect(data);
              }}
            >
              <Text style={StylesCourse.textTitle}>
                {data ? data.class_name : ""}
              </Text>
            </TouchableOpacity>
            {(user && user.userRole === "S") || user.userRole === "SS" ? (
              <View style={{ position: "absolute", top: 5, right: 0 }}>
                <DropdownPopover
                  list={[
                    { label: "แก้ไข", value: "edit" },
                    { label: "ลบ", value: "delete" },
                  ]}
                  showIconOnly={true}
                  onSelect={(value) => {
                    onOption && onOption(value);
                  }}
                />
              </View>
            ) : null}
            <View style={{ height: 10 }} />
            <View style={{ flexDirection: "row" }}>
              <StatusTag
                title={`${
                  data
                    ? data.point === 0
                      ? "ฟรี"
                      : `${Number(data.point).toLocaleString()} คะแนน`
                    : ""
                }`}
                color={
                  data && data.point === 0
                    ? "rgba(180, 241, 179, 1)"
                    : "rgba(194, 215, 255, 1)"
                }
                colorText={
                  data && data.point === 0
                    ? "rgba(28, 155, 26, 1)"
                    : "rgba(78, 132, 255, 1)"
                }
              />
              <View style={{ width: 10 }} />
              <StatusTag title={rank ? rank : "ตัวแทน"} />
            </View>
            <View style={StylesCourse.calendarContainer}>
              <CalendarRewardIcon />
              <Text>
                {data
                  ? `${moment(data.class_open_date).format(
                      "DD/MM/YYYY HH:mm"
                    )} - ${moment(data.class_close_date).format(
                      "DD/MM/YYYY HH:mm"
                    )}`
                  : ""}
              </Text>
            </View>
            <View style={{ height: 10 }} />
            <View
              style={{
                width: "100%",
                height: 70,
                overflow: "hidden",
              }}
            >
              {parse(`${data ? data.detail : ""}`)}
            </View>
          </View>
        </View>
      </View>
    </Grid>
  );
};

const Styles = {
  title: {
    fontSize: 18,
    fontWeight: "bold",
    color: "rgba(15, 16, 17, 1)",
    marginTop: 20,
  },
};

export default CourseSection;
