import React from "react";
import { TouchableOpacity } from "react-native-web";
import { IoClose } from "react-icons/io5";
const CloseButton = (props) => {
  const { onPress, color, style } = props;
  return (
    <TouchableOpacity
      style={{ ...style }}
      onPress={() => {
        onPress && onPress();
      }}
    >
      <IoClose style={{ fontSize: 25, color: color ? color : "#000" }} />
    </TouchableOpacity>
  );
};

export default CloseButton;
