import React, { useState } from "react";
import moment from "moment";
import { View } from "react-native-web";
import DayBox from "./DayBox";
import DayBoxRange from "./DayBoxRange";

const BuildDayBoxBetWeen = ({
  currentDate,
  startDate,
  endDate,
  callback,
  onSelectStartDate,
  onSelectEndDate,
}) => {
  const date = moment(currentDate);

  const dayInWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const totalDaysInMonth = date.daysInMonth();
  const startDayIndex = dayInWeek.indexOf(date.startOf("month").format("dddd"));
  const endDayIndex = dayInWeek.indexOf(date.endOf("month").format("dddd"));
  const totalRow = Math.ceil(
    (totalDaysInMonth + startDayIndex + (6 - endDayIndex)) / 7
  );

  const getDaysBeforeAndAfter = () => {
    const prevMonth = moment(date).subtract(1, "month");
    const nextMonth = moment(date).add(1, "month");
    const daysBefore = Array.from({ length: startDayIndex }, (_, i) => ({
      day: prevMonth.daysInMonth() - startDayIndex + 1 + i,
      isActive: false,
      isValid: false,
    }));
    const daysAfter = Array.from({ length: 6 - endDayIndex }, (_, i) => ({
      day: i + 1,
      isActive: false,
      isValid: false,
    }));
    return { daysBefore, daysAfter };
  };

  const generateDayBoxData = () => {
    const { daysBefore, daysAfter } = getDaysBeforeAndAfter();
    const currentMonthDays = Array.from(
      { length: totalDaysInMonth },
      (_, i) => ({
        day: i + 1,
        isActive: true,
        isValid: true,
      })
    );
    return [...daysBefore, ...currentMonthDays, ...daysAfter];
  };

  const renderDayBox = (dayBoxData, index) => {
    return dayBoxData ? (
      <DayBoxRange
        key={`daybox_${index}`}
        currentDate={currentDate}
        day={dayBoxData.day}
        isStartDate={
          startDate
            ? Number(startDate.format("DD")) === Number(dayBoxData.day) &&
              startDate.format("MM/YYYY") === date.format("MM/YYYY")
            : false
        }
        isEndDate={
          endDate
            ? Number(endDate.format("DD")) === Number(dayBoxData.day) &&
              endDate.format("MM/YYYY") === date.format("MM/YYYY")
            : false
        }
        isBetween={checkBetween(dayBoxData.day)}
        isActive={dayBoxData.isActive}
        isValid={dayBoxData.isValid}
        onPress={(response) => {
          if (!startDate) {
            let initStartDate = date;
            initStartDate.date(response);
            onSelectStartDate && onSelectStartDate(initStartDate);
            return;
          }
          if (startDate && !endDate) {
            let initEndDate = date;
            initEndDate.date(response);
            if (initEndDate.isBefore(startDate)) {
              onSelectStartDate && onSelectStartDate(initEndDate);
              return;
            }
            if (!initEndDate.isBefore(startDate)) {
              onSelectEndDate && onSelectEndDate(initEndDate);
            }
            return;
          }
          if (startDate && endDate) {
            let initStartDate = date;
            initStartDate.date(response);
            onSelectStartDate && onSelectStartDate(initStartDate);
            onSelectEndDate(null);
          }
          //callback && callback(response);
        }}
      />
    ) : (
      <DayBoxRange key={`daybox_${index}`} />
    );
  };

  const checkBetween = (day) => {
    if (!startDate || !endDate) {
      return false;
    }

    let initDate = moment(date);
    initDate.date(day);
    // initDate.subtract(1, "month");
    return initDate.isBetween(startDate, endDate, undefined, "[]");
  };

  const dayBoxData = generateDayBoxData();
  const content = [];

  for (let i = 0; i < totalRow; i++) {
    content.push(
      <View style={Styles.dayBoxLineContainer} key={`dayBoxContainer${i}`}>
        {dayBoxData.slice(i * 7, i * 7 + 7).map(renderDayBox)}
      </View>
    );
  }

  return content;
};

const Styles = {
  icon: {
    fontSize: 20,
    color: "#DE7C78",
  },
  container: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  selectBox: {
    width: "100%",
    flexDirection: "row",
  },
  dayBoxLineContainer: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default BuildDayBoxBetWeen;
