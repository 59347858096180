import React, { useState } from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import { View, Text, TouchableOpacity } from "react-native-web";
import { BsCheckLg } from "react-icons/bs";
import { IoClose } from "react-icons/io5";

function PopupHead(props) {
  const { type, showIcon = false, message, icon } = props;
  const { onClose, onPress } = props;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    onClose && onClose();
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={true}
        onClose={handleClose}
      >
        <SnackbarContent
          style={{
            width: "100%",
            backgroundColor:
              type === "success"
                ? "rgba(28, 155, 26, 1)"
                : type === "fail"
                ? "#FF0000"
                : "rgba(138, 145, 161, 1)",
            color: "white",
          }}
          message={
            <TouchableOpacity
              style={{
                width: "100%",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
              onPress={() => {
                onPress && onPress();
              }}
            >
              {type === "success" && showIcon ? (
                <BsCheckLg style={{ color: "#FFFFFF", fontSize: 10 }} />
              ) : type === "fail" && showIcon ? (
                <IoClose style={{ color: "#FFFFFF", fontSize: 15 }} />
              ) : null}
              {type === "success" || (type === "fail" && showIcon) ? (
                <View style={{ width: 10 }} />
              ) : null}

              <Text
                style={{
                  color: "#FFFFFF",
                  textAlign: "center",
                }}
              >
                {message ? message : ""}
              </Text>
            </TouchableOpacity>
          }
        />
      </Snackbar>
    </div>
  );
}

export default PopupHead;
