import { Divider } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Modal, View, Text, StyleSheet, ScrollView } from "react-native-web";
import { Grid, Typography } from "@mui/material";
import ImageGallery from "../../../components/images/ImageGallery";
//components
import CloseButton from "../../../components/button/CloseButton";
import Button from "../../../components/button/Button";
//functions
import { calculatorFontSize } from "../../../functions/screen/screenFunction";
import { useDialog } from "../../../components/modal/Popup";
import apiPath from "../../../services/configs/apiPath";
import { post } from "../../../functions/fetch/fetch";
import { getUserInfo } from "../../../services/api/member/member";
//images
import { CorrectCircleIcon } from "../../../asset/images/icons/iconsLib";
import { formatNumber } from "../../../functions/format/numberFormat";

const PopupProductDetail = (props) => {
  //property
  const dialog = useDialog();
  //props
  const { visible, data } = props;
  const { title, subTitle } = props;
  const { onEdit, onClose } = props;
  //state
  const [userInfo, setUserInfo] = useState();

  useEffect(() => {
    console.log(data);
  }, [data]);

  useEffect(() => {
    if (!userInfo) {
      downloadUserProfile();
    }
  }, []);

  const downloadUserProfile = async () => {
    let uInfo = await getUserInfo();
    if (uInfo) {
      setUserInfo(uInfo);
    }
  };

  const deleteProduct = async () => {
    let confirm = await dialog.confirm({
      title: "ยืนยันการลบข้อมูล",
      message: ``,
    });
    if (!confirm) {
      return;
    }

    let url = apiPath.products.changeProductActive;
    let { status, response } = await post(url, { ...data, active: "N" }, true);
    if (!status || response.data.error) {
    }
    onClose && onClose();
  };

  const editProduct = () => {
    onEdit && onEdit(data);
  };

  return (
    <Modal transparent={true} visible={visible ? visible : false}>
      <View style={Styles.modalContainer}>
        <View style={Styles.box}>
          <View style={Styles.headerBox}>
            <View style={{ flex: 1 }}>
              <Text style={Styles.headerSubTitle}>
                {subTitle ? subTitle : ""}
              </Text>
              <Text style={Styles.headerTitle}>
                {data && (data.name ? data.name : "")}
              </Text>
            </View>
            <CloseButton
              onPress={() => {
                onClose && onClose();
              }}
            />
          </View>
          <View style={{ height: 20 }} />
          <ScrollView
            style={{ width: "100%" }}
            showsVerticalScrollIndicator={false}
          >
            <Grid container spacing={4} style={{}}>
              <Grid item xs={12}>
                <View
                  style={{
                    width: "100%",
                    height: 1,
                    backgroundColor: "rgba(242, 242, 242, 1)",
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <View style={{ flex: 1 }}>
                  <ImageGallery
                    images={data ? data.images : []}
                    thumbnailWidth={100}
                    thumbnailHeight={100}
                  />

                  <View style={Styles.boxProductDetail}>
                    <View style={Styles.productDetailRowText}>
                      <Text style={Styles.productDetailTextTitle}>
                        รหัสสินค้า
                      </Text>
                      <Text style={Styles.productDetailTextValue}>
                        {data && (data.code ? data.code : "")}
                      </Text>
                    </View>
                    <View style={Styles.productDetailRowText}>
                      <Text style={Styles.productDetailTextTitle}>
                        หมวดหมู่
                      </Text>
                      <Text style={Styles.productDetailTextValue}>
                        {data &&
                          (data.productCategoryName
                            ? data.productCategoryName
                            : "")}
                      </Text>
                    </View>
                    <View style={Styles.productDetailRowText}>
                      <Text style={Styles.productDetailTextTitle}>ประเภท</Text>
                      <Text style={Styles.productDetailTextValue}>
                        {data &&
                          (data.productTypeName ? data.productTypeName : "")}
                      </Text>
                    </View>
                    <View style={Styles.productDetailRowText}>
                      <Text style={Styles.productDetailTextTitle}>ราคา</Text>
                      <Text style={Styles.productDetailTextValue}>
                        {data &&
                          (data.price
                            ? formatNumber(data.price).toLocaleString()
                            : "")}
                      </Text>
                      <Text style={{ ...Styles.productDetailTextSubValue }}>
                        {" "}
                        บาท
                      </Text>
                    </View>
                    <View style={Styles.productDetailRowText}>
                      <Text style={Styles.productDetailTextTitle}>จำนวน</Text>
                      <Text style={Styles.productDetailTextValue}>1.00</Text>
                      <Text style={{ ...Styles.productDetailTextSubValue }}>
                        {" "}
                        ชิ้น
                      </Text>{" "}
                    </View>
                  </View>
                </View>
              </Grid>

              <Grid item xs={12} md={8}>
                <Text style={Styles.boxLeftTextTitle}>ข้อมูลสินค้า</Text>
                <Typography
                  variant="body1"
                  component="p"
                  style={{ whiteSpace: "pre-line", marginTop: 8 }}
                >
                  {data && (data.detail ? data.detail : "")}
                </Typography>
                <View
                  style={{
                    width: "100%",
                    height: 1,
                    backgroundColor: "rgba(242, 242, 242, 1)",
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                />
                <Text style={{ ...Styles.boxLeftTextTitle }}>
                  จุดเด่นสินค้า
                </Text>
                <View style={{ height: 8 }} />
                {data &&
                  data.prominentPoint &&
                  data.prominentPoint.map((item) => {
                    return <FeatureText text={item} />;
                  })}
              </Grid>
              <Grid item xs={12} md={4}></Grid>
              {userInfo &&
              (userInfo.userRole === "S" || userInfo.userRole === "SS") ? (
                <Grid item xs={12} md={8}>
                  <View style={{ flexDirection: "row", width: "100%" }}>
                    <Button
                      buttonText={"ลบสินค้า"}
                      color={"primary"}
                      style={{ flex: 1 }}
                      isBorder={true}
                      onClick={() => {
                        deleteProduct();
                      }}
                    />
                    <View style={{ width: 20 }} />
                    <Button
                      buttonText={"แก้ไขข้อมูลสินค้า"}
                      color={"primary"}
                      style={{ flex: 1 }}
                      isBorder={false}
                      onClick={() => {
                        editProduct();
                      }}
                    />
                  </View>
                </Grid>
              ) : null}
            </Grid>
          </ScrollView>
        </View>
      </View>
    </Modal>
  );
};

const FeatureText = (props) => {
  const { text } = props;
  return (
    <View
      style={{
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
        marginBottom: 8,
      }}
    >
      <CorrectCircleIcon width={20} height={20} color="rgba(170, 73, 66, 1)" />
      <Typography style={{ marginLeft: 10, color: "rgba(105, 109, 121, 1)" }}>
        {text ? text : ""}
      </Typography>
    </View>
  );
};

const Styles = StyleSheet.create({
  modalContainer: {
    position: "fixed",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 9999,
    alignItems: "center",
    justifyContent: "center",
  },
  box: {
    width: "90%",
    maxHeight: "90%",
    maxWidth: 1280,
    padding: 20,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFF",
    borderRadius: 16,
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.08,
    shadowRadius: 16,
  },
  headerBox: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  headerTitle: {
    fontSize: calculatorFontSize(16, 20),
    color: "rgba(15, 16, 17, 1)",
    flex: 1,
  },
  headerSubTitle: {
    color: "rgba(102, 102, 102, 1)",
  },
  boxLeftTextTitle: {
    fontSize: calculatorFontSize(12, 16),
    fontWeight: "bold",
  },
  boxProductDetail: {
    backgroundColor: "rgba(250, 250, 250, 1)",
    padding: 16,
    marginTop: 20,
    borderRadius: 16,
  },
  productDetailTextTitle: {
    color: "rgba(102, 102, 102, 1)",
    fontSize: calculatorFontSize(16, 20),
    flex: 1,
  },
  productDetailTextValue: {
    color: "rgba(15, 16, 17, 1)",
    fontSize: calculatorFontSize(16, 20),
    fontWeight: "bold",
  },
  productDetailTextSubValue: {
    color: "rgba(102, 102, 102, 1)",
    fontSize: calculatorFontSize(16, 20),
  },
  productDetailRowText: {
    flexDirection: "row",
    marginBottom: 16,
  },
});

export default PopupProductDetail;
