import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
} from "react-native-web";
import Button from "../button/Button";
import { useDialog } from "../modal/Popup";
//icons
import { IoMdClose } from "react-icons/io";
import { CgTrash } from "react-icons/cg";
import { FiPlus } from "react-icons/fi";

//functions
import { checkFileType } from "../../functions/validate/validate";
import { PlusCircleIcon } from "../../asset/images/icons/iconsLib";

const UploadImages = (props) => {
  const { maxUpload = 5, style, images } = props;
  const { onChangeFile } = props;
  const inputRef = React.useRef();
  const popup = useDialog();
  //state
  const [file, setFile] = useState([]);

  useEffect(() => {
    if (images) {
      setFile(images);
    }
  }, [images]);

  const selectFile = () => {
    if (file.length + 1 > maxUpload) {
      popup.alert({
        title: "แจ้งเตือน",
        message: `${file.length} ${maxUpload}`,
      });
      return;
    }
    if (inputRef) {
      inputRef.current.click();
    }
  };

  const deleteFile = (index) => {
    if (index >= 0) {
      file.splice(index, 1);
      setFile([...file]);
    }
  };

  return (
    <View style={{ ...Styles.container, ...style }}>
      <View style={Styles.boxTag}>
        {file.map((file, index) => (
          <TagUpload
            key={"UploadImages_" + index.toLocaleString()}
            index={index}
            file={file}
            onDelete={(index) => {
              deleteFile(index);
            }}
          />
        ))}
        {file.length < maxUpload ? (
          <TagDefault
            key={"UploadImages_TagDefault"}
            onSelect={() => selectFile()}
          />
        ) : null}
        <TagEmpty totalFile={file.length} maxUpload={maxUpload} />
      </View>

      <input
        type="file"
        accept="image/png,image/jpeg"
        style={{ display: "none" }}
        ref={inputRef}
        onClick={(event) => {
          event.target.value = null;
        }}
        onChange={(event, data) => {
          if (event.target.files[0] !== undefined) {
            let image = event.target.files[0];
            checkFileType(
              image,
              (type) => {
                if (type === "image/png" || type === "image/jpeg") {
                  let init = [...file];
                  init.push(image);
                  setFile(init);
                  onChangeFile && onChangeFile(init);
                } else {
                  alert("กรุณาอัพโหลดเฉพาะไฟล์รูปภาพ PNG หรือ JPEG เท่านั้น");
                }
              },
              (value) => {}
            );
          }
        }}
      />
    </View>
  );
};

const TagUpload = (props) => {
  const { file, index } = props;
  const { onDelete } = props;

  const StylesTag = {
    container: {
      background: "rgba(237, 238, 240, 1)",
      borderRadius: "8px",
      width: 200,
      height: 200,
      justifyContent: "center",
      alignItems: "center",
      marginRight: 20,
      marginBottom: 20,
      overflow: "hidden",
    },
    trashIcon: {
      fontSize: 20,
      color: "rgba(170, 73, 66, 1)",
    },
    removeIcon: {
      borderRadius: "50%",
      backgroundColor: "#FFFFFF",
      padding: 8,
      position: "absolute",
      top: 5,
      right: 5,
    },
    img: {
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  };

  return (
    <View style={StylesTag.container}>
      <img
        src={
          file ? (file instanceof File ? URL.createObjectURL(file) : file) : ""
        }
        style={StylesTag.img}
        alt={"productImage"}
      />
      <TouchableOpacity
        style={StylesTag.removeIcon}
        onPress={() => {
          onDelete && onDelete(index);
        }}
      >
        <CgTrash style={StylesTag.trashIcon} />
      </TouchableOpacity>
    </View>
  );
};

const TagEmpty = (props) => {
  const { totalFile, maxUpload } = props;

  const StylesTag = {
    container: {
      background: "rgba(237, 238, 240, 1)",
      border: "1px dashed #D5D7DD",
      borderRadius: "8px",
      width: 200,
      height: 200,
      justifyContent: "center",
      alignItems: "center",
      marginRight: 20,
      marginBottom: 20,
    },
  };
  let renderList = [];
  for (let x = 1; x < maxUpload - totalFile; x++) {
    renderList.push(<View style={StylesTag.container} />);
  }
  return renderList;
};

const TagDefault = (props) => {
  const { onSelect } = props;

  const StylesTag = {
    container: {
      background: "rgba(237, 238, 240, 1)",
      border: "1px dashed #D5D7DD",
      borderRadius: "8px",
      width: 200,
      height: 200,
      justifyContent: "center",
      alignItems: "center",
      marginRight: 20,
      marginBottom: 20,
    },
    addMark: {
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    addMarkText: {
      fontSize: "clamp(14px,2vh,18px)",
      fontWeight: "bold",
      color: "rgba(170, 73, 66, 1)",
    },
    addMarkIcon: {
      fontSize: 20,
      fontWeight: "bold",
      color: "rgba(170, 73, 66, 1)",
      marginRight: 8,
    },
  };

  return (
    <View style={StylesTag.container}>
      <TouchableOpacity
        style={StylesTag.addMark}
        onPress={() => {
          onSelect && onSelect();
        }}
      >
        <FiPlus style={StylesTag.addMarkIcon} />
        <Text style={StylesTag.addMarkText}>เพิ่มรูปภาพ</Text>
      </TouchableOpacity>
    </View>
  );
};

const Styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    padding: 8,
  },
  boxTag: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
  },
  tagText: {
    flex: 1,
    fontSize: 14,
    color: "#FFFFFF",
  },
  tagCloseIcon: {
    fontSize: 14,
    color: "#FFFFFF",
    marginLeft: 8,
  },
});

export default UploadImages;
