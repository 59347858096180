import React, { useEffect, useState } from "react";
import { View, Text } from "react-native-web";
const StatusTag = (props) => {
  const {
    title = "",
    color = "rgba(255, 228, 187, 1)",
    colorText = "rgba(241, 152, 19, 1)",
  } = props;
  return (
    <View
      style={{
        width: 100,
        height: 20,
        backgroundColor: color,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 8,
      }}
    >
      <Text
        style={{
          fontSize: 12,
          fontWeight: "bold",
          color: colorText,
        }}
      >
        {title}
      </Text>
    </View>
  );
};

export default StatusTag;
