import React, { useState } from "react";
import moment from "moment";
import { View } from "react-native-web";
//component
import DayBox from "./DayBox";
moment.locale("en"); // ตั้งค่าใช้ภาษาไทย

const BuildDayBox = (props) => {
  const { currentDate, isBetween, startDate, endDate } = props;
  const { callback } = props;

  const date = moment(currentDate);
  const [selectNumber, setSelectNumber] = useState(moment(currentDate).date());
  const startOfMonth = date.startOf("month").format("dddd");
  const endOfMonth = date.endOf("month").format("dddd");
  const totalDaysInMonth = date.daysInMonth();
  const dayInWeek = [
    { index: 0, word: "Sunday" },
    { index: 1, word: "Monday" },
    { index: 2, word: "Tuesday" },
    { index: 3, word: "Wednesday" },
    { index: 4, word: "Thursday" },
    { index: 5, word: "Friday" },
    { index: 6, word: "Saturday" },
  ];

  let startDayIndex = dayInWeek.filter((d) => d.word === startOfMonth)[0].index;
  let endDayIndex = 6 - dayInWeek.filter((d) => d.word === endOfMonth)[0].index;
  let totalRow =
    (totalDaysInMonth + Math.abs(startDayIndex) + Math.abs(endDayIndex)) / 7;

  //สำหรับ map วัทที่ ที่ไม่ใช่ของเดือนปัจจุบัน
  const prevMonth = moment(date).subtract(1, "month");
  let dayBeforeMonth = [];
  for (
    let x = prevMonth.daysInMonth() - startDayIndex;
    x <= prevMonth.daysInMonth();
    x++
  ) {
    dayBeforeMonth.push({
      day: x,
      isActive: false,
      isValid: false,
    });
  }
  let dayAfterMonth = [];
  for (let x = 1; x <= endDayIndex; x++) {
    dayAfterMonth.push({
      day: x,
      isActive: false,
      isValid: false,
    });
  }

  let content = [<View style={{ marginTop: 10 }} />];
  let currentDay = 0;
  for (let x = 1; x <= totalRow; x++) {
    if (x === 1) {
      //first row
      let data = [];
      for (let i = 0; i < 7; i++) {
        data.push(
          i < startDayIndex
            ? dayBeforeMonth.length > 0
              ? dayBeforeMonth[i + 1]
              : null
            : {
                day: currentDay + 1,
                isActive: true,
                isValid: true,
              }
        );
        currentDay = i < startDayIndex ? currentDay : currentDay + 1;
      }
      content.push(
        <View style={Styles.dayBoxLineContainer} key={"dayBoxContainer" + x}>
          {data.map((item, index) => {
            return item ? (
              <DayBox
                key={"daybox_" + index}
                currentDate={currentDate}
                selectNumber={selectNumber}
                day={item.day}
                isActive={false}
                isValid={item.isValid}
                onPress={(response) => {
                  callback && callback(response);
                }}
              />
            ) : (
              <DayBox key={"daybox_" + index} />
            );
          })}
        </View>
      );
    } else if (x === totalRow) {
      //last row
      let data = [];
      for (let i = 0; i < 7; i++) {
        data.push(
          currentDay + 1 > totalDaysInMonth
            ? currentDay - totalDaysInMonth < dayAfterMonth.length > 0
              ? dayAfterMonth[currentDay - totalDaysInMonth]
              : null
            : {
                day: currentDay + 1,
                isActive: true,
                isValid: true,
              }
        );

        currentDay = currentDay + 1;
      }

      content.push(
        <View style={Styles.dayBoxLineContainer} key={"dayBoxContainer" + x}>
          {data.map((item, index) => {
            return item ? (
              <DayBox
                key={"daybox_" + index}
                currentDate={currentDate}
                selectNumber={selectNumber}
                day={item.day}
                isActive={item.isActive}
                isValid={item.isValid}
                onPress={(response) => {
                  callback && callback(response);
                }}
              />
            ) : (
              <DayBox key={"daybox_" + index} />
            );
          })}
        </View>
      );
    } else {
      //another row
      let data = [];
      for (let i = 0; i < 7; i++) {
        data.push({
          day: currentDay + 1,
          isActive: true,
          isValid: true,
        });
        currentDay += 1;
      }

      content.push(
        <View style={Styles.dayBoxLineContainer} key={"dayBoxContainer" + x}>
          {data.map((item, index) => {
            return item ? (
              <DayBox
                key={"daybox_" + index}
                currentDate={currentDate}
                selectNumber={selectNumber}
                day={item.day}
                isActive={item.isActive}
                isValid={item.isValid}
                onPress={(response) => {
                  callback && callback(response);
                }}
              />
            ) : (
              <DayBox key={"daybox_" + index} />
            );
          })}
        </View>
      );
    }
  }
  return content;
};

const Styles = {
  icon: {
    fontSize: 20,
    color: "#DE7C78",
  },
  container: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  selectBox: {
    width: "100%",
    flexDirection: "row",
  },
  dayBoxLineContainer: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default BuildDayBox;
