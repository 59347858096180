import React from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import { Grid } from "@mui/material";
import { Divider } from "rsuite";
import { useNavigate } from "react-router-dom";
//component
import Button from "../../components/button/Button";
import Dropdown from "../../components/dropdown/Dropdown";
import UploadImage from "../../components/uploadbox/UploadImage";
import TextField from "../../components/textField/TextField";
import TextEditor from "../../components/textField/TextEditor";
import Switch from "react-ios-switch";
//images
import facebookIcon from "../../asset/images/setting/Facebook.png";
import igIcon from "../../asset/images/setting/IG.png";
import youtubeIcon from "../../asset/images/setting/YouTube.png";
import tiktokIcon from "../../asset/images/setting/TikTok.png";
import lineIcon from "../../asset/images/setting/Line.png";

const SettingPage = () => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <View style={Styles.container}>
        <Grid container style={Styles.topContainer} spacing={2}>
          <Grid item xs={12} md={8} lg={9} xl={10}>
            <View style={{ flex: 1 }}>
              <Text style={Styles.textTopContainer1}>ตั้งค่าเว็บไซต์</Text>
              <Text style={Styles.textTopContainer2}>
                จัดการเว็บไซต์ / ตั้งค่าเว็บไซต์
              </Text>
            </View>
          </Grid>
        </Grid>
        <View style={{ height: 30 }} />
        <View style={Styles.contentContainer}>
          <Text style={Styles.titleTextHead}>ตั้งค่าเว็บไซต์</Text>
          <Divider />

          <View
            style={{
              width: "100%",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: 16 }}>เพิ่มรูปโลโก้</Text>
            <Text style={Styles.textImageMark}>
              ขนาดรูปภาพ 300 x 80 px ขนาดไฟล์ไม่เกิน 1 MB รองรับไฟล์ JPG, PNG,
              JPEGS
            </Text>
            <View style={{ marginTop: 16 }} />
            <UploadImage
              value={""}
              placeholder={"เพิ่มรูปภาพ"}
              style={{ width: 200, height: 200 }}
              onChangeImage={(image) => {}}
            />
            <View style={{ marginTop: 16 }} />
            <Text style={{ fontSize: 16 }}>เพิ่ม Favicon</Text>
            <Text style={Styles.textImageMark}>
              ขนาดไฟล์ไม่เกิน 1 MB รองรับไฟล์ JPG, PNG, JPEGS
            </Text>
            <View style={{ marginTop: 16 }} />
            <UploadImage
              value={""}
              placeholder={"เพิ่มรูปภาพ"}
              style={{ width: 200, height: 200 }}
              onChangeImage={(image) => {}}
            />
          </View>
          <View style={{ marginTop: 20 }} />

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Text style={Styles.titleText}>
                ชื่อโดเมนย่อย
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />

              <TextField
                value={""}
                iconName={""}
                isInvalid={false}
                placeholder={"ระบุชื่อโดเมนย่อย"}
                errorMessage={""}
                onChangeText={(value) => {}}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Text style={Styles.titleText}>
                ชื่อร้านค้า/บริษัท
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />

              <TextField
                value={""}
                iconName={""}
                isInvalid={false}
                placeholder={"ระบุชื่อร้านค้า/บริษัท"}
                errorMessage={""}
                onChangeText={(value) => {}}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Text style={Styles.titleText}>
                ค่าขนส่ง
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />

              <TextField
                value={""}
                iconName={""}
                isInvalid={false}
                placeholder={"ระบุค่าขนส่ง"}
                errorMessage={""}
                onChangeText={(value) => {}}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Text style={Styles.titleText}>
                คลังสินค้า
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />
              <Dropdown
                value={""}
                valueType={"string"}
                placeholder={"เลือกคลังสินค้า"}
                list={""}
                onChange={(values) => {
                  const { value, label } = values;
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Text style={Styles.titleText}>
                ผู้รับ
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />
              <Dropdown
                value={""}
                valueType={"string"}
                placeholder={"เลือกผู้รับ"}
                list={""}
                onChange={(values) => {
                  const { value, label } = values;
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Text style={Styles.titleText}>
                เบอร์โทรศัพท์
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />

              <TextField
                value={""}
                iconName={""}
                isInvalid={false}
                placeholder={"ระบุเบอร์โทรศัพท์"}
                errorMessage={""}
                onChangeText={(value) => {}}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Text style={Styles.titleText}>
                อีเมล
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />

              <TextField
                value={""}
                iconName={""}
                isInvalid={false}
                placeholder={"ระบุอีเมล"}
                errorMessage={""}
                onChangeText={(value) => {}}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Text style={Styles.titleText}>
                ปิดการซื้อขายหน้าเว็บ
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />
              <Dropdown
                value={""}
                valueType={"string"}
                placeholder={"เลือกปิดการซื้อขายหน้าเว็บ"}
                list={""}
                onChange={(values) => {
                  const { value, label } = values;
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Text style={Styles.titleText}>
                รายละเอียดบัญชีรับเงิน
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />
              <TextEditor />
            </Grid>
            <Grid item xs={12}>
              <Text style={Styles.titleText}>
                รายละเอียดที่อยู่
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />
              <TextEditor />
            </Grid>
          </Grid>
          <View style={{ marginTop: 30 }} />

          <Text style={Styles.titleTextHead}>ตั้งค่าเว็บไซต์</Text>
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <SocialSwitch
                id={"line"}
                title={"Line"}
                iconName={lineIcon}
                onChange={(value) => {}}
              />
              <View style={{ height: 8 }} />

              <TextField
                value={""}
                iconName={""}
                isInvalid={false}
                placeholder={""}
                errorMessage={""}
                onChangeText={(value) => {}}
              />
              <View style={{ height: 8 }} />
              <Text style={Styles.textImageMark}>
                เช่น rosegoldth หรือ @rosegoldth
              </Text>
            </Grid>
            <Grid item xs={12} md={6}>
              <SocialSwitch
                id={"facebook"}
                title={"Facebook"}
                iconName={facebookIcon}
                onChange={(value) => {}}
              />
              <View style={{ height: 8 }} />

              <TextField
                value={""}
                iconName={""}
                isInvalid={false}
                placeholder={""}
                errorMessage={""}
                onChangeText={(value) => {}}
              />
              <View style={{ height: 8 }} />
              <Text style={Styles.textImageMark}>
                เช่น www.facebook.com/rosgoldthpage
              </Text>
            </Grid>
            <Grid item xs={12} md={6}>
              <SocialSwitch
                id={"instagram"}
                title={"Instagram"}
                iconName={igIcon}
                onChange={(value) => {}}
              />
              <View style={{ height: 8 }} />

              <TextField
                value={""}
                iconName={""}
                isInvalid={false}
                placeholder={""}
                errorMessage={""}
                onChangeText={(value) => {}}
              />
              <View style={{ height: 8 }} />
              <Text style={Styles.textImageMark}>เช่น rosegoldth</Text>
            </Grid>
            <Grid item xs={12} md={6}>
              <SocialSwitch
                id={"youtube"}
                title={"Youtube"}
                iconName={youtubeIcon}
                onChange={(value) => {}}
              />
              <View style={{ height: 8 }} />

              <TextField
                value={""}
                iconName={""}
                isInvalid={false}
                placeholder={""}
                errorMessage={""}
                onChangeText={(value) => {}}
              />
              <View style={{ height: 8 }} />
              <Text style={Styles.textImageMark}>
                เช่น www.youtube.com/c/rosegoldth
              </Text>
            </Grid>
            <Grid item xs={12} md={6}>
              <SocialSwitch
                id={"tiktok"}
                title={"Tiktok"}
                iconName={tiktokIcon}
                onChange={(value) => {}}
              />
              <View style={{ height: 8 }} />

              <TextField
                value={""}
                iconName={""}
                isInvalid={false}
                placeholder={""}
                errorMessage={""}
                onChangeText={(value) => {}}
              />
              <View style={{ height: 8 }} />
              <Text style={Styles.textImageMark}>เช่น @rosegoldth</Text>
            </Grid>
          </Grid>
          <View style={{ marginTop: 30 }} />

          <Text style={Styles.titleTextHead}>ตั้งค่าเว็บไซต์</Text>
          <Divider />
          <Text style={{ fontWeight: "bold" }}>เกี่ยวกับเรา</Text>
          <View style={{ marginTop: 20 }} />

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Text style={Styles.titleText}>Google analytic code</Text>
              <View style={{ height: 8 }} />

              <TextField
                value={""}
                iconName={""}
                isInvalid={false}
                placeholder={""}
                errorMessage={""}
                onChangeText={(value) => {}}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Text style={Styles.titleText}>Facebook pixel code</Text>
              <View style={{ height: 8 }} />

              <TextField
                value={""}
                iconName={""}
                isInvalid={false}
                placeholder={""}
                errorMessage={""}
                onChangeText={(value) => {}}
              />
            </Grid>
          </Grid>
          <View style={{ marginTop: 30 }} />

          <Text style={Styles.titleTextHead}>ตั้งค่าเว็บไซต์</Text>
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Text style={Styles.titleText}>รายละเอียดเกี่ยวกับเรา</Text>
              <View style={{ height: 8 }} />

              <TextField
                value={""}
                iconName={""}
                isInvalid={false}
                placeholder={""}
                errorMessage={""}
                onChangeText={(value) => {}}
              />
            </Grid>
          </Grid>

          <View style={{ marginTop: 30 }} />

          <Text style={Styles.titleTextHead}>ตั้งค่า Cookie information</Text>
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Text style={Styles.titleText}>Cookie information</Text>
              <View style={{ height: 8 }} />

              <TextField
                value={""}
                iconName={""}
                isInvalid={false}
                placeholder={""}
                errorMessage={""}
                onChangeText={(value) => {}}
              />
            </Grid>
          </Grid>

          <View style={{ height: 40 }} />
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              buttonText={"ยกเลิก"}
              color={"primary"}
              style={{ flex: 1, maxWidth: 400 }}
              isBorder={true}
              onClick={() => {}}
            />
            <View style={{ width: 20 }} />
            <Button
              buttonText={"อัพเดทเว็บไซต์"}
              color={"primary"}
              style={{ flex: 1, maxWidth: 400 }}
              isBorder={false}
              onClick={() => {}}
            />
          </View>
          <View style={{ height: 40 }} />
        </View>
        <View style={{ height: 100 }} />
      </View>
    </React.Fragment>
  );
};

const SocialSwitch = ({ id, onChange, title, iconName }) => {
  return (
    <View
      key={id}
      style={{
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={iconName}
        alt={"icon"}
        style={{ width: 20, height: 20, marginRight: 10 }}
      />
      <Text style={Styles.titleText}>
        {title}
        <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
      </Text>
      <View style={{ flex: 1 }} />
      <Switch
        checked={true}
        onChange={(checked) => {
          onChange && onChange(checked);
        }}
        onColor="rgba(170, 73, 66, 1)"
      />
    </View>
  );
};
const Styles = {
  container: {
    flex: 1,
    height: "100vh",
    padding: "1.5%",
  },
  topContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  contentContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  textTopContainer1: {
    fontSize: "clamp(18px, 3vw, 22px)",
    fontWeight: "bold",
    color: "#AA4942",
  },
  textTopContainer2: {
    fontSize: "clamp(12px, 3vw, 16px)",
    color: "#696D79",
  },
  titleTextHead: {
    fontSize: "clamp(16px,2vh,20px)",
    fontWeight: "bold",
    color: "rgba(15, 16, 17, 1)",
  },
  textImageMark: {
    fontSize: 14,
    color: "rgba(105, 109, 121, 1)",
  },
};
export default SettingPage;
