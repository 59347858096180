import React from "react";
import { View, Text, Touchable, TouchableOpacity } from "react-native-web";
import { Divider } from "rsuite";
import { Grid } from "@mui/material";
//component
import UploadImage from "../../../components/uploadbox/UploadImage";
import TextField from "../../../components/textField/TextField";
import Button from "../../../components/button/Button";
//images
import { CalendarRewardIcon } from "../../../asset/images/icons/iconsLib";
import productImg from "../../../asset/images/example/product.png";
import { SlOptionsVertical } from "react-icons/sl";

const CreateBanner = (props) => {
  const { onClose } = props;
  return (
    <View>
      <Text style={Styles.titleTextHead}>สร้างแบนเนอร์</Text>

      <Divider />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Text style={{ fontSize: 16 }}>เพิ่มรูปแบนเนอร์</Text>
          <Text style={Styles.textImageMark}>
            รองรับไฟล์ JPG, PNG, JPEGS เท่านั้น
          </Text>
          <View style={{ marginTop: 16 }} />
          <UploadImage
            value={""}
            placeholder={"เพิ่มรูปแบนเนอร์"}
            style={{ width: "100%", height: 280 }}
            onChangeImage={(image) => {}}
          />
          <View style={{ marginTop: 16 }} />
        </Grid>
        <Grid item xs={12}>
          <Text style={Styles.titleText}>ชื่อแบนเนอร์</Text>
          <View style={{ height: 8 }} />

          <TextField
            value={""}
            iconName={""}
            isInvalid={false}
            placeholder={"ระบุชื่อแบนเนอร์"}
            errorMessage={""}
            onChangeText={(value) => {}}
          />
        </Grid>
      </Grid>
      <View style={{ height: 100 }} />
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          buttonText={"ยกเลิก"}
          color={"primary"}
          style={{ flex: 1, maxWidth: 400 }}
          isBorder={true}
          onClick={() => {
            onClose && onClose();
          }}
        />
        <View style={{ width: 20 }} />
        <Button
          buttonText={"สร้างแบนเนอร์"}
          color={"primary"}
          style={{ flex: 1, maxWidth: 400 }}
          isBorder={false}
          onClick={() => {}}
        />
      </View>
      <View style={{ height: 40 }} />
    </View>
  );
};

const Styles = {
  titleTextHead: {
    fontSize: "clamp(16px,2vh,20px)",
    fontWeight: "bold",
    color: "rgba(15, 16, 17, 1)",
  },
  textImageMark: {
    fontSize: 14,
    color: "rgba(105, 109, 121, 1)",
  },
};

export default CreateBanner;
