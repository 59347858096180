import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import { Grid } from "@mui/material";
import { Divider } from "rsuite";
import moment from "moment-timezone";
//component
import SearchBox from "../../../components/textField/SearchBox";
import Button from "../../../components/button/Button";
import EventViewUserDetail from "./EventViewUserDetail";
import { IoIosArrowBack } from "react-icons/io";

//images
import productImg from "../../../asset/images/example/product.png";
import { EditButton } from "../../../components/button/IconButton";
import EventJoinTable from "./EventJoinTable";
import apiPath from "../../../services/configs/apiPath";
import { get, post } from "../../../functions/fetch/fetch";
import { useDialog } from "../../../components/modal/Popup";
import { useSelector } from "react-redux";
import { FiEdit } from "react-icons/fi";
import { BiTrash } from "react-icons/bi";

const EventViewAdmin = (props) => {
  const dialog = useDialog();
  const { data } = props;
  const { onClose, onEdit } = props;
  const { masterdata } = useSelector((state) => {
    return state.setupData;
  });
  //state
  const [detail, setDetail] = useState([
    { lable: "ชื่ออีเวนท์", value: "Countdown Event Party 2023" },
    { lable: "วันที่จัดอีเวนท์", value: "Countdown Event Party 2023" },
    { lable: "เวลา", value: "Countdown Event Party 2023" },
    { lable: "ประเภทอีเวนท์", value: "Countdown Event Party 2023" },
    { lable: "จำนวนพอยท์/ค่าเข้างาน", value: "Countdown Event Party 2023" },
    { lable: "เบอร์โทรศัพท์ติดต่อ", value: "Countdown Event Party 2023" },
    { lable: "สถานที่จัดอีเวนท์", value: "Countdown Event Party 2023" },
    { lable: "รายละเอียดอีเวนท์", value: "Countdown Event Party 2023" },
  ]);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [viewUser, setViewUser] = useState(null);

  useEffect(() => {
    setDetail([
      { lable: "ชื่ออีเวนท์", value: data.event_name },
      {
        lable: "วันที่จัดอีเวนท์",
        value: `${moment(data.event_start_time_date).format(
          "DD/MM/YYYY"
        )} - ${moment(data.event_end_time_date).format("DD/MM/YYYY")}`,
      },
      {
        lable: "เวลา",
        value: `${moment(data.event_start_time_date).format(
          "HH:mm"
        )} - ${moment(data.event_end_time_date).format("HH:mm")}`,
      },
      {
        lable: "ประเภทอีเวนท์",
        value: data.event_type_id === 1 ? "ชำระเงิน" : "ใช้คะแนน",
      },
      {
        lable: "จำนวนพอยท์/ค่าเข้างาน",
        value: `${Number(data.event_price).toLocaleString()} ${
          data.event_type_id === 1 ? "บาท" : "Points"
        }`,
      },
      { lable: "เบอร์โทรศัพท์ติดต่อ", value: data.event_telephone_contact },
      { lable: "สถานที่จัดอีเวนท์", value: data.event_location },
    ]);
    downloadEventDetail();
  }, [data]);

  const downloadEventDetail = async () => {
    let url = `${apiPath.event.getRedeemListByEventId}?event_id=${data.event_id}`;
    let { status, response } = await get(url, {}, true);
    console.log(response);
    if (!status) {
      dialog.alert({ message: response.data.error });
      return;
    }
    if (response.data) {
      setTableData(response.data);
      setIsLoading(true);
    }
  };

  const handleOption = async (values) => {
    const { action, data } = values;
    if (action === "view") {
      setViewUser(data);
    } else if (action === "accept") {
      handleAccept(data.event_redeem_id);
    }
  };

  const handleAccept = async (redeemId) => {
    if (!redeemId) {
      return;
    }
    let url = apiPath.event.eventCheckIn;
    let { status, response } = await post(
      url,
      {
        redeem_event_id: redeemId,
        check_in: true,
      },
      true
    );
    if (!status) {
      dialog.alert({ message: response.data.error });
      return;
    }
    if (response.data) {
      dialog.alert({ message: "ยืนยันการเข้าร่วมสำเร็จ" });
      downloadEventDetail();
      return;
    }
  };

  const handleDelete = async () => {
    if (!data.event_id) {
      return;
    }
    let confirm = await dialog.confirm({
      title: `ยืนยันการลบ ${data.event_name}`,
      message: `ยืนยันการลบ ${data.event_name} ใช่ หรือ ไม่ ?`,
    });
    if (!confirm) {
      return;
    }
    let url = apiPath.event.updateDisableStatus;
    let { status, response } = await post(
      url,
      { id: data.event_id, status: 1 },
      true
    );
    if (!status) {
      dialog.alert({ message: response.data.error });
      return;
    }
    await dialog.alert({ message: "ลบข้อมูลสำเร็จ" });
    onClose && onClose();
  };

  return (
    <React.Fragment>
      {viewUser ? (
        <EventViewUserDetail
          data={viewUser}
          onClose={() => {
            setViewUser(null);
            downloadEventDetail();
          }}
        />
      ) : null}
      <View style={Styles.contentContainer}>
        <Grid container spacing={2} style={{ alignItems: "center" }}>
          <Grid item xs={12} md={6} lg={8}>
            <View style={{ flexDirection: "row" }}>
              <TouchableOpacity
                onPress={() => {
                  onClose && onClose();
                }}
              >
                <IoIosArrowBack
                  style={{
                    fontSize: 25,
                    color: "rgba(222, 124, 120, 1)",
                    marginRight: 10,
                  }}
                />
              </TouchableOpacity>
              <Text style={Styles.textTitle}>รายละเอียดอีเวนท์</Text>
            </View>
          </Grid>

          <Grid item xs={6} md={3} lg={2}>
            <Button
              buttonText={"แก้ไขอีเวนท์"}
              icon={
                <FiEdit
                  style={{
                    fontSize: 17,
                    marginRight: 10,
                    color: "rgba(222, 124, 120, 1)",
                  }}
                />
              }
              isBorder={true}
              color="primary"
              style={{ width: "100%" }}
              onClick={() => {
                let params = {
                  event_id: data.event_id, // ถ้าไม่มีให้ส่ง null สำหรับสร้างอีเว้นท์ใหม่ / ถ้าหากต้องการอัพเดตให้ส่ง id ของ event มา
                  event_image: data.event_image,
                  register_limit: data.register_limit,
                  qualify_rank: data.qualify_rank
                    ? JSON.parse(data.qualify_rank)
                    : [], // ไม่มีให้ส่ง [] แสดงมาสามารถเข้างานได้ทุกคน
                  qualify_top_rank: data.qualify_top_rank
                    ? JSON.parse(data.qualify_top_rank)
                    : [],
                  event_start_time_date: data.event_start_time_date, // งานเริ่มกี่โมง
                  event_end_time_date: data.event_end_time_date, // งานสิ้นสุดกี่โมง
                  event_list_food: data.list_food,
                  event_description: data.event_description,
                  event_link_location: data.event_link_location,
                  event_location: data.event_location,
                  event_other_contact: data.event_other_contact,
                  event_telephone_contact: data.event_telephone_contact,
                  event_price: data.event_price,
                  event_type_id: data.event_type_id,
                  event_start_register_date: data.event_start_register_date, // วันเริ่มซื้อ event
                  event_close_register_date: data.event_close_register_date, // วันปิดการซื้อ event
                  event_date: moment().toDate(), // ส่งเวลา Now มา
                  event_name: data.event_name,
                  disable_status: false, // กรณีสร้างอีเว้นท์ใหม่ส่ง false / กรณีที่ต้องการปิด event นี้ให้ส่ง true
                };

                onEdit && onEdit(params);
              }}
            />
          </Grid>
          <Grid item xs={6} md={3} lg={2}>
            <Button
              buttonText={"ลบอีเวนท์"}
              icon={
                <BiTrash
                  style={{
                    fontSize: 20,
                    marginRight: 10,
                    color: "rgba(222, 124, 120, 1)",
                  }}
                />
              }
              isBorder={true}
              color="primary"
              style={{ width: "100%" }}
              onClick={() => {
                handleDelete();
              }}
            />
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <img
              src={data.event_image ? data.event_image : ""}
              alt={"event"}
              style={{ width: "100%", borderRadius: 20 }}
            />
          </Grid>
          <Grid item xs={12} md={9}>
            <EventDetailBox data={detail} />
          </Grid>
        </Grid>
        <View style={{ height: 20 }} />
        <Text style={Styles.textTitle}>ผู้ลงทะเบียน</Text>
        <Divider />
        <View style={{ flex: 1 }}>
          <EventJoinTable
            tableData={tableData}
            isLoading={isLoading}
            onOption={(values) => {
              handleOption(values);
            }}
          />
        </View>
      </View>
    </React.Fragment>
  );
};

const EventDetailBox = (props) => {
  const { data } = props;

  const StylesEDB = {
    container: {
      flex: 1,
      backgroundColor: "rgba(248, 248, 248, 1)",
      borderRadius: 10,
      padding: 20,
    },
    contentRow: {
      flexDirection: "row",
      marginBottom: 10,
    },
    textTitle: { flex: 0.3, color: "rgba(105, 109, 121, 1)" },
    textValue: { flex: 0.7, color: "rgba(15, 16, 17, 1)", fontWeight: "bold" },
  };
  return (
    <View style={StylesEDB.container}>
      {data &&
        data.map((item, index) => {
          return (
            <View style={StylesEDB.contentRow} key={"eventviewdetail" + index}>
              <Text style={StylesEDB.textTitle}>{item.lable}</Text>
              <Text style={StylesEDB.textValue}>{item.value}</Text>
            </View>
          );
        })}
    </View>
  );
};

const Styles = {
  contentContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  textTitle: {
    fontSize: "clamp(16px,2vh,20px)",
    fontWeight: "bold",
    color: "rgba(15, 16, 17, 1)",
  },
};

export default EventViewAdmin;
