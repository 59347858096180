import apiPath from "../../configs/apiPath";
import { get, post } from "../../../functions/fetch/fetch";

export const getAllStockListByQuantity = async (countryCodeStore = "THA") => {
  let url = apiPath.report.getAllStockListByQuantity;
  let { status, response } = await get(
    url,
    { countryCodeStore: countryCodeStore },
    true
  );
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};

export const getStockListByAgentCode = async (
  countryCodeStore = "THA",
  startIndex = 1,
  limit = 10
) => {
  let url = apiPath.report.getStockListByAgentCode;
  let { status, response } = await get(
    url,
    {
      countryCodeStore: countryCodeStore,
      startIndex: startIndex,
      limit: limit,
    },
    true
  );
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};

export const getProductStockTransactionReport = async () => {
  let url = apiPath.report.getProductStockTransactionReport;
  let { status, response } = await get(url, {}, true);

  console.log(response);
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};

export const getProductStockOrderCount = async () => {
  let url = apiPath.report.getProductStockOrderCount;
  let { status, response } = await get(url, {}, true);

  console.log(response);
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};

export const getProductStockRetailCount = async () => {
  let url = apiPath.report.getProductStockRetailCount;
  let { status, response } = await get(url, {}, true);

  console.log(response);
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};
