import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { View, Text } from "react-native-web";
import { Divider } from "rsuite";
import moment from "moment-timezone";
import { Container } from "shards-react";
//components
import Button from "../../components/button/Button";
import TextArea from "../../components/textField/TextArea";
import DatePicker from "../../components/calendar/DatePicker";
import TimePicker from "../../components/time/TimePicker";
import Dropdown from "../../components/dropdown/Dropdown";
import TextField from "../../components/textField/TextField";
import BoxImage from "../../components/uploadbox/BoxUploadImage";
import ProductShoppingTable from "./component/ProductShoppingTable";
import EmptyProduct from "./component/EmptyProduct";
import SummaryBox from "./component/SummaryBox";
import { useParams } from "react-router-dom";
import { get, post } from "../../functions/fetch/fetch";
import apiPath from "../../services/configs/apiPath";
import { useDialog } from "../../components/modal/Popup";
import { upload } from "../../functions/fetch/fetch";
import { dataURItoBlob } from "../../functions/format/convertFile";
//images

const CustomerPayment = (props) => {
  const { paymentCode } = useParams();
  const dialog = useDialog();
  const hostname = window.location.hostname;

  //state
  let [shoppingData, setShoppingData] = useState({
    date: moment(),
    time: moment().format("HH:mm"),
    saleId: null,
    saleCode: null,
    saleCodeStore: null,
    customerId: null,
    description: null,
    productList: null,
    transportId: null,
    transportCode: null,
    paymentMethodCode: null,
    countryCodeStore: null,
    forSelf: false,
    paymentMethodList: [],
    orders: [],
  });
  const [displayDataSummary, setDisplayDataSummary] = useState({
    quantity: 0,
    total: 0,
    totalDiscount: 0,
    totalDelivery: 0,
    totalBasePrice: 0,
    addOn:[]
  });
  const [productCalculate, setProductCalculate] = useState([]);

  useEffect(() => {
    if (paymentCode) {
      downloadRetailData(paymentCode);
    }
  }, [paymentCode]);

  useEffect(() => {
    if (shoppingData.orders) {
      calculateOrder(shoppingData.orders);
    }
  }, [shoppingData]);

  const downloadRetailData = async (code) => {
    if (code) {
      dialog.loading();
      let url = apiPath.retail.getCustomerPaymentData;
      let { status, response } = await get(url, { paymentCode: code }, false);
      if (!status) {
        await dialog.alertParent({
          style: { maxWidth: 432 },
          title: "URL ไม่ถูกต้อง",
          message: "ไม่สามารถดำเนินการสั่งซื้อต่อได้ กรุณาติดต่อตัวแทน",
          forceOpen: true,
        });
        return;
      }
      const val = response.data;
      let diffInDays = moment().diff(moment(val.createDate), "days");
      if (diffInDays > 1) {
        await dialog.alert({
          style: { maxWidth: 432 },
          title: "URL หมดอายุการใช้งาน",
          message: "ไม่สามารถดำเนินการสั่งซื้อต่อได้ กรุณาติดต่อตัวแทน",
          forceOpen: true,
        });

        return;
      }

      if (val.status === "Y") {
        await dialog.alert({
          style: { maxWidth: 432 },
          title: "การสั่งซื้อเสร็จสมบูรณ์",
          message:
            "เราได้รับคำสั่งซื้อของท่านเรียบร้อยแล้ว \nขอบคุณสำหรับการสั่งซื้อ",
          forceOpen: true,
        });
      }

      setShoppingData(val);
      setDisplayDataSummary({
        quantity: val.totalQuantity,
        total: val.totalPrice,
        totalDiscount: val.totalDiscount,
        totalDelivery: val.transportCost,
        totalBasePrice: val.totalBasePrice,
        addOn:val.addOn ? val.addOn : [],
      });
      dialog.cancle();
    } else {
      await dialog.alert({
        style: { maxWidth: 432 },
        title: "URL หมดอายุการใช้งาน",
        message: "ไม่สามารถดำเนินการสั่งซื้อต่อได้ กรุณาติดต่อตัวแทน",
        forceOpen: true,
      });
      return;
    }
  };

  const submit = async () => {
    if (!shoppingData.paymentMethodCode) {
      dialog.alert({ message: "กรุณาเลือกวิธีชำระเงิน" });
      return;
    }
    let params = {
      date: moment(shoppingData.date).toDate(),
      time: shoppingData.time,
      hour: Number(shoppingData.time.split(":")[0]),
      minute: Number(shoppingData.time.split(":")[1]),
      saleId: shoppingData.saleId,
      saleCode: shoppingData.saleCode,
      saleCodeStore: shoppingData.saleCodeStore,
      saleCodeTransaction: shoppingData.saleCode,
      customerId: shoppingData.customerId,
      customerName: shoppingData.customerName,
      customerEmail: shoppingData.customerEmail,
      productList: productCalculate,
      transportCode: shoppingData.transportCode,
      transportCost: displayDataSummary.totalDelivery,
      totalQuantity: displayDataSummary.quantity,
      totalBasePrice: displayDataSummary.totalBasePrice,
      totalPrice: displayDataSummary.total,
      totalDiscount: displayDataSummary.totalDiscount,
      description: shoppingData.description ? shoppingData.description : "",
      orders: shoppingData.orders,
      attachment: shoppingData.attachment,
      trackingCodes: shoppingData.trackingCodes,
    };

    if (shoppingData.paymentImage) {
      //Upload image process
      const uploadImages = await upload(
        [dataURItoBlob(shoppingData.paymentImage)],
        `${process.env["REACT_APP_ENV"]}/Images/Retail/Payment/${shoppingData.saleCode}`
      );
      const { data } = uploadImages.response;
      if (!data) {
        await dialog.alert({
          title: "แจ้งเตือน",
          message: uploadImages.response.data.error,
        });
        return;
      }
      params.paymentImage = data.data ? data.data[0] : "";
      //end upload image process
    }

    if (shoppingData.paymentMethodCode === "cashdelivery") {
      handleCashOnDelivery(params);
      return;
    }
    //ชำระเงิน
    let paymentRes = await dialog.payment({
      paymentMethodCode: shoppingData.paymentMethodCode,
      data: {
        ...params,
        transactionType: "RETAIL",
        paymentMethodCode: shoppingData.paymentMethodCode,
        isCustomerPayment: true,
        customerPaymentRefNo: paymentCode,
      },
    });
    if (!paymentRes.close) {
      return;
    }
    if (
      paymentRes.status !== null &&
      paymentRes.status !== undefined &&
      paymentRes.status === false
    ) {
      dialog.alert({ message: "การชำระเงินไม่สำเร็จ" });
      return;
    }

    handleSuccess();
  };

  const calculateOrder = async (values) => {
    if (!values || !shoppingData) {
      return;
    }
    let url = apiPath.retail.calculateOrder;
    let { status, response } = await post(
      url,
      {
        orders: values,
        ...shoppingData,
      },
      false
    );
    if (!status) {
      // dialog.cancle();
      return;
    }
    if (response.data) {
      const cal = response.data;
      setProductCalculate(cal.data);
      setDisplayDataSummary({
        ...displayDataSummary,
        quantity: cal.totalQuantity,
        totalDiscount: cal.totalDiscount,
        total: cal.totalPrice,
        totalBasePrice: cal.totalBasePrice,
        totalDelivery: cal.totalTransportCost,
        totalExtraCashDelivery: cal.totalExtraCashDelivery,
        addOn: cal.addOn ? cal.addOn : [],
      });
    }
    dialog.cancle();
  };

  const handleSuccess = async () => {
    let message = "เพิ่มรายการขายปลีกสำเร็จ";
    let alert = await dialog.alert({
      title: "แจ้งเตือน",
      message: message,
    });
    if (!alert) {
      return;
    }
    window.location.reload();
  };

  const handleCashOnDelivery = async (params) => {
    if (!params) {
      dialog.cancle();
      return;
    }
    dialog.loading();
    params.paymentMethodCode = "cashdelivery";
    params.customerPaymentRefNo = paymentCode;
    params.transactionType = "RETAIL";
    let url = apiPath.retail.addRetailCashDeliveryCustomer;
    let { status, response } = await post(url, params, false);
    if (!status) {
      await dialog.alert({ title: "แจ้งเตือน", message: response.data.error });
      dialog.cancle();
      return;
    }
    let alert = dialog.alert({ message: "เพิ่มรายการขายปลีกสำเร็จ" });
    if (!alert) {
      return;
    }
    dialog.cancle();
    window.location.reload();
  };

  return (
    <Container>
      <View style={Styles.container}>
        {shoppingData.saleId ? (
          <React.Fragment>
            <Grid container style={Styles.topContainer} spacing={2}>
              <Grid item xs={12} md={8} lg={9} xl={10}>
                <View style={{ flex: 1 }}>
                  <Text style={Styles.textTopContainer1}>ONELIFE</Text>
                  <Text style={Styles.textTopContainer2}>
                    เลขที่รายการขาย {paymentCode}
                  </Text>
                </View>
              </Grid>
            </Grid>
            <View style={{ height: 30 }} />
            <View style={Styles.contentContainer}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={9} lg={10}>
                  <Text
                    style={{
                      fontSize: "clamp(16px,2vh,20px)",
                      fontWeight: "bold",
                      color: "rgba(15, 16, 17, 1)",
                    }}
                  >
                    รายละเอียดคำสั่งซื้อ
                  </Text>
                </Grid>
              </Grid>
              <Divider />
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} style={{ zIndex: 11 }}>
                  <Text style={Styles.titleText}>วันที่การขาย</Text>
                  <View style={{ height: 8 }} />
                  <DatePicker
                    date={shoppingData.date}
                    styles={{ width: "100%" }}
                    placeholder="เลือกวันที่การขาย"
                    disable={true}
                  />
                </Grid>
                <Grid item xs={12} md={6} style={{ zIndex: 10 }}>
                  <Text style={Styles.titleTextField}>เวลา</Text>
                  <View style={{ height: 8 }} />
                  <TimePicker
                    time={shoppingData.time}
                    styles={{ width: "100%" }}
                    placeholder="เลือกเวลา"
                    disable={true}
                  />
                </Grid>
                <Grid item xs={12} md={6} style={{ zIndex: 9 }}>
                  <Text style={Styles.titleText}>
                    ผู้ขาย{" "}
                    <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
                  </Text>
                  <View style={{ height: 8 }} />
                  <TextField disable={true} value={shoppingData.saleName} />
                  {false && (
                    <React.Fragment>
                      <View style={{ height: 8 }} />
                      <Text style={Styles.textRemark}>
                        สามารถพิมพ์เพื่อค้นหาได้
                      </Text>
                    </React.Fragment>
                  )}
                </Grid>
                <Grid item xs={12} md={6} style={{ zIndex: 8 }}>
                  <Text style={Styles.titleText}>
                    ลูกค้า{" "}
                    <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
                  </Text>
                  <View style={{ height: 8 }} />
                  <View
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      disable={true}
                      value={shoppingData.customerName}
                    />
                  </View>
                  {false && (
                    <React.Fragment>
                      {" "}
                      <View style={{ height: 8 }} />
                      <Text style={Styles.textRemark}>
                        สามารถพิมพ์เพื่อค้นหาได้
                      </Text>
                    </React.Fragment>
                  )}
                </Grid>

                <Grid item xs={12} md={12} lg={12} style={{ zIndex: 7 }}>
                  <Text
                    style={{
                      fontSize: "clamp(16px,2vh,20px)",
                      fontWeight: "bold",
                      color: "rgba(15, 16, 17, 1)",
                    }}
                  >
                    รายละเอียดสินค้า
                  </Text>
                  <Divider />
                  {false && (
                    <Grid item xs={12} md={6} style={{ zIndex: 6 }}>
                      <View style={{ height: 8 }} />
                      <View
                        style={{
                          flex: 1,
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          zIndex: 8,
                        }}
                      >
                        <Dropdown
                          placeholder={"เพิ่ม/ค้นหาสินค้า"}
                          list={[]}
                          onChange={(values) => {}}
                        />
                      </View>
                    </Grid>
                  )}
                </Grid>

                <Grid item xs={12} style={{ zIndex: 5 }}>
                  {productCalculate.length > 0 ? (
                    <ProductShoppingTable
                      tableData={productCalculate}
                      isView={true}
                    />
                  ) : (
                    <EmptyProduct />
                  )}
                </Grid>
                <View style={{ height: 20 }} />
                <Grid item xs={12} md={12} lg={12}>
                  <Text
                    style={{
                      fontSize: "clamp(16px,2vh,20px)",
                      fontWeight: "bold",
                      color: "rgba(15, 16, 17, 1)",
                    }}
                  >
                    รายละเอียดเพิ่มเติม
                  </Text>
                  <Divider />
                </Grid>

                <Grid item xs={12} md={6} style={{ zIndex: 4 }}>
                  <Text style={Styles.titleText}>
                    วิธีการส่ง{" "}
                    <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
                  </Text>
                  <View style={{ height: 8 }} />
                  <View
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      value={shoppingData.transportName}
                      disable={true}
                    />
                  </View>
                </Grid>
                <Grid item xs={12} md={6} style={{ zIndex: 3 }}>
                  <Text style={Styles.titleText}>ค่าจัดส่ง</Text>
                  <View style={{ height: 8 }} />
                  <TextField
                    value={Number(shoppingData.transportCost).toLocaleString()}
                    disable={true}
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <View style={{ height: 40 }} />
                  <Text
                    style={{
                      fontSize: "clamp(16px,2vh,20px)",
                      fontWeight: "bold",
                      color: "rgba(15, 16, 17, 1)",
                    }}
                  >
                    รายละเอียดการขายสินค้า
                  </Text>
                  <Divider />
                </Grid>

                <SummaryBox displayDataSummary={displayDataSummary} />

                <Grid item xs={12}>
                  <View style={{ height: 20 }} />
                  <Text style={Styles.titleTextField}>วิธีการชำระเงิน</Text>
                  <View style={{ height: 8 }} />
                  <Grid item xs={12} md={4}>
                    <Dropdown
                      value={shoppingData.paymentMethodCode}
                      valueType={"string"}
                      placeholder={"เลือกวิธีการชำระเงิน"}
                      list={shoppingData.paymentMethodList}
                      onChange={(values) => {
                        const { value, label } = values;
                        setShoppingData({
                          ...shoppingData,
                          paymentMethodCode: value,
                        });
                      }}
                    />
                  </Grid>
                </Grid>

{ false &&                <Grid item xs={12}>
                  <View style={{ height: 20 }} />
                  <Text style={Styles.titleTextField}>
                    แนบหลักฐานการชำระเงิน
                  </Text>
                  <View style={{ height: 16 }} />
                  <Grid xs={12} md={4}>
                    <BoxImage
                      style={{ maxWidth: 500, maxHeight: 500 }}
                      accept={"image/png, image/jpeg"}
                      onSelect={(value) => {
                        setShoppingData({
                          ...shoppingData,
                          paymentImage: value,
                        });
                      }}
                    />
                  </Grid>
                </Grid>}

                <Grid item xs={12}>
                  <Text style={Styles.titleTextField}>รายละเอียดอื่นๆ</Text>
                  <View style={{ height: 8 }} />
                  <TextArea
                    onChangeText={(values) => {
                      setShoppingData({
                        ...shoppingData,
                        description: values,
                      });
                    }}
                  />
                </Grid>

                <Grid
                  container
                  spacing={2}
                  style={{ justifyContent: "center", marginTop: 40 }}
                >
                  <Grid item xs={12} md={4} lg={3}>
                    <Button
                      buttonText={"ยืนยันการสั่งซื้อ"}
                      color={"primary"}
                      style={Styles.acceptButton}
                      onClick={() => {
                        submit();
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </View>
            <View style={{ height: 100 }} />
          </React.Fragment>
        ) : null}
      </View>
    </Container>
  );
};

const Styles = {
  container: {
    flex: 1,
    height: "100vh",
    padding: "1.5%",
  },
  topContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  contentContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  textTopContainer1: {
    fontSize: "clamp(18px, 3vw, 22px)",
    fontWeight: "bold",
    color: "#AA4942",
  },
  textTopContainer2: {
    fontSize: "clamp(12px, 3vw, 16px)",
    color: "#696D79",
  },
  topButtonText: {
    fontSize: "clamp(14px, 3vw, 18px)",
  },
  textRemark: {
    fontSize: 14,
    color: "rgba(105, 109, 121, 1)",
  },
  acceptButton: {
    width: "100%",
  },
};

export default CustomerPayment;
