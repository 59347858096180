import React, { useEffect } from "react";
import { View, Text } from "react-native-web";
import { Grid } from "@mui/material";

const SummaryBoxReport = (props) => {
  const { subData = [], sumData = [] } = props;

  const StylesSummaryBox = {
    container: {
      width: "100%",
      backgroundColor: "rgba(248, 248, 248, 1)",
      borderRadius: 16,
      padding: 20,
      justifyContent: "center",
      alignItems: "center",
    },
    row: {
      width: "100%",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    titleText: {
      color: "rgba(105, 109, 121, 1)",
      flex: 1,
    },
    valueText: {
      color: "rgba(15, 16, 17, 1)",
      fontSize: 18,
      fontWeight: "bold",
    },
    unitText: {
      marginLeft: 8,
      color: "rgba(105, 109, 121, 1)",
    },
    line: {
      width: "100%",
      height: 1,
      backgroundColor: "rgba(237, 238, 240, 1)",
      marginTop: 20,
      marginBottom: 20,
    },
    sumText: {
      fontSize: 18,
      fontWeight: "bold",
      color: "rgba(15, 16, 17, 1)",
      flex: 1,
    },
    sumValueText: {
      fontSize: 20,
      fontWeight: "bold",
      color: "rgba(170, 73, 66, 1)",
    },
  };

  const SummaryRow = (props) => {
    const { title, value, unit } = props;
    return (
      <View style={StylesSummaryBox.row}>
        <Text style={StylesSummaryBox.titleText}>{title ? title : ""}</Text>
        <Text style={StylesSummaryBox.valueText}>{value ? value : ""}</Text>
        <Text style={StylesSummaryBox.unitText}>{unit ? unit : ""}</Text>
      </View>
    );
  };

  return (
    <Grid item xs={12}>
      <View style={StylesSummaryBox.container}>
        {subData.map((item) => {
          return (
            <SummaryRow
              key={item.key}
              title={item.label}
              value={item.value}
              unit={item.labelValue}
            />
          );
        })}
        {sumData.map((item) => {
          return (
            <React.Fragment>
              <View style={StylesSummaryBox.line} />
              <View style={StylesSummaryBox.row}>
                <Text style={StylesSummaryBox.sumText}>{item.label}</Text>
                <Text style={StylesSummaryBox.sumValueText}>{item.value}</Text>
                <Text style={StylesSummaryBox.unitText}>{item.labelValue}</Text>
              </View>
            </React.Fragment>
          );
        })}
      </View>
    </Grid>
  );
};

export default SummaryBoxReport;
