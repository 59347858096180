import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
//components
import TextField from "../../../components/textField/TextField";
//images
import { BiTrash } from "react-icons/bi";
//functions
import { formatNumber } from "../../../functions/format/numberFormat";

const ProductShoppingTable = (props) => {
  const { tableHeader = [], tableData = [], isView = false } = props;
  const { onChangeProduct } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const pagesCount = Math.ceil(tableData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const [tableDataSliced, setTableDataSliced] = useState([]);

  const { onSelect, onDelete } = props;

  useEffect(() => {
    setTableDataSliced(tableData.slice(startIndex, endIndex));
  }, [tableData]);
  return (
    <View style={{ width: "100%", height: "100%" }}>
      <TableContainer sx={styles.tableContainer}>
        <Table sx={styles.table}>
          <TableHead sx={{ position: "sticky", top: 0, zIndex: 9999 }}>
            <TableRow style={{ width: "100%", backgroundColor: "#FFF" }}>
              <TableCell sx={{ ...styles.headerCell }}>
                สินค้า (รหัสและชื่อ)
              </TableCell>
              <TableCell sx={{ ...styles.headerCell }}>ราคา (บาท)</TableCell>
              <TableCell sx={{ ...styles.headerCell }}>จำนวน</TableCell>
              <TableCell sx={{ ...styles.headerCell }}>ส่วนลด</TableCell>
              <TableCell sx={{ ...styles.headerCell }}>
                ยอมรวมทั้งหมด (บาท)
              </TableCell>
              <TableCell sx={{ ...styles.headerCell }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableDataSliced.map((row) => {
              return (
                <TableRow key={row.id} sx={styles.tableRow}>
                  <TableCell sx={styles.tableCell}>
                    <Text>
                      {row.productName && row.productCode
                        ? `${row.productName} (${row.productCode})`
                        : ""}
                    </Text>
                  </TableCell>
                  <TableCell sx={styles.tableCell}>
                    <Text>
                      {row.price ? Number(row.price).toLocaleString() : ""}
                    </Text>
                  </TableCell>
                  <TableCell sx={{ ...styles.tableCell, width: 100 }}>
                    {!isView ? (
                      <TextField
                        value={Number(row.quantity).toLocaleString()}
                        type={""}
                        maxLength={7}
                        style={{ textAlign: "center" }}
                        onChangeText={(value) => {
                          let init = { ...row };
                          init.quantity = Number(formatNumber(value));
                          console.log("QUANTITY", init.quantity);
                          onChangeProduct && onChangeProduct(init);
                        }}
                      />
                    ) : (
                      <Text>{row.quantity ? row.quantity : "0"}</Text>
                    )}
                  </TableCell>
                  <TableCell sx={styles.tableCell}>
                    <Text>
                      {row.discount
                        ? Number(row.discount).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })
                        : "0"}
                    </Text>
                  </TableCell>
                  <TableCell sx={styles.tableCell}>
                    <Text>
                      {row.totalPrice
                        ? Number(row.totalPrice).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })
                        : ""}
                    </Text>
                  </TableCell>
                  <TableCell sx={styles.tableCell}>
                    {!isView ? (
                      <TouchableOpacity
                        onPress={() => {
                          onDelete && onDelete(row);
                        }}
                      >
                        <BiTrash style={{ fontSize: 20, color: "#E30505" }} />
                      </TouchableOpacity>
                    ) : null}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </View>
  );
};

const styles = {
  tableContainer: {
    overflowY: "scroll",
    scrollbarWidth: "thin",
    scrollbarColor: "#c7c7c7 #f5f5f5",
    flex: 1,
  },
  table: {
    border: "none",
    minWidth: 650,
  },
  tableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: "rgba(255, 255, 255, 1)",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "rgba(255, 241, 241, 1)",
    },
  },
  tableCell: {
    borderBottom: "none",
    whiteSpace: "nowrap",
    wordBreak: "break-word",
    alignItems: "center",
    justifyContent: "center",
  },
  headerCell: {
    color: "rgba(165, 88, 90, 1)",
    alignItems: "center",
    justifyContent: "center",
  },
};

export default ProductShoppingTable;
