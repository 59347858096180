import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { View, Text, TouchableOpacity } from "react-native-web";
import moment from "moment-timezone";
//component
import { GroupIcon } from "../../../asset/images/icons/iconsLib";
import OptionButton from "../../../components/button/OptionButton";
import DropdownPopover from "../../../components/dropdown/DropdownPopover";
import apiPath from "../../../services/configs/apiPath";
import { post } from "../../../functions/fetch/fetch";
import { useDialog } from "../../../components/modal/Popup";
import { useSelector } from "react-redux";
const ContentMain = (props) => {
  const { data = [], docId = 1 } = props;
  const { onSelect, onRefresh, onEdit } = props;

  const [list, setList] = useState([]);

  useEffect(() => {
    if (data) {
      const filteredData = data.filter((item) => {
        return item.docs_id === docId;
      });
      if (filteredData.length > 0) {
        setList(filteredData[0].folders);
        console.log(filteredData[0].folders);
      }
    }
  }, [data]);
  return (
    <View style={{ width: "100%", flex: 1 }}>
      <Grid container spacing={2}>
        {list.map((item) => {
          return (
            <MainInfoBox
              docId={docId}
              data={item}
              onSelect={(id) => {
                onSelect && onSelect(id);
              }}
              onRefresh={() => {
                onRefresh && onRefresh();
              }}
              onEdit={(data) => {
                onEdit && onEdit(data);
              }}
            />
          );
        })}
      </Grid>
    </View>
  );
};

const MainInfoBox = (props) => {
  const { user } = useSelector((state) => {
    return state;
  });
  const { data, docId } = props;
  const { onSelect, onRefresh, onEdit } = props;
  const dialog = useDialog();

  const handleEdit = async (data) => {
    onEdit && onEdit(data);
  };

  const handleDelete = async () => {
    let confirm = await dialog.confirm({
      title: `ยืนยันการลบ ${data.folder_name}`,
      message: `คุณต้องการลบโฟลเดอร์ ${data.folder_name} ใช่ หรือ ไม่ ?`,
    });
    if (!confirm) {
      console.log(data);
      return;
    }

    let url = apiPath.university.updateFolder;
    let params = {
      university_docs_type_id: docId,
      folder_name: data.folder_name,
      folder_id: data.id,
      status: true,
    };
    console.log(params);
    let { status, response } = await post(url, params, true);
    if (!status) {
      dialog.alert({ message: "เกิดข้อผิดพลาด" });
      return false;
    }
    if (response.data) {
      console.log(response.data);
      await dialog.alert({ message: "ลบข้อมูลบสำเร็จ" });
      onRefresh && onRefresh();
    }
  };

  return (
    <Grid item xs={12} md={4} lg={3}>
      <View style={{ flex: 1 }}>
        {(user && user.userRole === "S") || user.userRole === "SS" ? (
          <View
            style={{ position: "absolute", top: 15, right: 10, zIndex: 10 }}
          >
            <DropdownPopover
              list={[
                { label: "แก้ไข", value: "edit" },
                { label: "ลบ", value: "delete" },
              ]}
              showIconOnly={true}
              onSelect={(value) => {
                if (value === "delete") {
                  handleDelete();
                } else if (value === "edit") {
                  handleEdit(data);
                }
              }}
            />
          </View>
        ) : null}
        <TouchableOpacity
          style={Styles.container}
          activeOpacity={0.8}
          onPress={() => {
            onSelect && onSelect(data);
          }}
        >
          <View style={Styles.contentHeader}>
            <GroupIcon color={"#AA4942"} />
            <View style={{ width: 5 }} />
            <Text style={Styles.textTitle}>{data.folder_name}</Text>
          </View>
          <View style={{ height: 5 }} />
          <Text style={Styles.textSub}>
            {`แก้ไขล่าสุด ${moment(data.update_date).format(
              "DD/MM/YYYY HH:mm"
            )} โดย ${data.name_update_by}`}
          </Text>
        </TouchableOpacity>
      </View>
    </Grid>
  );
};

const Styles = {
  container: {
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
    borderRadius: 8,
    overflow: "hidden",
    padding: 10,
    zIndex: 0,
  },
  contentHeader: {
    flexDirection: "row",
  },
  textTitle: {
    fontWeight: "bold",
  },
  textSub: {
    fontSize: 12,
    color: "rgba(138, 145, 161, 1)",
  },
};

export default ContentMain;
