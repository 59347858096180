import React, { useMemo, useEffect, lazy, Suspense, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { ModalProvider } from "react-context-modals";
import { useSelector, useDispatch, useStore } from "react-redux";
import { View } from "react-native-web";
import Cookies from "js-cookie";
//CSS
import "./asset/fonts/kanit.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "shards-ui/dist/css/shards.min.css";
import "rsuite/dist/rsuite.min.css";
import "react-context-modals/dist/main.css";
import "react-image-gallery/styles/scss/image-gallery.scss";
//function
import apiPath from "./services/configs/apiPath";
import { get, post } from "./functions/fetch/fetch";
import { canParseJSON, isLogin } from "./functions/validate/validate";
import { downloadAllPhoneCode } from "./services/api/masterdata/phone";
import {
  downloadAllBanks,
  downloadAllCountry,
  downloadAllRanking,
} from "./services/api/masterdata/address";
import { useDialog } from "./components/modal/Popup";
//UI
//homes
import Home from "./containers/home/home";
import MenuLeftPanel from "./containers/home/MenuLeftPanel";
import NewsContentPage from "./containers/home/NewsContentPage";
//products
import Product from "./containers/product/product";
import ProductInventory from "./containers/product/productInventory";
import ProductAddNew from "./containers/product/productAddNew";
//shoppings
import Shopping from "./containers/shopping/shopping";
import ShoppingMyList from "./containers/shopping/shoppingMyList";
import ShoppingMyAgent from "./containers/shopping/shoppingMyAgent";
import ShoppingAdd from "./containers/shopping/shoppingAdd";
import ShoppingCustomer from "./containers/shopping/shoppingCustomer";
//orders
import Order from "./containers/order/order";
import OrderMyList from "./containers/order/OrderMyList";
import OrderMyAgent from "./containers/order/OrderMyAgent";
import AddOrder from "./containers/order/AddOrder";
import FirstOrder from "./containers/order/FirstOrder";
//Shippinh
import Shipping from "./containers/shipping/shipping";
//Member
import AgentList from "./containers/member/AgentList";
import AgentFreeCourseList from "./containers/member/AgentFreeCourseList";
import AdminList from "./containers/member/AdminList";
import ManageAdmin from "./containers/member/ManageAdmin";
//Reward
import Reward from "./containers/reward/Reward";
import ExchangeReward from "./containers/reward/ExchangeReward";
import MyReward from "./containers/reward/MyReward";
import AddReward from "./containers/reward/AddReward";
//Credit
import CreditInfo from "./containers/credit/CreditInfo";
import CreditHistory from "./containers/credit/CreditHistory";
import Wallet from "./containers/credit/Wallet";
import WalletHistory from "./containers/credit/WalletHistory";
//Report
import ReportOrder from "./containers/report/ReportOrder";
import ReportShopping from "./containers/report/ReportShopping";
import ReportStock from "./containers/report/ReportStock";
import ReportSerialNumber from "./containers/report/ReportSerialNumber";
import ReportProductFlow from "./containers/report/ReportProductFlow";
import ReportCRM from "./containers/report/ReportCRM";
import ReportPromotionQuarter from "./containers/report/ReportPromotionQuarter";
import ReportPositionQuarter from "./containers/report/ReportPositionQuarter";
import ReportCommission from "./containers/report/ReportComission";
import ReportReward from "./containers/report/ReportReward";
import ReportPaymentVoid from "./containers/report/ReportPaymentVoid";
//Setting
import SettingPage from "./containers/setting/SettingPage";
import ManagePage from "./containers/setting/ManagePage";
import SettingBanner from "./containers/setting/SettingBanner";
//Policy
import Policy from "./containers/policy/policy";
//University
import Course from "./containers/university/Course";
import CourseList from "./containers/university/CourseList";
import AddCourse from "./containers/university/AddCourse";
//Event
import EventList from "./containers/event/EventList";
import AddEvent from "./containers/event/AddEvent";
//HandlePage
import HandlePage from "./containers/handle/HandlePage";
import Auth from "./containers/handle/Auth";
//SettingSystem
import SettingCurrency from "./containers/settingSystem/SettingCurrency";
import SettingCourier from "./containers/settingSystem/SettingCourier";
import SettingProfile from "./containers/setting/SettingProfile";
//Customer Out of control
import CustomerPayment from "./containers/shopping/CustomerPayment";
import { getUserInfo } from "./services/api/member/member";
import HeaderMobile from "./containers/home/HeaderMobile";
import SettingArticle from "./containers/setting/SettingArticle";
import NewsHome from "./containers/home/NewsHome";
import ChangePassword from "./containers/login/ChangePassword";
//Suport Admin
import PatchTeamPath from "./containers/supportAdmin/PatchTeamPath";
//PAGES
const Login = lazy(() => import("./containers/login/login"));
const Register = lazy(() => import("./containers/register/register"));
const Playground = lazy(() => import("./containers/playground"));
const Member = lazy(() => import("./containers/member/member"));
const ResetPassword = lazy(() => import("./containers/login/ResetPassword"));

function App() {
  const dispatch = useDispatch();
  const dialog = useDialog();
  const { userAgent } = navigator;

  useEffect(() => {
    if (process.env["REACT_APP_ENV"] === "PROD") {
      console.log = function () {};
    }
    setupAllData();
  }, []);

  const setupAllData = async () => {
    let phoneCodes = await downloadAllPhoneCode();
    let initPhoneCodes = [];
    phoneCodes.forEach((item) => {
      initPhoneCodes.push({
        value: item.phonecode,
        label: `+${item.phonecode} (${item.name}) `,
      });
    });
    let countrys = await downloadAllCountry();
    let initCountrys = [];
    countrys.forEach((item) => {
      initCountrys.push({
        id: item.id,
        value: item.countryCode,
        label: item.countryNameTh,
      });
    });
    let ranking = await downloadAllRanking();

    let banks = await downloadAllBanks();
    let initBanks = [];
    banks.forEach((item) => {
      initBanks.push({
        id: item.id,
        value: item.id,
        label: item.bankName,
      });
    });

    if (isLogin()) {
      let uInfo = await getUserInfo();
      await dispatch({
        type: "SetUser",
        payload: uInfo,
      });
    }

    dispatch({
      type: "SETUPDATA",
    });
    dispatch({
      type: "SETUPDATA/MASTERDATA",
    });
    dispatch({
      type: "SETUPDATA/MASTERDATA/PHONE_CODE_LIST",
      payload: initPhoneCodes,
    });
    dispatch({
      type: "SETUPDATA/MASTERDATA/COUNTRY_LIST",
      payload: initCountrys,
    });
    dispatch({
      type: "SETUPDATA/MASTERDATA/BANK_LIST",
      payload: initBanks,
    });
    dispatch({
      type: "SETUPDATA/MASTERDATA/RANKING_LIST",
      payload: ranking,
    });
  };

  return (
    <Suspense fallback={<div />}>
      <ModalProvider>
        <BrowserRouter>
          <RenderComponent />
        </BrowserRouter>
      </ModalProvider>
    </Suspense>
  );
}

function RenderComponent() {
  //property
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const dialog = useDialog();
  //reducer
  const { page, showMenu } = useSelector((state) => {
    return state;
  });
  //state
  const [pathname, setPathName] = useState(window.location.pathname);
  const [token, setToken] = useState({});

  useMemo(() => {
    if (!page) {
      dispatch({ type: "SetPage", payload: "home" });
    }
    if (isLogin()) {
      setToken(JSON.parse(Cookies.get("rosegoldtoken")));
    }
  }, []);

  useEffect(() => {
    if (!isLogin()) {
      setToken(null);
      return;
    }
    checkTokenExpire();
  }, [Cookies.get("rosegoldtoken")]);

  useEffect(() => {
    const setMinuteDelay = 15;
    const renewInterval = setInterval(
      handleRefreshToken,
      setMinuteDelay * 60 * 1000
    );
    return () => clearInterval(renewInterval);
  }, [token]);

  useEffect(() => {
    if (location.pathname) {
      setPathName(location.pathname);
    }
  }, [location.pathname]);

  async function handleRefreshToken() {
    //call handleRefreshToken
    if (!isLogin()) {
      return;
    }
    if (!token) {
      //try get again
      setToken(JSON.parse(Cookies.get("rosegoldtoken")));
    }
    const { refresh_token } = token;
    if (!refresh_token) {
      return;
    }
    const newToken = await refreshAccessToken(refresh_token);
    if (!newToken) {
      return;
    }
    await Cookies.set("rosegoldtoken", JSON.stringify(newToken), {
      expires: 14,
    });
  }

  const refreshAccessToken = async (refreshToken) => {
    let url = apiPath.auth.renewToken;
    let { status, response } = await post(url, {
      refreshToken: refreshToken,
    });
    return !status ? [] : response.data;
  };

  const checkTokenExpire = async () => {
    let url = apiPath.auth.getInfo;
    let { status, response } = await get(url, {}, true);
    if (!status) {
      logout();
      return;
    }
    checkUseRole();
  };

  const checkUseRole = async () => {
    let url = apiPath.auth.getUserUseRole;
    let { status, response } = await get(url, {}, true);
    if (!status) {
      logout();
    }
    if (response.data) {
      if (response.data.error) {
        logout();
      }
      dispatch({
        type: "SetUserUseRole",
        payload: canParseJSON(response.data)
          ? JSON.parse(response.data)
          : response.data,
      });
    }
    checkActiveUser();
  };

  const checkActiveUser = async () => {
    //check active user
    let url = apiPath.member.getUserActiveInfo;
    let { status, response } = await get(url, {}, true);
    if (!status || response.data.error) {
      logout();
    }
    const { active } = response.data;
    if (
      active !== "Y" &&
      active !== "W" &&
      pathname !== "/register" &&
      pathname !== "/firstorder" &&
      pathname !== "/firstOrder" &&
      pathname !== "/login"
    ) {
      await dialog.alert({
        title: "แจ้งเตือน",
        message: "บัญชีผู้ใช้ไม่ได้รับอนุญาตให้เข้าใช้งาน",
      });
      logout();
      return;
    }
    // if (active === "W") {
    //   let value = await dialog.alertTag({
    //     message: "กรุณาสั่งซื้อสินค้าที่เมนู ขายปลีกและลูกค้า",
    //     type: "success",
    //   });
    //   if (value) {
    //     navigate("shoppingAdd");
    //   }
    // }

    //load userInfo
    let uInfo = await getUserInfo();
    dispatch({ type: "SetUser", payload: { ...uInfo } });
  };

  const logout = () => {
    Cookies.remove("token");
    Cookies.remove("rosegoldtoken");
    dispatch({ type: "SetUser", payload: null });
    navigate("/login");
  };

  return (
    <React.Fragment>
      {isLogin() ? <HeaderMobile /> : null}
      <View
        style={{ flex: 1, flexDirection: "row", backgroudColor: "#F5F5F5" }}
      >
        <MenuLeftPanel pathname={pathname} />
        <Routes>
          <Route path="/" element={isLogin() ? <Home /> : <Login />} />
          <Route path="/login" element={!isLogin() ? <Login /> : <Home />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/changepassword" element={<ChangePassword />} />
          <Route path="/register/:agentCode" element={<Register />} />
          <Route path="/home" element={<Home />}></Route>
          <Route path="/news/:id" element={<NewsContentPage />}></Route>
          <Route path="/playground" element={<Playground />} />
          <Route
            path="/inventory"
            element={
              <Auth pathname="/inventory">
                <ProductInventory />
              </Auth>
            }
          />
          <Route
            path="/addProduct"
            element={
              <Auth pathname="/addProduct">
                <ProductAddNew />
              </Auth>
            }
          />
          <Route
            path="/shoppingMyList"
            element={
              <Auth pathname="/shoppingMyList">
                <ShoppingMyList />
              </Auth>
            }
          />
          <Route
            path="/shoppingMyAgent"
            element={
              <Auth pathname="/shoppingMyAgent">
                <ShoppingMyAgent />
              </Auth>
            }
          />
          <Route
            path="/shoppingAdd"
            element={
              <Auth pathname="/shoppingAdd">
                <ShoppingAdd />
              </Auth>
            }
          />
          <Route
            path="/shoppingCustomer"
            element={
              <Auth pathname="/shoppingCustomer">
                <ShoppingCustomer />
              </Auth>
            }
          />
          <Route
            path="/orderMyList"
            element={
              <Auth pathname="/orderMyList">
                <OrderMyList />
              </Auth>
            }
          />
          <Route
            path="/orderMyAgent"
            element={
              <Auth pathname="/orderMyAgent">
                <OrderMyAgent />
              </Auth>
            }
          />
          <Route
            path="/addOrder"
            element={
              <Auth pathname="/addOrder">
                <AddOrder />
              </Auth>
            }
          />
          <Route
            path="firstOrder"
            element={isLogin() ? <FirstOrder /> : <Login />}
          />

          <Route
            path="/shipping"
            element={
              <Auth pathname="/shipping">
                <Shipping />
              </Auth>
            }
          />

          <Route
            path="/member"
            element={
              <Auth pathname="/member">
                <Member />
              </Auth>
            }
          />
          <Route
            path="/agentList"
            element={
              <Auth pathname="/agentList">
                <AgentList />
              </Auth>
            }
          />
          <Route
            path="/agentFreeCourseList"
            element={
              <Auth pathname="/agentFreeCourseList">
                <AgentFreeCourseList />
              </Auth>
            }
          />
          <Route
            path="/adminList"
            element={
              <Auth pathname="/adminList">
                <AdminList />
              </Auth>
            }
          />
          <Route
            path="/manageAdmin"
            element={
              <Auth pathname="/manageAdmin">
                <ManageAdmin />
              </Auth>
            }
          />

          <Route
            path="/reward"
            element={
              <Auth pathname="/reward">
                <Reward />
              </Auth>
            }
          />
          <Route
            path="/exchangeReward"
            element={
              <Auth pathname="/exchangeReward">
                <ExchangeReward />
              </Auth>
            }
          />
          <Route
            path="/myReward"
            element={
              <Auth pathname="/myReward">
                <MyReward />
              </Auth>
            }
          />
          <Route
            path="/addReward"
            element={
              <Auth pathname="/addReward">
                <AddReward />
              </Auth>
            }
          />

          <Route
            path="/creditInfo"
            element={
              <Auth pathname="/creditInfo">
                <CreditInfo />
              </Auth>
            }
          />
          <Route
            path="/creditHistory"
            element={
              <Auth path="/creditHistory">
                <CreditHistory />
              </Auth>
            }
          />
          <Route
            path="/wallet"
            element={
              <Auth path="/wallet">
                <Wallet />
              </Auth>
            }
          />

          <Route
            path="/walletHistory"
            element={
              <Auth path="/walletHistory">
                <WalletHistory />
              </Auth>
            }
          />

          <Route
            path="/reportOrder"
            element={
              <Auth pathname="/reportOrder">
                <ReportOrder />
              </Auth>
            }
          />

          <Route
            path="/reportShopping"
            element={
              <Auth pathname="/reportShopping">
                <ReportShopping />
              </Auth>
            }
          />

          <Route
            path="/reportStock"
            element={
              <Auth pathname="/reportStock">
                <ReportStock />
              </Auth>
            }
          />

          <Route
            path="/reportSerialNumber"
            element={
              <Auth pathname="/reportSerialNumber">
                <ReportSerialNumber />
              </Auth>
            }
          />

          <Route
            path="/reportProductFlow"
            element={
              <Auth pathname="/reportProductFlow">
                <ReportProductFlow />
              </Auth>
            }
          />

          <Route
            path="/reportCrm"
            element={
              <Auth pathname="/reportCrm">
                <ReportCRM />
              </Auth>
            }
          />

          <Route
            path="/reportPromotionQuarter"
            element={
              <Auth pathname="/reportPromotionQuarter">
                <ReportPromotionQuarter />
              </Auth>
            }
          />

          <Route
            path="/reportPositionQuarter"
            element={
              <Auth pathname="/reportPositionQuarter">
                <ReportPositionQuarter />
              </Auth>
            }
          />

          <Route
            path="/reportCommission"
            element={
              <Auth pathname="/reportCommission">
                <ReportCommission />
              </Auth>
            }
          />

          <Route
            path="/reportReward"
            element={
              <Auth pathname="/reportReward">
                <ReportReward />
              </Auth>
            }
          />

          <Route
            path="/reportPaymentVoid"
            element={
              <Auth pathname="/reportPaymentVoid">
                <ReportPaymentVoid />
              </Auth>
            }
          />

          <Route
            path="/settingPage"
            element={
              <Auth pathname="/settingPage">
                <SettingPage />
              </Auth>
            }
          />

          <Route
            path="/managePage"
            element={
              <Auth pathname="/managePage">
                <ManagePage />
              </Auth>
            }
          />
          <Route
            path="/settingBanner"
            element={
              <Auth pathname="/settingBanner">
                <SettingBanner />
              </Auth>
            }
          />
          <Route
            path="/settingArticle"
            element={
              <Auth pathname="/settingArticle">
                <SettingArticle />
              </Auth>
            }
          />
          <Route
            path="/policy"
            element={
              <Auth pathname="/policy">
                <Policy />
              </Auth>
            }
          />
          <Route
            path="/course"
            element={
              <Auth pathname="/course">
                <Course />
              </Auth>
            }
          />
          <Route
            path="/courseList"
            element={
              <Auth pathname="/courseList">
                <CourseList />
              </Auth>
            }
          />
          <Route
            path="/addCourse"
            element={
              <Auth pathname="/addCourse">
                <AddCourse />
              </Auth>
            }
          />
          <Route
            path="/eventList"
            element={
              <Auth pathname="/eventList">
                <EventList />
              </Auth>
            }
          />
          <Route
            path="/addEvent"
            element={
              <Auth pathname="/addEvent">
                <AddEvent />
              </Auth>
            }
          />
          <Route
            path="/settingCurrency"
            element={
              <Auth pathname="/settingCurrency">
                <SettingCurrency />
              </Auth>
            }
          />
          <Route
            path="/settingCourier"
            element={
              <Auth pathname="/settingCourier">
                <SettingCourier />
              </Auth>
            }
          />
          <Route
            path="/settingProfile"
            element={
              <Auth pathname="/settingProfile">
                <SettingProfile />
              </Auth>
            }
          />
          <Route
            path="/salespayment/:paymentCode"
            element={<CustomerPayment />}
          />
          <Route
            path="/patchteampath"
            element={              
            <Auth pathname="/patchteampath" forAdmin={true}>
              <PatchTeamPath />
            </Auth>}
          />
          <Route path="*" element={<HandlePage />} />
        </Routes>
      </View>
    </React.Fragment>
  );
}

export default App;
