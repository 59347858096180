import React, { useEffect, useState } from "react";
import { Text, View } from "react-native-web";
import { Grid } from "@mui/material";
import { Divider } from "rsuite";
//component
import Button from "../../components/button/Button";
import UploadImage from "../../components/uploadbox/UploadImage";
import Dropdown from "../../components/dropdown/Dropdown";
import TextField from "../../components/textField/TextField";
import UploadBox from "../../components/uploadbox/UploadBox";
import CheckBoxListV2 from "../../components/checkbox/CheckBoxListV2";
import { get, put } from "../../functions/fetch/fetch";
//images
//function
import { downloadAllUserRole } from "../../services/api/auth/user";
import { downloadAllPhoneCode } from "../../services/api/masterdata/phone";
import { formatNumberString } from "../../functions/format/numberFormat";
import {
  canParseJSON,
  validateEmail,
  validatePasswordV2,
} from "../../functions/validate/validate";
import { useDialog } from "../../components/modal/Popup";
import {
  exportDayOfMonthArray,
  getMonth,
  year,
} from "../../functions/format/dateFormat";
import moment from "moment-timezone";
import { upload } from "../../functions/fetch/fetch";
import {
  base64ToFile,
  dataURItoBlob,
} from "../../functions/format/convertFile";
import apiPath from "../../services/configs/apiPath";
import { post } from "../../functions/fetch/fetch";
import { setDate } from "rsuite/esm/utils/dateUtils";
import { useNavigate } from "react-router-dom";
import { isIE } from "rsuite/esm/utils";

const ManageAdmin = (props) => {
  //property
  const dialog = useDialog();
  const navigate = useNavigate();
  //props
  const { isEdit, editValue } = props;
  const { onCancle } = props;
  //state
  const [userRoles, setUserRoles] = useState([]);
  const [userRoleData, setUserRoleData] = useState([]);
  const [useRole, setUseRole] = useState([]);
  const [phoneCode, setPhoneCode] = useState([]);
  const [selectDay, setSelectDay] = useState([]);
  const [selectMonth, setSelectMonth] = useState([]);
  const [selectYear, setSelectYear] = useState([]);

  const [data, setData] = useState({
    imageProfile: null,
    userRole: null,
    name: null,
    nickName: null,
    gender: null,
    citizenId: null,
    phoneCode: null,
    phoneNumber: null,
    birthdate: null,
    birthdateTimeStamp: null,
    email: null,
    lineId: null,
    facebook: null,
    instagram: null,
    password: null,
    rePassword: null,
    documents: null,
    day: null,
    month: null,
    year: null,
    countryCode: "THA",
  });

  useEffect(() => {
    //
    setSelectYear(year().reverse());
    setSelectMonth(getMonth());
  }, []);

  useEffect(() => {
    const downloadAllData = async () => {
      let roles = await downloadAllUserRole();
      roles.forEach((item) => {
        item.useRole = canParseJSON(item.useRole)
          ? JSON.parse(item.useRole)
          : item.useRole;
      });
      let initRole = [];
      roles.forEach((item) => {
        initRole.push({ value: item.level, label: item.nameThai });
      });
      setUserRoles(initRole);
      setUserRoleData(roles);
      let phoneCodes = await downloadAllPhoneCode();
      let initPhoneCodes = [];
      phoneCodes.forEach((item) => {
        initPhoneCodes.push({
          value: item.phonecode,
          label: `+${item.phonecode} (${item.name}) `,
        });
      });
      setPhoneCode(initPhoneCodes);
    };
    downloadAllData();
  }, []);

  useEffect(() => {
    if (data.year && data.month && !data.day) {
      let date = new Date();
      date.setYear(data.year);
      date.setMonth(data.month - 1);
      generateSelectDay(date);
    }
    if (data.year && data.month && data.day) {
      let birthdate = new Date();
      birthdate.setDate(data.day);
      birthdate.setMonth(data.month - 1);
      birthdate.setFullYear(data.year);
      setData({
        ...data,
        birthdate: moment(birthdate).toDate(),
        birthdateTimeStamp: Number(moment(birthdate)),
      });
    }
  }, [data.year, data.month, data.day]);

  useEffect(() => {
    if (userRoles.length > 0 && phoneCode.length > 0) {
      if (isEdit) {
        let birthdate = new Date(editValue.birthdate);
        if (birthdate) {
          const day = birthdate.getDate();
          const month = birthdate.getMonth() + 1;
          const year = birthdate.getFullYear();
          generateSelectDay(birthdate);
          setData({ ...editValue, day: day, month: month, year: year });
          convertUseRole(editValue.useRole);
          dialog.cancle();
          //downloadUserUseRole();
          return;
        }
      }
      dialog.cancle();
    } else {
      dialog.loading();
    }
  }, [editValue, userRoles, phoneCode]);

  useEffect(() => {
    resetData();
  }, [isEdit]);

  const convertUseRole = (useRole) => {
    const findUseRole = userRoleData.find((d) => {
      return d.level === editValue.userRole;
    });
    if (!findUseRole) {
      return;
    }

    let _useRole = JSON.parse(useRole);

    findUseRole.useRole.forEach((item, index) => {
      if (item.enable && !_useRole[index].enable) {
        _useRole[index].active = "N";
        _useRole[index].enable = true;
      }
    });
    setUseRole(_useRole);
  };

  const generateSelectDay = (date) => {
    let days = exportDayOfMonthArray(date);
    let initDays = [];
    days.forEach((item) => {
      initDays.push({ value: item, label: item.toString() });
    });
    setSelectDay(initDays);
  };

  const validation = () => {
    if (!data.userRole) {
      return false;
    }
    if (!data.name) {
      return false;
    }
    if (!data.nickName) {
      return false;
    }
    if (!data.gender) {
      return false;
    }
    if (!data.phoneCode) {
      return false;
    }
    if (!data.phoneNumber) {
      return false;
    }
    if (!data.email) {
      return false;
    }
    if (!data.birthdate) {
      return false;
    }
    if (!isEdit) {
      if (!data.password || !data.rePassword) {
        return false;
      }
    }
    return true;
  };

  const validateUseRole = () => {
    let checkList = [];

    useRole.forEach((item) => {
      if (item.active !== "Y") {
        return;
      }
      if (item.subMenu.length === 0) {
        return;
      }
      let checkSubMenu = false;
      item.subMenu.forEach((element) => {
        if (element.active === "Y") {
          checkSubMenu = true;
        }
      });
      if (!checkSubMenu) {
        checkList.push(item.label);
      }
    });

    return checkList;
  };

  const submit = async () => {
    let checkUseRole = validateUseRole();
    if (checkUseRole.length > 0) {
      let title = "";
      checkUseRole.forEach((e) => {
        title += `${e} ,`;
      });
      await dialog.alert({
        title: "แจ้งเตือน",
        message: `เมนู ${title} ยังไม่ได้เปิดสิทธิเมนูย่อย`,
      });
      return;
    }

    if (!validation()) {
      await dialog.alert({
        title: "แจ้งเตือน",
        message: "กรุณากรอกข้อมูลให้ครบถ้วน",
      });
      console.log(data);
      return;
    }
    if (!data.imageProfile) {
      await dialog.alert({
        title: "แจ้งเตือน",
        message: "กรุณาอัพโหลดรูปโปรไฟล์",
      });
      return;
    }
    if (!validateEmail(data.email)) {
      await dialog.alert({
        title: "แจ้งเตือน",
        message: "รูปแบบอีเมลไม่ถูกต้อง",
      });
      return;
    }

    if (data.password !== data.rePassword && !isEdit) {
      await dialog.alert({
        title: "แจ้งเตือน",
        message: "รหัสผ่าน และยืนยันรหัสผ่านไม่ตรงกัน",
      });
      return;
    }
    if (!validatePasswordV2(data.password) && !isEdit) {
      await dialog.alert({
        title: "แจ้งเตือน",
        message:
          "รหัสผ่านอย่างน้อย 8 หลัก ประกอบไปด้วย A-Z, a-z, 1-0 และตัวอักษรพิเศษอย่างน้อยหนึ่งตัว",
      });
      return;
    }

    if (!useRole) {
      await dialog.alert({
        title: "แจ้งเตือน",
        message: "กรุณาตั้งสิทธิการใช้งาน",
      });
      return;
    }

    let params = {
      ...data,
      active: "Y",
      useRole: JSON.stringify(useRole),
    };

    if (data.imageProfile instanceof File) {
      const uploadImages = await upload(
        [data.imageProfile],
        `${process.env["REACT_APP_ENV"]}/Images/Register/Profile`
      );
      params.imageProfile = uploadImages.response.data.data[0];
    }
    if (!isEdit) {
      let uploadImages2;
      if (data.documents.length > 0) {
        uploadImages2 = await upload(
          data.documents,
          "Images/Register/ApproveDocument"
        );
      }
      params.documents = uploadImages2 ? uploadImages2.response.data.data : [];
    }
    console.log("update", params);
    if (isEdit) {
      let url = apiPath.member.updateAdmin;
      let { response } = await put(url, params, true);
      if (response.data.error) {
        await dialog.alert({
          title: "แจ้งเตือน",
          message: response.data.error,
        });
        return;
      }
    } else {
      let url = apiPath.auth.registerAdmin;
      let { response } = await post(url, params, true);
      if (response.data.error) {
        await dialog.alert({
          title: "แจ้งเตือน",
          message: response.data.error,
        });
        return;
      }
    }

    await dialog.alert({ title: "แจ้งเตือน", message: "บันทึกข้อมูลสำเร็จ" });
    if (isEdit) {
      onCancle && onCancle();
    } else {
      navigate("/adminList");
    }
  };

  const close = () => {
    if (isEdit) {
      onCancle && onCancle();
    } else {
      navigate("/adminList");
    }
  };

  const resetData = () => {
    setData({
      imageProfile: null,
      userRole: null,
      name: null,
      nickName: null,
      gender: null,
      citizenId: null,
      phoneCode: null,
      phoneNumber: null,
      birthdate: null,
      birthdateTimeStamp: null,
      email: null,
      lineId: null,
      facebook: null,
      instagram: null,
      password: null,
      rePassword: null,
      documents: null,
      day: null,
      month: null,
      year: null,
      countryCode: "THA",
    });
  };

  return (
    <View style={Styles.container}>
      <Grid container style={Styles.topContainer} spacing={2}>
        <Grid item xs={12} md={8} lg={9} xl={10}>
          <View style={{ flex: 1 }}>
            <Text style={Styles.textTopContainer1}>
              {isEdit ? "แก้ไขผู้ดูแลระบบ" : "เพิ่มผู้ดูแลระบบ"}
            </Text>
            <Text style={Styles.textTopContainer2}>
              {isEdit
                ? "สมาชิก / แก้ไขผู้ดูแลระบบ"
                : "สมาชิก / เพิ่มผู้ดูแลระบบ"}
            </Text>
          </View>
        </Grid>
      </Grid>
      <View style={{ height: 30 }} />

      <View style={Styles.contentContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} lg={6} xl={4}>
            <View style={{ height: 10 }}></View>
            <Text
              style={{
                fontSize: "clamp(16px,2vh,20px)",
                fontWeight: "bold",
                color: "rgba(15, 16, 17, 1)",
              }}
            >
              รายละเอียดผู้ดูแลระบบ
            </Text>
          </Grid>
        </Grid>
        <Divider />
        <View style={{ width: "100%" }}>
          <View
            style={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <UploadImage
              value={data ? data.imageProfile : ""}
              style={{ width: 200, height: 200 }}
              onChangeImage={(image) => {
                setData({
                  ...data,
                  imageProfile: base64ToFile(image),
                });
              }}
            />
            <View style={{ marginTop: 16 }} />
            <Text style={Styles.textImageMark}>
              รองรับไฟล์ JPG, PNG, JPEGS เท่านั้น
            </Text>
          </View>
          <View style={{ height: 20 }} />

          <Grid container spacing={2} style={{ zIndex: 100 }}>
            <Grid item xs={12} md={6} style={{ zIndex: 9 }}>
              <Text style={Styles.titleText}>
                กลุ่มผู้ดูแลระบบ{" "}
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Dropdown
                  value={data.userRole}
                  valueType={"string"}
                  placeholder={"เลือกกลุ่มผู้ดูแลระบบ"}
                  list={userRoles}
                  onChange={(values) => {
                    const { value, label } = values;
                    setData({
                      ...data,
                      userRole: value,
                    });
                    const findUseRole = userRoleData.find((d) => {
                      return d.level === value;
                    });
                    if (findUseRole) {
                      setUseRole(findUseRole.useRole);
                    }
                  }}
                />
              </View>
            </Grid>
            {false && (
              <Grid item xs={12} md={6}>
                <Text style={Styles.titleText}>รหัสตัวแทน</Text>
                <View style={{ height: 8 }} />
                <TextField
                  iconName={""}
                  isInvalid={false}
                  placeholder={"ระบุรหัสตัวแทน"}
                  errorMessage={""}
                  onChangeText={(value) => {
                    setData({
                      ...data,
                      agentCode: value,
                    });
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <Text style={Styles.titleText}>
                ชื่อ - นามสกุล{" "}
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />
              <TextField
                value={data.name ? data.name : ""}
                iconName={""}
                isInvalid={false}
                placeholder={"ระบุชื่อ - นามสกุล"}
                errorMessage={""}
                onChangeText={(value) => {
                  setData({
                    ...data,
                    name: value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Text style={Styles.titleText}>
                ชื่อเล่น <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />
              <TextField
                value={data.nickName ? data.nickName : ""}
                iconName={""}
                isInvalid={false}
                placeholder={"ระบุชื่อเล่น"}
                errorMessage={""}
                onChangeText={(value) => {
                  setData({
                    ...data,
                    nickName: value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Text style={Styles.titleText}>
                เพศ <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />
              <Dropdown
                value={data.gender}
                valueType={"string"}
                placeholder={"เลือกเพศ"}
                list={[
                  { value: "M", label: "ชาย" },
                  { value: "F", label: "หญิง" },
                ]}
                onChange={(values) => {
                  const { value, label } = values;
                  setData({
                    ...data,
                    gender: value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Text style={Styles.titleTextField}>
                เลขบัตรประชาชน{" "}
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}> *</Text>
              </Text>
              <View style={{ height: 8 }} />
              <TextField
                value={data.citizenId}
                iconName={""}
                isInvalid={false}
                placeholder={"ระบุเลขบัตรประชาชน"}
                errorMessage={""}
                onChangeText={(value) => {
                  setData({
                    ...data,
                    citizenId: formatNumberString(value),
                  });
                }}
                maxLength={13}
              />
            </Grid>

            <Grid item xs={12} md={6} style={{ zIndex: 8 }}>
              <Text style={Styles.titleText}>
                เบอร์โทรศัพท์{" "}
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />
              <View style={{ flexDirection: "row" }}>
                <View style={{ width: 120 }}>
                  <Dropdown
                    value={data.phoneCode}
                    valueType={"number"}
                    placeholder={""}
                    list={phoneCode}
                    onChange={(values) => {
                      const { value, label } = values;
                      setData({
                        ...data,
                        phoneCode: value.toString(),
                      });
                    }}
                  />
                </View>
                <View style={{ width: 10 }} />
                <TextField
                  value={data.phoneNumber}
                  iconName={""}
                  isInvalid={false}
                  placeholder={"ระบุเบอร์โทรศัพท์"}
                  errorMessage={""}
                  onChangeText={(value) => {
                    setData({
                      ...data,
                      phoneNumber: formatNumberString(value),
                    });
                  }}
                />
              </View>
            </Grid>
            <Grid item xs={12}>
              <Text
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                  color: "rgba(15, 16, 17, 1)",
                }}
              >
                วัน เดือน ปีเกิด
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}> *</Text>
              </Text>
            </Grid>
            <Grid item xs={12} md={4} style={{ zIndex: 7 }}>
              <Text style={Styles.titleText}>วันที่</Text>
              <View style={{ height: 8 }} />
              <Dropdown
                value={data.day ? data.day : null}
                valueType={"number"}
                placeholder={"เลือกวันที่"}
                disable={!data.year || !data.month}
                list={selectDay}
                onChange={(values) => {
                  const { value, label } = values;
                  setData({
                    ...data,
                    day: value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} style={{ zIndex: 6 }}>
              <Text style={Styles.titleText}>เดือน</Text>
              <View style={{ height: 8 }} />
              <Dropdown
                value={data.month}
                valueType={"number"}
                placeholder={"เลือกเดือน"}
                list={selectMonth}
                onChange={(values) => {
                  const { value, label } = values;
                  setData({
                    ...data,
                    month: value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} style={{ zIndex: 5 }}>
              <Text style={Styles.titleText}>ปี (ค.ศ.)</Text>
              <View style={{ height: 8 }} />
              <Dropdown
                value={data.year}
                valueType={"number"}
                placeholder={"เลือกปี"}
                list={selectYear}
                onChange={(values) => {
                  const { value, label } = values;
                  setData({
                    ...data,
                    year: value,
                  });
                }}
              />
            </Grid>

            {false && (
              <Grid item xs={12} md={6} style={{ zIndex: 0 }}>
                <Text style={Styles.titleText}>
                  อีเมล <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
                </Text>
                <View style={{ height: 8 }} />
                <TextField
                  iconName={""}
                  isInvalid={false}
                  placeholder={"ระบุอีเมล"}
                  errorMessage={""}
                  onChangeText={(value) => {
                    setData({
                      ...data,
                      email: value,
                    });
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12} md={6} style={{ zIndex: 0 }}>
              <Text style={Styles.titleText}>LINE ID</Text>
              <View style={{ height: 8 }} />
              <TextField
                value={data.lineId}
                iconName={""}
                isInvalid={false}
                placeholder={"ระบุ LINE ID"}
                errorMessage={""}
                onChangeText={(value) => {
                  setData({
                    ...data,
                    lineId: value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Text style={Styles.titleText}>Facebook</Text>
              <View style={{ height: 8 }} />
              <TextField
                value={data.facebook}
                iconName={""}
                isInvalid={false}
                placeholder={"ระบุ Facebook"}
                errorMessage={""}
                onChangeText={(value) => {
                  setData({
                    ...data,
                    facebook: value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Text style={Styles.titleText}>Instagram</Text>
              <View style={{ height: 8 }} />
              <TextField
                value={data.instagram}
                iconName={""}
                isInvalid={false}
                placeholder={"ระบุ Instargram"}
                errorMessage={""}
                onChangeText={(value) => {
                  setData({
                    ...data,
                    instagram: value,
                  });
                }}
              />
            </Grid>
          </Grid>
        </View>
        <View style={{ height: 24 }} />
        <Text
          style={{
            fontSize: 16,
            fontWeight: "bold",
            color: "rgba(15, 16, 17, 1)",
          }}
        >
          ข้อมูลบัญชีผู้ใช้
        </Text>
        <View style={{ height: 24 }} />

        <Grid container spacing={2} style={{ zIndex: 0 }}>
          <Grid item xs={12} style={{ zIndex: 0 }}>
            <Text style={Styles.titleText}>
              อีเมล <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
            </Text>
            <View style={{ height: 8 }} />
            <TextField
              value={data.email}
              disable={isEdit}
              iconName={""}
              isInvalid={false}
              placeholder={"ระบุอีเมล"}
              errorMessage={""}
              onChangeText={(value) => {
                setData({
                  ...data,
                  email: value,
                });
              }}
            />
          </Grid>
          {false && (
            <Grid item xs={12}>
              <Text style={Styles.titleTextField}>
                ชื่อบัญชีผู้ใช้{" "}
                <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
              </Text>
              <View style={{ height: 8 }} />
              <TextField
                iconName={""}
                isInvalid={false}
                placeholder={"ระบุชื่อบัญชีผู้ใช้"}
                errorMessage={""}
                onChangeText={(value) => {
                  setData({
                    ...data,
                    username: value,
                  });
                }}
              />
            </Grid>
          )}
          {!isEdit ? (
            <React.Fragment>
              <Grid item xs={12} md={6}>
                <Text style={Styles.titleTextField}>รหัสเข้าใช้งาน</Text>
                <View style={{ height: 8 }} />
                <TextField
                  iconName={""}
                  isInvalid={false}
                  placeholder={"ระบุรหัสเข้าใช้งาน"}
                  errorMessage={""}
                  type={"password"}
                  onChangeText={(value) => {
                    setData({
                      ...data,
                      password: value,
                    });
                  }}
                />
                <View style={{ marginTop: 5, marginBottom: 0 }}>
                  <Text
                    style={{
                      fontSize: 12,
                      color: "#696D79",
                      position: "absolute",
                    }}
                  >
                    รหัสผ่านอย่างน้อย 8 หลัก ประกอบไปด้วย A-Z, a-z, 1-0
                    และตัวอักษรพิเศษอย่างน้อยหนึ่งตัว
                  </Text>
                </View>
                <span
                  className="d-block d-md-none"
                  style={{ marginBottom: 25 }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Text style={Styles.titleTextField}>ยืนยันรหัสเข้าใช้งาน</Text>
                <View style={{ height: 8 }} />
                <TextField
                  iconName={""}
                  isInvalid={false}
                  placeholder={"ระบุยืนยันรหัสเข้าใช้งาน"}
                  errorMessage={""}
                  type={"password"}
                  onChangeText={(value) => {
                    setData({
                      ...data,
                      rePassword: value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={12}>
                <Text style={Styles.titleBold}>หลักฐานหน้าบัตรประชาชน</Text>
                <View style={{ height: 16 }} />
                <UploadBox
                  accept={"image/png, image/gif, image/jpeg"}
                  onChangeImage={(images) => {
                    console.log("uploadbox", images);
                    setData({
                      ...data,
                      documents: images,
                    });
                  }}
                />
              </Grid>
            </React.Fragment>
          ) : null}
        </Grid>
        <View style={{ height: 24 }} />
        {useRole.length > 0 ? (
          <Text
            style={{
              fontSize: 16,
              fontWeight: "bold",
              color: "rgba(15, 16, 17, 1)",
            }}
          >
            สิทธิการเข้าใช้งาน
          </Text>
        ) : null}
        <View style={{ height: 24 }} />

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CheckBoxListV2
              list={useRole}
              onCheck={(values) => {
                if (values.value === "home" && values.active !== "Y") {
                  dialog.alert({
                    title: "แจ้งเตือน",
                    message: "ไม่สามารถเอา หน้าหลัก ออกได้",
                  });
                  return;
                }
                let findIndex = useRole.findIndex((item) => {
                  return item.value === values.value;
                });
                let init = useRole;
                init[findIndex] = { ...values };
                setUseRole([...init]);
              }}
            />
          </Grid>
        </Grid>

        <View style={{ height: 40 }} />
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            buttonText={"ยกเลิก"}
            color={"primary"}
            style={{ flex: 1, maxWidth: 400 }}
            isBorder={true}
            onClick={() => {
              close();
            }}
          />
          <View style={{ width: 20 }} />
          <Button
            buttonText={isEdit ? "บันทึก" : "เพิ่มผู้ดูแลระบบ"}
            color={validation() ? "primary" : "disable"}
            style={{ flex: 1, maxWidth: 400 }}
            isBorder={false}
            onClick={() => {
              submit();
            }}
          />
        </View>
      </View>
      <View style={{ height: 100 }} />
    </View>
  );
};

const Styles = {
  container: {
    flex: 1,
    height: "100vh",
    padding: "1.5%",
  },
  topContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  contentContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  textTopContainer1: {
    fontSize: "clamp(18px, 3vw, 22px)",
    fontWeight: "bold",
    color: "#AA4942",
  },
  textTopContainer2: {
    fontSize: "clamp(12px, 3vw, 16px)",
    color: "#696D79",
  },
  topButtonText: {
    fontSize: "clamp(14px, 3vw, 18px)",
  },
  textImageMark: {
    fontSize: 14,
    color: "#696D79",
  },
};

export default ManageAdmin;
