import React, { useEffect, useState } from "react";
import { Text, View } from "react-native-web";
import { Grid } from "@mui/material";
import { Divider } from "rsuite";
//component
import Button from "../../components/button/Button";
import SearchBox from "../../components/textField/SearchBox";
import DatePicker from "../../components/calendar/DatePicker";
import SwitchMenu from "../../components/switch/SwitchMenu";
import { useDialog } from "../../components/modal/Popup";
//images
import noImage from "../../asset/images/component/noImage.jpeg";
//function
import { downloadAllAgent } from "../../services/api/member/member";
import AgentListTable from "./component/AgentListTable";
import AgentListTableDisplay from "./component/AgentListTableDisplay";
import AgentListNodeTreeDisplay from "./component/AgentListNodeTreeDisplay";

const AgentList = (props) => {
  //property
  const dialog = useDialog();
  //state
  const [tabSelectMenu, setTableSelectMenu] = useState(0);
  const [agentList, setAgentList] = useState([]);
  const [editValue, setEditValue] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    downloadAllData();
  }, []);

  const downloadAllData = async () => {
    dialog.loading();
    let agents = await downloadAllAgent();
    if (!agents) {
      dialog.cancle();
      return;
    }
    let initAgent = [];
    agents.forEach((item) => {
      initAgent.push(item);
    });
    setAgentList(initAgent);
    console.log(initAgent);
    dialog.cancle();
  };

  return (
    <View style={Styles.container}>
      <Grid container style={Styles.topContainer} spacing={2}>
        <Grid item xs={12} md={8} lg={9} xl={10}>
          <View style={{ flex: 1 }}>
            <Text style={Styles.textTopContainer1}>รายการตัวแทน</Text>
            <Text style={Styles.textTopContainer2}>สมาชิก / รายงานตัวแทน</Text>
          </View>
        </Grid>
      </Grid>
      <View style={{ height: 30 }} />
      <View style={Styles.contentContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <SwitchMenu
              height={40}
              titles={["รายงานตัวแทน", "โครงสร้างสายงาน"]}
              onSelect={(response) => {
                const { index } = response;
                setTableSelectMenu(index);
              }}
              selectColor={{ text: "#AA4942", background: "#FFD7D7" }}
              unSelectColor={{ text: "#0F1011", background: "" }}
            />
          </Grid>
        </Grid>
        {tabSelectMenu === 0 ? (
          <AgentListTableDisplay
            data={agentList}
            onRefresh={() => {
              downloadAllData();
            }}
          />
        ) : (
          <AgentListNodeTreeDisplay />
        )}
      </View>
    </View>
  );
};

const Styles = {
  container: {
    flex: 1,
    height: "100%",
    padding: "1.5%",
  },
  topContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  contentContainer: {
    width: "100%",
    flex: 1,
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  textTopContainer1: {
    fontSize: "clamp(18px, 3vw, 22px)",
    fontWeight: "bold",
    color: "#AA4942",
  },
  textTopContainer2: {
    fontSize: "clamp(12px, 3vw, 16px)",
    color: "#696D79",
  },
  topButtonText: {
    fontSize: "clamp(14px, 3vw, 18px)",
  },
};

export default AgentList;
