import React, { useState, useRef } from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
//Images
import { PlusCircleIcon } from "../../asset/images/icons/iconsLib";

const BoxUploadImage = (props) => {
  const { style, accept } = props;
  const { onSelect } = props;

  const [imageUrl, setImageUrl] = useState(null);

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setImageUrl(reader.result);
      onSelect && onSelect(reader.result);
    };
    reader.readAsDataURL(file);
    // do something with the selected file here
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setImageUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <TouchableOpacity
      activeOpacity={0.9}
      style={{ ...Styles.container, ...style }}
      onPress={() => {
        handleButtonClick();
      }}
    >
      <input
        ref={fileInputRef}
        type="file"
        accept={accept}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <div
        style={{ ...Styles.box }}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <View style={Styles.content}>
          {imageUrl ? (
            <img
              src={imageUrl}
              alt="Uploaded"
              style={{ width: 200, height: 200, objectFit: "cover" }}
            />
          ) : (
            <React.Fragment>
              <PlusCircleIcon
                width={24}
                height={24}
                color={"rgba(138, 145, 161, 1)"}
              />
              <View style={{ height: 10 }} />
              <Text style={Styles.text1}>
                ลากแล้ววางรูป หรือ{" "}
                <Text style={Styles.text2}>เลือกจากคอมพิวเตอร์</Text>
              </Text>
              <View style={{ height: 8 }} />
              <Text style={Styles.textPlaceHolder}>
                รองรับไฟล์ JPG, PNG, JPEGS
              </Text>
            </React.Fragment>
          )}
        </View>
      </div>
    </TouchableOpacity>
  );
};

const Styles = {
  container: {
    width: "100%",
    borderStyle: "dashed",
    borderWidth: 1,
    borderColor: "rgba(213, 215, 221, 1)",
    padding: 4,
    borderRadius: 4,
    justifyContent: "center",
    alignItems: "center",
  },
  box: {
    width: "100%",
    padding: 20,
    backgroundColor: "rgba(248, 248, 248, 1)",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  text1: {
    fontWeight: "bold",
    color: "rgba(10, 10, 10, 1)",
  },
  text2: {
    fontWeight: "bold",
    color: "rgba(222, 124, 120, 1)",
  },
  textPlaceHolder: {
    color: "rgba(185, 189, 199, 1)",
  },
};

export default BoxUploadImage;
