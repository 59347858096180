import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
} from "react-native-web";
//component
import Button from "../../../components/button/Button";
import Dropdown from "../../../components/dropdown/Dropdown";
//icons
import { IoMdClose } from "react-icons/io";
import { PlusCircleIcon } from "../../../asset/images/icons/iconsLib";
//functions
import { arraysHaveSameData } from "../../../functions/validate/validate";
import { find } from "rsuite/esm/utils/ReactChildren";
import { useDialog } from "../../../components/modal/Popup";

const SearchProductLink = (props) => {
  const { currentItem, data, style, value } = props;
  const { onChangeData } = props;
  const dialog = useDialog();

  //state
  const [productList, setProductList] = useState([]);
  const [selectList, setSelectList] = useState([]);
  const [isAdd, setIsAdd] = useState(false);
  const [isSet, setIsSet] = useState(false);

  useEffect(() => {
    if (value && !isSet && value.length > 0 && productList.length > 0) {
      let init = selectList;
      value.forEach((item) => {
        let findProduct = data.filter((d) => {
          return d.id === item;
        });
        if (findProduct.length > 0) {
          let check = selectList.filter((d) => {
            return d.id === item;
          });
          if (check.length > 0) {
            setIsAdd(false);
            return;
          }
        }
        init.push(findProduct[0]);
      });
      setIsSet(true);
      setSelectList(init);
    }
  }, [value, productList]);

  useEffect(() => {
    if (data) {
      let init = [];
      data.forEach((item) => {
        init.push({ value: item.id, label: item.name });
      });
      setProductList(init);
    }
  }, [data]);

  useEffect(() => {
    onChangeData && onChangeData(selectList);
  }, [selectList]);

  return (
    <View style={Styles.containerOut}>
      {isAdd ? (
        <View style={{ flex: 1, zIndex: 9999 }}>
          <Dropdown
            value={[]}
            placeholder={"ค้นหาสินค้า"}
            list={productList}
            onChange={(values) => {
              const { value, label } = values;
              if (value === currentItem.id) {
                dialog.alert({
                  title: "แจ้งเตือน",
                  message: "ไม่สามารถเลือกสินค้าเดียวกันได้",
                });

                return;
              }
              let findProduct = data.filter((d) => {
                return d.id === value;
              });
              if (findProduct.length > 0) {
                let check = selectList.filter((d) => {
                  return d.id === value;
                });
                if (check.length > 0) {
                  setIsAdd(false);
                  return;
                }
              }
              let init = selectList;
              init.push(findProduct[0]);
              setSelectList([...init]);
              setIsAdd(false);
            }}
          />
        </View>
      ) : (
        <View style={{ ...Styles.container, ...style }}>
          <ScrollView horizontal showsHorizontalScrollIndicator={false}>
            <View style={Styles.boxTag}>
              {selectList.map((item, index) => (
                <TagNameUpload
                  key={"TagNameUpload_" + index.toLocaleString()}
                  index={index}
                  data={item}
                  onDelete={(id) => {
                    let findIndex = selectList.findIndex((d) => {
                      return d.id === id;
                    });
                    let init = selectList;
                    init.splice(findIndex, 1);
                    setSelectList([...init]);
                  }}
                />
              ))}
            </View>
          </ScrollView>
        </View>
      )}
      <View style={{ width: 10 }} />
      <TouchableOpacity
        onPress={() => {
          setIsAdd(true);
        }}
      >
        <PlusCircleIcon width={24} height={24} color={"rgba(15, 16, 17, 1)"} />
      </TouchableOpacity>
    </View>
  );
};

const TagNameUpload = (props) => {
  const { data, index } = props;
  const { onDelete } = props;
  return (
    <View style={Styles.tagContainer}>
      <Text style={Styles.tagText}>{data ? data.name : ""}</Text>
      <TouchableOpacity
        onPress={() => {
          onDelete && onDelete(data.id);
        }}
      >
        <IoMdClose style={Styles.tagCloseIcon} />
      </TouchableOpacity>
    </View>
  );
};

const Styles = StyleSheet.create({
  containerOut: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    flexDirection: "row",
    flex: 1,
    height: 50,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderColor: "#D5D7DD",
    borderRadius: 8,
    padding: 8,
    zIndex: 0,
  },
  boxTag: {
    flex: 1,
    flexDirection: "row",
  },
  tagContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 8,
    paddingRight: 8,
    backgroundColor: "#B9BDC7",
    borderRadius: 2,
    marginRight: 8,
  },
  tagText: {
    flex: 1,
    fontSize: 14,
    color: "#FFFFFF",
  },
  tagCloseIcon: {
    fontSize: 14,
    color: "#FFFFFF",
    marginLeft: 8,
  },
});

export default SearchProductLink;
