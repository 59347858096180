import React, { useEffect, useState } from "react";
import { View, Text } from "react-native-web";
import { Grid } from "@mui/material";
import { useDialog } from "../../components/modal/Popup";
//images
import goldMedal from "../../asset/images/home/levelAgent/gold-medal.png";
import silverMedal from "../../asset/images/home/levelAgent/silver-medal.png";
import bronzeMedal from "../../asset/images/home/levelAgent/bronze-medal.png";
import basicMedal from "../../asset/images/home/levelAgent/basic-medal.png";
import apiPath from "../../services/configs/apiPath";
import { get } from "../../functions/fetch/fetch";

const LevelAgentHome = (props) => {
  const dialog = useDialog();
  const [list, setList] = useState([]);

  useEffect(() => {
    downloadTeamRankingReport();
  }, []);

  const downloadTeamRankingReport = async () => {
    console.log("downloadTeamRankingReport");
    let url = apiPath.member.getTeamRankingReport;
    let { status, response } = await get(url, {}, true);
    if (!status) {
      dialog.alert({ message: response.data.error });
      return;
    }
    if (response.data) {
      setList(response.data);
    }
  };

  return (
    <Grid item xs={12} md={6}>
      <View style={Styles.container}>
        <View style={{ padding: 20 }}>
          <Text style={Styles.textTitle}>ระดับของลูกทีมทั้งสายงาน</Text>
          <View style={{ height: 20 }} />
          <View style={{ flexDirection: "row" }}>
            <Text style={Styles.textHeader}>ชื่อรหัส</Text>
            <Text style={Styles.textHeader}>วันที่ล็อคอินล่าสุด</Text>
          </View>
        </View>
        <View style={{ height: 10 }} />
        <ContentRow
          bgColor={true}
          title={"Professional"}
          img={goldMedal}
          value={
            list.length > 0
              ? Number(
                  list.find((d) => {
                    return d.code === "professional";
                  }).total
                ).toLocaleString()
              : "0"
          }
        />
        <ContentRow
          bgColor={false}
          title={"Founder"}
          value={
            list.length > 0
              ? Number(
                  list.find((d) => {
                    return d.code === "founder";
                  }).total
                ).toLocaleString()
              : "0"
          }
          img={silverMedal}
        />
        <ContentRow
          bgColor={true}
          title={"Specialist"}
          value={
            list.length > 0
              ? Number(
                  list.find((d) => {
                    return d.code === "specialist";
                  }).total
                ).toLocaleString()
              : "0"
          }
          img={bronzeMedal}
        />
        <ContentRow
          bgColor={false}
          title={"RG Priority"}
          img={basicMedal}
          value={
            list.length > 0
              ? Number(
                  list.find((d) => {
                    return d.code === "rgpriority";
                  }).total
                ).toLocaleString()
              : "0"
          }
        />
      </View>
    </Grid>
  );
};

const ContentRow = (props) => {
  const { bgColor, img, title, value } = props;
  return (
    <View
      style={{
        flexDirection: "row",
        backgroundColor: bgColor ? "rgba(204, 0, 0, 0.1)" : "",
        padding: 20,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <View style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>
        <img src={img ? img : ""} alt={"icon"} style={{ width: 45 }} />
        <View style={{ width: 25 }} />
        <Text
          style={{
            fontSize: "clamp(12px, 2vw, 16px)",
            fontWeight: "bold",
            color: "rgba(105, 109, 121, 1)",
          }}
        >
          {title ? title : ""}
        </Text>
      </View>
      <View style={{ flex: 1 }}>
        <Text
          style={{
            fontSize: "clamp(12px, 2vw, 16px)",
            color: "rgba(105, 109, 121, 1)",
          }}
        >
          {`${value} คน`}
        </Text>
      </View>
    </View>
  );
};

const Styles = {
  container: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
  },
  textTitle: {
    fontSize: "clamp(18px, 2vw, 22px)",
    fontWeight: "bold",
    color: "rgba(15, 16, 17, 1)",
  },
  textHeader: {
    flex: 1,
    fontSize: "clamp(12px, 2vw, 16px)",
    fontWeight: "bold",
    color: "rgba(15, 16, 17, 1)",
  },
};

export default LevelAgentHome;
