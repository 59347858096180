import React, { useEffect, useMemo, useState } from "react";
import { View, Text, StyleSheet, Image } from "react-native-web";
import { useWindowsSize } from "../../functions/screen/screenFunction";
import Button from "../../components/button/Button";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
//Component
import TextField from "../../components/textField/TextField";
import { useDialog } from "../../components/modal/Popup";
//Images
import bgImage from "../../asset/images/login/bgv3.png";
// import logo from "../../asset/images/logo/Logo@3x.png";
import messageImg from "../../asset/images/login/password.png";
//functions
import { get, post } from "../../functions/fetch/fetch";
import apiPath from "../../services/configs/apiPath";
import { Grid } from "@mui/material";
import { validateEmail } from "../../functions/validate/validate";
const ChangePassword = (props) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const size = useWindowsSize();
  const dialog = useDialog();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [ref, setRef] = useState("");
  const [values, setValues] = useState({
    password: "",
    confirmPassword: "",
  });

  useMemo(() => {
    if (size) {
      setWidth(size.width);
      setHeight(size.height);
    }
  }, [size]);

  useEffect(() => {
    validateToken();
  }, []);

  const validateToken = async () => {
    const queryString = window.location.search;
    const searchParams = new URLSearchParams(queryString);
    const ref = searchParams.get("ref");
    if (!ref) {
      await dialog.alert({ message: "URL ไม่ถูกต้อง" });
      navigate("/");
    }

    let url = apiPath.member.checkTokenResetPassword;
    let { status, response } = await get(url, { token: ref }, false);
    if (!status) {
      await dialog.alert({ message: response.data.error });
      navigate("/");
    }
    setRef(ref);
  };

  const submit = async () => {
    if (!values.password || !values.confirmPassword) {
      dialog.alert({
        message: "กรุณาระบุรหัสผ่าน และยืนยันรหัสผ่านให้ครบถ้วน",
      });

      return;
    }
    if (values.password !== values.confirmPassword) {
      dialog.alert({ message: "รหัสผ่าน และยืนยันรหัสผ่านไม่ตรงกัน" });
      return;
    }
    dialog.loading();
    let url = apiPath.member.changePassword;
    let { status, response } = await post(
      url,
      { password: values.password, token: ref },
      false
    );
    if (!status) {
      dialog.alert({ message: response.data.error });
      return;
    }

    let res = await dialog.alert({
      message: "เปลี่ยนรหัสผ่านของท่านเรียบร้อย",
    });
    if (!res) {
      return;
    }
    dialog.cancle();
    navigate("/login");
  };

  return (
    <View style={{ width: "100%", height: height }}>
      {ref ? (
        <View style={Styles.container1}>
          {width >= 1024 ? (
            <View style={Styles.boxLeft}>
              <View
                style={{
                  top: "8%",
                  left: "8%",
                  position: "absolute",
                  zIndex: 10,
                }}
              ></View>
              <Image source={bgImage} style={Styles.bgImage} alt={"bgImage"} />
            </View>
          ) : null}
          <View style={{ flex: 1, padding: "5%" }}>
            <img src={messageImg} style={Styles.logo} alt={"logo"} />
            <View style={{ height: 30 }} />
            <Text style={Styles.title}>ตั้งค่ารหัสผ่านใหม่</Text>
            <Text style={Styles.subTitle}>กรุณากรอกรหัสผ่านใหม่ของคุณ</Text>
            <View style={{ height: 20 }} />

            <Text style={Styles.titleTextField}>รหัสผ่านใหม่</Text>
            <View style={{ height: 8 }} />
            <TextField
              placeholder={"ระบุรหัสผ่านใหม่"}
              type={"password"}
              onChangeText={(value) => {
                setValues({
                  ...values,
                  password: value,
                });
              }}
            />
            <View style={{ height: 20 }} />
            <Text style={Styles.titleTextField}>ยืนยันรหัสผ่านใหม่</Text>
            <View style={{ height: 8 }} />
            <TextField
              placeholder={"ระบุยืนยันรหัสผ่านใหม่"}
              type={"password"}
              onChangeText={(value) => {
                setValues({
                  ...values,
                  confirmPassword: value,
                });
              }}
            />
            <View style={{ height: 40 }} />
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                {" "}
                <Button
                  buttonText={"กลับหน้าลงชื่อเข้าใช้"}
                  color={"primary"}
                  style={Styles.buttonBack}
                  isBorder={true}
                  onClick={() => {
                    navigate("/login");
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  buttonText={"ยืนยัน"}
                  color={
                    values.password && values.confirmPassword
                      ? "primary"
                      : "disable"
                  }
                  style={Styles.buttonNext}
                  onClick={() => {
                    submit();
                  }}
                />
              </Grid>
            </Grid>
          </View>
        </View>
      ) : null}
    </View>
  );
};

const Styles = StyleSheet.create({
  container1: {
    width: "100%",
    height: "100%",
    flexDirection: "row",
    justifyContent: "center",
  },
  boxLeft: {
    width: "45%",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  logo: {
    width: "100%",
    maxWidth: 160,
    alignSelf: "center",
  },
  bgImage: {
    width: "100%",
    height: "100%",
    alignSelf: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    color: "rgba(15, 16, 17, 1)",
    alignSelf: "center",
  },
  subTitle: {
    fontSize: 15,
    color: "rgba(105, 109, 121, 1)",
    alignSelf: "center",
  },
  buttonBack: {
    width: "100%",
  },
  buttonNext: { width: "100%" },
});

export default ChangePassword;
