import React from "react";
import { View, Text, ScrollView } from "react-native-web";
//component
import PageButtonGroup from "./PageButtonGroup";
import ArrowButton from "./ArrowButton";
import ArrowFitButton from "./ArrowFitButton";

const PageButtonNavigate = (props) => {
  const { currentPage, pagesCount, dataCount, startIndex, endIndex } = props;
  const { onSelectPage } = props;
  return (
    <View style={Styles.container}>
      <Text style={{ color: "rgba(105, 109, 121, 1)" }}>
        {`รายการ ${startIndex + 1}-${endIndex} จาก ${dataCount}`}
      </Text>
      <View style={{ width: 40 }} />
      {/* <ArrowFitButton
          size={25}
          color={currentPage > 0 ? "#8A91A1" : "#AA4942"}
          onPress={() => {
            onSelectPage && onSelectPage(0);
          }}
      />
      <View style={{ width: 8 }} /> */}
      <ArrowButton
        size={20}
        color={currentPage - 1 === 0 ? "#8A91A1" : "#AA4942"}
        onPress={() => {
          if (currentPage - 1 > 0) {
            onSelectPage && onSelectPage(currentPage - 1);
          }
        }}
      />
      <View style={{ width: 25 }} />
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <ScrollView
          horizontal
          style={{
            flex: 1, // This will allow ScrollView to take the available space
          }}
          contentContainerStyle={{
            alignItems: "center",
          }}
        >
          <PageButtonGroup
            currentPage={currentPage}
            pageCount={pagesCount}
            onClick={(page) => {
              onSelectPage && onSelectPage(page);
            }}
          />
        </ScrollView>
      </View>

      <View style={{ width: 20 }} />
      <ArrowButton
        direction={"right"}
        size={20}
        color={currentPage + 1 > pagesCount ? "#8A91A1" : "#AA4942"}
        onPress={() => {
          if (currentPage + 1 <= pagesCount) {
            onSelectPage && onSelectPage(currentPage + 1);
          }
        }}
      />
      {/* <View style={{ width: 8 }} />
      <ArrowFitButton
          direction={"right"}
          size={25}
          color={currentPage - 1 === 0 ? "#8A91A1" : "#AA4942"}
          onPress={() => {
            onSelectPage && onSelectPage(0);
          }}
      /> */}
    </View>
  );
};

const Styles = {
  container: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: 10,
  },
};

export default PageButtonNavigate;
