import React, { useEffect, useState } from "react";
import { View, Text, ScrollView, TouchableOpacity } from "react-native-web";
import { Divider } from "rsuite";
import { Grid } from "@mui/material";
import moment from "moment-timezone";
//component
import PopupBackdrop from "../../../components/modal/PopupBackdrop";
import CloseButton from "../../../components/button/CloseButton";
import Dropdown from "../../../components/dropdown/Dropdown";
//image
import productImg from "../../../asset/images/example/product.png";
import correctImg from "../../../asset/images/icons/correct.png";
import Button from "../../../components/button/Button";
import apiPath from "../../../services/configs/apiPath";
import { post } from "../../../functions/fetch/fetch";
import { useDialog } from "../../../components/modal/Popup";
import { IoMdAdd } from "react-icons/io";
import { IoMdRemove } from "react-icons/io";
import { useSelector } from "react-redux";

const EventViewUserDetail = (props) => {
  const dialog = useDialog();
  const { data } = props;
  const { onClose } = props;
  const { masterData } = useSelector((state) => {
    return state.setupData;
  });
  const [detail, setDetail] = useState([]);
  const [statusJoin, setStatusJoin] = useState("INACTIVE");

  useEffect(() => {
    if (data) {
      let findRanking = masterData.rankingList.find((d) => {
        return Number(d.id) === Number(data.current_rank);
      });
      console.log(data);
      const follower = JSON.parse(data.followers);
      const parentAgentData = follower.find((d) => {
        return d.full_name === data.full_name;
      });
      let parent = [
        { label: "ชื่อ - นามสกุล", value: data.full_name },
        { label: "รหัสตัวแทน", value: data.agent_code },
        { label: "ระดับตัวแทน", value: findRanking ? findRanking.name : "-" },
        { label: "อีเมล", value: data.email },
        {
          label: "เบอร์โทรศัพท์",
          value: `+(${data.phone_code}) ${data.phone_number}`,
        },
        {
          label: "อาหารกลางวัน",
          value: parentAgentData
            ? parentAgentData.required_foods.join(",")
            : "-",
        },
      ];
      let child = [];

      follower.forEach((item, index) => {
        if (item.full_name !== data.full_name) {
          child.push({
            label: `ผู้ติดตาม ${index + 1}`,
            value: item.full_name,
          });
          child.push({
            label: "อาหารกลางวัน",
            value: item.required_foods.join(","),
          });
          if (item.note) {
            child.push({ label: "หมายเหตุ", value: item.note });
          }
        }
      });

      setDetail([
        ...parent,
        ...child,
        {
          label: "วันที่ลงทะเบียน",
          value: moment(data.create_date).format("DD/MM/YYYY HH:mm"),
        },
        { label: "หมายเหตุ", value: data.note ? data.note : "-" },
      ]);
      setStatusJoin(data.active_in_event);
    }
  }, [data, masterData]);

  const handleAccept = async () => {
    if (!data.event_redeem_id) {
      return;
    }
    let url = apiPath.event.eventCheckIn;
    let { status, response } = await post(
      url,
      {
        redeem_event_id: data.event_redeem_id,
        check_in: statusJoin === "ACTIVE" ? true : false,
      },
      true
    );
    if (!status) {
      dialog.alert({ message: response.data.error });
      return;
    }
    if (response.data) {
      dialog.alert({ message: "บันทึกสำเร็จ" });
      onClose && onClose();
      return;
    }
  };

  return (
    <PopupBackdrop>
      <View style={Styles.box}>
        <View>
          <Text style={Styles.textTitle}>รายละเอียดผู้ลงทะเบียน</Text>
          <CloseButton
            color={"rgba(15, 16, 17, 1)"}
            style={{ position: "absolute", top: 5, right: 5 }}
            onPress={() => {
              onClose && onClose();
            }}
          />
        </View>
        <Divider />
        <ScrollView
          style={{ padding: 10 }}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <EventUserDetailBox data={detail} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Text style={Styles.titleText}>สถานะ </Text>
              <View style={{ height: 8 }} />

              <Dropdown
                value={data.active_in_event}
                valueType={"string"}
                placeholder={"เลือกสถานะ"}
                list={[
                  { label: "เข้าร่วมกิจกรรม", value: "ACTIVE" },
                  { label: "ยังไม่เข้าร่วมกิจกรรม", value: "INACTIVE" },
                ]}
                onChange={(values) => {
                  const { value } = values;
                  setStatusJoin(value);
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} />
          </Grid>
          <View style={{ height: 30 }} />

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Button
                buttonText={"ยกเลิก"}
                color={"primary"}
                style={{ width: "100%" }}
                isBorder={true}
                onClick={() => {
                  onClose && onClose();
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                buttonText={"บันทึกการแก้ไข"}
                color={"primary"}
                style={{ width: "100%" }}
                isBorder={false}
                onClick={() => {
                  handleAccept();
                }}
              />
            </Grid>
          </Grid>
          <View style={{ height: 30 }} />
        </ScrollView>
      </View>
    </PopupBackdrop>
  );
};

const EventUserDetailBox = (props) => {
  const { data } = props;

  const StylesEDB = {
    container: {
      flex: 1,
      backgroundColor: "rgba(248, 248, 248, 1)",
      borderRadius: 10,
      padding: 20,
    },
    contentRow: {
      flexDirection: "row",
      marginBottom: 10,
    },
    textTitle: { flex: 0.3, color: "rgba(105, 109, 121, 1)" },
    textValue: { flex: 0.7, color: "rgba(15, 16, 17, 1)", fontWeight: "bold" },
  };
  return (
    <View style={StylesEDB.container}>
      {data &&
        data.map((item, index) => {
          return (
            <View
              style={StylesEDB.contentRow}
              key={"eventviewuserdetail" + index}
            >
              <Text style={StylesEDB.textTitle}>{item.label}</Text>
              <Text style={StylesEDB.textValue}>{item.value}</Text>
            </View>
          );
        })}
    </View>
  );
};

const Styles = {
  box: {
    width: "90vw",
    maxHeight: "90vh",
    padding: 20,
    maxWidth: 1000,
    backgroundColor: "#FFF",
    borderRadius: 16,
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.08,
    shadowRadius: 16,
  },
  textTitle: {
    fontSize: 18,
    fontWeight: "bold",
    color: "rgba(15, 16, 17, 1)",
  },
};

export default EventViewUserDetail;
