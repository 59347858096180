import React, { useEffect, useState } from "react";
import { View, Text } from "react-native-web";
import CheckBox from "./CheckBox";

const CheckBoxList = (props) => {
  const { list, value } = props;
  const { minWidth } = props;
  const { onCheck } = props;
  const [selectIndex, setSelectIndex] = useState();

  useEffect(() => {
    if (list && value >= 0) {
      let findIndex = list.findIndex((d) => {
        return d.value === value;
      });
      if (findIndex >= 0) {
        setSelectIndex(findIndex);
      }
    }
  }, [list, value]);

  return (
    <View style={Styles.container}>
      {list.map((item, index) => {
        return (
          <SelectBox
            key={"checkBox_" + item.label}
            index={index}
            item={item}
            selectIndex={selectIndex}
            onCheck={(values) => {
              if (!selectIndex) {
                setSelectIndex(values);
                onCheck && onCheck(values);
                return;
              }
              if (values.value !== selectIndex.value) {
                setSelectIndex(values);
              } else {
                setSelectIndex(null);
              }
              onCheck && onCheck(values);
            }}
            minWidth={minWidth}
          />
        );
      })}
    </View>
  );
};

const SelectBox = (props) => {
  const { index, selectIndex, item } = props;
  const { minWidth } = props;
  const { onCheck } = props;

  return (
    <View style={Styles.sbContainer}>
      <CheckBox
        value={selectIndex === index} // Compare indexes instead of values
        color="rgba(170, 73, 66, 1)"
        onCheck={() => {
          onCheck && onCheck(item);
        }}
      />
      <Text style={{ ...Styles.sbText, minWidth: minWidth ? minWidth : 0 }}>
        {item.label ? item.label : ""}
      </Text>
    </View>
  );
};

const Styles = {
  container: {
    width: "100%",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  sbContainer: {
    flexDirection: "row",
    padding: 20,
    backgroundColor: "rgba(255, 255, 255, 1)",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
    borderRadius: 8,
    marginRight: 20,
    marginBottom: 20,
  },
  sbText: {
    marginLeft: 8,
    fontSize: "clamp(12px,2vh,16px)",
  },
};

export default CheckBoxList;
