import apiPath from "../../configs/apiPath";
import { get, post } from "../../../functions/fetch/fetch";

export const downloadAllCustomerSales = async () => {
  let url = apiPath.sales.getAllCustomerSales;
  let { status, response } = await get(url, {}, true);
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};

export const downloadSalesStoreList = async (params) => {
  let url = apiPath.order.getSalesStoreList;
  let { status, response } = await get(url, { ...params }, true);
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};

export const downloadBuyerStoreList = async (params) => {
  let url = apiPath.order.getBuyerStoreList;
  let { status, response } = await get(url, { ...params }, true);
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};

export const downloadOrderMyList = async () => {
  let url = apiPath.order.getOrderMyList;
  let { status, response } = await get(url, {}, true);
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};

export const downloadOrderMyAgentList = async () => {
  let url = apiPath.order.getOrderMyAgentList;
  let { status, response } = await get(url, {}, true);
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};

export const downloadOrderCodeMyList = async () => {
  let url = apiPath.order.getOrderCodeMyList;
  let { status, response } = await get(url, {}, true);
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};

export const downloadOrderCodeMyAgentList = async () => {
  let url = apiPath.order.getOrderCodeMyList;
  let { status, response } = await get(url, {}, true);
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};

export const downloadOrderMyAgent = async () => {
  let url = apiPath.sales.getOrderMyAgent;
  let { status, response } = await get(url, {}, true);
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};

export const approveOrder = async (orderId) => {
  if (!orderId) {
    return null;
  }
  let url = apiPath.sales.approveOrder;
  let { status, response } = await post(url, { orderId: orderId }, true);
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};

//Retail
export const downloadRetailSaleList = async () => {
  let url = apiPath.retail.getRetailSalesList;
  let { status, response } = await get(url, {}, true);
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};

export const downloadRetailCustomerList = async (agentCode) => {
  if (!agentCode) {
    return;
  }
  let url = apiPath.retail.getRetailCustomerList;
  let { status, response } = await get(url, { agentCode: agentCode }, true);
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};
