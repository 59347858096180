import React, { useEffect, useState } from "react";
import { View, Text } from "react-native-web";
import { useDispatch ,useSelector} from "react-redux";
import { useNavigate } from "react-router-dom";
import { isLogin } from "../../functions/validate/validate";
import Cookies from "js-cookie";
import apiPath from "../../services/configs/apiPath";
import { get } from "../../functions/fetch/fetch";
import { defaultMenu } from "../../services/configs/pagesName";

const Auth = (props) => {
  //property
  const user = useSelector((state) => {
    return state.user;
  });
  const {forAdmin = false} = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //reducer
  const { userUseRole } = useDispatch((state) => {
    return state;
  });
  //state
  const [check, setCheck] = useState(false);
  const [valid, setValid] = useState(false);

  const { pathname } = props;

  useEffect(() => {
    //allow enter page here!
    if (pathname) {
      if (!isLogin()) {
        if (pathname === "/") {
          //logout and navigate to logout page
          logout();
        }
        if (pathname === "/login" || pathname === "/register") {
          //no action because no check auth for this page
          return;
        }
      }

      if(forAdmin){
          setCheck(true);
          console.log("FOR ADMIN",user)
          setValid(!user ? false : user.userRole === "S" || user.userRole === "SS");
        return;
      }
      //isLogin check auth user can access this page;
      if (
        userUseRole === undefined ||
        userUseRole === null ||
        userUseRole === "" ||
        userUseRole.length === 0
      ) {
        //try check again
        authFromUseRole();
        return;
      }
      validPage(userUseRole);
    }
  }, [pathname]);

  const authFromUseRole = async () => {
    let useRole = await checkUseRole();
    if (
      useRole === undefined ||
      useRole === null ||
      useRole === "" ||
      useRole.length === 0
    ) {
      logout();
      return;
    }
    validPage(useRole);
  };

  const checkUseRole = async () => {
    let url = apiPath.auth.getUserUseRole;
    let { status, response } = await get(url, {}, true);
    if (!status) {
      logout();
    }
    if (response.data) {
      dispatch({
        type: "SetUserUseRole",
        payload: JSON.parse(response.data),
      });
    }
    return JSON.parse(response.data);
  };

  const validPage = (useRole) => {
    if (useRole === undefined || useRole === null || useRole === "") {
      return;
    }

    let userAllPage = [];
    Object.values(useRole).forEach((page) => {
      if (page.active === "Y") {
        userAllPage.push(page.value);
      }
      if (page.subMenu.length > 0) {
        page.subMenu.forEach((sub) => {
          if (sub.active === "Y") {
            userAllPage.push(sub.value);
          }
        });
      }
    });
    let checkInRole = userAllPage.find((d) => {
      return `/${d}` === `${pathname}`;
    });
    if (!checkInRole) {
      //can't Enter Page no access permission
      setCheck(true);
      setValid(false);
      return;
    }
    //setMenuLeftPanel
    //dispatch({ type: "SetPage", payload: value });
    //dispatch({ type: "SetSubPage", payload: value });

    setCheck(true);
    setValid(true);
  };

  const logout = () => {
    Cookies.remove("token");
    Cookies.remove("rosegoldtoken");
    dispatch({ type: "SetUser", payload: null });
    navigate("/login");
  };

  return (
    <React.Fragment>
      {check && valid ? (
        <React.Fragment>{props.children}</React.Fragment>
      ) : check && !valid ? (
        <React.Fragment>
          <Text>you can't access this page.</Text>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

const PageNotFound = (props) => {
  return <View></View>;
};
export default Auth;
