import apiPath from "../../configs/apiPath";
import { get, post } from "../../../functions/fetch/fetch";

export const getPaymentStatus = async (refNo) => {
  let url = apiPath.payment.paymentCheckStatus;
  let { status, response } = await get(url, { refNo: refNo }, false);
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};
