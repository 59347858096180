import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import { Grid } from "@mui/material";
import { Divider } from "rsuite";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import * as XLSX from "xlsx";
//component
import Button from "../../components/button/Button";
import Dropdown from "../../components/dropdown/Dropdown";
import DatePicker from "../../components/calendar/DatePicker";
import ReportCommissionTable from "./component/ReportCommissionTable";
import apiPath from "../../services/configs/apiPath";
import { post } from "../../functions/fetch/fetch";
import { useDialog } from "../../components/modal/Popup";
import ReportCommissionLogPopup from "./component/ReportComissionLogPopup";
import DropdownPopover from "../../components/dropdown/DropdownPopover";

const ReportCommission = () => {
  const navigate = useNavigate();
  const dialog = useDialog();
  const [period, setPeriod] = useState();
  const [periodList, setPeriodList] = useState();

  const [comList, setComList] = useState([]);
  const [showLog, setShowLog] = useState(false);

  useEffect(() => {
    const downloadAll = async () => {
      await downloadComissionList(moment().format("YYYYMM"));
      setupPeriodList();
    };

    downloadAll();
  }, []);

  const setupPeriodList = () => {
    let init = generateDateLabels(1, Number(moment().format("YYYY") - 1), 24);
    setPeriodList(init);
    setTimeout(() => {
      setPeriod(moment().format("YYYYMM"));
    }, 100);
  };

  function generateDateLabels(startMonth, startYear, numMonths) {
    const result = [];

    let currentDate = moment({ year: startYear, month: startMonth - 1 }); // ลด 1 จากเดือน เนื่องจาก moment.js นับเดือนตั้งแต่ 0 (0 = January, 11 = December)

    for (let i = 0; i < numMonths; i++) {
      const label = currentDate.format("MM/YYYY");
      const value = currentDate.format("YYYYMM");

      result.push({
        label: label,
        value: value,
      });

      currentDate.add(1, "months");
    }

    return result;
  }

  const downloadComissionList = async (period) => {
    console.log("downloadComissionList", period);
    let url = apiPath.report.getCommissionList;
    const { status, response } = await post(url, { period: period }, true);
    if (!status) {
      dialog.alert({ message: response.data.error });
      return;
    }
    console.log(response.data);
    if (response.data) {
      setComList(response.data);
    }
  };

  // const downloadExcel = async () => {
  //   let newOrders = [];
  //   comList.forEach((item) => {
  //     console.log(item);
  //     newOrders.push({
  //       ประจำเดือน: period,
  //       รหัสตัวแทน: item.agentCode,
  //       ชื่อตัวแทน: item.agentName,
  //       ค่าคอมแบบสต็อก: item.comStock,
  //       ค่าคอมแบบไม่สต็อก: item.comNonStock,
  //       รวมค่าคอม: item.comSum,
  //       สถานะจ่ายเงิน: item.status,
  //       ชื่อธนาคาร: item.agentBankName,
  //       ชื่อบัญชี: item.agentAccountName,
  //       เลขที่บัญชี: item.agentAccountNumber,
  //     });
  //   });

  //   const worksheet = XLSX.utils.json_to_sheet(newOrders);
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //   XLSX.writeFile(
  //     workbook,
  //     `รายงานค่าคอมมิชชั่น_${moment().format("DD_MM_YYYY_HHmmss")}.xlsx`
  //   );
  // };

  // const downloadReport1 = async () => {
  //   if (!period) {
  //     dialog.alert({ message: "กรุณาเลือกงวดประจำเดือน" });
  //     return;
  //   }
  //   dialog.loading();
  //   let url = apiPath.report.getCommissionDoubleCheck;
  //   let { status, response } = await post(url, { period: period }, true);
  //   if (!status) {
  //     dialog.alert({ message: response.data.error });
  //     return;
  //   }
  //   if (response.data) {
  //     console.log(response.data);
  //     const worksheet = XLSX.utils.json_to_sheet(response.data);
  //     const workbook = XLSX.utils.book_new();
  //     XLSX.utils.book_append_sheet(workbook, worksheet, "commission_report");
  //     XLSX.writeFile(
  //       workbook,
  //       `รายงานค่าคอมมิชชั่น_${moment().format("DD_MM_YYYY_HHmmss")}.xlsx`
  //     );
  //   }
  //   dialog.cancle();
  // };

  const downloadReport1 = async () => {
    if (!period) {
      dialog.alert({ message: "กรุณาเลือกงวดประจำเดือน" });
      return;
    }
    dialog.loading();
    let url = apiPath.report.getCommissionDoubleCheck;

    try {
      let { status, response } = await post(url, { period: period }, true);
      if (!status) {
        throw new Error("Network response was not ok");
      }
      console.log("download", response.data);
      // Assuming data is a CSV string
      const blob = new Blob([response.data], { type: "text/csv" });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute(
        "download",
        `commission_report_${moment().format("DD_MM_YYYY_HHmmss")}.csv`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
      dialog.cancle();
    } catch (error) {
      dialog.alert({ message: error.message });
    }
  };

  const handleSelect = (value) => {
    if (value.option === "view") {
      setShowLog({ agentCode: value.agentCode, period: period });
    } else if (value.option === "approve") {
    } else if (value.option === "cancle") {
    }
  };

  return (
    <React.Fragment>
      {showLog ? (
        <ReportCommissionLogPopup
          showLog={showLog}
          onClose={() => {
            setShowLog(null);
          }}
        />
      ) : null}
      <View style={Styles.container}>
        <Grid container style={Styles.topContainer} spacing={2}>
          <Grid item xs={12} md={8} lg={9} xl={10}>
            <View style={{ flex: 1 }}>
              <Text style={Styles.textTopContainer1}>รายงาน ค่าคอมมิชชั่น</Text>
              <Text style={Styles.textTopContainer2}>
                รายงาน / รายงาน ค่าคอมมิชชั่น
              </Text>
            </View>
          </Grid>
          <Grid item xs={12} md={4} lg={3} xl={2}>
            {/* <DropdownPopover
              buttonStyle={"button"}
              title={"ดาวน์โหลด"}
              list={[{ label: "ดาวน์โหลดรายงาน", value: "report1" }]}
              onSelect={(value) => {}}
            /> */}

            <Button
              buttonText={"ดาวน์โหลดไฟล์ Excel"}
              color={"primary"}
              style={{ width: "100%" }}
              styleText={Styles.topButtonText}
              isBorder={true}
              onClick={() => {
                // downloadExcel();
                downloadReport1();
              }}
            />
          </Grid>
        </Grid>
        <View style={{ height: 30 }} />
        <View style={Styles.contentContainer}>
          <Grid container spacing={2} style={{ marginBottom: 15 }}>
            <Grid item xs={12} lg={4} style={{ zIndex: 10 }}>
              <View style={{ height: 8 }} />
              <Dropdown
                value={period}
                valueType={"string"}
                placeholder={"เลือกงวดประจำเดือน"}
                list={periodList}
                onChange={(values) => {
                  const { value, label } = values;
                  console.log(value, label);
                  setPeriod(value);
                }}
              />
            </Grid>

            <Grid item xs={12} lg={2}>
              <View style={{ height: 8 }} />
              <Button
                buttonText={"ค้นหา"}
                color={"primary"}
                style={{ width: "100%" }}
                styleText={Styles.topButtonText}
                onClick={() => {
                  downloadComissionList(period);
                }}
              />
            </Grid>
          </Grid>
          <Divider />
          <View style={{ flex: 1 }}>
            <ReportCommissionTable
              tableData={comList}
              onSelect={(value) => {
                handleSelect(value);
              }}
            />
          </View>
        </View>
      </View>
    </React.Fragment>
  );
};

const Styles = {
  container: {
    flex: 1,
    height: "100vh",
    padding: "1.5%",
  },
  topContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  contentContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  textTopContainer1: {
    fontSize: "clamp(18px, 3vw, 22px)",
    fontWeight: "bold",
    color: "#AA4942",
  },
  textTopContainer2: {
    fontSize: "clamp(12px, 3vw, 16px)",
    color: "#696D79",
  },
  topButtonText: {
    fontSize: "clamp(14px, 3vw, 18px)",
  },
  titleSearch: {
    fontSize: 20,
    color: "#0F1011",
  },
  subTitleSearch: {
    fontSize: 16,
    color: "#0F1011",
    marginTop: 8,
  },
};
export default ReportCommission;
