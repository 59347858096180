import React, { useState, useEffect } from "react";
import { Text, View } from "react-native-web";
import { Grid } from "@mui/material";
import { Divider } from "rsuite";
//component
import Button from "../../components/button/Button";
import DatePicker from "../../components/calendar/DatePicker";
import Dropdown from "../../components/dropdown/Dropdown";
import OrderPopupDetail from "./component/OrderPopupDetail";
//functions
import {
  downloadOrderCodeMyList,
  downloadOrderMyAgentList,
} from "../../services/api/sales/sales";
//images
import OrderMyListTable from "./component/OrderMyListTable";
import { useNavigate } from "react-router-dom";
import { useDialog } from "../../components/modal/Popup";
import apiPath from "../../services/configs/apiPath";
import moment from "moment-timezone";
import { get } from "../../functions/fetch/fetch";
import DatePickerRange from "../../components/calendar/DatePickerRange";

const OrderMyAgent = (props) => {
  //property
  const navigate = useNavigate();
  const dialog = useDialog();
  //state
  const [orderList, setOrderList] = useState([]);
  const [searchOrderList, setSearchOrderList] = useState([]);
  const [orderCodeList, setOrderCodeList] = useState([]);
  const [showPopupDetail, setShowPopupDetail] = useState(false);
  //view
  const [viewOrder, setViewOrder] = useState();
  //search
  const [search, setSearch] = useState({
    startDate: null,
    endDate: null,
    wording: "",
    status: "",
    orderCode: "",
  });

  useEffect(() => {
    downloadAll();
  }, []);

  useEffect(() => {
    if (search) {
      filterSearchData(orderList);
    }
  }, [search]);

  const downloadAll = async () => {
    dialog.loading();
    let orderList = await downloadOrderMyAgentList();
    let initOrder = [];
    orderList.forEach((item) => {
      initOrder.push(item);
    });
    setOrderList(initOrder);
    let orderCodeList = await downloadOrderMyAgentList();
    let initOrderCode = [];
    orderCodeList.forEach((item) => {
      initOrderCode.push({ value: item.orderCode, label: item.orderCode });
    });
    setOrderCodeList([{ value: "", label: "ทั้งหมด" }, ...initOrderCode]);
    console.log(initOrderCode);
    dialog.cancle();
  };

  const filterSearchData = async (data) => {
    const newList = data.filter((item) => {
      const statusCondition = !search.status || item.status === search.status;
      const orderCodeCondition =
        !search.orderCode || item.orderCode === search.orderCode;
      return statusCondition && orderCodeCondition;
    });

    setSearchOrderList(newList);
  };

  const searchFilter = async () => {
    dialog.loading();
    let url = apiPath.order.getOrderMyAgentList;
    let { status, response } = await get(
      url,
      {
        startDate: moment.utc(search.startDate).set({ h: 0, m: 0, s: 0 }),
        endDate: moment.utc(search.endDate).set({ h: 0, m: 0, s: 0 }),
      },
      true
    );
    if (!status) {
      dialog.alert({ message: response.data.error });
    }
    if (response.data) {
      await setOrderList(response.data);
      filterSearchData(response.data);
      dialog.cancle();
    }
  };

  return (
    <React.Fragment>
      <OrderPopupDetail
        visible={showPopupDetail}
        data={viewOrder}
        onClose={() => {
          setShowPopupDetail(false);
        }}
      />
      <View style={Styles.container}>
        <Grid container style={Styles.topContainer} spacing={2}>
          <Grid item xs={12} md={8} lg={9} xl={10}>
            <View style={{ flex: 1 }}>
              <Text style={Styles.textTopContainer1}>รายการคำสั่งซื้อ</Text>
              <Text style={Styles.textTopContainer2}>
                คำสั่งซื้อ / รายการคำสั่งซื้อ
              </Text>
            </View>
          </Grid>
          <Grid item xs={12} md={4} lg={3} xl={2}>
            <Button
              buttonText={"เพิ่มคำสั่งซื้อ"}
              color={"primary"}
              style={{ width: "100%" }}
              styleText={Styles.topButtonText}
              onClick={() => {
                navigate("/addOrder");
              }}
            />
          </Grid>
        </Grid>
        <View style={{ height: 30 }} />

        <View style={Styles.contentContainer}>
          <Grid container spacing={2} style={{ marginBottom: 15, zIndex: 10 }}>
            <Grid item xs={12} lg={5}>
              <View style={{ height: 8 }} />
              <Dropdown
                placeholder={"เลขที่คำขอ"}
                list={orderCodeList}
                onChange={(values) => {
                  const { value, label } = values;
                  setSearch({ ...search, orderCode: value });
                }}
              />
            </Grid>
            <Grid item xs={12} lg={5} style={{ zIndex: 9 }}>
              <View style={{ height: 8 }} />
              <Dropdown
                placeholder={"สถานะทั้งหมด"}
                list={[
                  { value: "", label: "ทั้งหมด" },
                  { value: "Y", label: "สำเร็จ" },
                  { value: "N", label: "ไม่สำเร็จ" },
                ]}
                onChange={(values) => {
                  const { value, label } = values;
                  setSearch({ ...search, status: value });
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={5} style={{ zIndex: 8 }}>
              <View style={{ height: 8 }} />
              <DatePickerRange
                startDate={search.startDate}
                endDate={search.endDate}
                placeholder={"เลือกวันที่เริ่มต้น - วันที่สิ้นสุด"}
                isTop={false}
                styles={{ width: "100%" }}
                onChange={(values) => {
                  const { startDate, endDate } = values;
                  if (startDate && endDate) {
                    setSearch({
                      ...search,
                      startDate: startDate.utc(),
                      endDate: endDate.utc(),
                    });
                  }
                }}
              />{" "}
            </Grid>
            <Grid item xs={12} lg={2} xl={2}>
              <View style={{ height: 8 }} />
              <Button
                buttonText={"ค้นหา"}
                color={"primary"}
                style={{ width: "100%" }}
                styleText={Styles.topButtonText}
                onClick={() => {
                  if (search.startDate && search.endDate) {
                    searchFilter();
                  }
                }}
              />
            </Grid>
          </Grid>
          <Divider />
          <View style={{ flex: 1 }}>
            <OrderMyListTable
              tableData={
                searchOrderList.length > 0
                  ? searchOrderList
                  : orderList.length > 0
                  ? orderList
                  : []
              }
              onView={(values) => {
                setViewOrder(values);
                setShowPopupDetail(true);
              }}
            />
          </View>
        </View>
      </View>
    </React.Fragment>
  );
};

const Styles = {
  container: {
    flex: 1,
    height: "100vh",
    padding: "1.5%",
  },
  topContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  contentContainer: {
    width: "100%",
    flex: 1,
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  textTopContainer1: {
    fontSize: "clamp(18px, 3vw, 22px)",
    fontWeight: "bold",
    color: "#AA4942",
  },
  textTopContainer2: {
    fontSize: "clamp(12px, 3vw, 16px)",
    color: "#696D79",
  },
  topButtonText: {
    fontSize: "clamp(14px, 3vw, 18px)",
  },
};

export default OrderMyAgent;
