import React from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import { Grid } from "@mui/material";
import { Divider } from "rsuite";
import { useNavigate } from "react-router-dom";
//component
import Button from "../../components/button/Button";
import Dropdown from "../../components/dropdown/Dropdown";
import ReportPositionQuarterTable from "./component/ReportPositionQuarterTable";

const ReportPositionQuarter = () => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <View style={Styles.container}>
        <Grid container style={Styles.topContainer} spacing={2}>
          <Grid item xs={12} md={8} lg={9} xl={10}>
            <View style={{ flex: 1 }}>
              <Text style={Styles.textTopContainer1}>
                รายงานตำแหน่งประจำไตรมาส
              </Text>
              <Text style={Styles.textTopContainer2}>
                รายงาน / รายงานตำแหน่งประจำไตรมาส
              </Text>
            </View>
          </Grid>
          <Grid item xs={12} md={4} lg={3} xl={2}>
            <Button
              buttonText={"ดาวน์โหลดไฟล์ Excel"}
              color={"primary"}
              style={{ width: "100%" }}
              styleText={Styles.topButtonText}
              isBorder={true}
              onClick={() => {}}
            />
          </Grid>
        </Grid>
        <View style={{ height: 30 }} />
        <View style={Styles.contentContainer}>
          <Grid container spacing={2} style={{ marginBottom: 15 }}>
            <Grid item xs={12} lg={4} style={{ zIndex: 10 }}>
              <View style={{ height: 8 }} />
              <Dropdown
                placeholder={"ไตรมาส"}
                list={[]}
                onChange={(values) => {
                  const { value, label } = values;
                  console.log(value, label);
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4} style={{ zIndex: 10 }}>
              <View style={{ height: 8 }} />
              <Dropdown
                placeholder={"ข้อมูลตัวแทน"}
                list={[]}
                onChange={(values) => {
                  const { value, label } = values;
                  console.log(value, label);
                }}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <View style={{ height: 8 }} />
              <Button
                buttonText={"ค้นหา"}
                color={"primary"}
                style={{ width: "100%" }}
                styleText={Styles.topButtonText}
                onClick={() => {}}
              />
            </Grid>
            <Grid item xs={12} lg={4} style={{ zIndex: 10 }}>
              <View style={{ height: 8 }} />
              <Dropdown
                placeholder={"ระดับตัวแทน"}
                list={[]}
                onChange={(values) => {
                  const { value, label } = values;
                  console.log(value, label);
                }}
              />
            </Grid>
          </Grid>
          <Divider />

          <Text style={Styles.subTitleSearch}>ผลการค้นหา 50 รายการ</Text>
          <View style={{ flex: 1 }}>
            <ReportPositionQuarterTable tableData={[""]} />
          </View>
        </View>
      </View>
    </React.Fragment>
  );
};

const Styles = {
  container: {
    flex: 1,
    height: "100vh",
    padding: "1.5%",
  },
  topContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  contentContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  textTopContainer1: {
    fontSize: "clamp(18px, 3vw, 22px)",
    fontWeight: "bold",
    color: "#AA4942",
  },
  textTopContainer2: {
    fontSize: "clamp(12px, 3vw, 16px)",
    color: "#696D79",
  },
  topButtonText: {
    fontSize: "clamp(14px, 3vw, 18px)",
  },
  titleSearch: {
    fontSize: 20,
    color: "#0F1011",
  },
  subTitleSearch: {
    fontSize: 16,
    color: "#0F1011",
    marginTop: 8,
  },
};
export default ReportPositionQuarter;
