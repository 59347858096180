import React, { useEffect, useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import { View, TouchableOpacity, Text } from "react-native-web";
//images
import { RiArrowDownSLine } from "react-icons/ri";
import { SlOptionsVertical } from "react-icons/sl";

const OptionButton = (props) => {
  const { title, list, styles } = props;
  const { onSelect } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectItem, setSelectItem] = useState("");

  useEffect(() => {
    if (list && !selectItem) {
      setSelectItem(list[0] ? list[0].label : "");
    }
  }, [list]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (value) => {
    setAnchorEl(null);
    onSelect && onSelect(value);
  };

  return (
    <>
      <View style={Styles.button}>
        <TouchableOpacity
          style={{ ...styles }}
          onPress={handleClick}
          variant="contained"
        >
          <SlOptionsVertical style={Styles.icon} />
        </TouchableOpacity>
      </View>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: "200px",
          },
        }}
      >
        {list.map((item) => {
          return (
            <MenuItem
              key={"menu_" + item.label}
              onClick={() => {
                handleClose && handleClose(item.value);
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(255, 215, 215, 1)", // Change 'red' to your desired color
                },
              }}
            >
              {item.label}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

const Styles = {
  button: {
    flexDirection: "row",
  },
  icon: {
    fontSize: 18,
    color: "#000",
  },
};

export default OptionButton;
