import apiPath from "../../configs/apiPath";
import { get, post, put } from "../../../functions/fetch/fetch";

export const downloadAllAdmin = async () => {
  let url = apiPath.member.getAllAdmin;
  let { status, response } = await get(url, {}, true);
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};

export const downloadAllAgent = async () => {
  let url = apiPath.member.getAgentList;
  let { status, response } = await get(url, {}, true);
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};

export const downloadAllTeam = async () => {
  let url = apiPath.member.getTeamList;
  let { status, response } = await get(url, {}, true);
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};

export const  getUserInfo = async () => {
  let url = apiPath.member.getUserInfo;
  let { status, response } = await get(url, {}, true);
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};

export const getProfileRegister = async (agentCode) => {
  if (!agentCode) {
    return null;
  }
  let url = apiPath.member.getProfileRegister;
  let { status, response } = await get(url, { agentCode: agentCode }, false);
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};

export const changeAdminActive = async (userId, active) => {
  let url = apiPath.member.changeAdminActive;
  let { status, response } = await put(
    url,
    { userId: userId, active: active },
    true
  );
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};
