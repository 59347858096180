import React, { useEffect, useState } from "react";
import TextField from "./TextField";
import { View, Text, TouchableOpacity } from "react-native-web";
//images
import {
  PlusCircleIcon,
  DeleteCircleIcon,
} from "../../asset/images/icons/iconsLib";

const TextFieldList = (props) => {
  const { placeholder, value } = props;
  const { onChange } = props;

  const [list, setList] = useState([""]);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (value) {
      setList(value);
    }
  }, [value]);

  const add = () => {
    if (list[index]) {
      list.push("");
      setList([...list]);
      setIndex((prev) => {
        return prev + 1;
      });
    }
  };

  const remove = (index) => {
    if (index === undefined || index === null) {
      return;
    }
    list.splice(index, 1);
    console.log(list);
    setList([...list]);
    setIndex((prev) => {
      return prev;
    });
  };

  return (
    <View>
      {list.map((item, index) => {
        return (
          <TextRow
            key={"textFieldList" + index.toString()}
            index={index}
            placeholder={placeholder}
            data={item}
            isLast={index + 1 === list.length}
            onAdd={() => add()}
            onChangeText={(value) => {
              list[index] = value;
              setList([...list]);
              onChange && onChange(list);
            }}
            onFocus={() => {
              setIndex(index);
            }}
            onRemove={(index) => remove(index)}
          />
        );
      })}
    </View>
  );
};

const TextRow = (props) => {
  const { data, placeholder, isLast, index } = props;
  const { onAdd, onChangeText, onFocus, onRemove } = props;
  return (
    <View
      style={{
        flex: 1,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 20,
      }}
    >
      <TextField
        value={data ? data : ""}
        placeholder={placeholder}
        onChangeText={(value) => {
          onChangeText && onChangeText(value);
        }}
        onFocus={(event) => {
          onFocus && onFocus();
        }}
      />
      <View style={{ width: 20 }} />
      {isLast ? (
        <TouchableOpacity onPress={() => onAdd && onAdd()}>
          <PlusCircleIcon
            width={24}
            height={24}
            color={"rgba(15, 16, 17, 1)"}
          />
        </TouchableOpacity>
      ) : (
        <TouchableOpacity onPress={() => onRemove && onRemove(index)}>
          <DeleteCircleIcon
            width={21}
            height={21}
            color={"rgba(227, 5, 5, 1)"}
          />
        </TouchableOpacity>
      )}
    </View>
  );
};

export default TextFieldList;
