import React, { useState } from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
//components
import DropdownMenuPopover from "../../../components/dropdown/DropdownPopover";
import PageButtonNavigate from "../../../components/button/PageButtonNavigate";
import { EditButton } from "../../../components/button/IconButton";
//images
import emptyProduct from "../../../asset/images/product/inventory/emptyProduct.png";
//functions
import { formatNumber } from "../../../functions/format/numberFormat";

const AgentFreeCourseTable = (props) => {
  const { tableHeader = [], tableData = [] } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const pagesCount = Math.ceil(tableData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const tableDataSliced = tableData.slice(startIndex, endIndex);

  const { onSelect } = props;

  return tableData.length > 0 ? (
    <View style={{ width: "100%", height: "100%" }}>
      <TableContainer sx={styles.tableContainer}>
        <Table sx={styles.table}>
          <TableHead sx={{ position: "sticky", top: 0, zIndex: 9999 }}>
            <TableRow style={{ width: "100%", backgroundColor: "#FFF" }}>
              <TableCell sx={{ ...styles.headerCell }}></TableCell>
              <TableCell sx={{ ...styles.headerCell }}>รหัสตัวแทน</TableCell>
              <TableCell sx={{ ...styles.headerCell }}>
                ชื่อ - นามสกุล
              </TableCell>
              <TableCell sx={{ ...styles.headerCell }}>อีเมล</TableCell>
              <TableCell sx={{ ...styles.headerCell }}>เบอร์โทร</TableCell>

              <TableCell sx={{ ...styles.headerCell }}>
                วันที่/เวลา ลงทะเบียน
              </TableCell>
              <TableCell sx={{ ...styles.headerCell }}>สถานะ</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableDataSliced.map((row) => (
              <TableRow key={row.id} sx={styles.tableRow}>
                <TableCell
                  sx={{
                    ...styles.tableCell,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={row.imageProfile ? row.imageProfile : ""}
                    style={{
                      width: 50,
                      height: 50,
                      borderRadius: 8,
                      objectFit: "cover",
                      alignSelf: "center",
                    }}
                  />
                </TableCell>
                <TableCell sx={styles.tableCell}>
                  <Text>{row.agentCode ? row.agentCode : ""}</Text>
                </TableCell>
                <TableCell sx={styles.tableCell}>
                  <Text>{row.name ? row.name : ""}</Text>
                </TableCell>
                <TableCell sx={styles.tableCell}>
                  <Text>{row.email ? row.email : ""}</Text>
                </TableCell>
                <TableCell sx={styles.tableCell}>
                  <Text>{row.phoneNumber ? row.phoneNumber : ""}</Text>
                </TableCell>
                <TableCell sx={styles.tableCell}>
                  <Text>{row.createDate ? row.createDate : ""}</Text>
                </TableCell>
                <TableCell sx={styles.tableCell}>
                  <Text>{row.active ? row.active : ""}</Text>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <PageButtonNavigate
        currentPage={currentPage}
        pagesCount={pagesCount}
        startIndex={startIndex}
        endIndex={endIndex}
        dataCount={tableData.length}
        onSelectPage={(page) => {
          setCurrentPage(page);
        }}
      />
    </View>
  ) : (
    <EmptyList />
  );
};

const EmptyList = () => {
  return (
    <View
      style={{
        flex: 1,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={emptyProduct} style={{ width: 120 }} alt={"emptyProduct"} />
      <View style={{ height: 30 }} />
      <Text
        style={{
          fontSize: "clamp(16px,2vh,20px)",
          fontWeight: "bold",
          color: "rgba(15, 16, 17, 1)",
        }}
      >
        ยังไม่มีรายการสมาชิกที่เรียนฟรี
      </Text>
    </View>
  );
};

const styles = {
  tableContainer: {
    overflowY: "scroll",
    scrollbarWidth: "thin",
    scrollbarColor: "#c7c7c7 #f5f5f5",
    flex: 1,
  },
  table: {
    border: "none",
    minWidth: 650,
  },
  tableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: "rgba(255, 255, 255, 1)",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "rgba(255, 241, 241, 1)",
    },
  },
  tableCell: {
    borderBottom: "none",
    whiteSpace: "nowrap",
    wordBreak: "break-word",
  },
  headerCell: {
    color: "rgba(165, 88, 90, 1)",
    whiteSpace: "nowrap",
  },
};

export default AgentFreeCourseTable;
