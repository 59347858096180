import React from "react";
import CheckBox from "./CheckBox";
import { View, Text } from "react-native-web";

const CheckBoxLabel = (props) => {
  const { label, value } = props;
  const { onCheck } = props;
  return (
    <View style={{ flexDirection: "row", alignItems: "center" }}>
      <CheckBox
        value={value && value}
        onCheck={(value) => {
          onCheck && onCheck(value);
        }}
      />
      <View style={{ width: 8 }} />
      <Text style={Styles.text}>{label ?? ""}</Text>
    </View>
  );
};

const Styles = {
  text: {
    color: "rgba(15, 16, 17, 1)",
  },
};

export default CheckBoxLabel;
