import React, { useState, useEffect } from "react";
import { View, Text } from "react-native-web";
import CheckBox from "./CheckBox";
import { Divider } from "rsuite";
import TextField from "../textField/TextField";
import { v4 } from "uuid";

const CheckBoxListV2 = (props) => {
  const { list, showLine = true, showTextFieldOption = false } = props;
  const { minWidth } = props;
  const { onCheck, onChangeOption } = props;

  const [state, setState] = useState([]);

  useEffect(() => {
    if (list.length > 0) {
      console.log("set check nox", list);
      setState(list);
    }
  }, [list]);

  return (
    <View style={Styles.container}>
      {state.map((item, index) => {
        return (
          <SelectBox
            index={index}
            item={item}
            onCheck={(values) => {
              console.log(v4());
              onCheck && onCheck(values);
            }}
            minWidth={minWidth}
            showLine={showLine}
            showTextFieldOption={showTextFieldOption}
            onChangeOption={(values) => {
              onChangeOption && onChangeOption(values);
            }}
          />
        );
      })}
    </View>
  );
};

const SelectBox = (props) => {
  const {
    index,
    selectIndex,
    item,
    showLine,
    showTextFieldOption = false,
    key,
  } = props;
  const { minWidth } = props;
  const { onCheck, onChangeOption } = props;

  return (
    <View>
      <View>
        <View style={Styles.sbContainer}>
          <CheckBox
            value={item.active ? item.active === "Y" : false}
            color="rgba(170, 73, 66, 1)"
            onCheck={(value) => {
              if (item.enable) {
                let init = { ...item };
                if (!value && init.subMenu) {
                  if (init.subMenu.length > 0) {
                    init.subMenu.forEach((element) => {
                      element.active = "N";
                    });
                  }
                }
                onCheck && onCheck({ ...init, active: value ? "Y" : "N" });
              }
            }}
          />
          <Text
            style={{
              ...Styles.sbText,
              minWidth: minWidth ? minWidth : 0,
              color: item.enable ? "" : "#B9BDC7",
            }}
          >
            {item.label ? item.label : ""}
          </Text>
        </View>
        {showTextFieldOption ? (
          <TextField
            value={item.optionText ? item.optionText : ""}
            style={{ marginTop: 10, flex: 1, maxWidth: 300 }}
            onChangeText={(value) => {
              console.log("ion option", value);
              onChangeOption && onChangeOption({ ...item, optionText: value });
            }}
          />
        ) : null}
      </View>
      {item.subMenu ? (
        <View style={Styles.containerSubMenu} key={`${v4()}`}>
          {item.subMenu.map((subItem) => {
            return (
              <View style={Styles.sbContainer} key={`${v4()}`}>
                <CheckBox
                  value={subItem.active ? subItem.active === "Y" : false}
                  color="rgba(170, 73, 66, 1)"
                  onCheck={(value) => {
                    if (subItem.enable) {
                      let init = { ...item };
                      let findIndexSubMenu = init.subMenu.findIndex((d) => {
                        return d.value === subItem.value;
                      });
                      if (findIndexSubMenu >= 0) {
                        init.subMenu[findIndexSubMenu].active = value
                          ? "Y"
                          : "N";
                        if (value) {
                          init.active = "Y";
                        }
                      }
                      onCheck && onCheck(init);
                    }
                  }}
                />
                <Text style={Styles.subMenuText}>{subItem.label}</Text>
              </View>
            );
          })}
        </View>
      ) : null}
      {showLine ? <Divider /> : null}
    </View>
  );
};

const Styles = {
  container: {
    width: "100%",
    margin: 10,
  },
  sbContainer: {
    flexDirection: "row",
    marginTop: 10,
  },
  sbText: {
    marginLeft: 8,
    fontSize: "clamp(12px,2vh,16px)",
  },
  containerSubMenu: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    margin: 10,
    marginBottom: 0,
    marginLeft: 30,
  },
  subMenuText: {
    marginLeft: 8,
    marginRight: 16,
    marginBottom: 8,
    fontSize: 14,
  },
};

export default CheckBoxListV2;
