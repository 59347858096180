import apiPath from "../../configs/apiPath";
import { get, post } from "../../../functions/fetch/fetch";

export const downloadPaymentMethodList = async ({transportCode}) => {
  console.log("TransportCode", transportCode)
  let url = apiPath.sales.getPaymentMethod;
  let { status, response } = await get(url, {
    transportCode: transportCode ? transportCode : null,
  }, true);
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};

export const downloadTransportMethodList = async () => {
  let url = apiPath.sales.getTransportMethod;
  let { status, response } = await get(url, {}, true);
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};

export const downloadCurrentTransportCode = async () => {
  let url = apiPath.transport.getCurrentTransportCode;
  let { status, response } = await get(url, {}, true);
  if (!status) {
    console.log(response.data.error);
  }
  return !status ? [] : response.data;
};
