import React, { useEffect } from "react";
import { View } from "react-native-web";
import { Container } from "shards-react";
//component
import AddOrder from "./AddOrder";

const FirstOrder = (props) => {
  useEffect(() => {}, []);

  return (
    <View style={Styles.container}>
      <Container>
        <View style={{ flex: 1, paddingBottom: 10 }}>
          <AddOrder
            isFirstOrder={true}
            onClose={() => {
              window.location.href = "/";
            }}
          />
        </View>
      </Container>
    </View>
  );
};

const Styles = {
  container: {
    width: "100%",
    height: "100%",
    padding: 0,
  },
};
export default FirstOrder;
