import { Divider } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Modal, View, Text, StyleSheet, ScrollView } from "react-native-web";
import { Grid, Typography } from "@mui/material";
//components
import CloseButton from "../../../components/button/CloseButton";
import Button from "../../../components/button/Button";
import TextField from "../../../components/textField/TextField";
import Dropdown from "../../../components/dropdown/Dropdown";
import { useDialog } from "../../../components/modal/Popup";
//functions
import { calculatorFontSize } from "../../../functions/screen/screenFunction";
import {
  allFieldsSet,
  validatePhone,
} from "../../../functions/validate/validate";
import {
  downloadAllCountry,
  downloadAllProvince,
  downloadAllDistrict,
  downloadAllSubDistrict,
  downloadAllZipcode,
} from "../../../services/api/masterdata/address";
import apiPath from "../../../services/configs/apiPath";
import { validateEmail } from "../../../functions/validate/validate";
import { post } from "../../../functions/fetch/fetch";
//images
import { CorrectCircleIcon } from "../../../asset/images/icons/iconsLib";
import {
  formatNumber,
  formatNumberString,
} from "../../../functions/format/numberFormat";
import { getDisabledState } from "rsuite/esm/CheckTreePicker/utils";

const PopupAddCustomer = (props) => {
  const { visible, data, saleCode } = props;
  const { title, subTitle } = props;
  const { onEnter, onClose } = props;
  const dialog = useDialog();
  //state
  const [countryList, setCountryList] = useState([]);
  const [provinceList, setProvinceList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [subDistrictList, setSubDistrictList] = useState([]);
  const [zipcodeList, setZipcodeList] = useState([]);
  const [customerData, setCustomerData] = useState({
    name: null,
    email: null,
    countryCode: "THA",
    phoneNumber: null,
    address: null,
    provinceCode: null,
    province: null,
    districtCode: null,
    district: null,
    subDistrictCode: null,
    subDistrict: null,
    zipcode: null,
    facebook: null,
    instargram: null,
  });

  useEffect(() => {
    setCustomerData({
      name: null,
      email: null,
      countryCode: "THA",
      phoneNumber: null,
      address: null,
      provinceCode: null,
      province: null,
      districtCode: null,
      district: null,
      subDistrictCode: null,
      subDistrict: null,
      zipcode: null,
      facebook: null,
      instargram: null,
    });
  }, [visible]);

  useEffect(() => {
    const downloadAllData = async () => {
      dialog.loading();
      let countrys = await downloadAllCountry();
      let initCountrys = [];
      countrys.forEach((item) => {
        initCountrys.push({
          value: item.countryCode,
          label: item.countryNameTh,
        });
      });
      setCountryList(initCountrys);

      let provinces = await downloadAllProvince();
      let initProvinces = [];
      provinces.forEach((item) => {
        initProvinces.push({ value: item.provinceCode, label: item.province });
      });
      setProvinceList(initProvinces);
      dialog.cancle();
    };
    downloadAllData();
  }, []);

  useEffect(() => {
    const getDistrictList = async () => {
      let districtList = await downloadAllDistrict(customerData.provinceCode);
      let initDistrictList = [];
      districtList.forEach((item) => {
        initDistrictList.push({
          value: item.districtCode,
          label: item.district,
        });
      });
      setDistrictList(initDistrictList);
    };
    if (customerData.provinceCode) {
      getDistrictList();
      setCustomerData({
        ...customerData,
        districtCode: null,
        district: null,
        subDistrictCode: null,
        subDistrict: null,
        zipcode: null,
      });
    }
  }, [customerData?.provinceCode]);

  useEffect(() => {
    const getSubDistrictList = async () => {
      let subDistrictList = await downloadAllSubDistrict(
        customerData.provinceCode,
        customerData.districtCode
      );
      let initSubDistrictList = [];
      subDistrictList.forEach((item) => {
        initSubDistrictList.push({
          value: item.subDistrictCode,
          label: item.subDistrict,
        });
      });
      setSubDistrictList(initSubDistrictList);
    };
    if (customerData.provinceCode && customerData.districtCode) {
      getSubDistrictList();
      setCustomerData({
        ...customerData,
        subDistrictCode: null,
        subDistrict: null,
        zipcode: null,
      });
    }
  }, [customerData?.districtCode]);

  useEffect(() => {
    const getZipcodeList = async () => {
      let zipcodeList = await downloadAllZipcode(
        customerData.province,
        customerData.district,
        customerData.subDistrict
      );
      let initZipcodeList = [];
      zipcodeList.forEach((item) => {
        initZipcodeList.push({
          value: item.zipcode,
          label: item.zipcode,
        });
      });
      setZipcodeList(initZipcodeList);
    };
    if (
      customerData.province &&
      customerData.district &&
      customerData.subDistrict
    ) {
      getZipcodeList();
      setCustomerData({
        ...customerData,
        zipcode: null,
      });
    }
  }, [customerData?.subDistrictCode]);

  const submit = async () => {
    let valid = true;
    const {
      name,
      email,
      countryCode,
      phoneNumber,
      address,
      provinceCode,
      districtCode,
      subDistrictCode,
      zipcode,
    } = customerData;

    // if (!email) {
    //   await dialog.alert({
    //     title: "แจ้งเตือน",
    //     message: "กรุณาระบุอีเมล",
    //   });
    //   return;
    // }
    // if (email && !validateEmail(email)) {
    //   await dialog.alert({
    //     title: "แจ้งเตือน",
    //     message: "รูปแบบของอีเมล์ไม่ถูกต้อง",
    //   });
    //   return;
    // }

    if (!name || !address || !phoneNumber) {
      valid = false;
    }

    let matchCountryCode = countryList.find((item) => item.value === countryCode);
    if (!matchCountryCode) {
      valid = false;
      await dialog.alert({
        title: "แจ้งเตือน",
        message: "เกิดข้อผิดพลาด ประเทศไม่ถูกต้อง กรุณาเลือกใหม่อีกครั้ง โดยการสลับเป็นประเทศอื่นก่อน และเลือกประเทศที่ต้องการอีกครั้ง",
      });
      return;
    }

    if (countryCode === "THA") {
      if (!provinceCode || !districtCode || !subDistrictCode || !zipcode) {
        valid = false;
      }
      if (!validatePhone(phoneNumber)) {
        await dialog.alert({
          title: "แจ้งเตือน",
          message: "เบอร์โทรศัพท์ไม่ถูกต้อง",
        });
        return;
      }
    }

    if (!valid) {
      await dialog.alert({
        title: "แจ้งเตือน",
        message: "กรุณากรอกข้อมูลให้ครบถ้วน",
      });
      return;
    }
    dialog.loading();
    let url = apiPath.sales.addCustomerSales;
    let { status, response } = await post(
      url,
      {
        ...customerData,
        saleCode: saleCode,
        appName:"onelife"
      },
      true
    );
    if (!status) {
      await dialog.alert({ title: "แจ้งเตือน", message: response.data.error });
      dialog.cancle();
      return;
    }

    await dialog.alert({ title: "แจ้งเตือน", message: "เพิ่มลูกค้าเรียบร้อย" });
    dialog.cancle();
    onClose && onClose();
  };

  return (
    <Modal transparent={true} visible={visible ? visible : false}>
      <View style={Styles.modalContainer}>
        <View style={Styles.box}>
          <View style={Styles.headerBox}>
            <View style={{ flex: 1 }}>
              <Text style={Styles.headerTitle}>เพิ่มลูกค้า</Text>
            </View>
            <CloseButton
              onPress={() => {
                onClose && onClose();
              }}
            />
          </View>
          <View style={{ height: 20 }} />
          <ScrollView
            style={{ width: "100%", padding: 5 }}
            showsVerticalScrollIndicator={false}
          >
            <Grid container spacing={4} style={{}}>
              <Grid item xs={12}>
                <View
                  style={{
                    width: "100%",
                    height: 1,
                    backgroundColor: "rgba(242, 242, 242, 1)",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Text style={Styles.titleTextField}>
                  ชื่อ - นามสกุล{" "}
                  <Text style={{ color: "rgba(204, 0, 0, 1)" }}> *</Text>
                </Text>
                <View style={{ height: 8 }} />
                <TextField
                  iconName={""}
                  isInvalid={false}
                  placeholder={"ระบุชื่อ - นามสกุล"}
                  errorMessage={""}
                  onChangeText={(value) => {
                    setCustomerData({
                      ...customerData,
                      name: value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Text style={Styles.titleTextField}>
                  อีเมล
                  {/* อีเมล <Text style={{ color: "rgba(204, 0, 0, 1)" }}> *</Text> */}
                </Text>
                <View style={{ height: 8 }} />
                <TextField
                  iconName={""}
                  isInvalid={false}
                  placeholder={"ระบุอีเมล"}
                  errorMessage={""}
                  onChangeText={(value) => {
                    setCustomerData({
                      ...customerData,
                      email: value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Text style={Styles.titleTextField}>
                  เบอร์โทรศัพท์
                  <Text style={{ color: "rgba(204, 0, 0, 1)" }}> *</Text>
                </Text>

                <View style={{ height: 8 }} />
                <TextField
                  value={customerData.phoneNumber}
                  iconName={""}
                  isInvalid={false}
                  placeholder={"ระบุเบอร์โทรศัพท์"}
                  errorMessage={""}
                  onChangeText={(value) => {
                    let num = formatNumberString(value);
                    if (num.length <= 10) {
                      setCustomerData({
                        ...customerData,
                        phoneNumber: num,
                      });
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Text style={Styles.titleTextField}>
                  ประเทศ
                  <Text style={{ color: "rgba(204, 0, 0, 1)" }}> *</Text>
                </Text>
                <View style={{ height: 8 }} />
                <Dropdown
                  value={customerData.countryCode}
                  valueType={"string"}
                  placeholder={"เลือกประเทศ"}
                  list={countryList}
                  style={{ zIndex: 100010 }}
                  onChange={(values) => {
                    const { value, label } = values;
                    setCustomerData({
                      ...customerData,
                      countryCode: value,
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Text style={Styles.titleTextField}>
                  ที่อยู่
                  <Text style={{ color: "rgba(204, 0, 0, 1)" }}> *</Text>
                </Text>

                <View style={{ height: 8 }} />
                <TextField
                  iconName={""}
                  isInvalid={false}
                  placeholder={"ระบุที่อยู่"}
                  errorMessage={""}
                  onChangeText={(value) => {
                    setCustomerData({
                      ...customerData,
                      address: value,
                    });
                  }}
                />
              </Grid>

              {customerData && customerData.countryCode === "THA" ? (
                <Grid item xs={12} md={6}>
                  <Text style={Styles.titleTextField}>
                    จังหวัด
                    <Text style={{ color: "rgba(204, 0, 0, 1)" }}> *</Text>
                  </Text>
                  <View style={{ height: 8 }} />
                  <Dropdown
                    value={{
                      value: customerData.provinceCode,
                      label: customerData.province,
                    }}
                    placeholder={"เลือกจังหวัด"}
                    list={provinceList}
                    style={{ zIndex: 100009 }}
                    onChange={(values) => {
                      const { value, label } = values;
                      setCustomerData({
                        ...customerData,
                        provinceCode: String(value),
                        province: label,
                      });
                    }}
                  />
                </Grid>
              ) : null}

              {customerData && customerData.countryCode === "THA" ? (
                <Grid item xs={12} md={6}>
                  <Text style={Styles.titleTextField}>
                    เขต/อำเภอ
                    <Text style={{ color: "rgba(204, 0, 0, 1)" }}> *</Text>
                  </Text>
                  <View style={{ height: 8 }} />
                  <Dropdown
                    value={{
                      value: customerData.districtCode,
                      label: customerData.district,
                    }}
                    disable={districtList.length === 0}
                    placeholder={"เลือกเขต/อำเภอ"}
                    list={districtList}
                    style={{ zIndex: 100008 }}
                    onChange={(values) => {
                      const { value, label } = values;
                      setCustomerData({
                        ...customerData,
                        districtCode: String(value),
                        district: label,
                      });
                    }}
                  />
                </Grid>
              ) : null}

              {customerData && customerData.countryCode === "THA" ? (
                <Grid item xs={12} md={6}>
                  <Text style={Styles.titleTextField}>
                    แขวง/ตำบล
                    <Text style={{ color: "rgba(204, 0, 0, 1)" }}> *</Text>
                  </Text>
                  <View style={{ height: 8 }} />
                  <Dropdown
                    value={{
                      value: customerData.subDistrictCode,
                      label: customerData.subDistrict,
                    }}
                    disable={subDistrictList.length === 0}
                    placeholder={"เลือกแขวง/ตำบล"}
                    list={subDistrictList}
                    style={{ zIndex: 100007 }}
                    onChange={(values) => {
                      const { value, label } = values;
                      setCustomerData({
                        ...customerData,
                        subDistrictCode: String(value),
                        subDistrict: label,
                      });
                    }}
                  />
                </Grid>
              ) : null}

              {customerData && customerData.countryCode === "THA" ? (
                <Grid item xs={12} md={6}>
                  <Text style={Styles.titleTextField}>
                    รหัสไปรษณีย์
                    <Text style={{ color: "rgba(204, 0, 0, 1)" }}> *</Text>
                  </Text>
                  <View style={{ height: 8 }} />
                  <Dropdown
                    value={{
                      value: customerData.zipcode,
                      label: customerData.zipcode,
                    }}
                    disable={subDistrictList.length === 0}
                    placeholder={"เลือกรหัสไปรษณีย์"}
                    list={zipcodeList}
                    style={{ zIndex: 100006 }}
                    onChange={(values) => {
                      const { value, label } = values;
                      setCustomerData({
                        ...customerData,
                        zipcode: String(value),
                      });
                    }}
                  />
                </Grid>
              ) : null}

              <Grid item xs={12} md={6}>
                <Text style={Styles.titleTextField}>Facebook</Text>
                <View style={{ height: 8 }} />
                <TextField
                  iconName={""}
                  isInvalid={false}
                  placeholder={"ระบุชื่อ Facebook"}
                  errorMessage={""}
                  onChangeText={(value) => {
                    setCustomerData({
                      ...customerData,
                      facebook: value,
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Text style={Styles.titleTextField}>Instargram</Text>
                <View style={{ height: 8 }} />
                <TextField
                  iconName={""}
                  isInvalid={false}
                  placeholder={"ระบุชื่อ Instargram"}
                  errorMessage={""}
                  onChangeText={(value) => {
                    setCustomerData({
                      ...customerData,
                      instargram: value,
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <View
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    buttonText={"ยกเลิก"}
                    color={"primary"}
                    isBorder={true}
                    style={{ width: "100%", maxWidth: 400 }}
                    onClick={() => {
                      onClose && onClose();
                    }}
                  />
                  <View style={{ width: 20 }} />
                  <Button
                    buttonText={"เพิ่มลูกค้า"}
                    color={"primary"}
                    style={{ width: "100%", maxWidth: 400 }}
                    onClick={() => {
                      submit();
                    }}
                  />
                </View>
              </Grid>
            </Grid>
          </ScrollView>
        </View>
      </View>
    </Modal>
  );
};

const Styles = {
  modalContainer: {
    position: "fixed",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 9999,
    alignItems: "center",
    justifyContent: "center",
  },
  box: {
    width: "90%",
    maxHeight: "90%",
    maxWidth: 1280,
    padding: 20,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFF",
    borderRadius: 16,
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.08,
    shadowRadius: 16,
  },
  headerBox: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  headerTitle: {
    fontSize: calculatorFontSize(16, 20),
    color: "rgba(15, 16, 17, 1)",
    flex: 1,
  },
  titleTextField: {},
  dropdownContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default PopupAddCustomer;
