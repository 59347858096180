import React from "react";
import { View, Text, Touchable, TouchableOpacity } from "react-native-web";
import { Divider } from "rsuite";
import { Grid } from "@mui/material";
//component
import UploadImage from "../../../components/uploadbox/UploadImage";
import TextField from "../../../components/textField/TextField";
import Button from "../../../components/button/Button";
import Dropdown from "../../../components/dropdown/Dropdown";
//images
import { CalendarRewardIcon } from "../../../asset/images/icons/iconsLib";
import productImg from "../../../asset/images/example/product.png";
import { SlOptionsVertical } from "react-icons/sl";
import TextEditor from "../../../components/textField/TextEditor";
import VideoUploadPreview from "../../../components/uploadbox/VideoUploadPreview";

const CreateTopic = (props) => {
  const { onClose } = props;
  return (
    <View>
      <Grid container spacing={2}>
        <Grid item xs={12} md={2}>
          <Text style={Styles.titleText}>
            แสดงผล
            <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
          </Text>
          <View style={{ height: 8 }} />
          <Dropdown
            value={""}
            valueType={"string"}
            placeholder={"เลือกแสดงผล"}
            list={""}
            onChange={(values) => {
              const { value, label } = values;
            }}
          />
        </Grid>
        <Grid item xs={12} md={8}></Grid>
        <Grid item xs={12} md={2}>
          <Button
            buttonText={"ดูตัวอย่าง"}
            color={"disable"}
            style={{ width: "100%" }}
            onClick={() => {
              onClose && onClose();
            }}
          />
        </Grid>
      </Grid>
      <View style={{ height: 20 }} />
      <Text style={Styles.titleTextHead}>สร้างคลิปวิดีโอ</Text>
      <View style={{ height: 20 }} />

      <Grid container spacing={2}>
        {false && (
          <Grid item xs={12}>
            <VideoUploadPreview />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <Text style={Styles.titleText}>
            หัวข้อคลิปวิดีโอ
            <Text style={{ color: "rgba(204, 0, 0, 1)" }}> *</Text>
          </Text>
          <View style={{ height: 8 }} />

          <TextField
            value={""}
            iconName={""}
            isInvalid={false}
            placeholder={"ระบุหัวข้อคลิปวิดีโอ"}
            errorMessage={""}
            onChangeText={(value) => {}}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Text style={Styles.titleText}>
            Order
            <Text style={{ color: "rgba(204, 0, 0, 1)" }}> *</Text>
          </Text>
          <View style={{ height: 8 }} />

          <TextField
            value={""}
            iconName={""}
            isInvalid={false}
            placeholder={"ระบุ Order"}
            errorMessage={""}
            onChangeText={(value) => {}}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Text style={Styles.titleText}>
            หมวดหมู่บทความ
            <Text style={{ color: "rgba(204, 0, 0, 1)" }}>*</Text>
          </Text>
          <View style={{ height: 8 }} />
          <Dropdown
            value={""}
            valueType={"string"}
            placeholder={"เลือกหมวดหมู่บทความ"}
            list={""}
            onChange={(values) => {
              const { value, label } = values;
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Text style={Styles.titleText}>
            คำอธิบาย
            <Text style={{ color: "rgba(204, 0, 0, 1)" }}> *</Text>
          </Text>
          <View style={{ height: 8 }} />

          <TextField
            value={""}
            iconName={""}
            isInvalid={false}
            placeholder={"ระบุคำอธิบาย"}
            errorMessage={""}
            onChangeText={(value) => {}}
          />
        </Grid>
        <Grid item xs={12}>
          <Text style={Styles.titleText}>
            จัดการ Content
            <Text style={{ color: "rgba(204, 0, 0, 1)" }}> *</Text>
          </Text>
          <View style={{ height: 8 }} />
          <TextEditor />
        </Grid>
      </Grid>
      <View style={{ height: 60 }} />
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          buttonText={"ยกเลิก"}
          color={"primary"}
          style={{ flex: 1, maxWidth: 400 }}
          isBorder={true}
          onClick={() => {
            onClose && onClose();
          }}
        />
        <View style={{ width: 20 }} />
        <Button
          buttonText={"สร้างคลิปวิดีโอ"}
          color={"primary"}
          style={{ flex: 1, maxWidth: 400 }}
          isBorder={false}
          onClick={() => {}}
        />
      </View>
      <View style={{ height: 40 }} />
    </View>
  );
};

const Styles = {
  titleTextHead: {
    fontSize: "clamp(16px,2vh,20px)",
    fontWeight: "bold",
    color: "rgba(15, 16, 17, 1)",
  },
  textImageMark: {
    fontSize: 14,
    color: "rgba(105, 109, 121, 1)",
  },
};

export default CreateTopic;
