import React, { useState, useEffect } from "react";
import PageButton from "./PageButton";

const PageButtonGroup = (props) => {
  const { pageCount, currentPage, maxPageButton = 5 } = props;
  const [buttons, setButtons] = useState([]);
  const { onClick } = props;

  useEffect(() => {
    if (pageCount && currentPage) {
      let startPage = Math.max(1, Math.min(pageCount - maxPageButton + 1, Math.floor((currentPage - 1) / maxPageButton) * maxPageButton + 1));
      let endPage = Math.min(pageCount, startPage + maxPageButton - 1);
      
      let init = [];
      for (let x = startPage; x <= endPage; x++) {
        init.push(
          <PageButton
            key={"pageButton_" + x}
            label={x.toLocaleString()}
            isSelect={currentPage === x}
            onClick={() => {
              onClick && onClick(x);
            }}
          />
        );
      }
      setButtons(init);
    }
  }, [pageCount, currentPage, maxPageButton]);

  return <React.Fragment>{buttons}</React.Fragment>;
};

export default PageButtonGroup;
