import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import moment from "moment-timezone";
//components
//images
import emptyProduct from "../../../asset/images/product/inventory/emptyProduct.png";
//functions
import PageButtonNavigate from "../../../components/button/PageButtonNavigate";
import DropdownPopover from "../../../components/dropdown/DropdownPopover";
import Loading from "../../../components/modal/Loading";
import LoadingTab from "../../../components/modal/LoadingTab";
const EventJoinTable = (props) => {
  const { tableHeader = [], tableData = [], isLoading = false } = props;
  const { onOption } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const pagesCount = Math.ceil(tableData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const tableDataSliced = tableData.slice(startIndex, endIndex);

  return tableData.length > 0 ? (
    <View style={{ width: "100%", height: "100%" }}>
      <TableContainer sx={styles.tableContainer}>
        <Table sx={styles.table}>
          <TableHead sx={{ position: "sticky", top: 0, zIndex: 9999 }}>
            <TableRow style={{ width: "100%", backgroundColor: "#FFF" }}>
              <TableCell sx={{ ...styles.headerCell }}>วันที่/เวลา</TableCell>
              <TableCell sx={{ ...styles.headerCell }}>รหัสตัวแทน</TableCell>
              <TableCell sx={{ ...styles.headerCell }}>
                ชื่อ - นามสกุล
              </TableCell>
              <TableCell sx={{ ...styles.headerCell }}>สถานะ</TableCell>
              <TableCell sx={{ ...styles.headerCell }}>
                สถานะการจัดการ
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableDataSliced.map((row) => {
              return (
                <TableRow key={row.id} sx={styles.tableRow}>
                  <TableCell sx={styles.tableCell}>
                    <Text>
                      {row.create_date
                        ? moment(row.create_date).format("YYYY/MM/DD HH:mm")
                        : ""}
                    </Text>
                  </TableCell>
                  <TableCell sx={styles.tableCell}>
                    <Text>{row.agent_code ? row.agent_code : ""}</Text>
                  </TableCell>
                  <TableCell sx={styles.tableCell}>
                    <Text>{row.name ? row.name : ""}</Text>
                  </TableCell>
                  <TableCell sx={styles.tableCell}>
                    <StatusTag status={row.active_in_event} />
                  </TableCell>
                  <TableCell sx={styles.tableCell}>
                    {" "}
                    <DropdownPopover
                      title={"ตัวเลือก"}
                      list={[
                        { label: "ยืนยันการเข้าร่วม", value: "accept" },
                        { label: "ดูรายละเอียด", value: "view" },
                      ]}
                      onSelect={(value) => {
                        onOption && onOption({ action: value, data: row });
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <PageButtonNavigate
        currentPage={currentPage}
        pagesCount={pagesCount}
        startIndex={startIndex}
        endIndex={endIndex}
        dataCount={tableData.length}
        onSelectPage={(page) => {
          setCurrentPage(page);
        }}
      />
    </View>
  ) : isLoading && tableData.length === 0 ? (
    <EmptyList />
  ) : (
    <LoadingTab />
  );
};

const StatusTag = (props) => {
  const { status = "INACTIVE" } = props;
  return (
    <View
      style={{
        width: 120,
        height: 20,
        backgroundColor:
          status === "ACTIVE" ? "#B4F1B3" : "rgba(255, 228, 187, 1)",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 8,
      }}
    >
      <Text
        style={{
          fontSize: 12,
          fontWeight: "bold",
          color: status === "ACTIVE" ? "#1C9B1A" : "rgba(241, 152, 19, 1)",
        }}
      >
        {status === "ACTIVE" ? "เข้าร่วมกิจกรรม" : "ยังไม่เข้าร่วมกิจกรรม"}
      </Text>
    </View>
  );
};

const EmptyList = () => {
  return (
    <View
      style={{
        flex: 1,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={emptyProduct} style={{ width: 120 }} alt={"emptyProduct"} />
      <View style={{ height: 30 }} />
      <Text
        style={{
          fontSize: "clamp(16px,2vh,20px)",
          fontWeight: "bold",
          color: "rgba(15, 16, 17, 1)",
        }}
      >
        ยังไม่มีรายการ
      </Text>
    </View>
  );
};

const styles = {
  tableContainer: {
    overflowY: "scroll",
    scrollbarWidth: "thin",
    scrollbarColor: "#c7c7c7 #f5f5f5",
    flex: 1,
  },
  table: {
    border: "none",
    minWidth: 650,
  },
  tableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: "rgba(255, 255, 255, 1)",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "rgba(255, 241, 241, 1)",
    },
  },
  tableCell: {
    borderBottom: "none",
    whiteSpace: "nowrap",
    wordBreak: "break-word",
  },
  headerCell: {
    color: "rgba(165, 88, 90, 1)",
    whiteSpace: "nowrap",
  },
};

export default EventJoinTable;
