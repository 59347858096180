import React from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import { Grid } from "@mui/material";
import moment from "moment-timezone";
import apiPath from "../../../services/configs/apiPath";
//images
import productImg from "../../../asset/images/example/product.png";
import { CalendarRewardIcon } from "../../../asset/images/icons/iconsLib";
import { BsListUl } from "react-icons/bs";
import { GroupIcon } from "../../../asset/images/icons/iconsLib";

const ContentListGrid = (props) => {
  const { data } = props;

  return (
    <Grid container spacing={2}>
      {data.map((item) => {
        return <ContentGridInfo data={item} />;
      })}
    </Grid>
  );
};

const ContentGridInfo = (props) => {
  const { data } = props;

  const downloadFile = (filePath, fileName = "file.jpg") => {
    let url = `${apiPath.university.downloadFile}?url=${filePath}`;
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok " + response.statusText);
        }

        const contentDisposition = response.headers.get("Content-Disposition");
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(
            /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          );
          if (fileNameMatch && fileNameMatch[1]) {
            fileName = fileNameMatch[1].replace(/['"]/g, "");
          }
        }

        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName; // The file name is now automatically extracted from the headers
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch((error) => console.error("Error:", error));
  };

  return (
    <Grid item xs={12} md={4} lg={3}>
      <View style={Styles.container} activeOpacity={0.8}>
        <View
          style={{ justifyContent: "center", alignItems: "center", margin: 10 }}
        >
          <GroupIcon color={"rgba(170, 73, 66, 1)"} width={100} height={100} />
        </View>
        <Text style={Styles.textTitle}>{data ? data.detail : ""}</Text>
        <View style={Styles.textContainer}>
          <CalendarRewardIcon />
          <Text style={Styles.textSub}>
            {moment(data.create_date).format("DD/MM/YYYY")}
          </Text>
        </View>
        <TouchableOpacity
          style={Styles.button}
          onPress={() => {
            downloadFile(data.file_download, data.detail);
          }}
        >
          <Text style={Styles.textButton}>ดาวน์โหลด</Text>
        </TouchableOpacity>
      </View>
    </Grid>
  );
};

const Styles = {
  container: {
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
    borderRadius: 8,
    overflow: "hidden",
    padding: 15,
  },
  contentHeader: {
    flexDirection: "row",
  },
  img: {
    width: "100%",
    height: 160,
    objectFit: "cover",
  },
  textTitle: {
    fontWeight: "bold",
    marginTop: 15,
  },
  textContainer: {
    flexDirection: "row",
    marginTop: 10,
  },
  textSub: {
    marginLeft: 10,
  },
  button: {
    backgroundColor: "rgba(180, 241, 179, 1)",
    borderRadius: 8,
    padding: 2,
    width: "100%",
    height: 40,
    marginTop: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  textButton: {
    fontWeight: "bold",
    color: "rgba(28, 155, 26, 1)",
  },
  iconSelect: {
    fontSize: 22,
    color: "rgba(170, 73, 66, 1)",
  },
};

export default ContentListGrid;
