import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import { Grid } from "@mui/material";
//images
import newsMock1 from "../../asset/images/home/news/newsmock1.png";
import { CalendarIcon, OptionIcon } from "../../asset/images/icons/iconsLib";
import apiPath from "../../services/configs/apiPath";
import { get } from "../../functions/fetch/fetch";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import LoadingTab from "../../components/modal/LoadingTab";
import EmptyList from "../../components/pages/EmptyList";

const NewsHome = (props) => {
  const [newsList, setNewsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    downloadNews();
  }, []);

  const downloadNews = async () => {
    setIsLoading(true);
    let url = apiPath.news.getAllByMember;
    let { status, response } = await get(url, {}, true);
    if (!status) {
      setNewsList([]);
    } else {
      setNewsList(response.data);
    }
    setIsLoading(false);
  };

  return (
    <Grid item xs={12} md={12} lg={4}>
      <View style={Styles.container}>
        <Text style={Styles.textTitle}>ประกาศ</Text>
        <View style={{ height: 20 }} />
        {!isLoading && newsList.length > 0 ? (
          newsList.map((item) => {
            return (
              <NewsBox
                data={item}
                onClick={() => {
                  console.log(item);
                  navigate("/news/" + item.ID);
                }}
              />
            );
          })
        ) : isLoading ? (
          <LoadingTab />
        ) : (
          <EmptyList />
        )}
      </View>
    </Grid>
  );
};
const NewsBox = (props) => {
  const { data } = props;
  const { onClick } = props;
  return (
    <View
      style={{
        flex: 1,
        maxHeight: 175,
        flexDirection: "row",
        marginBottom: 20,
      }}
    >
      <TouchableOpacity
        onPress={() => {
          onClick && onClick();
        }}
      >
        <img
          src={data ? data.Image : ""}
          alt={"ads news"}
          style={Styles.newsImg}
        />
      </TouchableOpacity>
      <View style={{ width: 20 }} />
      <View style={Styles.newsContentBox}>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <TouchableOpacity
            onPress={() => {
              onClick && onClick();
            }}
          >
            <Text style={Styles.newsTextTitel}>{data ? data.Title : ""}</Text>
          </TouchableOpacity>
          {/* <TouchableOpacity>
            <OptionIcon width={15} height={15} color={"rgba(170, 73, 66, 1)"} />
          </TouchableOpacity> */}
        </View>
        <View style={{ height: 5 }} />
        <View
          style={{
            width: 56,
            backgroundColor: "rgba(153, 255, 190, 1)",
            padding: 4,
            paddingLeft: 8,
            paddingRight: 8,
            flex: "none",
            borderRadius: 8,
          }}
        >
          <Text
            style={{
              fontSize: "clamp(8px, 2vw, 12px)",
              color: "rgba(0, 153, 112, 1)",
            }}
          >
            เผยแพร่
          </Text>
        </View>
        <View style={{ height: 10 }} />
        <View style={{ flexDirection: "row" }}>
          <CalendarIcon
            width={18}
            height={20}
            color={"rgba(105, 109, 121, 1)"}
          />
          <View style={{ width: 10 }} />
          <Text
            style={{
              fontSize: "clamp(10px, 2vw, 14px)",
              color: "rgba(15, 16, 17, 1)",
            }}
          >
            {data ? moment(data.CreateDate).format("DD/MM/YYYY") : ""}
          </Text>
        </View>
        <View style={{ height: 10 }} />
        <Text
          style={{
            fontSize: "clamp(8px, 2vw, 12px)",
            color: "rgba(15, 16, 17, 1)",
          }}
          numberOfLines={2}
          ellipsizeMode="tail"
        >
          {data ? data.Description : ""}
        </Text>
      </View>
    </View>
  );
};

const Styles = {
  container: {
    width: "100%",
    height: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
  },
  textTitle: {
    fontSize: "clamp(18px, 2vw, 22px)",
    fontWeight: "bold",
    color: "rgba(15, 16, 17, 1)",
  },
  newsTextTitel: {
    fontSize: "clamp(12px, 2vw, 16px)",
    fontWeight: "bold",
    flex: 1,
  },
  newsContentBox: {
    flex: 1,
    borderRadius: 16,
    padding: 16,
    backgroundColor: "rgba(178, 178, 178, 0.1)",
  },
  newsImg: {
    width: "clamp(150px, 2vw, 175px)",
    height: "clamp(150px, 2vw, 175px)",
    borderRadius: 8,
  },
};

export default NewsHome;
