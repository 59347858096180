import React, { useState, useRef, useEffect } from "react";
import { Text, View } from "react-native-web";
import LinearGradient from "react-native-web-linear-gradient";
import DropdownShadow from "../../components/dropdown/DropdownShadow";
//Images
import winsale1 from "../../asset/images/home/salesContainer/winsale1.png";
import apiPath from "../../services/configs/apiPath";
import { get } from "../../functions/fetch/fetch";
import { useDialog } from "../../components/modal/Popup";

const SalesContainer5 = (props) => {
  //property
  const dialog = useDialog();

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const refBox = useRef();
  //state
  const [rankingInfo, setRankingInfo] = useState({});

  useEffect(() => {
    downloadRankingInfo();
  }, []);

  useEffect(() => {
    if (refBox.current) {
      setWidth(refBox.current.offsetWidth / 1.2);
      setHeight(refBox.current.offsetHeight);
    }
  }, [refBox.current]);

  const downloadRankingInfo = async () => {
    let url = apiPath.member.getUserTopRanking;
    let { status, response } = await get(url, {}, true);
    if (!status) {
      dialog.alert({ message: response.data.error });
      return;
    }
    if (response.data) {
      setRankingInfo(response.data);
    }
  };

  return (
    <View
      ref={refBox}
      style={Styles.linearContainer1}
      onLayout={(e) => {
        if (refBox.current) {
          setWidth(refBox.current.offsetWidth / 1.2);
        }
      }}
    >
      <View style={{ width: "100%", zIndex: 30 }}>
        <DropdownShadow
          placeholder={"ตำแหน่งที่พิชิตได้สูงสุด"}
          disable={true}
          list={[]}
          onChange={(values) => {
            const { value, label } = values;
            console.log(value, label);
          }}
        />
      </View>
      <CircleFade width={width} />

      <View style={{ flex: 1 }} />
      {/* <View style={{ width: "100%", zIndex: 30 }}>
        <DropdownShadow
          placeholder={"Millionaire"}
          list={[]}
          onChange={(values) => {
            const { value, label } = values;
            console.log(value, label);
          }}
        />
      </View> */}
      <View
        style={{
          width: "100%",
          backgroundColor: "rgba(232, 164, 94, 1)",
          borderRadius: 8,
          justifyContent: "center",
          alignItems: "center",
          padding: 8,
          marginTop: 20,
        }}
      >
        <Text
          style={{
            color: "#FFFFFF",
            fontSize: "clamp(20px,3vh,24px)",
            fontWeight: "bold",
          }}
        >
          {rankingInfo.name ? rankingInfo.name : "N/A"}
        </Text>
      </View>
    </View>
  );
};

const CircleFade = (props) => {
  const { width } = props;
  return (
    <View
      style={{
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 80,
      }}
    >
      <LinearGradient
        style={{
          width: width,
          height: width,
          borderRadius: "50%",
          position: "absolute",
          left: (width / 3.5) * -1,
        }}
        colors={[
          "rgba(236, 206, 162, 1)",
          "rgba(229, 189, 129, 0)",
          "rgba(229, 189, 129, 0)",
        ]}
        useAngle="true"
        angle={270}
      />
      <LinearGradient
        style={{
          width: width,
          height: width,
          borderRadius: "50%",
          position: "absolute",
          right: (width / 2.6) * -1,
          marginBottom: 50,
        }}
        colors={[
          "rgba(236, 206, 162, 1)",
          "rgba(229, 189, 129, 0)",
          "rgba(229, 189, 129, 0)",
        ]}
        useAngle="true"
        angle={110}
      />
      <img
        src={winsale1}
        alt={"profile"}
        style={{ width: width / 1.4, maxWidth: 200, zIndex: 20 }}
      />
    </View>
  );
};

const Styles = {
  container: {
    margin: 0,
  },
  linearContainer1: {
    width: "100%",
    height: "100%",
    boxShadow: "16px 16px 24px 0px rgba(0, 0, 0, 0.04)",
    background: "linear-gradient(173.69deg, #FF9B70 -1.06%, #FFCE70 100.91%)",
    borderRadius: 24,
    alignItems: "center",
    padding: 15,
    paddingBottom: 30,
    overflow: "hidden",
  },
  circle: {
    width: "clamp(90px,3vh,120px)",
    height: "clamp(90px,3vh,120px)",
    borderRadius: "50%",
    position: "absolute",
    opacity: 0.8,
  },
};
export default SalesContainer5;
