import React from "react";
import { Text } from "react-native-web";
const HandlePage = (props) => {
  return (
    <div>
      <Text style={{ fontSize: 16 }}>ไม่พบหน้าที่คุณต้องการ</Text>
    </div>
  );
};

export default HandlePage;
