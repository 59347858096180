import React from "react";
import Quill from "quill";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./textEditor.css";
import ImageResize from "quill-image-resize";
Quill.register("modules/imageResize", ImageResize);

const SizeStyle = Quill.import("attributors/style/size");
SizeStyle.whitelist = [
  "8px",
  "9px",
  "10px",
  "11px",
  "12px",
  "14px",
  "18px",
  "24px",
  "30px",
  "36px",
  "48px",
];
Quill.register(SizeStyle, true);

//    [{ header: "1" }, { header: "2" }, { font: [] }],
/*
    [{ font: [] }],
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    */

const colors = [
  "#000000",
  "#993300",
  "#333300",
  "#003300",
  "#003366",
  "#000080",
  "#333399",
  "#333333",
  "#800000",
  "#FF6600",
  "#808000",
  "#008000",
  "#008080",
  "#0000FF",
  "#666699",
  "#808080",
  "#FF0000",
  "#FF9900",
  "#99CC00",
  "#339966",
  "#33CCCC",
  "#3366FF",
  "#800080",
  "#999999",
  "#FF00FF",
  "#FFCC00",
  "#FFFF00",
  "#00FF00",
  "#00FFFF",
  "#00CCFF",
  "#993366",
  "#C0C0C0",
  "#FF99CC",
  "#FFCC99",
  "#FFFF99",
  "#CCFFCC",
  "#CCFFFF",
  "#99CCFF",
  "#CC99FF",
  "#FFFFFF",
  // ... add more colors until you have 64 in total.
];

const modules = {
  toolbar: [
    [
      {
        size: [
          "8px",
          "9px",
          "10px",
          "11px",
          "12px",
          "14px",
          "18px",
          "24px",
          "30px",
          "36px",
          "48px",
        ],
      },
    ],
    [{ align: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    ["clean"],
    [{ color: colors }, { background: colors }],
  ],
  imageResize: {}, // บรรทัดนี้เป็นการเพิ่ม module สำหรับการปรับขนาดรูปภาพ
};

const TextEditor = (props) => {
  const { html, onChange } = props;

  const handleChange = (html) => {
    onChange && onChange(html);
  };

  return (
    <div>
      <ReactQuill value={html} onChange={handleChange} modules={modules} />
    </div>
  );
};

export default TextEditor;
