import React from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import { Grid, Typography } from "@mui/material";
import moment from "moment-timezone";
//component
import Button from "../../../components/button/Button";
//images
import { CalendarRewardIcon } from "../../../asset/images/icons/iconsLib";
import productImg from "../../../asset/images/example/product.png";
import { BiTime } from "react-icons/bi";
import { useSelector } from "react-redux";

const EventBox = (props) => {
  const { user } = useSelector((state) => {
    return state;
  });
  const { data } = props;
  const { onView, onEdit } = props;
  return (
    <Grid item xs={12} md={4} xl={3}>
      <View style={Styles.container}>
        <View style={Styles.content}>
          <View style={{ flex: 1 }}>
            <TouchableOpacity
              onPress={() => {
                onView && onView(data);
              }}
            >
              <img
                style={Styles.img}
                alt={"image"}
                src={data.event_image ? data.event_image : ""}
              />
            </TouchableOpacity>
          </View>
        </View>

        <View style={{ width: 10 }} />
        <View style={Styles.bottomContent}>
          <TouchableOpacity
            onPress={() => {
              onView && onView(data);
            }}
          >
            <Text style={Styles.textTitle}>{data ? data.event_name : ""}</Text>
          </TouchableOpacity>
          <View style={{ height: 10 }} />
          <StatusTag
            title={`${Number(data.event_price).toLocaleString()} ${
              data.event_type_id === 1 ? " บาท" : " Points"
            }`}
            color={"rgba(194, 215, 255, 1)"}
            colorText={"rgba(78, 132, 255, 1)"}
          />
          <View style={{ height: 10 }} />
          <View style={Styles.calendarContainer}>
            <CalendarRewardIcon />
            <View style={{ width: 10 }} />
            <Text style={Styles.textCalendar}>
              วันที่จัดกิจกรรม{" "}
              {`${moment(data.event_start_time_date).format(
                "DD/MM/YYYY"
              )} - ${moment(data.event_end_time_date).format("DD/MM/YYYY")}`}
            </Text>
          </View>
          <View style={Styles.calendarContainer}>
            <BiTime style={{ color: "#8A91A1", fontSize: 24 }} />
            <View style={{ width: 10 }} />
            <Text style={Styles.textCalendar}>
              วันที่ลงทะเบียน{" "}
              {`${moment(data.event_start_register_date).format(
                "DD/MM/YYYY"
              )} - ${moment(data.event_close_register_date).format(
                "DD/MM/YYYY"
              )}`}
            </Text>
          </View>
          <View style={{ height: 10 }} />
          <Text numberOfLines={2} style={{ overflow: "hidden" }}>
            {data.event_description}
          </Text>
          <View style={{ height: 20 }} />

          <Button
            buttonText={
              user.userRole === "S" || user.userRole === "SS"
                ? "รายละเอียดอีเวนท์"
                : data.status_type === "CAN_JOIN_EVENT"
                ? "รายละเอียดอีเวนท์"
                : data.status_type === "ACTIVE"
                ? "เข้าร่วมแล้ว"
                : data.status_type === "INACTIVE"
                ? "ไม่สามารถเข้าร่วมได้"
                : "รายละเอียดอีเวนท์"
            }
            disable={
              user.userRole === "S" || user.userRole === "SS"
                ? false
                : data.status_type !== "CAN_JOIN_EVENT"
            }
            color={"primary"}
            style={{ width: "100%" }}
            isBorder={false}
            onClick={() => {
              onView && onView(data);
            }}
          />
        </View>
      </View>
    </Grid>
  );
};

const StatusTag = (props) => {
  const {
    title = "",
    color = "rgba(255, 228, 187, 1)",
    colorText = "rgba(241, 152, 19, 1)",
  } = props;
  return (
    <View
      style={{
        width: 100,
        height: 20,
        backgroundColor: color,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 8,
      }}
    >
      <Text
        style={{
          fontSize: 12,
          fontWeight: "bold",
          color: colorText,
        }}
      >
        {title}
      </Text>
    </View>
  );
};

const Styles = {
  container: {
    padding: 15,
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
    borderRadius: 8,
    minHeight: 520,
    maxHeight: 540,
    overflow: "hidden",
  },
  content: {
    flexDirection: "row",
  },
  img: {
    width: "100%",
    height: 200,
    objectFit: "cover",
    borderRadius: 8,
  },
  bottomContent: {
    flex: 1,
    marginTop: 20,
  },
  textTitle: {
    fontSize: 16,
    color: "rgba(15, 16, 17, 1)",
    fontWeight: "bold",
  },
  optionIcon: {
    fontSize: 15,
    color: "rgba(105, 109, 121, 1)",
    position: "absolute",
    top: 5,
    right: 0,
  },
  calendarContainer: {
    flexDirection: "row",
    marginTop: 10,
    alignItems: "center",
  },
  textCalendar: {
    fontSize: 12,
  },
  contentText: {
    fontSize: 10,
  },
};
export default EventBox;
