import React, { useState, useEffect } from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import { Grid } from "@mui/material";
import { DateRangePicker, Divider } from "rsuite";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import * as XLSX from "xlsx";
//component
import Button from "../../components/button/Button";
import Dropdown from "../../components/dropdown/Dropdown";
import DatePicker from "../../components/calendar/DatePicker";
import ReportShoppingTable from "./component/ReportShoppingTable";
import { getAllRetailReport } from "../../services/api/report/order";
import { useDialog } from "../../components/modal/Popup";
import apiPath from "../../services/configs/apiPath";
import { post } from "../../functions/fetch/fetch";
import DatePickerRange from "../../components/calendar/DatePickerRange";

const ReportShopping = () => {
  //property
  const navigate = useNavigate();
  const dialog = useDialog();
  //state
  const [isLoading, setIsLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [search, setSearch] = useState({});

  useEffect(() => {
    downloadAllStock();
  }, []);

  const downloadAllStock = async (props) => {
    dialog.loading();
    let res = await getAllRetailReport();
    if (!res) {
      return;
    }
    setOrders(res);
    dialog.cancle();
    setIsLoading(false);
  };

  const downloadExcel = async () => {
    let newOrders = [];
    orders.forEach((item) => {
      newOrders.push({
        "วันที่/เวลา": moment(item.date).format("DD/MM/YYYY HH:mm"),
        เลขที่อ้างอิง: item.orderCode,
        ผู้ขาย: item.saleName,
        ผู้ซื้อ: item.buyerName,
        ยอดรวมทั้งหมด: item.totalPrice,
        สถานะการขาย: item.status === "Y" ? "สำเร็จ" : "ไม่สำเร็จ",
      });
    });

    const worksheet = XLSX.utils.json_to_sheet(newOrders);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(
      workbook,
      `รายงานขายปลีก_${moment().format("DD_MM_YYYY_HHmmss")}.xlsx`
    );
  };

  const downloadExcelReport1 = async () => {
    if (!search.startDate || !search.endDate) {
      dialog.alert({ message: "กรุณาเลือกวันที่เริ่มต้นและวันที่สิ้นสุด" });
      return;
    }

    let url = apiPath.report.getRetailReportByProduct;
    let { status, response } = await post(
      url,
      {
        startDate: moment
          .utc(search.startDate)
          .set({ h: 0, m: 0, s: 0 })
          .format("YYYY-MM-DD HH:mm:ss"),
        endDate: moment
          .utc(search.endDate)
          .set({ h: 23, m: 59, s: 59 })
          .format("YYYY-MM-DD HH:mm:ss"),
      },
      true
    );
    if (!status) {
      dialog.alert({ message: response.data.error });
      return;
    }
    if (response.data) {
      console.log(response.data);
      // ตรวจสอบว่า response.data เป็นอาร์เรย์
      if (!Array.isArray(response.data)) {
        dialog.alert({ message: "ข้อมูลที่ได้รับไม่ถูกต้อง" });
        return;
      }
      const orderedKeys = [
        "date",
        "paymentMethodCode",
        "retailCode",
        "paymentRefNo",
        "gbpRefNo",
        "saleName",
        "saleCode",
        "customerName",
        "totalPrice",
        "totalPriceWithOutTransport",
      ];

      if (response.data && Array.isArray(response.data)) {
        const sortedData = response.data.map((item) => {
          const sortedItem = {};

          // เพิ่มคีย์ตามลำดับที่กำหนด
          orderedKeys.forEach((key) => {
            sortedItem[key] = item[key];
          });

          // เพิ่มคีย์ที่เหลือตามลำดับตัวอักษร
          Object.keys(item)
            .filter((key) => !orderedKeys.includes(key))
            .sort()
            .forEach((key) => {
              sortedItem[key] = item[key];
            });

          return sortedItem;
        });

        // ตอนนี้ sortedData มีข้อมูลที่ถูกเรียงลำดับแล้ว
        const worksheet = XLSX.utils.json_to_sheet(sortedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(
          workbook,
          `รายงานขายปลีก_${moment().format("DD_MM_YYYY_HHmmss")}.xlsx`
        );
      } else {
        dialog.alert({ message: "ข้อมูลไม่ถูกต้อง" });
      }
    }
  };
  
  return (
    <React.Fragment>
      <View style={Styles.container}>
        <Grid container style={Styles.topContainer} spacing={2}>
          <Grid item xs={12} md={8} lg={9} xl={10}>
            <View style={{ flex: 1 }}>
              <Text style={Styles.textTopContainer1}>รายงานขายปลีก</Text>
              <Text style={Styles.textTopContainer2}>
                รายงาน / รายงานขายปลีก
              </Text>
            </View>
          </Grid>
          <Grid item xs={12} md={4} lg={3} xl={2}>
            <Button
              buttonText={"ดาวน์โหลดไฟล์ Excel"}
              color={"primary"}
              style={{ width: "100%" }}
              styleText={Styles.topButtonText}
              isBorder={true}
              onClick={() => {
                // downloadExcel();
                downloadExcelReport1();
              }}
            />
          </Grid>
        </Grid>
        <View style={{ height: 30 }} />
        <View style={Styles.contentContainer}>
          <Grid container spacing={2} style={{ marginBottom: 15 }}>
            <Grid item xs={12} lg={4} style={{ zIndex: 10 }}>
              <View style={{ height: 8 }} />
              <Dropdown
                placeholder={"ผู้ขาย"}
                list={[]}
                onChange={(values) => {
                  const { value, label } = values;
                  console.log(value, label);
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4} style={{ zIndex: 9 }}>
              <View style={{ height: 8 }} />
              <Dropdown
                placeholder={"ลูกค้า"}
                list={[]}
                onChange={(values) => {
                  const { value, label } = values;
                  console.log(value, label);
                }}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <View style={{ height: 8 }} />
              <Button
                buttonText={"ค้นหา"}
                color={"primary"}
                style={{ width: "100%" }}
                styleText={Styles.topButtonText}
                onClick={() => {}}
              />
            </Grid>
            <Grid item xs={12} lg={4} style={{ zIndex: 7 }}>
              <View style={{ height: 8 }} />
              {/* <DatePicker placeholder="วันที่" styles={{ width: "100%" }} /> */}
              <DatePickerRange
                startDate={search.startDate}
                endDate={search.endDate}
                placeholder={"เลือกวันที่เริ่มต้น - วันที่สิ้นสุด"}
                isTop={false}
                styles={{ width: "100%" }}
                onChange={(values) => {
                  const { startDate, endDate } = values;
                  if (startDate && endDate) {
                    setSearch({
                      ...search,
                      startDate: startDate.utc(),
                      endDate: endDate.utc(),
                    });
                  }
                }}
              />{" "}
            </Grid>
          </Grid>
          <Divider />
          {false && (
            <Text style={Styles.titleSearch}>
              รายงานคำสั่งซื้อ วันที่ 10/9/2022 - 20/9/2022
            </Text>
          )}
          <Text style={Styles.subTitleSearch}>{`ผลการค้นหา ${
            orders.length > 0 ? Number(orders.length) : 0
          } รายการ`}</Text>{" "}
          <View style={{ flex: 1 }}>
            <ReportShoppingTable tableData={orders} isLoading={isLoading} />
          </View>
        </View>
      </View>
    </React.Fragment>
  );
};

const Styles = {
  container: {
    flex: 1,
    height: "100vh",
    padding: "1.5%",
  },
  topContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  contentContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  textTopContainer1: {
    fontSize: "clamp(18px, 3vw, 22px)",
    fontWeight: "bold",
    color: "#AA4942",
  },
  textTopContainer2: {
    fontSize: "clamp(12px, 3vw, 16px)",
    color: "#696D79",
  },
  topButtonText: {
    fontSize: "clamp(14px, 3vw, 18px)",
  },
  titleSearch: {
    fontSize: 20,
    color: "#0F1011",
  },
  subTitleSearch: {
    fontSize: 16,
    color: "#0F1011",
    marginTop: 8,
  },
};
export default ReportShopping;
