import React, { useState, useRef, useEffect } from "react";
import { View, Text, ScrollView, TouchableOpacity } from "react-native-web";
import simpleProduct from "../../asset/images/example/product.png";

const ImageGallery = (props) => {
  const { images = [] } = props;
  const { maxWidth = 400, maxHeight = 400 } = props;
  const { thumbnailWidth = 100, thumbnailHeight = 100 } = props;
  const [selectIndex, setSelectIndex] = useState(0);

  const scrollViewRef = useRef(null);

  useEffect(() => {}, [images]);

  const scrollToItem = (index) => {
    if (scrollViewRef.current) {
      console.log(thumbnailWidth);
      scrollViewRef.current.scrollTo({
        x: index * (thumbnailWidth + 10), // Replace ITEM_WIDTH with your item width
        y: 0,
        animated: true,
      });
    }
  };

  return (
    <View style={Styles.container}>
      <View style={Styles.previewImageContainer}>
        <img
          src={images[selectIndex]}
          style={{
            ...Styles.previewImage,
            width: "100%",
            maxHeight: maxHeight,
            objectFit: "cover",
          }}
        />
      </View>
      <View style={{ height: 20 }} />
      <View style={Styles.thumbnailImageContainer}>
        <ScrollView
          ref={scrollViewRef}
          horizontal={true}
          showsHorizontalScrollIndicator={false}
        >
          <View style={{ ...Styles.thumbnailImageContainer }}>
            {images.map((item, index) => {
              return (
                <ThumbnailImage
                  thumbnailWidth={thumbnailWidth}
                  thumbnailHeight={thumbnailHeight}
                  key={"image_" + index}
                  index={index}
                  image={item}
                  selectIndex={selectIndex}
                  onSelect={(i) => {
                    setSelectIndex(i);
                    scrollToItem(i);
                  }}
                />
              );
            })}
          </View>
        </ScrollView>
      </View>
    </View>
  );
};

const ThumbnailImage = (props) => {
  const { index, image, selectIndex } = props;
  const { thumbnailWidth, thumbnailHeight } = props;
  const isSelect = selectIndex === index;
  const { onSelect } = props;
  return (
    <TouchableOpacity
      style={{
        ...Styles.thumbnailImageBox,
        borderWidth: isSelect ? 2 : 0,
        borderColor: "rgba(165, 88, 90, 1)",
        width: thumbnailWidth,
        height: thumbnailHeight,
      }}
      activeOpacity={0.9}
      onPress={() => {
        onSelect && onSelect(index);
      }}
    >
      <img
        src={image ? image : ""}
        alt={"imagepreview"}
        style={{ width: "100%", height: "100%", objectFit: "cover" }}
      />
    </TouchableOpacity>
  );
};
const Styles = {
  container: {
    width: "100%",
    height: "100%",
  },
  previewImageContainer: {
    flex: 1,
    borderRadius: 20,
  },
  previewImage: {
    width: 250,
    height: 250,
    borderRadius: 20,
    objectFit: "cover",
  },
  thumbnailImageContainer: {
    flexDirection: "row",
  },
  thumbnailImageBox: {
    marginRight: 10,
    width: 100,
    height: 100,
    borderRadius: 10,
    overflow: "hidden",
    borderWidth: 2,
  },
};
export default ImageGallery;
