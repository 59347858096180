import React from "react";
import { Grid } from "@mui/material";
import { Text, View } from "react-native-web";
//Component
import Button from "../../components/button/Button";
//functions

const HomeHeaderContainer = (props) => {
  const { userInfo } = props;
  const generateRegisterLink = () => {
    if (userInfo.agentCode) {
      //const hostname = window.location.hostname;
      window.open(`/register/${userInfo.agentCode}`);
    }
  };
  return (
    <Grid container style={Styles.topContainer} spacing={2}>
      <Grid item xs={12} md={4} lg={6} xl={8}>
        <View style={{ flex: 1 }}>
          <Text style={Styles.textTopContainer1}>หน้าหลัก</Text>
          <Text style={Styles.textTopContainer2}>หน้าหลัก/ข้อมูลของคุณ</Text>
        </View>
      </Grid>
      <Grid item xs={12} md={2} lg={2} xl={1}></Grid>
      <Grid item xs={12} md={6} lg={4} xl={3}>
        <Button
          buttonText={"ลิงค์รับสมัคร BFF ของคุณ"}
          color={"primary"}
          style={{ width: "100%" }}
          styleText={Styles.topButtonText}
          onClick={() => {
            generateRegisterLink();
          }}
        />
      </Grid>
      {/* <Grid item xs={12} md={4} lg={3} xl={2}>
        <Button
          buttonText={"เว็บไซต์ส่วนตัว"}
          color={"primary"}
          isBorder={true}
          style={{ width: "100%" }}
          styleText={Styles.topButtonText}
          onClick={() => {}}
        />
      </Grid> */}
    </Grid>
  );
};

const Styles = {
  topContainer: {
    width: "100%",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
  },
  textTopContainer1: {
    fontSize: "clamp(18px, 3vw, 22px)",
    fontWeight: "bold",
    color: "#AA4942",
  },
  textTopContainer2: {
    fontSize: "clamp(12px, 3vw, 16px)",
    color: "#696D79",
  },
  topButtonText: {
    fontSize: "clamp(14px, 3vw, 18px)",
  },
};
export default HomeHeaderContainer;
