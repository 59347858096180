import React from "react";
import { View, Text, Touchable, TouchableOpacity } from "react-native-web";
import { Divider } from "rsuite";
import { Grid } from "@mui/material";
import StatusTag from "../../../components/tag/StatusTag";
import SearchBox from "../../../components/textField/SearchBox";
import Button from "../../../components/button/Button";
import DropdownPopover from "../../../components/dropdown/DropdownPopover";
//images
import productImg from "../../../asset/images/example/product.png";
import { CalendarRewardIcon } from "../../../asset/images/icons/iconsLib";
import { SlOptionsVertical } from "react-icons/sl";
import moment from "moment-timezone";

const ArticleContainer = (props) => {
  const { data = [] } = props;
  const { onOption } = props;
  return (
    <View>
      <Grid container spacing={2} style={{ marginBottom: 15 }}>
        <Grid item xs={12} lg={4} style={{ zIndex: 10 }}>
          <View style={{ height: 8 }} />
          <SearchBox placeholder="ค้นหา" />
        </Grid>
        <Grid item xs={12} lg={2}>
          <View style={{ height: 8 }} />
          <Button
            buttonText={"ค้นหา"}
            color={"primary"}
            style={{ width: "100%" }}
            onClick={() => {}}
          />
        </Grid>
      </Grid>
      <View style={{ height: 20 }} />
      <Grid container spacing={2}>
        {data.map((item) => {
          return (
            <ArticleBox
              data={item}
              onOption={(values) => {
                onOption && onOption(values);
              }}
            />
          );
        })}
      </Grid>
    </View>
  );
};

const ArticleBox = (props) => {
  const { data } = props;
  const { onOption } = props;
  const StylesCourse = {
    container: {
      padding: 15,
      boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
      borderRadius: 8,
      overflow: "hidden",
      height: 380,
    },
    content: {},
    img: {
      width: "100%",
      height: 190,
      objectFit: "cover",
      borderRadius: 8,
    },
    textTitle: {
      fontSize: 16,
      color: "rgba(15, 16, 17, 1)",
      fontWeight: "bold",
      marginRight: 5,
    },
    calendarContainer: {
      flexDirection: "row",
      marginTop: 10,
    },
    contentText: {
      fontSize: 14,
      color: "rgba(105, 109, 121, 1)",
      marginTop: 10,
    },
    optionIcon: {
      fontSize: 15,
      color: "rgba(105, 109, 121, 1)",
      position: "absolute",
      top: 5,
      right: -5,
    },
  };

  const customIconOption = () => {
    return (
      <View
        style={{
          width: 25,
          height: 25,
          backgroundColor: "rgba(15, 16, 17, 0.5)",
          borderRadius: "50%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <SlOptionsVertical
          style={{
            fontSize: 15,
            color: "#FFFFFF",
          }}
        />
      </View>
    );
  };

  return (
    <Grid item xs={12} md={4} xl={3}>
      <View style={StylesCourse.container}>
        <View style={StylesCourse.content}>
          <View style={{ position: "absolute", top: 5, right: 0 }}>
            <DropdownPopover
              list={[
                { label: "แก้ไข", value: "edit" },
                { label: "ลบ", value: "delete" },
              ]}
              customIcon={customIconOption()}
              onSelect={(value) => {
                onOption &&
                  onOption({
                    option: value,
                    data: data,
                  });
              }}
            />
          </View>
          <img
            style={StylesCourse.img}
            alt={"image"}
            src={data ? data.Image : ""}
          />
          <View style={{ height: 10 }} />
          <Text style={StylesCourse.textTitle}>{data ? data.Title : ""}</Text>
          <View style={{ height: 10 }} />

          <StatusTag
            title={"เผยแพร่"}
            color={"rgba(180, 241, 179, 1)"}
            colorText={"rgba(28, 155, 26, 1)"}
          />
          <View style={StylesCourse.calendarContainer}>
            <CalendarRewardIcon />
            <View style={{ width: 5 }} />
            <Text>
              {data ? moment(data.CreateDate).format("DD/MM/YYYY") : ""}
            </Text>
          </View>
          <Text style={StylesCourse.contentText}>
            {data ? data.Description : ""}
          </Text>
        </View>
      </View>
    </Grid>
  );
};

const Styles = {
  titleTextHead: {
    fontSize: "clamp(16px,2vh,20px)",
    fontWeight: "bold",
    color: "rgba(15, 16, 17, 1)",
  },
};

export default ArticleContainer;
