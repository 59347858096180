import React, { useEffect, useMemo, useRef, useState } from "react";
import { Modal, View, Text, StyleSheet } from "react-native-web";
import { Grid } from "@mui/material";
import TextField from "../../../components/textField/TextField";
import Button from "../../../components/button/Button";
//functions
import { useDialog } from "../../../components/modal/Popup";
import apiPath from "../../../services/configs/apiPath";
import { post, get } from "../../../functions/fetch/fetch";
import {
  formatCreditCardNumber,
  formatNumberString,
  getCardBrand,
} from "../../../functions/format/numberFormat";
//images
import visaIcon from "../../../asset/images/shopping/visa.png";
import masterCardIcon from "../../../asset/images/shopping/mastercard.png";
import jcbIcon from "../../../asset/images/shopping/jcb.png";
import unionpayIcon from "../../../asset/images/shopping/unionpay.png";
import discoverIcon from "../../../asset/images/shopping/discover.png";

const PopupPaymentCard = (props) => {
  //property
  const { visible, data } = props;
  const { onClose } = props;
  const dialog = useDialog();
  //state
  const [cardData, setCardData] = useState({
    cardNumber: null,
    cardExpire: null,
    cardCcv: null,
    cardHolderName: null,
  });

  const submit = async () => {
    let hostname = window.location.hostname;
    let domain =
      hostname === "localhost"
        ? `http://localhost:3000/`
        : `https://${hostname}/`;
    let responseURL = data.customerPaymentRefNo
      ? `${domain}salespayment/${data.customerPaymentRefNo}`
      : `${domain}${
          data.transactionType === "RETAIL" ? "shoppingMyList" : "orderMyList"
        }?payment=true`;

    const { cardNumber, cardExpire, cardCcv, cardHolderName } = cardData;
    if (!cardNumber || !cardExpire || !cardCcv || !cardHolderName) {
      dialog.alertParent({
        message:
          "กรุณากรอก หมายเลขบัตร วันหมดอายุ CCV และชื่อเจ้าของบัตร ให้ครบถ้วน",
      });
      return;
    }

    let url = data.isCustomerPayment
      ? apiPath.payment.createPaymentCardCustomer
      : apiPath.payment.createPaymentCard;
    let { status, response } = await post(
      url,
      { ...data, ...cardData, responseURL: responseURL },
      data.isCustomerPayment ? false : true
    );
    if (!status) {
      dialog.alertParent({
        message: response.data.error,
      });
      return;
    }
    const { refNo, html } = response.data;
    if (!refNo || !html) {
      dialog.alertParent({
        message: "Missing data",
      });
      return;
    }
    document.open();
    document.write(html);
    document.close();
  };

  return (
    <Modal transparent={true} visible={true}>
      <View style={Styles.modalContainer}>
        <View style={Styles.box}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Text style={Styles.text}>หมายเลขบัตร</Text>
              <View style={{ height: 8 }} />
              <TextField
                iconFix={
                  cardData.cardNumber ? getCardImage(cardData.cardNumber) : null
                }
                value={cardData.cardNumber ? cardData.cardNumber : ""}
                maxLength={19}
                onChangeText={(value) => {
                  const numOnly = formatNumberString(value);
                  var formattedNum = formatCreditCardNumber(numOnly);
                  setCardData({
                    ...cardData,
                    cardNumber: formattedNum,
                  });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Text style={Styles.text}>วันหมดอายุ (MM/YY)</Text>
              <View style={{ height: 8 }} />
              <TextField
                value={cardData.cardExpire ? cardData.cardExpire : ""}
                maxLength={5}
                onChangeText={(value) => {
                  let input = value.replace(/\D/g, "");
                  input = input.replace(/(\d{2})(\d)/, "$1/$2");
                  setCardData({
                    ...cardData,
                    cardExpire: input.substring(0, 5),
                  });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Text style={Styles.text}>CCV</Text>
              <View style={{ height: 8 }} />
              <TextField
                value={cardData.cardCcv ? cardData.cardCcv : ""}
                type={"password"}
                maxLength={4}
                onChangeText={(value) => {
                  let numOnly = formatNumberString(value);
                  setCardData({
                    ...cardData,
                    cardCcv: numOnly,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Text style={Styles.text}>ชื่อเจ้าของบัตร</Text>
              <View style={{ height: 8 }} />
              <TextField
                value={cardData.cardHolderName ? cardData.cardHolderName : ""}
                onChangeText={(value) => {
                  setCardData({
                    ...cardData,
                    cardHolderName: value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                style={{ width: "100%" }}
                color={"primary"}
                buttonText={"ชำระเงิน"}
                onClick={() => {
                  submit();
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                style={{ width: "100%" }}
                isBorder={true}
                color={"primary"}
                buttonText={"ยกเลิก"}
                onClick={() => {
                  onClose && onClose();
                }}
              />
            </Grid>
          </Grid>
        </View>
      </View>
    </Modal>
  );
};

function getCardImage(cardNumber) {
  let cardBrand = getCardBrand(cardNumber);
  switch (cardBrand) {
    case "Visa":
      return <img src={visaIcon} style={Styles.cardIcon} alt={"visa"} />;
    case "MasterCard":
      return (
        <img src={masterCardIcon} style={Styles.cardIcon} alt={"master card"} />
      );
    case "American Express":
      return;
    case "Discover":
      return (
        <img src={discoverIcon} style={Styles.cardIcon} alt={"discover"} />
      );
    case "JCB":
      return <img src={jcbIcon} style={Styles.cardIcon} alt={"jcb"} />;
    case "UnionPay":
      return (
        <img src={unionpayIcon} style={Styles.cardIcon} alt={"unionpay"} />
      );
    case "Unknown":
      return;
    default:
      return;
  }
}
const Styles = StyleSheet.create({
  modalContainer: {
    position: "fixed",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 9999,
    alignItems: "center",
    justifyContent: "center",
  },
  box: {
    padding: "clamp(20px, 2vw, 40px)",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFF",
    borderRadius: 16,
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.08,
    shadowRadius: 16,
    width: "90%",
    maxWidth: 432,
    marginLeft: "clamp(20px, 2vw, 40px)",
    marginRight: "clamp(20px, 2vw, 40px)",
  },
  textTitle: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 8,
  },
  text: {
    fontSize: 16,
    marginBottom: 8,
  },
  cardIcon: {
    width: 30,
  },
});

export default PopupPaymentCard;
