import React from "react";
import { TouchableOpacity } from "react-native-web";
import { EditIcon } from "../../asset/images/icons/iconsLib";

export const EditButton = (props) => {
  const { fontSize = 25, color = "#000000" } = props;
  const { onPress } = props;
  return (
    <TouchableOpacity
      onPress={() => {
        onPress && onPress();
      }}
    >
      <EditIcon width={fontSize} height={fontSize} color={color} />
    </TouchableOpacity>
  );
};
