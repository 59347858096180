import React, { useEffect, useMemo, useRef, useState } from "react";
import { Modal, View, StyleSheet } from "react-native-web";
import Button from "../../../components/button/Button";
import { ref, onValue, off } from "firebase/database";
import { database } from "../../../services/firebase";
//functions
import apiPath from "../../../services/configs/apiPath";
import { post, get } from "../../../functions/fetch/fetch";
import { useDialog } from "../../../components/modal/Popup";
import { base64ToBlob } from "../../../functions/format/convertFile";
import { getPaymentStatus } from "../../../services/api/payment/payment";
import Loading from "../../../components/modal/Loading";
import LoadingOverlay from "react-loading-overlay";

const PopupPaymentThaiQr = (props) => {
  //property
  const { data } = props;
  const { onSuccess, onFail, onClose, onTimeout } = props;
  const dialog = useDialog();
  //state
  const starCountRef = useRef(ref(database, "Listen/wait"));
  const timeOutRef = useRef(ref(database, "Timeout/wait"));
  const [qrcode, setQrcode] = useState();

  useEffect(() => {
    return () => {
      off(starCountRef.current);
      off(timeOutRef.current);
    };
  }, []);

  useEffect(() => {
    if (data) {
      const { paymentFor } = data;
      if (!paymentFor) {
        createThaiQr();
        return;
      }
      if (paymentFor === "redeemEvent") {
        createEventPayment();
      } else {
        createThaiQr();
      }
    }
  }, [data]);

  const createThaiQr = async () => {
    //dialog.loadParent();
    let url = data.isCustomerPayment
      ? apiPath.payment.createThaiQrCustomer
      : apiPath.payment.createThaiQr;
    let { status, response } = await post(
      url,
      data,
      data.isCustomerPayment ? false : true
    );
    if (!status) {
      let alert = await dialog.alert({
        title: "แจ้งเตือน",
        message: response.data.error,
      });
      if (alert) {
        return;
      }
      onClose && onClose();
      return;
    }
    const { refNo, base64 } = response.data;
    if (!refNo || !base64) {
      await dialog.alert({
        title: "แจ้งเตือน",
        message: "Missing data",
      });
      return;
    }
    //dialog.cancleParent();
    setQrcode(base64);
    listenPaymentSocket(refNo);
  };

  const createEventPayment = async () => {
    console.log("createEventPayment");
    let url = apiPath.event.redeemEvent;
    let { status, response } = await post(url, data, true);
    if (!status) {
      let alert = await dialog.alert({
        title: "แจ้งเตือน",
        message: response.data.error,
      });
      if (alert) {
        return;
      }
      onClose && onClose();
      return;
    }
    const { payment_id, qr_code } = response.data;
    console.log("response payment", response);
    if (!payment_id || !qr_code) {
      await dialog.alert({
        title: "แจ้งเตือน",
        message: "Missing data",
      });
      return;
    }
    //dialog.cancleParent();
    setQrcode(qr_code);
    listenPaymentSocket(payment_id);
    listenTimeout(payment_id);
  };

  const listenPaymentSocket = (refNo) => {
    const path = `Listen/${refNo}`;
    starCountRef.current = ref(database, path);

    const handleValueChange = (snapshot) => {
      if (snapshot.val()) {
        paymentCheckStatus(refNo);
      }
    };

    onValue(starCountRef.current, handleValueChange);

    // return a cleanup function
    return () => off(starCountRef, handleValueChange);
  };

  const listenTimeout = (refNo) => {
    const path = `Timeout/${refNo}`;
    timeOutRef.current = ref(database, path);

    const handleValueChange = (snapshot) => {
      if (snapshot.val()) {
        if (data.paymentFor === "redeemEvent") {
          onTimeout && onTimeout();
        }
      }
    };

    onValue(timeOutRef.current, handleValueChange);

    // return a cleanup function
    return () => off(timeOutRef, handleValueChange);
  };

  const paymentCheckStatus = async (refNo) => {
    if (!refNo) {
      onFail && onFail();
      return;
    }
    let status = "N";
    if (data.paymentFor === "redeemEvent") {
      let url = apiPath.event.getEventPaymentStatus;
      let { response } = await get(url, { payment_id: refNo }, true);
      if (response.data) {
        if (Number(response.data) === 1) {
          onSuccess && onSuccess();
        }
      }
      onFail && onFail();
    } else {
      let res = await getPaymentStatus(refNo);
      if (!res.paymentRef) {
        onFail && onFail();
        return;
      }
      status = res.status;
    }

    if (status === "Y") {
      onSuccess && onSuccess();
    } else {
      onFail && onFail();
    }
    off(starCountRef.current);
  };

  return (
    <React.Fragment>
      {!qrcode ? <Loading /> : null}
      {qrcode ? (
        <Modal transparent={true} visible={true}>
          <View style={Styles.modalContainer}>
            <View style={Styles.box}>
              <img
                src={URL.createObjectURL(base64ToBlob(qrcode))}
                alt={"qrcode_payment"}
                style={{ width: "100%" }}
              />

              <View style={{ height: 20 }} />
              <Button
                color={"primary"}
                isBorder={false}
                style={{ width: "100%" }}
                buttonText={"ยกเลิก"}
                onClick={() => {
                  onClose && onClose();
                }}
              />
            </View>
          </View>
        </Modal>
      ) : null}
    </React.Fragment>
  );
};

const Styles = StyleSheet.create({
  modalContainer: {
    position: "fixed",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 9999,
    alignItems: "center",
    justifyContent: "center",
  },
  box: {
    padding: "clamp(20px, 2vw, 40px)",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFF",
    borderRadius: 16,
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.08,
    shadowRadius: 16,
    marginLeft: "clamp(20px, 2vw, 40px)",
    marginRight: "clamp(20px, 2vw, 40px)",
    width: "90%",
    maxWidth: 400,
  },
  textTitle: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 8,
  },
});

export default PopupPaymentThaiQr;
