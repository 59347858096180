import React, { useRef, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Tree, TreeNode } from "react-organizational-chart";
import SingleMemberNode from "./SingleMemberNode";
import { ScrollView } from "react-native-web";
import { downloadAllTeam } from "../../../services/api/member/member";

const RenderTree = ({ node }) => {
  return (
    <TreeNode
      label={
        <SingleMemberNode
          name={node.item.name}
          ranking={node.item.ranking}
          imageProfile={node.item.imageProfile}
          teamCount={node.item.teamCount}
          userRole={node.item.userRole}
        />
      }
    >
      {Object.values(node.children).map((child) => (
        <RenderTree key={child.item.team_path} node={child} />
      ))}
    </TreeNode>
  );
};

const OrgChart = () => {
  const scrollToNodeRef = useRef(null);
  const scrollViewRef = useRef(null);

  const [rootNode, setRootNode] = useState();

  useEffect(() => {
    downloadTeamList();
  }, []);

  useEffect(() => {
    if (rootNode && scrollToNodeRef.current) {
      centerNodeInView();
    }
  }, [rootNode]);

  const centerNodeInView = () => {
    const container = scrollViewRef.current;
    const node = scrollToNodeRef.current;

    if (container && node) {
      const desiredScrollLeft =
        node.offsetLeft - container.offsetWidth / 2 + node.offsetWidth / 2;
      container.scrollLeft = desiredScrollLeft;
    }
  };

  const downloadTeamList = async () => {
    let team = await downloadAllTeam();
    if (!team) {
      return;
    }
    const sortedData = team.sort((a, b) => a.teamLevel - b.teamLevel);
    const tree = arrayToTree(sortedData);
    const result = findByTeamPath(tree, sortedData[0]?.teamPath);
    setRootNode(result);
  };

  return (
    <ScrollView
      horizontal
      style={{
        flex: 1,
        height: "100vh",
        overflow: "auto",
      }}
      contentContainerStyle={{
        justifyContent: "center",
        alignItems: "center",
        minWidth: "100%",
      }}
      ref={scrollViewRef}
    >
      <Grid container alignItems="center" justify="center">
        {rootNode ? (
          <React.Fragment>
            <div style={{ height: 100 }} />
            <Grid item xs={12} style={{ marginTop: 50 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Tree
                  lineWidth={"2px"}
                  lineColor={"#FFE2E2"}
                  lineHeight="60px"
                  lineBorderRadius={"10px"}
                  label={
                    <div ref={scrollToNodeRef}>
                      <SingleMemberNode
                        name={rootNode.item.name}
                        ranking={rootNode.item.ranking}
                        imageProfile={rootNode.item.imageProfile}
                        teamCount={rootNode.item.teamCount}
                        userRole={rootNode.item.userRole}
                      />
                    </div>
                  }
                >
                  {Object.values(rootNode.children).map((childNode) => (
                    <RenderTree
                      key={childNode.item.team_path}
                      node={childNode}
                    />
                  ))}
                </Tree>
              </div>
              <div style={{ height: 100 }} />
            </Grid>
          </React.Fragment>
        ) : null}
      </Grid>
    </ScrollView>
  );
};

function arrayToTree(items) {
  const tree = {};

  for (let item of items) {
    const path = item.teamPath.split("/");
    let currentLevel = tree;

    for (let i = 0; i < path.length; i++) {
      if (!currentLevel[path[i]]) {
        currentLevel[path[i]] = {
          item: i === path.length - 1 ? item : null,
          children: {},
        };
      }

      if (i === 0 && item.teamLevel === 1) {
        if (!tree["ONELIFE"]) {
          tree["ONELIFE"] = {
            item: items.find((i) => i.teamPath === "ONELIFE"),
            children: {},
          };
        }
        tree["ONELIFE"].children[path[i]] = currentLevel[path[i]];
      }

      currentLevel = currentLevel[path[i]].children;
    }
  }

  return tree;
}

function findByTeamPath(node, teamPath) {
  const pathParts = teamPath.split("/");

  let currentNode = node;

  for (let part of pathParts) {
    if (!currentNode[part]) return null;

    currentNode = currentNode[part];

    if (part === pathParts[pathParts.length - 1]) {
      return currentNode;
    }

    currentNode = currentNode.children;
  }

  return null;
}

export default OrgChart;
