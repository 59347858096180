import React from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import { useNavigate } from "react-router-dom";
const Reward = () => {
  const navigate = useNavigate();

  return (
    <View style={{ flex: 1 }}>
      <TouchableOpacity
        onPress={() => {
          navigate("login");
        }}
      >
        <Text>Reward</Text>
      </TouchableOpacity>
    </View>
  );
};

export default Reward;
