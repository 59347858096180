import React, { useState, useEffect } from "react";
import { StyleSheet } from "react-native-web";
import Select, { components } from "react-select";
//icons
import { RiArrowDownSLine } from "react-icons/ri";

const Dropdown = (props) => {
  const {
    list = [],
    placeholder,
    disable = false,
    value,
    valueType,
    findWithId,
    customStyle,
  } = props;
  const { onChange } = props;

  const [selectValue, setSelectValue] = useState();

  useEffect(() => {
    if (value !== null && value !== undefined && list.length > 0 && valueType) {
      console.log("Dropdown shadow value", value);
      if (findWithId) {
        let check =
          valueType === "string"
            ? list.filter((d) => {
                return String(d.id) === String(value);
              })
            : list.filter((d) => {
                return Number(d.id) === Number(value);
              });
        if (check.length > 0) {
          setSelectValue(check[0]);
        }
      } else {
        let check =
          valueType === "string"
            ? list.filter((d) => {
                return String(d.value) === String(value);
              })
            : list.filter((d) => {
                return Number(d.value) === Number(value);
              });
        if (check.length > 0) {
          setSelectValue(check[0]);
        }
      }
    } else if (!value && list && valueType) {
      setSelectValue(null);
    }
  }, [value]);

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 50,
      boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
      border: 0,
      backgroundColor: "rgba(255, 255, 255, 0.2)",
      borderRadius: 8,
      color: "#FFFFFF",
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
      color: "#FFFFFF",
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#FFFFFF" : "#333333",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#FFFFFF",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#FFFFFF",
      fontWeight: "bold",
    }),
    input: (provided) => ({
      ...provided,
      color: "#FFFFFF",
      fontWeight: "bold",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      paddingRight: state.selectProps.isDisabled
        ? provided.paddingRight
        : "25px",
    }),
  };

  return (
    <Select
      value={selectValue}
      isDisabled={disable}
      options={list ? list : []}
      placeholder={placeholder ? placeholder : ""}
      components={{
        DropdownIndicator: () => {
          return disable ? null : (
            <RiArrowDownSLine style={Styles.dropdownIcon} />
          );
        },
        IndicatorSeparator: () => null,
        SingleValue: CustomSingleValue,
      }}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: "#FFD7D7",
          primary: "#DE7C78",
        },
      })}
      onChange={(values) => {
        onChange && onChange(values);
      }}
      autoFocus={false}
      styles={customStyle ? customStyle : customStyles}
    />
  );
};

const Styles = StyleSheet.create({
  dropdownIcon: {
    position: "absolute",
    fontSize: 30,
    color: "#FFFFFF",
    right: 8,
  },
});

const CustomSingleValue = ({ children, ...props }) => {
  // React Native does not support line-clamp directly,
  // so we can approximate it with maxHeight
  const lineHeight = 20; // Define line height as per your font size
  const maxLines = 1;

  const style = {
    display: "-webkit-box",
    WebkitLineClamp: 3, // Number of lines you want to display
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  return (
    <components.SingleValue {...props}>
      <div style={style}>{children}</div>
    </components.SingleValue>
  );
};

export default Dropdown;
