import React from "react";
import { View, Text } from "react-native-web";
import emptyBox from "../../../asset/images/shopping/emptyBox.png";

const EmptyProduct = () => {
  return (
    <View
      style={{
        flex: 1,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={emptyBox}
        style={{ width: 120, zIndex: 0 }}
        alt={"emptyProduct"}
      />
      <View style={{ height: 30 }} />
      <Text
        style={{
          fontSize: "clamp(16px,2vh,20px)",
          fontWeight: "bold",
          color: "rgba(15, 16, 17, 1)",
        }}
      >
        ยังไม่มีสินค้า
      </Text>
      <Text
        style={{
          fontSize: "clamp(12px,2vh,16px)",
          color: "rgba(105, 109, 121, 1)",
          marginTop: 8,
        }}
      >
        กดที่ช่อง ‘เพิ่ม/ค้นหาสินค้า’ เพื่อเพิ่มสินค้าในคำสั่งซื้อ
      </Text>
    </View>
  );
};

export default EmptyProduct;
