import React from "react";
import TextField from "./TextField";

//images
import { RiSearchLine } from "react-icons/ri";

const SearchBox = (props) => {
  const { placeholder, showIcon = true, value = "" } = props;
  const { onChangeText } = props;

  return (
    <TextField
      value={value}
      placeholder={placeholder}
      icon={
        showIcon ? (
          <RiSearchLine
            style={{ fontSize: 20, color: "rgba(15, 16, 17, 1)" }}
          />
        ) : null
      }
      onChangeText={(values) => {
        onChangeText && onChangeText(values);
      }}
    />
  );
};

export default SearchBox;
